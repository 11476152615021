<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    class="maps"
	viewBox="0 0 540 600" style="enable-background:new 0 0 540 600; pointer-events: fill; overflow:visible;" xml:space="preserve">
    
<!-- Dot pattern no BG color -->
<pattern  x="54" y="-24" width="28.8" height="28.8" patternUnits="userSpaceOnUse" id="Dots_Pattern" viewBox="1.4 -30.2 28.8 28.8" style="overflow:visible;">
	<g>
		<rect x="1.4" y="-30.2" class="patternbg" width="28.8" height="28.8"/>
		<g>
			<path class="st1" d="M30.2,0c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C28.8-0.6,29.4,0,30.2,0z"/>
			<path class="st1" d="M15.8,0c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C14.4-0.6,15,0,15.8,0z"/>
			<path class="st1" d="M30.2-14.4c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4
				C28.8-15,29.4-14.4,30.2-14.4z"/>
			<path class="st1" d="M15.8-14.4c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C14.4-15,15-14.4,15.8-14.4z
				"/>
			<path class="st1" d="M8.6-7.2c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C7.2-7.8,7.8-7.2,8.6-7.2z"/>
			<path class="st1" d="M23-7.2c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C21.6-7.8,22.2-7.2,23-7.2z"/>
			<path class="st1" d="M8.6-21.6c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C7.2-22.2,7.8-21.6,8.6-21.6z
				"/>
			<path class="st1" d="M23-21.6c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C21.6-22.2,22.2-21.6,23-21.6z
				"/>
		</g>
		<g>
			<path class="st1" d="M1.4,0c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4C0.6-2.9,0-2.2,0-1.4C0-0.6,0.6,0,1.4,0z"/>
			<path class="st1" d="M1.4-14.4c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C0-15,0.6-14.4,1.4-14.4z"/>
		</g>
		<g>
			<path class="st1" d="M30.2-28.8c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4
				C28.8-29.4,29.4-28.8,30.2-28.8z"/>
			<path class="st1" d="M15.8-28.8c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4
				C14.4-29.4,15-28.8,15.8-28.8z"/>
		</g>
		<g>
			<path class="st1" d="M1.4-28.8c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4C0.6-31.7,0-31,0-30.2C0-29.4,0.6-28.8,1.4-28.8z"/>
		</g>
	</g>
</pattern>
<!-- Dot pattern White BG color -->
<pattern  x="54" y="-24" width="28.8" height="28.8" patternUnits="userSpaceOnUse" id="Dots_Pattern_W" viewBox="1.4 -30.2 28.8 28.8" style="overflow:visible;">
	<g>
		<rect x="1.4" y="-30.2" class="patternbg-focus" width="28.8" height="28.8"/>
		<g>
			<path class="st1-f" d="M30.2,0c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C28.8-0.6,29.4,0,30.2,0z"/>
			<path class="st1-f" d="M15.8,0c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C14.4-0.6,15,0,15.8,0z"/>
			<path class="st1-f" d="M30.2-14.4c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4
				C28.8-15,29.4-14.4,30.2-14.4z"/>
			<path class="st1-f" d="M15.8-14.4c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C14.4-15,15-14.4,15.8-14.4z
				"/>
			<path class="st1-f" d="M8.6-7.2c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C7.2-7.8,7.8-7.2,8.6-7.2z"/>
			<path class="st1-f" d="M23-7.2c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C21.6-7.8,22.2-7.2,23-7.2z"/>
			<path class="st1-f" d="M8.6-21.6c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C7.2-22.2,7.8-21.6,8.6-21.6z
				"/>
			<path class="st1-f" d="M23-21.6c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C21.6-22.2,22.2-21.6,23-21.6z
				"/>
		</g>
		<g>
			<path class="st1-f" d="M1.4,0c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4C0.6-2.9,0-2.2,0-1.4C0-0.6,0.6,0,1.4,0z"/>
			<path class="st1-f" d="M1.4-14.4c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C0-15,0.6-14.4,1.4-14.4z"/>
		</g>
		<g>
			<path class="st1-f" d="M30.2-28.8c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4
				C28.8-29.4,29.4-28.8,30.2-28.8z"/>
			<path class="st1-f" d="M15.8-28.8c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4
				C14.4-29.4,15-28.8,15.8-28.8z"/>
		</g>
		<g>
			<path class="st1-f" d="M1.4-28.8c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4C0.6-31.7,0-31,0-30.2C0-29.4,0.6-28.8,1.4-28.8z"/>
		</g>
	</g>
</pattern>

<!-- Line Pattern no BG Color -->
<!-- <pattern  x="54" y="-24" width="72" height="72" patternUnits="userSpaceOnUse" id="Diagonal_Lines" viewBox="79.6 -148.6 72 72" style="overflow:visible;">
	<g>
		<rect x="79.6" y="-148.6" class="patternbg" width="72" height="72"/>
		<g>
			<line class="st2" x1="144.1" y1="-66.1" x2="162.1" y2="-84.1"/>
			<line class="st2" x1="144.1" y1="-60.1" x2="168.1" y2="-84.1"/>
			<line class="st2" x1="144.1" y1="-54.1" x2="174.1" y2="-84.1"/>
			<line class="st2" x1="144.1" y1="-48.1" x2="180.1" y2="-84.1"/>
			<line class="st2" x1="144.1" y1="-42.1" x2="186.1" y2="-84.1"/>
			<line class="st2" x1="144.1" y1="-36.1" x2="192.1" y2="-84.1"/>
			<line class="st2" x1="144.1" y1="-30.1" x2="198.1" y2="-84.1"/>
			<line class="st2" x1="144.1" y1="-24.1" x2="204.1" y2="-84.1"/>
			<line class="st2" x1="144.1" y1="-18.1" x2="210.1" y2="-84.1"/>
			<line class="st2" x1="144.1" y1="-12.1" x2="216.1" y2="-84.1"/>
			<line class="st2" x1="144.1" y1="-6.1" x2="222.1" y2="-84.1"/>
			<line class="st2" x1="144.1" y1="-0.1" x2="228.1" y2="-84.1"/>
			<line class="st2" x1="150.1" y1="-0.1" x2="228.1" y2="-78.1"/>
		</g>
		<g>
			<line class="st2" x1="72.1" y1="-66.1" x2="90.1" y2="-84.1"/>
			<line class="st2" x1="72.1" y1="-60.1" x2="96.1" y2="-84.1"/>
			<line class="st2" x1="72.1" y1="-54.1" x2="102.1" y2="-84.1"/>
			<line class="st2" x1="72.1" y1="-48.1" x2="108.1" y2="-84.1"/>
			<line class="st2" x1="72.1" y1="-42.1" x2="114.1" y2="-84.1"/>
			<line class="st2" x1="72.1" y1="-36.1" x2="120.1" y2="-84.1"/>
			<line class="st2" x1="72.1" y1="-30.1" x2="126.1" y2="-84.1"/>
			<line class="st2" x1="72.1" y1="-24.1" x2="132.1" y2="-84.1"/>
			<line class="st2" x1="72.1" y1="-18.1" x2="138.1" y2="-84.1"/>
			<line class="st2" x1="72.1" y1="-12.1" x2="144.1" y2="-84.1"/>
			<line class="st2" x1="72.1" y1="-6.1" x2="150.1" y2="-84.1"/>
			<line class="st2" x1="72.1" y1="-0.1" x2="156.1" y2="-84.1"/>
			<line class="st2" x1="78.1" y1="-0.1" x2="156.1" y2="-78.1"/>
		</g>
		<g>
			<line class="st2" x1="0.1" y1="-0.1" x2="84.1" y2="-84.1"/>
			<line class="st2" x1="6.1" y1="-0.1" x2="84.1" y2="-78.1"/>
		</g>
		<g>
			<line class="st2" x1="144.1" y1="-138.1" x2="162.1" y2="-156.1"/>
			<line class="st2" x1="144.1" y1="-132.1" x2="168.1" y2="-156.1"/>
			<line class="st2" x1="144.1" y1="-126.1" x2="174.1" y2="-156.1"/>
			<line class="st2" x1="144.1" y1="-120.1" x2="180.1" y2="-156.1"/>
			<line class="st2" x1="144.1" y1="-114.1" x2="186.1" y2="-156.1"/>
			<line class="st2" x1="144.1" y1="-108.1" x2="192.1" y2="-156.1"/>
			<line class="st2" x1="144.1" y1="-102.1" x2="198.1" y2="-156.1"/>
			<line class="st2" x1="144.1" y1="-96.1" x2="204.1" y2="-156.1"/>
			<line class="st2" x1="144.1" y1="-90.1" x2="210.1" y2="-156.1"/>
			<line class="st2" x1="144.1" y1="-84.1" x2="216.1" y2="-156.1"/>
			<line class="st2" x1="144.1" y1="-78.1" x2="222.1" y2="-156.1"/>
			<line class="st2" x1="144.1" y1="-72.1" x2="228.1" y2="-156.1"/>
			<line class="st2" x1="150.1" y1="-72.1" x2="228.1" y2="-150.1"/>
		</g>
		<g>
			<line class="st2" x1="72.1" y1="-138.1" x2="90.1" y2="-156.1"/>
			<line class="st2" x1="72.1" y1="-132.1" x2="96.1" y2="-156.1"/>
			<line class="st2" x1="72.1" y1="-126.1" x2="102.1" y2="-156.1"/>
			<line class="st2" x1="72.1" y1="-120.1" x2="108.1" y2="-156.1"/>
			<line class="st2" x1="72.1" y1="-114.1" x2="114.1" y2="-156.1"/>
			<line class="st2" x1="72.1" y1="-108.1" x2="120.1" y2="-156.1"/>
			<line class="st2" x1="72.1" y1="-102.1" x2="126.1" y2="-156.1"/>
			<line class="st2" x1="72.1" y1="-96.1" x2="132.1" y2="-156.1"/>
			<line class="st2" x1="72.1" y1="-90.1" x2="138.1" y2="-156.1"/>
			<line class="st2" x1="72.1" y1="-84.1" x2="144.1" y2="-156.1"/>
			<line class="st2" x1="72.1" y1="-78.1" x2="150.1" y2="-156.1"/>
			<line class="st2" x1="72.1" y1="-72.1" x2="156.1" y2="-156.1"/>
			<line class="st2" x1="78.1" y1="-72.1" x2="156.1" y2="-150.1"/>
			<line class="st2" x1="84.1" y1="-72.1" x2="156.1" y2="-144.1"/>
			<line class="st2" x1="90.1" y1="-72.1" x2="156.1" y2="-138.1"/>
			<line class="st2" x1="96.1" y1="-72.1" x2="156.1" y2="-132.1"/>
			<line class="st2" x1="102.1" y1="-72.1" x2="156.1" y2="-126.1"/>
			<line class="st2" x1="108.1" y1="-72.1" x2="156.1" y2="-120.1"/>
			<line class="st2" x1="114.1" y1="-72.1" x2="156.1" y2="-114.1"/>
			<line class="st2" x1="120.1" y1="-72.1" x2="156.1" y2="-108.1"/>
			<line class="st2" x1="126.1" y1="-72.1" x2="156.1" y2="-102.1"/>
			<line class="st2" x1="132.1" y1="-72.1" x2="156.1" y2="-96.1"/>
			<line class="st2" x1="138.1" y1="-72.1" x2="156.1" y2="-90.1"/>
			<line class="st2" x1="144.1" y1="-72.1" x2="156.1" y2="-84.1"/>
		</g>
		<g>
			<line class="st2" x1="0.1" y1="-72.1" x2="84.1" y2="-156.1"/>
			<line class="st2" x1="6.1" y1="-72.1" x2="84.1" y2="-150.1"/>
			<line class="st2" x1="12.1" y1="-72.1" x2="84.1" y2="-144.1"/>
			<line class="st2" x1="18.1" y1="-72.1" x2="84.1" y2="-138.1"/>
			<line class="st2" x1="24.1" y1="-72.1" x2="84.1" y2="-132.1"/>
			<line class="st2" x1="30.1" y1="-72.1" x2="84.1" y2="-126.1"/>
			<line class="st2" x1="36.1" y1="-72.1" x2="84.1" y2="-120.1"/>
			<line class="st2" x1="42.1" y1="-72.1" x2="84.1" y2="-114.1"/>
			<line class="st2" x1="48.1" y1="-72.1" x2="84.1" y2="-108.1"/>
			<line class="st2" x1="54.1" y1="-72.1" x2="84.1" y2="-102.1"/>
			<line class="st2" x1="60.1" y1="-72.1" x2="84.1" y2="-96.1"/>
			<line class="st2" x1="66.1" y1="-72.1" x2="84.1" y2="-90.1"/>
			<line class="st2" x1="72.1" y1="-72.1" x2="84.1" y2="-84.1"/>
		</g>
		<g>
			<line class="st2" x1="144.1" y1="-144.1" x2="228.1" y2="-228.1"/>
			<line class="st2" x1="150.1" y1="-144.1" x2="228.1" y2="-222.1"/>
		</g>
		<g>
			<line class="st2" x1="72.1" y1="-144.1" x2="156.1" y2="-228.1"/>
			<line class="st2" x1="78.1" y1="-144.1" x2="156.1" y2="-222.1"/>
			<line class="st2" x1="84.1" y1="-144.1" x2="156.1" y2="-216.1"/>
			<line class="st2" x1="90.1" y1="-144.1" x2="156.1" y2="-210.1"/>
			<line class="st2" x1="96.1" y1="-144.1" x2="156.1" y2="-204.1"/>
			<line class="st2" x1="102.1" y1="-144.1" x2="156.1" y2="-198.1"/>
			<line class="st2" x1="108.1" y1="-144.1" x2="156.1" y2="-192.1"/>
			<line class="st2" x1="114.1" y1="-144.1" x2="156.1" y2="-186.1"/>
			<line class="st2" x1="120.1" y1="-144.1" x2="156.1" y2="-180.1"/>
			<line class="st2" x1="126.1" y1="-144.1" x2="156.1" y2="-174.1"/>
			<line class="st2" x1="132.1" y1="-144.1" x2="156.1" y2="-168.1"/>
			<line class="st2" x1="138.1" y1="-144.1" x2="156.1" y2="-162.1"/>
			<line class="st2" x1="144.1" y1="-144.1" x2="156.1" y2="-156.1"/>
		</g>
		<g>
			<line class="st2" x1="0.1" y1="-144.1" x2="84.1" y2="-228.1"/>
			<line class="st2" x1="6.1" y1="-144.1" x2="84.1" y2="-222.1"/>
			<line class="st2" x1="12.1" y1="-144.1" x2="84.1" y2="-216.1"/>
			<line class="st2" x1="18.1" y1="-144.1" x2="84.1" y2="-210.1"/>
			<line class="st2" x1="24.1" y1="-144.1" x2="84.1" y2="-204.1"/>
			<line class="st2" x1="30.1" y1="-144.1" x2="84.1" y2="-198.1"/>
			<line class="st2" x1="36.1" y1="-144.1" x2="84.1" y2="-192.1"/>
			<line class="st2" x1="42.1" y1="-144.1" x2="84.1" y2="-186.1"/>
			<line class="st2" x1="48.1" y1="-144.1" x2="84.1" y2="-180.1"/>
			<line class="st2" x1="54.1" y1="-144.1" x2="84.1" y2="-174.1"/>
			<line class="st2" x1="60.1" y1="-144.1" x2="84.1" y2="-168.1"/>
			<line class="st2" x1="66.1" y1="-144.1" x2="84.1" y2="-162.1"/>
			<line class="st2" x1="72.1" y1="-144.1" x2="84.1" y2="-156.1"/>
		</g>
	</g>
</pattern> -->
<pattern  y="600" width="72" height="72" patternUnits="userSpaceOnUse" id="Diagonal_Lines" viewBox="72.2 -72 72 72" style="overflow:visible;">
	<g>
		<rect x="72.2" y="-72" class="patternbg" width="72" height="72"/>
		<g>
			<line class="st2" x1="144" y1="-6" x2="216.5" y2="-6"/>
			<line class="st2" x1="144" y1="-30" x2="216.5" y2="-30"/>
			<line class="st2" x1="144" y1="-54" x2="216.5" y2="-54"/>
			<line class="st2" x1="144" y1="-18" x2="216.5" y2="-18"/>
			<line class="st2" x1="144" y1="-42" x2="216.5" y2="-42"/>
			<line class="st2" x1="144" y1="-66" x2="216.5" y2="-66"/>
		</g>
		<g>
			<line class="st2" x1="72" y1="-6" x2="144.5" y2="-6"/>
			<line class="st2" x1="72" y1="-30" x2="144.5" y2="-30"/>
			<line class="st2" x1="72" y1="-54" x2="144.5" y2="-54"/>
			<line class="st2" x1="72" y1="-18" x2="144.5" y2="-18"/>
			<line class="st2" x1="72" y1="-42" x2="144.5" y2="-42"/>
			<line class="st2" x1="72" y1="-66" x2="144.5" y2="-66"/>
		</g>
		<g>
			<line class="st2" x1="0" y1="-6" x2="72.5" y2="-6"/>
			<line class="st2" x1="0" y1="-30" x2="72.5" y2="-30"/>
			<line class="st2" x1="0" y1="-54" x2="72.5" y2="-54"/>
			<line class="st2" x1="0" y1="-18" x2="72.5" y2="-18"/>
			<line class="st2" x1="0" y1="-42" x2="72.5" y2="-42"/>
			<line class="st2" x1="0" y1="-66" x2="72.5" y2="-66"/>
		</g>
	</g>
</pattern>
<pattern  y="600" width="72" height="72" patternUnits="userSpaceOnUse" id="Diagonal_Lines_W" viewBox="72.2 -72 72 72" style="overflow:visible;">
	<g>
		<rect x="72.2" y="-72" class="patternbg-focus" width="72" height="72"/>
		<g>
			<line class="st2-f" x1="144" y1="-6" x2="216.5" y2="-6"/>
			<line class="st2-f" x1="144" y1="-30" x2="216.5" y2="-30"/>
			<line class="st2-f" x1="144" y1="-54" x2="216.5" y2="-54"/>
			<line class="st2-f" x1="144" y1="-18" x2="216.5" y2="-18"/>
			<line class="st2-f" x1="144" y1="-42" x2="216.5" y2="-42"/>
			<line class="st2-f" x1="144" y1="-66" x2="216.5" y2="-66"/>
		</g>
		<g>
			<line class="st2-f" x1="72" y1="-6" x2="144.5" y2="-6"/>
			<line class="st2-f" x1="72" y1="-30" x2="144.5" y2="-30"/>
			<line class="st2-f" x1="72" y1="-54" x2="144.5" y2="-54"/>
			<line class="st2-f" x1="72" y1="-18" x2="144.5" y2="-18"/>
			<line class="st2-f" x1="72" y1="-42" x2="144.5" y2="-42"/>
			<line class="st2-f" x1="72" y1="-66" x2="144.5" y2="-66"/>
		</g>
		<g>
			<line class="st2-f" x1="0" y1="-6" x2="72.5" y2="-6"/>
			<line class="st2-f" x1="0" y1="-30" x2="72.5" y2="-30"/>
			<line class="st2-f" x1="0" y1="-54" x2="72.5" y2="-54"/>
			<line class="st2-f" x1="0" y1="-18" x2="72.5" y2="-18"/>
			<line class="st2-f" x1="0" y1="-42" x2="72.5" y2="-42"/>
			<line class="st2-f" x1="0" y1="-66" x2="72.5" y2="-66"/>
		</g>
	</g>
</pattern>
<pattern id="Tilted_Lines" xlink:href="#Diagonal_Lines" patternTransform="matrix(0.4243 -0.4243 0.4243 0.4243 -21700.334 19757.3105)">
</pattern>
<pattern id="Tilted_Lines_W" xlink:href="#Diagonal_Lines_W" patternTransform="matrix(0.4243 -0.4243 0.4243 0.4243 -21700.334 19757.3105)">
</pattern>

<pattern id="Tilted_Dots" xlink:href="#Dots_Pattern" patternTransform="matrix(0.84 0 0 0.84 -1284.776 -1194.83)" class="counties">
</pattern>
<pattern id="Tilted_Dots_W" xlink:href="#Dots_Pattern_W" patternTransform="matrix(0.84 0 0 0.84 -1284.776 -1194.83)" class="counties">
</pattern>

<g id="BG">
	<g>
		<path class="noemc" d="M115.5,531.7c0.2,0,0.6-0.2,0.8-0.1c0.2,0,0.6,0.3,0.8,0.3c0.2,0,0.6-0.4,0.6-0.6c0-0.2-0.2-0.6-0.3-0.7
			c-0.2-0.1-0.8,0.1-0.9,0c-0.1-0.1-0.1-0.6-0.2-0.7c-0.2-0.2-0.9-0.5-1.1-0.4c-0.3,0.2-0.2,1.2-0.3,1.5c-0.1,0.2-0.4,0.5-0.3,0.6
			C114.6,531.8,115.2,531.7,115.5,531.7z"/>
		<path class="noemc" d="M113.2,532c0.1,0,0.5-0.1,0.5-0.2c0.1-0.2,0.1-0.6,0.1-0.8c0-0.2-0.2-0.7-0.4-0.7c-0.2,0-0.5,0.3-0.6,0.4
			c-0.1,0.2-0.4,0.7-0.3,0.9C112.7,531.8,113.1,532,113.2,532z"/>
		<path class="noemc" d="M109.1,531.1c0.2-0.1,0.6-0.3,0.8-0.3c0.1,0,0.4,0,0.6,0c0.2,0,0.7,0.3,1,0.2c0.3-0.1,0.6-0.6,0.7-0.8
			c0-0.2-0.1-0.6-0.2-0.8c-0.2-0.2-0.6-0.7-0.9-0.7c-0.3,0-0.7,0.5-1.1,0.5c-0.2,0-0.7-0.3-0.9-0.3c-0.2,0-0.6,0.2-0.8,0.3
			c-0.2,0.3-0.4,1-0.4,1.3c0,0.2,0,0.7,0.2,0.7C108.4,531.4,108.9,531.2,109.1,531.1z"/>
		<path class="noemc" d="M445.3,522.5c-0.1-0.1-0.4-0.2-0.5-0.2c-0.1,0-0.2,0.3-0.2,0.4c0,0.3,0.2,0.8,0.4,1c0.1,0.1,0.5,0.1,0.6,0
			c0.1-0.1,0.2-0.5,0.2-0.7C445.7,522.9,445.4,522.6,445.3,522.5z"/>
		<path class="noemc" d="M447.9,525.8c-0.2-0.1-0.5,0-0.7,0.1c-0.2,0.1-0.5,0.4-0.5,0.6c-0.1,0.3-0.1,0.8,0,1.1
			c0.1,0.4,0.7,1.2,1.1,1.3c0.2,0,0.7-0.3,0.8-0.5c0.2-0.3,0.2-1,0.1-1.3c0-0.2-0.2-0.6-0.4-0.8
			C448.3,526.1,448.1,525.8,447.9,525.8z"/>
		<path class="noemc" d="M457.4,511.1c0-0.1,0.1-1.3,0.1-1.7c0-0.4,0-1.3,0-1.4c0-0.1-0.5-0.9-0.8-1c-0.2,0-0.6,0.2-0.7,0.4
			c-0.2,0.3-0.2,1.1-0.3,1.4c-0.1,0.5-0.3,1.5-0.5,2c-0.1,0.3-0.3,1-0.5,1.2c-0.1,0.2-0.6,0.3-0.8,0.4c-0.4,0.2-1.2,0.6-1.5,0.9
			c-0.2,0.2-0.4,0.7-0.4,1c0,0.2-0.1,0.7,0.1,0.9c0.2,0.2,0.9,0.2,1.1,0.4c0.2,0.1,0.5,0.5,0.6,0.8c0.1,0.3,0.1,1,0,1.3
			c0,0.1-0.2,0.3-0.4,0.3c-0.3,0-0.9-0.2-1-0.4c-0.1-0.2,0.6-0.6,0.6-0.8c0-0.2-0.4-0.5-0.6-0.7c-0.2-0.2-0.8-0.5-1-0.4
			c-0.2,0.1-0.3,0.7-0.5,0.7c-0.2,0.1-0.6-0.4-0.8-0.4c-0.3,0-0.8,0.2-1,0.4c-0.3,0.3-0.4,1.1-0.6,1.4c-0.1,0.2-0.7,0.4-0.7,0.6
			c-0.1,0.4,0.5,1.1,0.8,1.4c0.3,0.4,1.4,0.8,1.7,1.2c0.2,0.3,0.2,1.1,0.2,1.5c0,0.3,0,0.9,0.1,1.1c0.1,0.2,0.4,0.6,0.4,0.8
			c0.1,0.5-0.1,1.4-0.1,1.9c0,0.2,0.1,0.7,0.1,0.9c0,0.4-0.3,1-0.3,1.4c0,0.4-0.1,1.1,0,1.5c0.1,0.3,0.8,0.8,0.8,1.1
			c0,0.3-0.3,0.7-0.4,1c-0.1,0.4,0,1.2-0.1,1.6c-0.1,0.3-0.5,0.8-0.6,1.1c-0.1,0.3-0.2,0.9-0.1,1.2c0.1,0.3,0.7,0.7,0.8,1
			c0.1,0.2,0,0.6,0.1,0.8c0.2,0.3,0.7,0.7,1.1,0.8c0.2,0.1,0.7,0.2,0.9,0.1c0.4-0.3,0.4-1.4,0.2-1.8c-0.1-0.3-0.7-0.8-0.8-1.1
			c-0.1-0.3-0.2-1.1-0.3-1.4c0-0.5,0.2-1.6,0.2-2.1c0-0.3-0.3-0.8-0.3-1.1c0-0.5,0.7-1.4,0.9-1.9c0.1-0.4,0.1-1.1,0.2-1.5
			c0.1-0.5,0.6-1.5,0.8-2c0.4-0.8,1.2-2.3,1.5-3.1c0.4-0.9,0.9-2.7,1.1-3.6c0.2-0.7,0.3-2.1,0.4-2.8c0.1-0.7,0.1-2.2,0.3-2.9
			c0.1-0.6,0.9-1.8,0.6-2.3C457.9,511.2,457.4,511.2,457.4,511.1z"/>
		<path class="noemc" d="M441.5,506c-0.2,0.1-0.6,0.4-0.8,0.5c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.3,0.5-0.2,0.6c0.1,0.3,0.7,0.7,1,0.7
			c0.2,0,0.7-0.2,0.9-0.4c0.1-0.1,0.1-0.6,0.2-0.7c0.1-0.1,0.5-0.3,0.5-0.4c0-0.2-0.5-0.3-0.7-0.3
			C441.8,505.9,441.6,505.9,441.5,506z"/>
		<path class="noemc" d="M448.8,507.4c-0.2,0-0.5,0.1-0.6,0.2c-0.1,0.1-0.3,0.4-0.2,0.6c0,0.2,0.4,0.3,0.5,0.4
			c0.1,0.1,0.4,0.1,0.6,0.1c0.2-0.1,0.6-0.4,0.6-0.7c0-0.1-0.3-0.3-0.4-0.4C449.2,507.5,449,507.4,448.8,507.4z"/>
		<path class="noemc" d="M450.8,502.1c0.2,0.1,0.6,0.1,0.8,0c0.2-0.1,0.3-0.5,0.5-0.6c0.1-0.1,0.5,0.1,0.6,0c0.2-0.2,0.2-0.7,0.1-0.9
			c-0.1-0.1-0.5-0.1-0.7-0.1c-0.3,0-0.8-0.2-1.1-0.1c-0.1,0-0.3,0.3-0.4,0.4c-0.1,0.2-0.4,0.8-0.3,1
			C450.4,501.9,450.7,502.1,450.8,502.1z"/>
		<path class="noemc" d="M469.7,436.3c-0.2,0-0.6,0.3-0.8,0.4c-0.1,0.1-0.3,0.4-0.4,0.4c-0.2,0.1-0.9,0-1.1,0.2
			c-0.1,0.1-0.2,0.4-0.2,0.5c0,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.5,0,0.6,0.1c0.1,0.1,0.2,0.4,0.3,0.4c0.2,0,0.6-0.2,0.7-0.4
			c0.1-0.1,0.3-0.4,0.4-0.6c0.2-0.2,0.6-0.4,0.7-0.6c0.1-0.2,0.3-0.5,0.2-0.7C470.4,436.3,469.9,436.3,469.7,436.3z"/>
		<path class="noemc" d="M476.6,421.7c-0.2,0-0.7,0-0.9-0.1c-0.1-0.1-0.3-0.4-0.4-0.4c-0.3,0-0.9,0.3-1.1,0.5
			c-0.1,0.1-0.1,0.4-0.2,0.5c-0.2,0.2-1.1,0.1-1.1,0.4c0,0.1,0.2,0.4,0.4,0.5c0.3,0.1,0.9,0.1,1.2,0.1c0.2,0,0.7-0.3,0.9-0.4
			c0.2-0.1,0.7-0.2,0.9-0.2c0.3,0,0.8,0.3,1.1,0.3c0.1,0,0.3-0.1,0.4-0.1c0.1-0.1,0.4-0.4,0.3-0.6c-0.1-0.2-0.5-0.3-0.7-0.3
			C477.1,421.8,476.8,421.7,476.6,421.7z"/>
		<path class="noemc" d="M451,498.5c-0.1-0.2-0.5-0.3-0.5-0.5c-0.1-0.2,0-0.6-0.1-0.8c0-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.6-0.5-0.8-0.4
			c-0.2,0.1-0.2,0.5-0.3,0.7c0,0.3-0.1,0.9,0,1.2c0.2,0.4,0.9,0.9,1.3,1c0.2,0,0.6-0.1,0.7-0.2C451,499.1,451,498.6,451,498.5z"/>
		<path class="noemc" d="M457.9,490.7c-0.1-0.4-0.6-1.4-1-1.4c-0.3,0-0.6,0.6-0.8,0.9c-0.2,0.3-0.2,1.2-0.5,1.5
			c-0.2,0.2-0.7,0.1-0.9,0.3c-0.2,0.2-0.5,0.7-0.5,0.9c0,0.2,0.2,0.5,0.3,0.7c0.1,0.2,0.3,0.5,0.4,0.7c0.2,0.4,0.3,1.3,0.5,1.6
			c0.1,0.1,0.3,0.3,0.3,0.4c0.2,0.3,0.3,1.1,0.3,1.4c0,0.3-0.1,0.9-0.2,1.1c-0.1,0.2-0.5,0.4-0.5,0.5c-0.1,0.2,0,0.6,0,0.8
			c-0.1,0.2-0.6,0.5-0.7,0.7c-0.1,0.2-0.2,0.8,0,1.1c0.1,0.2,0.7,0.5,0.9,0.5c0.2,0,0.5-0.3,0.6-0.4c0.2-0.2,0.4-0.8,0.5-1.1
			c0.1-0.4-0.1-1.2,0-1.6c0.1-0.4,0.5-1,0.6-1.3c0.3-0.7,1-2,1.1-2.7c0.1-0.6-0.1-1.8-0.1-2.4C458.1,492.5,458.1,491.3,457.9,490.7z
			"/>
		<path class="noemc" d="M454.4,499.6c0.3-0.1,0.9-0.5,1-0.8c0-0.2-0.5-0.6-0.6-0.8c-0.1-0.3-0.1-1-0.1-1.3c0-0.3,0.1-0.9,0.1-1.1
			c-0.1-0.4-0.3-1.2-0.7-1.3c-0.2-0.1-0.5,0.4-0.7,0.4c-0.2,0-0.6-0.3-0.7-0.5c-0.1-0.2-0.1-0.7-0.2-0.9c-0.2-0.2-0.7-0.4-0.9-0.5
			c-0.2,0-0.7,0.2-0.9,0c-0.2-0.1-0.3-0.7-0.4-0.9c-0.2-0.2-0.6-0.6-0.8-0.8c-0.1-0.2-0.3-0.6-0.4-0.7c-0.2-0.2-0.6-0.6-0.8-0.5
			c-0.2,0-0.3,0.5-0.4,0.7c0,0.3,0.3,0.8,0.3,1.1c0,0.2,0.1,0.7-0.1,0.8c-0.1,0.1-0.3,0-0.4,0c-0.2,0.1-0.6,0.5-0.5,0.7
			c0,0.2,0.5,0.3,0.6,0.5c0.1,0.1,0,0.5,0.1,0.6c0.2,0.1,0.9,0,0.9,0c0,0.1,0.7,0.8,1,1c0.2,0.1,0.6,0.1,0.7,0.2
			c0.2,0.2,0.5,0.8,0.6,1.1c0.1,0.3,0.1,0.8,0.2,1.1c0.1,0.3,0.6,0.7,0.8,0.9c0.2,0.2,0.7,0.7,1,0.8
			C453.6,499.4,454.2,499.6,454.4,499.6z"/>
		<path class="noemc" d="M447.7,487.6c0-0.1-0.3-0.3-0.3-0.4c-0.1-0.1,0-0.5-0.1-0.6c-0.2-0.2-0.7-0.4-0.9-0.5
			c-0.1-0.1-0.3-0.3-0.5-0.3c-0.2,0-0.5,0.3-0.5,0.5c-0.1,0.2,0,0.5,0.1,0.7c0.1,0.2,0.4,0.4,0.6,0.5c0.2,0.2,0.4,0.6,0.6,0.7
			c0.2,0.1,0.7,0.4,0.9,0.3C447.8,488.5,447.8,487.9,447.7,487.6z"/>
		<path class="noemc" d="M454.9,483.1c0.1-0.2-0.1-0.6,0-0.8c0.1-0.2,0.5-0.5,0.5-0.7c0-0.3-0.4-0.9-0.6-1.2c-0.1-0.1-0.4-0.5-0.6-0.5
			c-0.2,0-0.4,0.4-0.5,0.5c-0.1,0.1-0.1,0.4-0.2,0.6c-0.1,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.1,0.8-0.1,1c0,0.2,0.2,0.4,0.3,0.6
			c0.1,0.1,0.4,0.2,0.5,0.3c0.1,0.2-0.1,0.7,0,0.9c0,0.2,0.2,0.6,0.4,0.6c0.2,0,0.4-0.5,0.4-0.7c0-0.1-0.1-0.5-0.1-0.6
			C454.5,483.3,454.8,483.2,454.9,483.1z"/>
		<path class="noemc" d="M468.9,466.8c-0.1-0.2-0.1-0.6-0.2-0.8c-0.2-0.2-0.9-0.2-1.1,0c-0.2,0.2-0.2,0.7-0.1,1
			c0.1,0.2,0.4,0.5,0.5,0.6c0.2,0.1,0.5,0.3,0.7,0.3c0.2,0,0.6-0.3,0.6-0.5C469.4,467.2,469,467,468.9,466.8z"/>
		<path class="noemc" d="M465.9,464.2c-0.1,0-0.4,0-0.5,0.1c-0.1,0.1-0.3,0.4-0.4,0.5c0,0.2,0.2,0.6,0.3,0.7c0.1,0.1,0.4-0.1,0.6-0.2
			c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.5,0.1-0.6C466.3,464.4,466,464.2,465.9,464.2z"/>
		<path class="noemc" d="M461.5,463.6c-0.2,0.1-0.4,0.4-0.5,0.5c0,0.1-0.1,0.5,0,0.6c0.1,0.2,0.6,0.2,0.8,0.2c0.2,0,0.5-0.1,0.7-0.1
			c0.1-0.1,0.4-0.3,0.4-0.5c0-0.2-0.5-0.5-0.7-0.6C462,463.6,461.7,463.5,461.5,463.6z"/>
		<path class="noemc" d="M492.1,399.5c-0.2,0-0.4-0.2-0.6-0.2c-0.2-0.1-0.7-0.2-0.9-0.1c-0.2,0.1-0.5,0.5-0.5,0.8
			c0,0.2,0.2,0.4,0.4,0.6c0.2,0.2,0.7,0.4,0.9,0.5c0.2,0.1,0.5,0.6,0.7,0.7c0.2,0,0.6,0,0.7-0.2c0.1-0.1,0.1-0.5,0.2-0.6
			c0.1-0.1,0.5-0.3,0.5-0.5c0.1-0.2-0.1-0.7-0.2-0.8c-0.1-0.1-0.4-0.2-0.5-0.2C492.6,399.3,492.3,399.5,492.1,399.5z"/>
		<path class="noemc" d="M515.1,379.8c-0.1-0.3-0.6-0.6-0.9-0.7c-0.3-0.1-0.8,0.1-1,0.3c-0.2,0.1-0.4,0.4-0.6,0.5
			c-0.2,0.2-0.6,0.5-0.8,0.6c-0.3,0-0.8-0.2-1-0.5c-0.2-0.3-0.1-1-0.2-1.3c-0.1-0.3-0.2-1-0.4-1.2c-0.2-0.2-0.6-0.5-0.9-0.6
			c-0.2-0.1-0.6,0.1-0.8,0.1c-0.3,0-0.8,0.1-1,0.1c-0.4-0.1-1-0.6-1.3-0.7c-0.2-0.1-0.7,0-0.9-0.1c-0.3-0.1-0.7-0.4-0.9-0.6
			c-0.2-0.2-0.4-0.8-0.5-1c-0.2-0.3-0.6-0.9-0.9-1.1c-0.2-0.1-0.8-0.2-1.1-0.3c-0.2-0.1-0.7-0.4-0.9-0.6c-0.3-0.3-0.8-1-1.1-1.3
			c-0.2-0.2-0.5-0.6-0.7-0.7c-0.3-0.2-0.9-0.5-1.2-0.6c-0.2,0-0.7,0-0.9,0.1c-0.3,0.1-0.6,0.6-0.8,0.8c-0.2,0.2-0.8,0.5-1.1,0.6
			c-0.4,0.1-1.6-0.2-1.8-0.2c-0.1,0-0.6,0.1-0.8,0c-0.5-0.2-1.4-1-1.8-1.4c-0.3-0.2-0.7-0.8-1.1-1c-0.3-0.2-1-0.4-1.1-0.4
			c-0.1,0-1.3-0.5-1.4-0.6c-0.1-0.1-0.6-1.1-0.7-1.5c-0.1-0.4-0.2-1.1-0.1-1.4c0-0.1,0.3-0.3,0.4-0.4c0.1-0.2,0-0.8-0.1-1
			c-0.1-0.4-0.9-0.8-1-1.2c-0.1-0.2,0.1-0.8,0.2-0.9c0.3-0.2,1.1,0.2,1.3,0c0.2-0.1,0.3-0.6,0.3-0.8c0-0.3-0.6-0.7-0.7-0.9
			c0-0.3,0.3-0.7,0.3-1c-0.1-0.4-0.8-1.1-1.1-1.4c-0.3-0.3-0.9-0.7-1.2-0.9c-0.3-0.2-1.1-0.5-1.2-0.9c0-0.2,0.3-0.5,0.4-0.7
			c0-0.1,0.1-0.1,0.1-0.2c0-0.5-0.8-1.1-0.9-1.5c-0.1-0.3-0.1-1,0.1-1.3c0.1-0.2,0.4-0.3,0.5-0.4c0.1-0.2,0.1-0.9,0.2-1.1
			c0.3-0.5,1.6-0.8,1.9-1.2c0.2-0.3,0.5-1,0.6-1.4c0.1-0.4,0.2-1.4,0-1.8c-0.1-0.4-0.7-0.9-0.8-1.2c-0.1-0.3,0.1-1.1,0-1.4
			c-0.1-0.2-0.4-0.6-0.4-0.9c0-0.3,0.4-0.7,0.5-0.9c0-0.2,0-0.7-0.1-0.9c-0.2-0.3-0.8-0.9-1.2-0.9c-0.3,0-0.7,0.2-0.9,0.4
			c-0.2,0.2-0.3,0.9-0.6,0.9c-0.2,0-0.2-0.5-0.3-0.7c-0.1-0.3,0.1-1.1,0-1.4c-0.1-0.2-0.8-0.4-0.9-0.6c-0.1-0.3,0.1-1,0-1.2
			c-0.1-0.3-0.8-0.4-0.9-0.7c-0.1-0.4,0.4-1.1,0.3-1.5c0-0.3-0.4-1-0.6-1.2c-0.3-0.4-1.3-1-1.6-1.4c-0.2-0.3-0.5-1.1-0.6-1.5
			c-0.1-0.3-0.1-1.1,0-1.4c0-0.2,0.4-0.7,0.4-0.9c0-0.3-0.3-1-0.2-1.2c0.1-0.2,0.8-0.3,1-0.5c0.1-0.3,0-0.9-0.1-1.2
			c-0.1-0.4-0.5-1.1-0.8-1.4c-0.4-0.4-1.6-0.8-2.1-1.2c-0.4-0.4-1.3-1.2-1.4-1.8c-0.1-0.4,0.5-1.1,0.3-1.4c-0.1-0.2-0.6-0.3-0.8-0.4
			c-0.3-0.1-1-0.2-1.3-0.4c-0.6-0.3-2-0.9-2.3-1.5c-0.2-0.4,0.3-1.4,0.2-1.9c-0.2-0.5-0.9-1.3-1.4-1.5c-0.3-0.1-0.9-0.1-1.2-0.1
			c-0.4,0-1.2,0.2-1.6,0.2c-0.7,0-2.2-0.1-2.8-0.5c-0.3-0.2-0.6-0.8-0.8-1c-0.2-0.2-0.6-0.8-0.8-1c-0.3-0.2-1.1-0.3-1.4-0.6
			c-0.2-0.2-0.4-0.7-0.6-0.8c-0.2-0.2-0.4-0.3-0.7-0.5c-0.4-0.2-0.8-0.5-1-0.7c-0.4-0.4-1.1-1.5-1.4-2c-0.3-0.4-0.9-1.1-1-1.6
			c0-0.3,0.3-0.9,0.5-1.1c0.2-0.2,0.9-0.3,1.2-0.5c0.3-0.2,1.1-0.5,1.1-0.8c0-0.3-0.9-0.4-1.1-0.7c-0.1-0.3,0-0.9,0-1.2
			c0.1-0.3,0.6-0.9,0.5-1.3c-0.1-0.3-0.9-0.6-0.9-0.7c0-0.1,0.3-0.9,0.2-1.2c-0.2-0.4-1.4-0.1-1.8-0.4c-0.2-0.2-0.5-0.7-0.5-0.9
			c0.1-0.4,1.1-0.8,1.3-1.2c0.1-0.3,0.2-0.9,0.1-1.2c-0.1-0.2-0.6-0.6-0.5-0.8c0.1-0.4,1.1-0.5,1.2-0.9c0.1-0.4-0.9-0.9-1-1.3
			c0-0.4,0.7-1,0.6-1.4c-0.1-0.4-1.3-0.6-1.4-1c-0.1-0.2,0.4-0.6,0.4-0.8c0-0.3-0.5-0.7-0.5-1c0-0.3,0.5-0.7,0.4-1
			c0-0.3-0.6-0.6-0.6-0.8c0-0.4,0.8-1.1,0.7-1.6c-0.1-0.4-1-0.9-1-1.3c0-0.2,0.4-0.6,0.5-0.8c0.1-0.2,0.2-0.8,0-1
			c-0.3-0.4-1.4-0.2-1.8-0.6c-0.2-0.2-0.1-0.8-0.4-0.9c-0.2,0-0.4,0.5-0.6,0.5c-0.4-0.1-0.6-1.1-0.6-1.4c0-0.3,0.7-0.8,0.7-1.1
			c-0.1-0.4-0.9-1-1.3-1.1c-0.2-0.1-0.6,0.1-0.8,0c-0.3-0.1-0.6-0.7-0.6-0.9c0-0.2,0.3-0.6,0.5-0.8c0.2-0.1,0.9,0,1-0.2
			c0.1-0.2-0.1-0.7-0.3-0.8c-0.3-0.2-1,0-1.3-0.1c-0.3-0.2-0.5-0.9-0.6-1.2c-0.1-0.5,0.2-1.8-0.1-2.2c-0.2-0.3-0.9-0.4-1.1-0.7
			c-0.2-0.2-0.3-0.8-0.5-1.1c-0.2-0.3-0.8-0.8-1-1.1c-0.1-0.3-0.2-0.9,0-1.2c0.1-0.2,0.8-0.2,0.9-0.4c0.3-0.3,0.4-1.1,0.5-1.4
			c0.2-0.4,0.9-1,0.9-1.4c0-0.4-0.8-0.8-0.8-1.2c0-0.3,0.7-0.8,0.6-1.1c0-0.3-0.5-0.7-0.8-0.7c-0.4-0.1-1.2,0.6-1.5,0.4
			c-0.2-0.2-0.1-0.9,0-1.2c0-0.2,0.3-0.7,0.2-1c-0.1-0.3-0.6-0.6-0.8-0.7c-0.3-0.2-1-0.2-1.3-0.4c-0.2-0.2-0.3-1-0.6-1.1
			c-0.1-0.1-0.4,0.1-0.6,0.1c-0.1,0.1-0.3,0.1-0.4,0.1c-0.4,0-1.2-0.2-1.5-0.5c-0.2-0.3,0-1.2-0.3-1.5c-0.2-0.2-0.8-0.3-1-0.5
			s-1.1-1.2-1.3-1.2c-0.1,0-1.2,0.1-1.2,0c0-0.1-0.6-0.7-0.8-1c-0.2-0.3-0.3-1-0.5-1.2c-0.2-0.2-0.9-0.3-1.2-0.3
			c-0.4,0-1.2,0.4-1.5,0.3c-0.3-0.1-0.8-0.5-1.1-0.7c-0.5-0.3-1.4-1-1.9-1.3c-0.3-0.2-1-0.5-1.3-0.6c-0.5-0.1-1.4,0-1.9-0.2
			c-0.6-0.2-1.6-1-2.1-1.4c-0.5-0.5-1.4-1.6-2-2.1c-0.3-0.3-1.1-0.7-1.3-1c-0.4-0.4-1-1.5-1.2-2c-0.2-0.6-0.3-2,0-2.6
			c0.1-0.3,0.8-0.5,0.9-0.8c0.1-0.4-0.3-1.1-0.4-1.4c-0.2-0.4-0.7-1.2-1.1-1.4c-0.2-0.1-0.6-0.1-0.7,0c-0.2,0.2,0.4,0.9,0.3,1.2
			c-0.1,0.2-0.7,0.4-0.9,0.4c-0.3,0-0.8-0.6-1.1-0.7c-0.2-0.1-0.7,0.1-0.9,0c-0.3-0.2-0.3-1.1-0.5-1.4c-0.2-0.2-0.7-0.5-1-0.6
			c-0.4-0.3-1.2-1-1.6-1.3c-0.4-0.3-1.6-0.5-1.7-0.9c0,0,0,0,0,0c-0.1-0.2,0.3-0.6,0.4-0.7c0.3-0.2,1.1-0.1,1.3-0.4
			c0.2-0.2,0.5-0.9,0.4-1.2c-0.1-0.4-0.6-1-0.9-1.3c-0.3-0.3-0.9-0.9-1.2-1.1c-0.2-0.2-0.8-0.3-0.9-0.6c-0.1-0.2-0.1-0.7,0-0.9
			c0.1-0.2,0.6,0,0.8-0.1c0.2-0.2,0.5-0.8,0.4-1c-0.1-0.3-0.8-0.6-1.2-0.7c-0.3,0-0.8,0.1-1.1,0.2c-0.2,0.1-0.5,0.7-0.7,0.7
			c-0.3,0-0.5-0.5-0.7-0.8c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.4-0.5-1.3-0.9-1.4c-0.4-0.2-1.2,0.4-1.5,0.2c-0.2-0.1-0.5-0.6-0.5-0.9
			c0-0.3,0.6-0.9,0.5-1.3c-0.1-0.3-0.6-0.8-0.9-0.8c-0.3,0-0.7,0.7-1.1,0.6c-0.3,0-0.8-0.5-0.9-0.8c-0.1-0.3,0.1-1,0-1.3
			c-0.1-0.3-0.9-0.9-0.8-1.2c0.2-0.3,0.9,0.1,1.2,0.1c0.3,0,1.2-0.2,1.2-0.3c0-0.1-1.1-1-1.2-1.5c-0.1-0.3,0.4-0.7,0.3-1
			c-0.1-0.3-0.9-0.6-1-0.9c-0.1-0.2-0.1-0.8,0-0.9c0.1-0.1,0.5,0,0.7,0c0.3-0.1,1-0.5,1.1-0.9c0.1-0.4-0.6-1-0.5-1.4
			c0.1-0.3,0.6-0.8,0.8-1c0.2-0.1,0.8-0.1,0.9-0.3c0.2-0.3-0.2-1.2-0.4-1.5c-0.3-0.7-1.2-1.9-1.8-2.3c-0.4-0.3-1.5-0.4-2-0.7
			c-0.4-0.2-1.4-1.2-1.5-1.2c-0.1,0-1.3-0.4-1.6-0.7c-0.4-0.4-0.6-1.7-0.9-2.2c-0.4-0.6-1.3-1.7-1.8-2.3c-0.4-0.5-1.2-1.5-1.6-2
			c-0.1-0.1-0.1-0.1-0.2-0.2c-0.6-0.7-1.6-1.7-2.2-2.3c-0.3-0.3-0.9-0.8-1.2-0.9c-0.5-0.3-1.7-0.6-2.2-0.9c-0.3-0.2-0.9-0.7-1.2-1
			c-0.4-0.4-0.9-1.4-1.4-1.5c-0.3-0.1-0.9,0.5-1.3,0.6c-0.4,0.1-1.2,0.4-1.5,0.2c-0.4-0.1-1-0.8-1.3-1.1c-0.3-0.3-0.7-1.1-1-1.3
			c-0.3-0.2-1.1-0.2-1.5-0.4c-0.4-0.2-1.2-0.8-1.5-1.1c-0.4-0.4-1.1-1.3-1.3-1.9c-0.1-0.4,0-1.4,0-1.8c0-0.1,0-0.2,0-0.2
			c-0.2-0.3-1-0.3-1.2-0.5c-0.2-0.2-0.3-0.7-0.3-1c-0.1-0.2,0-0.7-0.2-0.8c-0.2-0.1-0.7,0.4-0.9,0.4c-0.1,0-0.3-0.4-0.4-0.4
			c-0.3,0-0.6,0.6-0.8,0.8c-0.1,0.2-0.2,0.8-0.4,0.8c-0.3,0.1-1-0.4-1-0.4c-0.1,0-0.6,0.9-0.9,1.2c-0.1,0.1-0.6,0.5-0.6,0.5
			c-0.1-0.1-0.7-0.3-0.8-0.5c-0.1-0.2,0.1-0.6,0.2-0.7c0.2-0.3,0.8-0.5,1.1-0.7c0.1-0.1,0.2-0.4,0.3-0.5c0.1-0.1,0.5-0.2,0.6-0.3
			c0.1-0.1,0.2-0.5,0.1-0.6c-0.1-0.2-0.6-0.2-0.8-0.2c-0.2,0.1-0.5,0.6-0.8,0.6c-0.3,0.1-0.8-0.1-1-0.2c-0.2,0-0.7,0-0.8,0
			c-0.1-0.1-0.6-0.4-0.8-0.5c-0.2-0.1-0.8-0.2-0.9-0.2c-0.1,0-0.4,0.2-0.6,0.2c-0.1-0.1,0-0.5-0.2-0.7c-0.1-0.1-0.4-0.2-0.5-0.2
			c-0.2,0-0.6,0.2-0.8,0.3c-0.2,0.1-0.7,0.1-0.9,0.2c-0.2,0.1-0.5,0.5-0.7,0.6c-0.2,0.1-0.7,0-0.9,0.1c-0.1,0.1,0.1,0.6,0.1,0.7
			c0,0.1-0.1,0.5-0.2,0.6c-0.2,0.1-0.8-0.2-1.1-0.1c-0.1,0-0.4,0.2-0.4,0.3c0,0.1,0.3,1.1,0.2,1.5c0,0.1,0,0.3-0.1,0.5
			c0,0.1-0.1,0.3-0.2,0.3c-0.2,0.1-0.8-0.3-0.8-0.4c0-0.1,0-0.9-0.1-1.1c-0.1-0.1-0.3-0.3-0.4-0.3c-0.3-0.1-0.8,0.1-1,0.2
			c-0.2,0.1-0.4,0.4-0.6,0.4c-0.2,0-0.6-0.4-0.8-0.4c-0.2,0-0.5,0.1-0.6,0.3c-0.1,0.2,0.3,0.6,0.3,0.9c0,0.2-0.1,0.7-0.2,0.7
			c-0.1-0.1-0.6-0.4-0.9-0.4c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.6-0.1,0.7c-0.1,0.2-0.6,0.6-0.7,0.5c-0.1,0-0.6-0.1-0.6-0.2
			c0-0.1,0.2-0.8,0-1c0,0,0,0,0,0c-0.2-0.2-0.7,0-1,0.1c-0.2,0.1-0.5,0.5-0.7,0.7c-0.2,0.2-0.3,0.8-0.4,1c-0.1,0.2-0.5,0.6-0.7,0.6
			c-0.2,0-0.3-0.4-0.5-0.5c-0.2-0.1-0.7,0-0.9,0.1c-0.3,0.1-0.6,0.6-0.9,0.8c-0.2,0.2-0.8,0.4-0.9,0.6c-0.1,0.2-0.1,0.8-0.2,1
			c-0.1,0.2-0.2,0.6-0.4,0.8c-0.2,0.2-0.6,0.4-0.8,0.5c-0.2,0.2-0.5,0.8-0.7,1.1c-0.2,0.3-0.5,0.9-0.7,1.2c-0.1,0.2-0.3,0.5-0.5,0.6
			c-0.2,0-0.7-0.5-0.6-0.6c0.1-0.1,0.1-1,0.3-1.4c0.1-0.3,0.7-0.6,0.9-0.8c0.1-0.2,0.1-0.5,0.3-0.7c0.1-0.1,0.5-0.2,0.5-0.3
			c0-0.1-0.2-0.3-0.3-0.4c-0.3-0.1-0.8,0.5-1.1,0.5c-0.1,0-0.4-0.2-0.4-0.3c0-0.1,0.8-0.4,0.9-0.7c0-0.1-0.3-0.4-0.3-0.5
			c0-0.2,0.5-0.5,0.8-0.6c0.2,0,0.5,0.3,0.7,0.2c0.2,0,0.3-0.4,0.4-0.5c0.2-0.1,0.8-0.2,1-0.3c0.2-0.1,0.7-0.4,0.7-0.6
			c0-0.2-0.2-0.5-0.4-0.6c-0.2-0.2-0.6-0.3-0.8-0.4c-0.2-0.1-0.5-0.4-0.7-0.5c-0.2-0.1-0.6-0.3-0.8-0.2c-0.2,0.1-0.4,0.5-0.4,0.7
			c0,0.1,0,0.5-0.1,0.6c-0.2,0.2-0.8,0-1-0.1c-0.2-0.1-0.3-0.5-0.5-0.5c-0.2,0-0.5,0.3-0.5,0.5c-0.1,0.2,0.1,0.7,0,0.9
			c-0.1,0.1-0.3,0.3-0.5,0.3c-0.2,0-0.7-0.4-0.9-0.3c-0.1,0-0.2,0.3-0.4,0.4c-0.2,0.1-0.7-0.1-0.9-0.1c-0.1,0-0.4,0.1-0.5,0.1
			c-0.2,0-0.5-0.1-0.7-0.1c-0.2,0.1-0.5,0.4-0.6,0.5c-0.1,0.1-0.3,0.5-0.4,0.6c-0.2,0.2-0.7,0.4-1,0.4c-0.2,0-0.5-0.2-0.7-0.1
			c-0.2,0-0.5,0.4-0.7,0.5c-0.2,0.1-0.5,0.2-0.7,0.1c0,0,0,0-0.1,0c-0.2-0.2-0.5-0.7-0.5-0.7c0-0.1,0.6-0.9,0.7-1.3
			c0.1-0.2,0-0.6,0.1-0.7c0.1-0.2,0.7-0.4,0.9-0.5c0.3-0.1,0.9-0.2,1.1-0.5c0.1-0.2,0-0.6,0-0.8c0.1-0.2,0.3-0.6,0.5-0.8
			c0.1-0.1,0.4-0.2,0.6-0.2c0.2,0,0.7,0.2,0.9,0.3c0.1,0.1,0,0.4,0.1,0.5c0.1,0.1,0.6-0.1,0.7,0c0.2,0.1,0.3,0.5,0.5,0.5
			c0.2,0,0.5-0.1,0.6-0.2c0.1-0.1-0.1-0.5,0-0.6c0-0.1,0.2-0.1,0.3-0.1c0.1,0.1,0.1,0.5,0.2,0.6c0.1,0.1,0.4,0.2,0.6,0.2
			c0.2,0,0.5,0,0.6-0.1c0.2-0.1,0.2-0.6,0.4-0.8c0.1-0.2,0.5-0.4,0.7-0.4c0.2-0.1,0.7,0.1,0.9,0c0.2-0.1,0.4-0.5,0.6-0.5
			c0.2-0.1,0.7-0.1,0.9,0c0.5,0.2,1,1.2,1.4,1.4c0.2,0.1,0.7,0.2,0.9,0.2c0,0,0,0,0,0c0.2,0.1,0.6,0.2,0.8,0.3
			c0.2,0.1,0.6,0.3,0.8,0.3c0.3,0,0.7-0.3,0.9-0.5c0.2-0.2,0.2-0.6,0.4-0.8c0.2-0.1,0.6,0,0.8-0.1c0.3-0.1,0.9-0.1,1.1-0.3
			c0.2-0.2,0.2-0.7,0.1-0.9c-0.1-0.1-0.5-0.2-0.5-0.4c-0.1-0.2,0-0.6,0.1-0.7c0.1-0.1,0.5-0.1,0.6,0c0.3,0.1,0.7,0.5,1,0.5
			c0.2,0,0.6-0.4,0.7-0.5c0.2-0.2,0.6-0.6,0.8-0.7c0.3-0.1,1,0.1,1.4,0c0.2-0.1,0.5-0.5,0.7-0.5c0.3-0.1,1.2,0.3,1.4,0
			c0.1-0.2-0.2-0.6-0.4-0.7c-0.2-0.2-0.6-0.6-0.8-0.8c-0.2-0.1-0.7-0.1-0.9-0.1c-0.2-0.1-0.6-0.2-0.7-0.4c-0.1-0.1-0.2-0.3-0.2-0.4
			c0-0.2,0.3-0.7,0.5-0.8c0.1-0.1,0.5-0.1,0.6-0.1c0.2,0.1,0.5,0.4,0.7,0.6c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.1,0.8,0,1.1,0.1
			c0.2,0.1,0.6,0.3,0.7,0.3c0.1,0,0.4-0.5,0.6-0.5c0.3-0.1,1,0.4,1.4,0.2c0.2-0.1,0.3-0.5,0.4-0.6c0.3-0.1,0.9,0.2,1.1,0
			c0.2-0.2,0-0.7-0.2-0.9c-0.2-0.3-1-0.4-1.2-0.6c-0.2-0.1-0.6-0.5-0.6-0.7c0-0.2,0.4-0.6,0.6-0.8c0.1-0.1,0.3-0.5,0.5-0.5
			c0.2,0,0.6,0.5,0.6,0.6c0,0.1,0.3,0.8,0.4,0.8c0.1,0,0.7,0.7,1.1,0.7c0.3,0,0.8-0.4,1.1-0.3c0.3,0,0.8,0.4,0.9,0.7
			c0.1,0.2,0,0.7,0.1,0.9c0.1,0.2,0.4,0.7,0.7,0.7c0.1,0,0.4-0.2,0.5-0.3c0.1-0.1,0-0.5,0.2-0.6c0.1-0.1,0.5,0.1,0.6,0.2
			c0.2,0.2,0,0.8,0.1,1.1c0,0.1,0.2,0.4,0.3,0.4c0.3,0.1,1.1-0.3,1.4-0.4c0.2-0.1,0.8-0.2,0.9-0.5c0.2-0.3,0.3-1.2,0.1-1.4
			c-0.2-0.1-0.7,0.2-0.9,0.1c-0.2,0-0.6-0.4-0.6-0.5c0-0.1,0-0.6,0.1-0.7c0.1-0.1,0.4-0.2,0.6-0.2c0.2,0,0.6,0.2,0.8,0.2
			c0.1-0.1,0.3-0.3,0.4-0.5c0-0.2-0.2-0.5-0.3-0.6c-0.2-0.2-0.6-0.6-0.9-0.6c-0.2,0-0.6,0.4-0.9,0.4c-0.2,0-0.5,0-0.6-0.1
			c-0.1-0.1-0.4-0.4-0.4-0.5c0-0.2,0.5-0.2,0.6-0.3c0.1-0.2,0.1-0.5,0-0.7c-0.1-0.2-0.5-0.4-0.5-0.6c0-0.2,0.5-0.5,0.5-0.8
			c0-0.3-0.3-0.9-0.5-1.1c-0.2-0.2-0.6-0.6-0.9-0.7c-0.2-0.1-0.5,0.1-0.7,0c-0.3-0.1-0.8-0.6-1.1-0.6c-0.2,0-0.5,0.1-0.6,0.3
			c-0.1,0.1,0,0.5-0.1,0.6c-0.2,0.1-0.6,0.1-0.8,0c-0.1,0-0.3-0.3-0.5-0.4c-0.2-0.1-0.7,0.2-0.9,0.1c-0.1-0.1-0.4-0.3-0.4-0.5
			c0-0.2,0.4-0.7,0.6-0.7c0.3-0.1,0.9,0.5,1.2,0.4c0.1,0,0.1-0.4,0.2-0.5c0.2-0.2,1,0.2,1.2-0.1c0.1-0.2-0.1-0.8-0.3-1
			c-0.1-0.1-0.5-0.1-0.6-0.2c-0.1-0.1-0.1-0.7-0.3-0.7c-0.1,0-0.3,0.3-0.5,0.3c-0.2,0-0.7,0-0.9-0.2c-0.1-0.2,0.2-0.6,0.1-0.7
			c0-0.2-0.3-0.5-0.5-0.5c-0.2,0-0.5,0.4-0.7,0.4c-0.2,0-0.5-0.1-0.7-0.1c-0.2,0.1-0.4,0.5-0.6,0.7c-0.1,0.1-0.3,0.4-0.5,0.4
			c-0.2,0-0.1-0.6-0.3-0.6c-0.1-0.1-0.5,0.1-0.6,0.2c-0.1,0.1-0.2,0.5-0.4,0.5c-0.2,0-0.6-0.4-0.6-0.6c-0.1-0.2-0.1-0.7,0.1-0.9
			c0.2-0.2,0.8,0,1-0.2c0.2-0.1,0.2-0.6,0.4-0.7c0.3-0.1,1,0.2,1.3,0.2c0.3,0,0.9,0,1-0.2c0.1-0.2-0.2-0.5-0.4-0.6
			c-0.2-0.1-0.7,0-0.8-0.1c-0.2-0.2,0.1-0.9-0.1-1.1c-0.2-0.2-0.8-0.5-1-0.4c-0.2,0.1-0.2,0.6-0.3,0.8c-0.2,0.2-0.6,0.6-0.9,0.7
			c-0.2,0-0.6-0.3-0.9-0.3c-0.2,0-0.4,0.4-0.5,0.5c-0.2,0-0.6-0.1-0.7-0.2c-0.1,0-0.3-0.2-0.4-0.2c-0.2,0.1-0.5,0.6-0.6,0.7
			c-0.1,0.1-0.6,0.4-0.9,0.4c-0.3,0.1-0.9,0.1-1.2,0.3c-0.2,0.1-0.3,0.7-0.5,0.7c-0.2,0-0.7-0.3-0.8-0.6c0-0.2,0.4-0.6,0.6-0.7
			c0.2-0.1,0.7,0,0.9-0.1c0.2-0.1,0.4-0.7,0.3-0.9c-0.1-0.3-0.9-0.5-1.2-0.5c-0.2,0-0.5,0.2-0.6,0.2c-0.3,0-0.7-0.5-1-0.5
			c-0.4-0.1-1.2-0.2-1.5-0.1c-0.2,0.1-0.4,0.5-0.6,0.5c-0.3,0.1-1.2,0.1-1.3-0.2c-0.2-0.3,0.5-1.1,0.8-1.3c0.4-0.3,1.3-0.3,1.8-0.4
			c0.4-0.1,1.1-0.3,1.5-0.4c0.3,0,0.9-0.1,1.2,0c0.2,0.1,0.5,0.6,0.7,0.8c0.2,0.3,0.4,1.1,0.7,1.2c0.3,0,0.6-0.7,0.9-0.8
			c0.3-0.1,0.9,0.2,1.2,0.2c0.4,0.1,1.1,0.3,1.5,0.2c0.2-0.1,0.5-0.7,0.5-0.9c-0.1-0.3-0.6-0.6-0.9-0.7c-0.2-0.1-0.8,0.1-1,0
			c-0.3-0.1-0.4-0.8-0.6-1c-0.2-0.2-0.7-0.4-1-0.5c-0.2,0-0.7,0.1-0.9,0.1c-0.3,0-0.9,0.1-1.2,0c-0.2,0-0.7-0.2-0.8-0.4
			c-0.2-0.3-0.1-1.1-0.4-1.4c-0.1-0.2-0.5-0.3-0.7-0.4c-0.2,0-0.6,0-0.8,0c-0.3-0.1-0.7-0.5-1-0.5c-0.3,0-0.8,0.2-1,0.3
			c-0.2,0.1-0.3,0.5-0.4,0.6c-0.2,0.3-0.5,0.9-0.7,1c-0.2,0.1-0.7,0-0.9-0.1c-0.2-0.2-0.4-0.9-0.4-1.2c0-0.3,0.4-0.7,0.6-0.9
			c0.2-0.2,0.6-0.7,0.9-0.7c0.3-0.1,0.9,0.5,1.2,0.3c0.1-0.1,0.2-0.5,0.1-0.6c-0.1-0.3-0.4-0.8-0.7-1.1c-0.2-0.2-0.6-0.3-0.8-0.5
			c-0.2-0.1-0.7-0.4-0.9-0.6c-0.2-0.2-0.4-0.8-0.6-1c-0.2-0.2-0.8-0.2-1-0.4c-0.2-0.2,0.1-0.9-0.1-1.1c-0.1-0.1-0.5-0.1-0.6,0
			c-0.2,0.1-0.4,0.4-0.6,0.6c-0.2,0.2-0.6,0.5-0.6,0.7c0,0.2,0.4,0.6,0.4,0.9c0,0.2-0.2,0.6-0.3,0.8c-0.1,0.2-0.4,0.6-0.6,0.6
			c-0.2,0-0.8-0.3-0.8-0.4c0,0,0-0.7-0.2-0.8c-0.2-0.1-0.6,0.2-0.7,0.2c0-0.1-0.1,0.7-0.2,0.9c-0.2,0.2-0.7,0.1-1,0.1
			c-0.3,0.1-0.8,0.4-1,0.6c-0.1,0.2-0.2,0.7-0.2,1c0,0.3,0.2,0.8,0.3,1.1c0.1,0.4,0.7,1,0.8,1.4c0,0.4-0.3,1-0.5,1.4
			c-0.1,0.2-0.3,0.7-0.4,1c-0.1,0.2-0.5,0.6-0.7,0.8c-0.2,0.2-0.6,0.6-0.8,0.7c-0.1,0.1-0.3,0.1-0.5,0.2c-0.1,0-0.3,0.1-0.4,0
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.4,0-1.2,0.1-1.6c0.1-0.3,0.6-0.6,0.8-0.8c0.2-0.2,0.7-0.7,0.8-1c0.1-0.3,0.1-1,0-1.3
			c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.2-0.4-0.5-0.5-0.7c-0.1-0.5-0.2-1.4-0.1-1.9c0.1-0.3,0.6-0.9,0.9-1.1c0.3-0.2,1-0.4,1.2-0.7
			c0.1-0.2-0.1-0.8,0.1-1c0.1-0.1,0.3-0.2,0.5-0.1c0.2,0,0.5,0.4,0.7,0.5c0.1,0,0.5,0,0.6,0c0.2-0.1,0.2-0.6,0.3-0.7
			c0.1-0.1,0.5,0,0.6-0.1c0.2-0.1,0.4-0.7,0.6-0.9c0.2-0.2,0.8-0.3,0.8-0.5c0-0.2-0.2-0.5-0.3-0.6c-0.2-0.1-0.7,0-1,0.1
			c-0.2,0.1-0.5,0.4-0.7,0.4c-0.3,0-0.8-0.6-0.9-0.7c-0.1-0.1-0.6-0.8-0.9-0.9c-0.2-0.1-0.7,0.2-0.9,0.3c-0.2,0.2-0.4,0.9-0.4,1.2
			c0,0.2,0,0.8,0,0.8c-0.1,0-0.6-0.6-0.8-0.6c-0.2,0-0.7,0.6-0.8,0.6c-0.1,0-1-0.1-1.3-0.3c-0.1-0.1-0.3-0.5-0.2-0.7
			c0.1-0.2,0.8,0,1.1-0.1c0.3-0.1,0.9-0.4,0.9-0.6c0-0.1-0.2-0.3-0.4-0.3c-0.2-0.1-0.7-0.2-0.9-0.1c-0.2,0-0.5,0.3-0.7,0.4
			c-0.2,0-0.8,0.1-0.9,0c-0.1-0.2,0.1-0.6,0.3-0.7c0.3-0.3,1-0.4,1.4-0.4c0.3,0,0.9,0.3,1.2,0.2c0.2-0.1,0.5-0.3,0.6-0.5
			c0.1-0.2,0.1-0.7,0-0.8c-0.2-0.2-0.9-0.2-1.1-0.4c-0.2-0.2-0.5-0.9-0.7-0.9c-0.2,0-0.5,0.2-0.7,0.2c-0.3,0-1-0.1-1.3-0.2
			c-0.1,0-0.3-0.1-0.4-0.3c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.2,0.5-0.4,0.7-0.4c0.3-0.1,0.9-0.2,1.2-0.1c0.2,0,0.5,0.3,0.7,0.3
			c0.2,0,0.5,0,0.7,0c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.4,0.4,0.6,0.4c0.2,0,0.5-0.1,0.6-0.3c0.1-0.2,0.1-0.7,0-0.8
			c-0.1-0.2-0.7-0.4-0.9-0.5c-0.2-0.1-0.8-0.3-0.9-0.5c-0.2-0.3,0.2-1.1,0.1-1.4c0-0.3-0.1-0.9-0.3-1.2c-0.2-0.2-0.8-0.4-0.9-0.7
			c-0.1-0.2,0.3-0.6,0.3-0.8c0-0.3-0.3-0.9-0.4-1.2c-0.1-0.3-0.5-0.9-0.7-1.1c-0.2-0.1-0.7-0.4-0.7-0.4c-0.1,0-0.9-0.7-1-0.8
			c0-0.1-0.3-1.1-0.6-1.3c-0.2-0.2-0.7-0.3-1-0.4c-0.2-0.2-0.5-0.6-0.8-0.8c-0.3-0.2-1-0.1-1.3-0.2c-0.3-0.1-0.9-0.4-1.2-0.4
			c-0.4-0.1-1.2-0.3-1.6-0.1c-0.1,0-0.3,0.3-0.4,0.4c-0.2,0.1-0.7-0.1-0.9,0c-0.2,0.1-0.5,0.5-0.7,0.5c-0.2,0.1-0.8-0.1-0.9-0.2
			c-0.1-0.1-0.2-0.5-0.1-0.6c0.2-0.2,0.8,0.2,1,0c0.1-0.1,0.1-0.5,0-0.6c-0.2-0.3-1.1-0.5-1.5-0.6c-0.2-0.1-0.7-0.3-0.9-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.3-0.5-0.5-0.6c-0.2-0.1-0.7-0.2-1-0.3c-0.4-0.2-0.9-1.1-1.2-1.3c-0.3-0.2-1-0.4-1.3-0.4
			c-0.2,0-0.5,0.3-0.6,0.3c-0.4,0.1-1.3,0.1-1.6-0.1c-0.1-0.1-0.5-0.4-0.4-0.5c0.1-0.4,1-0.7,1.4-0.8c0.3-0.1,0.9-0.1,1.2-0.1
			c0.4,0,1.3-0.1,1.6,0.2c0.2,0.1,0.1,0.6,0.2,0.8c0.2,0.2,0.6,0.3,0.8,0.4c0.2,0.1,0.4,0.4,0.6,0.5c0.1,0.1,0.4,0.5,0.5,0.6
			c0.3,0.2,1.1,0.4,1.2,0.5c0.1,0,0.7-0.4,1-0.3c0.1,0.1,0.2,0.4,0.2,0.6c0.1,0.2,0,0.8,0.2,1c0.1,0.1,0.4,0.1,0.5,0
			c0.1-0.1,0.1-0.5,0.2-0.6c0.1-0.1,0.6-0.2,0.7-0.1c0.2,0.1,0.2,0.9,0.4,1.1c0.3,0.3,1.3,0.7,1.6,0.4c0.1-0.1,0-0.5-0.1-0.7
			c-0.1-0.2-0.8-0.4-0.9-0.7c0-0.2,0.2-0.5,0.4-0.6c0.3-0.1,0.9,0.2,1.1,0.4c0.2,0.2,0.5,0.7,0.7,0.9c0.2,0.2,0.5,0.5,0.7,0.6
			c0.2,0.1,0.7,0,0.9-0.2c0.1-0.1,0.2-0.5,0.2-0.7c-0.1-0.2-0.5-0.6-0.7-0.7c-0.1-0.1-0.5-0.2-0.6-0.3c-0.2-0.1-0.7-0.4-0.8-0.6
			c-0.2-0.3-0.1-1.1-0.2-1.4c-0.2-0.4-0.8-1-1.1-1.2c-0.3-0.2-1-0.4-1.4-0.5c-0.3-0.1-0.9-0.3-1.2-0.2c-0.2,0.1-0.6,0.5-0.7,0.7
			c-0.1,0.2,0.1,0.6,0,0.7c-0.2,0.1-0.5-0.4-0.6-0.4c-0.2-0.1-0.6,0-0.8-0.1c-0.2-0.1-0.6-0.5-0.5-0.7c0.1-0.2,0.5-0.1,0.6-0.2
			c0.2-0.2,0.5-0.7,0.4-0.9c0-0.2-0.5-0.5-0.7-0.6c-0.3-0.1-0.8,0-1.1,0c-0.2-0.1-0.8-0.4-0.7-0.6c0.1-0.2,0.9,0,0.9-0.1
			c0-0.1,0-0.5-0.1-0.6c-0.1-0.1-0.5-0.2-0.6-0.3c-0.1-0.2-0.4-0.6-0.3-0.8c0.1-0.2,0.5-0.2,0.7-0.2c0.2,0,0.5,0.2,0.7,0.3
			c0.3,0.2,0.5,0.9,0.8,1.1c0.1,0,0.3,0,0.3,0c0.1-0.1,0.1-0.5,0.1-0.6c-0.1-0.4-0.3-1.1-0.6-1.4c-0.2-0.2-0.8-0.4-1-0.6
			c-0.3-0.2-0.8-0.9-1.1-1.1c-0.2-0.1-0.6,0.2-0.7,0.1c-0.2-0.1-0.3-0.5-0.4-0.7c-0.2-0.1-0.7-0.1-0.9-0.3c-0.3-0.2-0.6-0.7-0.5-1.1
			c0-0.2,0.1-0.4,0.3-0.5c0.3-0.1,0.9,0,1.2,0.1c0.2,0.1,0.5,0.5,0.7,0.6c0.2,0.2,0.7,0.6,1,0.6c0.2,0,0.8-0.2,0.8-0.4
			c0.1-0.3-0.3-0.8-0.5-1.1c-0.1-0.2-0.5-0.5-0.6-0.7c-0.1-0.3-0.1-0.9,0-1.2c0.1-0.2,0.5-0.5,0.6-0.7c0.2-0.4,0.4-1.4,0.4-1.9
			c0-0.6-0.2-1.9-0.4-2.5c-0.2-0.5-0.6-1.4-0.9-1.7c-0.3-0.4-1.3-0.7-1.8-1.1c-0.1,0-0.1-0.1-0.2-0.1c-0.4-0.4-0.9-1.4-1.2-1.9
			c-0.2-0.5-0.5-1.5-0.7-2c-0.1-0.5-0.1-1.7-0.4-2.2c-0.2-0.3-0.7-0.6-0.9-0.8c-0.2-0.2-0.6-0.7-0.8-0.9c-0.1-0.1-0.3-0.4-0.4-0.4
			c-0.2,0.1-0.1,0.5-0.2,0.6c0,0.2,0,0.6-0.2,0.7c-0.1,0.1-0.3-0.1-0.5-0.1c-0.2,0-0.7,0.2-0.9,0.1c-0.1,0-0.3-0.2-0.4-0.3
			c0-0.2,0.4-0.4,0.5-0.6c0-0.2-0.2-0.5-0.3-0.6c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0.2-0.3,0.3c-0.1,0.2-0.1,0.6-0.3,0.7
			c-0.2,0.1-0.6-0.2-0.7-0.3c0-0.1-0.3-0.5-0.4-0.7c0-0.2,0.2-0.5,0.3-0.6c0.1-0.1,0.5-0.2,0.5-0.3c0-0.1-0.1-0.4-0.3-0.5
			c-0.2-0.1-0.5,0.3-0.7,0.3c-0.2,0-0.4-0.5-0.6-0.6c-0.1-0.1-0.5-0.1-0.6,0c-0.2,0.2,0.2,0.7,0.1,1c0,0.2-0.3,0.4-0.4,0.5
			c-0.1,0.1-0.3,0.4-0.4,0.4c-0.2,0-0.5-0.1-0.6-0.2c-0.2-0.2-0.3-0.7-0.2-1c0.1-0.2,0.5-0.5,0.5-0.6c0-0.1,0-0.6,0.1-0.8
			c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.1,0.6-0.4,0.9-0.4c0.3,0,0.8,0.3,1,0.5c0.1,0.1,0.2,0.5,0.4,0.5c0.2,0,0.3-0.5,0.4-0.6
			c0.1-0.2,0.5-0.4,0.6-0.6c0.1-0.1,0.1-0.5,0.1-0.6c-0.1-0.1-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.5-0.4-0.7c-0.1-0.1-0.5-0.2-0.7-0.2
			c-0.3,0.1-0.3,0.8-0.6,1c-0.1,0.1-0.5-0.1-0.6-0.1c-0.2,0.1-0.6,0.5-0.8,0.6c-0.2,0.1-0.7,0.2-0.8,0c-0.1-0.1,0.3-0.4,0.3-0.6
			c0-0.1-0.3-0.3-0.4-0.4c-0.2,0-0.6,0.2-0.8,0.3c-0.2,0.1-0.4,0.5-0.5,0.6c-0.1,0.2-0.4,0.5-0.7,0.5c-0.2,0-0.6-0.3-0.8-0.4
			c-0.2,0-0.7-0.1-0.8,0c-0.3,0.1-0.5,0.7-0.7,0.9c-0.2,0.2-0.8,0.6-1,0.8c-0.2,0.2-0.4,0.7-0.6,0.9c-0.2,0.1-0.6,0.1-0.8,0
			c-0.2-0.1-0.6-0.6-0.6-0.9c0-0.2,0.1-0.7,0.3-0.8c0.1-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.5-0.4,0.5-0.6c0.1-0.2-0.1-0.6-0.1-0.8
			c0.1-0.2,0.4-0.6,0.6-0.7c0.2-0.1,0.7-0.2,0.9-0.1c0.3,0,0.8,0.5,1.1,0.5c0.2,0,0.6,0,0.8,0c0.2,0,0.8-0.1,0.9-0.3
			c0-0.2-0.3-0.5-0.4-0.5c-0.2-0.1-0.7,0.1-1,0c-0.2,0-0.6-0.1-0.7-0.3c-0.1-0.1-0.1-0.4,0-0.6c0.1-0.2,0.5-0.3,0.7-0.3
			c0.3,0,0.7,0.5,1,0.5c0.2,0,0.6-0.3,0.8-0.3c0.2,0,0.7,0.1,0.7,0.1c0,0.1,0.4,0.5,0.6,0.5c0.1,0,0.4-0.1,0.5-0.2
			c0.1-0.1,0-0.5-0.1-0.6c-0.1-0.2-0.7-0.2-0.9-0.4c-0.1-0.1-0.1-0.4-0.2-0.5c-0.1-0.1-0.6-0.1-0.7-0.3c-0.1-0.2,0-0.6,0.1-0.8
			c0.1-0.2,0.6-0.2,0.8-0.2c0.2,0,0.5,0.3,0.6,0.5c0.1,0.1,0.1,0.6,0.3,0.7c0.1,0.1,0.5,0,0.7,0c0.3,0.1,0.8,0.4,1,0.5
			c0.2,0.1,0.5,0.2,0.6,0.2c0.2,0,0.4-0.3,0.5-0.5c0.1-0.2,0.1-0.6,0-0.8c-0.1-0.2-0.2-0.6-0.4-0.7c-0.2-0.1-0.9,0-1.1,0
			c-0.2-0.1-0.6-0.2-0.6-0.4c0-0.2,0.3-0.6,0.5-0.7c0.1-0.2,0.6-0.6,0.6-0.9c0-0.2-0.4-0.5-0.5-0.7c-0.1-0.2-0.2-0.6-0.3-0.8
			c-0.1-0.2-0.4-0.6-0.6-0.7c-0.1-0.1-0.5,0-0.6,0.1c-0.2,0-0.6,0.2-0.7,0.3c-0.1,0.1-0.3,0.4-0.4,0.5c-0.2,0-0.5-0.1-0.6-0.2
			c-0.1-0.1-0.1-0.4,0-0.5c0.1-0.2,0.6-0.4,0.7-0.6c0-0.2-0.1-0.5-0.2-0.7c-0.3-0.3-1.1-0.3-1.4-0.3c-0.2,0-0.6-0.1-0.8,0
			c-0.2,0.1-0.3,0.5-0.4,0.6c-0.1,0.1-0.4,0.2-0.5,0.3c-0.2,0.2-0.3,0.7-0.6,0.8c-0.1,0-0.5-0.2-0.5-0.2c0-0.1,0.4-0.8,0.3-1
			c0-0.1-0.3-0.3-0.4-0.3c-0.2,0-0.5,0.2-0.6,0.3c-0.2,0.1-0.3,0.5-0.5,0.6c-0.1,0.1-0.5,0-0.6-0.1c-0.2-0.1-0.5-0.6-0.8-0.7
			c-0.2,0-0.5,0-0.6,0.1c-0.2,0.2-0.1,0.9-0.2,1.2c0,0.1-0.2,0.3-0.2,0.4c-0.1,0.2,0,0.6-0.2,0.7c-0.2,0.1-0.8,0-0.8-0.1
			c-0.1-0.1-0.5-0.5-0.5-0.8c0-0.2,0.5-0.3,0.6-0.5c0.1-0.2,0-0.6-0.1-0.7c-0.1-0.1-0.5,0-0.6,0.1c-0.2,0.1-0.4,0.5-0.6,0.6
			c-0.2,0.1-0.6,0.4-0.8,0.4c-0.2,0-0.5-0.4-0.6-0.3c-0.2,0-0.4,0.5-0.5,0.6c-0.2,0.1-0.5,0.5-0.7,0.4c-0.2-0.1-0.2-0.6-0.4-0.8
			c-0.1-0.1-0.4,0-0.5-0.1c-0.1,0-0.1-0.2-0.1-0.3c0-0.2-0.1-0.5,0-0.7c0.1-0.2,0.6-0.4,0.6-0.5s-0.2-0.7-0.3-0.9
			c-0.2-0.2-0.6-0.6-0.9-0.7c-0.2-0.1-0.6-0.3-0.8-0.2c0,0,0,0,0,0c-0.2,0-0.5,0.2-0.6,0.3c-0.1,0.1-0.4,0.3-0.5,0.4
			c-0.2,0.1-0.7,0.1-0.9,0.1c-0.1,0-0.3-0.3-0.4-0.3c-0.2-0.1-0.6-0.3-0.8-0.4c-0.3-0.1-0.9-0.1-1.2,0.1c-0.1,0.1-0.1,0.3-0.2,0.4
			c-0.2,0.2-0.8,0.5-1.1,0.5c-0.2,0-0.8-0.3-0.8-0.4c0-0.1,0-0.7-0.2-0.8c-0.1-0.1-0.4,0.1-0.4,0c-0.1,0-0.5-0.2-0.6-0.4
			c-0.1-0.2-0.1-0.6,0-0.8c0.1-0.2,0.7-0.3,0.9-0.3c0.2,0,0.5,0.4,0.6,0.5c0.1,0.1,0.5,0,0.6,0.1c0.2,0.1,0.5,0.5,0.7,0.5
			c0.2,0,0.6-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.6-0.3c0.3-0.1,0.9,0,1.1,0.1c0.1,0.1,0.2,0.5,0.4,0.5c0.1,0.1,0.5,0.2,0.7,0.1
			c0.1-0.1,0.1-0.5,0-0.7c-0.1-0.2-0.4-0.5-0.5-0.6c-0.2-0.1-0.6-0.2-0.8-0.3c-0.3-0.1-0.9-0.7-1-0.8c-0.1,0-0.3-0.6-0.4-0.8
			c-0.1-0.3-0.1-1.1-0.3-1.3c-0.1-0.1-0.5-0.1-0.7-0.1c-0.2,0.1-0.4,0.5-0.5,0.6c-0.2,0.2-0.6,0.9-0.6,0.9c-0.1,0-0.6,0-0.8-0.1
			c-0.2-0.1-0.5-0.4-0.7-0.4c-0.2,0-0.5,0-0.7,0c-0.1,0-0.3,0-0.4,0c-0.2-0.1-0.6-0.3-0.6-0.5c0-0.2,0.4-0.4,0.6-0.5
			c0.3-0.1,0.9,0.1,1.2,0c0.2-0.1,0.7-0.4,0.8-0.6c0.1-0.2,0.2-0.8,0.1-1c-0.1-0.2-0.4-0.5-0.6-0.6c-0.1-0.1-0.4-0.1-0.5-0.2
			c-0.2-0.2-0.3-0.8-0.5-0.9c-0.2-0.2-1-0.4-1.1-0.5c-0.1,0-0.6-0.3-0.8-0.5c-0.1-0.2,0-0.7-0.2-0.8c-0.2-0.2-0.8-0.3-1.1-0.3
			c-0.3,0-0.7,0.5-1,0.5c-0.2,0-0.6-0.4-0.8-0.5c-0.3-0.2-1.2-0.5-1.3-0.8c-0.1-0.2,0.1-0.6,0.2-0.7c0.3-0.2,1.1,0.4,1.4,0.4
			c0.2,0,0.5,0,0.6-0.2c0.1-0.2,0-0.8,0-0.8c0-0.1-0.4-0.6-0.4-0.8c-0.1-0.3,0.3-0.9,0.1-1.1c-0.2-0.3-1-0.4-1.3-0.3
			c-0.2,0-0.4,0.4-0.5,0.5c-0.3,0.2-0.8,0.5-1.1,0.5c-0.3,0-0.8-0.7-1-0.9c-0.2-0.1-0.6-0.4-0.8-0.5c-0.5-0.2-2-0.1-2.1-0.3
			c-0.1-0.1-0.6-0.9-0.8-1.2c-0.4-0.5-1.4-1.1-1.9-1.6c-0.4-0.4-1-1.2-1.4-1.6c-0.3-0.3-1.1-0.8-1.4-1.2c-0.3-0.3-0.7-1-1-1.2
			c-0.2-0.1-0.7-0.1-0.9-0.2c-0.3-0.1-0.8-0.6-1.2-0.7c-0.3-0.1-0.8-0.2-1.1-0.3c-0.2-0.1-0.7-0.1-0.8-0.3c0-0.1-0.1-0.2-0.1-0.2
			c0-0.4,0.4-1.1,0.2-1.5c-0.1-0.3-0.9-0.3-1.1-0.6c0-0.1,0-0.2,0-0.4c0.1-0.3,0.2-0.5,0.2-0.7c0-0.2-0.3-0.5-0.5-0.7
			c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1-0.4-0.1-1.2,0.1-1.6c0.2-0.3,0.9-0.7,1.2-0.9c0.4-0.4,1.4-1,1.8-1.5c0.3-0.4,0.5-1.3,0.6-1.8
			c0.1-0.5-0.1-1.4-0.1-1.8c0-0.5,0.2-1.9,0.2-2c0-0.1,1.1-0.5,1.3-0.8c0.2-0.3-0.1-1.2,0.1-1.5c0.2-0.2,0.7-0.4,1-0.4
			c0.5-0.1,1.4,0.3,1.9,0.2c0.4-0.1,1.3-0.8,1.4-1.3c0-0.4-0.9-0.9-0.9-1.4c0-0.3,0.3-0.9,0.5-1.1c0.4-0.2,1.7,0.6,2,0.2
			c0.2-0.2-0.3-0.9-0.3-1.2c0-0.4,0.4-1.3,0.7-1.6c0.2-0.4,0.8-1.1,1.2-1.3c0.4-0.2,1.3,0.1,1.7-0.1c0.4-0.1,1-0.7,1.3-1
			c0.3-0.3,0.7-1.1,1.1-1.3c0.3-0.2,1.2-0.3,1.4-0.7c0.3-0.4,0-1.5,0.4-1.8c0.2-0.1,0.7,0.2,1,0.1c0.2-0.1,0.3-0.5,0.4-0.8
			c0.1-0.1,0.1-0.3,0.2-0.3c0.2-0.1,0.7,0,0.9,0c0.3,0,1,0.2,1.3,0.1c0.4-0.1,0.8-0.9,1.1-1.2c0.2-0.1,0.7-0.2,0.8-0.5
			c0-0.2-0.6-0.5-0.6-0.8c0-0.5,1.2-0.9,1.3-1.4c0.1-0.3-0.4-0.9-0.4-1.2c0-0.3,0.4-1,0.5-1.3c0.2-0.4,1.1-0.9,1.1-1.4
			c0-0.4-0.8-1.2-0.8-1.2l-10.5,0.1l-6.6,0.1l-4.1,0l-23.9,0.2l-39.3,0.3L180.5,18l-7.5,0.1l-42.2,0.4l-19,0.2L95,18.8L67.6,19
			L64,19l-7.7,0.1l-24.6,0.2l7.1,43.5l0.7,4.5l6,36.8l4.1,25.3l3.6,22l4.8,29.5l4.5,27.7l6,36.4l0.6,3.4l1.8,10.8
			c0.4,0.1,1,0.2,1.2,0.1c0.3-0.1,0.9-0.4,1-0.7c0.2-0.5,0-1.6-0.3-2.1c-0.1-0.3-0.8-0.5-0.9-0.8c-0.2-0.4,0.1-1.2,0.1-1.6
			c0-0.4,0-1.3-0.3-1.7c-0.1-0.2-0.6-0.2-0.7-0.4c-0.2-0.2-0.6-0.8-0.6-1.1c0-0.3,0.3-1,0.5-1.1c0.3-0.1,0.8,0.5,1,0.7
			c0.2,0.2,0.3,0.6,0.5,0.7c0.2,0.1,0.8,0,0.9,0.2c0.3,0.3,0.4,1.2,0.5,1.6c0.1,0.4-0.1,1.4,0,1.8c0.1,0.4,0.4,1.1,0.5,1.4
			c0.1,0.3,0.1,1,0.1,1.3c0,0.3-0.4,0.8-0.4,1.1c0,0.3,0.2,1,0.4,1.1c0.2,0.1,0.6-0.4,0.8-0.6c0.2-0.2,0.3-0.9,0.4-1.2
			c0.2-0.2,0.7-0.4,0.9-0.6c0.1-0.2,0.1-0.7,0-0.9c0-0.2-0.3-0.6-0.4-0.8c0-0.4,0.1-1.1,0.3-1.4c0.1-0.3,0.6-0.8,0.9-1
			c0.2-0.1,0.8-0.2,0.9-0.4c0.2-0.3-0.1-1-0.2-1.4c0-0.2-0.3-0.6-0.3-0.7c0-0.3,0.4-1,0.6-1.2c0.2-0.1,0.6,0,0.8-0.1
			c0.2-0.2,0.5-0.7,0.5-0.9c0-0.3-0.5-1.1-0.5-1.2c0-0.1-0.2-0.8,0-1c0.1-0.1,0.5-0.1,0.7,0c0.2,0.1,0.3,0.5,0.4,0.7
			c0.1,0.2,0.2,0.6,0.2,0.8c0,0.3-0.2,1-0.2,1.3c0,0.3,0,0.8,0,1.1c0.1,0.2,0.3,0.8,0.5,0.9c0.3,0.1,0.9-0.4,1.1-0.7
			c0.2-0.3-0.1-1.2-0.1-1.5c0.1-0.5,0.6-1.4,0.6-1.9c0-0.3-0.3-1-0.3-1.3c0.1-0.6,0.5-1.7,0.9-2c0.3-0.3,1.4,0,1.7-0.2
			c0.2-0.2,0.2-0.9,0.4-1.1c0.3-0.3,1.3-0.4,1.6-0.7c0.3-0.2,0.7-0.8,0.8-1.1c0.1-0.3-0.3-1-0.2-1.3c0-0.2,0.3-0.6,0.3-0.6
			s0.3-1.2,0.1-1.5c-0.2-0.4-1.3-0.6-1.7-0.9c-0.6-0.5-1.5-1.6-2-2.2c-0.4-0.6-1.1-1.8-1.4-2.5c-0.1-0.3-0.6-1-0.4-1.3
			c0,0,0.1,0,0.1-0.1c0.2-0.2,0.8-0.3,1.1-0.3c0.3,0,1,0.3,1.2,0.5c0.3,0.3,0.5,1,0.7,1.4c0.2,0.3,0.7,0.8,0.8,1.1
			c0.2,0.4,0.3,1.4,0.6,1.8c0.2,0.3,0.9,1,1.3,1c0.3,0,0.9-0.2,1.2-0.4c0.3-0.3,0.5-1.1,0.7-1.4c0.2-0.3,0.5-0.8,0.8-1
			c0.3-0.2,0.9-0.4,1.3-0.5c0.3,0,1.1,0,1.4,0.2c0.2,0.2,0.1,0.9,0,1.1c-0.2,0.2-1,0-1.3,0.1c-0.2,0.1-0.5,0.5-0.7,0.7
			c-0.2,0.3-0.2,1-0.3,1.3c0,0.3,0,1.1,0,1.1c0.1,0.7-0.4,1.4-0.6,1.5c-0.2,0.2-0.9,0.3-1.1,0.5c-0.2,0.2-0.4,0.8-0.5,1.1
			c0,0.3,0,0.8,0.1,1c0.1,0.3,0.8,0.5,0.9,0.7c0,0.2-0.2,0.6-0.4,0.7c-0.3,0.1-0.9-0.1-1.2,0c-0.3,0.2-0.6,1-0.9,1.2
			c-0.3,0.2-1,0.2-1.3,0.4c-0.4,0.3-0.9,1.1-1.1,1.4c-0.2,0.3-0.5,0.9-0.7,1.2c-0.2,0.3-0.8,0.9-0.9,1.2c-0.1,0.4,0.1,1.3,0.3,1.7
			c0.1,0.2,0.4,0.5,0.6,0.5c0.3,0,0.7-0.5,0.9-0.7c0.3-0.3,0.9-0.9,1.2-1.3c0.3-0.4,0.9-1.2,1.3-1.5c0.3-0.3,1.1-0.6,1.5-0.7
			c0.3-0.1,0.9-0.3,1.1-0.6c0.2-0.2,0.4-0.8,0.6-1c0.3-0.2,1.1-0.3,1.3-0.1c0.2,0.2,0.1,0.9,0,1.1c-0.1,0.2-0.5,0.5-0.7,0.7
			c-0.3,0.2-1.3,0.4-1.3,0.8c0,0.2,0.4,0.5,0.6,0.6c0.3,0.1,0.9,0,1.2-0.1c0.2-0.1,0.5-0.5,0.8-0.5c0.2-0.1,0.8-0.2,0.9,0
			c0.2,0.2,0.1,0.9-0.1,1.2c-0.1,0.2-0.6,0.3-0.8,0.5c-0.2,0.2-0.3,0.7-0.5,0.9c-0.1,0.1-0.5,0.3-0.6,0.3c-0.3,0-0.7-0.6-0.9-0.7
			c-0.2-0.1-0.7-0.3-0.9-0.3c-0.5,0-1.6,0.2-1.9,0.5c-0.2,0.2-0.2,0.7-0.2,1c0,0.3,0.6,0.9,0.6,1.2c0,0.3-0.1,0.9-0.3,1.1
			c-0.2,0.1-0.6,0.1-0.8,0c-0.3-0.1-0.8-0.8-1.2-0.9c-0.2-0.1-0.7-0.1-0.9,0.1c-0.2,0.2,0.3,0.9,0.2,1.1c-0.1,0.2-0.6,0.7-0.8,0.6
			c-0.2,0-0.2-0.6-0.4-0.7c-0.2-0.1-0.7-0.1-0.9,0.1c-0.2,0.2-0.1,0.8-0.3,0.9c-0.3,0.2-1.2-0.3-1.6-0.1c-0.4,0.2-1,0.9-1.3,1.3
			c-0.3,0.3-0.8,1-1.1,1.3c-0.2,0.1-0.6,0.3-0.8,0.4c-0.2,0.2-0.4,0.7-0.4,1c0.1,0.2,0.5,0.5,0.7,0.5c0.3,0.1,0.9-0.3,1.2-0.1
			c0.2,0.1,0.3,0.5,0.4,0.7c0,0.2-0.2,0.7-0.3,0.9c-0.2,0.3-0.7,0.8-1,1c-0.2,0.1-0.8,0.2-1.1,0.3c-0.4,0.2-1.3,0.5-1.6,0.9
			c-0.2,0.3-0.3,1.1-0.4,1.5c-0.1,0.3-0.1,0.9-0.2,1.2c-0.1,0.4-0.7,1.2-0.6,1.7c0.1,0.3,0.5,0.8,0.8,0.9c0.3,0,0.7-0.5,0.9-0.7
			c0.2-0.2,0.3-1,0.5-1.1c0.2-0.1,0.6,0.2,0.7,0.1c0.3-0.1,0.6-0.8,0.9-0.8c0.2,0,0.6,0.4,0.7,0.6c0,0.3-0.5,0.9-0.7,1.1
			c-0.3,0.3-1,0.6-1.2,1c-0.2,0.4-0.1,1.3-0.2,1.8c-0.1,0.4-0.6,0.9-0.8,1.3c-0.2,0.4-0.5,1.3-0.8,1.6c-0.4,0.3-1.5,0.4-2,0.5
			c-0.2,0-0.6,0.1-0.9,0.1l0.7,4.3l0.3,1.8c0,0,1.6-0.1,2.1,0.1c0.3,0.2,0.8,0.7,0.8,1.1c0,0.2-0.5,0.5-0.7,0.7
			c-0.2,0.3-0.3,0.8-0.4,1.1c-0.1,0.4-0.4,1.2-0.4,1.6c0.1,0.5,0.5,1.3,0.8,1.7c0.2,0.3,0.9,0.7,1.2,0.8c0.3,0.1,1,0.2,1.2,0.5
			c0.3,0.2,0.5,0.9,0.7,1.2c0.1,0.2,0.6,0.3,0.6,0.5c0,0.2-0.3,0.6-0.5,0.8c-0.2,0.2-1,0.5-1.1,0.8c-0.2,0.4-0.1,1.2,0.1,1.6
			c0.2,0.4,1.1,1,1.6,1.2c0.3,0.1,1,0.1,1.2,0.3c0.2,0.2,0.2,1,0.2,1.3c0,0.4-0.5,1.2-0.6,1.6c-0.1,0.4,0,1.2,0.1,1.6
			c0.1,0.5,0.5,1.5,0.8,2c0.3,0.6,1.4,1.5,1.6,2.1c0.1,0.3,0.2,1.1,0.1,1.4c-0.1,0.2-0.7,0.5-0.8,0.7c-0.1,0.2-0.3,0.8-0.2,1.1
			c0.2,0.4,1.2,0.6,1.5,0.9c0.2,0.3-0.1,1,0,1.4c0.1,0.3,0.7,0.8,0.8,1.1c0.1,0.2,0,0.6,0,0.9c0,0.2,0,0.4,0,0.5
			c0.1,0.5,0.4,1.4,0.6,1.8c0.3,0.5,1.1,1.5,1.5,2c0.3,0.3,0.7,1.1,1,1.4c0.3,0.3,1.1,0.8,1.4,1.1c0.5,0.6,1.4,2,1.8,2.6
			c0.4,0.5,1.4,1.4,1.7,2c0.3,0.6,0.5,1.9,0.5,2.5c0,0.7-0.7,2-0.6,2.6c0,0.3,0.2,0.8,0.4,1.2c0.1,0.2,0.2,0.5,0.3,0.6
			c0.3,0.5,1,1.4,1.4,1.8c0.3,0.3,1.3,0.6,1.4,1c0.1,0.3-0.1,0.8-0.2,1.1c-0.2,0.4-0.9,0.8-1.1,1.2c-0.1,0.2-0.3,0.7-0.3,1
			c0,0.4,0.9,0.9,0.8,1.2c-0.1,0.4-1.1,0.4-1.4,0.7c-0.1,0.2-0.3,0.6-0.2,0.8c0.2,0.3,1.3,0.3,1.4,0.6c0,0,0,0.1,0,0.1
			c-0.1,0.3-0.4,0.6-0.5,0.8c-0.2,0.2-0.6,0.4-0.7,0.6c-0.2,0.3-0.2,1-0.4,1.2c-0.2,0.3-0.9,0.4-1.2,0.6c-0.2,0.2-0.6,0.8-0.7,1.1
			c-0.1,0.3-0.1,0.9,0,1.2c0.2,0.4,0.8,1.2,1.2,1.5c0.5,0.4,1.8,0.7,2.5,0.9c0.6,0.2,1.7,0.7,2.3,1c0.6,0.3,1.7,1,2.2,1.5
			c0.4,0.4,0.9,1.3,1.2,1.6c0.5,0.5,2.1,0.9,2.2,1.6c0.1,0.4-0.6,1.1-1,1.4c-0.3,0.2-1.1,0-1.5,0.2c-0.2,0.1-0.6,0.5-0.6,0.7
			c-0.1,0.3,0.5,0.9,0.5,1.2c0,0.2-0.3,0.5-0.5,0.8c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2,0,0.7-0.2,0.9c-0.2,0.2-0.7,0.3-1,0.4
			c-0.6,0.1-1.8-0.4-2.4-0.3c-0.3,0-0.9,0.3-1.2,0.5c-0.3,0.2-0.9,0.6-0.9,1c0.1,0.4,1.3,0.4,1.5,0.8c0.1,0.1,0,0.5-0.1,0.6
			c-0.3,0.4-1.2,0.8-1.6,1c-0.5,0.2-1.7,0.1-2.3,0.3c-0.3,0.1-0.9,0.6-1.2,0.9c-0.5,0.6-1.2,1.9-1.7,2.5c-0.3,0.4-0.8,1-1.1,1.4
			c-0.4,0.4-1.4,0.8-1.6,1.3c-0.2,0.4,0.1,1.4,0.1,1.9c0.1,0.6,0.5,1.6,0.7,2.2c0.1,0.4,0.7,1.4,0.5,1.8c-0.1,0.4-1.2,0.5-1.3,0.8
			c-0.1,0.3,0.4,0.9,0.4,1.3c0.1,0.4,0.3,1.1,0.2,1.5c0,0.3-0.5,0.9-0.6,1.2c-0.1,0.5,0,1.6,0,2.1c0,0.3,0.1,1,0.1,1.4
			c0,0.3-0.3,1-0.5,1.3c-0.2,0.3-0.7,0.8-0.9,1.1c-0.1,0.3-0.1,1,0,1.3c0,0.1,0.1,0.3,0.2,0.5c0.1,0.3,0.3,0.6,0.3,0.8
			c0,0.3,0,0.9-0.2,1.1c-0.2,0.2-0.7,0.3-1,0.4c-0.2,0.1-0.8,0.4-0.9,0.7c-0.1,0.3,0.2,0.7,0.3,1c0,0.2,0.2,0.7,0.1,0.9
			c-0.1,0.4-0.6,1.1-0.8,1.4c-0.1,0.2-0.6,0.4-0.7,0.6c-0.2,0.2-0.4,0.8-0.6,1c-0.3,0.3-1.1,0.5-1.2,0.9c-0.1,0.2,0.4,0.7,0.4,0.9
			c0.1,0.3,0.1,1-0.1,1.2c-0.2,0.2-1-0.1-1.3-0.1c-0.3,0.1-1.1,0.2-1.2,0.6c-0.1,0.3,0.5,0.7,0.6,1c0.1,0.3,0,0.9-0.1,1.2
			c-0.1,0.3-0.5,0.6-0.6,0.9c-0.1,0.3,0.1,0.8,0.1,1.1c0,0.4-0.4,1-0.5,1.4c0,0.2,0.1,0.6,0.1,0.8c0.1,0.4,0.6,1.1,0.7,1.5
			c0.1,0.4,0,1.3,0,1.7c0,0.5,0,1.4-0.1,1.9c-0.1,0.4-0.7,1-0.7,1.3c0,0.2,0.2,0.5,0.4,0.7c0.2,0.2,0.3,0.4,0.4,0.5
			c0.2,0.3,0.6,0.9,0.7,1.3c0.1,0.4,0,1.3,0.1,1.8c0.1,0.3,0.4,0.8,0.5,1.1c0.1,0.3,0.3,1,0.3,1.4c0,0.3-0.3,0.8-0.4,1.1
			c-0.1,0.3-0.4,1-0.4,1.4c0,0.4,0.4,1.2,0.5,1.6c0.3,0.6,0.7,2,1.2,2.5c0.4,0.5,1.6,1.3,2.1,1.8c0.2,0.3,0.8,0.8,0.8,1.1
			c0,0.2-0.2,0.7-0.3,1c-0.1,0.1-0.5,0.3-0.5,0.5c0,0.2,0.3,0.6,0.4,0.8c0.1,0.1,0.4,0,0.6,0.1c0.2,0.1,0.4,0.6,0.6,0.7
			c0.2,0.2,0.8,0.2,1,0.4c0.2,0.2,0.1,0.9,0.1,1.1c0.1,0.6,0.2,1.8,0.3,2.4c0,0.6,0,1.8,0.1,2.4c0.1,0.7,0.8,1.9,1,2.5
			c0.2,0.5,0.6,1.4,0.6,1.9c0,0.3-0.4,0.9-0.5,1.2c-0.1,0.4-0.1,1-0.1,1.5c0,0.2,0,0.3-0.1,0.4c-0.1,0.5-0.5,1.4-0.8,1.8
			c-0.2,0.4-0.8,1-1,1.4c-0.1,0.3-0.3,1-0.3,1.4c-0.1,0.6-0.1,1.8,0,2.5c0.1,0.6,0.6,1.7,0.4,2.2c-0.1,0.4-1,0.9-1.2,1.3
			c-0.2,0.3-0.3,1.1-0.3,1.5c0,0.5,0.5,1.4,0.5,1.9c0,0.3-0.4,0.9-0.5,1.2c-0.2,0.5-0.4,1.5-0.6,2c-0.1,0.3-0.8,0.6-0.8,0.9
			c0,0.3,0.7,0.7,0.8,1c0.1,0.4,0.1,1.2,0.1,1.6c0,0.2-0.3,0.7-0.3,0.9c0,0.4,0.4,1.2,0.4,1.6c0,0.6-0.1,1.9-0.3,2.5
			c-0.1,0.4-0.5,1.2-0.8,1.5c-0.2,0.2-0.9,0.4-1.1,0.6c-0.3,0.2-0.7,0.8-0.8,1.1c-0.1,0.5,0.2,1.5,0.4,1.9c0.2,0.7,1.2,2,1.3,2.7
			c0.1,0.4,0,1.2,0,1.6c-0.1,0.5-0.4,1.4-0.6,1.9c-0.1,0.3-0.4,0.9-0.4,1.2c0,0.6,0.9,1.6,1.1,2.2c0.1,0.4,0,1.1,0.1,1.5
			c0,0.2,0.1,0.7,0.3,0.9c0.2,0.2,0.9,0,1.1,0.1c0.4,0.2,0.9,0.9,1.2,1.3c0.3,0.4,0.6,1.3,0.8,1.7c0.2,0.4,0.6,1.3,0.8,1.7
			c0.5,0.7,2.3,1.7,2.6,2.5c0.2,0.6-0.1,1.8,0,2.4c0,0.2,0.2,0.6,0.3,0.9c0.1,0.2,0.2,0.4,0.2,0.5c0.3,0.6,1.4,1.7,1.5,2.4
			c0.1,0.5-0.2,1.6-0.1,2.1c0.1,0.5,0.9,1.2,1,1.6c0.1,0.4-0.2,1.4-0.1,1.8c0,0.4,0.4,1.1,0.4,1.5c0,0.3-0.4,1-0.3,1.3
			c0.1,0.5,0.9,1.2,1.2,1.4c0.3,0.2,1.2,0.2,1.3,0.5c0.2,0.3-0.1,1.1-0.2,1.4c-0.1,0.3-0.5,0.8-0.6,1.1c0,0.3,0.2,0.9,0.4,1.1
			c0.2,0.3,0.9,0.6,1.2,0.9c0.3,0.3,0.7,1.2,0.9,1.6c0.3,0.6,0.9,1.8,1.3,2.3c0.2,0.2,0.8,0.5,0.9,0.8c0.1,0.2,0.1,0.8,0,1.1
			c0,0.2-0.3,0.7-0.3,0.9c0,0.2,0.4,0.5,0.5,0.7c0.1,0.3,0,1,0.1,1.3c0.1,0.3,0.7,0.6,0.7,0.9c0,0.3-0.3,0.8-0.4,1.1
			c-0.2,0.5-0.8,1.7-0.7,2.2c0.1,0.2,0.7,0.4,0.8,0.6c0.2,0.4,0.2,1.4,0.2,1.8c0,0.3-0.3,0.8-0.2,1.1c0.1,0.3,0.6,0.7,0.8,0.9
			c0.1,0.1,0.1,0.4,0.2,0.6c0.4-0.1,0.8,0.2,1,0.3c0.2,0.1,0.4,0.5,0.6,0.7c0.2,0.2,0.5,0.4,0.6,0.3c0.2-0.2,0-0.7,0-0.9
			c-0.1-0.3-0.4-0.8-0.4-1c0-0.2,0.1-0.7,0.2-0.9c0.1-0.1,0.5-0.1,0.6-0.1c0.2,0.1,0.5,0.6,0.6,0.9c0.1,0.3,0.3,1,0.4,1
			c0.1,0.1,0.9,0.3,1.1,0.2c0.2,0,0.6-0.4,0.9-0.5c0.1-0.1,0.5-0.1,0.6-0.2c0.2-0.3,0-0.9,0.1-1.2c0.1-0.2,0.4-0.5,0.5-0.7
			c0.1-0.1,0.1-0.5,0.2-0.6c0.2-0.1,0.7-0.1,0.9-0.1c0.3,0,0.8,0.1,1,0.1c0.2-0.1,0.5-0.5,0.7-0.6c0.2-0.1,0.7,0,1,0.1
			c0.3,0.1,0.6,0.6,0.9,0.6c0.2,0,0.6-0.4,0.8-0.4c0.2,0,0.5,0.3,0.6,0.2c0.2-0.1,0.4-0.5,0.5-0.7c0.1-0.3-0.2-1-0.1-1.3
			c0.1-0.3,0.6-0.7,0.8-1c0.2-0.3,0.4-1,0.5-1.3c0.2-0.3,0.6-0.8,0.7-1.1c0.2-0.5,0.2-1.4,0.3-1.9c0.1-0.6,0.1-1.8,0.4-2.3
			c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.4,0.6-1.2,0.7-1.6c0.1-0.5,0.1-1.2,0.1-1.9c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.4,0.3,0.5,0.4
			c0.2,0.4,0.2,1.4,0.2,1.9c0,0.4-0.2,1.1-0.3,1.4c-0.1,0.3-0.2,0.9-0.3,1.2c0,0.3,0,0.8-0.1,1.1c-0.1,0.3-0.6,0.7-0.7,1
			c-0.1,0.3,0,1,0,1.3c-0.1,0.3-0.5,0.9-0.7,1.1c-0.2,0.3-0.5,1.1-0.7,1.4c-0.2,0.2-0.6,0.5-0.7,0.8c-0.1,0.2-0.1,0.7,0,0.9
			c0.1,0.3,0.3,0.7,0.5,0.9c0.2,0.2,0.6,0.5,0.9,0.4c0.2-0.1,0.5-0.6,0.6-0.8c0.1-0.2-0.1-0.7,0-0.8c0.1-0.1,0.5-0.2,0.6-0.1
			c0.2,0.1,0.4,0.4,0.4,0.5c0.1,0.2,0,0.6-0.1,0.8c-0.1,0.2-0.5,0.3-0.6,0.5c-0.1,0.2-0.1,0.5,0,0.7c0.2,0.3,0.9,0.6,1.2,0.7
			c0.1,0,0.4-0.1,0.6-0.1c0.1,0,0.4-0.1,0.5-0.2c0.2-0.2,0.3-0.8,0.5-1.1c0.1-0.1,0.4-0.4,0.5-0.4c0.3,0,0.6,0.7,0.9,0.8
			c0.2,0.1,0.8,0.2,1.1,0.2c0.3,0,0.8-0.2,1.1-0.3c0.2-0.1,0.5-0.5,0.7-0.6c0.3-0.1,0.9,0.1,1.2,0.2c0.2,0,0.6,0.3,0.8,0.2
			c0.2-0.1,0.6-0.5,0.6-0.8c0-0.3-0.5-0.7-0.5-0.9c0-0.2,0.3-0.5,0.4-0.6c0.1-0.3,0.2-0.8,0.2-1.1c0-0.3-0.3-0.9-0.3-1.2
			c0-0.3,0-0.8,0.1-1c0.1-0.2,0.3-0.5,0.5-0.5c0.1-0.1,0.3,0,0.4-0.1c0.1-0.1,0.4-0.4,0.4-0.5c0.1-0.2,0.2-0.6,0.3-0.8
			c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.8-0.3,1-0.4c0.2-0.1,0.4-0.4,0.5-0.5c0.2-0.1,0.6-0.2,0.7-0.4c0.2-0.2,0.3-0.8,0.5-1.1
			c0.1-0.3,0.4-0.7,0.5-1c0.2-0.3,0.5-1,0.9-1.1c0.2-0.1,0.6,0.1,0.7,0.2c0,0,0,0,0,0c0.1,0.2-0.2,0.6-0.3,0.8
			c-0.1,0.2-0.1,0.7-0.2,0.9c-0.1,0.3-0.5,0.7-0.7,0.9c-0.2,0.2-0.3,0.8-0.5,1c-0.2,0.2-0.7,0.5-0.9,0.7c-0.2,0.2-0.5,0.5-0.6,0.7
			c-0.2,0.3-0.3,0.9-0.5,1.1c-0.2,0.3-0.9,0.5-1.3,0.7c-0.2,0.1-0.6,0.2-0.8,0.4c-0.2,0.2-0.3,0.7-0.3,1c0,0.2,0.3,0.6,0.3,0.8
			c0,0.4-0.3,1.1-0.2,1.5c0.1,0.2,0.5,0.3,0.6,0.5c0.1,0.1,0.1,0.5,0,0.7c0,0.2-0.2,0.4-0.3,0.5c-0.1,0.3-0.2,0.9-0.4,1.1
			c-0.3,0.3-1.2,0.3-1.6,0.4c-0.3,0.1-0.8,0.1-1.1,0.2c-0.2,0.1-0.5,0.4-0.7,0.5c-0.2,0.1-0.6,0.2-0.8,0.1c-0.3,0-0.9-0.5-1.2-0.5
			c-0.2,0-0.6,0.2-0.8,0.3c-0.1,0.2,0,0.7-0.1,0.8c-0.1,0.1-0.5,0.1-0.6,0.1c-0.2-0.1-0.4-0.5-0.6-0.5c-0.1,0-0.4,0.1-0.5,0.2
			c-0.2,0.2-0.2,0.8-0.4,0.9c-0.2,0.1-0.7,0.1-0.9,0.2c-0.3,0-1,0-1.2,0.1c-0.1,0.1-0.3,0.3-0.5,0.4c-0.3,0.2-1.1,0.2-1.5,0.3
			c-0.3,0-0.9,0-1.1,0.1c-0.2,0.1-0.5,0.5-0.7,0.5c-0.3,0.1-1.2-0.4-1.2-0.4c0,0-0.9,0-1.2,0.1c-0.3,0.1-0.8,0.6-1.1,0.7
			c-0.3,0.1-1.1-0.2-1.4-0.1c-0.4,0.2-1.2,0.9-1.4,1.3c-0.1,0.2-0.2,0.8-0.1,1c0,0.1,0.2,0.4,0.3,0.5c0.1,0.3,0.3,0.8,0.3,1.1
			c0,0.3-0.3,0.7-0.5,0.9c-0.2,0.1-0.7,0-0.8,0.2c0,0.1,0,0.3,0,0.3l48.7,2.7l0,0.1l30.6,1.7l8.8,0.5l25.5,1.8l38.7,2.7l23.2,1.6
			l55.8,3.9l16.5,1.1L366,556l8.8,0.6c0,0-0.7,0.8-0.9,1.1c-0.2,0.4-0.2,1.3-0.4,1.6c-0.1,0.2-0.6,0.6-0.6,0.9c0,0.4,0.5,1,0.7,1.4
			c0.2,0.5,0.6,1.6,0.9,2c0.3,0.5,1.3,1.1,1.6,1.6c0.3,0.4,0.5,1.4,0.6,1.9c0.1,0.5,0,1.4,0,1.9c0,0.6-0.1,1.7-0.2,2.2
			c-0.1,0.5-0.4,1.6-0.4,2.1c0,0.6,0.2,1.7,0.4,2.3c0.2,0.7,0.9,2,1.2,2.7c0.4,0.8,1.1,2.5,1.7,3.2c0.3,0.3,1,0.6,1.4,0.6
			c0.6,0,1.5-0.8,2-1c0.5-0.1,1.4-0.1,1.9,0c0.5,0.1,1.3,0.4,1.8,0.6c0.4,0.1,1.2,0.4,1.6,0.4c0.5,0.1,1.5,0,1.9,0
			c0.4,0,1.2,0.2,1.6,0.1c0.5-0.2,1.4-0.8,1.7-1.2c0.3-0.4,0.5-1.5,0.4-2c-0.1-0.5-0.7-1.4-0.9-1.8c-0.1-0.4-0.3-1.2-0.2-1.6
			c0.1-0.4,1.1-0.7,1.1-1.1c0.1-0.4-0.6-1.2-0.6-1.6c0.1-0.4,0.8-0.8,0.9-1.2c0.2-0.6,0.2-1.8,0.4-2.4c0.2-0.6,1-1.5,1.1-2.1
			c0.2-0.8-0.1-2.4-0.2-3.2c-0.1-0.5-0.6-1.6-0.5-2.2c0.1-0.6,0.9-1.7,1.1-2.3c0.3-0.9,0.7-2.9,0.6-3.9c-0.1-0.9-1-2.4-1.4-3.2
			c-0.1-0.3-0.5-0.7-0.7-0.9c-0.4-0.7-0.7-2.2-1-2.9c-0.2-0.4-0.4-1.3-0.6-1.7c-0.2-0.3-0.9-0.7-0.9-1c0-0.3,0.4-0.7,0.4-1
			c0-0.3-0.3-0.9-0.3-1.2c0.1-0.6,0.8-1.7,1-2.3c0.1-0.5,0.3-1.4,0.2-1.9c-0.1-0.6-0.9-1.6-0.8-2.2c0-0.4,0.7-1.5,0.7-1.6
			c0-0.1-0.2-1.1,0.1-1.4c0.3-0.2,1.1,0.2,1.4,0.1c0.4-0.1,1.1-0.6,1.2-0.9c0.1-0.3-0.2-0.9-0.3-1.1c-0.1-0.4-0.8-1.2-0.7-1.6
			c0.1-0.4,0.8-1.2,1.2-1.3c0.4-0.1,1.1,0.4,1.4,0.7c0.3,0.2,0.5,1,0.8,1.2c0.3,0.2,1.2,0.2,1.5,0c0.4-0.3,0.5-1.4,0.7-1.8
			c0.1-0.2,0.5-0.7,0.5-0.9c0.1-0.5-0.3-1.5-0.2-2c0.1-0.3,0.4-0.9,0.7-1.1c0.3-0.2,1-0.5,1.4-0.5c0.5,0.1,1.2,0.8,1.6,1
			c0.4,0.2,1.1,0.8,1.5,0.7c0.4-0.1,0.3-1.3,0.7-1.4c0.1,0,0.2,0,0.3,0c0.4,0,0.9,0.3,1.1,0.4c0.3,0.3,0.7,1.1,0.9,1.5
			c0.1,0.3,0.2,1.1,0.4,1.3c0.2,0.2,0.7,0.4,1,0.4c0.4,0.1,1.2-0.1,1.5,0c0.3,0.1,0.7,0.6,1,0.8c0.2,0.1,0.8,0.2,1,0.2
			c0.3,0,0.9-0.5,1.2-0.5c0.5,0,1.6,0.3,2.1,0.5c0.4,0.2,1.2,0.6,1.4,1c0.1,0.2,0,0.8,0.1,1.1c0.1,0.3,0.3,0.9,0.5,1
			c0.2,0.1,0.8,0,1-0.2c0.2-0.2-0.1-1.1,0.1-1.3c0.1-0.2,0.6-0.2,0.8-0.2c0.2,0.1,0.3,0.7,0.5,0.8c0.3,0.2,1.2,0.1,1.6,0.3
			c0.5,0.3,0.9,1.4,1.3,1.8c0.2,0.2,0.6,0.6,0.8,0.7c0.4,0.1,1.2,0,1.6,0c0.3,0,1,0.1,1.3,0.2c0.4,0.1,1.2,0.9,1.7,0.8
			c0.3-0.1,0.6-0.8,0.9-0.9c0.3-0.1,0.8,0.1,1,0.2c0.2,0.1,0.3,0.6,0.4,0.8c0.1,0.2,0.2,0.9,0.5,1c0.3,0.1,1-0.2,1.3-0.3
			c0.2-0.1,0.6-0.4,0.8-0.4c0.2,0,0.5,0.3,0.7,0.5c0.1,0.2,0.1,0.5,0.1,0.7c0.1,0.2,0.2,0.6,0.3,0.7c0.1,0.2,0.3,0.6,0.5,0.6
			c0.3,0.1,0.8-0.2,0.9-0.5c0.1-0.2,0-0.6-0.1-0.8c-0.1-0.1-0.4-0.2-0.5-0.3c-0.1-0.2-0.1-0.7,0-0.9c0.2-0.2,1-0.2,1.2,0
			c0.2,0.1,0,0.7,0.2,0.8c0.3,0.3,1.4,0.4,1.7,0.1c0.2-0.1,0-0.7,0.2-0.8c0.1-0.1,0.5,0.1,0.6,0.2c0.2,0.2,0.3,1,0.5,1.2
			c0.3,0.3,1.2,0.1,1.6,0.3c0.2,0.1,0.5,0.6,0.7,0.6c0.3,0,0.7-0.6,0.9-0.9c0.1-0.2-0.1-0.7,0.1-0.9c0.2-0.2,0.8-0.1,1.1-0.1
			c0.4,0,1.2,0.3,1.6,0.2c0.2-0.1,0.7-0.4,0.8-0.6c0.1-0.3-0.3-1.1-0.3-1.5c0-0.2,0-0.7,0.1-0.9c0.1-0.1,0.5,0.1,0.6,0
			c0.2-0.2,0.3-0.7,0.3-0.9c0-0.3-0.3-0.7-0.4-1c-0.1-0.4,0.1-1.2,0-1.6c-0.1-0.2-0.3-0.6-0.4-0.8c-0.2-0.2-0.7-0.5-0.9-0.7
			c-0.3-0.2-0.8-0.7-1.1-1c-0.3-0.3-0.8-0.9-0.9-1.3c-0.1-0.3-0.1-1,0.1-1.2c0.2-0.2,0.9,0,1.1-0.2c0.1-0.1,0.3-0.5,0.3-0.7
			c0-0.2-0.3-0.6-0.5-0.7c-0.1-0.1-0.5,0-0.6,0c-0.3,0.1-0.6,0.6-0.9,0.6c-0.2,0.1-0.7,0-0.9-0.1c-0.2-0.2-0.1-0.9-0.3-1.1
			c-0.1-0.2-0.5-0.3-0.7-0.4c-0.2-0.1-0.7,0-0.9-0.1c-0.2-0.1-0.6-0.3-0.7-0.5c-0.1-0.2-0.2-0.8-0.2-1.1c0-0.3,0.3-1,0.1-1.3
			c0-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.5,0.1-0.6,0.3c-0.1,0.2,0,0.7,0,0.9c-0.1,0.3-0.2,0.9-0.4,1c-0.2,0.1-0.6-0.5-0.6-0.6
			c0-0.1-0.3-0.8-0.3-1c0-0.3,0.2-0.8,0.4-1c0.2-0.3,0.9-0.6,1.2-0.7c0.3-0.1,1.1-0.2,1.4,0c0.2,0.1,0.5,0.7,0.5,0.9
			c0,0.2-0.3,0.5-0.3,0.7c0,0.2,0.1,0.7,0.3,0.9c0.1,0.2,0.5,0.6,0.8,0.6c0.3,0,0.4-0.7,0.6-0.8c0.5-0.2,1.6-0.1,1.9,0.2
			c0.2,0.1,0.1,0.7,0.2,0.8c0.2,0.2,0.8,0.2,1,0.3c0.3,0,0.9-0.1,1.1,0.1c0.2,0.1,0.4,0.7,0.6,0.7c0.1,0,0.4-0.3,0.4-0.4
			c0.1-0.3-0.2-0.8-0.4-1.1c-0.2-0.3-1.1-0.8-1.1-0.9c0-0.1-0.7-1-0.9-1.4c-0.2-0.3-0.5-1-0.5-1.4c0-0.4,0.3-1.1,0.6-1.5
			c0.2-0.2,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.7,0.5-0.9c0.1-0.1,0.4-0.2,0.5-0.3c0.3-0.1,0.9-0.3,1.1-0.5c0.3-0.3,0.4-1.2,0.7-1.5
			c0.2-0.2,0.7-0.2,0.9-0.4c0.4-0.3,1-0.9,1.2-1.3c0.1-0.2,0.4-0.7,0.4-1c0-0.3,0-1-0.1-1.3c-0.2-0.4-0.7-1.1-1.1-1.4
			c-0.2-0.1-0.6-0.3-0.8-0.3c-0.2,0.1-0.3,0.7-0.5,0.9c-0.1,0.2-0.4,0.7-0.7,0.9c-0.2,0.1-0.6,0-0.8,0c-0.3,0-1.1,0.1-1.2,0.1
			s-0.8-0.5-1.1-0.6c-0.2-0.1-0.7,0-0.8-0.1c-0.2-0.2,0-0.9-0.1-1.1c-0.1-0.2-0.4-0.4-0.6-0.4c-0.2-0.1-0.8-0.2-1-0.1
			c-0.2,0.1-0.6,0.5-0.8,0.6c-0.2,0.2-0.4,0.8-0.7,0.9c-0.2,0.1-0.6-0.2-0.8-0.2c-0.3,0-0.9,0.3-1.2,0.3c-0.3,0-0.8-0.2-1-0.3
			c-0.3-0.1-0.6-0.7-0.9-0.8c-0.3-0.1-1,0.1-1.4,0c-0.2-0.1-0.5-0.4-0.7-0.5c-0.2-0.2-0.4-0.8-0.6-0.9c-0.2-0.1-0.6-0.4-0.8-0.4
			c-0.2-0.1-0.7,0-1,0c-0.2,0-0.6-0.1-0.6-0.2c0-0.2,0.5-0.5,0.7-0.6c0.2-0.1,0.7-0.1,1-0.1c0.2,0,0.6,0.2,0.8,0.3
			c0.2,0,0.5,0,0.7,0c0.2,0.1,0.5,0.5,0.7,0.7c0.1,0.1,0.4,0.4,0.5,0.4c0.3,0.1,0.9,0.1,1.2,0c0.3-0.1,0.6-0.5,0.9-0.6
			c0.3-0.1,0.9,0,1.1-0.1c0.2-0.1,0.4-0.5,0.6-0.6c0.3-0.1,0.7,0.3,1,0.3c0.2,0,0.5-0.2,0.7-0.2c0.4-0.1,1.1-0.4,1.4-0.3
			c0.3,0.1,0.8,0.6,1,0.9c0.2,0.2,0.3,0.7,0.5,0.9c0.2,0.2,0.6,0.5,0.8,0.6c0.3,0,0.9-0.1,1-0.3c0.1-0.2-0.2-0.7-0.2-0.9
			c0-0.3,0.3-1,0.5-0.9c0.3,0,0.2,1,0.5,1.1c0.2,0,0.5-0.4,0.6-0.5c0.2-0.2,0.5-0.9,0.8-1c0.2-0.1,0.7,0.2,0.9,0.1
			c0.2-0.1,0.3-0.6,0.4-0.8c0.1-0.3,0.4-0.9,0.2-1.2c-0.1-0.1-0.4,0-0.5,0.1c-0.2,0.1-0.3,0.5-0.4,0.5c-0.3,0.2-0.9,0.4-1.2,0.3
			c-0.3-0.1-0.8-0.6-0.8-0.9c-0.1-0.2,0.2-0.7,0.2-1c0-0.3,0-1.1-0.2-1.4c-0.2-0.3-1-0.6-1.3-0.9c-0.2-0.2-0.5-0.9-0.7-1.2
			c-0.2-0.2-0.6-0.5-0.8-0.6c-0.3-0.1-0.9-0.1-1.1-0.2c-0.2-0.1-0.5-0.4-0.6-0.6c-0.1-0.2-0.5-0.5-0.5-0.7c0-0.3,0.1-1,0.4-1.1
			c0,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.3,0.1c0.3,0.2,0.4,1,0.7,1.2c0.3,0.2,0.9,0,1.3,0c0.3,0,0.8,0.2,1.1,0.2c0.2,0,0.7-0.2,0.8-0.4
			c0-0.3-0.7-0.4-0.9-0.6c-0.2-0.2-0.7-0.6-0.9-0.8c-0.2-0.1-0.6-0.2-0.8-0.4c-0.1-0.1-0.2-0.4-0.2-0.6c-0.1-0.2-0.2-0.7-0.1-0.9
			c0.1-0.2,0.6-0.5,0.8-0.7c0.1-0.1,0.4-0.5,0.4-0.7c0.1-0.4-0.1-1.1-0.3-1.5c-0.1-0.3-0.6-0.6-0.9-0.8c-0.3-0.3-0.9-0.8-1.3-0.9
			c-0.2-0.1-0.8-0.1-1-0.3c-0.2-0.1-0.5-0.6-0.7-0.8c-0.2-0.2-0.7-0.7-0.6-1c0-0.1,0.4-0.2,0.5-0.2c0.2,0,0.7,0.1,0.9,0.2
			c0.1,0.1,0.2,0.6,0.4,0.6c0.2,0,0.6-0.2,0.7-0.4c0.2-0.3,0.2-1,0.1-1.4c-0.1-0.2-0.5-0.6-0.5-0.8c0-0.3,0.3-0.8,0.3-1.1
			c0-0.3-0.2-0.8-0.4-1.1c-0.1-0.2-0.6-0.3-0.8-0.4c-0.3-0.2-0.8-0.5-1-0.7c-0.2-0.2-0.4-0.5-0.5-0.7c0-0.1-0.1-0.1-0.1-0.1
			c-0.1-0.3,0.2-0.9,0.1-1.2c-0.1-0.2-0.4-0.5-0.5-0.5c-0.2-0.1-0.8,0.1-1,0.2c-0.2,0.1-0.6,0.4-0.8,0.6c-0.3,0.2-0.7,0.8-1.1,0.8
			c-0.2,0-0.6-0.3-0.9-0.3c-0.3,0-0.8,0.2-1,0.1c-0.2-0.1-0.8-0.3-0.8-0.5c0-0.3,0.4-0.7,0.6-0.8c0.2-0.1,0.7,0.1,0.9,0.1
			c0.2,0,0.5-0.3,0.6-0.4c0.1-0.1,0.2-0.5,0.3-0.6c0.2-0.1,0.7-0.1,1-0.1c0.3-0.1,0.8-0.3,1.1-0.4c0.1,0,0.4,0.1,0.5,0.1
			c0.2,0,0.7-0.4,0.9-0.4c0.2,0,0.5,0.1,0.7,0.3c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.3-0.3,0.9-0.2,1.2c0,0.2,0.3,0.6,0.5,0.7
			c0.1,0.2,0.5,0.4,0.6,0.6c0.3,0.2,0.7,0.8,1,1c0.1,0.1,0.5,0.2,0.6,0.3c0.2,0.2,0.4,0.7,0.5,1c0.1,0.4-0.2,1.1-0.2,1.5
			c0,0.2,0.2,0.7,0.2,0.9c0,0.2,0,0.6,0.1,0.8c0.2,0.3,0.9,0.7,1.3,0.8c0.2,0,0.6-0.1,0.7,0c0.2,0.1,0.3,0.6,0.4,0.8
			c0.1,0.3,0,0.9,0.1,1.2c0.1,0.3,0.5,0.9,0.8,1.2c0.3,0.3,1,0.6,1.3,0.8c0.4,0.2,1.1,0.7,1.6,0.6c0.2,0,0.5-0.4,0.7-0.6
			c0.2-0.2,0.8-0.5,0.9-0.7c0.3-0.4,0.6-1.2,0.6-1.6c0-0.3-0.1-0.8-0.3-1c-0.2-0.2-0.7-0.2-0.8-0.4c-0.2-0.1-0.4-0.5-0.4-0.7
			c-0.1-0.3,0.1-0.8,0-1c-0.1-0.2-0.6-0.5-0.8-0.7c-0.1-0.2-0.2-0.8-0.2-1.1c0-0.3,0.1-1.2,0.1-1.3c0-0.1,0.3-0.6,0.4-0.8
			c0.1-0.2,0.3-0.5,0.4-0.7c0.3-0.4,1.1-1,1.4-1.4c0.2-0.3,0.3-1,0.6-1.1c0.3-0.1,1,0.2,1.2,0.5c0.3,0.3,0.5,1.2,0.5,1.7
			c0,0.3-0.2,0.8-0.2,1.1c0.1,0.3,0.6,0.8,0.7,1.1c0.1,0.3,0.1,1,0,1.4c-0.1,0.3-0.3,0.7-0.4,0.9c-0.1,0.3-0.5,0.9-0.5,1.2
			c0,0.4,0.5,1.2,0.7,1.5c0.2,0.3,0.8,1,1.2,1.1c0.2,0.1,0.8,0.1,1.1,0c0.3-0.1,0.9-0.3,1.2-0.5c0.3-0.2,0.9-0.6,1.1-0.9
			c0.2-0.3,0.4-0.9,0.4-1.2c0-0.2-0.2-0.6-0.4-0.8c-0.1-0.2-0.6-0.3-0.6-0.5c-0.1-0.2,0.1-0.7,0.2-0.8c0.1-0.1,0.3,0,0.4,0
			c0.2,0.1,0.6,0.2,0.7,0.4c0.1,0.1,0,0.6,0.2,0.7c0.2,0.1,0.5-0.3,0.6-0.5c0.1-0.2,0.2-0.6,0.3-0.8c0.2-0.4,1.1-1.5,1.1-1.5
			c0-0.1,1.4-1.3,1.8-1.7c0.2-0.2,0.6-0.7,0.7-1.1c0.1-0.3,0-0.8-0.1-1.1c-0.1-0.3-0.6-0.7-0.7-1c-0.2-0.3-0.4-1.1-0.6-1.5
			c-0.1-0.2-0.5-0.6-0.6-0.8c-0.1-0.3-0.2-1-0.1-1.4c0-0.3,0.2-0.9,0.4-1.1c0.2-0.2,0.8-0.4,1.1-0.5c0.2-0.1,0.8-0.2,1-0.1
			c0.2,0.1,0.5,0.5,0.4,0.6c-0.1,0.3-1.1,0.2-1.4,0.4c-0.1,0.1-0.3,0.5-0.4,0.7c-0.1,0.3-0.4,1-0.2,1.3c0.1,0.3,0.7,0.5,0.9,0.7
			c0.2,0.3,0.3,1.2,0.5,1.6c0.2,0.4,0.8,1,1.2,1.2c0.1,0,0.4,0,0.5,0c0.3-0.1,0.7-0.6,0.8-0.9c0.2-0.3-0.1-1.2,0.2-1.5
			c0.1-0.2,0.8-0.2,0.8-0.3c0-0.1-0.4-1.3-0.2-1.7c0.2-0.3,0.9-0.4,1.2-0.6c0.2-0.1,0.8-0.4,0.8-0.7c0.1-0.3-0.3-0.8-0.5-1
			c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0.1-0.2,0.8-0.4,0.9c-0.2,0.1-0.9-0.3-1-0.4c-0.1-0.2-0.4-0.7-0.4-1c0-0.1,0.1-0.4,0.2-0.5
			c0.2-0.2,0.9-0.2,1.1-0.4c0.2-0.2,0.2-0.9,0-1.1c-0.1-0.2-0.6-0.2-0.8-0.1c-0.2,0.1-0.3,0.5-0.5,0.6c-0.4,0.1-1.2-0.1-1.6-0.2
			c-0.3-0.1-0.6-0.7-0.8-0.9c-0.2-0.2-0.8-0.7-1.1-0.8c-0.3-0.1-1,0.1-1.2,0c-0.2-0.1-0.4-0.6-0.6-0.7c-0.3-0.2-0.9-0.3-1.2-0.3
			c-0.2,0-0.5,0.2-0.6,0.2c-0.2,0-0.6-0.3-0.8-0.4c-0.5-0.1-1.4,0-1.8,0.2c-0.2,0.1-0.4,0.5-0.5,0.7c-0.1,0.2-0.4,0.7-0.4,1
			c0,0.3,0.2,0.7,0.3,1c0.1,0.2,0.5,0.6,0.6,0.8c0.1,0.2,0,0.6,0.1,0.8c0,0.2,0.3,0.5,0.3,0.7c0,0.2-0.2,0.7-0.4,0.9
			c-0.1,0.1-0.4,0.5-0.5,0.4c-0.3,0-0.6-0.5-0.7-0.7c0-0.2,0.3-0.5,0.3-0.7c0-0.2,0-0.7-0.1-1c-0.1-0.3-0.2-0.8-0.4-0.9
			c-0.1-0.1-0.4-0.1-0.5-0.2c-0.1-0.1-0.4-0.2-0.5-0.3c-0.1-0.2-0.2-0.7-0.1-1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.3-0.3,0.4-0.4
			c0.1-0.2,0.2-0.7,0.4-0.8c0.2-0.2,0.8-0.2,1-0.3c0.2-0.1,0.5-0.3,0.6-0.5c0.1-0.2,0.3-0.7,0.3-0.9c-0.1-0.2-0.5-0.5-0.7-0.6
			c-0.3-0.1-0.9,0.3-1.2,0.3c-0.2,0-0.4-0.3-0.5-0.4c-0.2-0.1-0.7-0.1-1,0c-0.2,0-0.7,0.3-0.9,0.3c-0.2,0-0.6-0.4-0.8-0.5
			c-0.3-0.1-0.9-0.2-1.2-0.3c-0.2,0-0.8,0-0.9,0c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.4-0.2-0.5c-0.1-0.3-0.1-0.8-0.1-1
			c0-0.3-0.1-0.8,0-1c0.1-0.1,0.6-0.1,0.8-0.1c0.2,0,0.5,0.1,0.6,0.3c0.1,0.1,0,0.5,0.1,0.6c0.1,0.2,0.7,0.2,0.9,0.4
			c0.1,0.1,0,0.5,0.1,0.7c0.2,0.2,1,0.2,1.3,0.2c0.3,0,0.8-0.3,1.1-0.4c0.3,0,0.9,0.1,1.2,0.2c0.2,0.1,0.7,0.4,0.9,0.5
			c0.4,0.1,1.2-0.2,1.6-0.2c0.3,0,0.9,0.3,1.2,0.4c0.3,0.1,0.9,0.3,1.2,0.4c0.3,0.1,0.9,0.4,1.2,0.3c0.2-0.1,0.3-0.6,0.5-0.7
			c0.1-0.1,0.4-0.1,0.6,0c0.3,0.1,0.8,0.5,1,0.7c0.2,0.2,0.3,0.7,0.5,0.8c0.2,0.2,0.8,0.3,1,0.4c0.3,0.1,1,0.2,1.3,0
			c0.3-0.2,0.8-0.8,0.9-1.1c0.1-0.4-0.1-1.1-0.2-1.5c-0.1-0.5-0.1-1.4-0.3-1.8c-0.1-0.2-0.7-0.4-0.8-0.6c-0.2-0.2-0.2-0.9-0.4-1.1
			c-0.1-0.2-0.6-0.2-0.8-0.4c-0.2-0.1-0.5-0.4-0.5-0.6c0-0.2,0.3-0.4,0.3-0.6c0.1-0.3,0.1-1,0-1.3c-0.2-0.4-1.3-0.8-1.5-1.3
			c-0.2-0.3-0.1-1-0.3-1.4c-0.1-0.3-0.3-0.8-0.5-1c-0.2-0.2-0.7-0.2-0.8-0.4c-0.2-0.2-0.4-0.7-0.6-0.9c-0.1-0.2-0.5-0.4-0.6-0.6
			c-0.1-0.2-0.3-0.7-0.2-0.9c0.1-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.5-0.2,0.7-0.2c0.2,0.1,0.4,0.4,0.5,0.6c0.1,0.2,0.3,0.7,0.4,0.9
			c0.1,0.3,0.4,0.9,0.7,1c0.2,0.1,0.5-0.1,0.6-0.2c0.2-0.2,0.2-0.8,0.5-0.9c0.2-0.1,0.6,0,0.7,0.1c0.2,0.2,0.1,0.9,0.1,1.1
			c0,0.3-0.1,0.9,0,1.2c0.1,0.4,0.8,1.2,1,1.6c0.2,0.3,0.5,1,0.7,1.4c0.2,0.4,0.4,1.1,0.6,1.4c0.2,0.2,0.7,0.7,1,0.6
			c0.4-0.1,0.6-1,0.8-1.4c0.3-0.6,0.8-1.7,1.1-2.3c0.3-0.5,0.8-1.5,1.1-1.9c0.2-0.3,0.7-0.7,0.9-1c0.1-0.2,0.4-0.7,0.4-1
			c0-0.2-0.2-0.4-0.2-0.6c0-0.1,0.1-0.4,0.2-0.5c0.1-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.5-0.4,0.7-0.5c0.3-0.4,0.8-1.3,1.1-1.7
			c0.2-0.3,0.9-0.9,1-1.2c0.2-0.5,0.3-1.5,0.4-2c0.1-0.3,0.4-1,0.6-1.3c0.2-0.2,0.9-0.5,1.1-0.7c0.1-0.1,0.3-0.5,0.3-0.7
			c0-0.3-0.6-0.6-0.7-0.8c-0.3-0.3-0.8-1-1.2-1.1c-0.2-0.1-0.7-0.2-0.9-0.1c-0.3,0.1-0.6,0.6-0.8,0.8c-0.2,0.2-0.6,0.5-0.6,0.7
			c-0.1,0.3,0.4,0.8,0.3,1.1c-0.1,0.1-0.4,0.3-0.5,0.4c-0.2,0-0.6-0.1-0.7-0.2c-0.2-0.2-0.1-0.8-0.3-1c-0.1-0.2-0.5-0.6-0.7-0.6
			c-0.3-0.1-0.9,0.2-1.1,0.4c-0.2,0.3-0.2,1.1-0.4,1.4c-0.2,0.4-0.5,1.1-0.8,1.4c-0.3,0.3-1,0.7-1.4,0.9c-0.3,0.1-1.1,0.1-1.4,0.3
			c-0.2,0.1-0.4,0.6-0.5,0.7c-0.2,0.2-0.8,0.5-1.1,0.4c-0.2-0.1-0.5-0.4-0.5-0.7c0-0.3,0.3-0.9,0.5-1.1c0.2-0.3,0.9-0.7,1.2-0.8
			c0.2-0.1,0.8-0.3,1-0.5c0.2-0.2,0.5-0.6,0.7-0.8c0.3-0.2,1.1-0.4,1.3-0.8c0.1-0.2,0.1-0.9-0.1-1.1c-0.1-0.2-0.5-0.3-0.7-0.3
			c-0.2,0-0.7,0-0.8,0.2c-0.2,0.1-0.2,0.7-0.3,0.8c-0.1,0.1-0.5,0.3-0.7,0.4c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0-0.7-0.1-0.8-0.2
			c-0.2-0.2-0.2-0.7-0.4-0.9c-0.2-0.2-0.6-0.5-0.8-0.6c-0.2-0.1-0.5-0.5-0.7-0.5c-0.2-0.1-0.7,0.2-0.9,0.1c-0.2-0.1-0.1-0.6-0.3-0.7
			c-0.2-0.1-0.6-0.2-0.8-0.1c-0.2,0-0.5,0.2-0.7,0.4c-0.1,0.2-0.1,0.7-0.2,0.9c-0.1,0.2-0.3,0.7-0.6,0.7c-0.2,0.1-0.6-0.1-0.7-0.2
			c-0.2-0.1-0.3-0.5-0.5-0.6c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.2-0.4-0.5-0.4-0.7c0,0,0,0,0,0c0-0.2,0.2-0.5,0.3-0.5
			c0.1-0.1,0.5,0,0.6,0c0.1,0.1,0.2,0.5,0.3,0.5c0.1,0.1,0.5-0.1,0.6-0.2c0.1-0.1,0-0.3,0.1-0.4c0.1-0.1,0.6-0.1,0.7-0.2
			c0.2-0.1,0.5-0.3,0.7-0.3c0.2,0,0.7,0,0.9,0c0.2,0,0.5,0.4,0.7,0.3c0.1-0.1,0.1-0.5,0-0.6c0-0.2-0.3-0.5-0.3-0.7
			c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.7,0.4-0.9c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.3,0.4,0.5c0,0.2-0.2,0.6-0.3,0.7
			c0,0.2,0.1,0.6,0.2,0.8c0.1,0.1,0.2,0.5,0.4,0.5c0.3,0.1,0.8-0.3,1-0.5c0.1-0.1,0.1-0.4,0.2-0.5c0.1-0.1,0.5-0.2,0.7-0.3
			c0.3,0,1-0.1,1.3,0.1c0.2,0.1,0.3,0.5,0.4,0.7c0.2,0.2,0.4,0.9,0.7,0.9c0.1,0,0.4-0.2,0.4-0.3c0.1-0.2,0.1-0.7,0-0.9
			c-0.1-0.1-0.5-0.2-0.6-0.4c-0.1-0.2-0.1-0.7,0-0.9c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.4,0,0.5,0.1c0.2,0.1,0.4,0.4,0.5,0.6
			c0.1,0.2,0.1,0.7,0.2,0.9c0.1,0.2,0.4,0.5,0.7,0.6c0.1,0.1,0.5,0.1,0.6,0c0.1-0.1,0-0.5,0-0.7c0.1-0.2,0.3-0.4,0.4-0.5
			c0.2-0.1,0.6,0.1,0.8,0.2c0.3,0.1,0.7,0.6,1,0.5c0.4-0.1,0.8-0.8,1-1.1c0.2-0.3,0.4-0.9,0.6-1.2c0.1-0.2,0.4-0.7,0.5-0.9
			c0.1-0.3,0.3-1,0.2-1.3c-0.1-0.3-0.5-0.9-0.7-1.2c-0.2-0.2-0.8-0.5-1.1-0.6c-0.2-0.1-0.6-0.4-0.7-0.6c-0.1-0.2-0.3-0.7-0.4-0.9
			c-0.1-0.2-0.3-0.8-0.5-0.9c-0.2-0.1-0.6,0.1-0.7,0c-0.2-0.1-0.8-0.4-0.8-0.6c0-0.2,0.1-0.5,0.2-0.7c0-0.1,0.1-0.1,0.2-0.1
			c0.2-0.1,0.6,0,0.8,0.1c0.2,0.1,0.6,0.4,0.7,0.6c0.2,0.2,0.5,0.6,0.7,0.7c0.1,0.1,0.4,0.1,0.4,0.1c0.1,0.1,0,0.5,0.1,0.7
			c0.1,0.2,0.5,0.7,0.8,0.7c0.2,0,0.2-0.5,0.4-0.6c0.2-0.1,0.7,0.2,0.9,0.4c0.4,0.3,0.8,1.2,1,1.6c0.1,0.3,0.3,1,0.3,1.4
			c0,0.3-0.4,0.9-0.4,1.2c-0.1,0.3-0.1,0.9-0.1,1.2c0,0.3,0,1,0.2,1.3c0.1,0.3,0.4,0.9,0.7,1.1c0.2,0.1,0.7,0,0.9-0.2
			c0.3-0.2,0.4-0.9,0.6-1.2c0.2-0.3,0.6-0.9,0.8-1.3c0.1-0.4,0.1-1.2,0.2-1.5c0.2-0.4,0.8-1.1,1.1-1.4c0.2-0.4,0.5-1.2,0.8-1.6
			c0.1-0.2,0.5-0.5,0.5-0.7c0.1-0.2,0.2-0.6,0.1-0.8c-0.1-0.2-0.5-0.6-0.7-0.7c-0.1-0.1-0.4,0-0.5-0.1c-0.2-0.1-0.4-0.6-0.3-0.8
			c0-0.1,0.4-0.3,0.5-0.3c0.2-0.1,0.7,0,0.8-0.2c0.2-0.2,0.3-0.7,0.3-0.9c0-0.5-0.1-1.4-0.2-1.8c-0.1-0.2-0.3-0.6-0.4-0.8
			c-0.1-0.4-0.1-1.1,0-1.4c0-0.3,0.3-0.8,0.3-1.1c0-0.2-0.2-0.6-0.4-0.7c-0.1-0.1-0.5,0-0.7,0c-0.3,0-1,0.1-1.3,0.3
			c-0.2,0.1-0.2,0.6-0.4,0.7c-0.2,0.1-0.7,0.1-0.9,0.1c-0.2,0-0.6,0.1-0.8,0.3c-0.2,0.1-0.4,0.6-0.6,0.7c-0.2,0.1-0.7,0.1-0.8,0.3
			c-0.1,0.1-0.3,0.5-0.4,0.7c0,0.1,0,0.3,0.1,0.4c0.2,0.2,0.7,0.1,0.9,0.1c0.1,0,0.4-0.1,0.5-0.1c0.2,0,0.5,0,0.6,0
			c0.1,0,0.3,0.1,0.3,0.2c0.1,0.1,0,0.4-0.1,0.5c-0.2,0.3-0.9,0.7-1.2,0.7c-0.2,0-0.6-0.5-0.8-0.5c-0.2,0-0.5,0.2-0.7,0.3
			c-0.2,0.1-0.6,0.5-0.8,0.6c-0.4,0.1-1.1,0.1-1.5,0c-0.2,0-0.6-0.4-0.8-0.4c-0.3-0.1-1.1,0.2-1.4,0.2c-0.3,0-1,0.1-1.3,0
			c-0.2-0.1-0.7-0.4-0.9-0.5c-0.2-0.2-0.6-0.5-0.6-0.8c0-0.3,0.3-0.8,0.5-1c0.1-0.1,0.4-0.4,0.6-0.4c0.3-0.1,0.9,0.1,1.2,0
			c0.1-0.1,0.2-0.5,0.4-0.6c0.2-0.2,0.7-0.4,0.9-0.4c0.4,0,1.1,0.2,1.5,0.3c0.2,0.1,0.4,0.5,0.6,0.5c0.2,0.1,0.5,0.1,0.7,0
			c0.3-0.1,0.8-0.3,1-0.6c0.1-0.2,0.1-0.7,0.2-0.9c0.1-0.2,0.5-0.4,0.7-0.5c0.2-0.2,0.4-0.7,0.4-0.9c0-0.4-0.5-0.9-0.8-1.2
			c-0.4-0.3-1.3-0.7-1.7-1c-0.3-0.2-0.9-0.4-1.1-0.7c-0.2-0.3-0.2-1-0.4-1.3c-0.2-0.2-0.6-0.5-0.9-0.6c-0.3-0.2-1.1-0.4-1.4-0.5
			c-0.3-0.1-1,0.1-1.3,0c-0.4-0.1-1.1-0.5-1.4-0.8c-0.1-0.2-0.3-0.6-0.3-0.8c-0.1-0.3-0.1-0.9,0-1.2c0.1-0.3,0.5-0.9,0.5-1.2
			c0-0.2-0.4-0.7-0.6-0.8c-0.2-0.2-0.8-0.3-1.1-0.3c-0.2,0-0.5,0.2-0.7,0.3c-0.3,0-1-0.1-1.4-0.2c-0.2-0.1-0.6-0.2-0.7-0.4
			c-0.2-0.2-0.6-0.9-0.7-1.2c-0.1-0.2-0.1-0.6-0.1-0.8c0-0.2,0-0.6,0.1-0.8c0.1-0.1,0.6-0.3,0.8-0.2c0,0,0.1,0,0.1,0
			c0.1,0.1,0.2,0.4,0.2,0.5c0,0.1-0.1,0.4,0,0.6c0,0.3,0.1,1.1,0.3,1.3c0.2,0.1,0.6,0.1,0.8,0.1c0.3,0,0.8-0.2,1.1-0.2
			c0.4,0,1.2-0.1,1.5,0.1c0.2,0.1,0.6,0.5,0.8,0.7c0.2,0.2,0.6,0.8,0.6,1.1c0,0.3-0.2,0.8-0.2,1.1c0,0.4,0.3,1,0.4,1.3
			c0.1,0.2,0.5,0.6,0.8,0.7c0.3,0.1,1,0,1.4,0.1c0.4,0.1,1.1,0.5,1.5,0.7c0.3,0.2,0.9,0.8,1.2,1.1c0.4,0.3,1.1,0.8,1.6,0.9
			c0.4,0.1,1.3,0.1,1.7,0.3c0.2,0.1,0.3,0.4,0.5,0.4c0.2,0,0.7-0.2,0.9-0.4c0.2-0.3,0.2-1,0.2-1.3c0-0.3-0.2-0.8-0.4-0.9
			c-0.2-0.2-1.1-0.2-1.2-0.5c-0.1-0.3,0.2-0.8,0.2-1.1c0-0.3-0.2-0.9-0.1-1.2c0-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.4-0.7,0.3-0.9
			c-0.1-0.2-0.7-0.3-0.8-0.5c-0.1-0.2,0-0.6,0-0.8c0-0.2,0-0.6,0.2-0.6c0.1,0,0.1,0,0.2,0c0.3,0.1,0.6,0.5,0.7,0.7
			c0.2,0.2,0.4,0.8,0.5,1c0.1,0.2,0.3,0.6,0.2,0.8c-0.1,0.2-0.5,0.4-0.6,0.6c-0.1,0.3-0.2,0.9-0.2,1.2c0,0.3,0.3,0.9,0.5,1.1
			c0.2,0.2,0.7,0.2,0.9,0.4c0.2,0.2,0.4,0.7,0.5,0.9c0.2,0.3,0.5,0.8,0.6,1.1c0.1,0.4,0.1,1.2,0.3,1.5c0.1,0.1,0.5,0.3,0.7,0.2
			c0.3-0.1,0.6-0.7,0.8-1c0.5-0.5,1.5-1.4,2-1.9c0.4-0.4,1.2-1.1,1.6-1.4c0.4-0.2,1.3-0.4,1.7-0.6c0.4-0.2,1.1-0.8,1.4-1.1
			c0.2-0.3,0.4-1.1,0.5-1.5c0.1-0.4,0.4-1.1,0.6-1.5c0.2-0.3,0.5-0.9,0.7-1.2c0.2-0.2,0.6-0.5,0.7-0.8c0.1-0.3-0.2-0.8-0.4-1
			c-0.1-0.2-0.5-0.4-0.7-0.5c-0.2,0-0.5,0.1-0.7,0.2c-0.1,0.1-0.3,0.4-0.5,0.4c-0.2,0-0.7-0.2-0.9-0.4c-0.2-0.2-0.3-0.7-0.5-0.8
			c-0.2-0.1-0.6-0.1-0.7-0.2c-0.3-0.1-0.9-0.5-1.1-0.7c-0.2-0.2-0.4-0.8-0.6-1.1c-0.2-0.2-0.6-0.6-0.8-0.6c-0.4-0.1-1.2,0-1.6,0
			c-0.4,0-1.3,0.3-1.7,0.2c-0.1,0-0.3-0.1-0.4-0.2c-0.3-0.2-0.7-0.5-0.9-0.7c-0.4-0.3-1.3-1-1.6-1.4c-0.2-0.4-0.3-1.2-0.4-1.7
			c-0.1-0.4-0.3-1.3-0.6-1.6c-0.2-0.2-0.8-0.4-1.1-0.4c-0.3,0-0.9,0-1.1,0.1c-0.2,0.1-0.6,0.2-0.7,0.3c-0.2,0.1-0.5,0.5-0.7,0.6
			c-0.2,0-0.8-0.1-0.9-0.3c-0.1-0.2,0.2-0.5,0.2-0.7c0-0.3-0.3-0.8-0.5-0.9c-0.2,0-0.4,0.5-0.6,0.7c-0.2,0.2-0.4,0.8-0.7,0.9
			c-0.3,0.1-1.1-0.1-1.1-0.2c0-0.1-0.2-0.8-0.2-1.1c0-0.3,0.2-0.9,0-1.1c-0.1-0.1-0.5,0.1-0.7,0.1c-0.4,0-1.2,0.3-1.5,0.1
			c-0.3-0.2-0.4-1-0.4-1.4c0-0.2,0.2-0.7,0.1-0.9c-0.1-0.2-0.6-0.5-0.7-0.7c-0.1-0.2-0.1-0.7-0.3-0.9c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.3-0.2-0.3-0.3c-0.2-0.3-0.6-1.1-0.4-1.4c0.1-0.1,0.3-0.2,0.5-0.3c0.2,0,0.3,0,0.4,0c0.3,0.1,0.3,0.9,0.5,1.1
			c0.1,0.2,0.6,0.3,0.7,0.5c0.1,0.1,0,0.5,0,0.6c0,0.2,0,0.5,0.1,0.7c0.2,0.2,0.7,0.1,0.9,0.3c0.1,0.1,0.3,0.5,0.3,0.7
			c0,0.2-0.3,0.5-0.3,0.7c0,0.2,0.1,0.6,0.2,0.8c0.2,0.2,0.8,0,1.1,0c0.2,0,0.5,0.2,0.6,0.3c0.1,0.1,0.3,0.4,0.3,0.6
			c0.1,0.2,0,0.7,0.1,0.8c0.2,0.1,0.7-0.2,0.8-0.3c0.2-0.2,0.4-0.7,0.6-0.9c0.2-0.3,0.5-0.9,0.7-1.1c0.2-0.2,0.6-0.5,0.8-0.5
			c0.2,0,0.6,0.2,0.8,0.3c0.2,0.2,0.5,0.7,0.5,0.9c0,0.2-0.5,0.5-0.6,0.7c-0.1,0.2-0.4,0.8-0.3,1c0.1,0.1,0.5,0.1,0.7,0
			c0.3-0.1,0.9-0.6,1.2-0.6c0.3-0.1,1-0.1,1.3,0.1c0.4,0.2,0.6,1.2,0.9,1.4c0.2,0.2,0.7,0.2,0.9,0.4c0.2,0.2,0.1,0.8,0.2,1.1
			c0.2,0.5,0.8,1.3,1.1,1.7c0.4,0.4,1.1,1.2,1.6,1.2c0.3,0,0.8-0.6,1.1-0.7c0.2-0.1,0.7-0.4,1-0.4c0.3,0,0.5,0.6,0.8,0.7
			c0.3,0,0.8-0.2,1.1-0.4c0.2-0.2,0.4-0.6,0.4-0.8c0-0.4-0.4-1-0.7-1.3c-0.1-0.1-0.5-0.4-0.7-0.4c-0.5-0.1-1.3,0.5-1.8,0.5
			c-0.3,0-0.9-0.5-1.2-0.6c-0.3-0.2-1.1-0.3-1.4-0.6c-0.2-0.2-0.2-0.8-0.3-1c0-0.3,0.2-1,0.1-1.3c0-0.2-0.2-0.5-0.3-0.7
			c-0.1-0.2-0.5-0.4-0.7-0.5c-0.2,0-0.5,0.1-0.6,0.2c-0.3,0-0.8,0.1-1.1,0.1c-0.2,0-0.6-0.2-0.8-0.3c-0.2-0.1-0.7-0.2-0.9-0.4
			c-0.1-0.1-0.3-0.5-0.3-0.6c0.1-0.2,0.5-0.3,0.7-0.3c0.2,0,0.6,0.4,0.8,0.5c0.3,0.1,0.8,0.3,1.1,0.3c0.2,0,0.7,0,0.8-0.1
			c0.1-0.1-0.1-0.4-0.1-0.5c-0.2-0.2-0.7-0.4-1-0.5c-0.2-0.1-0.8-0.5-0.8-0.6c0,0-0.3-0.6-0.5-0.7c-0.1-0.1-0.4-0.1-0.5-0.2
			c-0.1-0.1-0.4-0.4-0.3-0.5c0.1-0.2,0.6-0.3,0.8-0.3c0.3,0,0.9,0,1.1,0.1c0.2,0.2,0.3,0.7,0.4,0.9c0.1,0.2,0.5,0.4,0.7,0.6
			c0.2,0.1,0.8,0.2,1,0.4c0.2,0.2,0.5,0.6,0.6,0.9c0.1,0.2,0,0.7,0.1,1c0.1,0.2,0.5,0.6,0.6,0.8c0.2,0.2,0.5,0.7,0.6,0.9
			c0.1,0.3,0.3,0.8,0.4,1.1c0.2,0.2,0.6,0.5,0.9,0.6c0.2,0.1,0.6,0.3,0.9,0.3c0.2,0,0.5-0.3,0.6-0.4c0.1-0.2,0-0.7,0-0.9
			c-0.1-0.4-0.2-1.1-0.5-1.4c-0.2-0.2-0.8-0.4-0.9-0.6c-0.1-0.2,0.1-0.6,0-0.9c-0.1-0.2-0.3-0.5-0.4-0.6c-0.2-0.2-0.9-0.1-1.1-0.2
			c-0.2-0.1-0.5-0.5-0.6-0.7c-0.1-0.1-0.4-0.4-0.4-0.6c0-0.1,0.1-0.5,0.1-0.6c0-0.2-0.1-0.7,0-0.9c0.1-0.2,0.5-0.4,0.6-0.5
			c0.2-0.2,0.7-0.7,1-0.7c0.2,0,0.4,0.4,0.5,0.5c0.1,0.2,0.1,0.8,0,1c-0.1,0.1-0.6-0.1-0.8,0c-0.1,0.1-0.3,0.4-0.4,0.6
			c0,0.2,0.1,0.6,0.2,0.7c0.2,0.1,0.6,0.1,0.9,0.1c0.2,0,0.8-0.1,0.9,0c0.1,0.1,0,0.4,0,0.5c0,0.3,0,0.9,0.2,1.1
			c0.1,0.1,0.6,0.1,0.7,0.2c0.1,0.1,0.4,0.4,0.4,0.6c0,0.2-0.1,0.5-0.1,0.6c0,0.3,0,0.9,0,1.1c0.1,0.4,0.6,1,0.9,1.3
			c0.3,0.3,1,0.9,1.3,1.2c0.2,0.3,0.4,1.1,0.7,1.3c0.4,0.3,1.3,0.2,1.8,0.3c0.5,0,1.4-0.3,1.8-0.2c0.3,0.1,0.8,0.6,1.1,0.7
			c0.2,0.2,0.6,0.7,0.9,0.7c0.2,0,0.5-0.5,0.7-0.6c0.2-0.1,0.7-0.1,0.8,0c0.2,0.2,0.1,0.8,0.2,0.9c0.2,0.2,0.6,0.6,0.9,0.5
			c0.4-0.1,0.7-0.8,0.9-1.1c0.2-0.3,0.4-1.1,0.7-1.4c0.4-0.4,1.6-0.8,2.1-1.2c0.4-0.3,1-0.9,1.4-1.2c0.2-0.2,0.8-0.6,1-0.8
			c0.1-0.1,0.3-0.5,0.2-0.7c-0.1-0.3-0.6-0.7-0.8-0.8c-0.4-0.2-1.2-0.2-1.6-0.1c-0.2,0.1-0.5,0.4-0.6,0.4c-0.3,0-1-0.3-1.2-0.5
			c-0.2-0.2-0.5-0.8-0.7-1c-0.2-0.2-0.7-0.2-0.9-0.4c-0.2-0.3,0.1-1.1-0.1-1.4c-0.1-0.3-0.7-0.6-0.9-0.8c-0.2-0.1-0.7-0.3-0.9-0.4
			c-0.3-0.2-1-0.8-1-0.9c0-0.1-0.3-1-0.3-1.3c0-0.3,0-1.1,0.2-1.4c0.1-0.2,0.6-0.4,0.7-0.6c0.2-0.2,0.4-0.7,0.4-1
			c0-0.3-0.6-0.9-0.7-0.9c-0.1,0-0.4,0-0.5-0.1c-0.2-0.1-0.5-0.6-0.5-0.8c-0.1-0.2,0-0.7-0.1-0.9c-0.1-0.2-0.6-0.3-0.8-0.5
			c-0.1-0.1-0.3-0.3-0.3-0.4c0-0.2,0.5-0.3,0.7-0.4c0.2,0,0.6,0,0.8,0.1c0.2,0.1,0.5,0.5,0.6,0.7c0.2,0.3,0.6,1,0.9,1.3
			c0.1,0.1,0.5,0.2,0.6,0.3c0.2,0.2,0.4,0.8,0.4,1.1c0,0.4-0.5,1-0.5,1.4c0,0.3,0.3,0.9,0.3,1.2c0,0.3-0.6,0.7-0.6,1
			c0,0.3,0.1,1,0.3,1.2c0.3,0.2,1.1,0,1.5,0.2c0.3,0.1,0.8,0.5,1,0.8c0.2,0.2,0.1,0.9,0.3,1c0.3,0.1,0.9-0.3,1.1-0.5
			c0.2-0.2,0.5-0.8,0.7-1c0.1-0.1,0.5-0.2,0.6-0.4c0.1-0.1,0.1-0.4,0-0.5c-0.3-0.3-1.3,0.2-1.6,0c-0.2-0.1-0.3-0.6-0.3-0.8
			c0-0.2,0.1-0.6,0.3-0.6c0.2-0.1,0.7,0,0.9,0.1c0.2,0.1,0.3,0.5,0.5,0.6c0.3,0.1,0.9,0.3,1.2,0.2c0.2-0.1,0.5-0.4,0.6-0.6
			c0.1-0.2,0.2-0.7,0.2-0.9c0-0.2-0.3-0.5-0.4-0.6c-0.1-0.3,0-1,0.1-1.3c0-0.3,0.3-1,0.2-1.3c0-0.2-0.3-0.5-0.5-0.6
			c-0.2-0.1-0.7-0.1-0.9-0.3c-0.2-0.2,0-1-0.2-1.2c-0.2-0.2-0.8-0.3-1-0.5c-0.2-0.1-0.6-0.4-0.7-0.5c-0.2-0.2-0.6-0.6-0.7-0.9
			c-0.1-0.2-0.5-0.6-0.4-0.8c0.1-0.1,0.5,0.1,0.7,0.1c0.2,0.1,0.6,0.6,0.8,0.7c0.2,0.1,0.7,0.3,0.9,0.4c0.3,0.1,0.9,0.2,1.1,0.4
			c0.2,0.2,0.1,0.7,0.3,0.9c0.2,0.3,0.7,0.6,1,0.7c0.2,0.1,0.6,0,0.7,0.1c0.2,0.2,0.1,0.8,0.1,1.1c0,0.3-0.4,0.8-0.4,1.1
			c0,0.2,0.1,0.8,0.1,1c0,0.2,0.2,0.7,0.2,1c0,0.2,0,0.8,0,1c0,0.2-0.1,0.7-0.3,0.9c-0.1,0.1-0.4,0.2-0.4,0.3
			c-0.1,0.2,0,0.5,0.1,0.6c0.1,0.2,0.5,0.5,0.5,0.7c0,0.1-0.3,0.4-0.3,0.5c-0.1,0.2-0.2,0.8-0.1,0.9c0.2,0.2,0.8,0.1,1-0.1
			c0.3-0.2,0.2-1,0.5-1.2c0.2-0.1,0.7-0.2,1-0.1c0.2,0,0.5,0.3,0.6,0.4c0.3,0,1-0.3,1.3-0.4c0.3-0.2,0.8-0.7,1.1-0.9
			c0.5-0.4,2-1,2.2-1.6c0.1-0.3-0.2-0.8-0.3-1.1c-0.1-0.2-0.6-0.5-0.6-0.8c-0.1-0.2-0.2-0.7-0.1-0.9c0.1-0.2,0.4-0.5,0.6-0.4
			c0.1,0,0.2,0.3,0.3,0.4c0,0.1,0,0.4,0.1,0.6c0.1,0.1,0.3,0.4,0.4,0.4c0.2,0,0.4-0.4,0.5-0.5c0.1-0.2,0.3-0.8,0.3-1.1
			c0-0.3-0.2-0.7-0.2-1C514.8,380.8,515.2,380.1,515.1,379.8z M419.6,364l13.8-4.3l0,0L419.6,364z M249.2,408.9
			c0.1,0.3,0.8,0.8,0.8,1.1C250,409.7,249.3,409.2,249.2,408.9c0-0.1,0-0.2,0-0.2C249.2,408.8,249.2,408.8,249.2,408.9z M57.6,95.8
			c-0.4,0.6-1.6,2-1.6,2s-0.1,0-0.2,0c0.1,0,0.1,0,0.2,0C56.6,97,57.3,96.3,57.6,95.8z M221.4,86.5c0.2,0.1,0.6,0.4,0.8,0.5
			c0.1,0,0.4,0,0.5,0.1c0.1,0.1-0.1,0.5,0,0.6c0.1,0.2,0.6,0.2,0.8,0.2c0.2-0.1,0.3-0.5,0.5-0.6c0.2-0.1,0.8,0.1,1,0
			c0.1,0,0.3-0.3,0.4-0.4c0.2-0.1,0.6-0.3,0.9-0.4c0.1,0,0.4,0.1,0.6,0.1c0.2,0,0.4-0.3,0.6-0.4c0.2,0,0.5,0,0.6,0
			c0.2,0,0.8-0.1,0.9,0.1c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.4,0-0.5,0.1c-0.2,0-0.7,0-0.8,0.1
			c-0.2,0.1-0.5,0.5-0.6,0.8c0,0.2,0.4,0.7,0.4,0.9c0,0.2-0.3,0.5-0.4,0.7c0,0.2,0.2,0.6,0.1,0.7c-0.1,0.2-0.9,0.1-0.9,0
			c0-0.1-0.4-0.5-0.5-0.7c-0.1-0.2,0.1-0.8,0-1c-0.1-0.2-0.7-0.3-0.9-0.2c-0.2,0.1-0.3,0.5-0.4,0.5c-0.1,0.1-0.3,0-0.5,0
			c-0.2,0.1-0.5,0.2-0.6,0.4c-0.1,0.1-0.1,0.5-0.2,0.6c-0.1,0.1-0.4,0.3-0.4,0.3c-0.1,0.1-0.1,0.6,0,0.7c0.1,0.1,0.4,0.1,0.5,0
			c0.1-0.1,0.1-0.4,0.2-0.5c0.1,0,0.4,0.2,0.4,0.3c0.1,0.1,0.2,0.6,0.1,0.7c-0.1,0.2-0.6,0.2-0.8,0.2c-0.2,0-0.7-0.1-0.9,0
			c-0.2,0.1-0.3,0.5-0.4,0.6c-0.3,0.2-0.9,0.4-1.2,0.5c-0.2,0.1-0.6,0.4-0.8,0.6c-0.1,0.1-0.3,0.4-0.5,0.5c-0.1,0.1-0.5,0.1-0.7,0.1
			c-0.2,0.1-0.7,0.4-0.9,0.6c-0.1,0.2-0.1,0.7-0.2,0.9c-0.1,0.1-0.4,0.4-0.5,0.4c-0.2,0.1-0.7,0-0.8,0.1c-0.2,0.1-0.4,0.5-0.5,0.7
			c-0.1,0.2-0.3,0.5-0.3,0.7c-0.1,0.2-0.3,0.7-0.4,1c-0.1,0.2-0.2,0.6-0.4,0.7c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2-0.1-0.4-0.3-0.4-0.5
			c0-0.2,0.3-0.4,0.4-0.5c0.1-0.2,0-0.5,0-0.7c0.1-0.2,0.4-0.5,0.4-0.6c0.1-0.1,0.2-0.5,0.1-0.6c-0.1-0.1-0.4-0.1-0.5-0.1
			c-0.2,0.1-0.3,0.7-0.5,0.9c-0.2,0.1-0.7,0.1-0.9,0.2c-0.2,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.5,0.4-0.8,0.4c-0.1,0-0.4-0.2-0.6-0.1
			c-0.1,0-0.4,0.3-0.5,0.4c-0.1,0.3,0.1,0.8,0.2,1.1c0,0.3,0.2,1,0.1,1.4c0,0.1-0.2,0.4-0.3,0.4c-0.2,0-0.4-0.4-0.6-0.5
			c-0.3-0.2-0.8-0.8-1.1-0.7c-0.2,0-0.4,0.5-0.4,0.6c0,0.4,0.5,1.1,0.5,1.4c0,0.3-0.1,0.8-0.2,1c-0.1,0.2-0.3,0.5-0.5,0.5
			c-0.2,0.1-0.6-0.3-0.8-0.2c-0.2,0-0.5,0.4-0.7,0.4c-0.2,0-0.6-0.2-0.8-0.2c-0.3,0-0.9,0-1.1,0.2c-0.1,0.1-0.3,0.4-0.4,0.5
			c-0.2,0.2-0.8,0.3-1,0.4c-0.2,0.1-0.7,0.2-0.8,0.3c-0.1,0.1-0.1,0.4-0.2,0.5c-0.2,0.2-0.8,0.3-1.1,0.4c-0.2,0-0.5-0.1-0.7,0
			c-0.2,0.1-0.4,0.5-0.4,0.8c0,0.3,0.3,0.8,0.5,0.9c0.3,0.2,1,0.2,1.2,0.1c0.2-0.1,0.3-0.8,0.5-1c0.2-0.1,0.6-0.2,0.8-0.3
			c0.2-0.1,0.4-0.5,0.6-0.6c0.2-0.1,0.6-0.2,0.8-0.2c0.2,0,0.6,0,0.7,0c0.2,0,0.5,0.1,0.7,0.3c0.1,0.1,0.4,0.5,0.4,0.7
			c0,0.2-0.3,0.6-0.5,0.7c-0.2,0-0.5-0.3-0.7-0.4c-0.3,0-1,0-1.3,0.2c-0.1,0.1-0.3,0.3-0.2,0.5c0.1,0.2,0.5,0.3,0.7,0.4
			c0.2,0.1,0.7-0.1,0.8,0c0.1,0.1,0,0.3,0,0.4c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.1-0.6,0.3-0.8,0.3c-0.2,0-0.5-0.3-0.8-0.4
			c-0.3,0-0.8,0-1.1,0.1c-0.2,0.1-0.6,0.3-0.6,0.5c0,0.2,0.4,0.6,0.5,0.8c0.2,0.2,0.7,0.4,0.7,0.6c0,0.1-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.4,0.1-0.6,0.1c0,0,0,0-0.1,0c-0.2,0-0.5-0.3-0.7-0.4c-0.2,0-0.5,0-0.6,0.1c-0.1,0.1-0.4,0.4-0.4,0.5
			c0,0.2,0.5,0.5,0.5,0.7c0.1,0.2,0.2,0.5,0.1,0.7c-0.2,0.2-0.8,0.1-1.1,0.1c-0.2,0-0.7-0.3-0.9-0.4c-0.2,0-0.5,0.1-0.6,0.2
			c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.2-0.2,0.6-0.1,0.8c0.1,0.2,0.5,0.4,0.7,0.4c0.3,0.1,1-0.2,1.3-0.1c0.2,0.1,0.4,0.5,0.6,0.6
			c0.2,0.1,0.7,0.2,0.9,0.3c0.3,0.2,1,0.6,1.1,1c0,0.3-0.5,0.8-0.7,0.9c-0.2,0.1-0.7-0.2-0.9-0.4c-0.2-0.1-0.3-0.5-0.5-0.6
			c-0.3-0.1-0.9,0-1.2,0.1c-0.1,0-0.3,0.2-0.5,0.2c-0.2,0-0.5-0.2-0.6-0.3c-0.1-0.1-0.3-0.4-0.5-0.5c-0.2-0.1-0.6-0.3-0.9-0.2
			c-0.2,0-0.5,0.3-0.5,0.4c-0.1,0.2,0,0.5,0.1,0.7c0.1,0.2,0.5,0.3,0.7,0.4c0.2,0.2,0.5,0.6,0.5,0.8c0,0.1-0.2,0.4-0.3,0.5
			c-0.2,0.1-0.6,0.1-0.8,0c-0.2-0.1-0.4-0.6-0.6-0.6c-0.2,0-0.6,0.4-0.9,0.4c-0.2,0-0.5-0.2-0.6-0.3c-0.2-0.2-0.2-0.8-0.4-1
			c-0.2-0.2-0.7-0.5-0.9-0.6c-0.3-0.2-0.9-0.5-1.2-0.6c-0.2,0-0.7,0.1-0.9,0.3c-0.1,0.1-0.3,0.4-0.3,0.5c0,0.1-0.1,0.5,0,0.6
			c0.1,0.2,0.6,0.2,0.9,0.2c0.2,0,0.4-0.4,0.5-0.4c0.1,0,0.2,0.3,0.3,0.4c0.1,0.2,0,0.6,0,0.8c0,0.2,0.1,0.5,0,0.7
			c-0.1,0.2-0.5,0.3-0.7,0.3c-0.2,0-0.5-0.3-0.7-0.2c-0.2,0.1-0.5,0.6-0.5,0.6s-0.4,0.8-0.6,1c-0.1,0.1-0.5,0.1-0.6,0.1
			c-0.3,0-0.7-0.5-1-0.5c0-0.2-0.4-0.4-0.6-0.6c-0.1-0.2-0.4-0.5-0.5-0.7c-0.1-0.2-0.2-0.6-0.2-0.8c0-0.2,0.2-0.5,0.2-0.6
			c0.1-0.3,0.6-0.9,0.4-1.1c-0.1-0.2-0.7,0.1-0.9,0c-0.2-0.1-0.2-0.6-0.3-0.7c-0.3-0.3-1.1-0.5-1.5-0.6c-0.2,0-0.6,0.1-0.7,0
			c-0.2-0.1-0.6-0.7-0.5-1c0-0.3,0.8-0.6,0.8-0.9c0-0.2-0.3-0.6-0.5-0.8c-0.1-0.1-0.5-0.2-0.6-0.4c-0.1-0.1-0.4-0.5-0.4-0.7
			c0-0.2,0.2-0.6,0.3-0.6c0.3-0.1,0.8,0.6,1.1,0.7c0.1,0.1,0.5,0.2,0.6,0.1c0.2-0.1,0.3-0.6,0.5-0.7c0.2-0.1,0.8-0.2,1-0.1
			c0.1,0.1,0.1,0.6,0.1,0.7c-0.1,0.2-0.4,0.5-0.5,0.6c-0.1,0.2-0.5,0.8-0.4,1c0.1,0.2,0.5,0.5,0.7,0.6c0.2,0,0.5-0.3,0.6-0.2
			c0.2,0.1,0,0.6,0.1,0.8c0.1,0.2,0.5,0.8,0.8,0.7c0.2,0,0.4-0.5,0.4-0.8c0-0.2,0-0.7-0.1-0.9c-0.1-0.2-0.5-0.5-0.6-0.7
			c-0.1-0.3,0-1,0-1.4c0-0.3-0.2-0.9-0.2-1.2c0.1-0.2,0.3-0.7,0.5-0.8c0.2,0,0.5,0.3,0.6,0.4c0.2,0.2,0.6,0.7,0.6,1
			c0,0.3-0.4,1.2-0.4,1.3c0,0.1,0.3,0.8,0.5,1c0.2,0.2,0.7,0.2,1,0.2c0.3,0,0.9-0.1,1.2-0.3c0.2-0.1,0.6-0.6,0.7-0.8
			c0.1-0.2,0.1-0.8,0.1-1.1c-0.1-0.4-0.6-1-0.9-1.2c-0.3-0.3-1-0.6-1.2-0.9c-0.2-0.2-0.4-0.8-0.4-1.1c0-0.3-0.2-1,0.1-1.1
			c0.3-0.1,0.7,0.5,0.9,0.8c0.1,0.1,0.1,0.5,0.2,0.6c0.1,0.2,0.5,0.3,0.7,0.4c0.2,0.1,0.7,0.2,0.8,0.4c0.2,0.2,0.2,0.7,0.4,0.8
			c0.2,0.2,0.7,0.2,0.9,0.4c0.2,0.2,0.4,0.9,0.7,1.1c0.3,0.1,1-0.1,1.3-0.2c0.2,0,0.6,0,0.8-0.1c0.2-0.3-0.2-1.2-0.2-1.6
			c0-0.3-0.2-0.9-0.2-1.2c0-0.2,0.3-0.6,0.4-0.8c0.1-0.3,0.2-1.1,0.5-1.2c0.2,0,0.5,0.4,0.6,0.6c0.1,0.2,0,0.7,0,1
			c0,0.3-0.2,0.9-0.1,1.2c0,0.3,0.2,0.9,0.4,1.1c0.1,0.1,0.5,0.3,0.6,0.2c0.2-0.1,0.2-0.6,0.3-0.8c0.1-0.2,0.5-0.4,0.5-0.6
			c0.1-0.3-0.3-0.8-0.4-1.1c-0.1-0.3,0.1-1.1,0-1.4c-0.1-0.3-0.8-0.6-0.9-0.9c-0.1-0.3-0.2-0.9-0.1-1.2c0.1-0.3,0.4-0.8,0.7-0.8
			c0.2,0,0.6,0.3,0.7,0.5c0.1,0.2-0.1,0.8,0,1c0.1,0.2,0.4,0.4,0.6,0.5c0.3,0,0.7-0.3,0.9-0.4c0.1-0.1,0.4-0.4,0.5-0.6
			c0.1-0.3-0.1-0.9-0.2-1.1c-0.2-0.3-1.2-0.4-1.4-0.8c-0.1-0.2,0-0.7,0.1-0.9c0.1-0.3,0.4-0.8,0.3-1.1c0-0.2-0.3-0.6-0.4-0.8
			c-0.1-0.1-0.3-0.3-0.5-0.4c-0.3-0.1-0.8,0.2-1.1,0.2c-0.3,0-1-0.1-1.2-0.2c-0.3-0.2-0.8-0.6-0.9-0.9c0,0,0-0.1,0-0.1
			c0-0.2,0.1-0.5,0.2-0.6c0.2-0.1,0.8-0.1,1-0.1c0.3,0.1,0.5,0.7,0.7,0.8c0.2,0.1,0.6,0,0.7-0.1c0.1-0.1,0.1-0.5,0-0.6
			c-0.1-0.3-0.7-0.5-1-0.7c-0.2-0.1-0.6-0.3-0.8-0.4c-0.2-0.1-0.6-0.2-0.8-0.2c-0.3-0.1-1,0.1-1.1-0.2c-0.1-0.2,0-0.6,0.1-0.8
			c0.1-0.2,0.6-0.3,0.9-0.4c0.2-0.1,0.6,0,0.8-0.1c0.2-0.2,0.2-0.7,0.2-1c0-0.3-0.5-1-0.4-1.3c0-0.2,0.5-0.4,0.6-0.6
			c0.1-0.2,0-0.9,0.1-1.1c0-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.5-0.6,0.4-0.9c-0.1-0.3-0.8-0.3-1-0.5c-0.1-0.1-0.2-0.4-0.2-0.5
			c0-0.2,0.4-0.4,0.5-0.6c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.6-0.2-0.7-0.4
			c-0.1-0.3,0.2-0.8,0.4-1c0.1-0.1,0.5-0.3,0.5-0.4c0.1-0.2-0.3-0.6-0.3-0.9c0-0.2,0.3-0.7,0.4-0.8c0.1-0.1,0.4-0.4,0.6-0.4
			c0,0,0,0,0,0c0.2,0,0.7,0.2,0.8,0.4c0.1,0.3-0.4,0.8-0.4,1.1c0,0.3,0,1,0.2,1.4c0.2,0.4,0.7,1.1,1.1,1.2c0.4,0.1,1.1-0.3,1.3-0.6
			c0.2-0.2,0.1-0.8,0.2-1c0.1-0.3,0.2-0.9,0.4-1.1c0.1-0.1,0.3-0.1,0.6-0.1c0.2,0,0.4,0.1,0.5,0.1c0.2,0.1,0.4,0.5,0.3,0.7
			c0,0.2-0.5,0.3-0.6,0.5c0,0.3,0.6,0.8,0.7,1.1c0.1,0.2,0.2,0.8,0,1c-0.2,0.2-0.7,0-1,0c-0.2,0-0.6,0-0.8,0.1
			c-0.2,0-0.4,0.2-0.6,0.3c-0.1,0-0.2,0.1-0.2,0.1c-0.2,0.1-0.6,0.3-0.8,0.5c-0.2,0.1-0.5,0.5-0.6,0.7c-0.1,0.2,0,0.6,0.1,0.8
			c0.1,0.2,0.6,0.2,0.8,0.2c0.2,0.1,0.8,0.1,0.9,0.3c0.2,0.2,0.3,0.8,0.1,1.1c-0.1,0.2-0.7,0.1-0.9,0.1c-0.2,0-0.7-0.2-0.9-0.2
			c-0.1,0-0.4-0.2-0.5-0.2c-0.2,0.1-0.5,0.5-0.5,0.7c0,0.2,0.4,0.3,0.4,0.5c0,0.2-0.6,0.5-0.5,0.6c0.1,0.2,0.7-0.2,0.9,0
			c0.1,0.1,0.2,0.5,0.2,0.7c-0.1,0.2-0.6,0.3-0.7,0.5c-0.1,0.2,0.1,0.5,0.1,0.7c0,0,0,0,0,0c0,0.3-0.1,0.9,0.1,1.1
			c0.2,0.2,0.8,0,0.9,0.2c0.1,0.1-0.1,0.6,0.1,0.7c0.2,0.1,0.5-0.3,0.5-0.5c0.1-0.2,0-0.7,0.1-0.9c0.1-0.1,0.4-0.2,0.5-0.4
			c0.2-0.2,0.2-0.9,0.4-1c0.1-0.1,0.4-0.1,0.6,0c0.2,0.1,0.4,0.4,0.4,0.6c0,0.2-0.6,0.3-0.7,0.5c0,0.2,0.2,0.5,0.4,0.6
			c0.2,0,0.4-0.4,0.6-0.4c0.2,0,0.6,0.2,0.8,0.3c0.2,0.1,0.7,0.4,0.8,0.6c0.1,0.2,0.1,0.7,0,0.9c-0.1,0.2-0.8,0.3-0.9,0.4
			c-0.1,0.1-0.1,0.4-0.2,0.5c-0.2,0.1-0.6-0.2-0.8-0.1c-0.2,0-0.5,0.2-0.6,0.3c-0.2,0.2-0.6,0.7-0.6,1c0,0.3,0.5,0.6,0.7,0.7
			c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.2-0.1,0.7,0.1,0.9c0.1,0.1,0.5,0.1,0.6,0.1c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.7,0,0.9,0
			c0.1,0,0.4,0.1,0.5,0.1c0.2,0,0.5-0.2,0.6-0.4c0.1-0.1,0.2-0.5,0.3-0.6c0.1-0.1,0.5-0.2,0.6-0.3c0.2-0.1,0.6-0.1,0.8-0.2
			c0.2-0.2,0.2-0.7,0.3-0.9c0-0.3,0.2-0.9,0-1.2c-0.1-0.2-0.7-0.3-0.9-0.4c-0.2-0.1-0.4-0.5-0.6-0.7c-0.1-0.1-0.3,0-0.4-0.1
			c-0.2-0.1-0.5-0.3-0.5-0.5c0.1-0.2,0.7-0.3,0.9-0.4c0.2,0,0.6-0.1,0.8-0.1c0.1,0,0.3,0.3,0.4,0.4c0.2,0,0.6-0.1,0.7-0.2
			c0.1-0.2,0.1-0.6,0.1-0.8c0-0.2,0.2-0.6,0.1-0.8c-0.1-0.2-0.4-0.6-0.5-0.8c-0.2-0.2-0.5-0.7-0.6-1c-0.2-0.2-0.5-0.7-0.7-0.9
			c-0.2-0.3-0.8-0.7-1-1c-0.1-0.2-0.4-0.6-0.4-0.9c0-0.2,0.3-0.7,0.5-0.8c0.2,0,0.5,0.1,0.6,0.2c0.1,0.1,0.2,0.5,0.3,0.7
			c0.1,0.3,0.4,0.8,0.5,1.1c0.3,0.4,0.9,1.3,1.2,1.7c0.2,0.4,0.6,1.3,0.8,1.7c0.2,0.4,0.1,1.7,0.5,1.7c0.3,0,0.3-0.8,0.5-1
			c0.1-0.1,0.6-0.2,0.7-0.4c0.2-0.2,0.7-0.9,0.6-1.2c-0.1-0.3-0.8-0.4-1-0.7c-0.1-0.2-0.1-0.6-0.3-0.8c-0.3-0.4-1.2-0.7-1.5-1
			c-0.3-0.3-0.5-1-0.7-1.4c-0.1-0.1-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.8-0.4-1.1c-0.2-0.2-0.8-0.3-1-0.5c-0.1-0.1-0.2-0.5-0.2-0.6
			c0.1-0.2,0.5-0.2,0.7-0.4c0.1-0.1,0.3-0.5,0.3-0.7c0-0.2,0-0.7-0.1-0.8c-0.1-0.1-0.6,0-0.7-0.2c-0.1-0.2-0.1-0.6,0-0.8
			c0-0.1,0.2-0.4,0.3-0.4c0.2-0.2,0.8-0.5,1.1-0.4c0.3,0.1,0.7,0.5,0.7,0.8c0.1,0.2-0.2,0.7-0.2,1c0,0.2,0.2,0.5,0.2,0.7
			c0,0.2,0,0.7,0,1c0,0.2-0.2,0.6-0.1,0.8c0.1,0.2,0.5,0.3,0.6,0.4c0.1,0.1,0.2,0.3,0.2,0.4c0,0.3-0.4,0.7-0.4,1
			c0,0.2,0.2,0.7,0.4,0.8c0.2,0.2,0.6,0.6,0.9,0.7c0.3,0.1,0.8-0.1,1.1,0c0.2,0.1,0.4,0.6,0.5,0.8c0.1,0.2,0.3,0.6,0.5,0.7
			c0.3,0.2,1.1,0.6,1.4,0.4c0.2-0.1,0.1-0.9,0.3-1c0.1-0.1,0.5,0,0.6,0c0.2,0,0.4,0.3,0.6,0.3c0.2,0,0.6-0.2,0.7-0.4
			c0.1-0.1,0.3-0.4,0.4-0.4c0.1,0,0.5,0.1,0.5,0.3c0.1,0.2-0.1,0.7-0.3,0.9c-0.2,0.2-1,0.1-1.2,0.3c-0.2,0.1-0.3,0.6-0.4,0.7
			c-0.2,0.1-0.7,0.2-0.8,0.3c-0.2,0.2-0.5,0.6-0.8,0.8c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.5,0.1-0.5,0.2c-0.1,0.1-0.1,0.4,0,0.6
			c0.1,0.2,0.5,0.4,0.7,0.4c0.2,0,0.5-0.2,0.7-0.2c0.3-0.1,0.8-0.1,1-0.2c0.2-0.1,0.4-0.6,0.6-0.8c0.1-0.1,0.4-0.5,0.6-0.5
			c0.1,0,0.3,0.3,0.5,0.4c0.2,0.1,0.7,0.1,0.9,0c0.2-0.1,0.3-0.6,0.4-0.8c0-0.2-0.1-0.5-0.1-0.6c0-0.2,0-0.6,0.1-0.8
			c0.1-0.1,0.4-0.1,0.5-0.1c0.2,0,0.4,0.4,0.5,0.5c0.1,0.1,0.4,0.1,0.5,0.1c0.2-0.1,0.2-0.6,0.2-0.8c0-0.2-0.2-0.6-0.1-0.8
			c0.1-0.1,0.4-0.2,0.5-0.3c0.1,0,0.5-0.1,0.6,0c0.1,0.1-0.2,0.6-0.1,0.7c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.2,0.5-0.4
			c0.1-0.2,0-0.8,0.1-1c0.1-0.2,0.4-0.4,0.5-0.6c0.2-0.1,0.5-0.4,0.6-0.5c0.1-0.2,0.2-0.6,0.3-0.8c0.1-0.1,0.3-0.3,0.4-0.5
			c0.1-0.2,0.1-0.7,0-0.9c-0.1-0.2-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.4-0.4-0.6c-0.1-0.2-0.2-0.7-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1
			C221,86.4,221.3,86.4,221.4,86.5z M276.4,137.7c0.1-0.1,0.2-0.2,0.3-0.2C276.6,137.5,276.5,137.6,276.4,137.7
			C276.4,137.7,276.4,137.7,276.4,137.7z M396.3,232.3c0.2,0,0.4,0.2,0.6,0.3c-0.1-0.1-0.2-0.1-0.3-0.2
			C396.4,232.4,396.4,232.4,396.3,232.3z M385.9,280.1c-0.8-0.1-1.7-0.3-2.5-0.4c0,0,0.1,0,0.1,0C384.3,279.8,385.1,280,385.9,280.1
			z M396,311.3C396,311.3,396,311.3,396,311.3c-0.1,0.2-0.1,0.3-0.2,0.5C395.8,311.7,395.9,311.5,396,311.3z M384.8,274.6
			C384.8,274.6,384.8,274.6,384.8,274.6C384.8,274.6,384.8,274.6,384.8,274.6C384.8,274.6,384.8,274.6,384.8,274.6z M383.4,271.9
			c0,0.3,0.1,0.5,0.2,0.8C383.5,272.4,383.5,272.2,383.4,271.9z M418,364c0.2,0,0.3,0,0.5,0C418.3,364,418.1,364,418,364z
			M382.7,239.1c0,0-0.1,0-0.1,0C382.6,239,382.7,239.1,382.7,239.1C382.7,239.1,382.7,239.1,382.7,239.1z M271.6,210.9
			c0.2,0.1,0.5-0.1,0.7-0.1c0.2,0,0.5,0.2,0.7,0.2c0.2,0,0.5-0.6,0.7-0.6c0.2,0,0.2,0.5,0.4,0.6c0.1,0.2,0.5,0.4,0.6,0.6
			c0.1,0.2-0.1,0.7-0.2,0.9c-0.1,0.2-0.6,0.4-0.7,0.6c-0.1,0.1-0.4,0.5-0.3,0.7c0.1,0.1,0.6-0.3,0.8-0.3c0.2-0.1,0.7-0.1,0.9-0.2
			c0.2-0.1,0.3-0.6,0.4-0.7c0.1-0.1,0.4-0.5,0.5-0.5c0.1,0,0.2,0.3,0.4,0.3c0.1,0,0.3-0.2,0.3-0.4c0-0.2-0.1-0.5-0.2-0.6
			c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.2-0.4-0.7-0.4-0.9c0-0.2,0.5-0.4,0.4-0.6c0-0.2-0.5-0.3-0.6-0.4c-0.2-0.1-0.5-0.4-0.5-0.6
			c0-0.2,0.2-0.4,0.4-0.5c0.2,0,0.4,0.4,0.6,0.4c0.1,0,0.2-0.3,0.2-0.4c0-0.2-0.2-0.6-0.2-0.9c-0.1-0.2,0-0.7-0.2-0.9
			c-0.1-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.6-0.1-0.8-0.2c-0.1,0-0.2-0.5-0.3-0.6c-0.2-0.1-0.6-0.1-0.6-0.4c0-0.2,0.2-0.5,0.3-0.6
			c0.1-0.1,0.5-0.1,0.7,0c0.2,0.1,0.5,0.7,0.7,0.6c0.2-0.1,0.1-0.7,0.1-1c0-0.2-0.2-0.6-0.2-0.8c0-0.1-0.1-0.5,0-0.6
			c0.1-0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.4,0.5,0.6,0.5c0.2,0,0.7-0.3,0.8-0.4c0.2-0.2,0.7-0.7,0.8-0.8c0.1,0,0.4-0.6,0.7-0.7
			c0.2,0,0.6,0.1,0.7,0.3c0.1,0.3,0,1-0.2,1.3c-0.1,0.2-0.9,0.1-1.1,0.2c-0.3,0.1-0.8,0.5-1,0.7c-0.1,0.1-0.1,0.6-0.1,0.7
			c0,0.1-0.2,0.8-0.1,1.1c0,0.2,0.3,0.6,0.4,0.8c0.1,0.1,0.2,0.3,0.4,0.4c0.3,0,0.8-0.4,1.1-0.5c0.2,0,0.6-0.2,0.8,0
			c0.1,0.1-0.2,0.4-0.3,0.6c-0.1,0.2-0.4,0.7-0.6,0.8c-0.1,0.1-0.6,0.2-0.7,0.3c-0.2,0.2-0.5,0.8-0.6,1.1c-0.1,0.3-0.2,1-0.1,1.3
			c0.1,0.2,0.3,0.7,0.5,0.6c0.1,0,0-0.4,0.1-0.5c0.1-0.3,0.2-0.9,0.4-1.1c0.1-0.2,0.5-0.4,0.7-0.4c0.2,0,0.6,0,0.7,0.2
			c0.1,0.1,0.1,0.5,0,0.6c-0.1,0.2-0.7,0.1-0.8,0.3c-0.1,0.2-0.1,0.6-0.1,0.8c0,0.1,0.2,0.4,0.3,0.5c0.1,0.2,0.2,0.5,0.2,0.7
			c0,0.2-0.5,0.6-0.5,0.8c0,0.2,0.3,0.6,0.5,0.6c0.2,0.1,0.8,0.1,1-0.1c0.2-0.1,0.4-0.6,0.4-0.8c0-0.2-0.2-0.7-0.1-0.9
			c0.1-0.2,0.5-0.4,0.6-0.4c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1-0.1,0.5-0.1,0.6c0,0.1,0.2,0.3,0.4,0.3c0.1,0,0.3-0.3,0.4-0.3
			c0.1,0,0.4-0.1,0.5,0c0.1,0.1,0,0.2,0,0.3c-0.1,0.2-0.5,0.5-0.7,0.7c-0.1,0.1-0.4,0.4-0.5,0.5c-0.1,0.1-0.5,0.1-0.6,0.3
			c-0.1,0.2,0.1,0.6,0,0.7c-0.1,0.2-0.6,0.5-0.8,0.6c-0.1,0.1-0.5,0-0.6,0.2c-0.1,0.2,0.5,0.5,0.6,0.7c0,0.1-0.1,0.4,0,0.5
			c0,0.1,0.1,0.3,0.2,0.4c0.2,0.3,0.5,0.6,0.6,0.8c0.2,0.2,0.9,0.6,1,0.8c0.1,0.2,0.1,0.5,0.1,0.8c0,0.2,0,0.3,0,0.4
			c0,0.3,0,0.9,0.1,1.2c0.1,0.2,0.5,0.6,0.6,0.8c0.1,0.3-0.1,0.9-0.2,1.1c-0.1,0.1-0.6,0.2-0.7,0.2c-0.2,0.1-0.9,0.4-0.9,0.6
			c0,0.2,0.4,0.5,0.6,0.6c0.1,0.1,0.6,0.2,0.6,0.4c0.1,0.2-0.1,0.6-0.2,0.7c-0.2,0.1-0.8,0-1.1-0.1c-0.1,0-0.4-0.2-0.5-0.2
			c-0.1-0.1-0.4-0.2-0.6-0.2c-0.1,0-0.2,0.3-0.4,0.3c-0.3,0.1-1,0-1.3,0.2c-0.2,0.1-0.4,0.5-0.6,0.6c-0.3,0.1-0.9-0.3-1.2-0.1
			c-0.2,0.1-0.3,0.5-0.4,0.7c-0.1,0.1-0.2,0.4-0.4,0.5c-0.1,0-0.4-0.2-0.6-0.2c-0.1,0-0.4,0-0.4,0.1c-0.1,0.2,0.4,0.6,0.4,0.8
			c0,0.2-0.5,0.5-0.5,0.8c-0.1,0.2,0.2,0.7,0.1,0.9c-0.1,0.2-0.7,0.5-1,0.5c-0.2-0.1-0.2-0.5-0.3-0.7c-0.1-0.1-0.5-0.2-0.6-0.2
			c-0.2,0-0.6,0.4-0.7,0.6c-0.2,0.3-0.3,1.1-0.3,1.4c0,0.2,0.4,0.6,0.5,0.8c0.1,0.4,0.3,1.3,0.1,1.6c-0.1,0.2-0.6,0.3-0.7,0.5
			c-0.1,0.2,0,0.6,0,0.7c0.1,0.1,0.5,0.2,0.5,0.4c0,0.2-0.1,0.6-0.3,0.7c-0.2,0.1-0.8-0.3-1-0.1c-0.1,0.1,0,0.4,0,0.6
			c-0.1,0.2-0.6,0.3-0.8,0.5c-0.2,0.1-0.8,0.3-0.9,0.6c-0.1,0.1-0.1,0.5,0,0.6c0.1,0.1,0.6,0.1,0.7,0.1c0.3,0,0.7-0.3,1-0.3
			c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.6-0.1,0.8,0c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.3,0,0.9,0.1,1.2c0.1,0.2,0.2,0.6,0.4,0.7
			c0.2,0.1,0.8,0.1,1,0c0.2-0.1,0.5-0.7,0.6-0.9c0-0.2-0.1-0.6-0.2-0.8c-0.1-0.2-0.3-0.5-0.3-0.7c0-0.2,0-0.7,0.1-0.9
			c0.1-0.2,0.6-0.5,0.7-0.7c0-0.2-0.3-0.4-0.4-0.6c-0.1-0.3-0.2-1.1,0-1.4c0.1-0.2,0.7-0.2,0.8-0.1c0.2,0.1,0.5,0.6,0.5,0.8
			c0.1,0.3,0.1,0.8,0.1,1.1c0,0.3-0.2,0.9-0.1,1.2c0,0.3,0.6,1,0.5,1.3c0,0.2-0.6,0.7-0.6,0.7c0,0.2,0.1,0.5,0.1,0.7
			c-0.1,0.2-0.4,0.5-0.5,0.7c-0.1,0.1-0.3,0.3-0.4,0.5c0,0.2,0.2,0.4,0.3,0.5c0.2,0.1,0.6-0.1,0.7,0c0.2,0.1,0.2,0.5,0.4,0.6
			c0.2,0,0.5-0.3,0.6-0.4c0.1-0.2,0.4-0.6,0.4-0.9c0-0.2-0.4-0.5-0.3-0.6c0.1-0.2,0.5-0.2,0.7-0.2c0,0,0,0,0,0
			c0.2,0.1,0.5,0.4,0.6,0.6c0.1,0.2-0.2,0.8-0.1,1c0.1,0.1,0.5,0.1,0.6,0.2c0.1,0.1,0,0.4-0.1,0.5c-0.2,0.1-0.7-0.2-0.9-0.1
			c-0.1,0.1-0.2,0.4-0.3,0.5c-0.1,0.2-0.5,0.5-0.6,0.7c-0.1,0.2,0.1,0.7,0,0.8c-0.2,0.1-0.5,0.1-0.9,0c-0.2-0.1-0.5-0.1-0.6-0.2
			c-0.2-0.1-0.5-0.5-0.7-0.6c-0.3-0.1-1,0-1.3-0.1c-0.2-0.1-0.8-0.2-0.9-0.4c-0.1-0.1-0.2-0.5-0.1-0.7c0.1-0.2,0.6-0.2,0.6-0.4
			c0.1-0.3-0.3-0.8-0.6-0.9c-0.2-0.1-0.8-0.1-1-0.2c-0.3-0.1-0.9,0-1.1-0.2c-0.1-0.1-0.2-0.5-0.1-0.6c0.1-0.1,0.5-0.1,0.6-0.3
			c0.1-0.2-0.1-0.7-0.3-0.8c-0.2-0.1-0.6,0-0.8,0c-0.2,0.1-0.4,0.6-0.6,0.7c-0.3,0-0.8-0.7-1.1-0.7c-0.3,0-0.7,0.6-1,0.8
			c-0.2,0.1-0.5,0.6-0.7,0.6c-0.2,0-0.6-0.5-0.8-0.5c-0.1,0-0.4-0.1-0.5,0c-0.1,0.1,0.1,0.6,0,0.7c-0.1,0.2-0.5,0.4-0.6,0.6
			c-0.1,0.2,0.1,0.5,0,0.7c-0.1,0.2-0.4,0.7-0.7,0.7c-0.2,0.1-0.7,0-0.8-0.1c-0.1-0.1-0.1-0.4-0.1-0.6c0-0.2,0.4-0.5,0.3-0.6
			c-0.1-0.1-0.5,0.1-0.7,0.1c-0.2,0-0.6-0.2-0.8-0.4c-0.2-0.2-0.3-0.9-0.6-1c-0.2-0.1-0.8-0.1-1,0c-0.3,0.1-0.5,0.7-0.7,0.9
			c-0.1,0.1-0.5,0.1-0.6,0.3c-0.2,0.2-0.1,0.9-0.3,1.1c-0.2,0.2-0.8,0.5-1,0.4c-0.1,0-0.2-0.3-0.2-0.4c0-0.2,0.3-0.6,0.4-0.8
			c0-0.1,0-0.4,0.1-0.6c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.2,0.4-0.7,0.3-0.9c-0.2-0.3-1,0.1-1.4,0c-0.2,0-0.5-0.1-0.7-0.2
			c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2-0.1-0.5-0.1-0.7-0.1c-0.3-0.1-1.1-0.4-1.2-0.4c0,0-0.1-0.1-0.2-0.2c-0.1-0.2-0.3-0.5-0.2-0.6
			c0.2-0.3,1.1-0.1,1.5,0c0.2,0,0.6,0.1,0.8,0.2c0.2,0.1,0.5,0.5,0.7,0.5c0.2,0.1,0.7,0.1,1,0.1c0.1,0,0.4-0.1,0.6-0.1
			c0.2,0.1,0.5,0.5,0.7,0.6c0.2,0.1,0.9,0.2,1,0c0.1-0.2-0.2-0.7-0.4-0.8c-0.2-0.2-0.9-0.4-1.2-0.5c-0.2-0.1-0.7-0.2-0.9-0.3
			c-0.2-0.1-0.3-0.6-0.5-0.7c-0.2-0.1-0.7-0.1-0.9-0.3c-0.1-0.1,0.1-0.6,0-0.7c-0.2-0.2-0.8-0.1-1.1-0.3c-0.2-0.2-0.5-0.8-0.6-1.1
			c0-0.2,0.2-0.7,0.2-0.9c0-0.2-0.1-0.6-0.1-0.8c0-0.2,0.1-0.8,0.3-0.9c0.1-0.1,0.4,0.1,0.5,0.3c0.1,0.2,0.1,0.7,0.2,0.9
			c0.1,0.2,0.2,0.7,0.3,0.9c0.1,0.2,0.6,0.3,0.7,0.4c0.1,0.2,0,0.7,0.1,0.9c0.2,0.2,0.7,0.1,0.9,0.2c0.2,0.1,0.6,0.1,0.7,0.3
			c0.1,0.1,0.1,0.5,0.2,0.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.4-0.1,0.5,0c0.2,0.1,0.6,0.3,0.7,0.5c0.1,0.1,0.1,0.5,0.2,0.6
			c0.2,0.2,0.9,0.2,1.1,0.4c0.2,0.2,0,0.8,0.1,1.1c0.1,0.2,0.5,0.4,0.7,0.5c0.1,0.1,0.4,0.4,0.5,0.4c0.2,0,0.2-0.5,0.3-0.7
			c0.1-0.2,0.2-0.6,0.4-0.7c0.1-0.1,0.5,0.1,0.7,0.1c0.2,0,0.6-0.1,0.8-0.1c0.2,0,0.6,0.1,0.8,0c0.1-0.1,0.2-0.5,0.1-0.6
			c-0.1-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.1-0.6,0.1-0.7c0.1-0.1,0.5,0,0.5,0c0.1,0,0.4-0.4,0.6-0.4c0.3,0,0.6,0.5,0.8,0.7
			c0.2,0.1,0.5,0.4,0.7,0.3c0.2-0.1,0.1-0.7,0.1-0.9c0-0.2-0.3-0.5-0.3-0.6c-0.1-0.2,0-0.7-0.1-0.9c-0.1-0.2-0.5-0.3-0.6-0.5
			c-0.1-0.2-0.2-0.7-0.1-0.9c0.1-0.1,0.4-0.2,0.5-0.3c0.1-0.2-0.1-0.7,0.1-0.8c0.2-0.1,0.6,0.1,0.7,0.2c0.1,0.2-0.1,0.7,0,0.9
			c0.1,0.2,0.5,0.2,0.6,0.4c0.1,0.3-0.1,0.9-0.1,1.2c0,0.2,0,0.5,0.1,0.6c0.1,0.1,0.5,0.1,0.7,0c0.1-0.1,0.3-0.3,0.4-0.5
			c0.1-0.1,0.1-0.4,0.2-0.5c0-0.2,0-0.6,0.1-0.8c0.1-0.2,0.8-0.3,0.8-0.5c0-0.3-0.8-0.6-0.8-0.9c0-0.2,0.5-0.2,0.6-0.4
			c0.1-0.2,0-0.8,0-1c-0.1-0.2-0.5-0.6-0.5-0.8c0-0.3,0.5-0.6,0.6-0.9c0-0.2,0-0.5-0.1-0.7c0-0.1-0.3-0.3-0.3-0.4
			c-0.1-0.2-0.2-0.7,0-0.8c0.1-0.1,0.6,0.1,0.7,0c0.2-0.1,0.2-0.6,0.2-0.8c0-0.3-0.6-0.7-0.6-1c0-0.2,0.3-0.5,0.4-0.6
			c0.1-0.1,0.5-0.1,0.6,0c0.2,0.1,0.4,0.7,0.5,1c0,0.2-0.1,0.7-0.1,0.9c0,0.2,0.2,0.7,0.4,0.8c0.2,0.1,0.8,0.2,1,0
			c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.2-0.5-0.2-0.7c0-0.2,0.1-0.7,0.3-0.9c0.2-0.2,0.8-0.2,1-0.4c0.2-0.1,0.6-0.5,0.8-0.5
			c0.1,0,0.3,0.1,0.5,0.1c0.1,0,0.4-0.2,0.5-0.3c0.1-0.1,0.2-0.5,0.4-0.6c0.1-0.1,0.5-0.2,0.7-0.2c0.2,0,0.4,0.4,0.6,0.4
			c0.2,0,0.6-0.1,0.7-0.2c0.2-0.1,0.4-0.5,0.5-0.7c0.1-0.3,0.1-1,0.3-1.1c0.1,0,0.3,0.2,0.4,0.3c0.3,0.1,0.9,0.1,1.1,0
			c0.2-0.1,0.4-0.7,0.4-1c0-0.2-0.6-0.4-0.6-0.6c-0.1-0.2,0-0.6,0.1-0.7c0.1-0.2,0.7,0,0.8-0.2c0.1-0.4-0.6-1-0.8-1.3
			c-0.1-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.5-0.3-0.6-0.4c-0.1-0.2-0.1-0.6-0.3-0.8c-0.1-0.1-0.3-0.5-0.5-0.5c-0.2,0-0.4,0.4-0.6,0.5
			c-0.2,0.1-0.7,0.3-0.9,0.2c-0.2-0.1-0.3-0.6-0.2-0.7c0.1-0.3,0.9-0.3,1.2-0.5c0.1-0.1,0.4-0.4,0.4-0.6c0-0.2,0-0.6-0.1-0.7
			c-0.1-0.2-0.7-0.5-0.8-0.7c0-0.1,0.1-0.3,0.1-0.4c0-0.2-0.3-0.6-0.5-0.7c-0.1-0.1-0.5-0.1-0.6,0c-0.2,0.1-0.3,0.4-0.5,0.6
			c-0.2,0.2-0.6,0.4-0.7,0.6c-0.1,0.2,0.3,0.7,0.2,0.8c-0.2,0.2-0.7-0.3-0.9-0.3c-0.2,0-0.4,0.3-0.5,0.4c-0.1,0.1-0.3,0.4-0.3,0.5
			c-0.1,0.2,0.1,0.8-0.1,1c-0.3,0.1-1-0.3-1.1-0.5c-0.1-0.2-0.1-0.6-0.3-0.7c-0.1-0.1-0.8,0.3-0.9,0.2c-0.1-0.1,0-0.5,0.1-0.6
			c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.3-0.5,0.4-0.6c0.1-0.2,0.1-0.6,0-0.7c-0.1-0.1-0.5,0-0.6,0.1c-0.1,0.1-0.1,0.4-0.2,0.5
			c-0.2,0.1-0.6,0.1-0.7-0.1c-0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.1,0.4-0.1,0.4-0.2c0-0.1-0.2-0.4-0.3-0.5c-0.1-0.1-0.4-0.1-0.5-0.1
			c-0.2,0.1-0.4,0.7-0.6,0.9c-0.1,0.1-0.2,0.3-0.3,0.3c-0.2,0-0.4-0.4-0.4-0.6c0-0.1,0.3-0.4,0.3-0.5c0-0.2-0.3-0.3-0.5-0.4
			c-0.2-0.1-0.6-0.1-0.8,0c-0.1,0.1-0.1,0.5-0.3,0.7c-0.1,0.1-0.4,0.2-0.5,0.3c-0.1,0.2,0.2,0.7-0.1,0.8c-0.2,0.1-0.5-0.2-0.7-0.4
			c-0.1-0.1,0.3-0.6,0.3-0.7c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1-0.1-0.5,0-0.7c0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.4-0.3-0.5
			c-0.1-0.2-0.2-0.6-0.1-0.8c0.1-0.1,0.3-0.4,0.4-0.4c0.1,0,0.4,0.2,0.5,0.3c0.1,0.2-0.1,0.6,0,0.7c0.1,0.2,0.5,0.2,0.6,0.4
			c0.1,0.2,0.2,0.6,0.3,0.8c0.1,0.1,0.6,0.1,0.7,0.1c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.6,0.1,0.7-0.1c0.1-0.2-0.3-0.7-0.3-0.9
			c0-0.2,0.2-0.5,0.2-0.7c0-0.2-0.4-0.5-0.6-0.7c-0.2-0.2-0.9-0.3-1-0.6c0-0.1,0.1-0.3,0.2-0.5c0.1-0.3,0.4-0.9,0.3-1.2
			c-0.1-0.2-0.5-0.4-0.6-0.6c-0.1-0.2,0.1-0.6,0-0.8c-0.1-0.2-0.5-0.5-0.7-0.5c-0.2,0-0.6,0.2-0.7,0.3c-0.1,0.1-0.1,0.6-0.3,0.7
			c-0.1,0.1-0.4,0.1-0.5,0c-0.1-0.1,0-0.5,0-0.7c0-0.2-0.1-0.5-0.2-0.6c-0.2-0.2-0.6-0.4-0.9-0.4c-0.2,0-0.5,0.1-0.6,0.2
			c-0.1,0.1-0.1,0.5-0.3,0.5c-0.1,0.1-0.5,0.1-0.6,0c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.3c0.1-0.1,0.4-0.1,0.5-0.3
			c0-0.1-0.1-0.4-0.2-0.5c-0.2-0.1-0.7-0.1-0.9-0.1c-0.1,0-0.4,0.1-0.5,0c-0.1-0.1-0.1-0.3,0-0.5c0-0.1,0.1-0.2,0.1-0.2
			c0.1-0.1,0.4-0.1,0.5-0.3c0-0.1,0-0.4-0.1-0.4c-0.1-0.1-0.5-0.2-0.7-0.3c-0.1,0-0.4,0-0.5,0.1c-0.1,0.1-0.2,0.4-0.3,0.5
			c-0.1,0.1-0.3,0.3-0.5,0.3c-0.1-0.1,0-0.5,0-0.7c0-0.1,0.2-0.4,0.1-0.5c-0.1-0.1-0.5-0.2-0.7-0.1c-0.1,0-0.2,0.3-0.3,0.3
			c-0.1,0.1-0.5,0.1-0.6,0c-0.1-0.1-0.2-0.5-0.1-0.7c0.1-0.2,0.5-0.4,0.7-0.5c0.2,0,0.7,0.1,0.9,0.2c0.1,0.1,0.3,0.3,0.4,0.3
			c0.2,0.1,0.7-0.1,0.9-0.1c0.2,0,0.4,0.4,0.6,0.3c0.2-0.1,0.1-0.5,0.2-0.7c0-0.1,0.1-0.3,0.2-0.4c0-0.2-0.1-0.7,0-0.9
			c0,0,0-0.1,0-0.2c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.5,0,0.6,0.1c0.1,0.1,0.1,0.6,0.1,0.8c0,0.3-0.4,0.8-0.4,1
			c0,0.2,0.2,0.5,0.3,0.7c0,0.2-0.2,0.7-0.1,1c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0,0.2,0,0.3,0c0.3-0.1,0.8-0.2,1.1-0.3
			c0.2-0.1,0.6-0.4,0.7-0.6c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.1-0.3-0.3-0.3-0.4c0,0,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.7,0.2-0.7
			c0.3-0.1,0.8,0.3,1,0.6c0,0.1,0,0.2,0,0.2c0.1,0.1,0.4,0.2,0.4,0.4c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.2,0.1,0.3
			c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.3,0.1,0.4,0.2c0.2,0.2,0.3,0.7,0.4,1c0.1,0.2,0.6,0.4,0.7,0.6c0.1,0.3-0.4,0.8-0.4,1.1
			c0,0.3,0.1,0.8,0.2,1c0.1,0.2,0.6,0.5,0.8,0.6c0.2,0.1,1,0.2,1.1,0.4c0.1,0.2-0.4,0.7-0.5,0.9c-0.1,0.2-0.2,0.7-0.2,0.9
			c0,0.2,0.3,0.5,0.4,0.6c0.2,0.2,0.7,0.3,0.8,0.5c0.1,0.2,0.1,0.6,0.2,0.8c0,0.2,0.2,0.6,0.2,0.8c0,0.2-0.4,0.5-0.4,0.7
			C271.2,210.5,271.4,210.8,271.6,210.9z M294.1,357.7l-8.2-17.8l0,0L294.1,357.7L294.1,357.7z M241.5,207.7L241.5,207.7
			L241.5,207.7L241.5,207.7z M124.4,292.4c-0.1-0.5-0.2-1.7-0.3-2.2c0.1,0.4,0.1,1.3,0.2,1.9C124.3,292.2,124.4,292.3,124.4,292.4z
			M209.6,217.2c0,0.1,0,0.3,0,0.4C209.6,217.5,209.5,217.3,209.6,217.2z M154.1,207.9C154.1,207.9,154.2,207.9,154.1,207.9
			C154.2,207.9,154.1,207.9,154.1,207.9z M191.7,216.8c0.2,0,0.4,0.1,0.6,0.2C192.1,216.9,191.9,216.8,191.7,216.8
			c-0.1,0-0.2,0-0.2,0C191.5,216.8,191.6,216.8,191.7,216.8C191.7,216.8,191.7,216.8,191.7,216.8z M220.3,271.1l-8.2,6.1l0,0
			L220.3,271.1z M203,171.9c0.1,0.3,0.3,0.7,0.3,0.9C203.3,172.6,203.1,172.2,203,171.9z M177.3,156.5c0,0-0.1,0-0.1-0.1
			C177.2,156.5,177.2,156.5,177.3,156.5C177.3,156.5,177.3,156.5,177.3,156.5z M178.4,206.3l-1.1-1.7l-3.3-4.9L178.4,206.3z
			M154.7,207.5c0.1,0,0.1-0.1,0.1-0.1C154.8,207.4,154.8,207.4,154.7,207.5z M139.7,257.1c0-0.1-0.1-0.1-0.1-0.2
			C139.7,256.9,139.7,257,139.7,257.1z M139.9,257.3C139.9,257.3,139.9,257.3,139.9,257.3c0-0.1-0.1-0.1-0.1-0.2
			C139.8,257.2,139.8,257.2,139.9,257.3z M138.5,261c-0.1,0.4-0.3,0.7-0.5,1.1C138.2,261.7,138.3,261.4,138.5,261z M137.8,262.5
			c-0.2,0.5-0.5,0.9-0.7,1.4C137.3,263.5,137.6,263,137.8,262.5z M137.1,264c-0.3,0.5-0.5,1-0.8,1.5
			C136.5,265,136.8,264.5,137.1,264z M139.7,195.9c0.3,0,0.7-0.1,0.9-0.2C140.2,195.8,139.7,195.9,139.7,195.9z M135.2,196.8
			C135.2,196.8,135.2,196.8,135.2,196.8c-0.4,0.1-0.8,0.2-1,0.4C134.5,197,134.8,196.9,135.2,196.8z M136.2,265.5
			c-1.2,2.1-2.6,4.2-3.9,6.2C133.7,269.7,135,267.7,136.2,265.5z M210.9,300.9c-1.1,0-2.2,0.1-3.3,0.1
			C208.7,301,209.8,300.9,210.9,300.9z M214.2,300.8c0.2,0,0.3,0,0.5,0l0,0C214.5,300.8,214.3,300.8,214.2,300.8
			C214.2,300.8,214.2,300.8,214.2,300.8z M138.1,158.6C138.1,158.6,138.1,158.6,138.1,158.6C138.1,158.6,138.1,158.6,138.1,158.6z
			M197.9,380C197.9,380,197.9,380,197.9,380C197.9,380,197.9,380,197.9,380z M240,304.7C240,304.7,240,304.7,240,304.7
			C240,304.7,240,304.7,240,304.7z M239.9,304.6c-0.1-0.1-0.1-0.2-0.2-0.4C239.8,304.4,239.9,304.5,239.9,304.6z M277.7,397.5
			C277.7,397.4,277.7,397.4,277.7,397.5C277.7,397.4,277.7,397.4,277.7,397.5z M277.9,397.7c0,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0
			C277.9,397.6,277.9,397.7,277.9,397.7z M287.2,461.6c0,0-0.1,0-0.1-0.1C287.1,461.6,287.2,461.6,287.2,461.6z M327.3,227.5
			C327.3,227.5,327.3,227.5,327.3,227.5C327.3,227.5,327.3,227.5,327.3,227.5L327.3,227.5z M327.4,227.3c0.4-0.5,1.7-2.2,2-2.7
			C329.1,225.2,328.1,226.4,327.4,227.3z M322.7,239.1c0-0.1,0.1-0.2,0.1-0.3C322.8,239,322.7,239.1,322.7,239.1z M283.8,133.9
			C283.8,133.9,283.8,134,283.8,133.9C283.8,134,283.8,133.9,283.8,133.9z M257.3,180.4c0,0-0.1-0.1-0.1-0.1
			C257.2,180.3,257.2,180.4,257.3,180.4z M257.1,180.2c-0.1-0.1-0.2-0.3-0.1-0.2C257,180.1,257.1,180.2,257.1,180.2z M269.2,143.7
			c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0C269.1,143.8,269.1,143.8,269.2,143.7z M268,153.7c0.1-0.8,0.1-1.7,0.2-2.5
			C268.2,152,268.1,152.8,268,153.7z M179.9,138.1c0,0,0.1,0,0.1,0C180,138.1,180,138.1,179.9,138.1z M180.5,138.2
			C180.5,138.2,180.6,138.2,180.5,138.2C180.6,138.2,180.5,138.2,180.5,138.2z M180.9,138.2C180.9,138.2,180.9,138.2,180.9,138.2
			C180.9,138.2,180.9,138.2,180.9,138.2z M176.9,137.7c0.1,0,0.2,0,0.2,0C177,137.7,177,137.7,176.9,137.7z M177.7,137.8
			c0.1,0,0.2,0,0.3,0C177.9,137.8,177.8,137.8,177.7,137.8z M178.5,137.9c0.1,0,0.2,0,0.3,0C178.7,137.9,178.6,137.9,178.5,137.9z
			M179.3,138c0.1,0,0.1,0,0.2,0C179.4,138,179.3,138,179.3,138z M100.8,172.6c0.7-1.1,1.4-2.4,2-3.6l0-3.1l0,3.1
			C102.3,170.2,101.5,171.5,100.8,172.6z M108,198.5C108,198.5,108,198.5,108,198.5C108,198.5,108,198.5,108,198.5L108,198.5z
			M114.9,237.9c0.4,0.4,0.7,0.7,1,1.1c-0.2-0.2-0.3-0.4-0.5-0.6C115.3,238.2,115.1,238.1,114.9,237.9z M112.9,236.1
			c0.5,0.4,1.3,1.1,2,1.7C114.2,237.2,113.5,236.6,112.9,236.1z M116.3,239.5c-0.1-0.2-0.2-0.3-0.4-0.5
			C116.1,239.2,116.2,239.4,116.3,239.5z M198,378.9C198,378.9,198,378.9,198,378.9C198,378.9,198,378.9,198,378.9
			C198,378.9,198,378.9,198,378.9z M257.2,366L257.2,366l-2.3-1.2L257.2,366z M266.7,382.3c0,0,0.1,0.1,0.1,0.2
			C266.8,382.4,266.8,382.3,266.7,382.3z M266.8,382.5c0,0.1,0.1,0.1,0.1,0.2C266.9,382.6,266.9,382.5,266.8,382.5z M266.9,382.7
			c0.1,0.2,0.2,0.5,0.2,0.6C267.1,383.2,267,382.9,266.9,382.7z M277.4,397.2c-0.1,0-0.1-0.1-0.2-0.1
			C277.3,397.1,277.3,397.2,277.4,397.2z M277.6,397.3c0,0-0.1-0.1-0.1-0.1C277.5,397.3,277.5,397.3,277.6,397.3z M344.8,282.6
			c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0,0C344.9,282.9,344.9,282.7,344.8,282.6z M318.5,194.7c0.1,0,0.2-0.1,0.2-0.1
			C318.6,194.6,318.5,194.7,318.5,194.7z M319.1,194.4c0.1,0,0.1-0.1,0.2-0.1C319.2,194.3,319.1,194.4,319.1,194.4z M317.8,195.1
			c0.1-0.1,0.2-0.1,0.3-0.2C318,195,317.9,195.1,317.8,195.1z M315.7,196.5c0.1-0.1,0.2-0.1,0.2-0.2
			C315.8,196.4,315.7,196.4,315.7,196.5z M316.3,196c0.1-0.1,0.2-0.1,0.3-0.2C316.5,195.9,316.4,196,316.3,196z M317.1,195.6
			c0.1-0.1,0.2-0.1,0.3-0.2C317.3,195.5,317.2,195.5,317.1,195.6z M314.5,197.2c0.2-0.1,0.4-0.2,0.6-0.4
			C314.9,197,314.7,197.1,314.5,197.2z M313.3,198c0.1-0.1,0.2-0.2,0.4-0.3C313.5,197.7,313.3,197.8,313.3,198z M261.5,106.6
			l-3.8-4.6L261.5,106.6L261.5,106.6z M241.4,143L241.4,143l2.6-1.8L241.4,143z M207.6,70.5c-0.1-0.1-0.1-0.2-0.2-0.3
			C207.4,70.4,207.5,70.5,207.6,70.5z M208.6,72.2C208.6,72.2,208.6,72.2,208.6,72.2C208.6,72.2,208.6,72.2,208.6,72.2
			C208.6,72.2,208.6,72.2,208.6,72.2z M207.1,69.9c0,0,0.1,0.1,0.1,0.1C207.1,69.9,207.1,69.9,207.1,69.9z M207.6,70.6
			c0,0.1,0.1,0.1,0.1,0.2C207.7,70.7,207.6,70.7,207.6,70.6z M207.8,70.9C207.8,71,207.8,71,207.8,70.9
			C207.8,71,207.8,71,207.8,70.9z M208,71.2C208,71.2,208,71.2,208,71.2C208,71.2,208,71.2,208,71.2z M207.3,59.7
			c-0.3,0.2-1.2,0.9-2.1,1.5C205.9,60.7,206.6,60.2,207.3,59.7z M198.3,77.9c0,0.7-0.1,1.4-0.1,1.9c0-0.4,0-1,0.1-1.6
			C198.3,78.1,198.3,78,198.3,77.9z M198.4,75.6c0,0.1,0,0.1,0,0.2C198.4,75.7,198.4,75.6,198.4,75.6z M198.4,76.7
			c0,0.3,0,0.6,0,0.9C198.4,77.3,198.4,77,198.4,76.7z M142.2,116c-0.2,0-0.5,0-0.8,0c0.4,0,0.7,0,1,0
			C142.4,116,142.3,116,142.2,116z M137.3,116.1c0,0,0.1,0,0.1,0C137.4,116.1,137.3,116.1,137.3,116.1z M137.6,116.1
			c0,0,0.1,0,0.1,0C137.7,116.1,137.7,116.1,137.6,116.1z M138,116.1c0.1,0,0.1,0,0.2,0C138.1,116.1,138,116.1,138,116.1z
			M138.4,116.1c0,0,0.1,0,0.1,0C138.5,116.1,138.5,116.1,138.4,116.1z M138.8,116c0.1,0,0.2,0,0.2,0C139,116,138.9,116,138.8,116z
			M139.3,116c0.1,0,0.2,0,0.4,0C139.5,116,139.4,116,139.3,116z M139.7,116c0.1,0,0.3,0,0.4,0C140,116,139.8,116,139.7,116z
			M140.1,116c0.5,0,0.9,0,1.3,0C141,116,140.6,116,140.1,116z M136.8,116.1C136.8,116.1,136.8,116.1,136.8,116.1
			C136.8,116.1,136.8,116.1,136.8,116.1z M137,116.1c0,0,0.1,0,0.1,0C137.1,116.1,137,116.1,137,116.1z M136.6,116.1
			C136.7,116.1,136.7,116.1,136.6,116.1C136.7,116.1,136.7,116.1,136.6,116.1z M112.3,235.7C112.3,235.6,112.3,235.6,112.3,235.7
			C112.3,235.6,112.3,235.6,112.3,235.7z M112.9,236.1c-0.3-0.2-0.5-0.4-0.6-0.5C112.5,235.8,112.7,235.9,112.9,236.1z M150.8,442.7
			c0,0-0.1,0-0.1-0.1c0.1,0.1,0.3,0.1,0.4,0.2C151,442.9,150.9,442.8,150.8,442.7z M291.5,511.7c0.1,0.1,0.2,0.3,0.3,0.5
			C291.7,512,291.6,511.9,291.5,511.7z M384.8,396.2c-0.1,0-0.2,0-0.3,0.1C384.6,396.2,384.7,396.2,384.8,396.2z M385.3,396.1
			c-0.2,0-0.3,0.1-0.5,0.1C385,396.1,385.2,396.1,385.3,396.1z M385.8,396.1c-0.1,0-0.2,0-0.3,0C385.6,396.1,385.7,396,385.8,396.1z
			M419.2,364c0.1,0,0.1,0,0.2,0C419.4,364,419.3,364,419.2,364z M456.6,360.6C456.6,360.6,456.6,360.6,456.6,360.6
			C456.6,360.6,456.6,360.6,456.6,360.6C456.6,360.6,456.6,360.6,456.6,360.6z M453.4,348.4c-0.1-0.1-0.1-0.2-0.2-0.3
			C453.3,348.2,453.4,348.3,453.4,348.4z M453.2,348.1c-0.1-0.1-0.1-0.2-0.2-0.3C453.1,347.9,453.2,348,453.2,348.1z M453,347.4
			c0,0.1,0.1,0.2,0.1,0.3C453,347.6,453,347.5,453,347.4z M445.4,326.5C445.4,326.5,445.4,326.5,445.4,326.5
			C445.4,326.5,445.4,326.5,445.4,326.5C445.4,326.5,445.4,326.5,445.4,326.5z M445.6,327.7c0-0.1,0-0.2,0-0.3
			C445.6,327.5,445.6,327.6,445.6,327.7z M445.6,327.2c0-0.1,0-0.1,0-0.2C445.5,327.1,445.6,327.1,445.6,327.2z M445.1,326.1
			c0.1,0.1,0.1,0.2,0.2,0.3C445.2,326.2,445.2,326.1,445.1,326.1z M457.9,392.2C457.9,392.2,457.9,392.2,457.9,392.2
			c0,0-0.1,0-0.1,0.1L457.9,392.2z M460.7,373.3c0.5-0.1,0.9-0.2,1.4-0.3C461.7,373.1,461.2,373.2,460.7,373.3L460.7,373.3z
			M265.3,107.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0C265.3,107.1,265.3,107.1,265.3,107.1z M175.5,57.8c-1.5-0.1-4-0.3-5.7-0.5v0
			C169.7,57.3,174,57.7,175.5,57.8z M71.2,87.1C71.1,87.1,71.1,87.2,71.2,87.1C71.1,87.2,71.1,87.2,71.2,87.1
			C71.1,87.2,71.1,87.2,71.2,87.1z M52.1,97.7c0.4,0,0.8,0.1,1.2,0.1C52.9,97.8,52.5,97.7,52.1,97.7z M80.9,228.8l4.6-3.7l-1,0.8
			L80.9,228.8z M114.7,450.9l1.2,0c0,1.1,0,2.4,0,3.6c0-1.2,0-2.4,0-3.6L114.7,450.9z M115.9,456.3
			C115.9,456.3,115.9,456.3,115.9,456.3c0,1.2-0.1,2.3-0.1,3.5C115.8,458.6,115.9,457.4,115.9,456.3z M142.8,494.4l-3.4,0
			L142.8,494.4L142.8,494.4z M156.4,494.7L156.4,494.7l0-0.2L156.4,494.7z M253.1,436.3c0,0,0.1,0.2,0.3,0.4
			C253.3,436.5,253.2,436.4,253.1,436.3C253.2,436.3,253.1,436.3,253.1,436.3z M253.4,436.7c0,0,0.1,0.1,0.1,0.1
			C253.5,436.8,253.5,436.7,253.4,436.7z M253.6,437c0,0,0.1,0.1,0.1,0.1C253.7,437,253.6,437,253.6,437z M253.8,437.2
			c0,0,0.1,0.1,0.1,0.1C253.9,437.3,253.9,437.3,253.8,437.2z M254,437.5c0,0,0.1,0.1,0.1,0.1C254.1,437.6,254,437.6,254,437.5z
			M254.2,437.8C254.2,437.8,254.3,437.9,254.2,437.8C254.3,437.9,254.2,437.8,254.2,437.8z M382.1,476.3c-0.4,0.2-1.4,0.2-1.8,0.5
			c-0.3,0.3-0.4,1.2-0.7,1.5c-0.3,0.3-1,0.9-1.4,0.8c-0.5,0-1.6-1.1-1.7-1.1c0-0.1-1,0.1-1.3,0c-0.4-0.1-0.9-0.7-1.3-0.7
			c-0.2,0-0.6,0.4-0.9,0.4c-0.4,0-1-0.5-1.3-0.5c-0.4,0-1.1,0.4-1.4,0.4c-0.4,0-1-0.6-1.4-0.7c-0.1,0-0.2-0.1-0.4-0.1l0,0
			c0.1,0,0.3,0.1,0.4,0.1c0.4,0.1,1,0.6,1.4,0.7c0.4,0,1.1-0.4,1.4-0.4c0.4,0,1,0.5,1.3,0.5c0.2,0,0.6-0.4,0.9-0.4
			c0.4,0,0.9,0.7,1.3,0.7c0.3,0.1,1.3-0.1,1.3,0c0,0.1,1.2,1.1,1.7,1.1c0.4,0,1.2-0.5,1.4-0.8c0.3-0.3,0.4-1.3,0.7-1.5
			C380.6,476.6,381.7,476.6,382.1,476.3c0.3-0.2,0.8-0.7,1-1c0,0,0,0,0,0C382.9,475.6,382.4,476.2,382.1,476.3z M383.1,473.1
			C383.1,473.1,383.1,473.1,383.1,473.1c0.4-0.5,1.9-0.5,2.3-1c0,0,0,0,0,0C385,472.6,383.5,472.6,383.1,473.1z M394.2,470.5
			c-0.3,0-0.9,0.1-1.2,0.1c-0.5,0.1-1.4,0.5-1.8,0.5c-0.5,0-1.3-0.4-1.8-0.5c-0.4-0.1-1.2-0.4-1.6-0.4c-0.4,0-1.3,0.4-1.6,0.7
			c0,0,0,0,0,0c0.3-0.3,1.2-0.6,1.6-0.7c0.4,0,1.2,0.3,1.6,0.4c0.4,0.1,1.3,0.5,1.8,0.5c0.5,0,1.4-0.4,1.8-0.5
			C393.2,470.5,393.9,470.5,394.2,470.5c0.1,0,0.4,0,0.5,0c0,0,0,0,0,0C394.6,470.5,394.3,470.5,394.2,470.5z M403.2,461.2
			C403.2,461.2,403.2,461.2,403.2,461.2C403.3,461.2,403.3,461.2,403.2,461.2C403.3,461.2,403.3,461.2,403.2,461.2z M424.5,431
			c-0.1-0.1-0.1-0.1-0.2-0.2c0.2,0.2,0.5,0.5,0.6,0.7C424.8,431.3,424.7,431.1,424.5,431z M446.8,467.9c0-0.9,0-1.9,0-2.9
			C446.9,466,446.9,467,446.8,467.9z"/>
	</g>
</g>

<g id="EMCs">        
	<path @click="openEmc" data-emc="north-georgia-emc" ref="north-georgia-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M131.1,34.9l-0.3-0.3V18.5l-19,0.2L95,18.8L67.6,19c-0.4,0.6-1.2,1-1.9,1.2c-0.1,0-0.3,0.1-0.4,0.1
		c-0.9,0.2-1.9,0.4-2.7,1.1c-1.5,1.3-1.4,4.1-3.2,4.8c-1.1,0.5-2.5-0.1-3.6,0.5c-1.1,0.6-1.3,2.1-1.3,3.3c0,1.3,0.1,2.7-0.7,3.6
		c-0.4,0.5-1,0.8-1.3,1.4c-0.3,0.7-0.1,1.6-0.5,2.2c-0.2,0.3-0.6,0.5-0.8,0.7c-1.1,0.9-1.2,2.5-1.1,4c0.1,1.4,0.4,2.9-0.1,4.2
		c-0.7,2.1-3.2,3.4-3.1,5.6c0,0.8,0.3,1.6-0.1,2.3c-0.2,0.3-0.6,0.5-0.7,0.8c-0.2,0.4-0.1,0.8,0,1.2c0.4,1.7,0.1,3.5-0.7,5
		c1.2,0.4,1,2.4,2,3.2c0.3,0.2,0.7,0.3,1.1,0.4c1.1,0.4,2.2,1,2.9,1.9c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.6-0.3,1.2-0.4,1.8
		c-0.9,0.2-1.7,0.6-2.4,1.1c0.2,0.7,0.2,1.4,0,2.1c1.5,0.1,3,0.3,4.4,0.7c-1.3,3.2-2,6.6-1.9,10c0,0.9,1.6,1,2.4,0.5
		c1.2-0.8,2.1-1.9,2.6-3.2c0.1-0.4,0.2-0.7,0.4-1.1c0.3-0.5,0.7-1,1.1-1.4c2-2.2,3.2-5.3,3.1-8.3c1-0.5,1.8-1.2,2.4-2.1
		c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.5,0.5-1,0.9-1.4c1-1.1,2.7-1,4.1-0.8c0.2,0,0.4,0.1,0.5,0.2c0.1,0.2,0.1,0.4,0.1,0.6
		c-0.1,0.6-0.1,1.1-0.2,1.7c-0.1,1.1-0.2,2.3-0.3,3.4c-0.1,0.7-0.1,1.4-0.4,2c-0.4,1-1.1,1.9-1.8,2.8c-1.2,1.8-2,3.9-3,5.9
		c-2.6,5.7-6.5,10.9-11.3,15c-0.2,0.2-0.4,0.3-0.4,0.6c0,0.4,0.5,0.6,0.9,0.6c1.3,0.1,2.6,0.1,3.9,0.1c0.6-0.8,1.3-1.5,1.6-2
		c0.5-0.7,1.3-2.2,2-2.6c0.2-0.1,0.9-0.1,1.1-0.1c0.6-0.2,1.8-0.8,2.4-1.1c0.6-0.3,2-0.8,2.6-1.1c0.4-0.2,1.3-0.6,1.7-0.9
		c0.3-0.3,0.7-1.2,1.1-1.4c0.3-0.2,0.9-0.2,1.2-0.4c0.4-0.2,0.9-0.7,1.3-1c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c1.9,1.1,3.9,2.2,5.8,3.2
		c0.4,0.2,0.9,0.5,1.4,0.4c0.6-0.1,1-0.7,1.5-1.1c0.7-0.6,1.5-1,2.4-1.3v0l1.7-1.2c0.8-0.5,1.6-1,2.6-0.8c0.5,0.1,1,0.4,1.4,0.6
		c0.5,0.3,1,0.6,1.4,0.9c1.4,0.9,1.7,1.1,3,2h1.4v2h1v-1.6h1v1.9h1.9v-1.9h22.5v-1.5h2.9c0-2.5,0.1-5,0.1-7.5c0-0.2,0-0.3-0.1-0.4
		c-0.1-0.1-0.8-2.2-0.8-2.2s1.3-1.5,1.8-2.3c1.1-1.7,2.4-3.3,3.9-4.6l0-4.1l-0.1-17.7l3-11.6L131.1,34.9z"/>
	<path @click="openEmc" data-emc="tri-state-emc" ref="tri-state-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M164.8,38.3c1-0.8,2-1.6,3.1-2.3c-0.3-0.7-0.6-1.4-1-2c1.3-1.3,2.6-2.6,4-3.8c-1.2-1.6-2.5-3.2-3.7-4.8
		c0.8-1.6,2-2.9,3.1-4.2c0.9-1,1.8-2,2.7-3.1l-42.2,0.4v16.2l0.3,0.3l9.7,1.3l10.8-0.1l4.8,2.7l4.3,1.4v1.5h1.6
		c0.5-1.1,1.2-2.3,1.5-2.7C164.1,38.8,164.4,38.5,164.8,38.3z"/>
	<path @click="openEmc" data-emc="blue-ridge-mountain-emc" ref="blue-ridge-mountain-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M240.5,21.2c-0.1-0.4-0.4-1.2-0.4-1.6c0-0.3,0.1-0.8,0.1-1.1c0-0.2-0.1-0.6-0.3-0.9l-39.3,0.3L180.5,18
		l-7.5,0.1c-0.9,1-1.8,2-2.7,3.1c-1.2,1.3-2.3,2.6-3.1,4.2c1.2,1.6,2.5,3.2,3.7,4.8c-1.4,1.2-2.7,2.5-4,3.8c0.3,0.7,0.6,1.4,1,2
		c-1,0.8-2,1.6-3.1,2.3c-0.3,0.3-0.7,0.5-0.9,0.8c-0.3,0.3-1,1.5-1.5,2.7l5.7,9v1.3h0.8l0.1,4.2l0.8,1c0,0,4.3,0.4,5.7,0.5
		c2.4,0.2,2.5-0.3,2.7,0.7c0.1,1.1,0.6,1.3,0.6,1.3c0.2,0,0.5,0.2,0.7,0.2c0.2,0,0.6-0.3,0.8-0.5c0.2-0.2,0.2-0.8,0.4-1
		c0.2-0.2,0.8-0.3,1-0.2c0.4,0,1,0.4,1.4,0.5c0.3,0.1,0.9,0.4,1.2,0.5c0.4,0.1,1.3,0.2,1.8,0.1c0.2-0.1,0.3-0.4,0.5-0.4
		c0.2-0.1,0.7,0.1,0.9,0.2c0.3,0.2,0.7,0.8,1,1c0.2,0.1,0.6,0.4,0.8,0.5c0.4,0.1,1.3,0.3,1.5,0c0.2-0.2-0.4-0.8-0.3-1
		c0.1-0.2,0.9-0.1,1.1-0.3c0.2-0.2,0-0.9,0.1-1.1c0.1-0.2,0.4-0.6,0.6-0.8c0.3-0.3,1.2-0.5,1.6-0.7c0.3-0.2,1-0.6,1.2-0.9
		c0.3-0.4,0.4-1.4,0.6-1.8c0.1-0.2,0.5-0.7,0.5-0.9c0-0.2-0.2-0.5-0.1-0.7c0.1-0.3,0.9-0.5,1.2-0.7c0.3-0.3,0.5-1,0.8-1.3
		c0.3-0.2,1-0.1,1.3-0.3c0.1-0.1,0.2-0.5,0.4-0.7c0.2-0.2,0.9-0.5,1.2-0.5c0.3,0,0.7,0.4,0.9,0.5c0.2,0,0.7-0.1,0.9,0
		c0.4,0,1.1,0.1,1.5,0.3c0.3,0.1,0.8,0.7,1.1,0.8c0.3,0.1,1,0.2,1.3,0.2c0.2,0,0.7,0,0.9,0c0.4,0,1.1,0,1.4,0.1
		c0.1,0,0.4,0.2,0.6,0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.3,1.4-0.1,1.7-0.4c0.2-0.2,0-0.7,0.1-0.9c0.1-0.3,0.8-0.6,0.9-0.9
		c0.1-0.4-0.2-1.1-0.3-1.4c0-0.4-0.1-1.1,0-1.5c0.2-0.6,0.8-1.8,1.3-2.2c0.4-0.4,1.5-0.6,2.1-0.7c0.4-0.1,1-0.3,1.6-0.5
		c0.3-0.1,0.6-0.2,0.8-0.2c0.6-0.1,1.9-0.4,2.5-0.4c0.6,0,1.9,0.1,2.5,0.3c0.4,0.1,1.1,0.6,1.5,0.7c0.3,0,0.9-0.1,1.2-0.3
		c0.1-0.1,0.2-0.5,0.4-0.6c0.2-0.1,0.8,0,1.1,0.2c0.1-0.2,0.3-0.4,0.3-0.5c0.1-0.2-0.1-0.5,0-0.7c0.1-0.1,0.6,0,0.8,0
		c0.2-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.2-0.7,0.4-1c0.1-0.1,0.2-0.4,0.4-0.5c0,0-0.1-0.1-0.1-0.1c-0.2-0.3-0.5-1.1-0.5-1.5
		c0-0.2,0-0.7,0-0.9c0-0.2,0-0.7,0-1c0.1-0.3,0.5-0.6,0.6-0.9c0.1-0.3,0-1,0-1.3c0-0.2-0.2-0.7-0.1-0.9c0.1-0.2,0.6-0.2,0.8-0.3
		c0.2-0.2,0.1-1,0.3-1.2c0.2-0.2,0.8,0.1,1.1-0.1c0.1-0.1,0.3-0.4,0.4-0.5c0.1-0.2,0-0.6,0.1-0.7c0.1-0.2,0.4-0.6,0.5-0.7
		c0.2-0.2,0.6-0.4,0.8-0.4c0.3-0.1,0.9,0.2,1.2,0.1c0.3-0.1,1-0.6,1.1-0.9c0.1-0.2-0.2-0.6-0.3-0.8c-0.1-0.2-0.4-0.7-0.4-0.9
		c0-0.2-0.2-0.6-0.1-0.8c0.2-0.3,1.1,0,1.4-0.1c0.3-0.1,0.7-0.4,1-0.4c0.3-0.1,0.8,0,1.1,0c0.4,0,1.4,0.2,1.8-0.1
		c0.2-0.2,0.4-0.8,0.5-1.1C240.7,22.5,240.6,21.6,240.5,21.2z"/>
	<path @click="openEmc" data-emc="habersham-emc" ref="habersham-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M277,23c-0.2-0.4-0.4-0.8-0.7-1.1c-0.2-0.3-0.5-0.5-0.8-0.8c-0.7-0.6-1.3-1.1-2-1.6c0.4-0.8,0.7-1.5,1.1-2.3
		l-6.6,0.1c-0.5,0.6-0.9,1.2-1.4,1.9c-0.1,0.1-0.2,0.3-0.3,0.3c-0.3,0.2-0.7-0.1-1-0.4c-0.2-0.2-0.8-1.2-1.4-1.8l-23.9,0.2
		c0.1,0.3,0.2,0.7,0.3,0.9c0,0.3-0.1,0.8-0.1,1.1c0,0.4,0.3,1.2,0.4,1.6c0.1,0.4,0.2,1.3,0.1,1.7c-0.1,0.3-0.2,0.9-0.5,1.1
		c-0.4,0.2-1.3,0.1-1.8,0.1c-0.3,0-0.8-0.1-1.1,0c-0.3,0.1-0.7,0.4-1,0.4c-0.3,0.1-1.2-0.2-1.4,0.1c-0.1,0.1,0,0.6,0.1,0.8
		c0.1,0.3,0.3,0.7,0.4,0.9c0.1,0.2,0.4,0.6,0.3,0.8c-0.1,0.4-0.8,0.8-1.1,0.9c-0.3,0.1-0.9-0.1-1.2-0.1c-0.2,0-0.7,0.3-0.8,0.4
		c-0.2,0.1-0.4,0.5-0.5,0.7c-0.1,0.2,0,0.6-0.1,0.7c-0.1,0.1-0.2,0.4-0.4,0.5c-0.2,0.1-0.8-0.1-1.1,0.1c-0.2,0.2-0.1,1-0.3,1.2
		c-0.1,0.2-0.7,0.1-0.8,0.3c-0.1,0.2,0.1,0.7,0.1,0.9c0,0.3,0.1,1,0,1.3c-0.1,0.3-0.5,0.6-0.6,0.9c-0.1,0.2,0,0.7,0,1
		c0,0.2,0,0.7,0,0.9c0.1,0.4,0.3,1.2,0.5,1.5c0,0,0.1,0.1,0.1,0.1c-0.1,0.2-0.3,0.4-0.4,0.5c-0.1,0.2-0.2,0.8-0.4,1
		c-0.2,0.2-0.6,0.5-0.9,0.6c-0.2,0.1-0.7-0.1-0.8,0c-0.1,0.1,0,0.5,0,0.7c0,0.1-0.2,0.3-0.3,0.5c-0.3-0.2-0.8-0.3-1.1-0.2
		c-0.2,0.1-0.2,0.5-0.4,0.6c-0.2,0.2-0.9,0.3-1.2,0.3c-0.4,0-1.1-0.5-1.5-0.7c-0.6-0.2-1.9-0.3-2.5-0.3c-0.6,0-1.9,0.3-2.5,0.4
		c-0.2,0-0.5,0.1-0.8,0.2c-0.6,0.2-1.2,0.3-1.6,0.5c-0.5,0.2-1.6,0.4-2.1,0.7c-0.5,0.4-1.1,1.5-1.3,2.2c-0.1,0.4,0,1.1,0,1.5
		c0,0.4,0.4,1.1,0.3,1.4c-0.1,0.3-0.7,0.6-0.9,0.9c-0.1,0.2,0,0.7-0.1,0.9c-0.3,0.3-1.4,0.1-1.7,0.4c-0.1,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.3-0.2,0.6-0.3,0.8c-0.2,0.6-0.1,2-0.3,2.6c-0.1,0.3-0.7,0.9-0.9,1.2c-0.3,0.4-1,1.3-1.1,1.9c0,0.5,0.6,1.3,0.7,1.8l0,0.3
		c0,0-2.2,1.5-3.1,2.4c-0.4,1.6-0.1,3.3,0.7,4.6c0.5,0.7,1.2,2.1,1.7,2.8c1,1.3,1.2,1.8,1.9,2.8c0.3,0.3,1.4,0.4,1.8,0.7
		c0.4,0.3,0.9,1.2,0.9,1.6c0,0.2-0.3,0.7-0.5,0.8c-0.1,0.1-0.5,0.2-0.5,0.2l-0.1,1.8l0,0.1c2.7,2.2,5.5,4.5,8.2,6.7
		c0.8,0.7,1.6,1.3,2.3,2.1c0.2,0,0.5,0,0.6,0.1c0.2,0.1,0.6,0.4,0.8,0.5c0.1,0,0.4,0,0.5,0.1c0.1,0.1-0.1,0.5,0,0.6
		c0.1,0.2,0.6,0.2,0.8,0.2c0.2-0.1,0.3-0.5,0.5-0.6c0.2-0.1,0.8,0.1,1,0c0.1,0,0.3-0.3,0.4-0.4c0.2-0.1,0.6-0.3,0.9-0.4
		c0.1,0,0.4,0.1,0.6,0.1c0.2,0,0.4-0.3,0.6-0.4c0.2,0,0.5,0,0.6,0c0.2,0,0.8-0.1,0.9,0.1c0,0.1,0,0.2,0,0.3c0.4,0.1,0.8,0.2,1.1,0.3
		c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2-0.1c0.9-0.3,1.8-0.6,2.8-0.7l0.1,0.1c0,0,0-0.1,0-0.1c0.2-0.4,0.5-1.1,0.7-1.4
		c0.2-0.2,0.6-0.5,0.8-0.6c0.4-0.2,1.2-0.2,1.5-0.4c0.3-0.1,0.7-0.6,0.8-0.8c0.2-0.3,0.3-1,0.5-1.2c0.1-0.1,0.5-0.3,0.6-0.4
		c0.1-0.2,0.2-0.7,0.4-0.9c0.1-0.1,0.5-0.2,0.7-0.3c0.3-0.1,1-0.1,1.3-0.2c0.3-0.2,0.6-1.1,1-1.1c0.3,0,0.8,0.6,1.1,0.6
		c0.4,0.1,1.2-0.1,1.6-0.1c0.8,0,2.3,0.3,3.1,0.4c0.4,0.1,1,0.2,1.6,0.3l5.5-13.4c1.1-0.6,2.4-0.7,3.5-1.1c1.1-0.4,2.1-1.1,3-1.8
		c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.1-0.3,0-0.4c0-0.8-0.1-1.7-0.1-2.5c0-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.3-0.4-0.5
		c-0.6-0.7-1.6-2-2.3-2.7c-0.6-0.7-0.8-2-0.8-2.1c0.1-0.1,0.2-0.2,0.3-0.3c0.6-0.3,1.2-0.7,1.8-1c0.1-0.3,0.2-0.5,0.2-0.7
		c0-0.2-0.3-0.5-0.5-0.7c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1-0.4-0.1-1.2,0.1-1.6c0.2-0.3,0.9-0.7,1.2-0.9c0.4-0.4,1.4-1,1.8-1.5
		c0.3-0.4,0.5-1.3,0.6-1.8c0.1-0.5-0.1-1.4-0.1-1.8c0-0.5,0.2-1.9,0.2-2c0-0.1,1.1-0.5,1.3-0.8c0.2-0.3-0.1-1.2,0.1-1.5
		c0.2-0.2,0.7-0.4,1-0.4c0.5-0.1,1.4,0.3,1.9,0.2c0.4-0.1,1.3-0.8,1.4-1.3c0-0.4-0.9-0.9-0.9-1.4c0-0.3,0.3-0.9,0.5-1.1
		c0.4-0.2,1.7,0.6,2,0.2c0.2-0.2-0.3-0.9-0.3-1.2c0-0.4,0.4-1.3,0.7-1.6c0.2-0.4,0.8-1.1,1.2-1.3c0.4-0.2,1.3,0.1,1.7-0.1
		c0.4-0.1,1-0.7,1.3-1c0.3-0.3,0.7-1.1,1.1-1.3c0.3-0.2,1.2-0.3,1.4-0.7c0.3-0.4,0-1.5,0.4-1.8c0.2-0.1,0.7,0.2,1,0.1
		c0.2-0.1,0.3-0.5,0.4-0.8C278.6,25.2,277.7,24.2,277,23z"/>
	<path @click="openEmc" data-emc="amicalola-emc" ref="amicalola-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M208.7,50.7c-0.3-0.1-1.1-0.1-1.4-0.1c-0.2,0-0.7,0-0.9,0c-0.3,0-1-0.1-1.3-0.2c-0.3-0.1-0.8-0.7-1.1-0.8
		c-0.3-0.2-1.1-0.3-1.5-0.3c-0.2,0-0.7,0.1-0.9,0c-0.2-0.1-0.7-0.4-0.9-0.5c-0.3,0-1,0.3-1.2,0.5c-0.1,0.1-0.2,0.6-0.4,0.7
		c-0.3,0.2-1,0.1-1.3,0.3c-0.3,0.2-0.5,1-0.8,1.3c-0.2,0.2-1.1,0.4-1.2,0.7c-0.1,0.2,0.2,0.5,0.1,0.7c0,0.3-0.4,0.7-0.5,0.9
		c-0.2,0.4-0.3,1.4-0.6,1.8c-0.2,0.3-0.9,0.8-1.2,0.9c-0.4,0.2-1.2,0.4-1.6,0.7c-0.2,0.1-0.5,0.5-0.6,0.8c-0.1,0.3,0.1,0.9-0.1,1.1
		c-0.2,0.2-0.9,0.1-1.1,0.3c-0.1,0.2,0.4,0.8,0.3,1c-0.2,0.3-1.1,0.1-1.5,0c-0.2-0.1-0.7-0.3-0.8-0.5c-0.3-0.2-0.7-0.8-1-1
		c-0.2-0.1-0.7-0.3-0.9-0.2c-0.2,0-0.3,0.4-0.5,0.4c-0.4,0.1-1.3,0-1.8-0.1c-0.3-0.1-0.9-0.3-1.2-0.5c-0.3-0.1-1-0.5-1.4-0.5
		c-0.3,0-0.8,0.1-1,0.2c-0.2,0.2-0.2,0.8-0.4,1c-0.1,0.2-0.6,0.4-0.8,0.5c-0.2,0-0.5-0.2-0.7-0.2c0,0-0.5-0.3-0.6-1.3
		c-0.1-1.1-0.3-0.5-2.7-0.7c-1.5-0.1-4-0.3-5.7-0.5c-0.1-0.1-0.8-1-0.8-1l-0.1-4.2H168v-1.3l-5.7-9h-1.6v-1.5l-4.3-1.4l-4.8-2.7
		l-10.8,0.1l-9.7-1.3l-0.3,3.3l-3,11.6l0.1,17.7l0,4.1c-1.5,1.3-2.8,2.9-3.9,4.6c-0.5,0.8-1.8,2.3-1.8,2.3s0.7,2.1,0.8,2.2
		c0.1,0.1,0.1,0.3,0.1,0.4c0,2.5-0.1,5-0.1,7.5c0.4,0.6,3.2,4.4,4.9,6.4l-0.1,8.7c-1.8,1.1-7,4-7,4l1.4,3.7l1.9-0.2l0.7-0.9l0.3,2.3
		c0,0,1.6,2.2,2.6,3.3l-0.1,6.4c0.6,0.4,1.2,0.9,1.7,1.5c0.5-0.2,1-0.4,1.6-0.6c0.1-1.3,0.3-2.6,0.5-3.9c1.6-1.1,5-3.4,5.2-3.4
		c0.1,0,4.1-0.1,5.9-0.2c0.5-1,1-1.8,1.3-2.8c0.1-0.5,0.3-1,0.4-1.5c0-0.8,0.1-1.6,0.1-2.5c0-0.3,0-0.5,0.1-0.8
		c0.1-0.3,0.4-0.6,0.6-0.8c0.8-0.8,1.7-1.5,2.7-1.9c1.7,0,3.5,0.1,5.2,0.2c0.6-1.3,1.2-2.7,1.5-4.1c1.2,0,3.1,0.1,3.7-0.1
		c0.3-0.1,0.5-0.3,0.8-0.5c1.2-0.8,2.5-1.5,3.9-2c0.7,0.8,2.2,2.6,2.2,2.6s2-0.2,3.2-0.6c0,0,0.1,0,0.1,0c0.5-0.2,0.9-0.3,1.3-0.6
		c0.5-0.4,0.8-0.9,1.1-1.3c0.2-0.3,0.5-0.7,0.7-1c1.3-1.9,2.6-3.7,4-5.4h7.3c0.2,0,0.4,0.1,0.5,0.1l6.3-6.3c0.1,0,0.1-0.1,0.2-0.2
		c0-0.5,0-0.9,0-1.3c0-0.3,0-0.6,0-0.9c0-0.5,0.1-1.2,0.3-1.9c-0.3-0.9-0.6-1.4-0.8-2.1c0-0.2-0.1-0.3,0-0.5c0-0.2,0.5-1.4,0.5-1.4
		h8.5c0.1-1.3,0.1-2.7,0.1-2.7l2.4-2.2l4.2,0.5c0,0,1.3-0.1,1.9-0.4c0.5-0.3,1.4-0.9,1.7-1.1c-0.7-1-0.9-1.5-1.9-2.8
		c-0.5-0.7-1.3-2-1.7-2.8c-0.9-1.4-1.1-3.1-0.7-4.6c1-0.9,2-1.7,3.1-2.4c0-0.1,0-0.2,0-0.3c-0.1-0.5-0.7-1.3-0.7-1.8
		c0-0.5,0.8-1.4,1.1-1.9c0.2-0.3,0.8-0.8,0.9-1.2c0.3-0.6,0.2-1.9,0.3-2.6c0.1-0.2,0.1-0.6,0.3-0.8
		C209.1,50.9,208.9,50.7,208.7,50.7z"/>
		<path @click="openEmc" data-emc="sawnee-emc" ref="sawnee-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M201.4,111.5C201.4,111.5,201.4,111.5,201.4,111.5c-0.2,0-0.5-0.3-0.7-0.4c-0.2,0-0.5,0-0.6,0.1
			c-0.1,0.1-0.4,0.4-0.4,0.5c0,0.2,0.5,0.5,0.5,0.7c0.1,0.2,0.2,0.5,0.1,0.7c-0.2,0.2-0.8,0.1-1.1,0.1c-0.2,0-0.7-0.3-0.9-0.4
			c-0.2,0-0.5,0.1-0.6,0.2c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.2-0.2,0.6-0.1,0.8c0.1,0.2,0.5,0.4,0.7,0.4c0.3,0.1,1-0.2,1.3-0.1
			c0.2,0.1,0.4,0.5,0.6,0.6c0.2,0.1,0.7,0.2,0.9,0.3c0.3,0.2,1,0.6,1.1,1c0,0.3-0.5,0.8-0.7,0.9c-0.2,0.1-0.7-0.2-0.9-0.4
			c-0.2-0.1-0.3-0.5-0.5-0.6c-0.3-0.1-0.9,0-1.2,0.1c-0.1,0-0.3,0.2-0.5,0.2c-0.2,0-0.5-0.2-0.6-0.3c-0.1-0.1-0.3-0.4-0.5-0.5
			c-0.2-0.1-0.6-0.3-0.9-0.2c-0.2,0-0.5,0.3-0.5,0.4c-0.1,0.2,0,0.5,0.1,0.7c0.1,0.2,0.5,0.3,0.7,0.4c0.2,0.2,0.5,0.6,0.5,0.8
			c0,0.1-0.2,0.4-0.3,0.5c-0.2,0.1-0.6,0.1-0.8,0c-0.2-0.1-0.4-0.6-0.6-0.6c-0.2,0-0.6,0.4-0.9,0.4c-0.2,0-0.5-0.2-0.6-0.3
			c-0.2-0.2-0.2-0.8-0.4-1c-0.2-0.2-0.7-0.5-0.9-0.6c-0.3-0.2-0.9-0.5-1.2-0.6c-0.2,0-0.7,0.1-0.9,0.3c-0.1,0.1-0.3,0.4-0.3,0.5
			c0,0.1-0.1,0.5,0,0.6c0.1,0.2,0.6,0.2,0.9,0.2c0.2,0,0.4-0.4,0.5-0.4c0.1,0,0.2,0.3,0.3,0.4c0.1,0.2,0,0.6,0,0.8
			c0,0.2,0.1,0.5,0,0.7c-0.1,0.2-0.5,0.3-0.7,0.3c-0.2,0-0.5-0.3-0.7-0.2c-0.2,0.1-0.5,0.6-0.5,0.6s-0.4,0.8-0.6,1
			c-0.1,0.1-0.5,0.1-0.6,0.1c-0.3,0-0.7-0.5-1-0.5c0-0.2-0.4-0.4-0.6-0.6c-0.1-0.2-0.4-0.5-0.5-0.7c-0.1-0.2-0.2-0.6-0.2-0.8
			c0-0.2,0.2-0.5,0.2-0.6c0.1-0.3,0.6-0.9,0.4-1.1c-0.1-0.2-0.7,0.1-0.9,0c-0.2-0.1-0.2-0.6-0.3-0.7c-0.3-0.3-1.1-0.5-1.5-0.6
			c-0.2,0-0.6,0.1-0.7,0c-0.2-0.1-0.6-0.7-0.5-1c0-0.3,0.8-0.6,0.8-0.9c0-0.2-0.3-0.6-0.5-0.8c-0.1-0.1-0.5-0.2-0.6-0.4
			c-0.1-0.1-0.4-0.5-0.4-0.7c0-0.2,0.2-0.6,0.3-0.6c0.3-0.1,0.8,0.6,1.1,0.7c0.1,0.1,0.5,0.2,0.6,0.1c0.2-0.1,0.3-0.6,0.5-0.7
			c0.2-0.1,0.8-0.2,1-0.1c0.1,0.1,0.1,0.6,0.1,0.7c-0.1,0.2-0.4,0.5-0.5,0.6c-0.1,0.2-0.5,0.8-0.4,1c0.1,0.2,0.5,0.5,0.7,0.6
			c0.2,0,0.5-0.3,0.6-0.2c0.2,0.1,0,0.6,0.1,0.8c0.1,0.2,0.5,0.8,0.8,0.7c0.2,0,0.4-0.5,0.4-0.8c0-0.2,0-0.7-0.1-0.9
			c-0.1-0.2-0.5-0.5-0.6-0.7c-0.1-0.3,0-1,0-1.4c0-0.3-0.2-0.9-0.2-1.2c0.1-0.2,0.3-0.7,0.5-0.8c0.2,0,0.5,0.3,0.6,0.4
			c0.2,0.2,0.6,0.7,0.6,1c0,0.3-0.4,1.2-0.4,1.3c0,0.1,0.3,0.8,0.5,1c0.2,0.2,0.7,0.2,1,0.2c0.3,0,0.9-0.1,1.2-0.3
			c0.2-0.1,0.6-0.6,0.7-0.8c0.1-0.2,0.1-0.8,0.1-1.1c-0.1-0.4-0.6-1-0.9-1.2c-0.3-0.3-1-0.6-1.2-0.9c-0.2-0.2-0.4-0.8-0.4-1.1
			c0-0.3-0.2-1,0.1-1.1c0.3-0.1,0.7,0.5,0.9,0.8c0.1,0.1,0.1,0.5,0.2,0.6c0.1,0.2,0.5,0.3,0.7,0.4c0.2,0.1,0.7,0.2,0.8,0.4
			c0.2,0.2,0.2,0.7,0.4,0.8c0.2,0.2,0.7,0.2,0.9,0.4c0.2,0.2,0.4,0.9,0.7,1.1c0.3,0.1,1-0.1,1.3-0.2c0.2,0,0.6,0,0.8-0.1
			c0.2-0.3-0.2-1.2-0.2-1.6c0-0.3-0.2-0.9-0.2-1.2c0-0.2,0.3-0.6,0.4-0.8c0.1-0.3,0.2-1.1,0.5-1.2c0.2,0,0.5,0.4,0.6,0.6
			c0.1,0.2,0,0.7,0,1c0,0.3-0.2,0.9-0.1,1.2c0,0.3,0.2,0.9,0.4,1.1c0.1,0.1,0.5,0.3,0.6,0.2c0.2-0.1,0.2-0.6,0.3-0.8
			c0.1-0.2,0.5-0.4,0.5-0.6c0.1-0.3-0.3-0.8-0.4-1.1c-0.1-0.3,0.1-1.1,0-1.4c-0.1-0.3-0.8-0.6-0.9-0.9c-0.1-0.3-0.2-0.9-0.1-1.2
			c0.1-0.3,0.4-0.8,0.7-0.8c0.2,0,0.6,0.3,0.7,0.5c0.1,0.2-0.1,0.8,0,1c0.1,0.2,0.4,0.4,0.6,0.5c0.3,0,0.7-0.3,0.9-0.4
			c0.1-0.1,0.4-0.4,0.5-0.6c0.1-0.3-0.1-0.9-0.2-1.1c-0.2-0.3-1.2-0.4-1.4-0.8c-0.1-0.2,0-0.7,0.1-0.9c0.1-0.3,0.4-0.8,0.3-1.1
			c0-0.2-0.3-0.6-0.4-0.8c-0.1-0.1-0.3-0.3-0.5-0.4c-0.3-0.1-0.8,0.2-1.1,0.2c-0.3,0-1-0.1-1.2-0.2c-0.3-0.2-0.8-0.6-0.9-0.9
			c0,0,0-0.1,0-0.1c0-0.2,0.1-0.5,0.2-0.6c0.2-0.1,0.8-0.1,1-0.1c0.3,0.1,0.5,0.7,0.7,0.8c0.2,0.1,0.6,0,0.7-0.1
			c0.1-0.1,0.1-0.5,0-0.6c-0.1-0.3-0.7-0.5-1-0.7c-0.2-0.1-0.6-0.3-0.8-0.4c-0.2-0.1-0.6-0.2-0.8-0.2c-0.3-0.1-1,0.1-1.1-0.2
			c-0.1-0.2,0-0.6,0.1-0.8c0.1-0.2,0.6-0.3,0.9-0.4c0.2-0.1,0.6,0,0.8-0.1c0.2-0.2,0.2-0.7,0.2-1c0-0.3-0.5-1-0.4-1.3
			c0-0.2,0.5-0.4,0.6-0.6c0.1-0.2,0-0.9,0.1-1.1c0-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.5-0.6,0.4-0.9c-0.1-0.3-0.8-0.3-1-0.5
			c-0.1-0.1-0.2-0.4-0.2-0.5c0-0.2,0.4-0.4,0.5-0.6c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.6-0.2-0.7-0.4c-0.1-0.3,0.2-0.8,0.4-1c0.1-0.1,0.5-0.3,0.5-0.4c0.1-0.2-0.3-0.6-0.3-0.9c0-0.2,0.3-0.7,0.4-0.8
			c0.1-0.1,0.4-0.4,0.6-0.4c0,0,0,0,0,0c0.2-0.3,0.3-0.6,0.4-0.9c0.3-0.8,0.3-1.7,0.4-2.5c0-0.4,0-1,0.1-1.6h-8.5
			c0,0-0.5,1.3-0.5,1.4c0,0.2,0,0.3,0,0.5c0.2,0.8,0.5,1.2,0.8,2.1c-0.2,0.8-0.3,1.4-0.3,1.9c0,0.4,0,0.7,0,0.9c0,0.4,0,0.7,0,1.3
			c-0.1,0-0.1,0.1-0.2,0.2l-6.3,6.3c-0.2,0-0.4-0.1-0.5-0.1h-7.3c-1.4,1.7-2.7,3.6-4,5.4c-0.2,0.3-0.5,0.7-0.7,1
			c-0.3,0.5-0.7,1-1.1,1.3c-0.4,0.3-0.8,0.5-1.3,0.6c0,0-0.1,0-0.1,0c-1.2,0.4-3.2,0.6-3.2,0.6s-1.5-1.8-2.2-2.6
			c-1.4,0.5-2.7,1.2-3.9,2c-0.3,0.2-0.5,0.3-0.8,0.5c-0.6,0.2-2.5,0.1-3.7,0.1c-0.4,1.4-0.9,2.8-1.5,4.1c-1.7-0.1-3.5-0.2-5.2-0.2
			c-1,0.4-1.9,1.1-2.7,1.9c-0.2,0.2-0.5,0.5-0.6,0.8c-0.1,0.2-0.1,0.5-0.1,0.8c0,0.8-0.1,1.6-0.1,2.5c0,0.5-0.2,1.1-0.4,1.5
			c-0.3,1-0.8,1.8-1.3,2.8c-0.1,0-0.1,0-0.2,0c0.4,2.7,1.2,6.5,1.2,6.5l5.3-0.7l1.3,2.3c0,0,3.3-0.2,5-0.4c0.2,0.3,0.5,0.5,0.7,0.7
			c0.9,0.9,1.8,1.8,2.9,2.5c1.1-0.5,3.4-1.2,3.4-1.2l1.7,4c0.4,0.3,2.7,1.7,2.6,2.1c-0.1,0.2-3.4,5.2-3.4,5.5c0,0.3,0.2,0.6,0.4,0.8
			c0.6,0.8,1.1,1.6,1.7,2.4c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.1,0.6,0.1c0.8,0,1.5,0.1,2.3,0.1c0-0.1,0-0.2,0-0.2
			c0.2-0.4,0.9-1,1.3-1.3c0.3-0.3,0.9-1,1.3-1.1c0,0,1.3-0.8,3.1-1c0.5-0.1,1.3,0,2.2,0c2.2,0.2,5,0.6,5,0.6
			c1.5-1.7,4.4-1.7,5.7-2.2c1.3-0.5,2.7-3.1,3.1-3.3c0.4-0.2,1.3,1.4,1.9,1.3c0.6-0.1,1.5-0.8,1.5-0.8s-0.2-3.6,0-4.4
			s1.6-0.9,2.7-1.5c1-0.6,1.4-2.3,1.4-2.3l1.5-1.8l6.6-8L201.4,111.5z"/>
	<path @click="openEmc" data-emc="cobb-emc" ref="cobb-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M163.6,129.7l-1.7-4c0,0-2.3,0.6-3.4,1.2c-1-0.8-2-1.6-2.9-2.5c-0.2-0.2-0.5-0.5-0.7-0.7c-1.6,0.2-5,0.4-5,0.4
		l-1.3-2.3l-5.3,0.7c0,0-0.8-3.7-1.2-6.5c-1.9,0-5.5,0.1-5.7,0.2c-0.1,0-3.6,2.4-5.2,3.4c-0.1,1.3-0.3,2.6-0.5,3.9
		c-0.5,0.2-1,0.4-1.6,0.6c-0.5-0.5-1.1-1.1-1.7-1.5h-1.4l-3-3.8h-1.3l-1,1.5v5l-2.1,2.2h-3.1l-1.3,1.8l0,0l0.5,1.3l-0.8,2.9v4.5
		l2.9,2.6l1.5,2.6l1,2h1.5l0.1,8c0.5,0.8,1.2,1.4,1.9,1.9c1.9,1.3,4.3,1.6,6.6,1.9c2.8,0.3,5.7,0.7,8.5,1c0.4-2.3,1.1-5.4,1.1-5.4
		s-2.5-1.9-3.5-3.1c-0.7,0.4-1.4,0.8-2.1,1.3c-0.1-2.3-0.2-4.6-0.3-6.9c0.6-0.8,1.2-1.7,1.8-2.5c-1.2-0.9-3.6-2.8-3.6-2.8
		s-0.2-0.1-0.2-0.2c0-0.1-0.1-0.3-0.1-0.3s0.1-2.4,0.3-3.6c1.8,0.1,3.5,0.5,5.2,1c0.2,1.2,0.4,2.4,0.5,3.6c2.6,2,6.1,3,8.4,5.4
		c0.4,2.3,1.2,6.8,1.2,6.8l0.7-0.4c0,0,2.3-3.2,3.3-5c0.6,0,1.3,0,1.9,0c0.3-0.2,0.8-0.5,1.1-0.6c0.4-0.2,1.2-0.6,1.4-1
		c0.2-0.3,0.2-1.1,0.3-1.4c0.1-0.2,0.5-0.5,0.6-0.7c0.1-0.3,0.4-0.9,0.4-1.2c0,0,0-0.1,0-0.1c0.4-0.6,0.8-1,1.5-1.4
		c0.8-0.5,1.3-2.2,1.3-2.2h3.5c0-0.3,3.4-5.3,3.4-5.5C166.3,131.4,164,130,163.6,129.7z"/>
	<path @click="openEmc" data-emc="coweta-fayette-emc" ref="coweta-fayette-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M155.4,219.3c0.1-0.1,0.2-0.5,0.2-0.7c0-0.3-0.3-1-0.3-1s-0.9-0.8-0.9-0.9c0-0.2-0.1-1.1,0.1-1.4
		c0.1-0.2,0.6-0.2,0.7-0.4c0.1-0.2-0.1-0.7-0.2-0.9c-0.1-0.3-0.7-0.7-0.7-0.9c0-0.2,0.6-0.4,0.7-0.5c0.2-0.2,0.5-0.7,0.5-1
		c0-0.2-0.3-0.5-0.3-0.7c0-0.2,0.3-0.7,0.2-0.9c-0.1-0.2-0.5-0.3-0.7-0.4c-0.2,0-0.5,0.2-0.6,0.1c-0.3-0.2-0.2-1.2-0.1-1.5
		c0.1-0.3,0.8-0.6,0.8-0.7c0-0.1,0-0.9,0-1.2c0-0.4,0.2-1.2,0.1-1.6c-0.1-0.4-0.5-1.2-0.6-1.6c-0.2-0.4-0.3-1.4-0.6-1.9
		c-1,0-2-0.1-3-0.1c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.3-0.6-0.5c-2.2-2.1-4.7-4-7.3-5.6c-0.1,0-0.1,0-0.2,0.1
		c-0.4,0.1-1,0.6-1.4,0.7c-0.4,0.1-0.9,0.2-0.9,0.2l-0.6-1.2l-1.7-1.1c-0.9,1-1.2,1.4-2,2.5c-0.1,0.1-0.2,0.3-0.2,0.4
		c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.4,0.1-0.8,0.2-1,0.4c-0.4,0.2-0.8,0.9-1,1.4l-22.5-0.2l-0.1,0c0,0-1.4-0.4-1.8-0.4
		c-0.2,0-0.7,0.3-0.8,0.5c-0.2,0.3,0.1,1.1,0,1.5c-0.1,0.3-0.5,0.8-0.8,0.9c-0.4,0.2-1.5-0.2-1.9,0c-0.4,0.1-0.8,0.8-1.2,1.1
		c-0.4,0.3-1.4,0.5-1.7,0.8c-0.3,0.3-0.2,1.3-0.4,1.6c-0.1,0.2-0.7,0.4-0.8,0.6c-0.2,0.3,0.1,1.1,0,1.4c-0.1,0.2-0.7,0.3-0.9,0.5
		c-0.2,0.1-0.6,0.6-0.8,0.7c-0.4,0.1-1.2,0.2-1.5,0c-0.4-0.2-0.6-1.1-0.9-1.4c-0.3-0.3-0.9-1-1.4-1.1c-0.4-0.1-1.6-0.2-1.8,0.2
		c-0.1,0.3,0.5,0.8,0.6,1.1c0,0.3-0.2,0.9-0.4,1.1c-0.3,0.3-1.1,0.4-1.4,0.5c-0.3,0.2-1.1,0.9-1.1,0.9l-0.1,0
		c-0.3,1-0.6,2.1-0.8,3.2c-0.1,0.4-0.1,0.7-0.3,1c-0.1,0.3-0.4,0.5-0.6,0.7c-1.1,1.2-1.5,2.7-2.5,4c-0.1,0.1-0.2,0.3-0.3,0.4
		c-0.2,0.3-0.2,0.6-0.3,0.9c-0.2,1.7-0.5,3.3-0.7,5c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.4-0.3,0.4l-1,0.8c0.8,1.1,1.6,2.1,2.3,3.2
		c0.1,0.1,3,1.2,3.9,1.6c2.7,1,8.1,2.7,8.1,2.7l2.8,3.4l2.9,0.5l1.3,1.6h0.9h0.7l1.1-1.4v-1.9h3.6c0,0,3.3,2.5,4.1,3.9
		s1.1,2.4,1.9,3.1c0.7,0.7,4.7,0.7,5,0c0.3-0.7,1.4-2.3,1.8-2.8c0.4-0.5,2.6-2.1,2.6-2.1l5.6-0.1c0,0,2.3-2.1,3.2-2.5
		c0.5-0.2,1-1,1.3-1.6h5.6c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.2,0.2-0.5,0.2-0.7c0-0.2,0.2-0.6,0.1-0.7c-0.1-0.2-0.6-0.4-0.7-0.7
		c-0.1-0.3,0-0.9,0.1-1.1c0.1-0.1,0.5,0,0.7-0.1c0.2-0.1,0.4-0.6,0.4-0.8c0,0,0,0,0,0c0.5-0.1,1.4-0.1,1.8-0.2
		c0.6,0,1.9,0.1,2.4,0.1c0.4,0,0.9,0.2,1.4,0.2c0.3,0,0.9,0.2,1.1,0c0.3-0.3-0.2-1.2-0.1-1.5c0.1-0.5,0.4-1.4,0.7-1.8
		c0.2-0.3,0.9-0.8,1.1-1.1c0.3-0.3,0.6-1,0.9-1.2c0.2-0.1,0.8-0.1,0.9-0.3c0.2-0.3,0.1-1.1,0.3-1.4c0.1-0.1,0.4-0.3,0.5-0.4
		c0.1-0.2,0.2-0.7,0.2-0.9c0-0.3-0.7-0.9-0.6-1.2C154.8,219.5,155.3,219.5,155.4,219.3z"/>

        <g id="diverse-power-inc" >
            <path @click="openEmc" data-emc="diverse-power-inc" ref="diverse-power-inc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M132.6,279.8c-0.1-2.7-0.2-5.4-0.3-8c1.4-2,2.7-4.1,3.9-6.2c-1.3,0-3.5,0.1-4.4,0c-1.3-0.2-4.7-1.8-6.8-3.2
			c-2.1-1.5-2.3-2.5-2.3-2.5l-1.2-4.7l0.9-2.9v-2.1l-0.4-1.8h-7.8l-1.5-2.3l-0.4-2.4l1.1-2.5l2-2.7c-1.3-1.3-3.2-2.8-3.2-2.8h-3.6
			v1.9l-1.1,1.4h-0.7h-0.9l-1.3-1.6l-2.9-0.5l-2.8-3.4c0,0-5.4-1.7-8.1-2.7c-1-0.4-3.8-1.4-3.9-1.6c-0.8-1.1-1.6-2.1-2.3-3.2
			l-3.6,2.9l1.3,1.3c0.2-0.2,0.8-0.3,1.1-0.3c0.3,0,1,0.3,1.2,0.5c0.3,0.3,0.5,1,0.7,1.4c0.2,0.3,0.7,0.8,0.8,1.1
			c0.2,0.4,0.3,1.4,0.6,1.8c0.2,0.3,0.9,1,1.3,1c0.3,0,0.9-0.2,1.2-0.4c0.3-0.3,0.5-1.1,0.7-1.4c0.2-0.3,0.5-0.8,0.8-1
			c0.3-0.2,0.9-0.4,1.3-0.5c0.3,0,1.1,0,1.4,0.2c0.2,0.2,0.1,0.9,0,1.1c-0.2,0.2-1,0-1.3,0.1c-0.2,0.1-0.5,0.5-0.7,0.7
			c-0.2,0.3-0.2,1-0.3,1.3c0,0.3,0,1.1,0,1.1c0.1,0.7-0.4,1.4-0.6,1.5c-0.2,0.2-0.9,0.3-1.1,0.5c-0.2,0.2-0.4,0.8-0.5,1.1
			c0,0.3,0,0.8,0.1,1c0.1,0.3,0.8,0.5,0.9,0.7c0,0.2-0.2,0.6-0.4,0.7c-0.3,0.1-0.9-0.1-1.2,0c-0.3,0.2-0.6,1-0.9,1.2
			c-0.3,0.2-1,0.2-1.3,0.4c-0.4,0.3-0.9,1.1-1.1,1.4c-0.2,0.3-0.5,0.9-0.7,1.2c-0.2,0.3-0.8,0.9-0.9,1.2c-0.1,0.4,0.1,1.3,0.3,1.7
			c0.1,0.2,0.4,0.5,0.6,0.5c0.3,0,0.7-0.5,0.9-0.7c0.3-0.3,0.9-0.9,1.2-1.3c0.3-0.4,0.9-1.2,1.3-1.5c0.3-0.3,1.1-0.6,1.5-0.7
			c0.3-0.1,0.9-0.3,1.1-0.6c0.2-0.2,0.4-0.8,0.6-1c0.3-0.2,1.1-0.3,1.3-0.1c0.2,0.2,0.1,0.9,0,1.1c-0.1,0.2-0.5,0.5-0.7,0.7
			c-0.3,0.2-1.3,0.4-1.3,0.8c0,0.2,0.4,0.5,0.6,0.6c0.3,0.1,0.9,0,1.2-0.1c0.2-0.1,0.5-0.5,0.8-0.5c0.2-0.1,0.8-0.2,0.9,0
			c0.2,0.2,0.1,0.9-0.1,1.2c-0.1,0.2-0.6,0.3-0.8,0.5c-0.2,0.2-0.3,0.7-0.5,0.9c-0.1,0.1-0.5,0.3-0.6,0.3c-0.3,0-0.7-0.6-0.9-0.7
			c-0.2-0.1-0.7-0.3-0.9-0.3c-0.5,0-1.6,0.2-1.9,0.5c-0.2,0.2-0.2,0.7-0.2,1c0,0.3,0.6,0.9,0.6,1.2c0,0.3-0.1,0.9-0.3,1.1
			c-0.2,0.1-0.6,0.1-0.8,0c-0.3-0.1-0.8-0.8-1.2-0.9c-0.2-0.1-0.7-0.1-0.9,0.1c-0.2,0.2,0.3,0.9,0.2,1.1c-0.1,0.2-0.6,0.7-0.8,0.6
			c-0.2,0-0.2-0.6-0.4-0.7c-0.2-0.1-0.7-0.1-0.9,0.1c-0.2,0.2-0.1,0.8-0.3,0.9c-0.3,0.2-1.2-0.3-1.6-0.1c-0.4,0.2-1,0.9-1.3,1.3
			c-0.3,0.3-0.8,1-1.1,1.3c-0.2,0.1-0.6,0.3-0.8,0.4c-0.2,0.2-0.4,0.7-0.4,1c0.1,0.2,0.5,0.5,0.7,0.5c0.3,0.1,0.9-0.3,1.2-0.1
			c0.2,0.1,0.3,0.5,0.4,0.7c0,0.2-0.2,0.7-0.3,0.9c-0.2,0.3-0.7,0.8-1,1c-0.2,0.1-0.8,0.2-1.1,0.3c-0.4,0.2-1.3,0.5-1.6,0.9
			c-0.2,0.3-0.3,1.1-0.4,1.5c-0.1,0.3-0.1,0.9-0.2,1.2c-0.1,0.4-0.7,1.2-0.6,1.7c0.1,0.3,0.5,0.8,0.8,0.9c0.3,0,0.7-0.5,0.9-0.7
			c0.2-0.2,0.3-1,0.5-1.1c0.2-0.1,0.6,0.2,0.7,0.1c0.3-0.1,0.6-0.8,0.9-0.8c0.2,0,0.6,0.4,0.7,0.6c0,0.3-0.5,0.9-0.7,1.1
			c-0.3,0.3-1,0.6-1.2,1c-0.2,0.4-0.1,1.3-0.2,1.8c-0.1,0.4-0.6,0.9-0.8,1.3c-0.2,0.4-0.5,1.3-0.8,1.6c-0.4,0.3-1.5,0.4-2,0.5
			c-0.2,0-0.6,0.1-0.9,0.1l0.7,4.3l0.3,1.8c0,0,1.6-0.1,2.1,0.1c0.3,0.2,0.8,0.7,0.8,1.1c0,0.2-0.5,0.5-0.7,0.7
			c-0.2,0.3-0.3,0.8-0.4,1.1c-0.1,0.4-0.4,1.2-0.4,1.6c0.1,0.5,0.5,1.3,0.8,1.7c0.2,0.3,0.9,0.7,1.2,0.8c0.3,0.1,1,0.2,1.2,0.5
			c0.3,0.2,0.5,0.9,0.7,1.2c0.1,0.2,0.6,0.3,0.6,0.5c0,0.2-0.3,0.6-0.5,0.8c-0.2,0.2-1,0.5-1.1,0.8c-0.2,0.4-0.1,1.2,0.1,1.6
			c0.2,0.4,1.1,1,1.6,1.2c0.3,0.1,1,0.1,1.2,0.3c0.2,0.2,0.2,1,0.2,1.3c0,0.4-0.5,1.2-0.6,1.6c-0.1,0.4,0,1.2,0.1,1.6
			c0.1,0.5,0.5,1.5,0.8,2c0.3,0.6,1.4,1.5,1.6,2.1c0.1,0.3,0.2,1.1,0.1,1.4c-0.1,0.2-0.7,0.5-0.8,0.7c-0.1,0.2-0.3,0.8-0.2,1.1
			c0.2,0.4,1.2,0.6,1.5,0.9c0.2,0.3-0.1,1,0,1.4c0.1,0.3,0.7,0.8,0.8,1.1c0.1,0.2,0,0.6,0,0.9c0,0.2,0,0.4,0,0.5
			c0.1,0.5,0.4,1.4,0.6,1.8c0.3,0.5,1.1,1.5,1.5,2c0.3,0.3,0.7,1.1,1,1.4c0.3,0.3,1.1,0.8,1.4,1.1c0.5,0.6,1.4,2,1.8,2.6
			c0.4,0.5,1.4,1.4,1.7,2c0.3,0.6,0.5,1.9,0.5,2.5c0,0.7-0.7,2-0.6,2.6c0,0.3,0.2,0.8,0.4,1.2l26.1-14.9l4.5,0v-8h0.4v-4.1h-0.9
			c0,0-0.1-1.1-0.1-1.4c0.1-0.4,0.3-1,0.5-1.3c0.1-0.2,0.5-0.4,0.6-0.6c0.2-0.4-0.2-1.2,0-1.6c0.1-0.2,0.6-0.4,0.7-0.6
			c0.1-0.4-0.5-1-0.6-1.4c-0.1-0.5-0.2-1.7-0.3-2.2c-0.1-0.6-0.3-1.7-0.1-2.2c0.1-0.3,0.6-0.6,0.6-0.9c0.1-0.6-0.4-1.8-0.5-2.5
			c0-0.5,0.2-1.4,0.1-1.9c0-0.5-0.2-1.5-0.3-2c-0.1-0.3-0.3-0.6-0.4-1l0.2,0.1L132.6,279.8z"/>
        <path @click="openEmc" data-emc="diverse-power-inc" ref="diverse-power-inc-1" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M70.9,259.2c0.4,0.1,1,0.2,1.2,0.1c0.3-0.1,0.9-0.4,1-0.7c0.2-0.5,0-1.6-0.3-2.1c-0.1-0.3-0.8-0.5-0.9-0.8
            c-0.2-0.4,0.1-1.2,0.1-1.6c0-0.4,0-1.3-0.3-1.7c-0.1-0.2-0.6-0.2-0.7-0.4c-0.2-0.2-0.6-0.8-0.6-1.1c0-0.3,0.3-1,0.5-1.1
            c0.3-0.1,0.8,0.5,1,0.7c0.2,0.2,0.3,0.6,0.5,0.7c0.2,0.1,0.8,0,0.9,0.2c0.3,0.3,0.4,1.2,0.5,1.6c0.1,0.4-0.1,1.4,0,1.8
            c0.1,0.4,0.4,1.1,0.5,1.4c0.1,0.3,0.1,1,0.1,1.3c0,0.3-0.4,0.8-0.4,1.1c0,0.3,0.2,1,0.4,1.1c0.2,0.1,0.6-0.4,0.8-0.6
            c0.2-0.2,0.3-0.9,0.4-1.2c0.2-0.2,0.7-0.4,0.9-0.6c0.1-0.2,0.1-0.7,0-0.9c0-0.2-0.3-0.6-0.4-0.8c0-0.4,0.1-1.1,0.3-1.4
            c0.1-0.3,0.6-0.8,0.9-1c0.2-0.1,0.8-0.2,0.9-0.4c0.2-0.3-0.1-1-0.2-1.4c0-0.2-0.3-0.6-0.3-0.7c0-0.3,0.4-1,0.6-1.2
            c0.2-0.1,0.6,0,0.8-0.1c0.2-0.2,0.5-0.7,0.5-0.9c0-0.3-0.5-1.1-0.5-1.2c0-0.1-0.2-0.8,0-1c0.1-0.1,0.5-0.1,0.7,0
            c0.2,0.1,0.3,0.5,0.4,0.7c0.1,0.2,0.2,0.6,0.2,0.8c0,0.3-0.2,1-0.2,1.3c0,0.3,0,0.8,0,1.1c0.1,0.2,0.3,0.8,0.5,0.9
            c0.3,0.1,0.9-0.4,1.1-0.7c0.2-0.3-0.1-1.2-0.1-1.5c0.1-0.5,0.6-1.4,0.6-1.9c0-0.3-0.3-1-0.3-1.3c0.1-0.6,0.5-1.7,0.9-2
            c0.3-0.3,1.4,0,1.7-0.2c0.2-0.2,0.2-0.9,0.4-1.1c0.3-0.3,1.3-0.4,1.6-0.7c0.3-0.2,0.7-0.8,0.8-1.1l-18.4,7.9L70.9,259.2z"/>
        <path @click="openEmc" data-emc="diverse-power-inc" ref="diverse-power-inc-2" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M150.3,442.5C150.3,442.5,150.3,442.5,150.3,442.5c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.3,0.1-1.2,0-1.5
			c-0.1-0.4-0.5-1-0.6-1.3c-0.1-0.4,0.1-1.2-0.1-1.5c-0.2-0.2-0.9-0.2-1.1-0.5c-0.1-0.2-0.1-0.6,0-0.8c0-0.3,0.3-0.9,0.4-1.2
			c0-0.3,0-1,0-1.3c-0.1-0.3-0.6-0.9-0.6-1.2c0-0.2,0.1-0.7,0.3-0.9c0.2-0.2,0.7-0.4,0.8-0.6c0.1-0.2,0.1-0.7,0.1-1
			c-0.6-0.4-1.3-0.8-1.9-1.2c-0.3-0.2-0.5-0.3-0.8-0.5c-0.1-0.1-0.1-0.1-0.1-0.2c-0.5-0.6-0.7-1.4-0.9-2.1c-0.7-0.1-1.3-0.2-2-0.3
			c-0.3,0.5-0.5,1.6-0.8,2.4l-3.8,0c0,0,0-0.1,0-0.1c0.1-0.3,0.5-0.8,0.6-1.1c0.2-0.4,0.5-1.3,0.5-1.7c0-0.4-0.5-0.9-0.6-1.3
			c-0.1-0.4,0-1.3,0.2-1.7c0.1-0.3,0.7-0.6,0.7-0.9c0-0.3-0.5-0.9-0.5-1c0-0.1-0.2-0.9-0.3-1.2c-0.1-0.3-0.5-0.8-0.6-1.1
			c-0.1-0.4,0.1-1.2-0.1-1.5c-0.1-0.3-0.6-0.8-0.8-1c-0.2-0.3-0.6-0.7-0.7-1c-0.1-0.3,0.1-0.9,0.1-1.1c-0.1-0.2-0.4-0.6-0.5-0.8
			c-0.1-0.3-0.4-1-0.3-1.3c0-0.2,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.9,0.2-0.9h-4l0-0.4c-0.8-1.2-1.6-2.4-2.4-3.6
			c-0.2-0.4-0.5-0.7-0.8-1c-0.3-0.4-0.8-0.6-1.2-0.9c-1.8-1.2-3.5-2.4-5.1-3.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.3-0.1-0.5-0.1
			c-1.6,0-3.3-0.2-4.9-0.5c0-0.3,0-0.6,0-0.9c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.5-0.5,1-0.9,1.5-1.4
			c0.1-0.1,0.2-0.2,0.3-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.5,0-0.9,0-1.4c-3-0.1-6-0.2-8.9,0.1c-0.4,0-0.8,0.1-1.1,0
			c-0.3-0.1-0.6-0.3-0.8-0.6c-1-0.9-2-1.7-3.1-2.5l-4,0l-1,0c0,0,0.2-1,0-1.2c-0.2-0.3-1.1,0-1.4-0.2c-0.2-0.1-0.3-0.6-0.5-0.7
			c-0.1-0.1-0.4,0.1-0.6,0.1c-0.3,0-1-0.2-1.3-0.5c-0.1-0.1-0.1-0.6-0.3-0.8c-0.1-0.1-0.4,0-0.5-0.1c-0.2-0.2-0.1-0.9,0-1.2
			c0.1-0.2,0.2-0.7,0.4-0.8c0.2-0.1,0.7,0.3,0.8,0.2c0.1-0.1,0.1-0.6,0-0.7c-0.2-0.3-0.8-0.7-1.2-0.8c-0.4-0.1-1.2,0-1.6,0
			c-0.3,0.1-0.9,0.6-1.2,0.5c-0.9-0.1-0.9-0.8-1.2-1.1c-0.2-0.2-1.1-0.2-1.1-0.2l0,0.9h-3v-0.8c0,0-1.1-0.1-1.5-0.2
			c-0.4,0-1.2-0.1-1.7-0.1c0.1,0.3,0.3,0.6,0.3,0.8c0,0.3,0,0.9-0.2,1.1c-0.2,0.2-0.7,0.3-1,0.4c-0.2,0.1-0.8,0.4-0.9,0.7
			c-0.1,0.3,0.2,0.7,0.3,1c0,0.2,0.2,0.7,0.1,0.9c-0.1,0.4-0.6,1.1-0.8,1.4c-0.1,0.2-0.6,0.4-0.7,0.6c-0.2,0.2-0.4,0.8-0.6,1
			c-0.3,0.3-1.1,0.5-1.2,0.9c-0.1,0.2,0.4,0.7,0.4,0.9c0.1,0.3,0.1,1-0.1,1.2c-0.2,0.2-1-0.1-1.3-0.1c-0.3,0.1-1.1,0.2-1.2,0.6
			c-0.1,0.3,0.5,0.7,0.6,1c0.1,0.3,0,0.9-0.1,1.2c-0.1,0.3-0.5,0.6-0.6,0.9c-0.1,0.3,0.1,0.8,0.1,1.1c0,0.4-0.4,1-0.5,1.4
			c0,0.2,0.1,0.6,0.1,0.8c0.1,0.4,0.6,1.1,0.7,1.5c0.1,0.4,0,1.3,0,1.7c0,0.5,0,1.4-0.1,1.9c-0.1,0.4-0.7,1-0.7,1.3
			c0,0.2,0.2,0.5,0.4,0.7c0.2,0.2,0.3,0.4,0.4,0.5c0.2,0.3,0.6,0.9,0.7,1.3c0.1,0.4,0,1.3,0.1,1.8c0.1,0.3,0.4,0.8,0.5,1.1
			c0.1,0.3,0.3,1,0.3,1.4c0,0.3-0.3,0.8-0.4,1.1c-0.1,0.3-0.4,1-0.4,1.4c0,0.4,0.4,1.2,0.5,1.6c0.3,0.6,0.7,2,1.2,2.5
			c0.4,0.5,1.6,1.3,2.1,1.8c0.2,0.3,0.8,0.8,0.8,1.1c0,0.2-0.2,0.7-0.3,1c-0.1,0.1-0.5,0.3-0.5,0.5c0,0.2,0.3,0.6,0.4,0.8
			c0.1,0.1,0.4,0,0.6,0.1c0.2,0.1,0.4,0.6,0.6,0.7c0.2,0.2,0.8,0.2,1,0.4c0.2,0.2,0.1,0.9,0.1,1.1c0.1,0.6,0.2,1.8,0.3,2.4
			c0,0.6,0,1.8,0.1,2.4c0.1,0.7,0.8,1.9,1,2.5c0.2,0.5,0.6,1.4,0.6,1.9c0,0.3-0.4,0.9-0.5,1.2c-0.1,0.4-0.1,1-0.1,1.5
			c0.3-0.1,0.6-0.1,0.8-0.1c0.1,0,0.3,0,0.5,0.1c3.1,0.2,6.2,1.1,9.3,1.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3
			c0.1,0,0.1,0.1,0.2,0.1c1.3,1.1,2.5,2.2,3.8,3.3c1.2-1,2.4-2.1,3.5-3.3h4.5c0,0,0,0,0,0.1c0.1,0.2,0.2,0.7,0.4,0.8
			c0.2,0.1,0.8,0.2,1,0.1c0.1-0.1-0.1-0.6,0-0.8c0.2-0.2,0.7-0.3,0.9-0.2c0.2,0.1,0.1,0.6,0.2,0.8c0,0.3-0.1,1-0.1,1.4
			c0,0.3,0,0.9-0.2,1.1c-0.1,0.1-0.5,0.2-0.5,0.4c-0.1,0.2-0.1,0.7,0.1,0.9c0.2,0.3,0.9,0.4,1.1,0.7c0.1,0.2,0.2,0.6,0.2,0.8
			c0,0.2-0.4,0.4-0.4,0.6c0,0.2,0.3,0.6,0.4,0.8c0.1,0.1,0.4,0.4,0.4,0.4l1.2,0c0,1.7,0,3.9,0,5.3c0.3-0.2,0.6-0.4,0.9-0.5
			c0.3-0.2,0.7-0.4,1-0.7c0.6-0.5,1-1.2,1.5-1.7c0.3-0.3,0.6-0.5,1-0.8c0.6-0.5,1.3-1,1.9-1.5c1-0.9,1.9-1.8,2.8-2.7
			c1.3,0.1,2.5,0.9,3.8,1.2c0.2,0,0.3,0.1,0.5,0c0.1,0,0.2-0.1,0.4-0.2c1.2-0.7,2.2-1.3,3.4-2c1.2,0,2.5,0,3.7,0
			c0.1,0.9,0.3,1.8,0.5,2.6c0.6,0,1.3-0.2,1.8-0.5c0.5-0.3,1-0.8,1.4-1.3c0.5-0.6,0.9-1.1,1.3-1.7c2,0,4,0.1,6,0.2
			c0.3-0.5,0.6-0.9,0.9-1.4c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.3,0.5-0.5,0.7-0.8c0.3-0.3,0.7-0.7,1-1
			C150.6,442.7,150.5,442.6,150.3,442.5z"/>
        </g>

	<path @click="openEmc" data-emc="snapping-shoals-emc" ref="snapping-shoals-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M228.6,194.4l-1.6-2.1l-1.5-0.9l-1.7-1.8l-0.3-1.7l1.2-0.2l-0.3-3.3c-0.3-0.2-0.7-0.4-1-0.6
		c-0.5-0.4-1.2-1.4-1.8-1.6c-0.7-0.3-3-0.3-3-0.3l-5.1-3.8l-3.2-0.3l-1.3-3.1l-1.8-1.3l-3.4,1.4l-0.8-0.8c0.1-0.4,0.1-0.8,0.1-1.1
		c0-0.2-0.2-0.6-0.3-0.9l-1.1-0.3c0,0-0.9-1.1-1.6-1c-0.7,0.1,0.7,2.7-0.3,2.9c-1,0.2-2.8-0.1-2.8-0.1l-0.8,1.1v2.5l0.8,1.2
		l-1.7-0.5l-2.4-2l-2-3.5l-1-1.3l-1.2,1.2c0,0-3.3,0-3.3,0.6c0,0.5,0,3.3,0,3.3l-1.8,0.6v1.2c0,0-1.5,0.1-2.4,0
		c-0.8-0.1-1.5,0.3-2.5,1.2c-1,0.9-2.3,0.4-3.8-0.1c-1.5-0.5-3-0.6-3-0.6l-6.4,3.4c0.3,0.5,0.7,1.1,0.9,1.4c0.2,0.3,0.9,0.7,1,1.1
		c0.2,0.6-0.3,2,0,2.5c0.2,0.3,1.1,0.6,1.2,1l0.8,3.2l4.3,8.7l4.5,6.6l1.1,4.2l1.4,1.7h2.5l4.3,3h4v1.6c0,0,0,0,0,0
		c0.2,0,0.4,0.1,0.6,0.2c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0l1.2,0.6h1.3l1-2.5v-2.5c0.4-0.1,0.9,0,1.1-0.2
		l5.4,5.1h6.8c0-0.2,0-0.6,0-0.8c0-0.2,0.1-0.4,0.2-0.7c0.1-0.2,0.1-0.3,0.2-0.4c0.3-0.3,1.1-0.3,1.4-0.6c0.2-0.2,0.2-0.8,0.3-1
		c0.1-0.3,0.3-0.9,0.5-1.2c0.2-0.3,1.1-0.4,1.3-0.7c0.2-0.3,0.2-1.1,0-1.3c-0.2-0.2-0.9,0.4-1.1,0.3c-0.2-0.1-0.2-0.5-0.2-0.7
		c0-0.2,0.2-0.7,0.2-0.9c0-0.2-0.1-0.6-0.1-0.8c0-0.2,0.2-0.6,0.3-0.7c0.1-0.2,0.5-0.4,0.7-0.5c0.3-0.2,0.9-0.6,1.2-0.8
		c0.3-0.2,0.8-0.9,1.2-1c0.3-0.1,1,0.3,1.3,0.2c0.2-0.1,0.4-0.5,0.6-0.6c0.2-0.1,0.7-0.1,0.9-0.3c0.3-0.2,0.9-0.6,1.1-0.9
		c0.2-0.3,0.2-1.1,0.4-1.3c0.2-0.2,0.6-0.5,0.9-0.6c0.5-0.2,1.7-0.2,2.2-0.4c0.3-0.1,0.9-0.3,1-0.6c0.1-0.3-0.4-0.8-0.3-1
		c0.1-0.2,0.4-0.5,0.6-0.5c0.2,0,0.7,0.2,0.8,0.4c0.2,0.2,0.4,1,0.5,1.1c0.1,0.1,0.8,0.7,1.2,0.6c0.1,0,0.2-0.1,0.3-0.2l2.7-2.7
		l1.8-1.9L228.6,194.4z"/>
        <path @click="openEmc" data-emc="carroll-emc" ref="carroll-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M108,198.5c-1.6-0.8-3.5-1-5-1.9c-0.7-0.4-1.3-1-2-1.4c-0.6-0.4-1.3-0.7-2-0.8c-0.2,0-0.3-0.1-0.4-0.2
			c-0.1-0.1,0-0.2,0-0.3c0.1-1.4,0.2-2.8,0.3-4.2c0-0.3,0-0.6-0.1-0.9c-0.1-0.2-0.3-0.3-0.5-0.5c-0.5-0.3-1-0.6-1.4-1
			c0-1.1,0.8-2,1.8-2.4c0.8-0.3,1.6-0.2,2.4-0.4c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0.1-0.5,0-0.7c-0.4-0.9-1.1-1.7-1.3-2.6
			c-0.1-0.4-0.1-0.9-0.1-1.4c0-1.5,0-3.1,0-4.6c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.3-0.6c0.8-1.2,1.9-3,2.6-4.7l0-3.1l-2.8-0.8
			v-3.2c0,0,0.3-2.4,0.7-3.2c0.4-0.7,1.5-1.6,1.5-2.2c0-0.6-2.7-2.7-3.5-3.4c-0.8-0.6-0.9-0.4-0.9-1.6s1.1-1.8,1.1-1.8l0-1.4l-1-0.7
			v-2.3l-2.8-0.2h-7l-8.7,5.6h-5.9l-2.1,3.8v2.4h-1.6l-4.9-5.2v-1.6l1.3-1v-2.2c0,0,1.2-0.3,1.1-1.3c-0.1-1-1.4-2.4-1.4-2.4l-2-0.4
			c0,0,0,2.2-1.3,2.9c-1.2,0.7-9.3,6.2-9.3,6.2l4.8,29.5l4.5,27.7l6,36.4l0.6,3.4l18.4-7.9c0.1-0.3-0.3-1-0.2-1.3
			c0-0.2,0.3-0.6,0.3-0.6s0.3-1.2,0.1-1.5c-0.2-0.4-1.3-0.6-1.7-0.9c-0.6-0.5-1.5-1.6-2-2.2c-0.4-0.6-1.1-1.8-1.4-2.5
			c-0.1-0.3-0.6-1-0.4-1.3c0,0,0.1,0,0.1-0.1l-1.3-1.3l4.6-3.7c0,0,0.2-0.2,0.3-0.4c0.1-0.2,0.1-0.4,0.2-0.6c0.2-1.7,0.5-3.3,0.7-5
			c0-0.3,0.1-0.7,0.3-0.9c0.1-0.1,0.2-0.3,0.3-0.4c0.9-1.2,1.4-2.8,2.5-4c0.2-0.2,0.4-0.4,0.6-0.7c0.2-0.3,0.2-0.7,0.3-1
			c0.2-1.1,0.4-2.2,0.8-3.2l0.1,0c0,0,0.8-0.7,1.1-0.9c0.3-0.2,1.1-0.3,1.4-0.5c0.2-0.2,0.4-0.8,0.4-1.1c0-0.3-0.7-0.8-0.6-1.1
			c0.2-0.4,1.3-0.3,1.8-0.2c0.4,0.1,1.1,0.8,1.4,1.1c0.3,0.3,0.5,1.2,0.9,1.4c0.3,0.2,1.2,0.1,1.5,0c0.2-0.1,0.6-0.5,0.8-0.7
			c0.2-0.1,0.8-0.3,0.9-0.5c0.2-0.3-0.1-1.1,0-1.4c0.1-0.2,0.7-0.4,0.8-0.6c0.2-0.3,0.2-1.3,0.4-1.6c0.3-0.4,1.3-0.6,1.7-0.8
			c0.3-0.2,0.8-0.9,1.2-1.1c0.4-0.2,1.5,0.2,1.9,0c0.3-0.1,0.7-0.6,0.8-0.9C108.1,199.6,107.8,198.9,108,198.5z"/>
		<path @click="openEmc" data-emc="greystone-power-corporation" ref="greystone-power-corporation" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M140.2,159.1c-0.7,0.2-1.5,0.2-2.2-0.1c0-0.3,0.1-0.7,0.2-1.1c-2.8-0.3-5.7-0.7-8.5-1
			c-2.3-0.3-4.7-0.6-6.6-1.9c-0.8-0.5-1.4-1.2-1.9-1.9l-0.1-8h-1.5l-1-2l-1.5-2.6L114,138v-4.5l0.8-2.9l-0.5-1.3l0,0l-1.2,0
			l-4.8-3.7l-2.1,2l1.1,1.6l0.8,1.3l-7.2,8.9v2l1.1,1.4v0.9l-2.2,1.5l-2.1-0.2v2.3l1,0.7l0,1.4c0,0-1.1,0.6-1.1,1.8s0.1,1,0.9,1.6
			c0.8,0.6,3.5,2.7,3.5,3.4c0,0.6-1,1.5-1.5,2.2c-0.4,0.7-0.7,3.2-0.7,3.2v3.2l2.8,0.8l0,3.1c-0.7,1.6-1.8,3.4-2.6,4.7
			c-0.1,0.2-0.3,0.4-0.3,0.6c-0.1,0.2-0.1,0.5-0.1,0.7c0,1.5,0,3.1,0,4.6c0,0.5,0,0.9,0.1,1.4c0.2,1,1,1.7,1.3,2.6
			c0.1,0.2,0.2,0.5,0,0.7c-0.1,0.1-0.2,0.2-0.3,0.2c-0.8,0.3-1.6,0.2-2.4,0.4c-1,0.3-1.7,1.3-1.8,2.4c0.5,0.3,1,0.6,1.4,1
			c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.3,0.1,0.6,0.1,0.9c-0.1,1.4-0.2,2.8-0.3,4.2c0,0.1,0,0.2,0,0.3c0.1,0.1,0.2,0.2,0.4,0.2
			c0.7,0.2,1.4,0.4,2,0.8c0.7,0.4,1.3,1,2,1.4c1.5,0.9,3.4,1.1,5,1.9c0,0,0,0,0,0c0.1-0.2,0.6-0.5,0.8-0.5c0.5-0.1,1.8,0.4,1.8,0.4
			l0.1,0l22.5,0.2c0.3-0.5,0.7-1.2,1-1.4c0.2-0.2,0.6-0.3,1-0.4c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.3,0.2-0.4
			c0.8-1.1,1.1-1.5,2-2.5l-0.5-1.8l-1.3-2.2l-0.3-2.9l-1.5-1.3l1-1.5l2.3,1.2h2v-2.8l-1.2-1.8l-0.9-3.7l0.9-2.4l-1.3-0.7l-3,3.1
			l-1.1-1c0.3-0.6,2.5-2.4,3.2-3.1c0.7-0.7,1.2-1.5,1.2-1.5c0-0.9,0-1.2,0-1.9c0-0.1,0-0.3,0-0.4c-0.6-0.2-1.1-1-1.7-1.1
			c0.1-0.6,0.2-1.3,0.2-1.3l-2.6-1.7c0,0-0.3,0.3-0.7,0.6c-0.3,0.3-0.7,0.6-1,0.8c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.3,0-0.5-0.1
			c-0.3-0.1-0.7-0.4-1.1-0.7c-0.1-0.1-1.5,1.8-1.5,1.8s-0.9-0.1-1-0.1c-0.2-0.2,1.6-2.5,1.4-2.7c-0.7-0.7-1.1-1.3-1.1-1.3l0.7-0.7
			l1.2-1.2c0,0,2.1-0.4,2.7-0.5c0.7-0.2,1.5-0.1,2.3,0c0.1,0.4,0.3,0.8,0.4,1.2c1.5,0,2.9,0,4.4,0.1c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.3-0.1,0.4-0.2c0.8-0.5,1.6-1.1,2.3-1.6c0.3-0.6,0.4-1.2,0.3-1.9C141.6,158.5,141,158.9,140.2,159.1z"/>
	<path @click="openEmc" data-emc="hart-emc" ref="hart-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M338.8,141.4c-0.1-0.2-0.7-0.4-0.9-0.5c-0.2-0.1-0.8-0.3-0.9-0.5c-0.2-0.3,0.2-1.1,0.1-1.4
		c0-0.3-0.1-0.9-0.3-1.2c-0.2-0.2-0.8-0.4-0.9-0.7c-0.1-0.2,0.3-0.6,0.3-0.8c0-0.3-0.3-0.9-0.4-1.2c-0.1-0.3-0.5-0.9-0.7-1.1
		c-0.2-0.1-0.7-0.4-0.7-0.4c-0.1,0-0.9-0.7-1-0.8c0-0.1-0.3-1.1-0.6-1.3c-0.2-0.2-0.7-0.3-1-0.4c-0.2-0.2-0.5-0.6-0.8-0.8
		c-0.3-0.2-1-0.1-1.3-0.2c-0.3-0.1-0.9-0.4-1.2-0.4c-0.4-0.1-1.2-0.3-1.6-0.1c-0.1,0-0.3,0.3-0.4,0.4c-0.2,0.1-0.7-0.1-0.9,0
		c-0.2,0.1-0.5,0.5-0.7,0.5c-0.2,0.1-0.8-0.1-0.9-0.2c-0.1-0.1-0.2-0.5-0.1-0.6c0.2-0.2,0.8,0.2,1,0c0.1-0.1,0.1-0.5,0-0.6
		c-0.2-0.3-1.1-0.5-1.5-0.6c-0.2-0.1-0.7-0.3-0.9-0.4c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.3-0.5-0.5-0.6c-0.2-0.1-0.7-0.2-1-0.3
		c-0.4-0.2-0.9-1.1-1.2-1.3c-0.3-0.2-1-0.4-1.3-0.4c-0.2,0-0.5,0.3-0.6,0.3c-0.4,0.1-1.3,0.1-1.6-0.1c-0.1-0.1-0.5-0.4-0.4-0.5
		c0.1-0.4,1-0.7,1.4-0.8c0.3-0.1,0.9-0.1,1.2-0.1c0.4,0,1.3-0.1,1.6,0.2c0.2,0.1,0.1,0.6,0.2,0.8c0.2,0.2,0.6,0.3,0.8,0.4
		c0.2,0.1,0.4,0.4,0.6,0.5c0.1,0.1,0.4,0.5,0.5,0.6c0.3,0.2,1.1,0.4,1.2,0.5c0.1,0,0.7-0.4,1-0.3c0.1,0.1,0.2,0.4,0.2,0.6
		c0.1,0.2,0,0.8,0.2,1c0.1,0.1,0.4,0.1,0.5,0c0.1-0.1,0.1-0.5,0.2-0.6c0.1-0.1,0.6-0.2,0.7-0.1c0.2,0.1,0.2,0.9,0.4,1.1
		c0.3,0.3,1.3,0.7,1.6,0.4c0.1-0.1,0-0.5-0.1-0.7c-0.1-0.2-0.8-0.4-0.9-0.7c0-0.2,0.2-0.5,0.4-0.6c0.3-0.1,0.9,0.2,1.1,0.4
		c0.2,0.2,0.5,0.7,0.7,0.9c0.2,0.2,0.5,0.5,0.7,0.6c0.2,0.1,0.7,0,0.9-0.2c0.1-0.1,0.2-0.5,0.2-0.7c-0.1-0.2-0.5-0.6-0.7-0.7
		c-0.1-0.1-0.5-0.2-0.6-0.3c-0.2-0.1-0.7-0.4-0.8-0.6c-0.2-0.3-0.1-1.1-0.2-1.4c-0.2-0.4-0.8-1-1.1-1.2c-0.3-0.2-1-0.4-1.4-0.5
		c-0.3-0.1-0.9-0.3-1.2-0.2c-0.2,0.1-0.6,0.5-0.7,0.7c-0.1,0.2,0.1,0.6,0,0.7c-0.2,0.1-0.5-0.4-0.6-0.4c-0.2-0.1-0.6,0-0.8-0.1
		c-0.2-0.1-0.6-0.5-0.5-0.7c0.1-0.2,0.5-0.1,0.6-0.2c0.2-0.2,0.5-0.7,0.4-0.9c0-0.2-0.5-0.5-0.7-0.6c-0.3-0.1-0.8,0-1.1,0
		c-0.2-0.1-0.8-0.4-0.7-0.6c0.1-0.2,0.9,0,0.9-0.1c0-0.1,0-0.5-0.1-0.6c-0.1-0.1-0.5-0.2-0.6-0.3c-0.1-0.2-0.4-0.6-0.3-0.8
		c0.1-0.2,0.5-0.2,0.7-0.2c0.2,0,0.5,0.2,0.7,0.3c0.3,0.2,0.5,0.9,0.8,1.1c0.1,0,0.3,0,0.3,0c0.1-0.1,0.1-0.5,0.1-0.6
		c-0.1-0.4-0.3-1.1-0.6-1.4c-0.2-0.2-0.8-0.4-1-0.6c-0.3-0.2-0.8-0.9-1.1-1.1c-0.2-0.1-0.6,0.2-0.7,0.1c-0.2-0.1-0.3-0.5-0.4-0.7
		c-0.2-0.1-0.7-0.1-0.9-0.3c-0.3-0.2-0.6-0.7-0.5-1.1c0-0.2,0.1-0.4,0.3-0.5c0.3-0.1,0.9,0,1.2,0.1c0.2,0.1,0.5,0.5,0.7,0.6
		c0.2,0.2,0.7,0.6,1,0.6c0.2,0,0.8-0.2,0.8-0.4c0.1-0.3-0.3-0.8-0.5-1.1c-0.1-0.2-0.5-0.5-0.6-0.7c-0.1-0.3-0.1-0.9,0-1.2
		c0.1-0.2,0.5-0.5,0.6-0.7c0.2-0.4,0.4-1.4,0.4-1.9c0-0.6-0.2-1.9-0.4-2.5c-0.2-0.5-0.6-1.4-0.9-1.7c-0.3-0.4-1.3-0.7-1.8-1.1
		c-0.1,0-0.1-0.1-0.2-0.1c-0.4-0.4-0.9-1.4-1.2-1.9c-0.2-0.5-0.5-1.5-0.7-2c-0.1-0.5-0.1-1.7-0.4-2.2c-0.2-0.3-0.7-0.6-0.9-0.8
		c-0.2-0.2-0.6-0.7-0.8-0.9c-0.1-0.1-0.3-0.4-0.4-0.4c-0.2,0.1-0.1,0.5-0.2,0.6c0,0.2,0,0.6-0.2,0.7c-0.1,0.1-0.3-0.1-0.5-0.1
		c-0.2,0-0.7,0.2-0.9,0.1c-0.1,0-0.3-0.2-0.4-0.3c0-0.2,0.4-0.4,0.5-0.6c0-0.2-0.2-0.5-0.3-0.6c-0.1,0-0.3,0-0.4,0
		c-0.1,0-0.2,0.2-0.3,0.3c-0.1,0.2-0.1,0.6-0.3,0.7c-0.2,0.1-0.6-0.2-0.7-0.3c0-0.1-0.3-0.5-0.4-0.7c0-0.2,0.2-0.5,0.3-0.6
		c0.1-0.1,0.5-0.2,0.5-0.3c0-0.1-0.1-0.4-0.3-0.5c-0.2-0.1-0.5,0.3-0.7,0.3c-0.2,0-0.4-0.5-0.6-0.6c-0.1-0.1-0.5-0.1-0.6,0
		c-0.2,0.2,0.2,0.7,0.1,1c0,0.2-0.3,0.4-0.4,0.5c-0.1,0.1-0.3,0.4-0.4,0.4c-0.2,0-0.5-0.1-0.6-0.2c-0.2-0.2-0.3-0.7-0.2-1
		c0.1-0.2,0.5-0.5,0.5-0.6c0-0.1,0-0.6,0.1-0.8c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.1,0.6-0.4,0.9-0.4c0.3,0,0.8,0.3,1,0.5
		c0.1,0.1,0.2,0.5,0.4,0.5c0.2,0,0.3-0.5,0.4-0.6c0.1-0.2,0.5-0.4,0.6-0.6c0.1-0.1,0.1-0.5,0.1-0.6c-0.1-0.1-0.5-0.1-0.6-0.2
		c-0.1-0.1-0.2-0.5-0.4-0.7c-0.1-0.1-0.5-0.2-0.7-0.2c-0.3,0.1-0.3,0.8-0.6,1c-0.1,0.1-0.5-0.1-0.6-0.1c-0.2,0.1-0.6,0.5-0.8,0.6
		c-0.2,0.1-0.7,0.2-0.8,0c-0.1-0.1,0.3-0.4,0.3-0.6c0-0.1-0.3-0.3-0.4-0.4c-0.2,0-0.6,0.2-0.8,0.3c-0.2,0.1-0.4,0.5-0.5,0.6
		c-0.1,0.2-0.4,0.5-0.7,0.5c-0.2,0-0.6-0.3-0.8-0.4c-0.2,0-0.7-0.1-0.8,0c-0.3,0.1-0.5,0.7-0.7,0.9c-0.2,0.2-0.8,0.6-1,0.8
		c-0.2,0.2-0.4,0.7-0.6,0.9c-0.2,0.1-0.6,0.1-0.8,0c-0.2-0.1-0.6-0.6-0.6-0.9c0-0.2,0.1-0.7,0.3-0.8c0.1-0.1,0.5-0.1,0.7-0.2
		c0.2-0.1,0.5-0.4,0.5-0.6c0.1-0.2-0.1-0.6-0.1-0.8c0.1-0.2,0.4-0.6,0.6-0.7c0.2-0.1,0.7-0.2,0.9-0.1c0.3,0,0.8,0.5,1.1,0.5
		c0.2,0,0.6,0,0.8,0c0.2,0,0.8-0.1,0.9-0.3c0-0.2-0.3-0.5-0.4-0.5c-0.2-0.1-0.7,0.1-1,0c-0.2,0-0.6-0.1-0.7-0.3
		c-0.1-0.1-0.1-0.4,0-0.6c0.1-0.2,0.5-0.3,0.7-0.3c0.3,0,0.7,0.5,1,0.5c0.2,0,0.6-0.3,0.8-0.3c0.2,0,0.7,0.1,0.7,0.1
		c0,0.1,0.4,0.5,0.6,0.5c0.1,0,0.4-0.1,0.5-0.2c0.1-0.1,0-0.5-0.1-0.6c-0.1-0.2-0.7-0.2-0.9-0.4c-0.1-0.1-0.1-0.4-0.2-0.5
		c-0.1-0.1-0.6-0.1-0.7-0.3c-0.1-0.2,0-0.6,0.1-0.8c0.1-0.2,0.6-0.2,0.8-0.2c0.2,0,0.5,0.3,0.6,0.5c0.1,0.1,0.1,0.6,0.3,0.7
		c0.1,0.1,0.5,0,0.7,0c0.3,0.1,0.8,0.4,1,0.5c0.2,0.1,0.5,0.2,0.6,0.2c0.2,0,0.4-0.3,0.5-0.5c0.1-0.2,0.1-0.6,0-0.8
		c-0.1-0.2-0.2-0.6-0.4-0.7c-0.2-0.1-0.9,0-1.1,0c-0.2-0.1-0.6-0.2-0.6-0.4c0-0.2,0.3-0.6,0.5-0.7c0.1-0.2,0.6-0.6,0.6-0.9
		c0-0.2-0.4-0.5-0.5-0.7c-0.1-0.2-0.2-0.6-0.3-0.8c-0.1-0.2-0.4-0.6-0.6-0.7c-0.1-0.1-0.5,0-0.6,0.1c-0.2,0-0.6,0.2-0.7,0.3
		c-0.1,0.1-0.3,0.4-0.4,0.5c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.1-0.4,0-0.5c0.1-0.2,0.6-0.4,0.7-0.6c0-0.2-0.1-0.5-0.2-0.7
		c-0.3-0.3-1.1-0.3-1.4-0.3c-0.2,0-0.6-0.1-0.8,0c-0.2,0.1-0.3,0.5-0.4,0.6c-0.1,0.1-0.4,0.2-0.5,0.3c-0.2,0.2-0.3,0.7-0.6,0.8
		c-0.1,0-0.5-0.2-0.5-0.2c0-0.1,0.4-0.8,0.3-1c0-0.1-0.3-0.3-0.4-0.3c-0.2,0-0.5,0.2-0.6,0.3c-0.2,0.1-0.3,0.5-0.5,0.6
		c-0.1,0.1-0.5,0-0.6-0.1c-0.2-0.1-0.5-0.6-0.8-0.7c-0.2,0-0.5,0-0.6,0.1c-0.2,0.2-0.1,0.9-0.2,1.2c0,0.1-0.2,0.3-0.2,0.4
		c-0.1,0.2,0,0.6-0.2,0.7c-0.2,0.1-0.8,0-0.8-0.1c-0.1-0.1-0.5-0.5-0.5-0.8c0-0.2,0.5-0.3,0.6-0.5c0.1-0.2,0-0.6-0.1-0.7
		c-0.1-0.1-0.5,0-0.6,0.1c-0.2,0.1-0.4,0.5-0.6,0.6c-0.2,0.1-0.6,0.4-0.8,0.4c-0.2,0-0.5-0.4-0.6-0.3c-0.2,0-0.4,0.5-0.5,0.6
		c-0.2,0.1-0.5,0.5-0.7,0.4c-0.2-0.1-0.2-0.6-0.4-0.8c-0.1-0.1-0.4,0-0.5-0.1c-0.1,0-0.1-0.2-0.1-0.3c0-0.2-0.1-0.5,0-0.7
		c0.1-0.2,0.6-0.4,0.6-0.5s-0.2-0.7-0.3-0.9c-0.2-0.2-0.6-0.6-0.9-0.7c-0.2-0.1-0.6-0.3-0.8-0.2c0,0,0,0,0,0c-0.2,0-0.5,0.2-0.6,0.3
		c-0.1,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.7,0.1-0.9,0.1c-0.1,0-0.3-0.3-0.4-0.3c-0.2-0.1-0.6-0.3-0.8-0.4c-0.3-0.1-0.9-0.1-1.2,0.1
		c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.2-0.8,0.5-1.1,0.5c-0.2,0-0.8-0.3-0.8-0.4c0-0.1,0-0.7-0.2-0.8c-0.1-0.1-0.4,0.1-0.4,0
		c-0.1,0-0.5-0.2-0.6-0.4c-0.1-0.2-0.1-0.6,0-0.8c0.1-0.2,0.7-0.3,0.9-0.3c0.2,0,0.5,0.4,0.6,0.5c0.1,0.1,0.5,0,0.6,0.1
		c0.2,0.1,0.5,0.5,0.7,0.5c0.2,0,0.6-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.6-0.3c0.3-0.1,0.9,0,1.1,0.1c0.1,0.1,0.2,0.5,0.4,0.5
		c0.1,0.1,0.5,0.2,0.7,0.1c0.1-0.1,0.1-0.5,0-0.7c-0.1-0.2-0.4-0.5-0.5-0.6c-0.2-0.1-0.6-0.2-0.8-0.3c-0.3-0.1-0.9-0.7-1-0.8
		c-0.1,0-0.3-0.6-0.4-0.8c-0.1-0.3-0.1-1.1-0.3-1.3c-0.1-0.1-0.5-0.1-0.7-0.1c-0.2,0.1-0.4,0.5-0.5,0.6c-0.2,0.2-0.6,0.9-0.6,0.9
		c-0.1,0-0.6,0-0.8-0.1c-0.2-0.1-0.5-0.4-0.7-0.4c-0.2,0-0.5,0-0.7,0c-0.1,0-0.3,0-0.4,0c-0.2-0.1-0.6-0.3-0.6-0.5
		c0-0.2,0.4-0.4,0.6-0.5c0.3-0.1,0.9,0.1,1.2,0c0.2-0.1,0.7-0.4,0.8-0.6c0.1-0.2,0.2-0.8,0.1-1c-0.1-0.2-0.4-0.5-0.6-0.6
		c-0.1-0.1-0.4-0.1-0.5-0.2c-0.2-0.2-0.3-0.8-0.5-0.9c-0.2-0.2-1-0.4-1.1-0.5c-0.1,0-0.6-0.3-0.8-0.5c-0.1-0.2,0-0.7-0.2-0.8
		c-0.2-0.2-0.8-0.3-1.1-0.3c-0.3,0-0.7,0.5-1,0.5c-0.2,0-0.6-0.4-0.8-0.5c-0.3-0.2-1.2-0.5-1.3-0.8c-0.1-0.2,0.1-0.6,0.2-0.7
		c0.3-0.2,1.1,0.4,1.4,0.4c0.2,0,0.5,0,0.6-0.2c0.1-0.2,0-0.8,0-0.8c0-0.1-0.4-0.6-0.4-0.8c-0.1-0.3,0.3-0.9,0.1-1.1
		c-0.2-0.3-1-0.4-1.3-0.3c-0.2,0-0.4,0.4-0.5,0.5c-0.3,0.2-0.8,0.5-1.1,0.5c-0.3,0-0.8-0.7-1-0.9c-0.2-0.1-0.6-0.4-0.8-0.5
		c-0.5-0.2-2-0.1-2.1-0.3c-0.1-0.1-0.6-0.9-0.8-1.2c-0.4-0.5-1.4-1.1-1.9-1.6c-0.4-0.4-1-1.2-1.4-1.6c-0.3-0.3-1.1-0.8-1.4-1.2
		c-0.3-0.3-0.7-1-1-1.2c-0.2-0.1-0.7-0.1-0.9-0.2c-0.3-0.1-0.8-0.6-1.2-0.7c-0.3-0.1-0.8-0.2-1.1-0.3c-0.2-0.1-0.7-0.1-0.8-0.3
		c0-0.1-0.1-0.2-0.1-0.2c0-0.4,0.4-1.1,0.2-1.5c-0.1-0.3-0.9-0.3-1.1-0.6c0-0.1,0-0.2,0-0.4c-0.6,0.3-1.2,0.7-1.8,1
		c-0.1,0.1-0.2,0.1-0.3,0.3c-0.1,0.1,0.1,1.4,0.8,2.1c0.6,0.7,1.6,2.1,2.3,2.7c0.1,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0.1,0.7
		c0,0.8,0.1,1.7,0.1,2.5c0,0.2,0,0.3,0,0.4c-0.1,0.2-0.2,0.3-0.4,0.4c-0.9,0.7-1.9,1.4-3,1.8c-1.2,0.4-2.5,0.4-3.5,1.1l-5.5,13.4
		c0.5,0.1,1,0.2,1.3,0.3c0.8,0.3,2.3,0.9,3,1.3c0.5,0.3,1.5,0.7,2,1.2c0.1,0.1,0.1,0.1,0.2,0.2c0.6,0.7,1.5,3.4,1.5,3.4l-1,13.2
		l1.5,2.1l3.8,4.5c1.2,0.4,2.5,0.4,3.8,0.6c0.2-0.1,0.4-0.1,0.5-0.1c0.2,0,0.7,0.2,0.9,0.4c0.2,0.1,0.2,0.6,0.4,0.7
		c0.2,0.1,0.7-0.2,0.8,0c0.1,0.1,0.1,0.3,0.2,0.4c0.2,0.1,0.7,0.1,1,0.2c0.3,0.1,0.8,0.2,1.1,0.3c0.3,0.1,0.8,0.4,1,0.4
		c0.2,0,0.7,0.1,0.8,0c0.2-0.1,0.2-0.6,0.4-0.7c0.1,0,0.3,0.3,0.5,0.4c0.1,0.1,0.4,0.1,0.6,0.1c0.1,0,0.3-0.3,0.5-0.3
		c0.2,0,0.7,0.2,0.9,0.2c0.2,0,0.6-0.4,0.8-0.4c0.2,0,0.5,0.3,0.7,0.3c0.2,0.1,0.6,0,0.8,0.1c0.2,0.1,0.5,0.5,0.7,0.6
		c0.1,0.1,0.5,0,0.7,0.1c0.5,0.9,1.1,1.8,1.4,2.8c0.2,0.6,0.4,1.1,0.7,1.7c0.4,0.7,1.1,1.1,1.8,1.6c1.1,0.6,2.2,1.2,3.2,1.8
		c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.3,0.4,0.8,0.6,0.9c0.2,0.1,0.6-0.1,0.8,0c0.2,0.2,0.3,0.8,0.5,1c0.2,0.2,0.7,0.4,1,0.4
		c0.2,0,0.5-0.2,0.6-0.1c0.2,0.1,0.3,0.5,0.4,0.7c0.1,0.2,0.1,0.6,0.3,0.8c0.1,0.1,0.5,0.3,0.6,0.4c0.2,0.2,0.4,0.6,0.5,0.8
		c0.1,0.2,0,0.6,0,0.7c0.1,0.2,0.4,0.6,0.6,0.7c0.2,0.1,0.9,0,1.1,0.2c0.2,0.2,0.5,0.8,0.4,1.1c0,0.3-0.6,0.7-0.5,1
		c0,0.2,0.4,0.4,0.6,0.6c-1.1,1.1-2,2.3-2.8,3.7c-1-0.3-1.9-0.8-2.8-1.5c-1.4,1.4-2.7,2.8-4,4.2c0.2,0.1,0.5,0.3,0.7,0.4
		c0.2,0.2,0.4,1,0.7,1.2c0.2,0.2,0.9,0.2,1.1,0.2c0.4,0,1-0.6,1.4-0.6c0.3,0,0.9,0.4,1.1,0.6c0.2,0.2,0.4,0.9,0.7,1
		c0.2,0.1,0.8,0,1.1,0c0.2,0.1,0.6,0.5,0.8,0.5c0.3,0,0.6-0.5,0.8-0.7c0.1-0.2,0.3-0.7,0.4-0.8c0.2-0.2,0.8-0.1,1.1-0.2
		c0.2-0.1,0.5-0.5,0.7-0.5c0.3-0.1,0.9,0.3,1.1,0.2c0.3-0.1,0.6-0.7,0.9-0.8c0.2-0.1,0.6,0.2,0.9,0.2c0.2,0,0.6-0.3,0.8-0.4
		c0.2-0.1,0.4-0.5,0.6-0.7c0.3,0.2,0.9,0.4,1.2,0.6c0.2,0.1,0.6,0.5,0.8,0.6c0.3,0.1,0.9,0.1,1.1,0.3c0.2,0.2,0.7,0.7,0.7,1
		c0,0.2-0.6,0.4-0.6,0.7c0,0.2,0.3,0.5,0.5,0.6c0.2,0.2,0.7,0.6,1,0.8c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.6,0.6-0.7
		c0.3-0.1,0.9-0.1,1.1,0c0.2,0.1,0.1,0.7,0.3,0.9c0.2,0.2,0.6,0.3,0.9,0.4c0.3,0.1,0.9-0.1,1.2,0c0.2,0.1,0.5,0.6,0.7,0.8
		c0.2,0.1,0.6,0.2,0.7,0.3c0.2,0.2,0.5,1.1,0.6,1.2c0.1,0.1,1.1,0.1,1.4,0.1c0.2,0,0.5,0.1,0.6,0c0.2-0.1,0-0.7,0.1-0.9
		c0.1-0.2,0.4-0.6,0.6-0.6c0.2,0,0.4,0.4,0.5,0.5c0.2,0.1,0.7-0.1,0.9,0c0.2,0,0.6,0.2,0.7,0.4c0.2,0.2,0.3,0.8,0.6,0.9
		c0.3,0.2,1.1-0.1,1.5,0c0.2,0.1,0.6,0.5,0.8,0.6c0.2,0.1,0.6,0.3,0.9,0.4c0.1,0,0.1,0,0.1,0c0.3,0,0.8-0.5,1.2-0.5
		c0.3,0,0.7,0.5,1,0.4c0.4,0,0.9-0.7,1.2-0.9c0.3-0.2,1-0.3,1.3-0.4c0.3-0.1,0.9-0.7,1.3-0.6c0.3,0,0.7,0.5,0.9,0.6
		c0.3,0.1,0.9,0.3,1.2,0.3c0.3,0,0.7-0.4,1-0.4c0.4-0.1,1.3,0.2,1.7,0c0.2-0.1,0.5-0.5,0.7-0.6c0.3-0.2,1-0.5,1.3-0.4
		c0.3,0.1,0.8,0.5,0.9,0.8c0.2,0.3-0.1,1.1,0.2,1.3c0,0,0,0,0.1,0c0.2,0.1,0.7,0,0.9-0.1c0.2,0,0.6,0.1,0.9,0
		c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.2,0.5-0.4,0.7-0.4c0.3-0.1,0.9-0.2,1.2-0.1c0.2,0,0.5,0.3,0.7,0.3c0.2,0,0.5,0,0.7,0
		c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.4,0.4,0.6,0.4c0.2,0,0.5-0.1,0.6-0.3C339,142.1,338.9,141.6,338.8,141.4z"/>
    <g id="jackson-emc">
        <path @click="openEmc" data-emc="jackson-emc" ref="jackson-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M221.7,88.1c-0.1-0.2-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.4-0.4-0.6c-0.1-0.2-0.2-0.7-0.1-0.9
		c0.1-0.1,0.2-0.1,0.3-0.1c-0.7-0.8-1.5-1.5-2.3-2.1c-2.7-2.2-5.5-4.5-8.2-6.7l0-0.1l0.1-1.8c0,0,0.4-0.1,0.5-0.2
		c0.2-0.2,0.5-0.6,0.5-0.8c0-0.5-0.6-1.3-0.9-1.6c-0.4-0.3-1.4-0.4-1.8-0.7c0,0,0,0,0,0c-0.3,0.2-1.2,0.8-1.7,1.1
		c-0.6,0.3-1.9,0.4-1.9,0.4l-4.2-0.5l-2.4,2.2c0,0-0.1,2.9-0.2,4.4c0,0.9-0.1,1.7-0.4,2.5c-0.1,0.3-0.3,0.6-0.4,0.9
		c0.2,0,0.7,0.2,0.8,0.4c0.1,0.3-0.4,0.8-0.4,1.1c0,0.3,0,1,0.2,1.4c0.2,0.4,0.7,1.1,1.1,1.2c0.4,0.1,1.1-0.3,1.3-0.6
		c0.2-0.2,0.1-0.8,0.2-1c0.1-0.3,0.2-0.9,0.4-1.1c0.1-0.1,0.3-0.1,0.6-0.1c0.2,0,0.4,0.1,0.5,0.1c0.2,0.1,0.4,0.5,0.3,0.7
		c0,0.2-0.5,0.3-0.6,0.5c0,0.3,0.6,0.8,0.7,1.1c0.1,0.2,0.2,0.8,0,1c-0.2,0.2-0.7,0-1,0c-0.2,0-0.6,0-0.8,0.1
		c-0.2,0-0.4,0.2-0.6,0.3c-0.1,0-0.2,0.1-0.2,0.1c-0.2,0.1-0.6,0.3-0.8,0.5c-0.2,0.1-0.5,0.5-0.6,0.7c-0.1,0.2,0,0.6,0.1,0.8
		c0.1,0.2,0.6,0.2,0.8,0.2c0.2,0.1,0.8,0.1,0.9,0.3c0.2,0.2,0.3,0.8,0.1,1.1c-0.1,0.2-0.7,0.1-0.9,0.1c-0.2,0-0.7-0.2-0.9-0.2
		c-0.1,0-0.4-0.2-0.5-0.2c-0.2,0.1-0.5,0.5-0.5,0.7c0,0.2,0.4,0.3,0.4,0.5c0,0.2-0.6,0.5-0.5,0.6c0.1,0.2,0.7-0.2,0.9,0
		c0.1,0.1,0.2,0.5,0.2,0.7c-0.1,0.2-0.6,0.3-0.7,0.5c-0.1,0.2,0.1,0.5,0.1,0.7c0,0,0,0,0,0c0,0.3-0.1,0.9,0.1,1.1
		c0.2,0.2,0.8,0,0.9,0.2c0.1,0.1-0.1,0.6,0.1,0.7c0.2,0.1,0.5-0.3,0.5-0.5c0.1-0.2,0-0.7,0.1-0.9c0.1-0.1,0.4-0.2,0.5-0.4
		c0.2-0.2,0.2-0.9,0.4-1c0.1-0.1,0.4-0.1,0.6,0c0.2,0.1,0.4,0.4,0.4,0.6c0,0.2-0.6,0.3-0.7,0.5c0,0.2,0.2,0.5,0.4,0.6
		c0.2,0,0.4-0.4,0.6-0.4c0.2,0,0.6,0.2,0.8,0.3c0.2,0.1,0.7,0.4,0.8,0.6c0.1,0.2,0.1,0.7,0,0.9c-0.1,0.2-0.8,0.3-0.9,0.4
		c-0.1,0.1-0.1,0.4-0.2,0.5c-0.2,0.1-0.6-0.2-0.8-0.1c-0.2,0-0.5,0.2-0.6,0.3c-0.2,0.2-0.6,0.7-0.6,1c0,0.3,0.5,0.6,0.7,0.7
		c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.2-0.1,0.7,0.1,0.9c0.1,0.1,0.5,0.1,0.6,0.1c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.7,0,0.9,0
		c0.1,0,0.4,0.1,0.5,0.1c0.2,0,0.5-0.2,0.6-0.4c0.1-0.1,0.2-0.5,0.3-0.6c0.1-0.1,0.5-0.2,0.6-0.3c0.2-0.1,0.6-0.1,0.8-0.2
		c0.2-0.2,0.2-0.7,0.3-0.9c0-0.3,0.2-0.9,0-1.2c-0.1-0.2-0.7-0.3-0.9-0.4c-0.2-0.1-0.4-0.5-0.6-0.7c-0.1-0.1-0.3,0-0.4-0.1
		c-0.2-0.1-0.5-0.3-0.5-0.5c0.1-0.2,0.7-0.3,0.9-0.4c0.2,0,0.6-0.1,0.8-0.1c0.1,0,0.3,0.3,0.4,0.4c0.2,0,0.6-0.1,0.7-0.2
		c0.1-0.2,0.1-0.6,0.1-0.8c0-0.2,0.2-0.6,0.1-0.8c-0.1-0.2-0.4-0.6-0.5-0.8c-0.2-0.2-0.5-0.7-0.6-1c-0.2-0.2-0.5-0.7-0.7-0.9
		c-0.2-0.3-0.8-0.7-1-1c-0.1-0.2-0.4-0.6-0.4-0.9c0-0.2,0.3-0.7,0.5-0.8c0.2,0,0.5,0.1,0.6,0.2c0.1,0.1,0.2,0.5,0.3,0.7
		c0.1,0.3,0.4,0.8,0.5,1.1c0.3,0.4,0.9,1.3,1.2,1.7c0.2,0.4,0.6,1.3,0.8,1.7c0.2,0.4,0.1,1.7,0.5,1.7c0.3,0,0.3-0.8,0.5-1
		c0.1-0.1,0.6-0.2,0.7-0.4c0.2-0.2,0.7-0.9,0.6-1.2c-0.1-0.3-0.8-0.4-1-0.7c-0.1-0.2-0.1-0.6-0.3-0.8c-0.3-0.4-1.2-0.7-1.5-1
		c-0.3-0.3-0.5-1-0.7-1.4c-0.1-0.1-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.8-0.4-1.1c-0.2-0.2-0.8-0.3-1-0.5c-0.1-0.1-0.2-0.5-0.2-0.6
		c0.1-0.2,0.5-0.2,0.7-0.4c0.1-0.1,0.3-0.5,0.3-0.7c0-0.2,0-0.7-0.1-0.8c-0.1-0.1-0.6,0-0.7-0.2c-0.1-0.2-0.1-0.6,0-0.8
		c0-0.1,0.2-0.4,0.3-0.4c0.2-0.2,0.8-0.5,1.1-0.4c0.3,0.1,0.7,0.5,0.7,0.8c0.1,0.2-0.2,0.7-0.2,1c0,0.2,0.2,0.5,0.2,0.7
		c0,0.2,0,0.7,0,1c0,0.2-0.2,0.6-0.1,0.8c0.1,0.2,0.5,0.3,0.6,0.4c0.1,0.1,0.2,0.3,0.2,0.4c0,0.3-0.4,0.7-0.4,1
		c0,0.2,0.2,0.7,0.4,0.8c0.2,0.2,0.6,0.6,0.9,0.7c0.3,0.1,0.8-0.1,1.1,0c0.2,0.1,0.4,0.6,0.5,0.8c0.1,0.2,0.3,0.6,0.5,0.7
		c0.3,0.2,1.1,0.6,1.4,0.4c0.2-0.1,0.1-0.9,0.3-1c0.1-0.1,0.5,0,0.6,0c0.2,0,0.4,0.3,0.6,0.3c0.2,0,0.6-0.2,0.7-0.4
		c0.1-0.1,0.3-0.4,0.4-0.4c0.1,0,0.5,0.1,0.5,0.3c0.1,0.2-0.1,0.7-0.3,0.9c-0.2,0.2-1,0.1-1.2,0.3c-0.2,0.1-0.3,0.6-0.4,0.7
		c-0.2,0.1-0.7,0.2-0.8,0.3c-0.2,0.2-0.5,0.6-0.8,0.8c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.5,0.1-0.5,0.2c-0.1,0.1-0.1,0.4,0,0.6
		c0.1,0.2,0.5,0.4,0.7,0.4c0.2,0,0.5-0.2,0.7-0.2c0.3-0.1,0.8-0.1,1-0.2c0.2-0.1,0.4-0.6,0.6-0.8c0.1-0.1,0.4-0.5,0.6-0.5
		c0.1,0,0.3,0.3,0.5,0.4c0.2,0.1,0.7,0.1,0.9,0c0.2-0.1,0.3-0.6,0.4-0.8c0-0.2-0.1-0.5-0.1-0.6c0-0.2,0-0.6,0.1-0.8
		c0.1-0.1,0.4-0.1,0.5-0.1c0.2,0,0.4,0.4,0.5,0.5c0.1,0.1,0.4,0.1,0.5,0.1c0.2-0.1,0.2-0.6,0.2-0.8c0-0.2-0.2-0.6-0.1-0.8
		c0.1-0.1,0.4-0.2,0.5-0.3c0.1,0,0.5-0.1,0.6,0c0.1,0.1-0.2,0.6-0.1,0.7c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.2,0.5-0.4
		c0.1-0.2,0-0.8,0.1-1c0.1-0.2,0.4-0.4,0.5-0.6c0.2-0.1,0.5-0.4,0.6-0.5c0.1-0.2,0.2-0.6,0.3-0.8c0.1-0.1,0.3-0.3,0.4-0.5
		C221.8,88.8,221.9,88.3,221.7,88.1z"/>
	<path @click="openEmc" data-emc="jackson-emc" ref="jackson-emc-1" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M292.4,126.8c0-0.3,0.5-0.7,0.5-1c0-0.3-0.2-0.9-0.4-1.1c-0.2-0.2-0.9-0.1-1.1-0.2c-0.2-0.1-0.5-0.5-0.6-0.7
		c-0.1-0.2,0-0.6,0-0.7c-0.1-0.2-0.3-0.7-0.5-0.8c-0.1-0.1-0.5-0.3-0.6-0.4c-0.1-0.2-0.2-0.6-0.3-0.8c-0.1-0.2-0.2-0.6-0.4-0.7
		c-0.1-0.1-0.5,0.1-0.6,0.1c-0.3,0-0.8-0.2-1-0.4c-0.2-0.2-0.3-0.9-0.5-1c-0.2-0.1-0.6,0.1-0.8,0c-0.2-0.1-0.6-0.6-0.6-0.9
		c0-0.1,0-0.3,0.1-0.4c-1.1-0.6-2.2-1.1-3.2-1.8c-0.7-0.4-1.4-0.9-1.8-1.6c-0.3-0.5-0.5-1.1-0.7-1.7c-0.4-1-0.9-1.9-1.4-2.8
		c-0.2-0.1-0.6,0-0.7-0.1c-0.2-0.1-0.5-0.5-0.7-0.6c-0.2-0.1-0.6,0-0.8-0.1c-0.2,0-0.5-0.3-0.7-0.3c-0.2,0-0.6,0.4-0.8,0.4
		c-0.2,0-0.7-0.2-0.9-0.2c-0.1,0-0.3,0.3-0.5,0.3c-0.1,0-0.4,0-0.6-0.1c-0.1-0.1-0.3-0.4-0.5-0.4c-0.2,0-0.2,0.6-0.4,0.7
		c-0.2,0.1-0.6,0-0.8,0c-0.3-0.1-0.8-0.3-1-0.4c-0.3-0.1-0.8-0.3-1.1-0.3c-0.2-0.1-0.8,0-1-0.2c-0.1-0.1-0.1-0.3-0.2-0.4
		c-0.2-0.1-0.7,0.2-0.8,0c-0.2-0.1-0.2-0.6-0.4-0.7c-0.2-0.2-0.6-0.3-0.9-0.4c-0.1,0-0.3,0.1-0.5,0.1c0,0,0,0,0,0
		c-1.3-0.2-2.5-0.3-3.8-0.6l0.1,0.1l-3.8-4.6l-1.5-2.1l1-13.2c0,0-0.9-2.7-1.5-3.4c0-0.1-0.1-0.1-0.2-0.2c-0.5-0.4-1.5-0.9-2-1.2
		c-0.7-0.4-2.2-1.1-3-1.3c-0.3-0.1-0.8-0.2-1.3-0.3c-0.6-0.1-1.2-0.3-1.6-0.3c-0.8-0.1-2.3-0.4-3.1-0.4c-0.4,0-1.2,0.2-1.6,0.1
		c-0.3-0.1-0.7-0.7-1.1-0.6c-0.4,0.1-0.7,0.9-1,1.1c-0.3,0.2-1,0.1-1.3,0.2c-0.2,0.1-0.6,0.1-0.7,0.3c-0.2,0.2-0.2,0.7-0.4,0.9
		c-0.1,0.1-0.5,0.3-0.6,0.4c-0.2,0.3-0.3,1-0.5,1.2c-0.2,0.2-0.6,0.7-0.8,0.8c-0.3,0.2-1.2,0.2-1.5,0.4c-0.2,0.1-0.7,0.4-0.8,0.6
		c-0.3,0.3-0.5,1.1-0.7,1.4c0,0,0,0,0,0.1l-0.1-0.1c-1,0.1-1.9,0.3-2.8,0.7c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0
		c-0.4-0.1-0.8-0.2-1.1-0.3c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.4,0-0.5,0.1c-0.2,0-0.7,0-0.8,0.1c-0.2,0.1-0.5,0.5-0.6,0.8
		c0,0.2,0.4,0.7,0.4,0.9c0,0.2-0.3,0.5-0.4,0.7c0,0.2,0.2,0.6,0.1,0.7c-0.1,0.2-0.9,0.1-0.9,0c0-0.1-0.4-0.5-0.5-0.7
		c-0.1-0.2,0.1-0.8,0-1c-0.1-0.2-0.7-0.3-0.9-0.2c-0.2,0.1-0.3,0.5-0.4,0.5c-0.1,0.1-0.3,0-0.5,0c-0.2,0.1-0.5,0.2-0.6,0.4
		c-0.1,0.1-0.1,0.5-0.2,0.6c-0.1,0.1-0.4,0.3-0.4,0.3c-0.1,0.1-0.1,0.6,0,0.7c0.1,0.1,0.4,0.1,0.5,0c0.1-0.1,0.1-0.4,0.2-0.5
		c0.1,0,0.4,0.2,0.4,0.3c0.1,0.1,0.2,0.6,0.1,0.7c-0.1,0.2-0.6,0.2-0.8,0.2c-0.2,0-0.7-0.1-0.9,0c-0.2,0.1-0.3,0.5-0.4,0.6
		c-0.3,0.2-0.9,0.4-1.2,0.5c-0.2,0.1-0.6,0.4-0.8,0.6c-0.1,0.1-0.3,0.4-0.5,0.5c-0.1,0.1-0.5,0.1-0.7,0.1c-0.2,0.1-0.7,0.4-0.9,0.6
		c-0.1,0.2-0.1,0.7-0.2,0.9c-0.1,0.1-0.4,0.4-0.5,0.4c-0.2,0.1-0.7,0-0.8,0.1c-0.2,0.1-0.4,0.5-0.5,0.7c-0.1,0.2-0.3,0.5-0.3,0.7
		c-0.1,0.2-0.3,0.7-0.4,1c-0.1,0.2-0.2,0.6-0.4,0.7c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2-0.1-0.4-0.3-0.4-0.5c0-0.2,0.3-0.4,0.4-0.5
		c0.1-0.2,0-0.5,0-0.7c0.1-0.2,0.4-0.5,0.4-0.6c0.1-0.1,0.2-0.5,0.1-0.6c-0.1-0.1-0.4-0.1-0.5-0.1c-0.2,0.1-0.3,0.7-0.5,0.9
		c-0.2,0.1-0.7,0.1-0.9,0.2c-0.2,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.5,0.4-0.8,0.4c-0.1,0-0.4-0.2-0.6-0.1c-0.1,0-0.4,0.3-0.5,0.4
		c-0.1,0.3,0.1,0.8,0.2,1.1c0,0.3,0.2,1,0.1,1.4c0,0.1-0.2,0.4-0.3,0.4c-0.2,0-0.4-0.4-0.6-0.5c-0.3-0.2-0.8-0.8-1.1-0.7
		c-0.2,0-0.4,0.5-0.4,0.6c0,0.4,0.5,1.1,0.5,1.4c0,0.3-0.1,0.8-0.2,1c-0.1,0.2-0.3,0.5-0.5,0.5c-0.2,0.1-0.6-0.3-0.8-0.2
		c-0.2,0-0.5,0.4-0.7,0.4c-0.2,0-0.6-0.2-0.8-0.2c-0.3,0-0.9,0-1.1,0.2c-0.1,0.1-0.3,0.4-0.4,0.5c-0.2,0.2-0.8,0.3-1,0.4
		c-0.2,0.1-0.7,0.2-0.8,0.3c-0.1,0.1-0.1,0.4-0.2,0.5c-0.2,0.2-0.8,0.3-1.1,0.4c-0.2,0-0.5-0.1-0.7,0c-0.2,0.1-0.4,0.5-0.4,0.8
		c0,0.3,0.3,0.8,0.5,0.9c0.3,0.2,1,0.2,1.2,0.1c0.2-0.1,0.3-0.8,0.5-1c0.2-0.1,0.6-0.2,0.8-0.3c0.2-0.1,0.4-0.5,0.6-0.6
		c0.2-0.1,0.6-0.2,0.8-0.2c0.2,0,0.6,0,0.7,0c0.2,0,0.5,0.1,0.7,0.3c0.1,0.1,0.4,0.5,0.4,0.7c0,0.2-0.3,0.6-0.5,0.7
		c-0.2,0-0.5-0.3-0.7-0.4c-0.3,0-1,0-1.3,0.2c-0.1,0.1-0.3,0.3-0.2,0.5c0.1,0.2,0.5,0.3,0.7,0.4c0.2,0.1,0.7-0.1,0.8,0
		c0.1,0.1,0,0.3,0,0.4c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.1-0.6,0.3-0.8,0.3c-0.2,0-0.5-0.3-0.8-0.4c-0.3,0-0.8,0-1.1,0.1
		c-0.2,0.1-0.6,0.3-0.6,0.5c0,0.2,0.4,0.6,0.5,0.8c0.2,0.2,0.7,0.4,0.7,0.6c0,0.1-0.1,0.5-0.2,0.6c-0.1,0.1-0.4,0.1-0.6,0.1l4.1,3.7
		l-6.6,8l-1.5,1.8c0,0-0.4,1.7-1.4,2.3c-1,0.6-2.4,0.7-2.7,1.5s0,4.4,0,4.4s-0.9,0.7-1.5,0.8c-0.6,0.1-1.4-1.6-1.9-1.3
		c-0.4,0.2-1.8,2.8-3.1,3.3c-1.3,0.5-4.2,0.4-5.7,2.2c0,0,0,0-0.1,0l3.1,3.1l0.3,1.3h-4.4c0,0-1.5,1.5-3,1.9c-1.5,0.5-2.5,2-2.5,2
		s1.7,1.3,1.7,3.7s1.2,2.5,1.2,2.5l3.2-1.5l5.1,0.1l3-1.5h2.6c0,0,2.3-2.6,4-2.7c1.7-0.1,3.4,0.3,4.2,1.1c0.8,0.8,2.6,1,3.5,1.6
		c0.9,0.6,2.4,1,2.6,0s-0.9-1.9-0.3-2.7c0.6-0.8,2.5,0.2,3.1-1c0.5-0.8,2.4-2.2,3.5-2.9c0.1,0.1,0.1,0.1,0.1,0.2
		c0.3,0.3,1.1,0.7,1.4,1c0.2,0.2,0.2,0.9,0.4,1.2c0.1,0.2,0.6,0.3,0.7,0.5c0.1,0.3-0.2,1.1-0.1,1.4c0,0.1,0.1,0.1,0.1,0.2
		c0.3,0.4,1,0.8,1.3,1c0.3,0.2,0.9,0.5,1.2,0.7c0.4,0.2,1.5,0.5,1.7,0.9c0.1,0.2-0.3,0.6-0.3,0.9c0,0.1,0.1,0.5,0.2,0.6
		c0.2,0.1,0.8,0,1-0.1c0.3-0.1,0.6-0.5,0.9-0.6c0.2,0,0.7,0.3,0.9,0.3c0.4,0,1.1,0,1.5-0.1c0.3-0.1,0.7-0.5,0.9-0.6
		c0.2-0.1,0.6,0,0.8-0.1c0.2-0.1,0.5-0.6,0.7-0.7c0.2-0.1,0.7,0.1,0.9,0c0.2-0.1,0.6-0.3,0.7-0.4c0,0,0.1,0,0.1-0.1
		c0-0.1,0-0.1,0-0.2c-0.2-0.5-0.7-0.7-1.1-0.9c0.2-1.4,0.3-2.9,0.5-4.3c0.2,0.4,0.7,0.5,1.1,0.6c0.4,0.1,0.9,0.1,1.3,0.2
		c0.7,0.2,1.3,0.7,1.9,1.1c0.8,0.5,1.8,0.8,2.7,1c0.6,0.2,1.2,0.3,1.8,0.5l6.2-4.2l2.7-1.8l-2.6,1.8c1,0,2.2-0.1,3,0.1
		c0.4,0.1,0.8,0.3,1.1,0.4c1.8,0.7,3.6,1.3,5.5,1.6c0.3,0,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.3,0.8,0.5c0.6,0.5,1.3,0.9,1.9,1.4
		c0.9,0.6,1.7,1.2,2.6,1.9c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.4c0.7,0.6,1.3,1.1,2,1.7
		c0.9-1.1,1.9-2.3,2.8-3.4c0.2-0.3,0.5-0.6,0.8-0.8c0.5-0.3,1.1-0.2,1.7-0.2c1,0.1,1.9,0.2,2.9,0.3c0-0.4,0.1-0.8,0.1-1.2
		c-0.1-0.2-0.3-0.3-0.4-0.4c-0.2-0.2-0.7-0.4-0.7-0.7c0-0.3,0.5-0.8,0.7-1c0.2-0.2,0.8-0.4,0.9-0.7c0-0.1,0-0.1,0-0.2
		c0.1-0.3,0-0.9-0.1-1.1c0-0.2-0.2-0.6-0.3-0.8c0-0.2-0.1-0.6-0.1-0.9c0,0,0,0,0,0c1-0.1,1.9-0.3,2.9-0.4c0.3,0,0.6,0,0.9-0.1
		c0.5-0.1,1-0.4,1.4-0.6c0.9-0.5,1.9-1.1,2.4-2c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.2,0.5-0.9,0.7-1.2
		c0.2-0.3,0.9-0.6,1.2-0.9c0.2-0.2,0.5-0.6,0.8-0.7c0.3-0.1,0.9,0,1.2-0.1c0.4-0.1,1-0.7,1.4-0.8c0.3-0.1,0.9-0.2,1.2-0.1
		c0.1,0,0.2,0.1,0.3,0.1c1.3-1.4,2.6-2.9,4-4.2c0.8,0.6,1.8,1.2,2.8,1.5c0.8-1.3,1.8-2.5,2.8-3.7C292.8,127.2,292.4,127,292.4,126.8
		z"/>
    </g>
	<path @click="openEmc" data-emc="tri-county-emc" ref="tri-county-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M281,260.3l-3.8-5.1l-0.2-1.4l-2.7-3.8l-0.6-1l2.1-2.2l0.3-1.1l-0.6-1.3c-0.2-0.1-0.5-0.1-0.6-0.2
		c-0.2-0.1-0.5-0.5-0.7-0.6c-0.3-0.1-1,0-1.3-0.1c-0.2-0.1-0.8-0.2-0.9-0.4c-0.1-0.1-0.2-0.5-0.1-0.7c0.1-0.2,0.6-0.2,0.6-0.4
		c0.1-0.3-0.3-0.8-0.6-0.9c-0.2-0.1-0.8-0.1-1-0.2c-0.3-0.1-0.9,0-1.1-0.2c-0.1-0.1-0.2-0.5-0.1-0.6c0.1-0.1,0.5-0.1,0.6-0.3
		c0.1-0.2-0.1-0.7-0.3-0.8c-0.2-0.1-0.6,0-0.8,0c-0.2,0.1-0.4,0.6-0.6,0.7c-0.3,0-0.8-0.7-1.1-0.7c-0.3,0-0.7,0.6-1,0.8
		c-0.2,0.1-0.5,0.6-0.7,0.6c-0.2,0-0.6-0.5-0.8-0.5c-0.1,0-0.4-0.1-0.5,0c-0.1,0.1,0.1,0.6,0,0.7c-0.1,0.2-0.5,0.4-0.6,0.6
		c-0.1,0.2,0.1,0.5,0,0.7c-0.1,0.2-0.4,0.7-0.7,0.7c-0.2,0.1-0.7,0-0.8-0.1c-0.1-0.1-0.1-0.4-0.1-0.6c0-0.2,0.4-0.5,0.3-0.6
		c-0.1-0.1-0.5,0.1-0.7,0.1c-0.2,0-0.6-0.2-0.8-0.4c-0.2-0.2-0.3-0.9-0.6-1c-0.2-0.1-0.8-0.1-1,0c-0.3,0.1-0.5,0.7-0.7,0.9
		c-0.1,0.1-0.5,0.1-0.6,0.3c-0.2,0.2-0.1,0.9-0.3,1.1c-0.2,0.2-0.8,0.5-1,0.4c-0.1,0-0.2-0.3-0.2-0.4c0-0.2,0.3-0.6,0.4-0.8
		c0-0.1,0-0.4,0.1-0.6c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.2,0.4-0.7,0.3-0.9c-0.2-0.3-1,0.1-1.4,0c-0.2,0-0.5-0.1-0.7-0.2
		c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2-0.1-0.5-0.1-0.7-0.1c-0.3-0.1-1.1-0.4-1.2-0.4c0,0-0.1-0.1-0.2-0.2c-0.1-0.2-0.3-0.5-0.2-0.6
		c0.2-0.3,1.1-0.1,1.5,0c0.2,0,0.6,0.1,0.8,0.2c0.2,0.1,0.5,0.5,0.7,0.5c0.2,0.1,0.7,0.1,1,0.1c0.1,0,0.4-0.1,0.6-0.1
		c0.2,0.1,0.5,0.5,0.7,0.6c0.2,0.1,0.9,0.2,1,0c0.1-0.2-0.2-0.7-0.4-0.8c-0.2-0.2-0.9-0.4-1.2-0.5c-0.2-0.1-0.7-0.2-0.9-0.3
		c-0.2-0.1-0.3-0.6-0.5-0.7c-0.2-0.1-0.7-0.1-0.9-0.3c-0.1-0.1,0.1-0.6,0-0.7c-0.2-0.2-0.8-0.1-1.1-0.3c-0.2-0.2-0.5-0.8-0.6-1.1
		c0-0.2,0.2-0.7,0.2-0.9c0-0.2-0.1-0.6-0.1-0.8c0-0.2,0.1-0.8,0.3-0.9c0.1-0.1,0.4,0.1,0.5,0.3c0.1,0.2,0.1,0.7,0.2,0.9
		c0.1,0.2,0.2,0.7,0.3,0.9c0.1,0.2,0.6,0.3,0.7,0.4c0.1,0.2,0,0.7,0.1,0.9c0.2,0.2,0.7,0.1,0.9,0.2c0.2,0.1,0.6,0.1,0.7,0.3
		c0.1,0.1,0.1,0.5,0.2,0.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.4-0.1,0.5,0c0.2,0.1,0.6,0.3,0.7,0.5c0.1,0.1,0.1,0.5,0.2,0.6
		c0.2,0.2,0.9,0.2,1.1,0.4c0.2,0.2,0,0.8,0.1,1.1c0.1,0.2,0.5,0.4,0.7,0.5c0.1,0.1,0.4,0.4,0.5,0.4c0.2,0,0.2-0.5,0.3-0.7
		c0.1-0.2,0.2-0.6,0.4-0.7c0.1-0.1,0.5,0.1,0.7,0.1c0.2,0,0.6-0.1,0.8-0.1c0.2,0,0.6,0.1,0.8,0c0.1-0.1,0.2-0.5,0.1-0.6
		c-0.1-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.1-0.6,0.1-0.7c0.1-0.1,0.5,0,0.5,0c0.1,0,0.4-0.4,0.6-0.4c0.3,0,0.6,0.5,0.8,0.7
		c0.2,0.1,0.5,0.4,0.7,0.3c0.2-0.1,0.1-0.7,0.1-0.9c0-0.2-0.3-0.5-0.3-0.6c-0.1-0.2,0-0.7-0.1-0.9c-0.1-0.2-0.5-0.3-0.6-0.5
		c-0.1-0.2-0.2-0.7-0.1-0.9c0.1-0.1,0.4-0.2,0.5-0.3c0.1-0.2-0.1-0.7,0.1-0.8c0.2-0.1,0.6,0.1,0.7,0.2c0.1,0.2-0.1,0.7,0,0.9
		c0.1,0.2,0.5,0.2,0.6,0.4c0.1,0.3-0.1,0.9-0.1,1.2c0,0.2,0,0.5,0.1,0.6c0.1,0.1,0.5,0.1,0.7,0c0.1-0.1,0.3-0.3,0.4-0.5
		c0.1-0.1,0.1-0.4,0.2-0.5c0-0.2,0-0.6,0.1-0.8c0.1-0.2,0.8-0.3,0.8-0.5c0-0.3-0.8-0.6-0.8-0.9c0-0.2,0.5-0.2,0.6-0.4
		c0.1-0.2,0-0.8,0-1c-0.1-0.2-0.5-0.6-0.5-0.8c0-0.3,0.5-0.6,0.6-0.9c0-0.2,0-0.5-0.1-0.7c0-0.1-0.3-0.3-0.3-0.4
		c-0.1-0.2-0.2-0.7,0-0.8c0.1-0.1,0.6,0.1,0.7,0c0.2-0.1,0.2-0.6,0.2-0.8c0-0.3-0.6-0.7-0.6-1c0-0.2,0.3-0.5,0.4-0.6
		c0.1-0.1,0.5-0.1,0.6,0c0.2,0.1,0.4,0.7,0.5,1c0,0.2-0.1,0.7-0.1,0.9c0,0.2,0.2,0.7,0.4,0.8c0.2,0.1,0.8,0.2,1,0
		c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.2-0.5-0.2-0.7c0-0.2,0.1-0.7,0.3-0.9c0.2-0.2,0.8-0.2,1-0.4c0.2-0.1,0.6-0.5,0.8-0.5
		c0.1,0,0.3,0.1,0.5,0.1c0.1,0,0.4-0.2,0.5-0.3c0.1-0.1,0.2-0.5,0.4-0.6c0.1-0.1,0.5-0.2,0.7-0.2c0.2,0,0.4,0.4,0.6,0.4
		c0.2,0,0.6-0.1,0.7-0.2c0.2-0.1,0.4-0.5,0.5-0.7c0.1-0.3,0.1-1,0.3-1.1c0.1,0,0.3,0.2,0.4,0.3c0.3,0.1,0.9,0.1,1.1,0
		c0.2-0.1,0.4-0.7,0.4-1c0-0.2-0.6-0.4-0.6-0.6c-0.1-0.2,0-0.6,0.1-0.7c0.1-0.2,0.7,0,0.8-0.2c0.1-0.4-0.6-1-0.8-1.3
		c-0.1-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.5-0.3-0.6-0.4c-0.1-0.2-0.1-0.6-0.3-0.8c-0.1-0.1-0.3-0.5-0.5-0.5c-0.2,0-0.4,0.4-0.6,0.5
		c-0.2,0.1-0.7,0.3-0.9,0.2c-0.2-0.1-0.3-0.6-0.2-0.7c0.1-0.3,0.9-0.3,1.2-0.5c0.1-0.1,0.4-0.4,0.4-0.6c0-0.2,0-0.6-0.1-0.7
		c-0.1-0.2-0.7-0.5-0.8-0.7c0-0.1,0.1-0.3,0.1-0.4c0-0.2-0.3-0.6-0.5-0.7c-0.1-0.1-0.5-0.1-0.6,0c-0.2,0.1-0.3,0.4-0.5,0.6
		c-0.2,0.2-0.6,0.4-0.7,0.6c-0.1,0.2,0.3,0.7,0.2,0.8c-0.2,0.2-0.7-0.3-0.9-0.3c-0.2,0-0.4,0.3-0.5,0.4c-0.1,0.1-0.3,0.4-0.3,0.5
		c-0.1,0.2,0.1,0.8-0.1,1c-0.3,0.1-1-0.3-1.1-0.5c-0.1-0.2-0.1-0.6-0.3-0.7c-0.1-0.1-0.8,0.3-0.9,0.2c-0.1-0.1,0-0.5,0.1-0.6
		c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.3-0.5,0.4-0.6c0.1-0.2,0.1-0.6,0-0.7c-0.1-0.1-0.5,0-0.6,0.1c-0.1,0.1-0.1,0.4-0.2,0.5
		c-0.2,0.1-0.6,0.1-0.7-0.1c-0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.1,0.4-0.1,0.4-0.2c0-0.1-0.2-0.4-0.3-0.5c-0.1-0.1-0.4-0.1-0.5-0.1
		c-0.2,0.1-0.4,0.7-0.6,0.9c-0.1,0.1-0.2,0.3-0.3,0.3c-0.2,0-0.4-0.4-0.4-0.6c0-0.1,0.3-0.4,0.3-0.5c0-0.2-0.3-0.3-0.5-0.4
		c-0.2-0.1-0.6-0.1-0.8,0c-0.1,0.1-0.1,0.5-0.3,0.7c-0.1,0.1-0.4,0.2-0.5,0.3c-0.1,0.2,0.2,0.7-0.1,0.8c-0.2,0.1-0.5-0.2-0.7-0.4
		c-0.1-0.1,0.3-0.6,0.3-0.7c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1-0.1-0.5,0-0.7c0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.4-0.3-0.5
		c-0.1-0.2-0.2-0.6-0.1-0.8c0.1-0.1,0.3-0.4,0.4-0.4c0.1,0,0.4,0.2,0.5,0.3c0.1,0.2-0.1,0.6,0,0.7c0.1,0.2,0.5,0.2,0.6,0.4
		c0.1,0.2,0.2,0.6,0.3,0.8c0.1,0.1,0.6,0.1,0.7,0.1c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.6,0.1,0.7-0.1c0.1-0.2-0.3-0.7-0.3-0.9
		c0-0.2,0.2-0.5,0.2-0.7c0-0.2-0.4-0.5-0.6-0.7c-0.2-0.2-0.9-0.3-1-0.6c0-0.1,0.1-0.3,0.2-0.5c0.1-0.3,0.4-0.9,0.3-1.2
		c-0.1-0.2-0.5-0.4-0.6-0.6c-0.1-0.2,0.1-0.6,0-0.8c-0.1-0.2-0.5-0.5-0.7-0.5c-0.2,0-0.6,0.2-0.7,0.3c-0.1,0.1-0.1,0.6-0.3,0.7
		c-0.1,0.1-0.4,0.1-0.5,0c-0.1-0.1,0-0.5,0-0.7c0-0.2-0.1-0.5-0.2-0.6c-0.2-0.2-0.6-0.4-0.9-0.4c-0.2,0-0.5,0.1-0.6,0.2
		c-0.1,0.1-0.1,0.5-0.3,0.5c-0.1,0.1-0.5,0.1-0.6,0c-0.1-0.1-0.1-0.3-0.2-0.4l-3.5,0.3l-2.2,2.1l-2.8,1.1l-4.2,1c0,0-1,0.5-1.9,1.2
		c-0.9,0.7-0.8,3-1.2,3.9c-0.3,0.9-2.3,2.3-2.3,2.3l-4.2-4.5l0.2-3.6l-0.2-0.2l0,0c-0.9,0.2-2.2,0.6-2.6,1.2c-0.5,0.9-1,4.3-1,4.3
		s-1,2.5-1.6,3c-0.6,0.5-1.4-0.9-1.4-0.9h-2.7c0,0,0,1.2,0,2.4c0,1.2,1,2,1.9,1.9s1.6-1.3,1.6-1.3l3,0.2l2.4,4.6l-0.2,4.6l3,0.3
		l6,5.4l1.2,1.9l-0.9,0.5h-2.9v2.9v1.9l-1.4,1.4l-7.2,3.3h-3.9l-5.6-0.6l-3.3-2.4l-1.8-1.3h-7.6l-3.2,1.8c0.1,0.2,0.3,0.4,0.3,0.6
		c0,0.2-0.1,0.5-0.3,0.8c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.3,0.1,0.9,0.3,1c0.2,0.1,0.6,0.1,0.8,0.1c0.3,0.1,0.8,0.5,1,0.7
		c0.2,0.4-0.1,1.3,0,1.6c0.2,0.5,1.1,1.3,1.4,1.7c0.3,0.4,0.9,1.4,1.4,1.7c0.4,0.3,1.3,0.5,1.7,0.8c0.6,0.5,1.3,1.9,1.6,2.6
		c0.2,0.4,0.2,1.2,0.4,1.5c0.2,0.2,0.8,0.1,1,0.3l1.5,1.6v4c0,0,1.8,1.2,1.9,2.6c0.1,1.4,0.6,3.3,1.1,4.5c0.5,1,0.5,2,0.5,2.8
		c0,0.1,0,0.2,0,0.4c0,0.9-1,1-1,1.8l2.2,1.9h5.2l1.1-1.9l0.2-0.4v-8.5l4.2-3.4v4.9l0.8,1.4l-2.4,2.6c0,0,0.3,2.7-0.1,3.1
		c-0.4,0.3,0.6,1.4,0.6,1.4l0.7,0.7l1.9-1.6l3.9,3.7l-1.5,0.9l-0.7,0.5l-1.4,1l3.5,3l1.3-1.7c0,0,1.4-1.4,2.5-1.6
		c1.1-0.2,4.7,0,5.4,0c0.4,0,1.9-0.6,3.4-1.4c1.2-0.6,2.4-1.4,3-1.9c1.1-1.2,2.1-2.1,2.1-2.1v-2.1l-1-1.7l1.3-3.2l1.1-2.9l4.7,0.2
		l3.6,4.2h1l0.2-4.1l2-1.4l2.4,1.5l0.9,0l0.7-3.3l1.3-1.1L281,260.3z"/>
	<path @click="openEmc" data-emc="central-georgia-emc" ref="central-georgia-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M261.9,197.3c-0.7-1.2-0.1-1.8,0.2-2.2c0.3-0.4-0.4-1.1-1-1.6c-0.4-0.4-2.3-2.8-3-3.8h-10.2l-1.4,2.2l-1.1,1.8
		H243c0,0-0.7,1.6-2.3,2.4c-1.6,0.8-3.4,0.9-3.4,0.9l-5.6,0l0,0l-1.7,1.8l-2.8,2.8c-0.1,0.1-0.2,0.2-0.3,0.2
		c-0.3,0.1-1.1-0.5-1.2-0.6c-0.1-0.1-0.3-0.9-0.5-1.1c-0.2-0.2-0.6-0.4-0.8-0.4c-0.2,0-0.6,0.3-0.6,0.5c-0.1,0.3,0.4,0.8,0.3,1
		c-0.1,0.3-0.7,0.5-1,0.6c-0.5,0.2-1.7,0.2-2.2,0.4c-0.2,0.1-0.7,0.4-0.9,0.6c-0.2,0.3-0.2,1-0.4,1.3c-0.2,0.3-0.8,0.8-1.1,0.9
		c-0.2,0.1-0.7,0.1-0.9,0.3c-0.2,0.1-0.4,0.5-0.6,0.6c-0.3,0.1-1-0.3-1.3-0.2c-0.4,0.1-0.9,0.8-1.2,1c-0.3,0.2-0.9,0.6-1.2,0.8
		c-0.2,0.1-0.6,0.3-0.7,0.5c-0.1,0.2-0.3,0.5-0.3,0.7c0,0.2,0.1,0.6,0.1,0.8c0,0.2-0.2,0.7-0.2,0.9c0,0.2,0.1,0.6,0.2,0.7
		c0.2,0.1,0.9-0.4,1.1-0.3c0.3,0.2,0.2,1,0,1.3c-0.2,0.3-1,0.5-1.3,0.7c-0.2,0.2-0.4,0.9-0.5,1.2c-0.1,0.2-0.1,0.8-0.3,1
		c-0.2,0.3-1.1,0.3-1.4,0.6c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.2-0.1,0.5,0,0.8h-6.8l-5.4-5.1
		c-0.2,0.2-0.7,0.1-1.1,0.2v2.5l-1,2.5H194l-1.2-0.6c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2-0.1
		c-0.2-0.1-0.5-0.2-0.6-0.2c-0.1,0-0.2,0-0.2,0c0.1,0,0.1,0,0.2,0v-1.6h-4l-4.3-3h-2.5l-1.4-1.7l-1.1-4.2l-1.1-1.7l-3.4,0.3l-1,2.6
		l-3-0.4l-3.9,1.6l-0.9,3.8l-1.7,0.7l-2.1-1.5h-2.6v-4.3h-4c-0.2,0.2-0.6,0.4-0.7,0.6c-0.2,0.3-0.2,1.3,0.1,1.5
		c0.1,0.1,0.5-0.1,0.6-0.1c0.2,0,0.6,0.2,0.7,0.4c0.1,0.2-0.2,0.7-0.2,0.9c0,0.2,0.3,0.5,0.3,0.7c0,0.3-0.3,0.8-0.5,1
		c-0.1,0.2-0.7,0.3-0.7,0.5c0,0.3,0.6,0.7,0.7,0.9c0.1,0.2,0.3,0.7,0.2,0.9c-0.1,0.2-0.6,0.2-0.7,0.4c-0.2,0.3-0.1,1.2-0.1,1.4
		c0,0.2,0.9,0.9,0.9,0.9s0.3,0.7,0.3,1c0,0.2-0.1,0.6-0.2,0.7c-0.1,0.2-0.6,0.2-0.7,0.4c-0.1,0.3,0.5,0.9,0.6,1.2
		c0,0.2-0.1,0.7-0.2,0.9c-0.1,0.1-0.4,0.3-0.5,0.4c-0.2,0.3,0,1.1-0.3,1.4c-0.2,0.2-0.7,0.1-0.9,0.3c-0.3,0.2-0.7,0.9-0.9,1.2
		c-0.3,0.3-0.9,0.8-1.1,1.1c-0.3,0.4-0.6,1.3-0.7,1.8c-0.1,0.4,0.4,1.3,0.1,1.5c-0.2,0.2-0.8,0-1.1,0c-0.4,0-0.9-0.1-1.4-0.2
		c-0.5,0-1.7-0.1-2.4-0.1c-0.4,0-1.3,0.1-1.8,0.2c0,0,0,0,0,0c0,0.2-0.2,0.6-0.4,0.8c-0.1,0.1-0.6,0-0.7,0.1
		c-0.2,0.2-0.2,0.8-0.1,1.1c0.1,0.2,0.6,0.4,0.7,0.7c0.1,0.2-0.1,0.6-0.1,0.7c0,0.2-0.3,0.5-0.2,0.7c0,0.1,0.1,0.1,0.1,0.2
		c0.2,0.2,0.7,0.5,0.8,0.7c0.1,0.3,0,1.1,0,1.5c0,0.4-0.2,1.2,0,1.5c0.1,0.2,0.5,0.4,0.8,0.5c0.1,0,0.1,0,0.2,0
		c0.2,0,0.7-0.4,0.8-0.6c0.2-0.3,0.1-1,0.4-1.2c0.3-0.2,1,0.2,1.3,0.1c0.3-0.1,0.8-0.3,1.1-0.5c0.2-0.1,0.7-0.5,0.7-0.5l5.2,0v0.9
		h2.1v1.3h7.1c0.9-0.6,1.9-1.2,2.4-1.5c0.9-0.4,3.1,1.5,3.1,1.5l2.2,3.6l10.7-2.2c0.1,0.1,0.3,0.3,0.4,0.4c0.2,0.2,0.4,0.4,0.5,0.6
		c0,0.2,0,0.3,0,0.5c0,1.1,0,2.2,0,3.3c1,0,2-0.1,3-0.1c0.3,0,0.5,0,0.7-0.1c0.3-0.1,0.6-0.3,0.8-0.4c0.9-0.6,1.9-1,2.9-1.4v0.2
		c1.5,2.1,3.1,4.3,3.5,6.9c0.1,0.8,0.2,1.6,0.2,2.4c0,1.2,0.1,2.4,0.1,3.6c0.1,0,0.2,0,0.3,0h2c0.6,1.2,4.4,7.1,4.8,7.8
		c0.4,0.8,0.6,1.7,0.7,2.6c0.3,1.3,5.4,8.9,5.8,9.7c0.3,0.6,0.8,1.3,1.3,1.6l2.9,0.8l8.2-6.1l1.6,1h2.4l-0.3-3.5h-1.3l0-2.2l0.1-3.1
		c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.4-0.1-1.2,0-1.5c0.1-0.2,0.7-0.2,0.7-0.5c0-0.3-0.9-0.4-1-0.7c-0.2-0.2-0.3-0.8-0.2-1.1
		c0.1-0.2,0.5-0.4,0.6-0.6c0.2-0.3,0.4-1,0.2-1.3c-0.1-0.2-0.8-0.1-1-0.3c-0.3-0.3-0.3-1.1-0.4-1.5c-0.3-0.7-1-2.1-1.6-2.6
		c-0.3-0.3-1.3-0.5-1.7-0.8c-0.4-0.3-1-1.3-1.4-1.7c-0.3-0.4-1.2-1.2-1.4-1.7c-0.1-0.4,0.1-1.3,0-1.6c-0.1-0.3-0.7-0.6-1-0.7
		c-0.2-0.1-0.7,0-0.8-0.1c-0.2-0.2-0.3-0.8-0.3-1c0-0.1,0.1-0.3,0.2-0.6c0.1-0.3,0.3-0.6,0.3-0.8c0-0.2-0.1-0.4-0.3-0.6l3.2-1.8h7.6
		l1.8,1.3l3.3,2.4l5.6,0.6h3.9l7.2-3.3l1.4-1.4v-1.9v-2.9h2.9l0.9-0.5l-1.2-1.9l-6-5.4l-3-0.3l0.2-4.6l-2.4-4.6l-3-0.2
		c0,0-0.6,1.2-1.6,1.3s-1.9-0.8-1.9-1.9c0-1.2,0-2.4,0-2.4h2.7c0,0,0.8,1.4,1.4,0.9c0.6-0.5,1.6-3,1.6-3s0.4-3.4,1-4.3
		c0.4-0.6,1.7-1,2.6-1.2l0,0l0,0l0.2,0.2l-0.2,3.6l4.2,4.5c0,0,1.9-1.4,2.3-2.3c0.3-0.9,0.3-3.2,1.2-3.9c0.9-0.7,1.9-1.2,1.9-1.2
		l4.2-1l2.8-1.1l2.2-2.1l3.5-0.3c0-0.1,0-0.2,0-0.3c0.1-0.1,0.4-0.1,0.5-0.3c0-0.1-0.1-0.4-0.2-0.5c-0.2-0.1-0.7-0.1-0.9-0.1
		c-0.1,0-0.4,0.1-0.5,0c-0.1-0.1-0.1-0.3,0-0.5c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.4-0.1,0.5-0.3c0-0.1,0-0.4-0.1-0.4
		c-0.1-0.1-0.5-0.2-0.7-0.3c-0.1,0-0.4,0-0.5,0.1c-0.1,0.1-0.2,0.4-0.3,0.5c-0.1,0.1-0.3,0.3-0.5,0.3c-0.1-0.1,0-0.5,0-0.7
		c0-0.1,0.2-0.4,0.1-0.5c-0.1-0.1-0.5-0.2-0.7-0.1c-0.1,0-0.2,0.3-0.3,0.3c-0.1,0.1-0.5,0.1-0.6,0c-0.1-0.1-0.2-0.5-0.1-0.7
		c0.1-0.2,0.5-0.4,0.7-0.5c0.2,0,0.7,0.1,0.9,0.2c0.1,0.1,0.3,0.3,0.4,0.3c0.2,0.1,0.7-0.1,0.9-0.1c0.2,0,0.4,0.4,0.6,0.3
		c0.2-0.1,0.1-0.5,0.2-0.7c0-0.1,0.1-0.3,0.2-0.4c0-0.2-0.1-0.7,0-0.9c0,0,0-0.1,0-0.2C263.1,198.6,262.4,198.1,261.9,197.3z"/>
		<path @click="openEmc" data-emc="rayle-emc" ref="rayle-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M371.3,172.9c0.2,0,0.6,0.2,0.8,0.2c0.1-0.1,0.3-0.3,0.4-0.5c0-0.2-0.2-0.5-0.3-0.6c-0.2-0.2-0.6-0.6-0.9-0.6
			c-0.2,0-0.6,0.4-0.9,0.4c-0.2,0-0.5,0-0.6-0.1c-0.1-0.1-0.4-0.4-0.4-0.5c0-0.2,0.5-0.2,0.6-0.3c0.1-0.2,0.1-0.5,0-0.7
			c-0.1-0.2-0.5-0.4-0.5-0.6c0-0.2,0.5-0.5,0.5-0.8c0-0.3-0.3-0.9-0.5-1.1c-0.2-0.2-0.6-0.6-0.9-0.7c-0.2-0.1-0.5,0.1-0.7,0
			c-0.3-0.1-0.8-0.6-1.1-0.6c-0.2,0-0.5,0.1-0.6,0.3c-0.1,0.1,0,0.5-0.1,0.6c-0.2,0.1-0.6,0.1-0.8,0c-0.1,0-0.3-0.3-0.5-0.4
			c-0.2-0.1-0.7,0.2-0.9,0.1c-0.1-0.1-0.4-0.3-0.4-0.5c0-0.2,0.4-0.7,0.6-0.7c0.3-0.1,0.9,0.5,1.2,0.4c0.1,0,0.1-0.4,0.2-0.5
			c0.2-0.2,1,0.2,1.2-0.1c0.1-0.2-0.1-0.8-0.3-1c-0.1-0.1-0.5-0.1-0.6-0.2c-0.1-0.1-0.1-0.7-0.3-0.7c-0.1,0-0.3,0.3-0.5,0.3
			c-0.2,0-0.7,0-0.9-0.2c-0.1-0.2,0.2-0.6,0.1-0.7c0-0.2-0.3-0.5-0.5-0.5c-0.2,0-0.5,0.4-0.7,0.4c-0.2,0-0.5-0.1-0.7-0.1
			c-0.2,0.1-0.4,0.5-0.6,0.7c-0.1,0.1-0.3,0.4-0.5,0.4c-0.2,0-0.1-0.6-0.3-0.6c-0.1-0.1-0.5,0.1-0.6,0.2c-0.1,0.1-0.2,0.5-0.4,0.5
			c-0.2,0-0.6-0.4-0.6-0.6c-0.1-0.2-0.1-0.7,0.1-0.9c0.2-0.2,0.8,0,1-0.2c0.2-0.1,0.2-0.6,0.4-0.7c0.3-0.1,1,0.2,1.3,0.2
			c0.3,0,0.9,0,1-0.2c0.1-0.2-0.2-0.5-0.4-0.6c-0.2-0.1-0.7,0-0.8-0.1c-0.2-0.2,0.1-0.9-0.1-1.1c-0.2-0.2-0.8-0.5-1-0.4
			c-0.2,0.1-0.2,0.6-0.3,0.8c-0.2,0.2-0.6,0.6-0.9,0.7c-0.2,0-0.6-0.3-0.9-0.3c-0.2,0-0.4,0.4-0.5,0.5c-0.2,0-0.6-0.1-0.7-0.2
			c-0.1,0-0.3-0.2-0.4-0.2c-0.2,0.1-0.5,0.6-0.6,0.7c-0.1,0.1-0.6,0.4-0.9,0.4c-0.3,0.1-0.9,0.1-1.2,0.3c-0.2,0.1-0.3,0.7-0.5,0.7
			c-0.2,0-0.7-0.3-0.8-0.6c0-0.2,0.4-0.6,0.6-0.7c0.2-0.1,0.7,0,0.9-0.1c0.2-0.1,0.4-0.7,0.3-0.9c-0.1-0.3-0.9-0.5-1.2-0.5
			c-0.2,0-0.5,0.2-0.6,0.2c-0.3,0-0.7-0.5-1-0.5c-0.4-0.1-1.2-0.2-1.5-0.1c-0.2,0.1-0.4,0.5-0.6,0.5c-0.3,0.1-1.2,0.1-1.3-0.2
			c-0.2-0.3,0.5-1.1,0.8-1.3c0.4-0.3,1.3-0.3,1.8-0.4c0.4-0.1,1.1-0.3,1.5-0.4c0.3,0,0.9-0.1,1.2,0c0.2,0.1,0.5,0.6,0.7,0.8
			c0.2,0.3,0.4,1.1,0.7,1.2c0.3,0,0.6-0.7,0.9-0.8c0.3-0.1,0.9,0.2,1.2,0.2c0.4,0.1,1.1,0.3,1.5,0.2c0.2-0.1,0.5-0.7,0.5-0.9
			c-0.1-0.3-0.6-0.6-0.9-0.7c-0.2-0.1-0.8,0.1-1,0c-0.3-0.1-0.4-0.8-0.6-1c-0.2-0.2-0.7-0.4-1-0.5c-0.2,0-0.7,0.1-0.9,0.1
			c-0.3,0-0.9,0.1-1.2,0c-0.2,0-0.7-0.2-0.8-0.4c-0.2-0.3-0.1-1.1-0.4-1.4c-0.1-0.2-0.5-0.3-0.7-0.4c-0.2,0-0.6,0-0.8,0
			c-0.3-0.1-0.7-0.5-1-0.5c-0.3,0-0.8,0.2-1,0.3c-0.2,0.1-0.3,0.5-0.4,0.6c-0.2,0.3-0.5,0.9-0.7,1c-0.2,0.1-0.7,0-0.9-0.1
			c-0.2-0.2-0.4-0.9-0.4-1.2c0-0.3,0.4-0.7,0.6-0.9c0.2-0.2,0.6-0.7,0.9-0.7c0.3-0.1,0.9,0.5,1.2,0.3c0.1-0.1,0.2-0.5,0.1-0.6
			c-0.1-0.3-0.4-0.8-0.7-1.1c-0.2-0.2-0.6-0.3-0.8-0.5c-0.2-0.1-0.7-0.4-0.9-0.6c-0.2-0.2-0.4-0.8-0.6-1c-0.2-0.2-0.8-0.2-1-0.4
			c-0.2-0.2,0.1-0.9-0.1-1.1c-0.1-0.1-0.5-0.1-0.6,0c-0.2,0.1-0.4,0.4-0.6,0.6c-0.2,0.2-0.6,0.5-0.6,0.7c0,0.2,0.4,0.6,0.4,0.9
			c0,0.2-0.2,0.6-0.3,0.8c-0.1,0.2-0.4,0.6-0.6,0.6c-0.2,0-0.8-0.3-0.8-0.4c0,0,0-0.7-0.2-0.8c-0.2-0.1-0.6,0.2-0.7,0.2
			c0-0.1-0.1,0.7-0.2,0.9c-0.2,0.2-0.7,0.1-1,0.1c-0.3,0.1-0.8,0.4-1,0.6c-0.1,0.2-0.2,0.7-0.2,1c0,0.3,0.2,0.8,0.3,1.1
			c0.1,0.4,0.7,1,0.8,1.4c0,0.4-0.3,1-0.5,1.4c-0.1,0.2-0.3,0.7-0.4,1c-0.1,0.2-0.5,0.6-0.7,0.8c-0.2,0.2-0.6,0.6-0.8,0.7
			c-0.1,0.1-0.3,0.1-0.5,0.2c-0.1,0-0.3,0.1-0.4,0c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.4,0-1.2,0.1-1.6c0.1-0.3,0.6-0.6,0.8-0.8
			c0.2-0.2,0.7-0.7,0.8-1c0.1-0.3,0.1-1,0-1.3c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.2-0.4-0.5-0.5-0.7c-0.1-0.5-0.2-1.4-0.1-1.9
			c0.1-0.3,0.6-0.9,0.9-1.1c0.3-0.2,1-0.4,1.2-0.7c0.1-0.2-0.1-0.8,0.1-1c0.1-0.1,0.3-0.2,0.5-0.1c0.2,0,0.5,0.4,0.7,0.5
			c0.1,0,0.5,0,0.6,0c0.2-0.1,0.2-0.6,0.3-0.7c0.1-0.1,0.5,0,0.6-0.1c0.2-0.1,0.4-0.7,0.6-0.9c0.2-0.2,0.8-0.3,0.8-0.5
			c0-0.2-0.2-0.5-0.3-0.6c-0.2-0.1-0.7,0-1,0.1c-0.2,0.1-0.5,0.4-0.7,0.4c-0.3,0-0.8-0.6-0.9-0.7c-0.1-0.1-0.6-0.8-0.9-0.9
			c-0.2-0.1-0.7,0.2-0.9,0.3c-0.2,0.2-0.4,0.9-0.4,1.2c0,0.2,0,0.8,0,0.8c-0.1,0-0.6-0.6-0.8-0.6c-0.2,0-0.7,0.6-0.8,0.6
			c-0.1,0-1-0.1-1.3-0.3c-0.1-0.1-0.3-0.5-0.2-0.7c0.1-0.2,0.8,0,1.1-0.1c0.3-0.1,0.9-0.4,0.9-0.6c0-0.1-0.2-0.3-0.4-0.3
			c-0.2-0.1-0.7-0.2-0.9-0.1c-0.2,0-0.5,0.3-0.7,0.4c-0.2,0-0.8,0.1-0.9,0c-0.1-0.2,0.1-0.6,0.3-0.7c0.3-0.3,1-0.4,1.4-0.4
			c0.3,0,0.9,0.3,1.2,0.2c0.2-0.1,0.5-0.3,0.6-0.5c0.1-0.2,0.1-0.7,0-0.8c-0.2-0.2-0.9-0.2-1.1-0.4c-0.2-0.2-0.5-0.9-0.7-0.9
			c-0.2,0-0.5,0.2-0.7,0.2c-0.3,0-1-0.1-1.3-0.2c-0.1,0-0.3-0.1-0.4-0.3c-0.3,0-0.7-0.1-0.9,0c-0.2,0-0.7,0.1-0.9,0.1c0,0,0,0-0.1,0
			c-0.2-0.2,0-1-0.2-1.3c-0.1-0.3-0.6-0.8-0.9-0.8c-0.3-0.1-1,0.2-1.3,0.4c-0.2,0.1-0.5,0.5-0.7,0.6c-0.4,0.1-1.3-0.1-1.7,0
			c-0.3,0.1-0.7,0.4-1,0.4c-0.3,0-0.9-0.2-1.2-0.3c-0.3-0.1-0.6-0.6-0.9-0.6c-0.4,0-0.9,0.5-1.3,0.6c-0.3,0.1-1,0.3-1.3,0.4
			c-0.3,0.2-0.8,0.8-1.2,0.9c-0.3,0-0.7-0.4-1-0.4c-0.3,0-0.8,0.5-1.2,0.5c0,0-0.1,0-0.1,0c-0.3-0.1-0.7-0.3-0.9-0.4
			c-0.2-0.1-0.6-0.5-0.8-0.6c-0.4-0.1-1.1,0.2-1.5,0c-0.2-0.1-0.4-0.7-0.6-0.9c-0.1-0.1-0.5-0.3-0.7-0.4c-0.2-0.1-0.7,0.1-0.9,0
			c-0.2-0.1-0.3-0.5-0.5-0.5c-0.2,0-0.5,0.4-0.6,0.6c-0.1,0.2,0.1,0.7-0.1,0.9c-0.1,0.1-0.5,0-0.6,0c-0.4,0-1.3,0-1.4-0.1
			c-0.1-0.1-0.3-1-0.6-1.2c-0.1-0.1-0.5-0.2-0.7-0.3c-0.2-0.2-0.5-0.7-0.7-0.8c-0.3-0.1-0.9,0-1.2,0c-0.2,0-0.7-0.2-0.9-0.4
			c-0.2-0.2-0.1-0.8-0.3-0.9c-0.2-0.2-0.9-0.1-1.1,0c-0.2,0.1-0.4,0.6-0.6,0.7c-0.1,0-0.3-0.1-0.5-0.2c-0.3-0.2-0.8-0.5-1-0.8
			c-0.1-0.1-0.5-0.4-0.5-0.6c0-0.2,0.6-0.4,0.6-0.7c0-0.3-0.5-0.8-0.7-1c-0.2-0.2-0.9-0.2-1.1-0.3c-0.2-0.1-0.6-0.5-0.8-0.6
			c-0.3-0.2-0.9-0.4-1.2-0.6c-0.2,0.3-0.5,0.6-0.6,0.7c-0.2,0.1-0.6,0.4-0.8,0.4c-0.2,0-0.7-0.2-0.9-0.2c-0.3,0.1-0.6,0.7-0.9,0.8
			c-0.3,0.1-0.9-0.3-1.1-0.2c-0.2,0-0.5,0.4-0.7,0.5c-0.2,0.1-0.8,0-1.1,0.2c-0.2,0.1-0.3,0.6-0.4,0.8c-0.2,0.2-0.6,0.7-0.8,0.7
			c-0.2,0-0.6-0.4-0.8-0.5c-0.3-0.1-0.8,0.1-1.1,0c-0.3-0.2-0.5-0.8-0.7-1c-0.2-0.2-0.8-0.6-1.1-0.6c-0.4,0-1,0.5-1.4,0.6
			c-0.3,0-0.9,0-1.1-0.2c-0.3-0.2-0.4-1-0.7-1.2c-0.2-0.2-0.7-0.5-0.9-0.5c-0.3-0.1-0.9,0-1.2,0.1c-0.4,0.1-1,0.7-1.4,0.8
			c-0.3,0.1-0.9,0-1.2,0.1c-0.2,0.1-0.6,0.6-0.8,0.7c-0.3,0.2-0.9,0.6-1.2,0.9c-0.2,0.3-0.5,0.9-0.7,1.2c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.5,0.9-1.5,1.5-2.4,2c-0.4,0.3-0.9,0.5-1.4,0.6c-0.3,0.1-0.6,0.1-0.9,0.1c-1,0.1-1.9,0.4-2.9,0.4c0,0,0,0,0,0
			c0,0.2,0,0.7,0.1,0.9c0,0.2,0.2,0.6,0.3,0.8c0.1,0.3,0.2,0.8,0.1,1.1c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0c-0.1,0.3-0.7,0.5-0.9,0.7
			c-0.2,0.2-0.7,0.7-0.7,1c0,0.2,0.5,0.5,0.7,0.7c0.1,0.1,0.2,0.2,0.4,0.4c-0.3,2.3-0.3,4.7-0.5,7.1c-0.1,0.6-0.1,1.3-0.4,1.9
			c-0.2,0.5-0.6,1-0.9,1.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.7,1-1.2,1.5-1.9,2.4c0.2,0.3,0.6,0.7,0.9,1.2c0.5,0.7,1.1,1.5,1.5,2
			c0.2,0.2,0.4,0.5,0.5,0.7c0.1,0.4,0,0.9-0.2,1.3c0,0.3-0.2,0.6-0.2,0.8c0,0.2-0.3,0.7-0.2,0.9c0.1,0.3,0.4,0.8,0.7,1.1l0,12.2H257
			c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.1,0.6,0.2,0.7,0.3c0.2,0.1,0.6,0.3,0.7,0.5c0.1,0.3-0.1,1,0,1.3c0.1,0.3,0.5,0.9,0.6,1.2
			c0.1,0.2,0.2,0.7,0.3,0.9c0.1,0.1,0.4,0.3,0.5,0.5c0.2,0.3,0.1,1,0.2,1.3c0.1,0.2,0.3,0.7,0.5,0.9c0,0.1,0.1,0.2,0.2,0.3l-2.7,2.2
			h0c0.7,1,2.6,3.4,3,3.8c0.6,0.5,1.3,1.2,1,1.6c-0.3,0.4-0.9,1-0.2,2.2c0.5,0.8,1.2,1.3,1.6,1.6c0-0.1,0.1-0.2,0.2-0.3
			c0.1-0.1,0.5,0,0.6,0.1c0.1,0.1,0.1,0.6,0.1,0.8c0,0.3-0.4,0.8-0.4,1c0,0.2,0.2,0.5,0.3,0.7c0,0.2-0.2,0.7-0.1,1
			c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0,0.2,0,0.3,0c0.3-0.1,0.8-0.2,1.1-0.3c0.2-0.1,0.6-0.4,0.7-0.6c0.1-0.1,0.2-0.1,0.3-0.2
			c-0.1-0.1-0.3-0.3-0.3-0.4c0,0,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.3-0.4-0.4
			c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.7,0.2-0.7c0.3-0.1,0.8,0.3,1,0.6c0,0.1,0,0.2,0,0.2
			c0.1,0.1,0.4,0.2,0.4,0.4c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.3,0.1,0.4,0.2
			c0.2,0.2,0.3,0.7,0.4,1c0.1,0.2,0.6,0.4,0.7,0.6c0.1,0.3-0.4,0.8-0.4,1.1c0,0.3,0.1,0.8,0.2,1c0.1,0.2,0.6,0.5,0.8,0.6
			c0.2,0.1,1,0.2,1.1,0.4c0.1,0.2-0.4,0.7-0.5,0.9c-0.1,0.2-0.2,0.7-0.2,0.9c0,0.2,0.3,0.5,0.4,0.6c0.2,0.2,0.7,0.3,0.8,0.5
			c0.1,0.2,0.1,0.6,0.2,0.8c0,0.2,0.2,0.6,0.2,0.8c0,0.2-0.4,0.5-0.4,0.7c0,0.2,0.2,0.5,0.4,0.6c0.2,0.1,0.5-0.1,0.7-0.1
			c0.2,0,0.5,0.2,0.7,0.2c0.2,0,0.5-0.6,0.7-0.6c0.2,0,0.2,0.5,0.4,0.6c0.1,0.2,0.5,0.4,0.6,0.6c0.1,0.2-0.1,0.7-0.2,0.9
			c-0.1,0.2-0.6,0.4-0.7,0.6c-0.1,0.1-0.4,0.5-0.3,0.7c0.1,0.1,0.6-0.3,0.8-0.3c0.2-0.1,0.7-0.1,0.9-0.2c0.2-0.1,0.3-0.6,0.4-0.7
			c0.1-0.1,0.4-0.5,0.5-0.5c0.1,0,0.2,0.3,0.4,0.3c0.1,0,0.3-0.2,0.3-0.4c0-0.2-0.1-0.5-0.2-0.6c-0.1-0.1-0.3-0.3-0.4-0.4
			c-0.1-0.2-0.4-0.7-0.4-0.9c0-0.2,0.5-0.4,0.4-0.6c0-0.2-0.5-0.3-0.6-0.4c-0.2-0.1-0.5-0.4-0.5-0.6c0-0.2,0.2-0.4,0.4-0.5
			c0.2,0,0.4,0.4,0.6,0.4c0.1,0,0.2-0.3,0.2-0.4c0-0.2-0.2-0.6-0.2-0.9c-0.1-0.2,0-0.7-0.2-0.9c-0.1-0.1-0.5-0.2-0.7-0.3
			c-0.2-0.1-0.6-0.1-0.8-0.2c-0.1,0-0.2-0.5-0.3-0.6c-0.2-0.1-0.6-0.1-0.6-0.4c0-0.2,0.2-0.5,0.3-0.6c0.1-0.1,0.5-0.1,0.7,0
			c0.2,0.1,0.5,0.7,0.7,0.6c0.2-0.1,0.1-0.7,0.1-1c0-0.2-0.2-0.6-0.2-0.8c0-0.1-0.1-0.5,0-0.6c0.1-0.1,0.4,0.2,0.5,0.3
			c0.2,0.1,0.4,0.5,0.6,0.5c0.2,0,0.7-0.3,0.8-0.4c0.2-0.2,0.7-0.7,0.8-0.8c0.1,0,0.4-0.6,0.7-0.7c0.2,0,0.6,0.1,0.7,0.3
			c0.1,0.3,0,1-0.2,1.3c-0.1,0.2-0.9,0.1-1.1,0.2c-0.3,0.1-0.8,0.5-1,0.7c-0.1,0.1-0.1,0.6-0.1,0.7c0,0.1-0.2,0.8-0.1,1.1
			c0,0.2,0.3,0.6,0.4,0.8c0.1,0.1,0.2,0.3,0.4,0.4c0.3,0,0.8-0.4,1.1-0.5c0.2,0,0.6-0.2,0.8,0c0.1,0.1-0.2,0.4-0.3,0.6
			c-0.1,0.2-0.4,0.7-0.6,0.8c-0.1,0.1-0.6,0.2-0.7,0.3c-0.2,0.2-0.5,0.8-0.6,1.1c-0.1,0.3-0.2,1-0.1,1.3c0.1,0.2,0.3,0.7,0.5,0.6
			c0.1,0,0-0.4,0.1-0.5c0.1-0.3,0.2-0.9,0.4-1.1c0.1-0.2,0.5-0.4,0.7-0.4c0.2,0,0.6,0,0.7,0.2c0.1,0.1,0.1,0.5,0,0.6
			c-0.1,0.2-0.7,0.1-0.8,0.3c-0.1,0.2-0.1,0.6-0.1,0.8c0,0.1,0.2,0.4,0.3,0.5c0.1,0.2,0.2,0.5,0.2,0.7c0,0.2-0.5,0.6-0.5,0.8
			c0,0.2,0.3,0.6,0.5,0.6c0.2,0.1,0.8,0.1,1-0.1c0.2-0.1,0.4-0.6,0.4-0.8c0-0.2-0.2-0.7-0.1-0.9c0.1-0.2,0.5-0.4,0.6-0.4
			c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1-0.1,0.5-0.1,0.6c0,0.1,0.2,0.3,0.4,0.3c0.1,0,0.3-0.3,0.4-0.3c0.1,0,0.4-0.1,0.5,0
			c0.1,0.1,0,0.2,0,0.3c-0.1,0.2-0.5,0.5-0.7,0.7c-0.1,0.1-0.4,0.4-0.5,0.5c-0.1,0.1-0.5,0.1-0.6,0.3c-0.1,0.2,0.1,0.6,0,0.7
			c-0.1,0.2-0.6,0.5-0.8,0.6c-0.1,0.1-0.5,0-0.6,0.2c-0.1,0.2,0.5,0.5,0.6,0.7c0,0.1-0.1,0.4,0,0.5c0,0.1,0.1,0.3,0.2,0.4
			c0.2,0.3,0.5,0.6,0.6,0.8c0.2,0.2,0.9,0.6,1,0.8c0.1,0.2,0.1,0.5,0.1,0.8h2.9c0,0,6.2-2.6,7.6-3.6c1.4-1,3-1.5,3.3-2.5
			c0.3-1,0.7-2,1.3-2.1c0.6-0.1,2.2,1.9,2.9,3.3c0.7,1.4,1.7,4.3,1.7,4.3s-0.9,1.1-1.7,1.5c-0.8,0.4-2.7,1.9-2.7,1.9
			s-0.6,1.1,0.2,1.9c0.8,0.8,2.7,2.5,2.7,2.5l1.5-1.7l2.2-0.7c0,0,1.4,1.6,2.3,1.6c0.9,0,2.1-0.8,2.7-1.3c0.5-0.5-0.8-1.9-1.4-2.7
			c-0.6-0.7-1.2-2.3-1.2-2.3s0.8-1.1,1.5-1.2c0.6-0.1,1-0.2,0.9-1.2s0.3-3.4,0.3-3.4s2.6-0.2,4.1-0.8c0,0,0-0.1,0-0.1
			c0-0.4,0.7-0.9,0.7-1.3c0.1-0.6-0.4-1.7-0.6-2.2c-0.1-0.3-0.2-1-0.5-1.2c-0.4-0.3-1.5-0.2-1.9-0.6c-0.3-0.3-0.3-1.1-0.4-1.4
			c-0.1-0.4-0.3-1.3-0.4-1.8c0.1-0.4,0.2-0.8,0.3-1c0.2-0.3,0.9-0.8,1.2-1.1c0.2-0.3,0.7-0.8,0.9-1.1c0.2-0.3,0.8-1,0.9-1.4
			c0.1-0.4-0.2-1.2,0-1.5c0.2-0.3,0.8-0.6,1.2-0.7c1.4-0.9,4.2-2.8,5.2-3.2c1-0.4,3.3-1,4.3-1.2c0.2-0.3,0.5-0.6,0.6-0.8
			c0.2-0.3,0.6-0.9,0.7-1.3c0.1-0.4-0.1-1.2,0.1-1.6c0.1-0.2,0.4-0.6,0.7-0.7c0.3-0.1,0.9,0.2,1.2,0.1c0.3-0.1,0.6-0.6,0.9-1
			c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3-0.3,0.4-0.4c0.3-0.1,0.8-0.1,1.1,0c0.1,0,0.4,0.3,0.5,0.2c0.2-0.1,0.3-0.5,0.4-0.7
			c0-0.1,0-0.4,0.1-0.5c0.1,0,0.2,0,0.3,0.2c0.2,0.2,0.4,0.5,0.6,0.4c0.3-0.1,0-0.9,0.2-1.1c0.3-0.2,1,0.4,1.3,0.5
			c0.3,0.1,0.9,0.2,1.2,0c0,0,0.1-0.1,0.1-0.2c-0.2-0.2-0.5-0.7-0.5-0.7c0-0.1,0.6-0.9,0.7-1.3c0.1-0.2,0-0.6,0.1-0.7
			c0.1-0.2,0.7-0.4,0.9-0.5c0.3-0.1,0.9-0.2,1.1-0.5c0.1-0.2,0-0.6,0-0.8c0.1-0.2,0.3-0.6,0.5-0.8c0.1-0.1,0.4-0.2,0.6-0.2
			c0.2,0,0.7,0.2,0.9,0.3c0.1,0.1,0,0.4,0.1,0.5c0.1,0.1,0.6-0.1,0.7,0c0.2,0.1,0.3,0.5,0.5,0.5c0.2,0,0.5-0.1,0.6-0.2
			c0.1-0.1-0.1-0.5,0-0.6c0-0.1,0.2-0.1,0.3-0.1c0.1,0.1,0.1,0.5,0.2,0.6c0.1,0.1,0.4,0.2,0.6,0.2c0.2,0,0.5,0,0.6-0.1
			c0.2-0.1,0.2-0.6,0.4-0.8c0.1-0.2,0.5-0.4,0.7-0.4c0.2-0.1,0.7,0.1,0.9,0c0.2-0.1,0.4-0.5,0.6-0.5c0.2-0.1,0.7-0.1,0.9,0
			c0.5,0.2,1,1.2,1.4,1.4c0.2,0.1,0.7,0.2,0.9,0.2c0,0,0,0,0,0c0.2,0.1,0.6,0.2,0.8,0.3c0.2,0.1,0.6,0.3,0.8,0.3
			c0.3,0,0.7-0.3,0.9-0.5c0.2-0.2,0.2-0.6,0.4-0.8c0.2-0.1,0.6,0,0.8-0.1c0.3-0.1,0.9-0.1,1.1-0.3c0.2-0.2,0.2-0.7,0.1-0.9
			c-0.1-0.1-0.5-0.2-0.5-0.4c-0.1-0.2,0-0.6,0.1-0.7c0.1-0.1,0.5-0.1,0.6,0c0.3,0.1,0.7,0.5,1,0.5c0.2,0,0.6-0.4,0.7-0.5
			c0.2-0.2,0.6-0.6,0.8-0.7c0.3-0.1,1,0.1,1.4,0c0.2-0.1,0.5-0.5,0.7-0.5c0.3-0.1,1.2,0.3,1.4,0c0.1-0.2-0.2-0.6-0.4-0.7
			c-0.2-0.2-0.6-0.6-0.8-0.8c-0.2-0.1-0.7-0.1-0.9-0.1c-0.2-0.1-0.6-0.2-0.7-0.4c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.3-0.7,0.5-0.8
			c0.1-0.1,0.5-0.1,0.6-0.1c0.2,0.1,0.5,0.4,0.7,0.6c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.1,0.8,0,1.1,0.1c0.2,0.1,0.6,0.3,0.7,0.3
			c0.1,0,0.4-0.5,0.6-0.5c0.3-0.1,1,0.4,1.4,0.2c0.2-0.1,0.3-0.5,0.4-0.6c0.3-0.1,0.9,0.2,1.1,0c0.2-0.2,0-0.7-0.2-0.9
			c-0.2-0.3-1-0.4-1.2-0.6c-0.2-0.1-0.6-0.5-0.6-0.7c0-0.2,0.4-0.6,0.6-0.8c0.1-0.1,0.3-0.5,0.5-0.5c0.2,0,0.6,0.5,0.6,0.6
			c0,0.1,0.3,0.8,0.4,0.8c0.1,0,0.7,0.7,1.1,0.7c0.3,0,0.8-0.4,1.1-0.3c0.3,0,0.8,0.4,0.9,0.7c0.1,0.2,0,0.7,0.1,0.9
			c0.1,0.2,0.4,0.7,0.7,0.7c0.1,0,0.4-0.2,0.5-0.3c0.1-0.1,0-0.5,0.2-0.6c0.1-0.1,0.5,0.1,0.6,0.2c0.2,0.2,0,0.8,0.1,1.1
			c0,0.1,0.2,0.4,0.3,0.4c0.3,0.1,1.1-0.3,1.4-0.4c0.2-0.1,0.8-0.2,0.9-0.5c0.2-0.3,0.3-1.2,0.1-1.4c-0.2-0.1-0.7,0.2-0.9,0.1
			c-0.2,0-0.6-0.4-0.6-0.5c0-0.1,0-0.6,0.1-0.7C370.8,173,371.1,172.9,371.3,172.9z"/>
		<path @click="openEmc" data-emc="walton-emc" ref="walton-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M265.5,147.5c-0.6-0.1-1.2-0.1-1.7,0.2c-0.3,0.2-0.6,0.5-0.8,0.8c-0.9,1.1-1.9,2.3-2.8,3.4
			c-0.7-0.6-1.3-1.1-2-1.7c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.3-0.3-0.5-0.5c-0.9-0.6-1.7-1.2-2.6-1.9
			c-0.6-0.5-1.3-0.9-1.9-1.4c-0.3-0.2-0.5-0.4-0.8-0.5c-0.3-0.1-0.5-0.1-0.8-0.2c-1.9-0.3-3.8-0.8-5.5-1.6c-0.4-0.2-0.7-0.3-1.1-0.4
			c-0.8-0.2-2-0.1-3-0.1l-0.1,0l-6.2,4.2c-0.6-0.2-1.2-0.3-1.8-0.5c-0.9-0.3-1.9-0.5-2.7-1c-0.6-0.4-1.2-0.9-1.9-1.1
			c-0.4-0.1-0.9-0.1-1.3-0.2c-0.4-0.1-0.9-0.2-1.1-0.6c-0.2,1.4-0.3,2.9-0.5,4.3c0.5,0.2,1,0.5,1.1,0.9c0,0.1,0,0.1,0,0.2
			c0,0-0.1,0-0.1,0.1c-0.2,0.1-0.5,0.4-0.7,0.4c-0.2,0.1-0.7-0.1-0.9,0c-0.2,0.1-0.5,0.6-0.7,0.7c-0.2,0.1-0.6,0-0.8,0.1
			c-0.3,0.1-0.7,0.5-0.9,0.6c-0.4,0.1-1.1,0.2-1.5,0.1c-0.2,0-0.7-0.3-0.9-0.3c-0.3,0-0.6,0.5-0.9,0.6c-0.2,0.1-0.8,0.2-1,0.1
			c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.4-0.7,0.3-0.9c-0.2-0.4-1.2-0.7-1.7-0.9c-0.3-0.2-0.9-0.5-1.2-0.7c-0.3-0.2-1-0.6-1.3-1
			c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.3,0.2-1.1,0.1-1.4c-0.1-0.2-0.6-0.4-0.7-0.5c-0.2-0.3-0.2-0.9-0.4-1.2c-0.2-0.3-1.1-0.7-1.4-1
			c0,0-0.1-0.1-0.1-0.2c-1.1,0.7-3,2.1-3.5,2.9c-0.7,1.2-2.5,0.2-3.1,1c-0.6,0.8,0.6,1.7,0.3,2.7s-1.7,0.6-2.6,0
			c-0.9-0.6-2.8-0.8-3.5-1.6c-0.8-0.8-2.5-1.1-4.2-1.1c-1.7,0.1-4,2.7-4,2.7h-2.6l-3,1.5l-8.3,5.3c0,0,0,0,0,0l2.3,4.6l3.3,6h1.7
			l0.3-1.1l2.2,0l0.6,1.9l2.5,3l1,1.3l2,3.5l2.4,2l1.7,0.5l-0.8-1.2v-2.5l0.8-1.1c0,0,1.8,0.3,2.8,0.1c1-0.2-0.4-2.7,0.3-2.9
			c0.7-0.1,1.6,1,1.6,1l1.1,0.3c0.1,0.3,0.3,0.7,0.3,0.9c0,0.2,0,0.7-0.1,1.1l0.8,0.8l3.4-1.4l1.8,1.3l1.3,3.1l3.2,0.3l5.1,3.8
			c0,0,2.3,0,3,0.3c0.6,0.2,1.3,1.3,1.8,1.6c0.3,0.2,1,0.7,1,0.6l0.3,3.3l-1.2,0.2l0.3,1.7l1.7,1.8l1.5,0.9l1.6,2.1l3,2.7l5.6,0
			c0,0,1.8-0.1,3.4-0.9c1.6-0.8,2.3-2.4,2.3-2.4h2.4l1.1-1.8l1.4-2.2h10.3l2.7-2.2c-0.1-0.1-0.1-0.2-0.2-0.3
			c-0.1-0.2-0.4-0.7-0.5-0.9c-0.1-0.3,0-1-0.2-1.3c-0.1-0.2-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.7-0.3-0.9c-0.1-0.3-0.6-0.9-0.6-1.2
			c-0.1-0.3,0.2-1,0-1.3c-0.1-0.2-0.5-0.4-0.7-0.5c-0.2-0.1-0.6-0.2-0.7-0.3c-0.2-0.1-0.3-0.5-0.3-0.4h10.6l0-12.2
			c-0.3-0.3-0.6-0.8-0.7-1.1c0-0.2,0.2-0.7,0.2-0.9c0-0.2,0.2-0.5,0.2-0.8c0.2-0.4,0.4-0.9,0.2-1.3c-0.1-0.3-0.3-0.5-0.5-0.7
			c-0.4-0.5-1-1.3-1.5-2c-0.3-0.4-0.6-0.8-0.9-1.2c0.7-0.9,1.3-1.5,1.9-2.4c0.1-0.1,0.1-0.2,0.2-0.2c0.3-0.5,0.7-1,0.9-1.5
			c0.2-0.6,0.3-1.2,0.4-1.9c0.2-1.9,0.2-3.9,0.4-5.9C267.5,147.7,266.5,147.6,265.5,147.5z"/>
			<path @click="openEmc" data-emc="washington-emc" ref="washington-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M355.4,313.5c-0.6-0.5-1.6-2.9-2-4.9c-0.4-2-3.1-2.1-3.5-2.7c-0.1-0.2-0.5-0.8-0.9-1.5
				c-0.7-1.2-1.5-2.6-1.5-2.6c-0.2-0.3,0.2-1,0.1-1.3c-0.1-0.2-0.5-0.6-0.6-0.8c-0.1-0.3-0.1-0.8,0-1.1c0-0.1,0.1-0.1,0.1-0.2
				c0,0,0.1-0.1,0.1-0.1l-3-5.2l-2.4-1.3l0.1-3l-2.2-3.6c0,0,3.7-0.1,4.4-0.8c0.4-0.4,0.7-0.9,0.9-1.4c0-0.1-0.1-0.2-0.1-0.3
				c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.3-0.3-0.9-0.4-1.2c0-0.1,0-0.1,0-0.2c0.3-0.2,0.5-0.4,0.8-0.7c0.7-0.9,1.9-1.4,2.5-2.3
				c0.6-0.9,0.1-3.1,0-4.4c-0.1-1.3-1.9-4.1-1.9-4.3c0-0.1-0.7-0.2-1.5-0.4c-0.8-0.2-1.7-0.4-2.2-0.6c-0.9-0.5-3.7,0-4.2-0.5
				c-0.5-0.5-0.3-1.7,0-2.7c0.3-1,0.4-2.3,1.1-3.2c0.6-0.8,0.6-2.1,0.7-3.4c-0.2-0.1-0.4-0.3-0.5-0.4c-0.2-0.2-0.4-0.7-0.6-0.9
				c-0.2-0.2-0.8-0.4-1.1-0.4c-0.2,0-0.7,0.2-0.9,0c-0.2-0.2-0.2-0.8-0.4-1c-0.2-0.4-1.2-0.7-1.3-1.1c-0.1-0.2,0.2-0.6,0.1-0.8
				c-0.1-0.3-0.9-0.4-1.1-0.7c-0.2-0.3-0.1-1-0.3-1.3c-0.2-0.3-0.8-0.5-1-0.7c-0.2-0.2-0.4-0.8-0.4-1.1c0-0.3,0.3-0.7,0.4-1
				c0-0.2,0.1-0.8-0.1-0.9c-0.3-0.3-1.5-0.1-1.7-0.5c-0.2-0.2,0-0.9,0-1.2c0-0.2,0-0.5,0-0.8c0-0.2,0-0.3-0.1-0.4
				c-0.2-0.3-0.8-0.8-1.2-0.9c-0.4-0.1-1.1,0.4-1.4,0.2c-0.2-0.2-0.1-0.9-0.3-1.1c-0.2-0.3-1-0.6-1.4-0.8c-0.2-0.1-0.7-0.1-0.9-0.3
				c-0.2-0.2-0.2-0.6-0.2-0.8c-0.1-0.3-0.2-0.9-0.4-1.1c-0.3-0.3-1.2-0.2-1.5-0.3c-0.2-0.1-0.6-0.4-0.7-0.6c-0.1-0.1,0-0.4,0.1-0.6
				c0,0,0,0,0,0c0.9-0.1,2.1-0.3,2.8-0.3c1.1,0,2.4-0.8,3.2-2.1c0.8-1.3,1.9-3.5,1.9-3.5s-2.9-3.3-3.2-3.9c-0.1-0.3-0.2-1-0.2-1.6
				c0,0,1.8-2.3,2.1-2.8c0.5-0.8,0.5-4.7,1.3-7.7c0.8-2.9,0.2-5.9,0.2-5.9c-0.6-1.4-3.1-3.1-5.1-4.4c-2-1.3-4.6-2.8-5.6-3.8
				c-1-0.9-2.6-1.8-2.7-2.8c-0.1-0.6-1.4-2.1-2.3-3.2c-0.2,0.1-0.4,0.3-0.6,0.4c-0.4,0.1-1,0.4-1.2,0.7c-0.2,0.3,0.1,1.1,0,1.5
				c-0.1,0.4-0.7,1-0.9,1.4c-0.2,0.3-0.7,0.9-0.9,1.1c-0.3,0.3-1,0.7-1.2,1.1c-0.1,0.2-0.2,0.7-0.3,1c0.2,0.5,0.3,1.3,0.4,1.8
				c0.1,0.4,0.1,1.2,0.4,1.4c0.3,0.4,1.5,0.3,1.9,0.6c0.2,0.2,0.4,0.9,0.5,1.2c0.2,0.6,0.6,1.7,0.6,2.2c-0.1,0.4-0.8,0.9-0.7,1.3
				c0,0,0,0.1,0,0.1l0,0c-1.6,0.7-4.1,0.8-4.1,0.8s-0.4,2.4-0.3,3.4s-0.3,1.1-0.9,1.2c-0.6,0.1-1.5,1.2-1.5,1.2s0.6,1.6,1.2,2.3
				c0.6,0.7,1.9,2.1,1.4,2.7c-0.5,0.5-1.8,1.3-2.7,1.3c-0.9,0-2.3-1.6-2.3-1.6l-2.2,0.7l-1.5,1.7c0,0-1.8-1.7-2.7-2.5
				c-0.8-0.8-0.2-1.9-0.2-1.9s1.9-1.5,2.7-1.9c0.8-0.4,1.7-1.5,1.7-1.5s-1-2.9-1.7-4.3c-0.7-1.4-2.3-3.4-2.9-3.3
				c-0.6,0.1-1,1.1-1.3,2.1c-0.3,1-1.9,1.5-3.3,2.5c-1.4,1-7.6,3.6-7.6,3.6h-2.9c0,0.2,0,0.3,0,0.4c0,0.3,0,0.9,0.1,1.2
				c0.1,0.2,0.5,0.6,0.6,0.8c0.1,0.3-0.1,0.9-0.2,1.1c-0.1,0.1-0.6,0.2-0.7,0.2c-0.2,0.1-0.9,0.4-0.9,0.6c0,0.2,0.4,0.5,0.6,0.6
				c0.1,0.1,0.6,0.2,0.6,0.4c0.1,0.2-0.1,0.6-0.2,0.7c-0.2,0.1-0.8,0-1.1-0.1c-0.1,0-0.4-0.2-0.5-0.2c-0.1-0.1-0.4-0.2-0.6-0.2
				c-0.1,0-0.2,0.3-0.4,0.3c-0.3,0.1-1,0-1.3,0.2c-0.2,0.1-0.4,0.5-0.6,0.6c-0.3,0.1-0.9-0.3-1.2-0.1c-0.2,0.1-0.3,0.5-0.4,0.7
				c-0.1,0.1-0.2,0.4-0.4,0.5c-0.1,0-0.4-0.2-0.6-0.2c-0.1,0-0.4,0-0.4,0.1c-0.1,0.2,0.4,0.6,0.4,0.8c0,0.2-0.5,0.5-0.5,0.8
				c-0.1,0.2,0.2,0.7,0.1,0.9c-0.1,0.2-0.7,0.5-1,0.5c-0.2-0.1-0.2-0.5-0.3-0.7c-0.1-0.1-0.5-0.2-0.6-0.2c-0.2,0-0.6,0.4-0.7,0.6
				c-0.2,0.3-0.3,1.1-0.3,1.4c0,0.2,0.4,0.6,0.5,0.8c0.1,0.4,0.3,1.3,0.1,1.6c-0.1,0.2-0.6,0.3-0.7,0.5c-0.1,0.2,0,0.6,0,0.7
				c0.1,0.1,0.5,0.2,0.5,0.4c0,0.2-0.1,0.6-0.3,0.7c-0.2,0.1-0.8-0.3-1-0.1c-0.1,0.1,0,0.4,0,0.6c-0.1,0.2-0.6,0.3-0.8,0.5
				c-0.2,0.1-0.8,0.3-0.9,0.6c-0.1,0.1-0.1,0.5,0,0.6c0.1,0.1,0.6,0.1,0.7,0.1c0.3,0,0.7-0.3,1-0.3c0.1,0,0.2,0.1,0.3,0.1
				c0.2,0,0.6-0.1,0.8,0c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.3,0,0.9,0.1,1.2c0.1,0.2,0.2,0.6,0.4,0.7c0.2,0.1,0.8,0.1,1,0
				c0.2-0.1,0.5-0.7,0.6-0.9c0-0.2-0.1-0.6-0.2-0.8c-0.1-0.2-0.3-0.5-0.3-0.7c0-0.2,0-0.7,0.1-0.9c0.1-0.2,0.6-0.5,0.7-0.7
				c0-0.2-0.3-0.4-0.4-0.6c-0.1-0.3-0.2-1.1,0-1.4c0.1-0.2,0.7-0.2,0.8-0.1c0.2,0.1,0.5,0.6,0.5,0.8c0.1,0.3,0.1,0.8,0.1,1.1
				c0,0.3-0.2,0.9-0.1,1.2c0,0.3,0.6,1,0.5,1.3c0,0.2-0.6,0.7-0.6,0.7c0,0.2,0.1,0.5,0.1,0.7c-0.1,0.2-0.4,0.5-0.5,0.7
				c-0.1,0.1-0.3,0.3-0.4,0.5c0,0.2,0.2,0.4,0.3,0.5c0.2,0.1,0.6-0.1,0.7,0c0.2,0.1,0.2,0.5,0.4,0.6c0.2,0,0.5-0.3,0.6-0.4
				c0.1-0.2,0.4-0.6,0.4-0.9c0-0.2-0.4-0.5-0.3-0.6c0.1-0.2,0.5-0.2,0.7-0.2c0,0,0,0,0,0c0.2,0.1,0.5,0.4,0.6,0.6
				c0.1,0.2-0.2,0.8-0.1,1c0.1,0.1,0.5,0.1,0.6,0.2c0.1,0.1,0,0.4-0.1,0.5c-0.2,0.1-0.7-0.2-0.9-0.1c-0.1,0.1-0.2,0.4-0.3,0.5
				c-0.1,0.2-0.5,0.5-0.6,0.7c-0.1,0.2,0.1,0.7,0,0.8c-0.2,0.1-0.5,0.1-0.9,0l0.6,1.3l-0.3,1.1l-2.1,2.2l0.6,1l2.7,3.8l0.2,1.4
				l3.8,5.1c0.2-0.1,0.3-0.7,0.5-0.7c0.3-0.1,0.9,0.2,1.2,0.4c0.3,0.2,0.7,0.8,0.8,1.1c0.1,0.4-0.2,1.2-0.1,1.6
				c0.1,0.3,0.8,0.9,0.8,1c0,0.1,1.1,0.4,1.4,0.6c0.3,0.2,0.4,1,0.6,1.3c0.2,0.3,1,0.5,1.2,0.8c0.3,0.2,0.7,0.8,0.8,1.1
				c0,0.1,0.1,0.2,0.1,0.3c0.3,0.6,0.6,1.6,0.9,2c0.2,0.3,1,0.7,1.2,1c0.2,0.4,0.4,1.3,0.5,1.7c0.1,0.4,0,1.2,0.1,1.6
				c0.2,0.4,0.9,1,1.1,1.4c0.1,0.4,0,1.2,0.1,1.6c0.1,0.4,0.6,1,1,1.2c0.2,0.1,0.7,0,1,0c0.3,0,0.9,0.2,1.2,0.2
				c0.2,0,0.6-0.2,0.7-0.1c0.3,0.1,0.6,0.7,0.8,0.9c0.2,0.2,0.7,0.4,0.9,0.6c0.3,0.3,0.6,1,0.7,1.4c0.1,0.5,0,1.5,0.2,1.9
				c0.2,0.4,1.2,0.9,1.4,1.3c0.2,0.4,0.1,1.4,0,1.8c-0.1,0.6-0.6,1.6-0.6,2.2c0,0.5,0.4,1.3,0.7,1.7c0.1,0.1,0.3,0.2,0.5,0.4
				c0.2,0.1,0.4,0.3,0.5,0.4c0.3,0.6,0.1,1.9-0.1,2.5c-0.1,0.4-0.8,0.9-1,1.3c-0.1,0.4-0.1,1-0.1,1.6c0,0-0.2,1.4,0.5,2.4
				s2.3,0.8,2.5,2.6c0.1,1.8-0.4,4.7-0.4,4.7s0.2,1.3,1,2c0.8,0.7,3.4,2.6,3.4,2.6v3.9v0.7l0.8-0.7l4.4-2.9l3.8-1.1l0.7-2.1l3.2-2.4
				c0,0,2.6,0.5,4.6,1.6c1.9,1.1,2.9,5.1,2.9,5.1l1.6,0.3l1,0.2c0,0,0.5,3.3,1.1,4.3c0.6,1,2.8,0.8,3.8,0.9c1,0.1,3.1,0,4.1,0.8
				c0.5,0.3,1.2,1.2,1.8,2.1c0.9,1.1,1.6,2.3,1.6,2.3l1.8-1.3c0,0-0.2-0.4-0.4-1c0-0.1-0.1-0.2-0.1-0.4c-0.2-0.6-0.4-1.2-0.5-1.5
				c-0.1-0.6,0.6-1.6,2.2-2.6c1.6-1,4.8-1.9,4.8-1.9v3.3c0,0,2-0.6,3.5-2.3c0.7-0.8,1.2-1.4,1.7-2
				C355.7,313.8,355.5,313.6,355.4,313.5z"/>
		<path @click="openEmc" data-emc="jefferson-energy-cooperative" ref="jefferson-energy-cooperative" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M413.4,227c-0.3-0.3-0.9-0.9-1.2-1.1c-0.2-0.2-0.8-0.3-0.9-0.6c-0.1-0.2-0.1-0.7,0-0.9c0.1-0.2,0.6,0,0.8-0.1
			c0.2-0.2,0.5-0.8,0.4-1c-0.1-0.3-0.8-0.6-1.2-0.7c-0.3,0-0.8,0.1-1.1,0.2c-0.2,0.1-0.5,0.7-0.7,0.7c-0.3,0-0.5-0.5-0.7-0.8
			c-3,0-5-1.7-6.6-2.5c-1.8-0.9-2.4-2.5-4.3-3.5c-1.9-1.1-3.5-2.2-3.5-2.2l-0.3-3.8c0,0-7.8-1.5-9.6-1.7c-0.9-0.1-3.7,0-6.8,0
			c-3.2-0.1-6.7-0.3-8.5-1c-3.4-1.5-7.2-6.8-7.2-6.8c-0.4-0.5-0.7-1.2-0.7-1.9c0-0.9,0.2-1.9-0.3-2.6c-0.5-0.6-1.3-0.7-1.9-1.2
			c-0.4-0.3-0.7-0.8-0.9-1.3c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.1,0-0.3,0.1-0.4c0.1-0.5,0.2-0.9,0.3-1.4c0-0.1,0.1-0.2,0.1-0.3
			c0.1-0.1,0.3-0.1,0.5-0.1c0.8,0,1.6,0,2.2-0.4c0.3-0.2,0.6-0.4,0.7-0.7c0.1-0.3,0.1-0.6,0.1-0.9c0.1-0.3,0.3-0.6,0.6-0.5
			c0.3-0.5,0.2-1.2-0.3-1.6c-0.3-0.2-0.7-0.4-0.8-0.7c-0.1-0.2,0-0.4,0-0.6c0-1.1-0.5-2.3,0.1-3.1c-0.5-0.5-1-1.1-1.4-1.6
			c0,0.1-0.1,0.3-0.2,0.3c-0.2,0.1-0.8-0.3-0.8-0.4c0-0.1,0-0.9-0.1-1.1c-0.1-0.1-0.3-0.3-0.4-0.3c-0.3-0.1-0.8,0.1-1,0.2
			c-0.2,0.1-0.4,0.4-0.6,0.4c-0.2,0-0.6-0.4-0.8-0.4c-0.2,0-0.5,0.1-0.6,0.3c-0.1,0.2,0.3,0.6,0.3,0.9c0,0.2-0.1,0.7-0.2,0.7
			c-0.1-0.1-0.6-0.4-0.9-0.4c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.6-0.1,0.7c-0.1,0.2-0.6,0.6-0.7,0.5c-0.1,0-0.6-0.1-0.6-0.2
			c0-0.1,0.2-0.8,0-1c0,0,0,0,0,0c-0.2-0.2-0.7,0-1,0.1c-0.2,0.1-0.5,0.5-0.7,0.7c-0.2,0.2-0.3,0.8-0.4,1c-0.1,0.2-0.5,0.6-0.7,0.6
			c-0.2,0-0.3-0.4-0.5-0.5c-0.2-0.1-0.7,0-0.9,0.1c-0.3,0.1-0.6,0.6-0.9,0.8c-0.2,0.2-0.8,0.4-0.9,0.6c-0.1,0.2-0.1,0.8-0.2,1
			c-0.1,0.2-0.2,0.6-0.4,0.8c-0.2,0.2-0.6,0.4-0.8,0.5c-0.2,0.2-0.5,0.8-0.7,1.1c-0.2,0.3-0.5,0.9-0.7,1.2c-0.1,0.2-0.3,0.5-0.5,0.6
			c-0.2,0-0.7-0.5-0.6-0.6c0.1-0.1,0.1-1,0.3-1.4c0.1-0.3,0.7-0.6,0.9-0.8c0.1-0.2,0.1-0.5,0.3-0.7c0.1-0.1,0.5-0.2,0.5-0.3
			c0-0.1-0.2-0.3-0.3-0.4c-0.3-0.1-0.8,0.5-1.1,0.5c-0.1,0-0.4-0.2-0.4-0.3c0-0.1,0.8-0.4,0.9-0.7c0-0.1-0.3-0.4-0.3-0.5
			c0-0.2,0.5-0.5,0.8-0.6c0.2,0,0.5,0.3,0.7,0.2c0.2,0,0.3-0.4,0.4-0.5c0.2-0.1,0.8-0.2,1-0.3c0.2-0.1,0.7-0.4,0.7-0.6
			c0-0.2-0.2-0.5-0.4-0.6c-0.2-0.2-0.6-0.3-0.8-0.4c-0.2-0.1-0.5-0.4-0.7-0.5c-0.2-0.1-0.6-0.3-0.8-0.2c-0.2,0.1-0.4,0.5-0.4,0.7
			c0,0.1,0,0.5-0.1,0.6c-0.2,0.2-0.8,0-1-0.1c-0.2-0.1-0.3-0.5-0.5-0.5c-0.2,0-0.5,0.3-0.5,0.5c-0.1,0.2,0.1,0.7,0,0.9
			c-0.1,0.1-0.3,0.3-0.5,0.3c-0.2,0-0.7-0.4-0.9-0.3c-0.1,0-0.2,0.3-0.4,0.4c-0.2,0.1-0.7-0.1-0.9-0.1c-0.1,0-0.4,0.1-0.5,0.1
			c-0.2,0-0.5-0.1-0.7-0.1c-0.2,0.1-0.5,0.4-0.6,0.5c-0.1,0.1-0.3,0.5-0.4,0.6c-0.2,0.2-0.7,0.4-1,0.4c-0.2,0-0.5-0.2-0.7-0.1
			c-0.2,0-0.5,0.4-0.7,0.5c-0.2,0.1-0.5,0.2-0.7,0.1c0,0,0,0-0.1,0c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.1-0.9,0-1.2,0
			c-0.3-0.1-1-0.6-1.3-0.5c-0.2,0.1,0,1-0.2,1.1c-0.2,0.1-0.4-0.2-0.6-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1,0.4-0.1,0.5
			c-0.1,0.2-0.2,0.6-0.4,0.7c-0.1,0-0.4-0.2-0.5-0.2c-0.3-0.1-0.9-0.1-1.1,0c-0.1,0.1-0.3,0.4-0.4,0.4c-0.1,0-0.2,0-0.3-0.1
			c-0.3,0.4-0.6,0.9-0.9,1c-0.3,0.1-0.9-0.2-1.2-0.1c-0.2,0.1-0.6,0.5-0.7,0.7c-0.2,0.4,0,1.2-0.1,1.6c-0.1,0.3-0.5,1-0.7,1.3
			c-0.1,0.2-0.5,0.5-0.6,0.8c-1.1,0.3-3.3,0.8-4.3,1.2c-0.9,0.4-3.2,1.8-4.6,2.8c0.9,1,2.2,2.6,2.3,3.2c0.1,0.9,1.7,1.8,2.7,2.8
			c1,0.9,3.6,2.5,5.6,3.8c2,1.3,4.5,3.1,5.1,4.4c0,0,0.7,3-0.2,5.9c-0.8,2.9-0.8,6.8-1.3,7.7c-0.3,0.5-1.4,1.9-2.1,2.8
			c0,0,0.1,0,0.1,0c0,0-0.1,0-0.1,0c0,0.6,0.1,1.3,0.2,1.6c0.3,0.5,3.2,3.9,3.2,3.9s-1.1,2.2-1.9,3.5c-0.8,1.3-2.1,2.1-3.2,2.1
			c-0.7,0-1.9,0.2-2.8,0.3c-0.1,0.2-0.1,0.4-0.1,0.6c0.1,0.2,0.5,0.5,0.7,0.6c0.4,0.2,1.2,0.1,1.5,0.3c0.2,0.2,0.3,0.8,0.4,1.1
			c0.1,0.2,0.1,0.7,0.2,0.8c0.2,0.2,0.7,0.2,0.9,0.3c0.4,0.2,1.2,0.5,1.4,0.8c0.2,0.2,0.1,0.9,0.3,1.1c0.3,0.2,1.1-0.3,1.4-0.2
			c0.4,0.1,1,0.6,1.2,0.9c0.1,0.1,0.1,0.3,0.1,0.4c0,0.3,0,0.6,0,0.8c0,0.3-0.2,1,0,1.2c0.3,0.4,1.4,0.1,1.7,0.5
			c0.2,0.2,0.1,0.7,0.1,0.9c0,0.3-0.4,0.7-0.4,1c0,0.3,0.2,0.8,0.4,1.1c0.2,0.3,0.9,0.5,1,0.7c0.2,0.3,0.1,1,0.3,1.3
			c0.2,0.3,0.9,0.4,1.1,0.7c0.1,0.2-0.2,0.6-0.1,0.8c0.1,0.4,1.1,0.8,1.3,1.1c0.2,0.2,0.1,0.9,0.4,1c0.2,0.1,0.7-0.1,0.9,0
			c0.3,0,0.9,0.2,1.1,0.4c0.2,0.2,0.4,0.7,0.6,0.9c0.1,0.1,0.3,0.3,0.5,0.4c-0.2,1.4-0.1,2.7-0.7,3.4c-0.7,0.9-0.9,2.2-1.1,3.2
			c-0.3,1-0.5,2.2,0,2.7c0.5,0.5,3.3,0,4.2,0.5c0.4,0.2,1.4,0.5,2.2,0.6c0.8,0.2,1.4,0.3,1.5,0.4c0,0.2,1.8,3,1.9,4.3
			c0.1,1.3,0.6,3.5,0,4.4c-0.6,0.9-1.8,1.5-2.5,2.3c-0.2,0.3-0.5,0.5-0.8,0.7c0,0.1,0,0.1,0,0.2c0.1,0.3,0.3,0.9,0.4,1.2
			c0,0.1,0.1,0.3,0.2,0.5c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0,0c-0.2,0.5-0.6,1-0.9,1.4c-0.7,0.7-4.4,0.8-4.4,0.8l2.2,3.6l-0.1,3
			l2.4,1.3l3,5.2c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.1,0.8,0,1.1c0.1,0.2,0.5,0.6,0.6,0.8
			c0.1,0.3-0.2,1-0.1,1.3c0,0,0.8,1.4,1.5,2.6c0.4,0.7,0.8,1.3,0.9,1.5c0.4,0.5,3.1,0.6,3.5,2.7c0.4,2,1.4,4.5,2,4.9
			c0.2,0.1,0.4,0.3,0.6,0.5c0.6-0.6,1.1-1.3,1.8-2.1c1.2-1.5,3.6-5,4.8-7.4s1.3-2.8,0.8-4.5c-0.5-1.7-2.8-5.9-2.8-5.9v-11.1
			c0,0,0,0,0,0c0.2-0.1,0.4-0.6,0.6-0.8c0.3-0.3,1.1-0.7,1.4-0.9c0.3-0.2,0.9-0.4,1.2-0.6c0.1-0.1,0.2-0.4,0.3-0.5
			c0.2-0.1,0.7-0.1,1.1-0.1c0.2,0.5,0.5,1,0.8,1.2c0.3,0.2,1.1,0.5,1.4,0.5c0.5,0.1,1.5-0.2,2-0.1c0.3,0,0.9,0,1.1,0.1
			c0.4,0.2,1,1,1.4,1.2c0.2,0.1,0.7,0.4,1,0.5c0.3,0,0.8-0.2,1-0.1c0.4,0.1,1,0.6,1.4,0.8c0.4,0.2,1.3,0.5,1.7,0.4
			c0.4-0.1,0.9-0.7,1.3-0.8c0.3-0.1,1-0.2,1.3-0.2c0.3,0,0.7,0,1,0.1c0-1.4,0-2.9,0.1-4.3c0.9,0.1,1.8,0.2,2.6,0.3l0,0
			c0.1,0,0.2,0,0.3,0.1c0.2,0,0.4,0,0.5,0.1c-0.2,0-0.4,0-0.5-0.1c0.8,0.2,1.6,0.3,2.4,0.4c1,0.1,2,0.3,3,0c0.6-0.2,1.1-0.5,1.7-0.7
			c1.4-0.8,2.9-1.6,4.3-2.4c-0.8-2.1-1.6-4.3-2.4-6.4l-3.2,0.2l-4.4,3.9c0,0,0,0,0,0c0,0,0,0,0,0c-0.7-0.7-1.2-1.7-1.4-2.7
			c-0.2-1.2-0.1-2.5,0-3.7c-1.2-0.8-2.3-1.6-3.5-2.5c-1.2,0.5-2.4,1-3.3,1.8c-0.5,0.5-1,1.1-1.6,1.4c-0.9,0.5-2,0.6-3,0.6
			c0-1.7-0.1-3.5,0.3-5.1c0.4-1.8,1.2-3.6,0.6-5.3c-0.1-0.3-0.3-0.6-0.3-0.9c-0.1-0.4-0.1-0.9-0.1-1.3c0-1.1,0.2-2.3,0.2-3.4
			c-0.8-0.4-1.6-0.7-2.4-1.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.9-0.1-1.7,0.1-2.5c0.1-0.4,0.4-0.8,0.6-1.2
			c0.3-0.7,0.2-1.5,0.7-2c1-1.1,1.2-3,2.6-3.7c0.2-0.1,0.4-0.2,0.7-0.1c0.2,0,0.3,0.2,0.4,0.3c0.7,0.6,1.3,1.4,2.1,1.8
			c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3-0.1,0.4-0.2c0.6-0.5,1.1-1.3,1.5-2c0.2-0.4,0.3-0.9,0.6-1.3c0.2-0.2,0.5-0.4,0.8-0.5
			c0.7-0.3,1.3-0.6,2-0.9c0.1,0,0.1-0.1,0.1-0.1c0,0-0.1,0-0.1,0c0-0.1,0.1-0.1,0.1,0c0,0,0,0,0,0c1.1,0.2,2.1,0.3,3.2,0.5
			c0.2,0,0.4,0.1,0.6,0c0.2,0,0.5-0.2,0.7-0.3c0.9-0.6,1.7-1.3,2.4-2.1c0.2-0.2,0.3-0.3,0.4-0.6c0.4-0.7,0.1-1.6,0.4-2.3
			c0.7-0.2,1.5-0.1,2,0.4c0.3,0.2,0.5,0.5,0.8,0.6c0.5,0.2,1.1-0.2,1.7-0.1c0.3-0.8,0.6-1.7,0.7-2.5c0.3-0.1,0.6-0.2,0.8-0.3
			c-0.1,0-0.2-0.1-0.2-0.1c0.2,0.1,0.6,0.3,0.8,0.4c0.8,0.2,2.4,0.3,3.1,0c0.3-0.1,0.6-0.7,0.8-0.8c0.3-0.2,1.1-0.2,1.4-0.4
			c0.3-0.2,0.5-0.9,0.7-1.2c0.4-0.4,1.5-0.8,2.1-1c0.3-0.1,0.9-0.3,1.3-0.3c0.5-0.1,1.4-0.3,1.9-0.3c0.3,0,1,0.3,1.3,0.5
			c0.5,0.2,1.3,1.1,1.8,1.2c0.1,0,0.4,0.1,0.6,0.1c0,0,0,0,0,0c-0.1-0.2,0.3-0.6,0.4-0.7c0.3-0.2,1.1-0.1,1.3-0.4
			c0.2-0.2,0.5-0.9,0.4-1.2C414.2,227.9,413.6,227.3,413.4,227z"/>
    <g id="planters-emc">
	<path @click="openEmc" data-emc="planters-emc" ref="planters-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M465.3,321.4c-0.3-0.5-0.8-0.9-1.3-1.2c-2.2-1.6-4.6-3-6.2-5.3c0.5-0.4,1-0.7,1.6-1.1c0.1-0.1,0.2-0.1,0.2-0.2
		c0.1-0.1,0.1-0.3,0.1-0.4c-0.1-1.1,0.1-2.1,0-3.2v-0.4c-0.4-0.2-0.8-0.5-1-0.7c-0.4-0.4-1.1-1.5-1.4-2c-0.3-0.4-0.9-1.1-1-1.6
		c0-0.3,0.3-0.9,0.5-1.1c0.2-0.2,0.9-0.3,1.2-0.5c0.3-0.2,1.1-0.5,1.1-0.8c0-0.3-0.9-0.4-1.1-0.7c-0.1-0.3,0-0.9,0-1.2
		c0.1-0.3,0.6-0.9,0.5-1.3c-0.1-0.3-0.9-0.6-0.9-0.7c0-0.1,0.3-0.9,0.2-1.2c-0.2-0.4-1.4-0.1-1.8-0.4c-0.2-0.2-0.5-0.7-0.5-0.9
		c0.1-0.4,1.1-0.8,1.3-1.2c0.1-0.3,0.2-0.9,0.1-1.2c-0.1-0.2-0.6-0.6-0.5-0.8c0.1-0.4,1.1-0.5,1.2-0.9c0.1-0.4-0.9-0.9-1-1.3
		c0-0.4,0.7-1,0.6-1.4c-0.1-0.4-1.3-0.6-1.4-1c-0.1-0.2,0.4-0.6,0.4-0.8c0-0.3-0.5-0.7-0.5-1c0-0.3,0.5-0.7,0.4-1
		c0-0.3-0.6-0.6-0.6-0.8c0-0.4,0.8-1.1,0.7-1.6c-0.1-0.4-1-0.9-1-1.3c0-0.2,0.4-0.6,0.5-0.8c0.1-0.2,0.2-0.8,0-1
		c-0.3-0.4-1.4-0.2-1.8-0.6c-0.2-0.2-0.1-0.8-0.4-0.9c-0.2,0-0.4,0.5-0.6,0.5c-0.4-0.1-0.6-1.1-0.6-1.4c0-0.3,0.7-0.8,0.7-1.1
		c-0.1-0.4-0.9-1-1.3-1.1c-0.2-0.1-0.6,0.1-0.8,0c-0.3-0.1-0.6-0.7-0.6-0.9c0-0.2,0.3-0.6,0.5-0.8c0.2-0.1,0.9,0,1-0.2
		c0.1-0.2-0.1-0.7-0.3-0.8c-0.3-0.2-1,0-1.3-0.1c-0.3-0.2-0.5-0.9-0.6-1.2c-0.1-0.5,0.2-1.8-0.1-2.2c-0.2-0.3-0.9-0.4-1.1-0.7
		c-0.2-0.2-0.3-0.8-0.5-1.1c-0.2-0.3-0.8-0.8-1-1.1c-0.1-0.3-0.2-0.9,0-1.2c0.1-0.2,0.8-0.2,0.9-0.4c0.3-0.3,0.4-1.1,0.5-1.4
		c0.2-0.4,0.9-1,0.9-1.4c0-0.4-0.8-0.8-0.8-1.2c0-0.3,0.7-0.8,0.6-1.1c0-0.3-0.5-0.7-0.8-0.7c-0.4-0.1-1.2,0.6-1.5,0.4
		c-0.2-0.2-0.1-0.9,0-1.2c0-0.2,0.3-0.7,0.2-1c-0.1-0.3-0.6-0.6-0.8-0.7c-0.3-0.2-1-0.2-1.3-0.4c-0.2-0.2-0.3-1-0.6-1.1
		c-0.1-0.1-0.4,0.1-0.6,0.1c-0.1,0.1-0.3,0.1-0.4,0.1c-0.4,0-1.2-0.2-1.5-0.5c-0.2-0.3,0-1.2-0.3-1.5c-0.2-0.2-0.8-0.3-1-0.5
		s-1.1-1.2-1.3-1.2c-0.1,0-1.2,0.1-1.2,0c0-0.1-0.6-0.7-0.8-1c-0.2-0.3-0.3-1-0.5-1.2c-0.2-0.2-0.9-0.3-1.2-0.3
		c-0.4,0-1.2,0.4-1.5,0.3c-0.3-0.1-0.8-0.5-1.1-0.7c-0.5-0.3-1.4-1-1.9-1.3c-0.3-0.2-1-0.5-1.3-0.6c-0.5-0.1-1.4,0-1.9-0.2
		c-0.6-0.2-1.6-1-2.1-1.4c-0.5-0.5-1.4-1.6-2-2.1c-0.3-0.3-1.1-0.7-1.3-1c-0.4-0.4-1-1.5-1.2-2c-0.2-0.6-0.3-2,0-2.6
		c0.1-0.3,0.8-0.5,0.9-0.8c0.1-0.4-0.3-1.1-0.4-1.4c-0.2-0.4-0.7-1.2-1.1-1.4c-0.2-0.1-0.6-0.1-0.7,0c-0.2,0.2,0.4,0.9,0.3,1.2
		c-0.1,0.2-0.7,0.4-0.9,0.4c-0.3,0-0.8-0.6-1.1-0.7c-0.2-0.1-0.7,0.1-0.9,0c-0.3-0.2-0.3-1.1-0.5-1.4c-0.2-0.2-0.7-0.5-1-0.6
		c-0.4-0.3-1.2-1-1.6-1.3c-0.4-0.3-1.6-0.5-1.7-0.9c-0.2,0-0.5-0.1-0.6-0.1c-0.5-0.1-1.3-1-1.8-1.2c-0.3-0.2-0.9-0.4-1.3-0.5
		c-0.5,0-1.4,0.2-1.9,0.3c-0.3,0.1-1,0.2-1.3,0.3c-0.5,0.2-1.6,0.6-2.1,1c-0.3,0.2-0.4,1-0.7,1.2c-0.3,0.2-1.1,0.2-1.4,0.4
		c-0.3,0.1-0.6,0.7-0.8,0.8c-0.7,0.3-2.3,0.2-3.1,0c-0.2,0-0.4-0.2-0.6-0.3c-0.3,0.1-0.6,0.2-0.8,0.3c-0.1,0.9-0.4,1.7-0.7,2.5
		c-0.5-0.1-1.1,0.3-1.7,0.1c-0.3-0.1-0.6-0.4-0.8-0.6c-0.6-0.5-1.3-0.6-2-0.4c-0.3,0.7,0,1.6-0.4,2.3c-0.1,0.2-0.3,0.4-0.4,0.6
		c-0.7,0.8-1.6,1.5-2.4,2.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.2,0-0.4,0-0.6,0c-1.1-0.2-2.1-0.3-3.2-0.5c0,0.1-0.1,0.1-0.1,0.1
		c-0.7,0.3-1.3,0.6-2,0.9c-0.3,0.1-0.6,0.3-0.8,0.5c-0.3,0.3-0.5,0.8-0.6,1.3c-0.3,0.8-0.8,1.5-1.5,2c-0.1,0.1-0.2,0.2-0.4,0.2
		c-0.1,0-0.3,0-0.4-0.1c-0.9-0.4-1.4-1.1-2.1-1.8c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.5,0-0.7,0.1c-1.3,0.7-1.6,2.6-2.6,3.7
		c-0.5,0.5-0.4,1.4-0.7,2c-0.2,0.4-0.4,0.8-0.6,1.2c-0.3,0.8-0.2,1.7-0.1,2.5c0,0.2,0,0.4,0.2,0.6c0.1,0.2,0.3,0.2,0.5,0.3
		c0.8,0.4,1.6,0.7,2.4,1.1c0,1.1-0.2,2.3-0.2,3.4c0,0.4,0,0.9,0.1,1.3c0.1,0.3,0.2,0.6,0.3,0.9c0.6,1.7-0.2,3.5-0.6,5.3
		c-0.4,1.7-0.3,3.4-0.3,5.1c1,0,2.1,0,3-0.6c0.6-0.4,1.1-0.9,1.6-1.4c0.9-0.8,2.1-1.3,3.3-1.8c1.1,0.8,2.3,1.7,3.5,2.5
		c-0.1,1.2-0.2,2.5,0,3.7c0.2,1,0.6,2,1.4,2.7l4.4-3.9l3.2-0.2c0.8,2.1,1.6,4.3,2.4,6.4c-1.4,0.8-2.9,1.6-4.3,2.4
		c-0.5,0.3-1.1,0.6-1.7,0.7c-1,0.3-2,0.1-3,0c-0.9-0.1-1.8-0.3-2.7-0.5l0,0c-0.9-0.1-1.8-0.2-2.6-0.3c0,1.4,0,2.9-0.1,4.3
		c0,1.9-0.1,3.8-0.1,5.6c0,0.2,0,0.4,0.1,0.6c0.1,0.1,0.2,0.2,0.4,0.3c1.5,0.9,3.1,1.7,4.7,2.5l1.7,4.8c0.1,0.1,0.3,0.2,0.4,0.3
		c1.4,1.1,2.8,2.2,4.2,3.2c-0.2,0.8-0.4,1.7-0.6,2.5c-0.4,1.6-0.8,3.2-1.2,4.8c0.6,0.8,1.2,1.6,1.8,2.4c0.1,0.2,0.3,0.4,0.5,0.5
		c0.2,0.1,0.5,0.1,0.8,0.1c0.9,0,1.8,0,2.8,0c0,0,0,0,0,0c0.9,0.2,1.9,0.1,2.7-0.2c0.5-0.2,1-0.5,1.5-0.7c0.5-0.2,1.1-0.2,1.6,0.1
		c-0.1,1.1-0.1,2.1,0,3.2c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0.3,0.3,0.4,0.4c0.8,0.4,1.6,0.9,2.4,1.3c1-0.6,1.9-1.5,2.6-2.4
		c-0.6-0.6-1.2-1.1-1.9-1.5c0-0.7,0-1.3,0-2c0-0.2,0-0.3,0.1-0.5c0.1-0.3,0.4-0.5,0.6-0.6c0.7-0.4,1.5-0.7,2.4-0.9
		c3.2-0.9,6.6-1.4,9.7-2.4c0,0,0,0,0,0c0.4,0.3,1.1,1.4,1.2,1.5c0.1,0.1,0,0.8,0.2,1.1c0.2,0.3,1.1,0.3,1.4,0.6
		c0.1,0.1,0.1,0.4,0.2,0.5c0.3,0.2,1.1-0.1,1.4,0.1c0.3,0.2,0.6,1,0.8,1.3c0.2,0.2,0.8,0.4,0.9,0.7c0.2,0.3,0,1,0.2,1.3
		c0.2,0.3,1,0.8,1.3,0.9c0.3,0.1,1,0.1,1.2,0.4c0.2,0.2,0.3,0.8,0.5,1c0.3,0.3,0.9,0.8,1.3,0.9c0.3,0.1,0.9-0.2,1.2-0.1
		c0.5,0.1,1.4,0.5,1.6,0.9c0.1,0.2-0.1,0.5,0,0.7c0.2,0.3,0.9,0.8,1.3,0.9c0.2,0,0.5-0.2,0.7-0.2c0.3,0,0.8-0.2,1-0.1
		c0.3,0.1,0.4,0.8,0.7,0.9c0.2,0.1,0.8-0.3,1-0.1c0.2,0.2-0.2,1,0,1.3c0.2,0.2,0.8,0.4,1.1,0.5c0.3,0.1,1,0.3,1.3,0.2
		c0.2-0.1,0.4-0.4,0.5-0.5c0.2-0.1,0.7-0.1,0.9-0.1c0.4,0.1,1,0.6,1.2,0.9c0.2,0.3,0.4,1,0.7,1.3c0.2,0.2,0.8,0.4,1.2,0.6l0.9-2
		l0.7-0.5c0.1-2.3,0.3-4.5,0.6-6.8c0.1-0.4,0.1-0.8,0.2-1.2c0.4-1,1.2-1.6,2-2.3c0.7,0.3,1.4,0.6,2.1,1.1c0,1.9-0.1,3.9-0.1,5.8
		l-1.6,1.1c0,0.9,0,1.8,0,2.7c1.2,0.1,2.4,0.2,3.6,0.4c0.1,2.1,0.3,4.3,0.4,6.4c2.1-0.7,4.1-1.6,6.1-2.6c0.3-0.2,0.6-0.3,1-0.4
		c0.3-0.1,0.6,0,0.8,0c2.1,0.1,4.2,0.2,6.3,0.3C466,323.4,466,322.3,465.3,321.4z"/>
	<path @click="openEmc" data-emc="planters-emc" ref="planters-emc-1" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M458,343.9l0.2-2.2l-3.2-3.4L453,335l-1.2-0.6v-2.8l-3.3-5c-1,0-2.1,0-3.1-0.1c0.2,0.4,0.3,0.9,0.3,1.2
		c0,0.3-0.2,1-0.4,1.3c-0.2,0.3-1,0.5-1.1,0.8c-0.1,0.2,0.3,0.9,0.3,1c0,0.1-0.5,0.6-0.5,0.8c0,0.4,0.8,1,0.9,1.4
		c0.1,0.4-0.3,1.1-0.1,1.5c0.1,0.2,0.5,0.5,0.6,0.7c0.2,0.3,0.4,1,0.7,1.2c0.2,0.2,0.8,0.2,1,0.4c0.2,0.3,0,1.1,0.1,1.4
		c0.1,0.3,0.5,1,0.5,1.1c0,0.1,1,0.3,1.2,0.6c0.2,0.3,0.1,1.2,0.2,1.6c0.1,0.3,0.4,0.9,0.7,1.1c0.4,0.2,1.6-0.2,1.9,0.1
		c0.3,0.3,0,1.2,0.2,1.5c0.1,0.2,0.7,0.2,0.8,0.3c0.3,0.3,0.6,1,0.6,1.4c0,0.4-0.4,1.2-0.3,1.6c0,0.1,0.1,0.2,0.1,0.3l3-1.2
		L458,343.9z"/>
    </g>
	<path @click="openEmc" data-emc="excelsior-emc" ref="excelsior-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M456.4,358.5c0-0.4,0.6-1.1,0.5-1.5c-0.1-0.4-1.1-0.5-1.4-0.8c-0.1-0.2-0.2-0.6-0.2-0.8c0-0.3,0.5-0.9,0.4-1.3
		c-0.1-0.3-0.8-0.6-1-0.8c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.5-0.1-1.3-0.1-1.7c0-0.3,0.1-0.9,0.1-1.2c0-0.3-0.2-0.9-0.3-1.1
		c-0.2-0.2-0.7-0.4-0.8-0.5c-0.2-0.2-0.4-0.7-0.5-1c-0.1-0.4,0.3-1.2,0.3-1.6c0-0.4-0.3-1.1-0.6-1.4c-0.2-0.2-0.7-0.2-0.8-0.3
		c-0.2-0.3,0.1-1.3-0.2-1.5c-0.4-0.3-1.5,0.1-1.9-0.1c-0.3-0.2-0.6-0.8-0.7-1.1c-0.1-0.4,0-1.3-0.2-1.6c-0.2-0.3-1.2-0.5-1.2-0.6
		c0-0.1-0.4-0.8-0.5-1.1c-0.1-0.3,0.1-1.1-0.1-1.4c-0.2-0.2-0.8-0.3-1-0.4c-0.3-0.2-0.5-0.9-0.7-1.2c-0.1-0.2-0.5-0.4-0.6-0.7
		c-0.1-0.4,0.2-1.1,0.1-1.5c-0.1-0.4-0.9-0.9-0.9-1.4c0-0.2,0.5-0.7,0.5-0.8c0-0.1-0.4-0.8-0.3-1c0.1-0.3,0.9-0.5,1.1-0.8
		c0.2-0.3,0.4-0.9,0.4-1.3c0-0.4-0.2-1.3-0.5-1.6c-0.2-0.3-1.2-0.2-1.3-0.5c-0.1-0.3,0.7-0.6,0.7-0.9c0-0.4-0.4-1.1-0.7-1.4
		c-0.1-0.2-0.5-0.5-0.7-0.6c0,0-0.1,0-0.1-0.1c-0.4-0.2-1-0.3-1.2-0.6c-0.3-0.3-0.4-1-0.7-1.3c-0.2-0.3-0.9-0.8-1.2-0.9
		c-0.2-0.1-0.7,0-0.9,0.1c-0.2,0.1-0.3,0.5-0.5,0.5c-0.3,0.1-1-0.1-1.3-0.2c-0.3-0.1-0.9-0.2-1.1-0.5c-0.2-0.3,0.2-1.1,0-1.3
		c-0.2-0.2-0.8,0.2-1,0.1c-0.3-0.1-0.4-0.8-0.7-0.9c-0.2-0.1-0.8,0.1-1,0.1c-0.2,0-0.5,0.2-0.7,0.2c-0.4-0.1-1.1-0.6-1.3-0.9
		c-0.1-0.2,0.1-0.6,0-0.7c-0.2-0.4-1.2-0.8-1.6-0.9c-0.3-0.1-0.9,0.2-1.2,0.1c-0.4-0.1-1-0.6-1.3-0.9c-0.2-0.2-0.3-0.8-0.5-1
		c-0.2-0.2-0.9-0.2-1.2-0.4c-0.4-0.2-1.1-0.6-1.3-0.9c-0.2-0.3,0-1-0.2-1.3c-0.2-0.3-0.7-0.5-0.9-0.7c-0.3-0.3-0.5-1.1-0.8-1.3
		c-0.3-0.2-1.1,0.1-1.4-0.1c-0.1-0.1-0.1-0.4-0.2-0.5c-0.3-0.3-1.1-0.3-1.4-0.6c-0.2-0.2-0.1-0.9-0.2-1.1c-0.1-0.1-0.8-1.2-1.2-1.5
		c0,0,0,0,0,0c-3.2,1.1-6.5,1.5-9.7,2.4c-0.8,0.2-1.6,0.5-2.4,0.9c-0.3,0.2-0.5,0.3-0.6,0.6c-0.1,0.2-0.1,0.3-0.1,0.5
		c0,0.7,0,1.3,0,2c0.7,0.4,1.4,0.9,1.9,1.5c-0.7,1-1.6,1.8-2.6,2.4c-0.8-0.4-1.6-0.9-2.4-1.3c-0.2-0.1-0.3-0.2-0.4-0.4
		c-0.1-0.1-0.1-0.3-0.1-0.4c-0.1-1.1-0.1-2.1,0-3.2c-0.4-0.3-1-0.3-1.6-0.1c-0.5,0.2-1,0.5-1.5,0.7c-0.8,0.4-1.8,0.4-2.7,0.2
		c-0.1,0.2-0.1,0.3-0.2,0.5c0-0.1,0.1-0.3,0.2-0.5c-0.9,0-1.8,0-2.8,0c-0.3,0-0.5,0-0.8-0.1c-0.2-0.1-0.4-0.3-0.5-0.5
		c-0.6-0.8-1.2-1.6-1.8-2.4c0.4-1.6,0.8-3.2,1.2-4.8c0.2-0.8,0.4-1.7,0.6-2.5c-1.4-1.1-2.8-2.2-4.2-3.2c-0.1-0.1-0.3-0.2-0.4-0.3
		l-1.7-4.8c-1.6-0.8-3.2-1.6-4.7-2.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.2-0.1-0.4-0.1-0.6c0-1.9,0.1-3.8,0.1-5.6
		c-0.4,0-0.8-0.1-1-0.1c-0.3,0-1,0.1-1.3,0.2c-0.4,0.1-0.9,0.8-1.3,0.8c-0.4,0.1-1.3-0.3-1.7-0.4c-0.4-0.1-1-0.7-1.4-0.8
		c-0.3-0.1-0.8,0.2-1,0.1c-0.3,0-0.8-0.3-1-0.5c-0.4-0.3-1-1-1.4-1.2c-0.3-0.1-0.8-0.1-1.1-0.1c-0.5,0-1.5,0.2-2,0.1
		c-0.4-0.1-1.2-0.3-1.4-0.5c-0.2-0.2-0.5-0.7-0.8-1.2c-0.4,0-0.9,0-1.1,0.1c-0.1,0.1-0.2,0.4-0.3,0.5c-0.2,0.2-0.9,0.5-1.2,0.6
		c-0.4,0.2-1.1,0.6-1.4,0.9c-0.2,0.2-0.4,0.7-0.6,0.8c0,0,0,0,0,0v9.9h5.1l4.3-2.5h1.8l3.9,4.1v6.6l-0.8,1.1l0.8,0.7l2.3-1.2
		l3.3,0.3v3.3h-6.6l-2.2-2.5h-3.2l-1.9,4.8l-0.3,1.9h4.5v6.3c-0.7,0.4-1.7,0.3-2.3-0.2c-0.4-0.3-0.7-0.7-1.1-1
		c-0.4-0.3-1-0.4-1.3-0.1c0.1,0.7,0.1,1.4,0.1,2c0.6,0.4,1.2,0.9,1.9,1.3c0,0.2-0.1,0.3-0.1,0.5c-1.2,0.4-2.3,1.1-3.2,2
		c1.1,1.4,2,3,2.5,4.6c0.1,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.2c0.4,0.2,0.9,0.3,1.3,0.5c0.2,0.4,0.2,0.8,0.1,1.3
		c-0.1,0.3-0.2,0.5-0.3,0.8c0,0.5,0.3,1,0.8,1.2c0.5,0.2,1,0.2,1.5,0.2c0,0.9,0,1.8,0,2.8c0,0.6-0.5,1.1-0.8,1.6
		c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.3,0.2-0.5,0.2c-0.6,0.2-1.1,0.5-1.5,1c0,1,0.2,2.1,1,2.7c0.3,0.2,0.6,0.3,0.9,0.6
		c0.3,0.3,0.5,0.8,0.9,1c0,0,1,0.3,1.2,0.6c0.1,0.2-0.1,0.8-0.1,1c0,0.3,0.4,0.9,0.5,1.2c0.1,0.2,0.3,0.9,0.4,1.1l3.1,0.4
		c0.9,0.7,1.8,1.4,2.6,2.1c1.3,0.2,2.5,0.4,3.8,0.6c0.2,0,0.3,0.1,0.5,0.1c0.2,0.1,0.3,0.3,0.4,0.5c0.6,1.3,1.5,2.4,2.4,3.5
		c0.6,0.8,1.1,1.7,1.9,2.2c1.2-0.2,2.3-1,3.5-1.3c0.7-0.2,1.4-0.3,2.1-0.4c0.5-0.1,1-0.1,1.6-0.2c0.5-0.1,1.1-0.1,1.6-0.2
		c0.5-0.1,1-0.1,1.5,0c0.6,0.1,1.1,0.3,1.6,0.4c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.8,1.4,0.7,3.1,0.7,4.7
		c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.3,0.4,0.5,0.6c1.6,1.2,3.1,2.5,4.5,4c1.9-0.4,3.8-0.9,5.8-1.3c0.2,0,0.3-0.1,0.5,0
		c0.1,0,0.2,0.1,0.3,0.2c1.2,0.9,2.4,1.9,3.5,2.9c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.4,0.1,0.6,0.1c0.6,0,1.9,0,1.9,0l13.8-4.3
		c1.5-0.5,1.9-0.7,3.1-1.6c0.3-0.2,0.5-0.4,0.7-0.7c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9,0-1.9,0.1-2.8c0-0.1,0-0.2,0.1-0.3
		c0.1-0.1,0.2-0.1,0.4-0.1c1-0.1,2.1-0.1,3.1-0.2c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0.6-1.1,1.2-2.2,1.7-3.4
		c-0.7-0.2-0.7-1.4-1.3-1.8c-0.3-0.2-0.8-0.2-1.1-0.5c-0.4-0.3-0.4-0.9-0.5-1.4c0-0.6,0-1.3,0-1.9c1.1,0.2,2.1,0.3,3.2,0.5
		c0.2,0,0.4,0.1,0.6,0.2c0.4,0.3,0.4,0.8,0.4,1.2c0,1.7,0.3,3.5,0.8,5.1c0.2,0,0.4,0.2,0.5,0.4c0.1,0.2,0,0.5,0,0.7
		c0,0.9,0.5,1.8,1.3,2.2c0.3,0.2,0.6,0.3,0.9,0.4c0.3,0.2,0.6,0.4,0.8,0.7c0.4,0.4,0.6,0.9,0.9,1.3c1,1.6,2.1,3.1,3.4,4.4
		c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0,0.2,0,0.4,0c0.9,0,1.7-0.1,2.6-0.1c0,0,0-0.1,0-0.1c0-0.3,0.1-0.8,0.1-1.1
		C456.6,359.2,456.4,358.7,456.4,358.5z"/>
	<path @click="openEmc" data-emc="altamaha-emc" ref="altamaha-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M384.6,390.5c-0.4-1.9-2.7-6.7-3.3-7c-0.4-0.3-0.9-0.4-1.2-0.7c-0.3-0.2-0.4-0.6-0.6-0.9
		c-1-1.8-2.2-3.5-3.4-5.1l0.4-2.6c-0.6-0.5-1.2-1-1.8-1.3c-1.2-0.6-2.4-0.9-3.2-2c0.2-0.6,0.4-1.3,0.7-1.9c0.1-0.2,0.2-0.5,0.2-0.7
		c0-0.3,0-0.5,0-0.8c-0.1-0.8-0.1-1.6-0.2-2.4c0-0.3,0-0.6-0.1-0.9c-0.1-0.3-0.3-0.6-0.5-0.9c-1.5-1.9-2.9-3.7-4.4-5.6
		c1.2-0.9,2.3-2,3.2-3.2c-1.5-2.4-2.8-5-3.9-7.7l2.3,0l4.8-3c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.3c-0.1-0.3-0.3-0.6-0.3-0.8
		c-0.1-0.3-0.4-0.9-0.5-1.2c0-0.3,0.2-0.8,0.1-1c-0.2-0.3-1.2-0.6-1.2-0.6c-0.4-0.1-0.6-0.7-0.9-1c-0.3-0.2-0.6-0.4-0.9-0.6
		c-0.8-0.6-1-1.7-1-2.7c0.4-0.4,1-0.8,1.5-1c0.2-0.1,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.3-0.5,0.8-1,0.8-1.6
		c0-0.9,0-1.8,0-2.8c-0.5,0-1,0-1.5-0.2c-0.5-0.2-0.9-0.7-0.8-1.2c0-0.3,0.2-0.5,0.3-0.8c0.1-0.4,0.1-0.9-0.1-1.3
		c-0.4-0.2-0.9-0.3-1.3-0.5c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c-0.6-1.7-1.4-3.3-2.5-4.6c0.9-0.9,2-1.6,3.2-2
		c0-0.2,0.1-0.3,0.1-0.5c-0.6-0.4-1.2-0.9-1.9-1.3c0-0.7-0.1-1.4-0.1-2c0.3-0.3,0.9-0.2,1.3,0.1c0.4,0.3,0.7,0.7,1.1,1
		c0.6,0.5,1.6,0.6,2.3,0.2v-6.3h-4.5l0.3-1.9l1.9-4.8h3.2l2.2,2.5h6.6v-3.3l-3.3-0.3l-2.3,1.2l-0.8-0.7l0.8-1.1v-6.6l-3.9-4.1h-1.8
		l-4.3,2.5h-5.1v1.2c0,0,2.3,4.2,2.8,5.9c0.5,1.7,0.3,2.1-0.8,4.5s-3.5,5.8-4.8,7.4c-0.7,0.8-1.2,1.4-1.8,2.1c-0.5,0.6-1,1.2-1.7,2
		c-1.5,1.7-3.5,2.3-3.5,2.3V315c0,0-3.1,0.9-4.8,1.9c-1.6,1-2.3,2-2.2,2.6c0,0.3,0.3,0.9,0.5,1.5c0,0.1,0.1,0.2,0.1,0.4
		c0.2,0.5,0.4,1,0.4,1l-1.8,1.3c0,0-0.8-1.2-1.6-2.3c-0.7-0.9-1.4-1.8-1.8-2.1c-1-0.7-3.1-0.7-4.1-0.8c-1-0.1-3.1,0.1-3.8-0.9
		c-0.6-1-1.1-4.3-1.1-4.3l-1-0.2l-1.6-0.3c0,0-0.9-4-2.9-5.1c-1.9-1.1-4.6-1.6-4.6-1.6l-3.2,2.4l-0.7,2.1l-3.8,1.1l-4.4,2.9
		l-0.8,0.7v2.5c0,0,4,3.6,4.6,5.4c0.5,1.8,1.9,5.3,2,7.5c0.1,2.2-0.1,4.6,0.6,5.5c0.8,0.9,2.3,0.7,3.8,1.1c1.4,0.4,2.6,0.5,4.9,2.4
		c0,0.4,0,1.1-0.2,1.4c-0.2,0.3-1.2,0.5-1.3,0.9c-0.1,0.4,0.7,1.1,0.9,1.5c0.2,0.4,0.6,1.2,0.9,1.4c0,0,0.1,0,0.1,0
		c0.3,0.1,0.8,0,1,0.1c0.2,0,0.6,0.2,0.8,0.3c0.3,0.2,0.6,1,1,1.1c0.3,0.1,0.9-0.1,1.2,0c0.4,0.1,1.5,0.6,1.5,0.7
		c0,0.1,0.3-1.3,0.7-1.5c0.2-0.1,0.5,0.1,0.8,0.3c0.2,0.2,0.5,0.4,0.6,0.5c0.2,0.2,0.5,0.5,0.5,0.7c0.1,0.3-0.3,1-0.1,1.3
		c0.1,0.2,0.6,0.4,0.7,0.6c0.1,0.2,0.3,0.8,0.3,1c0,0.1-0.5,0.8-0.6,1.1c-0.2,0.4-0.9,1.2-0.7,1.7c0.1,0.2,0.7,0.3,0.8,0.5
		c0.3,0.3,0.8,0.9,0.9,1.3c0.1,0.3-0.4,0.9-0.3,1.2c0,0.2,0.5,0.5,0.6,0.7c0.1,0.2,0.3,0.7,0.3,1c0,0.2-0.1,0.7-0.1,0.9
		c-0.1,0.3-0.5,1-0.5,1.4c0,0.5,0.4,1.6,0.7,2c0.2,0.2,0.8,0.5,1,0.7c0.2,0.3,0.4,1,0.4,1.4c0,0.3-0.5,0.6-0.6,0.9
		c-0.1,0.2-0.2,0.8-0.4,1c-0.2,0.3-0.9,0.5-0.9,0.8c0,0.3,0.5,0.9,0.5,1c0,0.1,0.8,0.7,0.9,1.1c0.1,0.3-0.1,0.9,0,1.1
		c0.1,0.4,0.7,0.9,0.7,1.3c0,0.3-0.2,1-0.4,1.2c-0.1,0.2-0.6,0.4-0.6,0.7c0,0.4,1,0.9,1.1,1c0,0.1-0.2,1-0.2,1.4
		c0,0.2,0.1,0.7,0.2,1c0.2,0.3,0.8,0.5,0.9,0.8c0.1,0.4-0.5,1.1-0.4,1.4c0.1,0.2,0.7,0.4,0.7,0.6c0.1,0.2-0.3,0.6-0.3,0.8
		c0,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.7,0.4,0.9c0.3,0.3,1,0.6,1.4,0.8c0.2,0.1,0.5,0.3,0.6,0.4c0.2,0.2,0.4,0.7,0.7,0.9
		c0.2,0.1,0.7,0.1,0.9,0.2c0.2,0.2,0.4,0.7,0.6,0.9c0.2,0.1,0.6,0.2,0.7,0.4c0.3,0.3,0.2,1.3,0.3,1.7c0.1,0.3,0.5,0.7,0.7,1
		c0.1,0,0.2-0.1,0.2-0.1c0.3-0.1,0.9-0.2,1.1-0.1c0.3,0.1,0.9,0.6,1.2,0.8c0.3,0.2,1,0.6,1.3,0.6c0.3,0,0.9-0.3,1.1-0.5
		c0.2-0.2,0.4-0.7,0.3-0.9c-0.2-0.4-1.4-0.3-1.4-0.7c0-0.2,0.6-0.5,0.8-0.6c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.5-0.1,0.7-0.1
		c0.4,0.1,1.5,0.8,1.6,0.8c0.1,0,0.6,0.4,0.8,0.4c0.4,0,1.1-0.6,1.5-0.7c0.2-0.1,0.6-0.2,0.8-0.2c0.1,0,0.2,0,0.3,0
		c0.4,0.2,0.6,1.1,0.9,1.4c0.3,0.3,1.2,0.8,1.6,0.7c0.2-0.1,0.3-0.5,0.4-0.6c0.2-0.1,0.8-0.2,1-0.1c0.3,0.1,0.9,0.5,1,0.8
		c0.1,0.2-0.2,0.7-0.1,0.8c0.1,0.2,0.6,0.5,0.8,0.5c0.3,0,0.7-0.5,1-0.5c0.3,0.1,0.7,0.8,0.8,0.8c0.1,0,0.8,0,1.1,0
		c0.4,0.1,1.1,0.4,1.5,0.5c0.2,0.1,0.7,0.5,1,0.5c0.3,0,0.8-0.2,1.1-0.3c0.2,0,0.8-0.1,1-0.1c0.1,0,0.4,0,0.5-0.1
		c0.2-0.1,0.3-0.7,0.4-0.9c0.1-0.1,0.3-0.2,0.4-0.2c0.3,0,0.7,0.7,1,0.8c0.2,0.1,0.6,0,0.8,0.1c0.2,0.1,0.3,0.8,0.6,0.9
		c0.2,0.1,0.6-0.2,0.8-0.1c0.2,0.1,0.4,0.4,0.5,0.6c0.1,0.3,0.1,1.1,0.1,1.2c0,0.1,1-0.4,1.3-0.4c0.1,0,0.2,0,0.4,0.1
		c0.3,0.1,0.7,0.3,0.9,0.4c0.3,0.1,0.7,0.5,1,0.6c0.3,0.1,0.9-0.1,1.2,0c0.3,0.1,0.8,0.7,1.1,0.9c0.2,0.1,0.6,0.3,0.8,0.3
		c0.1,0,0.3-0.2,0.5-0.3c0.3-0.1,0.8-0.3,1.1-0.4c0.3,0,1,0.1,1.3,0.2c0.2,0.1,0.5,0.5,0.7,0.6c0.2,0.1,0.8,0.2,1,0.3
		c0.3,0.2,0.6,1,0.9,1.2c0.1,0,0.2,0,0.3,0c0.1-0.5,0.2-0.9,0.2-1.4c0.1-0.5,0.2-1,0.2-1.6C385,392.4,384.8,391.4,384.6,390.5z"/>
		<path @click="openEmc" data-emc="canoochee-emc" ref="canoochee-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M442,423.9c0,0-0.7-0.2-2.7-0.7c-2-0.5-4.4-1.4-4.4-1.4v-8.2c0.2-0.4,0.5-0.9,0.9-1.4c1.1-1.5,3-3.3,5.7-4.6
			c3.6-1.8,7.5-4.2,7.5-4.2l3.9-3l-0.8-2.7l5.1-3.1l0.5-2.3l0.2-0.1l0,0l0,0l0.1-0.1c0,0,0,0,0,0c0.6-0.3,1.2-0.5,1.8-0.9
			c0.6,0.5,1.2,1,1.7,1.6c0.3,0.3,0.7,0.6,1.1,0.8c0.3,0.1,0.7,0.2,1,0.2c0.7,0.1,1.4,0.2,2.1,0.3c0.2,0,0.3,0,0.5,0
			c0.3,0,0.5-0.2,0.7-0.3c0.4-0.2,0.8-0.5,1.2-0.8c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.2,0.2-0.4,0.3-0.6c0.3-1.1,0.7-2.2,1-3.2
			c-0.1-0.1-0.3-0.2-0.3-0.3c-0.2-0.3-0.6-1.1-0.4-1.4c0.1-0.1,0.3-0.2,0.5-0.3c0-0.2,0.1-0.5,0.1-0.7c0-0.4-0.1-1.2-0.3-1.5
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.6-0.4-1.2-0.4-1.9c0-0.6,0-1.2,0.3-1.7c0.2-0.4,0.6-0.6,1-0.9c0-0.7,0-1.4,0-2c-1-0.1-2,0-3,0
			c-0.3-0.6-1.1-0.9-1.5-1.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2-1,0.5-2.1,1.4-2.3c-0.3-1.6-0.7-3.1-1-4.7c-0.4,0.1-0.6-0.4-1-0.5
			c-0.4-0.1-0.7,0.2-0.9,0.5c-0.4,0.5-0.9,1.1-1.2,1.7c-0.3,0.6-0.4,1.3-0.3,2c-0.5,0.1-0.9,0.2-1.4,0.3c-0.3-0.1-0.5-0.2-0.7-0.3
			c-0.3-0.2-0.8-0.8-0.9-1.1c0-0.2,0.2-0.7,0.2-0.9c0-0.2,0-0.6,0-0.8c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.2-0.9-0.1-1.2-0.2
			c-0.4-0.3-1-1.2-1-1.6c0,0,0,0,0,0c-0.3-0.3-0.6-0.7-0.9-0.9c-0.6-0.4-1.1-0.3-1.4-1c-0.3-0.5-0.6-1-0.8-1.5
			c-0.1-0.3-0.2-0.6-0.3-0.9c-0.2-0.4-0.4-0.8-0.6-1.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.8-0.6-1.6-1.2-2.4-1.7
			c-0.4-0.3-0.6-0.5-1-0.7c-0.6,0.1-1.3,0.3-1.9,0.4c-0.3,0.9-0.6,1.9-0.9,2.8c-0.4-0.1-0.8,0.1-1.2,0.1c-0.8,0-1.4-0.7-2.1-1.2
			c-0.8-0.7-1.8-1.2-2.8-1.4c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0.1-0.4,0.1c-0.6,0.3-1.2,0.5-1.8,0.8c-0.4,0.2-0.8,0.3-1.2,0.4
			c-0.3,0.1-0.6,0-0.9,0.1c-0.8,0-1.5,0.1-2.3,0.3c0-0.7,0-1.4,0-2.1l-13.8,4.3c-0.6,0-1.3,0-1.9,0c-0.2,0-0.4,0-0.6-0.1
			c-0.2-0.1-0.3-0.2-0.4-0.3c-1.1-1-2.3-2-3.5-2.9c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2,0-0.3,0-0.5,0c-1.9,0.4-3.8,0.9-5.8,1.3
			c-1.4-1.5-2.9-2.8-4.5-4c-0.2-0.2-0.4-0.3-0.5-0.6c-0.1-0.2-0.1-0.4-0.1-0.6c0.1-1.6,0.1-3.3-0.7-4.7c0-0.1-0.1-0.2-0.2-0.2
			c-0.1-0.1-0.2-0.1-0.4-0.1c-0.6-0.1-1.1-0.3-1.6-0.4c-0.5-0.1-1,0-1.5,0c-0.5,0.1-1.1,0.1-1.6,0.2c-0.5,0.1-1,0.1-1.6,0.2
			c-0.7,0.1-1.4,0.2-2.1,0.4c-1.2,0.4-2.3,1.2-3.5,1.3c-0.8-0.5-1.3-1.4-1.9-2.2c-0.9-1.1-1.8-2.3-2.4-3.5c-0.1-0.2-0.2-0.4-0.4-0.5
			c-0.1-0.1-0.3-0.1-0.5-0.1c-1.3-0.2-2.5-0.4-3.8-0.6c-0.8-0.7-1.7-1.5-2.6-2.1l-3.1-0.4l-4.8,3l-2.3,0c1.1,2.7,2.4,5.3,3.9,7.7
			c-0.9,1.2-2,2.3-3.2,3.2c1.5,1.9,2.9,3.7,4.4,5.6c0.2,0.3,0.4,0.5,0.5,0.9c0.1,0.3,0.1,0.6,0.1,0.9c0.1,0.8,0.1,1.6,0.2,2.4
			c0,0.3,0,0.5,0,0.8c0,0.2-0.1,0.5-0.2,0.7c-0.2,0.6-0.4,1.3-0.7,1.9c0.8,1.1,2,1.4,3.2,2c0.7,0.4,1.3,0.8,1.8,1.3l-0.4,2.6
			c1.2,1.6,2.4,3.3,3.4,5.1c0.2,0.3,0.4,0.6,0.6,0.9c0.3,0.3,0.8,0.5,1.2,0.7c0.5,0.4,2.9,5.1,3.3,7c0.2,0.9,0.3,1.9,0.3,2.8
			c0,0.5-0.1,1-0.2,1.6c-0.1,0.5-0.2,0.9-0.2,1.4c0.4,0,1-0.3,1.3-0.2c0.3,0.1,0.7,0.5,0.8,0.7c0.1,0.4-0.5,1-0.6,1.4
			c0,0.3-0.1,0.8,0.1,1c0.2,0.2,1,0.3,1,0.3c0.1,0,0.6,0.7,0.7,0.8c0.2,0.1,0.9,0.6,1,0.6c0.1,0,0.6,0.7,0.8,0.9
			c0.3,0.2,1.3,0.5,1.4,0.5c0.1,0,0.9,0.6,1.1,0.8c0,0,0,0,0,0c0.2,0.2,0.5,0.8,0.8,1c0.2,0.1,0.7,0,0.9,0.1
			c0.2,0.1,0.5,0.5,0.8,0.5c0.2,0,0.3-0.5,0.5-0.5c0.3,0,0.7,0.3,0.9,0.5c0.2,0.2,0.4,0.8,0.5,1c0.1,0.2,0,0.8,0.1,1
			c0.1,0.2,0.6,0.2,0.7,0.3c0.2,0.1,0.3,0.6,0.4,0.7c0.2,0.1,0.8,0,1,0.2c0.2,0.2,0.6,0.9,0.7,0.9c0,0,0.1,0,0.1,0
			c0.2,0,0.7,0.1,0.9,0c0.2-0.1,0.2-0.7,0.4-0.9c0.2-0.1,0.7-0.3,0.9-0.2c0.4,0.2,0.5,1.3,0.7,1.8c0.1,0.4,0,1.3,0.3,1.6
			c0.2,0.2,0.7,0.3,1,0.4c0.3,0.1,0.8,0.6,1.1,0.7c0.2,0,0.5-0.3,0.7-0.3c0.2,0,0.6,0.2,0.8,0.4c0.1,0.2,0.2,0.9,0.2,0.9
			c0,0.1-0.4,0.4-0.4,0.6c0,0.2,0.2,0.6,0.3,0.7c0.1,0.4,0.3,1.2,0.5,1.5c0.2,0.3,1,0.4,1.3,0.6c0.2,0.1,0.7,0.2,1,0.4
			c0.3,0.1,0.9,0.4,1.1,0.6c0.2,0.4,0.1,1.4,0.3,1.8c0.1,0.2,0.5,0.6,0.8,0.8c0.2,0.1,0.7,0.3,0.8,0.4c0.2,0.2,0.5,0.7,0.5,1
			c0.1,0.2-0.2,0.8,0,1c0.1,0.2,0.5,0.3,0.7,0.3c0.2,0.1,0.8,0.2,0.9,0.3c0.2,0.1,0.3,0.6,0.4,0.8c0.2,0.2,0.5,0.7,0.7,0.7
			c0.3,0.1,1-0.2,1.3-0.2c0.2,0,0.7,0.1,0.9,0.2c0.2,0.1,0.5,0.5,0.7,0.6c0.2,0.1,0.6,0.3,0.7,0.4c0.1,0.2-0.2,0.8-0.1,1.1
			c0.1,0.1,0.3,0.3,0.5,0.4c0.3,0.1,0.9,0.1,1.1,0.3c0.2,0.2,0.3,1,0.5,1.2c0.1,0.1,0.5-0.1,0.6,0c0.3,0.1,0.9,0.4,1.2,0.6
			c0.2,0.2,0.3,0.9,0.5,1.1c0.2,0.1,0.7,0,0.8,0.1c0.3,0.1,0.7,0.6,0.8,0.8c0.1,0.2,0.2,0.7,0.3,0.8c0.2,0.2,0.8,0.1,1,0.2
			c0.2,0.1,0.7,0.4,0.9,0.5c0.3,0.2,1,0.6,1.1,1c0.1,0.4-0.2,1.1-0.2,1.5c0,0.2-0.2,0.8-0.1,1c0.1,0.2,0.5,0.4,0.7,0.5
			c0.2,0.1,0.6,0.4,0.8,0.5c0.1,0.1,0.3,0.6,0.5,0.7c0.3,0.1,0.7-0.5,1-0.4c0.2,0.1,0.4,0.4,0.4,0.6l10.1-4.8c0,0,0.1-4.5,0.5-5.9
			C442.2,426.2,442,423.9,442,423.9z"/>
	<g id="coastal-emc">
        <path @click="openEmc" data-emc="coastal-emc" ref="coastal-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M470.5,436.5c0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.5,0.5-0.7,0.6c-0.1,0.1-0.3,0.5-0.4,0.6
				c-0.2,0.1-0.5,0.4-0.7,0.4c-0.1,0-0.2-0.4-0.3-0.4c-0.1-0.1-0.5-0.1-0.6-0.1c-0.1-0.1-0.3-0.2-0.4-0.4c0-0.1,0.1-0.4,0.2-0.5
            c0.2-0.2,0.8,0,1.1-0.2c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.1,0.6-0.4,0.8-0.4C469.9,436.3,470.4,436.3,470.5,436.5z"/>        
        <path @click="openEmc" data-emc="coastal-emc" ref="coastal-emc-1" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M477.9,422.1c0.1,0.2-0.2,0.5-0.3,0.6c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.8-0.3-1.1-0.3
            c-0.2,0-0.7,0.1-0.9,0.2c-0.2,0.1-0.7,0.3-0.9,0.4c-0.3,0-0.9,0.1-1.2-0.1c-0.1-0.1-0.4-0.3-0.4-0.5c0.1-0.3,0.9-0.2,1.1-0.4
            c0.1-0.1,0.1-0.4,0.2-0.5c0.2-0.2,0.8-0.6,1.1-0.5c0.1,0,0.3,0.3,0.4,0.4c0.2,0.1,0.7,0,0.9,0.1c0.1,0,0.4,0.1,0.6,0.2
            C477.4,421.9,477.8,422,477.9,422.1z"/>
		<path @click="openEmc" data-emc="coastal-emc" ref="coastal-emc-2" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M485.7,427.6c-0.1-0.2-0.5-0.6-0.7-0.7c-0.1-0.1-0.4,0-0.5-0.1c-0.2-0.1-0.4-0.6-0.3-0.8
			c0-0.1,0.4-0.3,0.5-0.3c0.2-0.1,0.7,0,0.8-0.2c0.2-0.2,0.3-0.7,0.3-0.9c0-0.5-0.1-1.4-0.2-1.8c-0.1-0.2-0.3-0.6-0.4-0.8
			c-0.1-0.4-0.1-1.1,0-1.4c0-0.3,0.3-0.8,0.3-1.1c0-0.2-0.2-0.6-0.4-0.7c-0.1-0.1-0.5,0-0.7,0c-0.3,0-1,0.1-1.3,0.3
			c-0.2,0.1-0.2,0.6-0.4,0.7c-0.2,0.1-0.7,0.1-0.9,0.1c-0.2,0-0.6,0.1-0.8,0.3c-0.2,0.1-0.4,0.6-0.6,0.7c-0.2,0.1-0.7,0.1-0.8,0.3
			c-0.1,0.1-0.3,0.5-0.4,0.7c0,0.1,0,0.3,0.1,0.4c0.2,0.2,0.7,0.1,0.9,0.1c0.1,0,0.4-0.1,0.5-0.1c0.2,0,0.5,0,0.6,0
			c0.1,0,0.3,0.1,0.3,0.2c0.1,0.1,0,0.4-0.1,0.5c-0.2,0.3-0.9,0.7-1.2,0.7c-0.2,0-0.6-0.5-0.8-0.5c-0.2,0-0.5,0.2-0.7,0.3
			c-0.2,0.1-0.6,0.5-0.8,0.6c-0.4,0.1-1.1,0.1-1.5,0c-0.2,0-0.6-0.4-0.8-0.4c-0.3-0.1-1.1,0.2-1.4,0.2c-0.3,0-1,0.1-1.3,0
			c-0.2-0.1-0.7-0.4-0.9-0.5c-0.2-0.2-0.6-0.5-0.6-0.8c0-0.3,0.3-0.8,0.5-1c0.1-0.1,0.4-0.4,0.6-0.4c0.3-0.1,0.9,0.1,1.2,0
			c0.1-0.1,0.2-0.5,0.4-0.6c0.2-0.2,0.7-0.4,0.9-0.4c0.4,0,1.1,0.2,1.5,0.3c0.2,0.1,0.4,0.5,0.6,0.5c0.2,0.1,0.5,0.1,0.7,0
			c0.3-0.1,0.8-0.3,1-0.6c0.1-0.2,0.1-0.7,0.2-0.9c0.1-0.2,0.5-0.4,0.7-0.5c0.2-0.2,0.4-0.7,0.4-0.9c0-0.4-0.5-0.9-0.8-1.2
			c-0.4-0.3-1.3-0.7-1.7-1c-0.3-0.2-0.9-0.4-1.1-0.7c-0.2-0.3-0.2-1-0.4-1.3c-0.2-0.2-0.6-0.5-0.9-0.6c-0.3-0.2-1.1-0.4-1.4-0.5
			c-0.3-0.1-1,0.1-1.3,0c-0.4-0.1-1.1-0.5-1.4-0.8c-0.1-0.2-0.3-0.6-0.3-0.8c-0.1-0.3-0.1-0.9,0-1.2c0.1-0.3,0.5-0.9,0.5-1.2
			c0-0.2-0.4-0.7-0.6-0.8c-0.2-0.2-0.8-0.3-1.1-0.3c-0.2,0-0.5,0.2-0.7,0.3c-0.3,0-1-0.1-1.4-0.2c-0.2-0.1-0.6-0.2-0.7-0.4
			c-0.2-0.2-0.6-0.9-0.7-1.2c-0.1-0.2-0.1-0.6-0.1-0.8c0-0.2,0-0.6,0.1-0.8c0.1-0.1,0.6-0.3,0.8-0.2c0,0,0.1,0,0.1,0
			c0.1,0.1,0.2,0.4,0.2,0.5c0,0.1-0.1,0.4,0,0.6c0,0.3,0.1,1.1,0.3,1.3c0.2,0.1,0.6,0.1,0.8,0.1c0.3,0,0.8-0.2,1.1-0.2
			c0.4,0,1.2-0.1,1.5,0.1c0.2,0.1,0.6,0.5,0.8,0.7c0.2,0.2,0.6,0.8,0.6,1.1c0,0.3-0.2,0.8-0.2,1.1c0,0.4,0.3,1,0.4,1.3
			c0.1,0.2,0.5,0.6,0.8,0.7c0.3,0.1,1,0,1.4,0.1c0.4,0.1,1.1,0.5,1.5,0.7c0.3,0.2,0.9,0.8,1.2,1.1c0.4,0.3,1.1,0.8,1.6,0.9
			c0.4,0.1,1.3,0.1,1.7,0.3c0.2,0.1,0.3,0.4,0.5,0.4c0.2,0,0.7-0.2,0.9-0.4c0.2-0.3,0.2-1,0.2-1.3c0-0.3-0.2-0.8-0.4-0.9
			c-0.2-0.2-1.1-0.2-1.2-0.5c-0.1-0.3,0.2-0.8,0.2-1.1c0-0.3-0.2-0.9-0.1-1.2c0-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.4-0.7,0.3-0.9
			c-0.1-0.2-0.7-0.3-0.8-0.5c-0.1-0.2,0-0.6,0-0.8c0-0.2,0-0.6,0.2-0.6c0.1,0,0.1,0,0.2,0c-0.1-0.2-0.1-0.4-0.1-0.5
			c0-0.3,0.2-1,0.4-1.3c0.2-0.2,0.7-0.3,0.8-0.5c0.2-0.2,0.5-0.7,0.5-1c0-0.2-0.1-0.7,0-0.9c0.1-0.3,0.6-0.9,0.9-1.1
			c0.1-0.1,0.5-0.2,0.7-0.3c0.1-0.1,0.3-0.2,0.5-0.4c-0.3-0.2-0.7-0.5-0.9-0.7c-0.4-0.3-1.3-1-1.6-1.4c-0.2-0.4-0.3-1.2-0.4-1.7
			c-0.1-0.4-0.3-1.3-0.6-1.6c-0.2-0.2-0.8-0.4-1.1-0.4c-0.3,0-0.9,0-1.1,0.1c-0.2,0.1-0.6,0.2-0.7,0.3c-0.2,0.1-0.5,0.5-0.7,0.6
			c-0.2,0-0.8-0.1-0.9-0.3c-0.1-0.2,0.2-0.5,0.2-0.7c0-0.3-0.3-0.8-0.5-0.9c-0.2,0-0.4,0.5-0.6,0.7c-0.2,0.2-0.4,0.8-0.7,0.9
			c-0.3,0.1-1.1-0.1-1.1-0.2c0-0.1-0.2-0.8-0.2-1.1c0-0.3,0.2-0.9,0-1.1c-0.1-0.1-0.5,0.1-0.7,0.1c-0.4,0-1.2,0.3-1.5,0.1
			c-0.3-0.2-0.4-1-0.4-1.4c0-0.2,0.2-0.7,0.1-0.9c-0.1-0.2-0.6-0.5-0.7-0.7c-0.1-0.2-0.1-0.7-0.3-0.9c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.3,1.1-0.7,2.2-1,3.2c-0.1,0.2-0.1,0.4-0.3,0.6c-0.1,0.1-0.2,0.1-0.2,0.2c-0.4,0.3-0.8,0.5-1.2,0.8c-0.2,0.1-0.5,0.3-0.7,0.3
			c-0.2,0-0.3,0-0.5,0c-0.7-0.1-1.4-0.2-2.1-0.3c-0.3,0-0.7-0.1-1-0.2c-0.4-0.2-0.8-0.5-1.1-0.8c-0.6-0.6-1.1-1.1-1.7-1.6
			c-0.6,0.4-1.2,0.6-1.8,0.9c0,0-0.1,0-0.1,0.1l-0.2,0.1l-0.5,2.3l-5.1,3.1l0.8,2.7l-3.9,3c0,0-3.9,2.4-7.5,4.2
			c-2.7,1.3-4.5,3.1-5.7,4.6c-0.4,0.5-0.7,1-0.9,1.4v8.2c0,0,2.4,0.8,4.4,1.4c2,0.5,2.7,0.7,2.7,0.7s0.2,2.3-0.2,3.7
			c-0.4,1.4-0.5,5.9-0.5,5.9l-10.1,4.8c0,0,0,0,0,0c0,0.2-0.3,0.7-0.2,1c0,0.2,0.3,0.5,0.5,0.6c0.2,0.1,0.9-0.2,1.1,0
			c0.2,0.2,0.1,0.9,0,1.1c-0.1,0.3-0.8,0.8-0.7,1.1c0,0.2,0.4,0.3,0.5,0.4c0.2,0.2,0.7,0.6,0.8,0.9c0.1,0.2-0.1,0.6,0,0.7
			c0.1,0.2,0.7,0.3,0.8,0.4c0.2,0.2,0.3,0.6,0.5,0.8c0.2,0.1,0.8-0.1,1.1,0.1c0.2,0.1,0.6,0.6,0.6,0.9c0.1,0.3,0.1,1,0.1,1.3
			c0,0.2-0.2,0.6-0.3,0.7c-0.1,0.2-0.4,0.4-0.5,0.5c0,0.1-0.1,0.3-0.1,0.5c0,0.1,0,0.1,0,0.2c0.2,0.3,1,0.3,1.1,0.5
			c0.2,0.3-0.1,1,0,1.3c0.1,0.2,0.4,0.6,0.6,0.8c0.2,0.2,0.8,0.4,0.9,0.6c0.2,0.2,0.3,0.9,0.6,1.1c0.1,0.1,0.5,0.3,0.6,0.4
			c0.2,0.2,0.4,0.9,0.6,1.1c0.2,0.2,0.9,0.4,1.2,0.6c0.3,0.2,0.8,0.8,1,1.1c0.2,0.2,0.4,0.8,0.6,1.1c0.2,0.2,0.8,0.5,1.1,0.6
			c0.3,0.1,0.8,0.1,1.1,0.2c0.3,0.2,0.5,0.9,0.7,1.2c0.2,0.2,0.5,0.7,0.8,0.8c0.1,0,0.2,0.1,0.4,0.1l4.7-5.6l3.2-4.7h3.4l2.2-2.3
			l0.3-2.3h-4.5v-3.4l-2.5-1.7v-3.3h7.7c0-0.2,0.2-0.5,0.3-0.5c0.1-0.1,0.5,0,0.6,0c0.1,0.1,0.2,0.5,0.3,0.5
			c0.1,0.1,0.5-0.1,0.6-0.2c0.1-0.1,0-0.3,0.1-0.4c0.1-0.1,0.6-0.1,0.7-0.2c0.2-0.1,0.5-0.3,0.7-0.3c0.2,0,0.7,0,0.9,0
			c0.2,0,0.5,0.4,0.7,0.3c0.1-0.1,0.1-0.5,0-0.6c0-0.2-0.3-0.5-0.3-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.7,0.4-0.9
			c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.3,0.4,0.5c0,0.2-0.2,0.6-0.3,0.7c0,0.2,0.1,0.6,0.2,0.8c0.1,0.1,0.2,0.5,0.4,0.5
			c0.3,0.1,0.8-0.3,1-0.5c0.1-0.1,0.1-0.4,0.2-0.5c0.1-0.1,0.5-0.2,0.7-0.3c0.3,0,1-0.1,1.3,0.1c0.2,0.1,0.3,0.5,0.4,0.7
			c0.2,0.2,0.4,0.9,0.7,0.9c0.1,0,0.4-0.2,0.4-0.3c0.1-0.2,0.1-0.7,0-0.9c-0.1-0.1-0.5-0.2-0.6-0.4c-0.1-0.2-0.1-0.7,0-0.9
			c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.4,0,0.5,0.1c0.2,0.1,0.4,0.4,0.5,0.6c0.1,0.2,0.1,0.7,0.2,0.9c0.1,0.2,0.4,0.5,0.7,0.6
			c0.1,0.1,0.5,0.1,0.6,0c0.1-0.1,0-0.5,0-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.1,0.6,0.1,0.8,0.2c0.3,0.1,0.7,0.6,1,0.5
			c0.4-0.1,0.8-0.8,1-1.1c0.2-0.3,0.4-0.9,0.6-1.2c0.1-0.2,0.4-0.7,0.5-0.9c0.1-0.3,0.3-1,0.2-1.3c-0.1-0.3-0.5-0.9-0.7-1.2
			c-0.2-0.2-0.8-0.5-1.1-0.6c-0.2-0.1-0.6-0.4-0.7-0.6c-0.1-0.2-0.3-0.7-0.4-0.9c-0.1-0.2-0.3-0.8-0.5-0.9c-0.2-0.1-0.6,0.1-0.7,0
			c-0.2-0.1-0.8-0.4-0.8-0.6c0-0.2,0.1-0.5,0.2-0.7c0-0.1,0.1-0.1,0.2-0.1c0.2-0.1,0.6,0,0.8,0.1c0.2,0.1,0.6,0.4,0.7,0.6
			c0.2,0.2,0.5,0.6,0.7,0.7c0.1,0.1,0.4,0.1,0.4,0.1c0.1,0.1,0,0.5,0.1,0.7c0.1,0.2,0.5,0.7,0.8,0.7c0.2,0,0.2-0.5,0.4-0.6
			c0.2-0.1,0.7,0.2,0.9,0.4c0.4,0.3,0.8,1.2,1,1.6c0.1,0.3,0.3,1,0.3,1.4c0,0.3-0.4,0.9-0.4,1.2c-0.1,0.3-0.1,0.9-0.1,1.2
			c0,0.3,0,1,0.2,1.3c0.1,0.3,0.4,0.9,0.7,1.1c0.2,0.1,0.7,0,0.9-0.2c0.3-0.2,0.4-0.9,0.6-1.2c0.2-0.3,0.6-0.9,0.8-1.3
			c0.1-0.4,0.1-1.2,0.2-1.5c0.2-0.4,0.8-1.1,1.1-1.4c0.2-0.4,0.5-1.2,0.8-1.6c0.1-0.2,0.5-0.5,0.5-0.7
			C485.6,428.2,485.7,427.8,485.7,427.6z"/>
	</g>
    <g id="okefenoke-remc" >
        <path @click="openEmc" data-emc="okefenoke-remc" ref="okefenoke-remc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M445.7,523c0,0.2,0,0.6-0.2,0.7c-0.1,0.1-0.5,0.1-0.6,0c-0.2-0.1-0.4-0.7-0.4-1c0-0.1,0.1-0.4,0.2-0.4
				c0.1-0.1,0.4,0.1,0.5,0.2C445.4,522.6,445.7,522.9,445.7,523z"/>
        <path @click="openEmc" data-emc="okefenoke-remc" ref="okefenoke-remc-1" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M448.8,527c0.1,0.3,0.1,1-0.1,1.3c-0.1,0.2-0.6,0.5-0.8,0.5c-0.4-0.1-1-0.9-1.1-1.3c-0.1-0.3-0.1-0.8,0-1.1
            c0.1-0.2,0.4-0.5,0.5-0.6c0.2-0.1,0.5-0.1,0.7-0.1c0.1,0,0.4,0.3,0.4,0.4C448.5,526.4,448.7,526.8,448.8,527z"/>
        <path @click="openEmc" data-emc="okefenoke-remc" ref="okefenoke-remc-2" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M458,511.3c0.3,0.5-0.5,1.7-0.6,2.3c-0.1,0.7-0.2,2.2-0.3,2.9c-0.1,0.7-0.3,2.1-0.4,2.8
            c-0.2,0.9-0.8,2.7-1.1,3.6c-0.3,0.8-1.1,2.3-1.5,3.1c-0.2,0.5-0.7,1.5-0.8,2c-0.1,0.4-0.1,1.1-0.2,1.5c-0.1,0.5-0.8,1.4-0.9,1.9
            c0,0.3,0.3,0.8,0.3,1.1c0,0.5-0.2,1.6-0.2,2.1c0,0.4,0.1,1.1,0.3,1.4c0.1,0.3,0.7,0.8,0.8,1.1c0.1,0.5,0.1,1.6-0.2,1.8
            c-0.2,0.1-0.7,0-0.9-0.1c-0.3-0.1-0.9-0.5-1.1-0.8c-0.1-0.2,0-0.6-0.1-0.8c-0.1-0.3-0.7-0.7-0.8-1c-0.1-0.3,0-0.9,0.1-1.2
            c0.1-0.3,0.5-0.8,0.6-1.1c0.1-0.4,0-1.2,0.1-1.6c0.1-0.3,0.4-0.7,0.4-1c0-0.3-0.7-0.8-0.8-1.1c-0.1-0.4,0-1.1,0-1.5
            c0-0.4,0.3-1,0.3-1.4c0-0.2-0.1-0.7-0.1-0.9c0-0.5,0.2-1.4,0.1-1.9c-0.1-0.2-0.4-0.6-0.4-0.8c-0.1-0.3-0.1-0.8-0.1-1.1
            c0-0.4,0-1.2-0.2-1.5c-0.3-0.4-1.3-0.8-1.7-1.2c-0.3-0.3-0.8-1-0.8-1.4c0-0.2,0.6-0.4,0.7-0.6c0.2-0.3,0.3-1.1,0.6-1.4
            c0.2-0.2,0.7-0.4,1-0.4c0.2,0,0.6,0.5,0.8,0.4c0.2-0.1,0.3-0.7,0.5-0.7c0.3-0.1,0.8,0.2,1,0.4c0.2,0.1,0.5,0.4,0.6,0.7
            c0,0.3-0.6,0.6-0.6,0.8c0.1,0.3,0.7,0.5,1,0.4c0.1,0,0.3-0.2,0.4-0.3c0.1-0.3,0.1-1,0-1.3c-0.1-0.2-0.4-0.6-0.6-0.8
            c-0.2-0.2-0.9-0.2-1.1-0.4c-0.1-0.2-0.1-0.7-0.1-0.9c0-0.3,0.3-0.8,0.4-1c0.3-0.3,1.1-0.7,1.5-0.9c0.2-0.1,0.6-0.3,0.8-0.4
            c0.2-0.2,0.4-0.9,0.5-1.2c0.2-0.5,0.4-1.5,0.5-2c0.1-0.4,0.1-1.1,0.3-1.4c0.1-0.2,0.5-0.5,0.7-0.4c0.3,0.1,0.8,0.9,0.8,1
            c0,0.1,0,1.1,0,1.4c0,0.4-0.1,1.6-0.1,1.7C457.4,511.2,457.9,511.2,458,511.3z"/>
        <path @click="openEmc" data-emc="okefenoke-remc" ref="okefenoke-remc-3" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M442.7,506.2c0,0.2-0.4,0.3-0.5,0.4c-0.1,0.2-0.1,0.6-0.2,0.7c-0.2,0.2-0.6,0.4-0.9,0.4
            c-0.3,0-0.9-0.4-1-0.7c-0.1-0.2,0.1-0.5,0.2-0.6c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.6-0.4,0.8-0.5c0.1,0,0.4-0.1,0.5,0
            C442.2,506,442.7,506,442.7,506.2z"/>
        <path @click="openEmc" data-emc="okefenoke-remc" ref="okefenoke-remc-4" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M449.7,508c0,0.2-0.4,0.6-0.6,0.7c-0.1,0-0.4,0-0.6-0.1c-0.2-0.1-0.5-0.2-0.5-0.4c0-0.2,0.1-0.5,0.2-0.6
            c0.1-0.1,0.4-0.2,0.6-0.2c0.1,0,0.4,0.1,0.5,0.1C449.5,507.6,449.7,507.8,449.7,508z"/>
        <path @click="openEmc" data-emc="okefenoke-remc" ref="okefenoke-remc-5" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M452.8,500.6c0.1,0.2,0.1,0.8-0.1,0.9c-0.1,0.1-0.5-0.1-0.6,0c-0.2,0.1-0.3,0.5-0.5,0.6
            c-0.2,0.1-0.6,0.1-0.8,0c-0.1-0.1-0.4-0.3-0.4-0.4c-0.1-0.3,0.1-0.8,0.3-1c0.1-0.1,0.3-0.3,0.4-0.4c0.3-0.1,0.8,0.1,1.1,0.1
            C452.3,500.5,452.7,500.5,452.8,500.6z"/>
	<path @click="openEmc" data-emc="okefenoke-remc" ref="okefenoke-remc-6" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M452.8,509.2c-0.2-0.4-0.7-1.1-1.1-1.4c-0.2-0.1-0.6-0.3-0.8-0.3c-0.2,0.1-0.3,0.7-0.5,0.9
		c-0.1,0.2-0.4,0.7-0.7,0.9c-0.2,0.1-0.6,0-0.8,0c-0.3,0-1.1,0.1-1.2,0.1s-0.8-0.5-1.1-0.6c-0.2-0.1-0.7,0-0.8-0.1
		c-0.2-0.2,0-0.9-0.1-1.1c-0.1-0.2-0.4-0.4-0.6-0.4c-0.2-0.1-0.8-0.2-1-0.1c-0.2,0.1-0.6,0.5-0.8,0.6c-0.2,0.2-0.4,0.8-0.7,0.9
		c-0.2,0.1-0.6-0.2-0.8-0.2c-0.3,0-0.9,0.3-1.2,0.3c-0.3,0-0.8-0.2-1-0.3c-0.3-0.1-0.6-0.7-0.9-0.8c-0.3-0.1-1,0.1-1.4,0
		c-0.2-0.1-0.5-0.4-0.7-0.5c-0.2-0.2-0.4-0.8-0.6-0.9c-0.2-0.1-0.6-0.4-0.8-0.4c-0.2-0.1-0.7,0-1,0c-0.2,0-0.6-0.1-0.6-0.2
		c0-0.2,0.5-0.5,0.7-0.6c0.2-0.1,0.7-0.1,1-0.1c0.2,0,0.6,0.2,0.8,0.3c0.2,0,0.5,0,0.7,0c0.2,0.1,0.5,0.5,0.7,0.7
		c0.1,0.1,0.4,0.4,0.5,0.4c0.3,0.1,0.9,0.1,1.2,0c0.3-0.1,0.6-0.5,0.9-0.6c0.3-0.1,0.9,0,1.1-0.1c0.2-0.1,0.4-0.5,0.6-0.6
		c0.3-0.1,0.7,0.3,1,0.3c0.2,0,0.5-0.2,0.7-0.2c0.4-0.1,1.1-0.4,1.4-0.3c0.3,0.1,0.8,0.6,1,0.9c0.2,0.2,0.3,0.7,0.5,0.9
		c0.2,0.2,0.6,0.5,0.8,0.6c0.3,0,0.9-0.1,1-0.3c0.1-0.2-0.2-0.7-0.2-0.9c0-0.3,0.3-1,0.5-0.9c0.3,0,0.2,1,0.5,1.1
		c0.2,0,0.5-0.4,0.6-0.5c0.2-0.2,0.5-0.9,0.8-1c0.2-0.1,0.7,0.2,0.9,0.1c0.2-0.1,0.3-0.6,0.4-0.8c0.1-0.3,0.4-0.9,0.2-1.2
		c-0.1-0.1-0.4,0-0.5,0.1c-0.2,0.1-0.3,0.5-0.4,0.5c-0.3,0.2-0.9,0.4-1.2,0.3c-0.3-0.1-0.8-0.6-0.8-0.9c-0.1-0.2,0.2-0.7,0.2-1
		c0-0.3,0-1.1-0.2-1.4c-0.2-0.3-1-0.6-1.3-0.9c-0.2-0.2-0.5-0.9-0.7-1.2c-0.2-0.2-0.6-0.5-0.8-0.6c-0.3-0.1-0.9-0.1-1.1-0.2
		c-0.2-0.1-0.5-0.4-0.6-0.6c-0.1-0.2-0.5-0.5-0.5-0.7c0-0.3,0.1-1,0.4-1.1c0,0,0.1,0,0.2,0c0-0.2-0.1-0.5-0.2-0.7
		c-0.1-0.2-0.5-0.7-0.8-0.7c-0.2,0-0.4,0.4-0.6,0.5c-0.2,0.1-0.8-0.1-1-0.3c-0.2-0.1-0.5-0.7-0.7-0.8c-0.1-0.1-0.4-0.2-0.5-0.3
		c-0.1-0.2-0.2-0.7-0.1-0.9c0.2-0.3,1.1-0.4,1.3-0.7c0.1-0.2,0-0.8,0-1c0-0.3,0-0.8-0.1-1c-0.1-0.3-0.5-0.8-0.8-0.8
		c-0.3,0-0.7,0.2-0.9,0.4c-0.2,0.2-0.3,0.8-0.5,1c-0.2,0.2-0.9,0.4-1.2,0.4c-0.2,0-0.7-0.1-0.9-0.1c-0.2,0-0.7,0.3-0.9,0.3
		c-0.1,0-0.2,0-0.3-0.1l1.4-1.9l4.1-2.9l0.3-5.2c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.3,0.2-0.9,0.1-1.2c-0.1-0.2-0.4-0.5-0.5-0.5
		c-0.2-0.1-0.8,0.1-1,0.2c-0.2,0.1-0.6,0.4-0.8,0.6c-0.3,0.2-0.7,0.8-1.1,0.8c-0.2,0-0.6-0.3-0.9-0.3c-0.3,0-0.8,0.2-1,0.1
		c-0.2-0.1-0.8-0.3-0.8-0.5c0-0.3,0.4-0.7,0.6-0.8c0.2-0.1,0.7,0.1,0.9,0.1c0.2,0,0.5-0.3,0.6-0.4c0.1-0.1,0.2-0.5,0.3-0.6
		c0.2-0.1,0.7-0.1,1-0.1c0.3-0.1,0.8-0.3,1.1-0.4c0.1,0,0.4,0.1,0.5,0.1c0.2,0,0.7-0.4,0.9-0.4c0.2,0,0.5,0.1,0.7,0.3
		c1.1-1.1,3.4-3.8,3.8-6c0.4-2.4,0.3-7.9,0.2-9.9c-0.2,0-0.3,0-0.4-0.1c-0.3-0.1-0.6-0.6-0.8-0.8c-0.2-0.3-0.4-1-0.7-1.2
		c-0.2-0.2-0.8-0.1-1.1-0.2c-0.3-0.1-0.8-0.4-1.1-0.6c-0.2-0.2-0.4-0.8-0.6-1.1c-0.2-0.3-0.7-0.9-1-1.1c-0.3-0.2-1-0.4-1.2-0.6
		c-0.2-0.2-0.4-0.9-0.6-1.1c-0.1-0.1-0.5-0.2-0.6-0.4c-0.2-0.2-0.4-0.9-0.6-1.1c-0.2-0.2-0.7-0.4-0.9-0.6c-0.2-0.2-0.5-0.6-0.6-0.8
		c-0.1-0.3,0.2-1.1,0-1.3c-0.2-0.3-1-0.3-1.1-0.5c0,0,0-0.1,0-0.2c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.4-0.4,0.5-0.5
		c0.1-0.2,0.3-0.5,0.3-0.7c0-0.3,0-1-0.1-1.3c-0.1-0.3-0.4-0.8-0.6-0.9c-0.2-0.1-0.8,0.1-1.1-0.1c-0.2-0.1-0.3-0.6-0.5-0.8
		c-0.2-0.2-0.7-0.2-0.8-0.4c-0.1-0.2,0.1-0.6,0-0.7c-0.1-0.3-0.6-0.7-0.8-0.9c-0.1-0.1-0.5-0.3-0.5-0.4c0-0.3,0.6-0.8,0.7-1.1
		c0.1-0.3,0.2-0.9,0-1.1c-0.2-0.2-0.8,0.1-1.1,0c-0.2-0.1-0.5-0.4-0.5-0.6c-0.1-0.2,0.3-0.7,0.2-1c0,0,0,0,0,0
		c0-0.2-0.3-0.6-0.4-0.6c-0.3-0.1-0.7,0.5-1,0.4c-0.2-0.1-0.3-0.5-0.5-0.7c-0.2-0.2-0.6-0.4-0.8-0.5c-0.2-0.1-0.6-0.3-0.7-0.5
		c-0.1-0.2,0.1-0.7,0.1-1c0-0.4,0.3-1.2,0.2-1.5c-0.1-0.3-0.8-0.8-1.1-1c-0.2-0.1-0.6-0.4-0.9-0.5c-0.2-0.1-0.8,0-1-0.2
		c-0.2-0.1-0.2-0.6-0.3-0.8c-0.1-0.1-0.2-0.3-0.4-0.5l-2.4,6.2v3.5l1.5,7l-3.9,8.6h-14.4c0,0-1.4,2.4-1.9,5c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.1,0.4-0.1,0.8-0.1,1.2c-0.2,3.1-0.6,4.7-0.6,4.7s-1.2,2.3-3.4,2.8c-2.2,0.5-4.4,0.6-4.4,0.6c-0.1,0-0.4,0-0.5,0
		c-0.3,0-0.9,0.1-1.2,0.1c-0.5,0.1-1.4,0.5-1.8,0.5c-0.5,0-1.3-0.4-1.8-0.5c-0.4-0.1-1.2-0.4-1.6-0.4c-0.4,0-1.3,0.4-1.6,0.7
		c-0.3,0.2-0.4,1.1-0.7,1.3c-0.5,0.4-1.9,0.5-2.3,1c-0.2,0.2-0.1,0.8-0.2,1.1c0,0.3,0.1,0.8,0,1.1c-0.1,0.3-0.7,0.8-1,1
		c-0.4,0.2-1.4,0.2-1.8,0.5c-0.3,0.3-0.4,1.2-0.7,1.5c-0.3,0.3-1,0.9-1.4,0.8c-0.5,0-1.6-1.1-1.7-1.1c0-0.1-1,0.1-1.3,0
		c-0.4-0.1-0.9-0.7-1.3-0.7c-0.2,0-0.6,0.4-0.9,0.4c-0.4,0-1-0.5-1.3-0.5c-0.4,0-1.1,0.4-1.4,0.4c-0.4,0-1-0.6-1.4-0.7
		c-0.1,0-0.2-0.1-0.4-0.1l0,0c-0.4-0.1-0.8-0.2-1.1-0.2c-0.3,0-1,0.2-1.4,0.2l-6.5,0.6l10,11.6l-0.7,13.5l-1.4,26.8L366,556l8.8,0.6
		c0,0-0.7,0.8-0.9,1.1c-0.2,0.4-0.2,1.3-0.4,1.6c-0.1,0.2-0.6,0.6-0.6,0.9c0,0.4,0.5,1,0.7,1.4c0.2,0.5,0.6,1.6,0.9,2
		c0.3,0.5,1.3,1.1,1.6,1.6c0.3,0.4,0.5,1.4,0.6,1.9c0.1,0.5,0,1.4,0,1.9c0,0.6-0.1,1.7-0.2,2.2c-0.1,0.5-0.4,1.6-0.4,2.1
		c0,0.6,0.2,1.7,0.4,2.3c0.2,0.7,0.9,2,1.2,2.7c0.4,0.8,1.1,2.5,1.7,3.2c0.3,0.3,1,0.6,1.4,0.6c0.6,0,1.5-0.8,2-1
		c0.5-0.1,1.4-0.1,1.9,0c0.5,0.1,1.3,0.4,1.8,0.6c0.4,0.1,1.2,0.4,1.6,0.4c0.5,0.1,1.5,0,1.9,0c0.4,0,1.2,0.2,1.6,0.1
		c0.5-0.2,1.4-0.8,1.7-1.2c0.3-0.4,0.5-1.5,0.4-2c-0.1-0.5-0.7-1.4-0.9-1.8c-0.1-0.4-0.3-1.2-0.2-1.6c0.1-0.4,1.1-0.7,1.1-1.1
		c0.1-0.4-0.6-1.2-0.6-1.6c0.1-0.4,0.8-0.8,0.9-1.2c0.2-0.6,0.2-1.8,0.4-2.4c0.2-0.6,1-1.5,1.1-2.1c0.2-0.8-0.1-2.4-0.2-3.2
		c-0.1-0.5-0.6-1.6-0.5-2.2c0.1-0.6,0.9-1.7,1.1-2.3c0.3-0.9,0.7-2.9,0.6-3.9c-0.1-0.9-1-2.4-1.4-3.2c-0.1-0.3-0.5-0.7-0.7-0.9
		c-0.4-0.7-0.7-2.2-1-2.9c-0.2-0.4-0.4-1.3-0.6-1.7c-0.2-0.3-0.9-0.7-0.9-1c0-0.3,0.4-0.7,0.4-1c0-0.3-0.3-0.9-0.3-1.2
		c0.1-0.6,0.8-1.7,1-2.3c0.1-0.5,0.3-1.4,0.2-1.9c-0.1-0.6-0.9-1.6-0.8-2.2c0-0.4,0.7-1.5,0.7-1.6c0-0.1-0.2-1.1,0.1-1.4
		c0.3-0.2,1.1,0.2,1.4,0.1c0.4-0.1,1.1-0.6,1.2-0.9c0.1-0.3-0.2-0.9-0.3-1.1c-0.1-0.4-0.8-1.2-0.7-1.6c0.1-0.4,0.8-1.2,1.2-1.3
		c0.4-0.1,1.1,0.4,1.4,0.7c0.3,0.2,0.5,1,0.8,1.2c0.3,0.2,1.2,0.2,1.5,0c0.4-0.3,0.5-1.4,0.7-1.8c0.1-0.2,0.5-0.7,0.5-0.9
		c0.1-0.5-0.3-1.5-0.2-2c0.1-0.3,0.4-0.9,0.7-1.1c0.3-0.2,1-0.5,1.4-0.5c0.5,0.1,1.2,0.8,1.6,1c0.4,0.2,1.1,0.8,1.5,0.7
		c0.4-0.1,0.3-1.3,0.7-1.4c0.1,0,0.2,0,0.3,0c0.4,0,0.9,0.3,1.1,0.4c0.3,0.3,0.7,1.1,0.9,1.5c0.1,0.3,0.2,1.1,0.4,1.3
		c0.2,0.2,0.7,0.4,1,0.4c0.4,0.1,1.2-0.1,1.5,0c0.3,0.1,0.7,0.6,1,0.8c0.2,0.1,0.8,0.2,1,0.2c0.3,0,0.9-0.5,1.2-0.5
		c0.5,0,1.6,0.3,2.1,0.5c0.4,0.2,1.2,0.6,1.4,1c0.1,0.2,0,0.8,0.1,1.1c0.1,0.3,0.3,0.9,0.5,1c0.2,0.1,0.8,0,1-0.2
		c0.2-0.2-0.1-1.1,0.1-1.3c0.1-0.2,0.6-0.2,0.8-0.2c0.2,0.1,0.3,0.7,0.5,0.8c0.3,0.2,1.2,0.1,1.6,0.3c0.5,0.3,0.9,1.4,1.3,1.8
		c0.2,0.2,0.6,0.6,0.8,0.7c0.4,0.1,1.2,0,1.6,0c0.3,0,1,0.1,1.3,0.2c0.4,0.1,1.2,0.9,1.7,0.8c0.3-0.1,0.6-0.8,0.9-0.9
		c0.3-0.1,0.8,0.1,1,0.2c0.2,0.1,0.3,0.6,0.4,0.8c0.1,0.2,0.2,0.9,0.5,1c0.3,0.1,1-0.2,1.3-0.3c0.2-0.1,0.6-0.4,0.8-0.4
		c0.2,0,0.5,0.3,0.7,0.5c0.1,0.2,0.1,0.5,0.1,0.7c0.1,0.2,0.2,0.6,0.3,0.7c0.1,0.2,0.3,0.6,0.5,0.6c0.3,0.1,0.8-0.2,0.9-0.5
		c0.1-0.2,0-0.6-0.1-0.8c-0.1-0.1-0.4-0.2-0.5-0.3c-0.1-0.2-0.1-0.7,0-0.9c0.2-0.2,1-0.2,1.2,0c0.2,0.1,0,0.7,0.2,0.8
		c0.3,0.3,1.4,0.4,1.7,0.1c0.2-0.1,0-0.7,0.2-0.8c0.1-0.1,0.5,0.1,0.6,0.2c0.2,0.2,0.3,1,0.5,1.2c0.3,0.3,1.2,0.1,1.6,0.3
		c0.2,0.1,0.5,0.6,0.7,0.6c0.3,0,0.7-0.6,0.9-0.9c0.1-0.2-0.1-0.7,0.1-0.9c0.2-0.2,0.8-0.1,1.1-0.1c0.4,0,1.2,0.3,1.6,0.2
		c0.2-0.1,0.7-0.4,0.8-0.6c0.1-0.3-0.3-1.1-0.3-1.5c0-0.2,0-0.7,0.1-0.9c0.1-0.1,0.5,0.1,0.6,0c0.2-0.2,0.3-0.7,0.3-0.9
		c0-0.3-0.3-0.7-0.4-1c-0.1-0.4,0.1-1.2,0-1.6c-0.1-0.2-0.3-0.6-0.4-0.8c-0.2-0.2-0.7-0.5-0.9-0.7c-0.3-0.2-0.8-0.7-1.1-1
		c-0.3-0.3-0.8-0.9-0.9-1.3c-0.1-0.3-0.1-1,0.1-1.2c0.2-0.2,0.9,0,1.1-0.2c0.1-0.1,0.3-0.5,0.3-0.7c0-0.2-0.3-0.6-0.5-0.7
		c-0.1-0.1-0.5,0-0.6,0c-0.3,0.1-0.6,0.6-0.9,0.6c-0.2,0.1-0.7,0-0.9-0.1c-0.2-0.2-0.1-0.9-0.3-1.1c-0.1-0.2-0.5-0.3-0.7-0.4
		c-0.2-0.1-0.7,0-0.9-0.1c-0.2-0.1-0.6-0.3-0.7-0.5c-0.1-0.2-0.2-0.8-0.2-1.1c0-0.3,0.3-1,0.1-1.3c0-0.1-0.3-0.2-0.4-0.2
		c-0.2,0-0.5,0.1-0.6,0.3c-0.1,0.2,0,0.7,0,0.9c-0.1,0.3-0.2,0.9-0.4,1c-0.2,0.1-0.6-0.5-0.6-0.6c0-0.1-0.3-0.8-0.3-1
		c0-0.3,0.2-0.8,0.4-1c0.2-0.3,0.9-0.6,1.2-0.7c0.3-0.1,1.1-0.2,1.4,0c0.2,0.1,0.5,0.7,0.5,0.9c0,0.2-0.3,0.5-0.3,0.7
		c0,0.2,0.1,0.7,0.3,0.9c0.1,0.2,0.5,0.6,0.8,0.6c0.3,0,0.4-0.7,0.6-0.8c0.5-0.2,1.6-0.1,1.9,0.2c0.2,0.1,0.1,0.7,0.2,0.8
		c0.2,0.2,0.8,0.2,1,0.3c0.3,0,0.9-0.1,1.1,0.1c0.2,0.1,0.4,0.7,0.6,0.7c0.1,0,0.4-0.3,0.4-0.4c0.1-0.3-0.2-0.8-0.4-1.1
		c-0.2-0.3-1.1-0.8-1.1-0.9c0-0.1-0.7-1-0.9-1.4c-0.2-0.3-0.5-1-0.5-1.4c0-0.4,0.3-1.1,0.6-1.5c0.2-0.2,0.7-0.4,0.9-0.6
		c0.2-0.2,0.3-0.7,0.5-0.9c0.1-0.1,0.4-0.2,0.5-0.3c0.3-0.1,0.9-0.3,1.1-0.5c0.3-0.3,0.4-1.2,0.7-1.5c0.2-0.2,0.7-0.2,0.9-0.4
		c0.4-0.3,1-0.9,1.2-1.3c0.1-0.2,0.4-0.7,0.4-1C453,510.1,453,509.5,452.8,509.2z M436.4,490c-0.1,0-0.2-0.1-0.3-0.1
		C436.1,489.9,436.3,490,436.4,490z"/>
    </g>
	<path @click="openEmc" data-emc="slash-pine-emc" ref="slash-pine-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M359.6,477.3l-11-2.8l-19.2,7.4l0,0l-30.6-0.1l-7.7,0l0,0.1c-1.2,1.4-2.8,3-3.9,4.2H279v10.5h-3
		c-0.7,1-1.4,1.9-2,2.9c-0.3,0.5-0.7,1-1,1.4l0.1,0c0,0.8-0.1,1.5,0,2.3l4.9,0.3v7h6c0.3,0.5,0.6,0.9,1,1.3c0.5,0.8,0.9,1.7,1,2.6
		c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.4,0.1c0.9,0,1.7,0.1,2.6,0.1c0.3-0.8,0.6-1.7,0.9-2.5c0-0.1,0.1-0.2,0.2-0.3
		c0.1-0.1,0.2-0.1,0.3-0.1c0.5-0.1,0.9-0.2,1.4-0.3c0.1,0.3,0.6,0.6,0.7,0.9c0.2,0.5-0.2,1.5,0,2c0.1,0.2,0.5,0.5,0.7,0.6
		c0.3,0.3,0.9,0.7,1,1.1c0,0.3-0.2,0.7-0.2,1c-0.1,0.4,0,1.2,0,1.6c0,0.3,0.2,1.1,0.1,1.4c-0.1,0.2-0.4,0.4-0.5,0.6
		c-0.1,0.3,0,0.9,0.1,1.4H287v2.5h-1.5v3.4h-2.3l-1,3v14.7h1.5v1.6h-1.6l0.1,2.8l55.8,3.9l16.5,1.1L366,556l1.4-26.7l1.4-26.8
		l0.7-13.5L359.6,477.3z"/>
    <g id="grady-emc" >
        <path @click="openEmc" data-emc="grady-emc" ref="grady-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M116.4,530.5c-0.1-0.1-0.1-0.6-0.2-0.7c-0.2-0.2-0.9-0.5-1.1-0.4c-0.3,0.2-0.2,1.2-0.3,1.5
            c-0.1,0.2-0.4,0.5-0.3,0.6c0.1,0.2,0.7,0.1,1,0c0.2,0,0.6-0.2,0.8-0.1c0.2,0,0.6,0.3,0.8,0.3c0.2,0,0.6-0.4,0.6-0.6
            c0-0.2-0.2-0.6-0.3-0.7C117.2,530.4,116.6,530.7,116.4,530.5z"/>
        <path @click="openEmc" data-emc="grady-emc" ref="grady-emc-1" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M113.9,531.1c0-0.2-0.2-0.7-0.4-0.7c-0.2,0-0.5,0.3-0.6,0.4c-0.1,0.2-0.4,0.7-0.3,0.9
            c0.1,0.2,0.4,0.3,0.6,0.4c0.1,0,0.5-0.1,0.5-0.2C113.9,531.7,113.9,531.3,113.9,531.1z"/>
		<path @click="openEmc" data-emc="grady-emc" ref="grady-emc-2" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M109.9,530.8c-0.2,0-0.6,0.2-0.8,0.3c-0.2,0.1-0.7,0.3-0.9,0.2c-0.2-0.1-0.2-0.6-0.2-0.7
			c0-0.3,0.1-1.1,0.4-1.3c0.1-0.2,0.6-0.3,0.8-0.3c0.2,0,0.7,0.3,0.9,0.3c0.3,0,0.7-0.6,1.1-0.5c0.3,0,0.7,0.5,0.9,0.7
			c0.1,0.2,0.3,0.6,0.2,0.8c-0.1,0.3-0.4,0.8-0.7,0.8c-0.2,0.1-0.7-0.2-1-0.2C110.3,530.8,110,530.8,109.9,530.8z"/>
	<path @click="openEmc" data-emc="grady-emc" ref="grady-emc-3" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M224,502.6c-0.8-1-1.7-1.9-2.7-2.7c-0.1-0.1-0.1-0.1-0.2-0.2l-2.2,0c-0.4-0.5-0.8-1.1-1.2-1.6
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.7,0-1.3,0-2,0c-0.2,0-0.3,0-0.5,0c-0.2,0.1-0.4,0.2-0.5,0.3
		c-0.7,0.5-1.5,0.9-2.3,1.2h-3.5c-0.8-0.5-1.5-1.1-2.1-1.8c-0.8,0.5-1.6,1.1-2.2,1.8h-9.8l0-4.4l-11.6-0.1l-26.2-0.2l0,0.2v0
		c-1.1,1.4-2.2,2.7-3.4,4c-0.4,0.4-0.8,0.9-1.3,1.2c-1.3-1.6-2.3-3.5-3-5.4l-5.9,0l-0.1,0c-0.3,1.2-0.3,2.5-0.2,3.8
		c0,0.2,0,0.4,0,0.6c-0.1,0.2-0.2,0.4-0.4,0.5c-0.4,0.6-0.5,1.4-0.5,2.1c0,1.3,0,2.5-0.1,3.8c0,0.1,0,0.2-0.1,0.3
		c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5,0.2-1.2,0.2-1.6,0.6c-0.4,0.4-0.5,1-0.6,1.5c-0.2,1.4-0.4,2.8-0.5,4.3c-1.4,1.4-2.9,2.8-4.3,4.3
		c0.1,0.2-0.2,0.6-0.3,0.8c-0.1,0.2-0.1,0.7-0.2,0.9c-0.1,0.3-0.5,0.7-0.7,0.9c-0.2,0.2-0.3,0.8-0.5,1c-0.2,0.2-0.7,0.5-0.9,0.7
		c-0.2,0.2-0.5,0.5-0.6,0.7c-0.2,0.3-0.3,0.9-0.5,1.1c-0.2,0.3-0.9,0.5-1.3,0.7c-0.2,0.1-0.6,0.2-0.8,0.4c-0.2,0.2-0.3,0.7-0.3,1
		c0,0.2,0.3,0.6,0.3,0.8c0,0.4-0.3,1.1-0.2,1.5c0.1,0.2,0.5,0.3,0.6,0.5c0.1,0.1,0.1,0.5,0,0.7c0,0.2-0.2,0.4-0.3,0.5
		c-0.1,0.3-0.2,0.9-0.4,1.1c-0.3,0.3-1.2,0.3-1.6,0.4c-0.3,0.1-0.8,0.1-1.1,0.2c-0.2,0.1-0.5,0.4-0.7,0.5c-0.2,0.1-0.6,0.2-0.8,0.1
		c-0.3,0-0.9-0.5-1.2-0.5c-0.2,0-0.6,0.2-0.8,0.3c-0.1,0.2,0,0.7-0.1,0.8c-0.1,0.1-0.5,0.1-0.6,0.1c-0.2-0.1-0.4-0.5-0.6-0.5
		c-0.1,0-0.4,0.1-0.5,0.2c-0.2,0.2-0.2,0.8-0.4,0.9c-0.2,0.1-0.7,0.1-0.9,0.2c-0.3,0-1,0-1.2,0.1c-0.1,0.1-0.3,0.3-0.5,0.4
		c-0.3,0.2-1.1,0.2-1.5,0.3c-0.3,0-0.9,0-1.1,0.1c-0.2,0.1-0.5,0.5-0.7,0.5c-0.3,0.1-1.2-0.4-1.2-0.4c0,0-0.9,0-1.2,0.1
		c-0.3,0.1-0.8,0.6-1.1,0.7c-0.3,0.1-1.1-0.2-1.4-0.1c-0.4,0.2-1.2,0.9-1.4,1.3c-0.1,0.2-0.2,0.8-0.1,1c0,0.1,0.2,0.4,0.3,0.5
		c0.1,0.3,0.3,0.8,0.3,1.1c0,0.3-0.3,0.7-0.5,0.9c-0.2,0.1-0.7,0-0.8,0.2c0,0.1,0,0.3,0,0.3l48.7,2.7l0,0.1l30.6,1.7l8.8,0.5
		l25.5,1.8l0.3-16.3c-0.3-0.3-0.7-0.7-1-1c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.3-0.2-0.6-0.2-0.9
		c0.5-0.4,1.1-0.8,1.6-1.2l0.1-3.8c-0.9-1-2-1.8-3.1-2.4c0.6-2.6,1.3-5.2,1.9-7.8c0.2-0.8,0.4-1.7,0.7-2.5c0.2-0.5,0.5-1,0.8-1.4
		c0.1-0.2,0.2-0.3,0.3-0.5c0.3-0.4,0.7-0.8,1-1.3c0.2-0.4,0.4-0.8,0.6-1.2c0.3-0.7,0.7-1.4,1-2c0-0.1,0.1-0.2,0.1-0.3
		C224.2,502.8,224.1,502.7,224,502.6z"/>
    </g>
	<path @click="openEmc" data-emc="three-notch-emc" ref="three-notch-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M142.8,494.4l-3.4,0l0.2-12.1c-0.5-0.6-0.9-1.1-1.4-1.7c-0.1-0.2-0.3-0.3-0.4-0.6c-0.1-0.2-0.1-0.5-0.2-0.7
		c-0.1-1-0.2-2-0.8-2.9c-0.2-0.4-0.5-0.7-0.8-1c-1.1-1.1-2.4-2-3.8-2.7l-3,0l0.1-5c-0.9-0.5-1.7-0.9-2.6-1.3c-0.3-0.1-0.7-0.3-1-0.5
		c-0.8-0.5-1.4-1.2-2.2-1.9c-2.3-2.1-5.4-2.9-8-4.5c0.2-2.9,0.2-5.9,0.1-8.8l-1.1,0c0,0-0.3-0.3-0.4-0.4c-0.1-0.2-0.4-0.6-0.4-0.8
		c0-0.2,0.4-0.4,0.4-0.6c0-0.2,0-0.6-0.2-0.8c-0.2-0.3-0.9-0.4-1.1-0.7c-0.1-0.2-0.2-0.7-0.1-0.9c0.1-0.1,0.5-0.2,0.5-0.4
		c0.2-0.2,0.1-0.8,0.2-1.1c0-0.3,0.1-1,0.1-1.4c0-0.2,0-0.7-0.2-0.8c-0.2-0.1-0.8,0-0.9,0.2c-0.1,0.1,0.1,0.6,0,0.8
		c-0.2,0.2-0.8,0.1-1-0.1c-0.2-0.1-0.3-0.6-0.4-0.8c0,0,0,0,0-0.1h-4.5c-1.1,1.1-2.3,2.2-3.5,3.3c-1.3-1.1-2.5-2.2-3.8-3.3
		c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-3.1-0.3-6.2-1.2-9.3-1.4c-0.2,0-0.4-0.1-0.5-0.1
		c-0.2,0-0.5,0-0.8,0.1c0,0.2,0,0.3-0.1,0.4c-0.1,0.5-0.5,1.4-0.8,1.8c-0.2,0.4-0.8,1-1,1.4c-0.1,0.3-0.3,1-0.3,1.4
		c-0.1,0.6-0.1,1.8,0,2.5c0.1,0.6,0.6,1.7,0.4,2.2c-0.1,0.4-1,0.9-1.2,1.3c-0.2,0.3-0.3,1.1-0.3,1.5c0,0.5,0.5,1.4,0.5,1.9
		c0,0.3-0.4,0.9-0.5,1.2c-0.2,0.5-0.4,1.5-0.6,2c-0.1,0.3-0.8,0.6-0.8,0.9c0,0.3,0.7,0.7,0.8,1c0.1,0.4,0.1,1.2,0.1,1.6
		c0,0.2-0.3,0.7-0.3,0.9c0,0.4,0.4,1.2,0.4,1.6c0,0.6-0.1,1.9-0.3,2.5c-0.1,0.4-0.5,1.2-0.8,1.5c-0.2,0.2-0.9,0.4-1.1,0.6
		c-0.3,0.2-0.7,0.8-0.8,1.1c-0.1,0.5,0.2,1.5,0.4,1.9c0.2,0.7,1.2,2,1.3,2.7c0.1,0.4,0,1.2,0,1.6c-0.1,0.5-0.4,1.4-0.6,1.9
		c-0.1,0.3-0.4,0.9-0.4,1.2c0,0.6,0.9,1.6,1.1,2.2c0.1,0.4,0,1.1,0.1,1.5c0,0.2,0.1,0.7,0.3,0.9c0.2,0.2,0.9,0,1.1,0.1
		c0.4,0.2,0.9,0.9,1.2,1.3c0.3,0.4,0.6,1.3,0.8,1.7c0.2,0.4,0.6,1.3,0.8,1.7c0.5,0.7,2.3,1.7,2.6,2.5c0.2,0.6-0.1,1.8,0,2.4
		c0,0.2,0.2,0.6,0.3,0.9c0.1,0.2,0.2,0.4,0.2,0.5c0.3,0.6,1.4,1.7,1.5,2.4c0.1,0.5-0.2,1.6-0.1,2.1c0.1,0.5,0.9,1.2,1,1.6
		c0.1,0.4-0.2,1.4-0.1,1.8c0,0.4,0.4,1.1,0.4,1.5c0,0.3-0.4,1-0.3,1.3c0.1,0.5,0.9,1.2,1.2,1.4c0.3,0.2,1.2,0.2,1.3,0.5
		c0.2,0.3-0.1,1.1-0.2,1.4c-0.1,0.3-0.5,0.8-0.6,1.1c0,0.3,0.2,0.9,0.4,1.1c0.2,0.3,0.9,0.6,1.2,0.9c0.3,0.3,0.7,1.2,0.9,1.6
		c0.3,0.6,0.9,1.8,1.3,2.3c0.2,0.2,0.8,0.5,0.9,0.8c0.1,0.2,0.1,0.8,0,1.1c0,0.2-0.3,0.7-0.3,0.9c0,0.2,0.4,0.5,0.5,0.7
		c0.1,0.3,0,1,0.1,1.3c0.1,0.3,0.7,0.6,0.7,0.9c0,0.3-0.3,0.8-0.4,1.1c-0.2,0.5-0.8,1.7-0.7,2.2c0.1,0.2,0.7,0.4,0.8,0.6
		c0.2,0.4,0.2,1.4,0.2,1.8c0,0.3-0.3,0.8-0.2,1.1c0.1,0.3,0.6,0.7,0.8,0.9c0.1,0.1,0.1,0.4,0.2,0.6c0.4-0.1,0.8,0.2,1,0.3
		c0.2,0.1,0.4,0.5,0.6,0.7c0.2,0.2,0.5,0.4,0.6,0.3c0.2-0.2,0-0.7,0-0.9c-0.1-0.3-0.4-0.8-0.4-1c0-0.2,0.1-0.7,0.2-0.9
		c0.1-0.1,0.5-0.1,0.6-0.1c0.2,0.1,0.5,0.6,0.6,0.9c0.1,0.3,0.3,1,0.4,1c0.1,0.1,0.9,0.3,1.1,0.2c0.2,0,0.6-0.4,0.9-0.5
		c0.1-0.1,0.5-0.1,0.6-0.2c0.2-0.3,0-0.9,0.1-1.2c0.1-0.2,0.4-0.5,0.5-0.7c0.1-0.1,0.1-0.5,0.2-0.6c0.2-0.1,0.7-0.1,0.9-0.1
		c0.3,0,0.8,0.1,1,0.1c0.2-0.1,0.5-0.5,0.7-0.6c0.2-0.1,0.7,0,1,0.1c0.3,0.1,0.6,0.6,0.9,0.6c0.2,0,0.6-0.4,0.8-0.4
		c0.2,0,0.5,0.3,0.6,0.2c0.2-0.1,0.4-0.5,0.5-0.7c0.1-0.3-0.2-1-0.1-1.3c0.1-0.3,0.6-0.7,0.8-1c0.2-0.3,0.4-1,0.5-1.3
		c0.2-0.3,0.6-0.8,0.7-1.1c0.2-0.5,0.2-1.4,0.3-1.9c0.1-0.6,0.1-1.8,0.4-2.3c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.4,0.6-1.2,0.7-1.6
		c0.1-0.5,0.1-1.2,0.1-1.9c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.4,0.3,0.5,0.4c0.2,0.4,0.2,1.4,0.2,1.9c0,0.4-0.2,1.1-0.3,1.4
		c-0.1,0.3-0.2,0.9-0.3,1.2c0,0.3,0,0.8-0.1,1.1c-0.1,0.3-0.6,0.7-0.7,1c-0.1,0.3,0,1,0,1.3c-0.1,0.3-0.5,0.9-0.7,1.1
		c-0.2,0.3-0.5,1.1-0.7,1.4c-0.2,0.2-0.6,0.5-0.7,0.8c-0.1,0.2-0.1,0.7,0,0.9c0.1,0.3,0.3,0.7,0.5,0.9c0.2,0.2,0.6,0.5,0.9,0.4
		c0.2-0.1,0.5-0.6,0.6-0.8c0.1-0.2-0.1-0.7,0-0.8c0.1-0.1,0.5-0.2,0.6-0.1c0.2,0.1,0.4,0.4,0.4,0.5c0.1,0.2,0,0.6-0.1,0.8
		c-0.1,0.2-0.5,0.3-0.6,0.5c-0.1,0.2-0.1,0.5,0,0.7c0.2,0.3,0.9,0.6,1.2,0.7c0.1,0,0.4-0.1,0.6-0.1c0.1,0,0.4-0.1,0.5-0.2
		c0.2-0.2,0.3-0.8,0.5-1.1c0.1-0.1,0.4-0.4,0.5-0.4c0.3,0,0.6,0.7,0.9,0.8c0.2,0.1,0.8,0.2,1.1,0.2c0.3,0,0.8-0.2,1.1-0.3
		c0.2-0.1,0.5-0.5,0.7-0.6c0.3-0.1,0.9,0.1,1.2,0.2c0.2,0,0.6,0.3,0.8,0.2c0.2-0.1,0.6-0.5,0.6-0.8c0-0.3-0.5-0.7-0.5-0.9
		c0-0.2,0.3-0.5,0.4-0.6c0.1-0.3,0.2-0.8,0.2-1.1c0-0.3-0.3-0.9-0.3-1.2c0-0.3,0-0.8,0.1-1c0.1-0.2,0.3-0.5,0.5-0.5
		c0.1-0.1,0.3,0,0.4-0.1c0.1-0.1,0.4-0.4,0.4-0.5c0.1-0.2,0.2-0.6,0.3-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.8-0.3,1-0.4
		c0.2-0.1,0.4-0.4,0.5-0.5c0.2-0.1,0.6-0.2,0.7-0.4c0.2-0.2,0.3-0.8,0.5-1.1c0.1-0.3,0.4-0.7,0.5-1c0.2-0.3,0.5-1,0.9-1.1
		c0.2-0.1,0.6,0.1,0.7,0.2c0,0,0,0,0,0c1.4-1.5,2.8-2.9,4.3-4.3c0.1-1.4,0.3-2.8,0.5-4.3c0.1-0.6,0.2-1.2,0.6-1.5
		c0.4-0.4,1.1-0.4,1.6-0.6c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-1.3,0-2.5,0.1-3.8c0-0.7,0-1.5,0.5-2.1
		c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.2,0-0.4,0-0.6C142.5,497,142.6,495.7,142.8,494.4z"/>
	<path @click="openEmc" data-emc="sumter-emc" ref="sumter-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M200.9,390c0-0.4,0.4-1.3,0.4-1.8c0-0.4-0.4-1.3-0.6-1.6c-0.1-0.3-0.5-0.9-0.7-1.2c-0.2-0.3-0.7-0.6-0.8-0.9
		c-0.1-0.3,0.3-1,0.2-1.3c-0.1-0.2-0.5-0.3-0.6-0.5c-0.2-0.3-0.1-0.9-0.2-1.2c-0.1-0.3-0.5-0.8-0.6-1.2c-0.1-0.4,0.1-1.1,0-1.5
		c0-0.1,0-0.1,0-0.2c0-0.3-0.1-0.8-0.2-1c-0.2-0.3-1.2-0.5-1.4-0.9c-0.2-0.4,0.5-1.3,0.4-1.7c-0.1-0.4-0.9-1-0.9-1.5
		c0-0.3,0.4-1,0.5-1.3c0.1-0.3,0.3-1,0.1-1.3c-0.2-0.3-1-0.3-1.3-0.5c-0.2-0.2-0.4-0.9-0.6-1.2c-0.2-0.3-0.6-0.8-0.8-1
		c-0.1-0.1-0.6-0.4-0.6-0.5c0-0.1,0.3-0.9,0.2-1.2c-0.1-0.4-1-0.8-1.2-1.1c-0.2-0.2-0.4-0.8-0.5-1c-0.1-0.2-0.4-0.5-0.4-0.8
		c0.1-0.2,0.5-1.3,0.3-1.8c-0.2-0.4-1-0.6-1.3-0.9c-0.2-0.2-0.4-0.6-0.5-1c-0.3-0.1-0.6-0.2-0.8-0.3c-0.5-0.1-1.6,0.2-2.1,0
		c-0.2-0.1-0.6-0.5-0.8-0.6c-0.3-0.1-1.1-0.3-1.2-0.3c-0.1,0-1,0.3-1,0.3l-2.8,0c-1.5-0.3-2.5-0.7-3.9-1.2c-0.4-0.1-0.8-0.3-1.2-0.4
		c-1.3-0.4-2.7-0.8-4-1.3c-1.1-0.4-2.4-0.8-2.9-1.9c-0.1-0.3-0.2-0.7-0.5-1c-0.4-0.3-1-0.1-1.5,0c-1.1,0.1-2-0.7-2.6-1.6
		c-0.6-0.9-0.9-1.9-1.5-2.8c-0.2-0.2-0.3-0.4-0.6-0.5c-0.3-0.1-0.5,0-0.8,0c-1.9,0.2-3.9-0.5-5.5-1.6c-0.5-0.4-1-0.8-1.5-1.2
		c-1-0.9-1.9-1.9-2.7-2.8c-0.3,0.5-1,0.8-1.6,0.7c-0.5,0-0.9-0.3-1.4-0.2c-0.3,0.1-0.6,0.3-0.9,0.4c-1,0.5-2.3,0.2-3.4,0.4
		c-0.3,0-0.6,0.1-0.9,0.3c-0.4,0.2-0.7,0.5-1,0.8c-2.2,2-4.8,3.7-7.6,4.8c-2.9-1-5-3.4-7.4-5.3v-22.6h2.2v-5.1
		c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.3,0.4-0.5,0.5c-0.1,0.1-0.4,0-0.6,0c-0.2,0-0.5,0-0.7,0.1
		c-0.3,0.2-0.5,0.8-0.8,1c-0.2,0.1-0.6,0.3-0.8,0.2c-0.1,0-0.4-0.1-0.6-0.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.8-0.1-1-0.1
		c-0.3,0-0.8,0.2-1.1,0.4c-0.2,0.1-0.4,0.6-0.6,0.7c-0.2,0.1-0.6-0.1-0.8,0.1c-0.2,0.1-0.2,0.7-0.3,0.9c-0.1,0.2-0.2,0.6-0.3,0.8
		c-0.1,0.1-0.2,0.4-0.3,0.5c-0.3,0.6-0.9,1.9-1,2.5c-0.1,0.3,0.2,1.1,0,1.4c-0.1,0.2-0.6,0.4-0.8,0.5c-0.3,0.2-0.8,0.7-1,1
		c-0.2,0.3-0.6,0.9-0.7,1.2c-0.1,0.4,0.3,1.2,0,1.5c-0.1,0.2-0.7,0.1-0.9,0.1c-0.3,0.1-0.8,0.3-1.1,0.5c-0.2,0.1-0.4,0.5-0.6,0.6
		c-0.2,0-0.6-0.2-0.8-0.3c-0.2-0.1-0.5-0.5-0.8-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.1,0.4-0.2,0.5c-0.2,0.2-0.8,0.2-1.1,0.2
		c-0.4,0-1.3-0.1-1.7-0.1c-0.6,0-1.7,0-2.2,0.1c-0.3,0.1-0.9,0.4-1.2,0.4c-0.2,0-0.5,0-0.7,0c-0.3,0.1-0.5,0.7-0.7,0.9
		c-0.3,0.2-1,0.6-1.4,0.7c-0.5,0.2-1.6,0.4-2,0.7c-0.3,0.2-0.6,1-0.9,1.3c-0.2,0.1-0.8,0.1-1,0.2c-0.2,0.1-0.5,0.4-0.7,0.5
		c-0.3,0.1-0.9-0.1-1.2-0.1c-0.2,0-0.5-0.2-0.7-0.2c-0.2,0-0.5-0.1-0.8-0.1c-0.1,0.3-0.4,0.6-0.5,0.8c-0.2,0.2-0.6,0.4-0.7,0.6
		c-0.2,0.3-0.2,1-0.4,1.2c-0.2,0.3-0.9,0.4-1.2,0.6c-0.2,0.2-0.6,0.8-0.7,1.1c-0.1,0.3-0.1,0.9,0,1.2c0.2,0.4,0.8,1.2,1.2,1.5
		c0.5,0.4,1.8,0.7,2.5,0.9c0.6,0.2,1.7,0.7,2.3,1c0.6,0.3,1.7,1,2.2,1.5c0.4,0.4,0.9,1.3,1.2,1.6c0.5,0.5,2.1,0.9,2.2,1.6
		c0.1,0.4-0.6,1.1-1,1.4c-0.3,0.2-1.1,0-1.5,0.2c-0.2,0.1-0.6,0.5-0.6,0.7c-0.1,0.3,0.5,0.9,0.5,1.2c0,0.2-0.3,0.5-0.5,0.8
		c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2,0,0.7-0.2,0.9c-0.2,0.2-0.7,0.3-1,0.4c-0.6,0.1-1.8-0.4-2.4-0.3c-0.3,0-0.9,0.3-1.2,0.5
		c-0.3,0.2-0.9,0.6-0.9,1c0.1,0.4,1.3,0.4,1.5,0.8c0.1,0.1,0,0.5-0.1,0.6c-0.3,0.4-1.2,0.8-1.6,1c-0.5,0.2-1.7,0.1-2.3,0.3
		c-0.3,0.1-0.9,0.6-1.2,0.9c-0.5,0.6-1.2,1.9-1.7,2.5c-0.3,0.4-0.8,1-1.1,1.4c-0.4,0.4-1.4,0.8-1.6,1.3c-0.2,0.4,0.1,1.4,0.1,1.9
		c0.1,0.6,0.5,1.6,0.7,2.2c0.1,0.4,0.7,1.4,0.5,1.8c-0.1,0.4-1.2,0.5-1.3,0.8c-0.1,0.3,0.4,0.9,0.4,1.3c0.1,0.4,0.3,1.1,0.2,1.5
		c0,0.3-0.5,0.9-0.6,1.2c-0.1,0.5,0,1.6,0,2.1c0,0.3,0.1,1,0.1,1.4c0,0.3-0.3,1-0.5,1.3c-0.2,0.3-0.7,0.8-0.9,1.1
		c-0.1,0.3-0.1,1,0,1.3c0,0.1,0.1,0.3,0.2,0.5c0.6,0,1.4,0,1.7,0.1c0.4,0,1.5,0.2,1.5,0.2v0.8h3l0-0.9c0,0,0.9,0,1.1,0.2
		c0.3,0.3,0.3,0.9,1.2,1.1c0.3,0,0.9-0.5,1.2-0.5c0.4-0.1,1.2-0.2,1.6,0c0.3,0.1,1,0.5,1.2,0.8c0.1,0.2,0.1,0.6,0,0.7
		c-0.2,0.1-0.6-0.3-0.8-0.2c-0.2,0.1-0.4,0.6-0.4,0.8c-0.1,0.3-0.2,1,0,1.2c0.1,0.1,0.4,0,0.5,0.1c0.2,0.1,0.1,0.6,0.3,0.8
		c0.2,0.2,0.9,0.5,1.3,0.5c0.2,0,0.5-0.2,0.6-0.1c0.2,0.1,0.3,0.6,0.5,0.7c0.3,0.2,1.2,0,1.4,0.2c0.2,0.2,0,1.2,0,1.2l1,0l4,0
		c1.1,0.8,2.1,1.6,3.1,2.5c0.3,0.2,0.5,0.5,0.8,0.6c0.4,0.1,0.8,0.1,1.1,0c3-0.3,5.9-0.2,8.9-0.1c0,0.5,0,0.9,0,1.4
		c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.5,0.5-1,0.9-1.5,1.4c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.3-0.1,0.4
		c0,0.3,0,0.6,0,0.9c1.6,0.3,3.2,0.5,4.9,0.5c0.2,0,0.4,0,0.5,0.1c0.1,0,0.2,0.1,0.3,0.2c1.6,1.3,3.3,2.6,5.1,3.7
		c0.4,0.3,0.8,0.5,1.2,0.9c0.3,0.3,0.5,0.7,0.8,1c0.8,1.2,1.6,2.4,2.4,3.6l0,0.4h4c0,0-0.1,0.7-0.2,0.9c-0.1,0.2-0.5,0.3-0.5,0.5
		c-0.1,0.3,0.2,1,0.3,1.3c0.1,0.2,0.4,0.6,0.5,0.8c0.1,0.3-0.1,0.9-0.1,1.1c0.1,0.3,0.5,0.8,0.7,1c0.2,0.3,0.7,0.7,0.8,1
		c0.1,0.4-0.1,1.2,0.1,1.5c0.1,0.3,0.5,0.8,0.6,1.1c0.1,0.3,0.3,1.2,0.3,1.2c0,0.1,0.5,0.7,0.5,1c0,0.3-0.6,0.7-0.7,0.9
		c-0.2,0.4-0.3,1.3-0.2,1.7c0.1,0.3,0.5,0.9,0.6,1.3c0,0.4-0.3,1.3-0.5,1.7c-0.1,0.3-0.5,0.8-0.6,1.1c0,0,0,0.1,0,0.1l3.8,0
		c0.3-0.8,0.5-1.9,0.8-2.4c0.7,0.1,1.3,0.2,2,0.3c0.2,0.7,0.4,1.5,0.9,2.1c0,0.1,0.1,0.1,0.1,0.2c0.2,0.2,0.5,0.4,0.8,0.5
		c0.6,0.4,1.3,0.8,1.9,1.2c0.3,0.2,0.5,0.3,0.8,0.5c2.5-1,5.3-0.7,7.9-1.1c0.7-0.1,1.4-0.3,2.1-0.5c1.1-0.3,2.3-0.5,3.4-0.8
		c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.2-0.1,0.3-0.2c0,0,0,0,0,0v-2c1.2,0,2.4,0,3.6,0c0,0.7,0,1.4,0.1,2.1l9.3-0.1c0,0,0.9,0.5,1.3,0.6
		c0.4,0.1,1.3,0.1,1.8,0.1c0.4,0.1,1.3,0.5,1.7,0.3c0.3-0.1,0.8-0.8,1-0.8c0.1,0,1.1-0.1,1.4,0c0.2,0.1,0.6,0.4,0.8,0.5
		c0.3,0.1,0.9,0.1,1.1,0c0.2-0.1,0.4-0.5,0.7-0.6c0.2-0.1,0.9,0,1,0s0.9,0.1,1.2,0c0.2,0,0.7-0.2,0.9-0.4c0.3-0.3,0.4-1.3,0.6-1.7
		c0.2-0.3,1-1,1-1s1-0.1,1.2-0.8c0.2-0.7,0.6-1,0.6-1.3c0-0.4-0.7-1-0.8-1.4c-0.1-0.3,0.3-0.9,0.2-1.2c-0.1-0.3-0.8-0.5-0.9-0.7
		c-0.2-0.4,0.4-1.4,0.2-1.8c-0.2-0.4-1.3-0.5-1.3-0.9c0-0.3,0.7-0.4,0.8-0.6c0.2-0.2,0.3-0.8,0.5-1c0.3-0.3,1-0.4,1.3-0.6
		c0.3-0.2,0.6-0.9,0.7-1.3c0.1-0.3-0.2-1.1,0-1.4c0.2-0.3,1-0.3,1.3-0.6c0.2-0.2,0.6-0.9,0.6-1.2c-0.1-0.3-0.9-0.4-1-0.7
		c-0.1-0.2,0.3-0.8,0.2-1c-0.1-0.2-0.4-0.4-0.6-0.5c-0.3-0.2-1.1-0.1-1.3-0.4c-0.2-0.3,0-1.2,0.2-1.5c0.2-0.2,0.9,0.2,1.1,0.1
		c0.2-0.1,0.5-0.5,0.7-0.6c0.2,0,0.7,0.3,0.9,0.3c0.2,0,0.6,0,0.7-0.2c0.2-0.3-0.4-1.1-0.4-1.5c0-0.3,0.1-0.9,0.2-1.1
		c0.2-0.3,0.7-0.9,1.1-1c0.2-0.1,0.6,0.1,0.7,0c0.2-0.1,0.4-0.4,0.5-0.5c0.2-0.2,0.5-0.5,0.7-0.8c-0.1,0-0.1-0.1-0.1-0.1
		c-0.2-0.3-0.4-1.2-0.2-1.6c0.1-0.3,0.8-0.7,0.9-1c0.1-0.3-0.2-1-0.2-1.4c0-0.4,0.1-1.1,0.2-1.4c0.1-0.3,0.6-0.6,0.7-0.9
		c0.1-0.4,0-1.1-0.1-1.7c0-0.2,0-0.4,0-0.5c0-0.3,0.2-0.8,0.2-1C201.2,391.1,200.9,390.4,200.9,390z"/>
	<path @click="openEmc" data-emc="upson-emc" ref="upson-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M183.3,293.4c-0.8-1-2.3-2-2.3-3.3c0-2,0-3.7,0-5.7h-1.4c-2.4-1.1-4.7-2.3-7.2-2.9c-0.7-0.2-1.5-0.4-1.8-1.1
		c-0.3-0.9,0.6-1.8,1.3-2.4c0.9-0.9,1.6-1.9,2-3c-1-1-2-2-3-3c0.6-0.7,1-1.5,1.4-2.3c0.3-0.5,0.5-1.1,0.5-1.6
		c-0.1-1.3-1.3-2.1-2.4-2.7c-1.3-0.8-2.7-1.9-3.1-3.4l0,0c-0.2-0.8-0.4-1.6-0.9-2.3c-1-1.3-2.5-1.9-4.1-2.2
		c-1.6-0.3-3.2-0.3-4.7-0.6c-0.4-0.8-0.7-1.7-0.8-2.5c-0.7,0-1.3,0-2,0c-0.2,0-0.4,0-0.5,0.1c-0.1,0.1-0.2,0.3-0.2,0.5
		c-0.1,0.9-0.3,1.8-0.4,2.7c-0.9,0.1-1.8,0.3-2.7,0.6c-0.8-1-1.6-2.1-2.4-3.2c-2.4,0.1-4.8,0-7.2-0.2c0,0.2-0.1,0.3-0.1,0.4
		c-0.2,0.2-0.8,0.3-1,0.5c-0.2,0.2-0.5,0.9-0.5,1.2c0,0.1,0.1,0.3,0.2,0.4c0,0,0,0,0,0c-0.3,0.7-0.5,1.4-0.8,2.1
		c-1.5,4.4-4.2,8.5-6.8,12.3c0,2.7,0.1,5.4,0.3,8l-8.8,0.1l-0.2-0.1c0.2,0.4,0.4,0.7,0.4,1c0.1,0.5,0.3,1.5,0.3,2
		c0,0.5-0.2,1.4-0.1,1.9c0,0.6,0.6,1.8,0.5,2.5c-0.1,0.3-0.6,0.6-0.6,0.9c-0.2,0.5,0,1.7,0.1,2.2c0.1,0.4,0.1,1.3,0.2,1.9h4
		l12.1,4.2l5.3,7l-0.1,2.4l-1.8,2.3h9.2c0,0,1-0.5,1.2-0.7c0.5-0.4,1.4-1.2,1.7-1.8c0.2-0.3,0.6-1,0.7-1.4c0.1-0.5,0-1.5,0.1-2
		c0.1-0.3,0.4-0.9,0.5-1.2c0-0.2-0.2-0.8,0-1c0.1-0.2,0.6-0.3,0.8-0.3c0.4,0,1.3,0.4,1.7,0.2h6.3l1.9-4.6l3.3-1
		c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.6,0.2,0.7,0.4c0.4,0.3,0.9,1.2,1.1,1.7c0.2,0.3,0.3,1,0.5,1.3c0,0.1,0.1,0.1,0.1,0.2
		c0.2,0.4,0.6,0.9,0.8,1.1c0.4,0.3,1.3,0.6,1.8,0.7c0.4,0.1,1.4,0.1,1.8-0.1c0.3-0.1,0.5-0.7,0.8-0.8c0.2-0.1,0.7-0.1,0.9,0
		c0.3,0.2,0.3,1,0.6,1.1c0.2,0.1,0.7,0.1,0.9,0c0.3-0.2,0.6-1,1-1.1c0.3-0.1,0.9-0.1,1.2,0.1c0.1,0.1,0.2,0.2,0.2,0.3
		c0.4-0.4,0.7-0.9,1.1-1.3c0.3-0.4,0.6-0.8,0.9-1.1C185.1,295.8,184.3,294.5,183.3,293.4z"/>
		<path @click="openEmc" data-emc="southern-rivers-energy" ref="southern-rivers-energy" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M219.9,288.1l-4.8-0.7l-2.4-1.1l-2.7-2.6l0.3-2.1l0.8-1.6l2.7-0.5l-1.4-1.6l-0.4-0.7l0,0l-2.9-0.8
			c-0.6-0.4-1-1-1.3-1.6c-0.4-0.8-5.6-8.4-5.8-9.7c-0.2-0.9-0.3-1.8-0.7-2.6c-0.4-0.6-4.1-6.6-4.8-7.8h-2c-0.1,0-0.2,0-0.3,0
			c0-1.2-0.1-2.4-0.1-3.6c0-0.8,0-1.6-0.2-2.4c-0.4-2.6-2-4.8-3.5-6.9v-0.2c-1,0.4-2,0.8-2.9,1.4c-0.3,0.2-0.5,0.3-0.8,0.4
			c-0.2,0.1-0.5,0.1-0.7,0.1c-1,0-2,0.1-3,0.1c0-1.1,0-2.2,0-3.3c0-0.2,0-0.3,0-0.5c-0.1-0.3-0.3-0.5-0.5-0.6
			c-0.1-0.1-0.3-0.3-0.4-0.4l-10.7,2.2l-2.2-3.6c0,0-2.2-1.9-3.1-1.5c-0.5,0.2-1.5,0.9-2.4,1.5h-7.1v-1.3h-2.1v-0.9l-5.2,0
			c0,0-0.5,0.4-0.7,0.5c-0.3,0.2-0.8,0.4-1.1,0.5c-0.3,0.1-1-0.3-1.3-0.1c-0.3,0.2-0.2,0.9-0.4,1.2c-0.1,0.2-0.6,0.5-0.8,0.6
			c0,0-0.1,0-0.2,0c-0.3-0.1-0.7-0.3-0.8-0.5c-0.2-0.3,0-1.1,0-1.5c0-0.4,0.2-1.1,0-1.5c-0.1-0.2-0.6-0.5-0.8-0.7h-5.6
			c-0.3,0.6-0.8,1.4-1.3,1.6c-0.9,0.3-3.2,2.5-3.2,2.5l-5.6,0.1c0,0-2.2,1.6-2.6,2.1c-0.4,0.5-1.5,2.1-1.8,2.8c-0.3,0.7-4.3,0.7-5,0
			c-0.7-0.7-1.1-1.7-1.9-3.1c-0.2-0.3-0.5-0.7-0.9-1.1l-2,2.7l-1.1,2.5l0.4,2.4l1.5,2.3h7.8l0.4,1.8v2.1l-0.9,2.9l1.2,4.7
			c0,0,0.2,1,2.3,2.5c2.1,1.5,5.5,3.1,6.8,3.2c0.9,0.1,3.1,0.1,4.4,0c1.1-2,2.1-4,2.9-6.1c0.2-0.7,0.5-1.4,0.8-2.1
			c-0.1-0.2-0.2-0.3-0.2-0.4c0-0.3,0.3-0.9,0.5-1.2c0.2-0.2,0.9-0.3,1-0.5c0.1-0.1,0.1-0.3,0.1-0.4c2.4,0.2,4.8,0.3,7.2,0.2
			c0.7,1.1,1.5,2.2,2.4,3.2c0.9-0.3,1.8-0.5,2.7-0.6c0.1-0.9,0.3-1.8,0.4-2.7c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.1,0.5-0.1
			c0.7,0,1.3,0,2,0c0.2,0.9,0.4,1.7,0.8,2.5c1.6,0.3,3.2,0.3,4.7,0.6c1.6,0.3,3.1,1,4.1,2.2c0.5,0.7,0.8,1.5,0.9,2.3
			c0.4,1.5,1.8,2.5,3.1,3.4c1.1,0.7,2.3,1.5,2.4,2.7c0,0.6-0.2,1.1-0.5,1.6c-0.4,0.8-0.9,1.6-1.4,2.3c1,1,2,2,3,3
			c-0.4,1.1-1.1,2.2-2,3c-0.7,0.7-1.6,1.5-1.3,2.4c0.3,0.7,1.1,0.9,1.8,1.1c2.6,0.6,4.8,1.8,7.2,2.9h1.4v-0.1c0,0,0.1,0,0.1,0h1.3
			c1.4,0,2.6-1.1,3.8-1.9c0.3-0.3,0.7-0.6,1.1-0.6c1.1,0.7,2.3,1.3,3.5,1.8c0.6,0.3,1.2,0.5,1.9,0.4c0.3-0.1,0.7-0.3,1-0.4
			c0.3-0.2,0.6-0.4,0.9-0.6c0.5,0.5,1.1,1,1.7,1.4c-0.4,0.4-0.7,0.9-1.1,1.3c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.3,0,0.4
			c0.2,1.4,0.6,2.8,1,4.2c0.2,0.5,0.3,1,0.6,1.4c0.6,0.8,1.5,1.3,2.5,1.2c0.4,0,0.8-0.1,1.2-0.2c0-0.9-0.1-1.9-0.1-2.8
			c-0.1-1.2-0.1-2.5,0.7-3.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.3-0.1,0.4-0.1c1.1-0.1,2.2-0.3,3.3-0.7c0.2,0.6,0.6,1.2,1,1.7
			c0.3,0.4,0.6,0.7,0.9,1c0.9,1.1,1.5,2.4,2.5,3.3c-1.4,1.7-1.9,4-3.4,5.5c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.2,0.1,0.4,0.2,0.6
			c0.5,0.8,1,1.6,1.6,2.4c2.2-0.1,4.4-0.2,6.6-0.2c0.2,0,0.3,0,0.5,0l0,0l2.9,0.2l1.8-2.1l0.4-0.9l0.5-1.1l0.2-0.1l3.5-3v-1.6
			L219.9,288.1z"/>
	<path @click="openEmc" data-emc="flint-emc" ref="flint-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M246.5,330.6c-0.1-0.3-0.3-1-0.4-1.3c-0.1-0.3,0-0.9-0.1-1.2c-0.1-0.2-0.4-0.4-0.4-0.6c0,0,0,0,0,0
		c-0.1-0.3,0.3-1,0.4-1.3c0.1-0.5,0.4-1.7,0.2-2.2c-0.2-0.5-1.3-1-1.7-1.3c-0.3-0.3-1.2-1-1.4-1.1c-0.1-0.1-0.5-1-0.7-1.3
		c-0.1-0.1-0.4-0.4-0.5-0.5c-0.3-0.4-0.8-1.3-0.9-1.8c-0.1-0.5,0.5-1.6,0.4-2.1c-0.1-0.4-1.1-1.2-1.1-1.3c0-0.1-0.3-1.3-0.1-1.6
		c0.2-0.2,0.9-0.1,1.1-0.3c0.2-0.2,0.2-1.2,0.2-1.3s-0.5-0.7-0.6-1c-0.1-0.4,0.3-1.1,0.2-1.4c-0.1-0.3-0.9-0.7-0.8-1
		c0-0.2,0.5-0.3,0.6-0.5c0.1-0.4-0.1-1.2-0.2-1.5c-0.1-0.2-0.5-0.8-0.6-0.9c0,0-0.2-0.3-0.3-0.6c0-0.1-0.1-0.1-0.1-0.2
		c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2
		c-0.2-0.2-0.6-0.8-0.8-0.9c-0.2-0.1-0.8,0.2-1,0.2c-0.4-0.1-0.9-0.8-1.2-1c-0.2-0.1-0.5-0.3-0.7-0.4c-0.1,0-0.1,0-0.2,0
		c-0.2,0-0.4,0.3-0.6,0.3c-0.4,0.1-1.2,0.2-1.5,0.1c-0.1,0-0.2-0.1-0.4-0.2l-2.7,0.9l-3.7,1.4l-2,2.7l-1.6,0.3l-2.2,0.4l-2-0.4
		l-1.5-2.3l0.5-3.3l-2.9-0.2l0,0c-2.4,0-4.7,0.1-7.1,0.2c-0.5-0.8-1-1.6-1.6-2.4c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.2,0.2-0.4,0.3-0.5
		c1.5-1.5,2-3.8,3.4-5.5c-1-0.9-1.6-2.3-2.5-3.3c-0.3-0.3-0.6-0.6-0.9-1c-0.4-0.5-0.7-1.1-1-1.7c-1.1,0.4-2.2,0.6-3.3,0.7
		c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.8,0.9-0.8,2.2-0.7,3.3c0,0.9,0.1,1.9,0.1,2.8c-0.4,0-0.8,0.2-1.2,0.2
		c-1,0-1.9-0.5-2.5-1.2c-0.3-0.4-0.5-0.9-0.6-1.4c-0.5-1.4-0.8-2.8-1-4.2c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.2-0.3
		c0.4-0.4,0.7-0.9,1.1-1.3c-0.6-0.4-1.2-0.9-1.7-1.4c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.6,0.1-1.3-0.1-1.9-0.4
		c-1.2-0.5-2.4-1.1-3.5-1.8c-0.4,0.1-0.8,0.4-1.1,0.6c-1.1,0.8-2.4,1.9-3.8,1.9h-1.3c0,0-0.1,0-0.1,0v0.1c0,2,0,3.7,0,5.7
		c0,1.3,1.4,2.3,2.3,3.3c1,1.2,1.7,2.4,2.5,3.7c-0.3,0.4-0.6,0.8-0.9,1.1c-0.4,0.4-0.7,0.9-1.1,1.3l0,0c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.2-0.2-0.9-0.2-1.2-0.1c-0.3,0.1-0.6,0.9-1,1.1c-0.2,0.1-0.7,0.1-0.9,0c-0.3-0.2-0.3-1-0.6-1.1c-0.2-0.1-0.7-0.1-0.9,0
		c-0.3,0.1-0.5,0.7-0.8,0.8c-0.4,0.2-1.3,0.2-1.8,0.1c-0.5-0.1-1.4-0.4-1.8-0.7c-0.3-0.2-0.6-0.7-0.8-1.1c0-0.1-0.1-0.1-0.1-0.2
		c-0.2-0.3-0.3-1-0.5-1.3c-0.2-0.4-0.8-1.3-1.1-1.7c-0.1-0.1-0.6-0.2-0.7-0.4c-0.1-0.1-0.2-0.2-0.3-0.4l-3.3,1l-1.9,4.6h-6.3
		c-0.4,0.2-1.3-0.3-1.7-0.2c-0.2,0-0.7,0.1-0.8,0.3c-0.1,0.2,0.1,0.7,0,1c-0.1,0.3-0.4,0.9-0.5,1.2c-0.1,0.5,0,1.5-0.1,2
		c-0.1,0.4-0.4,1.1-0.7,1.4c-0.3,0.5-1.2,1.4-1.7,1.8c-0.3,0.2-1.2,0.7-1.2,0.7h-9.2l1.8-2.3l0.1-2.4l-5.3-7l-12.1-4.2h-4
		c0,0.1,0,0.2,0.1,0.3c0.1,0.4,0.8,1,0.6,1.4c-0.1,0.2-0.6,0.4-0.7,0.6c-0.2,0.4,0.2,1.2,0,1.6c-0.1,0.2-0.5,0.4-0.6,0.6
		c-0.2,0.3-0.5,1-0.5,1.3c-0.1,0.3,0.1,1.4,0.1,1.4h0.9v4.1h-0.4v8l-4.5,0l-26.1,14.9c0.1,0.2,0.2,0.5,0.3,0.6
		c0.3,0.5,1,1.4,1.4,1.8c0.3,0.3,1.3,0.6,1.4,1c0.1,0.3-0.1,0.8-0.2,1.1c-0.2,0.4-0.9,0.8-1.1,1.2c-0.1,0.2-0.3,0.7-0.3,1
		c0,0.4,0.9,0.9,0.8,1.2c-0.1,0.4-1.1,0.4-1.4,0.7c-0.1,0.2-0.3,0.6-0.2,0.8c0.2,0.3,1.3,0.3,1.4,0.6c0,0,0,0.1,0,0.1
		c0.3,0,0.7,0,0.8,0.1c0.2,0,0.5,0.2,0.7,0.2c0.3,0.1,0.9,0.2,1.2,0.1c0.2,0,0.5-0.4,0.7-0.5c0.2-0.1,0.8-0.1,1-0.2
		c0.3-0.2,0.6-1,0.9-1.3c0.4-0.3,1.5-0.5,2-0.7c0.4-0.2,1.1-0.5,1.4-0.7c0.2-0.2,0.5-0.7,0.7-0.9c0.1-0.1,0.5,0,0.7,0
		c0.3-0.1,0.9-0.4,1.2-0.4c0.5-0.1,1.7-0.1,2.2-0.1c0.4,0,1.3,0.1,1.7,0.1c0.3,0,0.8-0.1,1.1-0.2c0.1-0.1,0.2-0.4,0.2-0.5
		c0.1-0.1,0.4-0.3,0.6-0.2c0.2,0,0.6,0.4,0.8,0.5c0.2,0.1,0.6,0.3,0.8,0.3c0.2,0,0.4-0.4,0.6-0.6c0.2-0.2,0.8-0.4,1.1-0.5
		c0.2-0.1,0.8,0,0.9-0.1c0.2-0.3-0.1-1.2,0-1.5c0.1-0.3,0.5-0.9,0.7-1.2c0.2-0.3,0.7-0.8,1-1c0.2-0.2,0.7-0.3,0.8-0.5
		c0.2-0.3-0.1-1.1,0-1.4c0.1-0.7,0.7-1.9,1-2.5c0.1-0.1,0.2-0.4,0.3-0.5c0.1-0.2,0.2-0.6,0.3-0.8c0.1-0.2,0.1-0.7,0.3-0.9
		c0.2-0.1,0.6,0,0.8-0.1c0.2-0.1,0.4-0.6,0.6-0.7c0.2-0.2,0.8-0.3,1.1-0.4c0.3,0,0.8,0.2,1,0.1c0.1,0,0.2-0.2,0.2-0.3
		c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.6-0.1,0.8-0.2c0.3-0.2,0.5-0.8,0.8-1c0.2-0.1,0.5-0.1,0.7-0.1c0.1,0,0.5,0.1,0.6,0
		c0.2-0.1,0.4-0.4,0.5-0.5c0.2-0.1,0.6-0.2,0.8-0.2c0.1,0,0.2,0.1,0.4,0.1v5.1h-2.2V345c2.4,1.9,4.5,4.3,7.4,5.3
		c2.8-1.2,5.4-2.8,7.6-4.8c0.3-0.3,0.6-0.6,1-0.8c0.3-0.1,0.6-0.2,0.9-0.3c1.1-0.2,2.4,0.1,3.4-0.4c0.3-0.1,0.6-0.3,0.9-0.4
		c0.5-0.1,0.9,0.1,1.4,0.2c0.6,0.1,1.2-0.2,1.6-0.7c0.9,1,1.8,2,2.7,2.8c0.5,0.4,0.9,0.8,1.5,1.2c1.6,1.1,3.5,1.8,5.5,1.6
		c0.3,0,0.6-0.1,0.8,0c0.2,0.1,0.4,0.3,0.6,0.5c0.6,0.9,0.9,1.9,1.5,2.8c0.6,0.9,1.5,1.7,2.6,1.6c0.5,0,1.1-0.3,1.5,0
		c0.3,0.2,0.4,0.6,0.5,1c0.5,1.1,1.8,1.6,2.9,1.9c1.3,0.4,2.7,0.8,4,1.3c0.4,0.1,0.8,0.3,1.2,0.4c1.4,0.4,2.5,0.9,3.9,1.2l2.8,0
		c0,0,0.9-0.3,1-0.3c0.1,0,0.9,0.2,1.2,0.3c0.2,0.1,0.6,0.5,0.8,0.6c0.5,0.2,1.6-0.1,2.1,0c0.2,0,0.5,0.1,0.8,0.3
		c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0.1,0.8,0.4,1.1,0.3c0.4-0.1,0.9-0.7,1.1-1c0.2-0.3,0-1,0.2-1.3c0.1-0.2,0.7-0.5,0.9-0.7
		c0.3-0.3,0.8-1.1,1.1-1.4c0.3-0.3,1.2-0.5,1.5-0.8c0.3-0.3,0.6-1.1,0.8-1.4c0.2-0.4,0.4-1.2,0.7-1.5c0.2-0.3,0.9-0.5,1.3-0.7
		c0.4-0.3,1.4-0.7,1.8-1c0.4-0.3,1.3-0.8,1.7-1.1c0.5-0.3,2.1-1.1,2.1-1.1v-1.4h5.4h0.7l1.7-3.5l4.3,2.2l2.4,0.4l0.9,0.9h2.9
		c0,0,0,0.5,0.1,0.6c0.2,0.2,0.7,0.5,1,0.5l1.9,1.1h3v-2v-2.2l3-0.3l1.4-1.1l2.6,1.5c0.1-0.4-0.1-1.2,0-1.6c0.1-0.3,0.5-0.9,0.7-1.3
		c0.2-0.3,0.5-1,0.7-1.3c0.4-0.6,1.2-1.7,1.6-2.2c0.3-0.4,0.9-1.1,1.1-1.5c0.5-0.6,1.3-2,1.8-2.6c0.1-0.2,0.4-0.6,0.6-0.7
		c0.2-0.1,0.6,0.2,0.8,0.3c0.3,0.1,0.9,0.7,1.3,0.7c0.2,0,0.6-0.3,0.7-0.5c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2,0.1-0.4,0.2-0.5
		c0.2-0.2,0.8,0,1-0.2c0.2-0.2,0.5-0.9,0.5-1.2C247.2,331.5,246.7,330.9,246.5,330.6z"/>
	<path @click="openEmc" data-emc="oconee-emc" ref="oconee-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M303.8,308c-0.8-0.7-1-2-1-2s0.6-2.8,0.4-4.7c-0.1-1.8-1.8-1.6-2.5-2.6s-0.5-2.4-0.5-2.4c0-0.6,0-1.2,0.1-1.6
		c0.1-0.4,0.8-0.9,1-1.3c0.2-0.6,0.3-2,0.1-2.5c-0.1-0.1-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.2-0.5-0.4c-0.3-0.4-0.7-1.2-0.7-1.7
		c0-0.6,0.5-1.6,0.6-2.2c0.1-0.5,0.1-1.4,0-1.8c-0.2-0.4-1.1-0.9-1.4-1.3c-0.2-0.4-0.1-1.4-0.2-1.9c-0.1-0.4-0.5-1.1-0.7-1.4
		c-0.2-0.2-0.7-0.4-0.9-0.6c-0.2-0.2-0.5-0.8-0.8-0.9c-0.2-0.1-0.5,0.1-0.7,0.1c-0.3,0-0.9-0.1-1.2-0.2c-0.2,0-0.8,0.1-1,0
		c-0.3-0.2-0.8-0.8-1-1.2c-0.2-0.4,0-1.2-0.1-1.6c-0.2-0.4-0.9-1-1.1-1.4c-0.1-0.4,0-1.2-0.1-1.6c-0.1-0.4-0.2-1.3-0.5-1.7
		c-0.2-0.3-1-0.7-1.2-1c-0.3-0.4-0.6-1.4-0.9-2c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.3-0.6-0.9-0.8-1.1c-0.3-0.2-1-0.5-1.2-0.8
		c-0.2-0.3-0.4-1-0.6-1.3c-0.3-0.3-1.4-0.6-1.4-0.6c0-0.1-0.7-0.7-0.8-1c-0.1-0.4,0.2-1.2,0.1-1.6c-0.1-0.3-0.5-0.9-0.8-1.1
		c-0.2-0.2-0.9-0.4-1.2-0.4c-0.2,0.1-0.3,0.6-0.5,0.7l0,1l-1.3,1.1l-0.7,3.3l-0.9,0l-2.4-1.5l-2,1.4l-0.2,4.1h-1l-3.6-4.2l-4.7-0.2
		l-1.1,2.9l-1.3,3.2l1,1.7v2.1c0,0-0.9,0.9-2.1,2.1c-0.5,0.5-1.7,1.3-3,1.9c-1.5,0.8-3,1.4-3.4,1.4c-0.7,0-4.3-0.2-5.4,0
		c-1.1,0.2-2.5,1.6-2.5,1.6l-1.3,1.7l1.1,0.9l4.5,1.8l2.1,2.2c0,0,0.2,1.2-0.4,1.7c-0.6,0.4-1.6,0.4-2.8,0c-1.2-0.4-3.5-0.5-3.5-0.5
		l-1,2.2l-1.9,2.5l3.3,3l-6.6,6.3c0.2,0.3,0.3,0.6,0.3,0.6c0.1,0,0.5,0.6,0.6,0.9c0.1,0.4,0.4,1.2,0.2,1.5c-0.1,0.2-0.6,0.3-0.6,0.5
		c0,0.3,0.7,0.7,0.8,1c0.1,0.4-0.2,1.1-0.2,1.4c0.1,0.3,0.6,0.9,0.6,1s0,1-0.2,1.3c-0.2,0.2-0.9,0.1-1.1,0.3c-0.2,0.3,0,1.5,0.1,1.6
		c0,0.1,1,0.9,1.1,1.3c0.1,0.5-0.5,1.6-0.4,2.1c0.1,0.5,0.6,1.4,0.9,1.8c0.1,0.1,0.4,0.4,0.5,0.5c0.2,0.3,0.6,1.2,0.7,1.3
		c0.1,0.1,1,0.8,1.4,1.1c0.4,0.3,1.5,0.8,1.7,1.3c0.2,0.5-0.1,1.6-0.2,2.2c-0.1,0.3-0.4,1-0.4,1.3c0,0,0,0,0,0l9.2-5.4
		c2.1,0.6,4.3,1.1,6.4,1.5c0.1-1.8,0.1-3.6,0.2-5.4l3.1-1.8c1.2,0.7,2.4,1.3,3.7,2c-0.2,1.7-0.3,3.4-0.3,5c0,0.3,0,0.6,0.2,0.9
		c0.2,0.3,0.5,0.5,0.8,0.7c2.4,1.5,4.7,3.2,6.7,5.2l-2.6,2.5c1,1.1,2.1,2.2,3.2,3.1c1.6-0.7,2.9-2,3.7-3.5c0.7,0.7,1.4,1.5,2.1,2.2
		c-1.3,1.2-2.4,2.6-3.4,4.1c2.4,0.6,4.8,1.1,7.2,1.4l0,0c1.6-1.1,3.1-2.2,4.6-3.4c0.4-0.3,0.8-0.7,0.9-1.2c0.1-0.7-0.4-1.4-0.9-2
		c-0.8-1-1.2-2.2-1.6-3.4c1.6,0,3.1,0,4.7,0c3-3,5.8-6,8.8-9.1c1.7-1.7,2.4-2.4,4.7-3.2v-3.2v-3.9
		C307.2,310.6,304.6,308.7,303.8,308z"/>
	<path @click="openEmc" data-emc="little-ocmulgee-emc" ref="little-ocmulgee-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M300.4,365.7l-2.5,1.3l2.2,1.8l2,1.6l-3,3l-3.2-0.3c-1.8-0.3-3.6-0.5-5.4-0.6c1.1,0.8,2.3,1.7,3.4,2.5
		c0,0,0,0,0.1,0l1,1l-1,3l-5.4,3.9l-0.9,5.2l-9.8,9.5c0,0.1,0.1,0.1,0.1,0.2c0.1,0.3,0.1,0.9-0.1,1.2c-0.1,0.2-0.6,0.4-0.7,0.6
		c0,0.1,0,0.3,0.1,0.5c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.5,0.4,0.7,0.6c0.2,0.2,0.3,0.7,0.5,0.9c0.2,0.2,0.9,0.4,1.1,0.7
		c0.2,0.2,0.4,0.8,0.7,1c0.2,0.1,0.5,0,0.7,0c0.2,0,0.7,0.1,0.8,0.3c0.1,0.2-0.1,0.6-0.1,0.8c0.1,0.2,0.5,0.5,0.7,0.7
		c0.2,0.2,0.7,0.5,1,0.4c0.2-0.1,0.3-0.5,0.4-0.6c0.2-0.2,0.5-0.7,0.8-0.7c0.3,0,0.7,0.4,0.9,0.6c0.2,0.2,0.4,0.8,0.6,0.9
		c0.2,0.1,0.6-0.4,0.7-0.3c0.1,0.1,0,0.6,0.1,0.7c0.2,0.2,1.1,0.1,1.2,0.1c0.1,0,0.8,0.6,1,0.8c0.2,0.1,0.4,0.5,0.6,0.6
		c0.4,0.1,1.2-0.2,1.5-0.1c0.2,0.1,0.4,0.4,0.5,0.6c0.2,0.2,0.3,0.9,0.6,1.1c0.2,0.1,0.8,0,1.1,0c0.3,0.1,1,0.5,1.3,0.5
		c0.2,0,0.7-0.2,0.9-0.3c0.1-0.1,0.3-0.3,0.4-0.5c0.1-0.1,0.2-0.2,0.2-0.2c0.2-0.1,0.6,0,0.8-0.1c0.2-0.1,0.4-0.7,0.6-0.9
		c0.2-0.1,0.7-0.2,0.8-0.1c0.2,0.1,0.3,0.8,0.6,0.9c0.3,0.2,1,0.4,1.3,0.3c0.2-0.1,0.4-0.6,0.7-0.7c0.3-0.2,0.9-0.4,1.2-0.4
		c0.3,0,0.6,0.5,0.9,0.7c0.2,0.1,0.8,0.4,1,0.4c0.2,0,0.6-0.4,0.8-0.5c0.2-0.1,0.7-0.3,0.9-0.3c0.3,0.1,0.6,0.9,0.7,0.9
		c0.1,0,0.8,0,0.9,0c0.1,0,0.4-0.6,0.7-0.7c0.2-0.1,0.7,0.3,0.9,0.3c0.3,0,0.8-0.2,1-0.4c0.3-0.3,0.2-1.1,0.5-1.4
		c0.2-0.2,0.8-0.1,0.9-0.3c0.2-0.2,0-0.8,0.2-1c0.2-0.3,0.8-0.6,1.1-0.7c0,0,0.1,0,0.1-0.1c0.2-0.1,0.5-0.2,0.7-0.3
		c0.2,0,0.8,0.1,1,0c0.2-0.1,0.5-0.5,0.7-0.7c0.1-0.2,0.2-0.7,0.3-0.9c0.1-0.1,0.6-0.2,0.8-0.3c0.2-0.2,0.6-0.7,0.7-0.9
		c0-0.2-0.1-0.5-0.1-0.7c0-0.2,0-0.5,0.1-0.6c0.1-0.1,0.6-0.1,0.7-0.2c0.3-0.2,0.5-0.8,0.7-0.9c0.4-0.1,1.2,0.2,1.6,0.2
		c0.1,0,0.5,0.2,0.6,0.1c0.3-0.1,0.5-0.8,0.6-1.1c0.1-0.3-0.2-0.9,0-1.1c0.2-0.3,1-0.4,1.3-0.5c0.4-0.1,1.2-0.4,1.6-0.5
		c0.2-0.1,0.7-0.2,0.9-0.4c0.2-0.2,0.3-1,0.6-1.2c0.2-0.2,0.9-0.4,1.2-0.6c0.2-0.2,0.5-0.7,0.7-0.9c0.2-0.2,0.8-0.3,0.9-0.5
		c0.1-0.2-0.1-0.7,0-0.9c0.1-0.1,0.6-0.1,0.7-0.1c0.3,0,1,0,1.2,0c0.1,0,0.4,0,0.7,0c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.5-0.4,0.7-0.4
		c0.5-0.2,1.8,0.1,2.2-0.2c0.2-0.1,0.3-0.6,0.5-0.7c0.3-0.2,0.9-0.5,1.2-0.5c0.3,0,0.7,0.8,1,0.7c0.2-0.1,0.2-0.7,0.4-0.9
		c0.2-0.3,0.9-0.8,1.1-1.1c0.2-0.3,0.3-0.9,0.6-1.1c0.2-0.2,0.9-0.3,1.2-0.3c0.3,0,0.9,0.6,1.2,0.6c0.3,0,0.9-0.3,1.3-0.4
		c-0.3-0.4-0.6-0.7-0.7-1c-0.2-0.4-0.1-1.4-0.3-1.7c-0.1-0.2-0.6-0.2-0.7-0.4c-0.2-0.2-0.4-0.7-0.6-0.9c-0.2-0.1-0.7-0.1-0.9-0.2
		c-0.2-0.1-0.5-0.7-0.7-0.9c-0.1-0.1-0.5-0.3-0.6-0.4c-0.3-0.2-1.1-0.5-1.4-0.8c-0.2-0.2-0.2-0.7-0.4-0.9c-0.1-0.2-0.4-0.4-0.4-0.6
		c0-0.2,0.4-0.6,0.3-0.8c-0.1-0.2-0.7-0.4-0.7-0.6c-0.1-0.4,0.5-1.1,0.4-1.4c-0.1-0.3-0.8-0.6-0.9-0.8c-0.1-0.2-0.2-0.7-0.2-1
		c0-0.4,0.3-1.3,0.2-1.4c0-0.1-1-0.6-1.1-1c0-0.2,0.5-0.5,0.6-0.7c0.2-0.3,0.4-0.9,0.4-1.2c0-0.4-0.7-0.9-0.7-1.3
		c-0.1-0.3,0.1-0.9,0-1.1c-0.1-0.3-0.8-1-0.9-1.1c0-0.1-0.6-0.7-0.5-1c0.1-0.3,0.7-0.6,0.9-0.8c0.1-0.2,0.2-0.8,0.4-1
		c0.1-0.2,0.6-0.6,0.6-0.9c0.1-0.4-0.2-1.1-0.4-1.4c-0.2-0.3-0.8-0.5-1-0.7c-0.3-0.4-0.7-1.5-0.7-2c0-0.4,0.4-1,0.5-1.4
		c0.1-0.2,0.1-0.6,0.1-0.9c0-0.3-0.2-0.7-0.3-1c-0.1-0.2-0.5-0.5-0.6-0.7c-0.1-0.3,0.4-0.9,0.3-1.2c-0.1-0.4-0.6-1-0.9-1.3
		c-0.2-0.2-0.8-0.2-0.8-0.5c-0.1-0.4,0.5-1.3,0.7-1.7c0.1-0.3,0.6-1,0.6-1.1c0-0.1-0.2-0.8-0.3-1c-0.1-0.2-0.7-0.4-0.7-0.6
		c-0.1-0.3,0.2-1,0.1-1.3c-0.1-0.2-0.3-0.6-0.5-0.7c-0.1-0.1-0.4-0.3-0.6-0.5c-0.3-0.2-0.6-0.4-0.8-0.3c-0.4,0.1-0.7,1.5-0.7,1.5
		c0-0.1-1.1-0.6-1.5-0.7c-0.3-0.1-1,0.2-1.2,0c-0.3-0.2-0.7-0.9-1-1.1c-0.2-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.8,0-1-0.1
		c0,0-0.1,0-0.1,0c-0.4-0.2-0.7-1.1-0.9-1.4c-0.2-0.4-1-1-0.9-1.5c0.1-0.4,1.1-0.6,1.3-0.9c0.2-0.3,0.2-0.9,0.2-1.4
		c-2.3-2-3.5-2.1-4.9-2.4c-1.4-0.4-3-0.2-3.8-1.1c-0.8-0.9-0.5-3.3-0.6-5.5c-0.1-2.2-1.5-5.7-2-7.5c-0.5-1.8-4.6-5.4-4.6-5.4
		c-2.3,0.8-3,1.5-4.7,3.2c-3,3-5.8,6-8.8,9.1c-1.6,0-3.1,0-4.7,0c0.4,1.2,0.9,2.4,1.6,3.4c0.4,0.6,1,1.3,0.9,2
		c-0.1,0.5-0.5,0.9-0.9,1.2c-1.5,1.2-3,2.3-4.6,3.4l8.1,17.8c0.7,0.3,1.4,0.5,2.1,0.8c2.2,0.8,4.2,1.7,6.2,2.9c0,0.8,0,1.6,0.1,2.4
		L300.4,365.7"/>
	<path @click="openEmc" data-emc="ocmulgee-emc" ref="ocmulgee-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M297.9,367l2.5-1.3l2-1.8c0-0.8,0-1.6-0.1-2.4c-2-1.2-4-2.1-6.2-2.9c-0.7-0.3-1.4-0.5-2.1-0.8l0,0l-8.2-17.8
		c-2.4-0.3-4.8-0.7-7.2-1.4c1-1.5,2.1-2.8,3.4-4.1c-0.7-0.7-1.4-1.5-2.1-2.2c-0.9,1.5-2.2,2.7-3.7,3.5c-1.2-0.9-2.2-2-3.2-3.1
		l2.6-2.5c-2-2-4.3-3.7-6.7-5.2c-0.3-0.2-0.6-0.4-0.8-0.7c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-1.7,0-3.4,0.3-5c-1.2-0.7-2.4-1.3-3.7-2
		l-3.1,1.8c-0.1,1.8-0.1,3.6-0.2,5.4c-2.2-0.4-4.3-0.9-6.4-1.5l-9.2,5.4c0,0.2,0.4,0.5,0.4,0.6c0.1,0.3,0.1,0.9,0.1,1.2
		c0.1,0.3,0.3,1,0.4,1.3c0.1,0.3,0.6,1,0.7,1.3c0,0.3-0.2,1-0.5,1.2c-0.2,0.2-0.8,0-1,0.2c-0.1,0.1-0.1,0.3-0.2,0.5
		c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.2-0.5,0.5-0.7,0.5l1.5,1.3v8.7h4.1v6c0,0,1.8,1.3,2.2,2.3c0.5,0.9,0.8,2.3,0.9,3.8
		c0.1,1.6,0,3.2,0,3.2l1.5,1.7v3.1l2.3,1.2c0.3-0.1,0.5-0.2,0.7-0.1c0.3,0.1,0.6,0.5,0.8,0.7c0.2,0.2,0.8,0.5,1,0.8
		c0.2,0.3,0.3,1.1,0.4,1.5c0,0.1,0,0.5,0.2,0.6c0.2,0.1,0.7-0.3,0.9-0.3c0.2,0,0.5,0.2,0.8,0.4c0.2,0.2,0.5,0.4,0.6,0.5
		c0.1,0.2,0,0.7,0.1,1c0.1,0.3,0.4,0.8,0.7,0.9c0.2,0.1,0.6-0.1,0.8-0.2c0.2-0.1,0.2-0.7,0.4-0.7c0.2,0,0.4,0.5,0.5,0.7
		c0.1,0.3,0.2,0.9,0.2,1.2c0,0.3-0.3,0.7-0.4,1c0,0.4,0.3,1.1,0.2,1.5c-0.1,0.3-0.7,0.6-0.7,0.9c0,0.2,0.5,0.3,0.6,0.4
		c0.2,0.2,0.5,0.6,0.6,0.8c0.1,0.2-0.1,0.6,0,0.8c0.1,0.2,0.6,0.4,0.6,0.7c0.1,0.3,0,0.8-0.1,1.1c-0.1,0.1-0.5,0.2-0.5,0.4
		c-0.1,0.2,0,0.8,0.1,1.1c0.2,0.3,0.8,0.4,1,0.6c0.2,0.2,0.4,1.1,0.4,1.1c0,0.1-0.2,0.8-0.3,1.1c0,0.2,0.1,0.6,0.2,0.7
		c0.1,0.2,0.5,0.5,0.7,0.7c0.2,0.3,0.7,1.4,0.8,1.5c0.1,0,0.5,0.6,0.7,0.7c0.2,0.1,0.7,0,0.9,0c0.3,0.1,0.8,0.2,1.1,0.4
		c0.2,0.1,0.3,0.5,0.5,0.6c0.2,0.1,0.6,0.2,0.7,0.3c0.3,0.2,0.5,0.9,0.7,1.1c0.1,0.2,0.4,0.4,0.5,0.6c0.1,0.4-0.3,1.2-0.1,1.5
		c0.2,0.3,1,0.3,1.2,0.6c0.1,0.2,0.1,0.6,0.2,0.7c0,0.1,0,0.2,0.1,0.2c0.1,0.3,0.1,0.7,0.1,0.9c0,0.2-0.2,0.6-0.2,0.8
		c0.1,0.3,0.5,0.9,0.8,1.1c0.3,0.2,0.9,0.5,1.2,0.5c0.2,0,0.3-0.4,0.5-0.4c0.2,0,0.5,0.2,0.6,0.5l9.8-9.5l0.9-5.2l5.4-3.9l1-3l-1-1
		c0,0,0,0-0.1,0c-1.1-0.8-2.3-1.7-3.4-2.5c1.8,0.1,3.6,0.3,5.4,0.6l3.2,0.3l3-3l-2-1.6L297.9,367z"/>
	<path @click="openEmc" data-emc="mitchell-emc" ref="mitchell-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M225.5,435.4v-4v-3.4l-3.8-4.2l2.2-0.5v-2.7l-4.1-0.8l-4.7-2.7l0-10.4c-0.3,0.1-0.6,0.2-0.6,0.2
		c-0.1-0.1-0.9-1.3-1.3-1.5c-0.3-0.1-0.9,0.1-1.2,0c-0.2-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.2-0.8-0.5-0.9c-0.1-0.1-0.4,0.1-0.6,0.1
		c-0.2,0-0.7,0.2-0.8,0.1c-0.1-0.1-0.5-0.5-0.5-0.6c0-0.1,0-0.9-0.2-1.1c-0.3-0.2-1.2,0.2-1.6,0.1c-0.3-0.1-0.8-0.5-1.2-0.5
		c-0.4-0.1-1.2,0-1.5,0c-0.2,0-0.7,0.2-1,0.1c-0.3-0.1-0.8-0.5-1.1-0.6c-0.3-0.1-1.1,0.1-1.4,0.1c-0.3-0.1-0.9-0.2-1.2-0.4
		c-0.2,0.3-0.5,0.6-0.7,0.8c-0.1,0.1-0.3,0.4-0.5,0.5c-0.2,0.1-0.6-0.1-0.7,0c-0.3,0.1-0.9,0.7-1.1,1c-0.1,0.3-0.2,0.8-0.2,1.1
		c0,0.4,0.6,1.2,0.4,1.5c-0.1,0.2-0.5,0.2-0.7,0.2c-0.2,0-0.7-0.3-0.9-0.3c-0.2,0-0.5,0.5-0.7,0.6c-0.3,0.1-0.9-0.2-1.1-0.1
		c-0.3,0.2-0.4,1.2-0.2,1.5c0.2,0.3,1,0.2,1.3,0.4c0.2,0.1,0.6,0.3,0.6,0.5c0.1,0.2-0.3,0.8-0.2,1c0.1,0.3,0.9,0.4,1,0.7
		c0.1,0.3-0.3,1-0.6,1.2c-0.3,0.3-1.1,0.3-1.3,0.6c-0.2,0.3,0.1,1.1,0,1.4c-0.1,0.3-0.4,1-0.7,1.3c-0.3,0.2-1,0.4-1.3,0.6
		c-0.2,0.2-0.3,0.8-0.5,1c-0.2,0.2-0.8,0.3-0.8,0.6c0,0.4,1.2,0.6,1.3,0.9c0.2,0.4-0.4,1.4-0.2,1.8c0.1,0.3,0.8,0.5,0.9,0.7
		c0.1,0.3-0.3,0.9-0.2,1.2c0.1,0.4,0.8,1,0.8,1.4c0,0.3-0.4,0.6-0.6,1.3c-0.2,0.6-1.2,0.8-1.2,0.8s-0.8,0.7-1,1
		c-0.2,0.4-0.3,1.4-0.6,1.7c-0.2,0.2-0.7,0.3-0.9,0.4c-0.3,0.1-1,0-1.2,0s-0.8-0.1-1,0c-0.2,0.1-0.4,0.5-0.7,0.6
		c-0.3,0.1-0.9,0.1-1.1,0c-0.2-0.1-0.6-0.4-0.8-0.5c-0.3-0.1-1.3,0-1.4,0c-0.1,0-0.7,0.7-1,0.8c-0.4,0.1-1.3-0.3-1.7-0.3
		c-0.4-0.1-1.3,0-1.8-0.1c-0.3-0.1-1.3-0.6-1.3-0.6l-9.3,0.1c0-0.7,0-1.4-0.1-2.1c-1.2,0-2.4,0-3.6,0v2c0,0,0,0,0,0
		c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.3,0.1-0.5,0.1c-1.1,0.3-2.3,0.5-3.4,0.8c-0.7,0.2-1.4,0.3-2.1,0.5c-2.6,0.4-5.4,0.1-7.9,1.1
		c-0.3-0.2-0.5-0.3-0.8-0.5c0,0.4,0,0.8-0.1,1c-0.1,0.2-0.7,0.4-0.8,0.6c-0.1,0.2-0.3,0.7-0.3,0.9c0,0.3,0.5,0.9,0.6,1.2
		c0.1,0.3,0.1,1,0,1.3c0,0.3-0.3,0.9-0.4,1.2c0,0.2-0.1,0.6,0,0.8c0.2,0.2,0.9,0.2,1.1,0.5c0.2,0.3,0,1.1,0.1,1.5
		c0.1,0.4,0.5,1,0.6,1.3c0.1,0.4-0.2,1.2,0,1.5c0,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0.1,0c0.2,0.1,0.6,0.3,0.7,0.4
		c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3,0.3-0.7,0.7-1,1c-0.2,0.2-0.5,0.5-0.7,0.8c-0.2,0.3-0.4,0.6-0.6,0.8c-0.3,0.5-0.6,0.9-0.9,1.4
		c-2-0.1-4-0.2-6-0.2c-0.4,0.6-0.8,1.2-1.3,1.7c-0.4,0.5-0.9,0.9-1.4,1.3c-0.5,0.3-1.2,0.6-1.8,0.5c-0.3-0.8-0.5-1.7-0.5-2.6
		c-1.2,0-2.5-0.1-3.7,0c-1.2,0.7-2.2,1.3-3.4,2c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0-0.3,0-0.5,0c-1.3-0.3-2.5-1.1-3.8-1.2
		c-0.9,1-1.8,1.9-2.8,2.7c-0.6,0.5-1.2,1-1.9,1.5c-0.3,0.3-0.7,0.5-1,0.8c-0.5,0.5-1,1.2-1.5,1.7c-0.3,0.3-0.6,0.5-1,0.7
		c-0.3,0.2-0.6,0.4-0.9,0.5c0,1.2-0.1,2.3-0.1,3.5c2.6,1.6,5.7,2.5,8,4.5c0.7,0.6,1.3,1.4,2.2,1.9c0.3,0.2,0.6,0.3,1,0.5
		c0.9,0.4,1.8,0.8,2.6,1.3l-0.1,5l3,0c1.4,0.7,2.7,1.6,3.8,2.7c0.3,0.3,0.6,0.6,0.8,1c0.5,0.9,0.6,1.9,0.8,2.9
		c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.2,0.4,0.4,0.6c0.5,0.6,0.9,1.1,1.4,1.7l-0.2,12.1l3.4,0l5.9,0c0.7,2,1.7,3.8,3,5.4
		c0.5-0.3,0.9-0.8,1.3-1.2c1.2-1.3,2.3-2.6,3.4-4l0-0.2l26.2,0.2l11.6,0.1l0.2-31.4l2-1.8l1.2-6.4c0,0,1.7-0.1,1.9-0.1
		c0.2,0,4.5,3.6,4.5,3.6l2.5-0.4l2.8-2.2l1.7,1.5h3.8l5.9,5.3l3-0.7l2.4-2.1l-0.9-2.5l-2.7-1.7v-1.5l5.9-4.1V445l-0.8-0.2l-1.5-1.5
		l1.8-1.2l1.7-2.5l0.4-0.2l0.1-4.1H225.5z"/>
	<path @click="openEmc" data-emc="colquitt-emc" ref="colquitt-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M293.6,517.3c0.1-0.3,0.3-0.7,0.2-1c-0.1-0.4-0.6-0.8-1-1.1c-0.2-0.2-0.6-0.4-0.7-0.6c-0.2-0.5,0.1-1.5,0-2
		c-0.1-0.3-0.5-0.6-0.7-0.9c-0.5,0.1-0.9,0.2-1.4,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.8-0.6,1.7-0.9,2.5
		c-0.9,0-1.7-0.1-2.6-0.1c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.9-0.5-1.8-1-2.6c-0.3-0.5-0.7-0.9-1-1.3h-6v-7
		l-4.9-0.3c0-0.8,0-1.5,0-2.3l-0.1,0c0.4-0.4,0.7-0.9,1-1.4c0.7-1,1.4-1.9,2-2.9h3v-10.5h8.2c1.1-1.2,2.6-2.8,3.9-4.2l0-0.1
		c0,0,0.1-0.2,0-0.5c-0.1-0.3,0.1-1.1,0-1.5c-0.1-0.2-0.5-0.4-0.5-0.6c0-0.3,0.4-0.8,0.5-1.2c0.1-0.3,0-1-0.1-1.3
		c-0.1-0.2-0.4-0.4-0.4-0.6c-0.1-0.3,0-1,0.2-1.3c0.1-0.3,0.8-0.5,0.9-0.8c0.1-0.2,0.1-0.7,0.1-0.9c0-0.3-0.3-0.8-0.2-1
		c0-0.2,0.5-0.5,0.5-0.7c0-0.3-0.4-1-0.6-1.3c-0.1-0.3-0.5-0.8-0.7-1.1c-0.2-0.5-0.2-1.5-0.4-2c-0.1-0.3-0.6-0.6-0.7-0.9
		c-0.2-0.5,0.1-1.6,0-2.1c-0.1-0.4-0.4-1.2-0.7-1.5c-0.3-0.3-1.7-0.7-1.8-0.8c-0.1-0.1-0.7-0.3-1.1-0.6l-3,0l-0.3,1.7H280V460
		l-12.9-13.8l-5.8-0.1l-0.4,0c0,0-0.7-0.5-0.9-0.7c-0.3-0.2-0.7-0.6-1-0.7c-0.3-0.1-1,0-1.3-0.1c-0.3-0.2-0.6-1-0.8-1.3
		c-0.2-0.5-0.5-1.7-0.7-2.2c-0.1-0.3-0.4-0.8-0.6-1.1c-0.3-0.5-0.8-1.4-1.1-1.9c-0.3-0.4-1-1.5-1.2-1.7l-2.8,1l-1.7,1.8l-2.3,1.4
		l-3.8-2.2H241l-1.4,1.1l-1.9-1.1l-2.9,1.6l-2.2-1.6l-2.7,1.2l-0.4,0.2l-1.7,2.5l-1.8,1.2l1.5,1.5l0.8,0.2v5.3l-5.9,4.1v1.5l2.7,1.7
		l0.9,2.5l-2.4,2.1l-3,0.7l-5.9-5.3H211l-1.7-1.5l-2.8,2.2l-2.5,0.4c0,0-4.3-3.6-4.5-3.6c-0.2,0-1.9,0.1-1.9,0.1l-1.2,6.4l-2,1.8
		l-0.2,31.4l0,4.4h9.8c0.7-0.7,1.4-1.3,2.2-1.8c0.6,0.7,1.4,1.3,2.1,1.8h3.5c0.8-0.3,1.6-0.7,2.3-1.2c0.2-0.1,0.3-0.2,0.5-0.3
		c0.1,0,0.3,0,0.5,0c0.7,0,1.3,0,2,0c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.4,0.5,0.8,1.1,1.2,1.6l2.2,0
		c0.1,0.1,0.1,0.1,0.2,0.2c1,0.8,1.9,1.7,2.7,2.7c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.3c-0.3,0.7-0.7,1.4-1,2
		c-0.2,0.4-0.4,0.8-0.6,1.2c-0.3,0.5-0.7,0.9-1,1.3c-0.1,0.1-0.2,0.3-0.3,0.5c-0.3,0.5-0.6,0.9-0.8,1.4c-0.3,0.8-0.5,1.6-0.7,2.5
		c-0.6,2.6-1.3,5.2-1.9,7.8c1.1,0.7,2.2,1.5,3.1,2.4l-0.1,3.8c-0.5,0.4-1.1,0.8-1.6,1.2c0.1,0.3,0.2,0.6,0.2,0.9
		c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.3,0.7,0.7,1,1l-0.3,16.3l38.7,2.7l23.2,1.6l-0.1-2.8h1.6v-1.6h-1.5v-14.7l1-3
		h2.3v-3.4h1.5v-2.5h6.3c-0.1-0.5-0.1-1.1-0.1-1.4c0.1-0.2,0.4-0.4,0.5-0.6c0.1-0.3-0.1-1-0.1-1.4
		C293.6,518.5,293.5,517.7,293.6,517.3z"/>
		<path @click="openEmc" data-emc="satilla-remc" ref="satilla-remc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M422,437.1l2.4-6.2c-0.1-0.1-0.3-0.3-0.4-0.3c-0.2-0.1-0.7,0-0.8-0.1c-0.2-0.2-0.3-0.9-0.5-1.1
			c-0.2-0.2-0.8-0.5-1.2-0.6c-0.2,0-0.5,0.1-0.6,0c-0.3-0.2-0.3-0.9-0.5-1.2c-0.2-0.2-0.9-0.2-1.1-0.3c-0.1-0.1-0.4-0.3-0.5-0.4
			c-0.1-0.2,0.2-0.8,0.1-1.1c-0.1-0.2-0.5-0.3-0.7-0.4c-0.2-0.1-0.5-0.5-0.7-0.6c-0.2-0.1-0.6-0.2-0.9-0.2c-0.3,0-1,0.3-1.3,0.2
			c-0.2-0.1-0.6-0.5-0.7-0.7c-0.1-0.2-0.3-0.6-0.4-0.8c-0.2-0.2-0.7-0.2-0.9-0.3c-0.2-0.1-0.6-0.2-0.7-0.3c-0.1-0.2,0.1-0.8,0-1
			c-0.1-0.3-0.3-0.8-0.5-1c-0.2-0.2-0.7-0.3-0.8-0.4c-0.2-0.2-0.6-0.5-0.8-0.8c-0.2-0.4,0-1.4-0.3-1.8c-0.2-0.3-0.8-0.5-1.1-0.6
			c-0.2-0.1-0.7-0.3-1-0.4c-0.3-0.1-1.1-0.3-1.3-0.6c-0.3-0.3-0.4-1.2-0.5-1.5c-0.1-0.2-0.3-0.5-0.3-0.7c0-0.2,0.4-0.5,0.4-0.6
			c0-0.1,0-0.8-0.2-0.9c-0.1-0.2-0.6-0.4-0.8-0.4c-0.2,0-0.5,0.3-0.7,0.3c-0.3,0-0.8-0.5-1.1-0.7c-0.2-0.1-0.8-0.2-1-0.4
			c-0.3-0.3-0.2-1.2-0.3-1.6c-0.1-0.5-0.3-1.5-0.7-1.8c-0.2-0.1-0.7,0-0.9,0.2c-0.2,0.1-0.2,0.7-0.4,0.9c-0.2,0.1-0.7,0.1-0.9,0
			c-0.1,0-0.1,0-0.1,0c-0.1,0-0.4-0.8-0.7-0.9c-0.2-0.1-0.8,0-1-0.2c-0.2-0.1-0.3-0.6-0.4-0.7c-0.2-0.1-0.6-0.1-0.7-0.3
			c-0.2-0.2-0.1-0.8-0.1-1c-0.1-0.3-0.3-0.8-0.5-1c-0.2-0.2-0.7-0.5-0.9-0.5c-0.2,0-0.3,0.5-0.5,0.5c-0.2,0-0.5-0.4-0.8-0.5
			c-0.2-0.1-0.7,0-0.9-0.1c-0.3-0.1-0.6-0.7-0.8-1c0,0,0,0,0,0c-0.2-0.2-1-0.8-1.1-0.8c-0.1,0-1.1-0.3-1.4-0.5
			c-0.2-0.2-0.7-0.9-0.8-0.9c-0.1,0-0.8-0.5-1-0.6c-0.2-0.1-0.7-0.8-0.7-0.8c-0.1,0-0.9-0.1-1-0.3c-0.2-0.2-0.1-0.8-0.1-1
			c0.1-0.4,0.7-1,0.6-1.4c-0.1-0.3-0.5-0.6-0.8-0.7c-0.4-0.1-1.2,0.3-1.6,0.1c-0.3-0.1-0.6-1-0.9-1.2c-0.2-0.1-0.8-0.2-1-0.3
			c-0.2-0.1-0.5-0.5-0.7-0.6c-0.3-0.1-0.9-0.2-1.3-0.2c-0.3,0-0.8,0.3-1.1,0.4c-0.1,0.1-0.3,0.3-0.5,0.3c-0.2,0-0.6-0.2-0.8-0.3
			c-0.3-0.2-0.7-0.8-1.1-0.9c-0.3-0.1-0.9,0.1-1.2,0c-0.3-0.1-0.7-0.4-1-0.6c-0.2-0.1-0.6-0.3-0.9-0.4c-0.1,0-0.3-0.1-0.4-0.1
			c-0.3,0-1.3,0.5-1.3,0.4c0-0.1,0-0.9-0.1-1.2c-0.1-0.2-0.3-0.5-0.5-0.6c-0.2-0.1-0.6,0.2-0.8,0.1c-0.2-0.1-0.4-0.8-0.6-0.9
			c-0.2-0.1-0.6,0-0.8-0.1c-0.3-0.1-0.7-0.8-1-0.8c-0.1,0-0.3,0.1-0.4,0.2c-0.2,0.2-0.2,0.7-0.4,0.9c-0.1,0.1-0.4,0.1-0.5,0.1
			c-0.2,0-0.8,0.1-1,0.1c-0.3,0-0.8,0.3-1.1,0.3c-0.3,0-0.7-0.4-1-0.5c-0.4-0.2-1.1-0.4-1.5-0.5c-0.3,0-1,0-1.1,0
			c-0.1,0-0.5-0.7-0.8-0.8c-0.3-0.1-0.7,0.5-1,0.5c-0.2,0-0.7-0.3-0.8-0.5c-0.1-0.2,0.2-0.6,0.1-0.8c-0.1-0.3-0.7-0.7-1-0.8
			c-0.2-0.1-0.8,0-1,0.1c-0.2,0.1-0.2,0.6-0.4,0.6c-0.4,0.1-1.3-0.4-1.6-0.7c-0.3-0.3-0.6-1.2-0.9-1.4c-0.1,0-0.2,0-0.3,0
			c-0.3,0-0.6,0.1-0.8,0.2c-0.4,0.1-1.1,0.8-1.5,0.7c-0.2,0-0.7-0.4-0.8-0.4c-0.1,0-1.2-0.7-1.6-0.8c-0.2,0-0.4,0-0.7,0.1
			c-0.2,0-0.3,0.1-0.4,0.1c-0.2,0.1-0.8,0.3-0.8,0.6c0,0.4,1.3,0.4,1.4,0.7c0.1,0.2-0.1,0.7-0.3,0.9c-0.2,0.2-0.8,0.5-1.1,0.5
			c-0.4,0-1-0.4-1.3-0.6c-0.3-0.2-0.8-0.7-1.2-0.8c-0.3-0.1-0.9,0.1-1.1,0.1c-0.1,0-0.1,0-0.2,0.1c-0.4,0.2-1,0.5-1.3,0.4
			c-0.3,0-0.9-0.6-1.2-0.6c-0.3,0-1,0.1-1.2,0.3c-0.2,0.2-0.4,0.9-0.6,1.1c-0.2,0.3-0.9,0.8-1.1,1.1c-0.1,0.2-0.1,0.8-0.4,0.9
			c-0.3,0.1-0.7-0.7-1-0.7c-0.3,0-1,0.3-1.2,0.5c-0.2,0.1-0.3,0.6-0.5,0.7c-0.5,0.3-1.7,0-2.2,0.2c-0.2,0.1-0.5,0.4-0.7,0.4
			c-0.2,0.1-0.5,0.1-0.8,0.1c-0.3,0-0.6,0-0.7,0c-0.2,0-0.9,0-1.2,0c-0.2,0-0.6,0-0.7,0.1c-0.1,0.2,0.1,0.7,0,0.9
			c-0.1,0.2-0.7,0.4-0.9,0.5c-0.2,0.2-0.5,0.7-0.7,0.9c-0.3,0.2-1,0.3-1.2,0.6c-0.2,0.2-0.3,1-0.6,1.2c-0.2,0.2-0.7,0.3-0.9,0.4
			c-0.4,0.1-1.2,0.4-1.6,0.5c-0.3,0.1-1.1,0.2-1.3,0.5c-0.2,0.2,0.1,0.9,0,1.1c-0.1,0.3-0.3,0.9-0.6,1.1c-0.1,0.1-0.4-0.1-0.6-0.1
			c-0.4-0.1-1.2-0.4-1.6-0.2c-0.3,0.1-0.5,0.8-0.7,0.9c-0.2,0.1-0.6,0-0.7,0.2c-0.1,0.1-0.1,0.5-0.1,0.6c0,0.2,0.1,0.5,0.1,0.7
			c-0.1,0.3-0.4,0.8-0.7,0.9c-0.2,0.1-0.6,0.1-0.8,0.3c-0.2,0.2-0.2,0.7-0.3,0.9c-0.1,0.2-0.4,0.7-0.7,0.7c-0.2,0.1-0.7-0.1-1,0
			c-0.2,0-0.5,0.2-0.7,0.3c0,0-0.1,0-0.1,0.1c-0.3,0.2-0.9,0.5-1.1,0.7c-0.1,0.2,0,0.8-0.2,1c-0.2,0.2-0.8,0.2-0.9,0.3
			c-0.3,0.3-0.2,1.1-0.5,1.4c-0.2,0.2-0.7,0.4-1,0.4c-0.2,0-0.7-0.4-0.9-0.3c-0.2,0.1-0.6,0.7-0.7,0.7c-0.1,0-0.8,0-0.9,0
			c-0.1,0-0.4-0.8-0.7-0.9c-0.2-0.1-0.7,0.2-0.9,0.3c-0.2,0.1-0.6,0.5-0.8,0.5c-0.3,0-0.8-0.3-1-0.4c-0.2-0.1-0.6-0.6-0.9-0.7
			c-0.3-0.1-0.9,0.2-1.2,0.4c-0.2,0.1-0.4,0.7-0.7,0.7c-0.3,0.1-1-0.1-1.3-0.3c-0.2-0.2-0.3-0.8-0.6-0.9v4.7l-2.5,2.5v7.1
			c0,0,0,0.9-0.2,1.1c-0.1,0.2-0.4,0.5-0.6,0.5c-0.2,0.1-0.6-0.2-0.7-0.1c-0.2,0.1-0.2,0.6-0.2,0.8c0,0.3,0.1,0.9,0.1,1.2
			c-0.1,0.3-0.3,0.8-0.5,0.9c-0.2,0.1-0.7-0.1-0.8,0c-0.3,0.2-0.3,1.1-0.6,1.3c-0.2,0.1-0.8,0-0.9,0.2c-0.2,0.2,0.1,0.9,0,1.2
			c-0.1,0.2-0.7,0.5-0.9,0.5c-0.2,0.1-0.8-0.2-0.9,0c-0.4,0.4,0.2,1.6,0.2,2.1c0,0.6,0,1.7-0.1,2.5l2.2,3.1l-1.7,2.5l-2.1,4.8v4.4
			L284,453l-2.3,0c0,0.4,0.1,1.2,0.2,1.5c0.1,0.4,0.4,1.2,0.7,1.4c0.2,0.2,0.9,0.1,1.1,0.3c0.2,0.2,0.3,0.9,0.4,1.3
			c0.1,0.4,0.1,1.1,0.3,1.5c0.1,0.2,0.5,0.4,0.6,0.6c0.3,0.3,0.7,1.2,1,1.5c0.3,0.2,1.1,0.6,1.2,0.7c0.1,0.1,1.4,0.5,1.8,0.8
			c0.3,0.3,0.6,1.1,0.7,1.5c0.1,0.5-0.2,1.6,0,2.1c0.1,0.3,0.6,0.6,0.7,0.9c0.2,0.5,0.2,1.5,0.4,2c0.1,0.3,0.5,0.8,0.7,1.1
			c0.2,0.3,0.6,0.9,0.6,1.3c0,0.2-0.4,0.5-0.5,0.7c-0.1,0.3,0.2,0.8,0.2,1c0,0.2,0,0.7-0.1,0.9c-0.1,0.3-0.8,0.5-0.9,0.8
			c-0.2,0.3-0.3,1-0.2,1.3c0,0.2,0.4,0.5,0.4,0.6c0.1,0.3,0.1,1,0.1,1.3c-0.1,0.3-0.5,0.8-0.5,1.2c0,0.2,0.5,0.4,0.5,0.6
			c0.1,0.3-0.1,1.1,0,1.5c0.1,0.2,0,0.5,0,0.5l7.7,0l30.6,0.1l0,0l19.2-7.4l11,2.8l6.5-0.6c0.3,0.1,1-0.2,1.4-0.2
			c0.3,0,0.7,0.1,1.1,0.2c0.1,0,0.3,0.1,0.4,0.1c0.4,0.1,1,0.6,1.4,0.7c0.4,0,1.1-0.4,1.4-0.4c0.4,0,1,0.5,1.3,0.5
			c0.2,0,0.6-0.4,0.9-0.4c0.4,0,0.9,0.7,1.3,0.7c0.3,0.1,1.3-0.1,1.3,0c0,0.1,1.2,1.1,1.7,1.1c0.4,0,1.2-0.5,1.4-0.8
			c0.3-0.3,0.4-1.3,0.7-1.5c0.3-0.3,1.4-0.3,1.8-0.5c0.3-0.2,0.8-0.7,1-1c0.1-0.2-0.1-0.8,0-1.1c0-0.3,0-0.9,0.2-1.1
			c0.4-0.5,1.9-0.5,2.3-1c0.3-0.2,0.4-1.1,0.7-1.3c0.3-0.3,1.2-0.6,1.6-0.7c0.4,0,1.2,0.3,1.6,0.4c0.4,0.1,1.3,0.5,1.8,0.5
			c0.5,0,1.4-0.4,1.8-0.5c0.3-0.1,0.9-0.1,1.2-0.1c0.1,0,0.4,0,0.5,0c0,0,0,0,0,0c0,0,2.2-0.1,4.4-0.6c2.2-0.5,3.4-2.8,3.4-2.8
			s0.4-1.6,0.6-4.7c0-0.4,0.1-0.8,0.1-1.2c0,0,0,0,0,0c0.5-2.7,1.9-5,1.9-5h14.4l3.9-8.6l-1.5-7V437.1z"/>
		<path @click="openEmc" data-emc="irwin-emc" ref="irwin-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M297.9,407.9c-0.2,0.1-0.4,0.7-0.6,0.9c-0.2,0.1-0.6,0-0.8,0.1c-0.1,0-0.2,0.1-0.2,0.2
			c-0.1,0.2-0.3,0.4-0.4,0.5c-0.2,0.1-0.7,0.3-0.9,0.3c-0.4,0-1-0.4-1.3-0.5c-0.3-0.1-0.8,0.1-1.1,0c-0.3-0.2-0.4-0.8-0.6-1.1
			c-0.1-0.2-0.3-0.5-0.5-0.6c-0.3-0.1-1.1,0.2-1.5,0.1c-0.2-0.1-0.4-0.4-0.6-0.6c-0.2-0.2-0.9-0.8-1-0.8c-0.1,0-1,0.1-1.2-0.1
			c-0.1-0.1,0.1-0.6-0.1-0.7c-0.2-0.1-0.6,0.4-0.7,0.3c-0.3-0.1-0.4-0.7-0.6-0.9c-0.2-0.2-0.6-0.6-0.9-0.6c-0.3,0-0.6,0.5-0.8,0.7
			c-0.1,0.1-0.2,0.6-0.4,0.6c-0.3,0.1-0.8-0.3-1-0.4c-0.2-0.1-0.6-0.5-0.7-0.7c-0.1-0.2,0.2-0.6,0.1-0.8c-0.1-0.2-0.6-0.3-0.8-0.3
			c-0.2,0-0.6,0.1-0.7,0c-0.3-0.1-0.4-0.8-0.7-1c-0.2-0.2-0.9-0.4-1.1-0.7c-0.2-0.2-0.3-0.7-0.5-0.9c-0.1-0.2-0.5-0.4-0.7-0.6
			c-0.1-0.1-0.2-0.3-0.3-0.6c-0.1-0.2-0.1-0.3-0.1-0.5c0.1-0.2,0.6-0.4,0.7-0.6c0.1-0.3,0.2-0.9,0.1-1.2c-0.1-0.2-0.5-0.6-0.7-0.6
			c-0.2,0-0.3,0.4-0.5,0.4c-0.3,0-0.9-0.3-1.2-0.5c-0.3-0.2-0.7-0.7-0.8-1.1c0-0.2,0.2-0.6,0.2-0.8c0-0.2-0.1-0.6-0.1-0.9
			c0-0.1,0-0.2-0.1-0.2c0-0.2-0.1-0.6-0.2-0.7c-0.2-0.3-1-0.3-1.2-0.6c-0.2-0.3,0.2-1.2,0.1-1.5c-0.1-0.2-0.4-0.4-0.5-0.6
			c-0.2-0.3-0.5-0.9-0.7-1.1c-0.2-0.1-0.6-0.2-0.7-0.3c-0.1-0.1-0.3-0.5-0.5-0.6c-0.2-0.2-0.8-0.3-1.1-0.4c-0.2,0-0.7,0.1-0.9,0
			c-0.2-0.1-0.6-0.7-0.7-0.7c-0.1,0-0.6-1.1-0.8-1.5c-0.1-0.2-0.6-0.5-0.7-0.7c-0.1-0.2-0.2-0.6-0.2-0.7c0-0.3,0.3-1,0.3-1.1
			c0,0-0.1-0.4-0.2-0.7c-1.3,1.2-2.5,2.4-3.9,3.5c1,1,2,1.9,2.9,2.9l-2.5,2.3c0,0-0.1,0.9-0.1,1.3c0.2,1.7,0.3,3.4,0.5,5.1
			c-1.1,0.8-1.1,0.8-1.1,0.8s-0.2,0.9-0.2,1.4c0,0.4,0,0.8,0,1.2h0c-0.4,0.6-0.8,1.1-1.3,1.7c-0.2,0.3-0.5,0.6-0.8,0.8
			c-0.8,0.5-1.9,0.2-2.8,0.4c0.1,1.5,0,3-0.3,4.4c-2.7,0.1-5.5,0.2-8.2,0.2c0,0.1,0,0.1,0,0.2c0.1,0.3,0.8,0.8,0.8,1.1
			c0,0.1,0,0.2,0,0.3l-3.2,0.2l-0.3-1.8l-1.8-5.5h-2.5l-1.5-2.1v-1.1h-3.4l-3.8,6.3h-11.9c0,0-2.3-0.3-3-0.3c-0.5,0-1.6,0-2.1,0.1
			c-0.3,0.1-1.1,0.3-1.6,0.5l0,10.4l4.7,2.7l4.1,0.8v2.7l-2.2,0.5l3.8,4.2v3.4v4h4.5l-0.1,4.1l2.7-1.2l2.2,1.6l2.9-1.6l1.9,1.1
			l1.4-1.1h1.6l3.8,2.2l2.3-1.4l1.7-1.8l2.8-1c0,0,0-0.1,0-0.1s1,1.3,1.3,1.8c0.3,0.5,0.8,1.4,1.1,1.9c0.2,0.3,0.5,0.8,0.6,1.1
			c0.2,0.5,0.5,1.7,0.7,2.2c0.2,0.3,0.5,1.1,0.8,1.3c0.3,0.2,1,0,1.3,0.1c0.3,0.1,0.8,0.5,1,0.7c0.2,0.2,0.9,0.7,0.9,0.7l0.4,0
			l5.8,0.1L280,460v2.9h2.9l0.3-1.7l3,0c-0.1,0-0.1-0.1-0.1-0.1c-0.3-0.3-0.7-1.1-1-1.5c-0.1-0.2-0.5-0.4-0.6-0.6
			c-0.2-0.3-0.2-1.1-0.3-1.5c-0.1-0.3-0.1-1-0.4-1.3c-0.2-0.2-0.9-0.1-1.1-0.3c-0.3-0.3-0.6-1.1-0.7-1.4c-0.1-0.4-0.2-1.1-0.2-1.5
			l2.3,0l4.4-3.8v-4.4l2.1-4.8l1.7-2.5l-2.2-3.1c0.1-0.8,0.1-1.9,0.1-2.5c0-0.5-0.6-1.7-0.2-2.1c0.2-0.2,0.7,0.1,0.9,0
			c0.3-0.1,0.8-0.3,0.9-0.5c0.1-0.2-0.2-0.9,0-1.2c0.2-0.2,0.7,0,0.9-0.2c0.3-0.2,0.3-1.1,0.6-1.3c0.2-0.1,0.7,0.1,0.8,0
			c0.2-0.1,0.5-0.7,0.5-0.9c0.1-0.3-0.1-0.9-0.1-1.2c0-0.2,0.1-0.7,0.2-0.8c0.2-0.1,0.6,0.1,0.7,0.1c0.2-0.1,0.5-0.3,0.6-0.5
			c0.1-0.2,0.2-1.1,0.2-1.1V415l2.5-2.5v-4.7C298.6,407.7,298.1,407.8,297.9,407.9z"/>
		<path @click="openEmc" data-emc="middle-georgia-emc" ref="middle-georgia-emc" @mouseover="mouseOverEMC" @mousemove="hoverEmc" @mouseleave="mouseLeaveEmc" d="M266,389.1c-1-1-2-1.9-2.9-2.9c1.3-1.1,2.6-2.3,3.9-3.5c-0.1-0.2-0.1-0.3-0.2-0.4c-0.2-0.2-0.8-0.4-1-0.6
			c-0.1-0.2-0.2-0.8-0.1-1.1c0.1-0.1,0.5-0.2,0.5-0.4c0.1-0.2,0.2-0.8,0.1-1.1c-0.1-0.2-0.6-0.4-0.6-0.7c-0.1-0.2,0.1-0.6,0-0.8
			c-0.1-0.2-0.4-0.7-0.6-0.8c-0.1-0.1-0.6-0.2-0.6-0.4c0-0.3,0.6-0.6,0.7-0.9c0.1-0.4-0.2-1.1-0.2-1.5c0-0.3,0.3-0.7,0.4-1
			c0-0.3-0.1-0.9-0.2-1.2c-0.1-0.2-0.3-0.7-0.5-0.7c-0.2,0-0.3,0.6-0.4,0.7c-0.2,0.1-0.6,0.3-0.8,0.2c-0.3-0.1-0.6-0.7-0.7-0.9
			c-0.1-0.2,0-0.8-0.1-1c-0.1-0.2-0.3-0.4-0.6-0.5c-0.3-0.2-0.6-0.3-0.8-0.4c-0.2,0-0.7,0.4-0.9,0.3c-0.1-0.1-0.1-0.4-0.2-0.6
			c-0.1-0.4-0.2-1.2-0.4-1.5c-0.2-0.3-0.7-0.6-1-0.8c-0.2-0.2-0.5-0.6-0.8-0.7c-0.2,0-0.4,0-0.7,0.1l-2.3-1.2v-3.1l-1.5-1.7
			c0,0,0.1-1.6,0-3.2c-0.1-1.6-0.5-2.9-0.9-3.8c-0.5-0.9-2.2-2.3-2.2-2.3v-6h-4.1v-8.7l-1.5-1.3c-0.4,0-0.9-0.6-1.3-0.7
			c-0.2-0.1-0.6-0.3-0.8-0.3c-0.2,0.1-0.4,0.5-0.6,0.7c-0.5,0.6-1.3,2-1.8,2.6c-0.3,0.4-0.8,1.1-1.1,1.5c-0.4,0.5-1.2,1.6-1.6,2.2
			c-0.2,0.3-0.5,1-0.7,1.3c-0.2,0.3-0.6,0.9-0.7,1.3c-0.1,0.4,0.1,1.2,0,1.6l-2.6-1.5l-1.4,1.1l-3,0.3v2.2v2h-3L224,348
			c-0.3,0-0.8-0.3-1-0.5c-0.1-0.1-0.1-0.6-0.1-0.6H220l-0.9-0.9l-2.4-0.4l-4.3-2.2l-1.7,3.5h-0.7h-5.4v1.4c0,0-1.6,0.8-2.1,1.1
			c-0.4,0.3-1.3,0.8-1.7,1.1c-0.4,0.3-1.4,0.7-1.8,1c-0.3,0.2-1,0.5-1.3,0.7c-0.3,0.3-0.5,1.1-0.7,1.5c-0.2,0.4-0.5,1.2-0.8,1.4
			c-0.3,0.3-1.2,0.5-1.5,0.8c-0.3,0.3-0.8,1.1-1.1,1.4c-0.2,0.2-0.7,0.5-0.9,0.7c-0.2,0.3,0,1-0.2,1.3c-0.2,0.3-0.8,0.9-1.1,1
			c-0.3,0.1-0.8-0.2-1.1-0.3c-0.2-0.1-0.4-0.1-0.6-0.2c0.2,0.4,0.4,0.8,0.5,1c0.2,0.3,1.1,0.6,1.3,0.9c0.2,0.4-0.2,1.5-0.3,1.8
			c-0.1,0.2,0.2,0.6,0.4,0.8c0.2,0.2,0.4,0.8,0.5,1c0.3,0.3,1.1,0.7,1.2,1.1c0.1,0.3-0.2,1.1-0.2,1.2c0,0.1,0.4,0.4,0.6,0.5
			c0.2,0.2,0.7,0.7,0.8,1c0.2,0.3,0.4,0.9,0.6,1.2c0.3,0.2,1.1,0.2,1.3,0.5c0.2,0.3,0,1-0.1,1.3c-0.1,0.3-0.5,1-0.5,1.3
			c0,0.4,0.8,1,0.9,1.5c0.1,0.4-0.6,1.3-0.4,1.7c0.2,0.4,1.1,0.5,1.4,0.9c0.1,0.2,0.2,0.6,0.2,1c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0.1
			h36.4v15.3l2.7,3.3l0.6,2.5h3v1.1l1.5,2.1h2.5l1.8,5.5l0.3,1.8l3.2-0.2c0-0.1,0-0.2,0-0.3c-0.1-0.3-0.8-0.8-0.8-1.1
			c0-0.1,0-0.2,0-0.2c2.7,0,5.5,0,8.2-0.2c0.3-1.4,0.4-2.9,0.3-4.4c0.9-0.3,2,0.1,2.8-0.4c0.3-0.2,0.5-0.5,0.8-0.8
			c0.4-0.6,0.8-1.1,1.3-1.7h0c0-0.4,0-0.8,0-1.2c0-0.5,0.2-1.4,0.2-1.4s0,0,1.1-0.8c-0.2-1.7-0.3-3.4-0.5-5.1c0-0.4,0.1-1.3,0.1-1.3
			L266,389.1z"/>
</g>
<g id="Counties" class="counties" :class="{ countiesfocus: toggleCounty}">
    <g id="Decatur">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="decatur-county" ref="decatur-county" class="st10" d="M139.4,494.4l3.4,0l13.5,0.1l-0.9,47.4l-48.7-2.7c0,0,0-0.2,0-0.3c0.1-0.2,0.6-0.1,0.8-0.2
            c0.2-0.2,0.5-0.6,0.5-0.9c0-0.3-0.2-0.8-0.3-1.1c-0.1-0.1-0.3-0.4-0.3-0.5c0-0.2,0-0.8,0.1-1c0.2-0.4,0.9-1.1,1.4-1.3
            c0.3-0.1,1.1,0.2,1.4,0.1c0.3-0.1,0.8-0.6,1.1-0.7c0.3-0.1,1.1-0.1,1.2-0.1c0,0,0.9,0.4,1.2,0.4c0.2,0,0.5-0.4,0.7-0.5
            c0.3-0.1,0.8-0.1,1.1-0.1c0.4-0.1,1.2-0.1,1.5-0.3c0.1-0.1,0.3-0.3,0.5-0.4c0.3-0.1,0.9-0.1,1.2-0.1c0.2,0,0.7,0,0.9-0.2
            c0.2-0.2,0.3-0.8,0.4-0.9c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.5,0.6,0.5c0.2,0,0.5,0,0.6-0.1c0.1-0.1,0-0.6,0.1-0.8
            c0.1-0.2,0.6-0.3,0.8-0.3c0.3,0,0.9,0.5,1.2,0.5c0.2,0,0.6-0.1,0.8-0.1c0.2-0.1,0.5-0.4,0.7-0.5c0.3-0.1,0.8-0.1,1.1-0.2
            c0.4-0.1,1.3-0.2,1.6-0.4c0.2-0.2,0.3-0.8,0.4-1.1c0.1-0.1,0.2-0.4,0.3-0.5c0-0.2,0.1-0.5,0-0.7c-0.1-0.2-0.5-0.3-0.6-0.5
            c-0.1-0.3,0.3-1.1,0.2-1.5c0-0.2-0.3-0.6-0.3-0.8c0-0.2,0.1-0.8,0.3-1c0.1-0.2,0.6-0.3,0.8-0.4c0.3-0.2,1-0.4,1.3-0.7
            c0.2-0.2,0.4-0.8,0.5-1.1c0.1-0.2,0.4-0.6,0.6-0.7c0.2-0.2,0.7-0.4,0.9-0.7c0.2-0.2,0.4-0.8,0.5-1c0.1-0.2,0.5-0.6,0.7-0.9
            c0.1-0.2,0.1-0.7,0.2-0.9c0.1-0.2,0.3-0.6,0.3-0.8c-0.1-0.2-0.5-0.3-0.7-0.2c-0.3,0.1-0.7,0.8-0.9,1.1c-0.2,0.2-0.4,0.7-0.5,1
            c-0.1,0.3-0.3,0.9-0.5,1.1c-0.1,0.2-0.5,0.3-0.7,0.4c-0.2,0.1-0.4,0.4-0.5,0.5c-0.2,0.2-0.8,0.3-1,0.4c-0.1,0.1-0.4,0.4-0.5,0.6
            c-0.1,0.2-0.2,0.6-0.3,0.8c-0.1,0.1-0.3,0.4-0.4,0.5c-0.1,0.1-0.3,0-0.4,0.1c-0.2,0.1-0.4,0.4-0.5,0.5c-0.1,0.2-0.2,0.8-0.1,1
            c0,0.3,0.3,0.9,0.3,1.2c0,0.3-0.1,0.8-0.2,1.1c-0.1,0.2-0.4,0.4-0.4,0.6c0,0.3,0.5,0.7,0.5,0.9c0,0.2-0.4,0.7-0.6,0.8
            c-0.2,0.1-0.6-0.2-0.8-0.2c-0.3-0.1-0.9-0.3-1.2-0.2c-0.2,0.1-0.5,0.5-0.7,0.6c-0.3,0.1-0.8,0.3-1.1,0.3c-0.3,0-0.8,0-1.1-0.2
            c-0.3-0.1-0.6-0.8-0.9-0.8c-0.2,0-0.4,0.2-0.5,0.4c-0.2,0.2-0.3,0.8-0.5,1.1c-0.1,0.1-0.4,0.2-0.5,0.2c-0.1,0-0.4,0.2-0.6,0.1
            c-0.3,0-1-0.4-1.2-0.7c-0.1-0.1-0.1-0.5,0-0.7c0.1-0.2,0.5-0.3,0.6-0.5c0.1-0.2,0.2-0.6,0.1-0.8c0-0.2-0.3-0.5-0.4-0.5
            c-0.1-0.1-0.5,0-0.6,0.1c-0.1,0.2,0.1,0.6,0,0.8c-0.1,0.2-0.4,0.7-0.6,0.8c-0.2,0.1-0.7-0.3-0.9-0.4c-0.2-0.2-0.4-0.7-0.5-0.9
            c-0.1-0.2-0.1-0.7,0-0.9c0.1-0.2,0.6-0.6,0.7-0.8c0.2-0.3,0.5-1.1,0.7-1.4c0.2-0.3,0.6-0.8,0.7-1.1c0.1-0.3-0.1-1,0-1.3
            c0.1-0.3,0.6-0.7,0.7-1c0.1-0.3,0-0.8,0.1-1.1c0-0.3,0.2-0.9,0.3-1.2c0.1-0.4,0.3-1,0.3-1.4c0-0.5,0-1.5-0.2-1.9
            c-0.1-0.1-0.4-0.3-0.5-0.4c-0.1-0.1-0.2-0.1-0.4-0.2c0-0.5,0-0.9-0.1-1.2c0-0.7-0.1-2-0.1-2.6c0-0.7,0.1-2.1,0.2-2.8
            c0.1-0.5,0.3-1.4,0.3-1.8c0-0.4-0.5-1-0.5-1.4c-0.1-0.5,0.2-1.5,0.3-1.9c0.1-0.4,0.3-1.3,0.3-1.7c0-0.4-0.2-1.3,0-1.7
            c0.2-0.3,0.8-0.6,1.1-0.7c0.3-0.1,1.1,0.2,1.3,0c0.3-0.3-0.1-1.3-0.1-1.7c0-0.3,0-0.8,0-1.2l8.3,0.1v-1.3L139.4,494.4z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="decatur-county" ref="decatur-county" class="st10" d="M116.4,530.5c-0.1-0.1-0.1-0.6-0.2-0.7c-0.2-0.2-0.9-0.5-1.1-0.4c-0.3,0.2-0.2,1.2-0.3,1.5
            c-0.1,0.2-0.4,0.5-0.3,0.6c0.1,0.2,0.7,0.1,1,0c0.2,0,0.6-0.2,0.8-0.1c0.2,0,0.6,0.3,0.8,0.3c0.2,0,0.6-0.4,0.6-0.6
            c0-0.2-0.2-0.6-0.3-0.7C117.2,530.4,116.6,530.7,116.4,530.5z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="decatur-county" ref="decatur-county" class="st10" d="M113.9,531.1c0-0.2-0.2-0.7-0.4-0.7c-0.2,0-0.5,0.3-0.6,0.4c-0.1,0.2-0.4,0.7-0.3,0.9
            c0.1,0.2,0.4,0.3,0.6,0.4c0.1,0,0.5-0.1,0.5-0.2C113.9,531.7,113.9,531.3,113.9,531.1z"/>
    </g>
    <g id="Seminole">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="seminole-county" ref="seminole-county" class="st10" d="M100.8,495.2l19.8,0.3c0,0.4,0,1,0,1.2c0,0.4,0.4,1.4,0.1,1.7c-0.2,0.2-1-0.1-1.3,0
            c-0.3,0.1-0.9,0.4-1.1,0.7c-0.2,0.4,0,1.3,0,1.7c0,0.4-0.3,1.3-0.3,1.7c-0.1,0.5-0.3,1.5-0.3,1.9c0,0.4,0.5,1,0.5,1.4
            c0,0.5-0.2,1.4-0.3,1.8c-0.1,0.7-0.2,2.1-0.2,2.8c0,0.7,0,2,0.1,2.6c0,0.3,0,0.7,0.1,1.2c0,0.7,0,1.5-0.1,1.9
            c-0.1,0.4-0.4,1.2-0.7,1.6c-0.1,0.1-0.4,0.3-0.4,0.4c-0.3,0.5-0.3,1.7-0.4,2.3c-0.1,0.5-0.2,1.5-0.3,1.9
            c-0.1,0.3-0.6,0.8-0.7,1.1c-0.2,0.3-0.4,1-0.5,1.3c-0.2,0.3-0.7,0.7-0.8,1c-0.1,0.3,0.2,1,0.1,1.3c-0.1,0.2-0.3,0.6-0.5,0.7
            c-0.2,0-0.4-0.2-0.6-0.2c-0.2,0-0.6,0.4-0.8,0.4c-0.3,0-0.6-0.5-0.9-0.6c-0.2-0.1-0.7-0.1-1-0.1c-0.2,0.1-0.5,0.5-0.7,0.6
            c-0.2,0.1-0.8-0.1-1-0.1c-0.2,0-0.7,0-0.9,0.1c-0.1,0.1-0.1,0.5-0.2,0.6c-0.1,0.2-0.5,0.5-0.5,0.7c-0.1,0.3,0.1,1-0.1,1.2
            c-0.1,0.1-0.4,0.2-0.6,0.2c-0.2,0.1-0.6,0.4-0.9,0.5c-0.3,0-1-0.2-1.1-0.2c-0.1-0.1-0.3-0.8-0.4-1c-0.1-0.2-0.3-0.8-0.6-0.9
            c-0.1-0.1-0.5,0-0.6,0.1c-0.2,0.1-0.3,0.6-0.2,0.9c0,0.3,0.4,0.8,0.4,1c0.1,0.2,0.2,0.8,0,0.9c-0.1,0.1-0.5-0.1-0.6-0.3
            c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.1-0.6-0.4-1-0.3c0-0.2-0.1-0.5-0.2-0.6c-0.1-0.3-0.7-0.6-0.8-0.9c-0.1-0.3,0.2-0.8,0.2-1.1
            c0-0.5,0.1-1.4-0.2-1.8c-0.1-0.2-0.8-0.4-0.8-0.6c-0.2-0.5,0.5-1.7,0.7-2.2c0.1-0.3,0.5-0.8,0.4-1.1c0-0.3-0.6-0.7-0.7-0.9
            c-0.1-0.3,0-1-0.1-1.3c-0.1-0.2-0.5-0.5-0.5-0.7c0-0.2,0.3-0.7,0.3-0.9c0-0.3,0.1-0.8,0-1.1c-0.1-0.3-0.7-0.6-0.9-0.8
            c-0.4-0.5-1-1.7-1.3-2.3c-0.2-0.4-0.6-1.3-0.9-1.6c-0.2-0.3-1-0.6-1.2-0.9c-0.2-0.2-0.4-0.8-0.4-1.1c0-0.3,0.5-0.8,0.6-1.1
            c0.1-0.3,0.4-1.1,0.2-1.4c-0.2-0.3-1-0.3-1.3-0.5c-0.4-0.3-1.1-1-1.2-1.4c-0.1-0.3,0.3-1,0.3-1.3c0-0.4-0.4-1.1-0.4-1.5
            c0-0.4,0.2-1.4,0.1-1.8c-0.1-0.5-0.8-1.2-1-1.6c-0.1-0.5,0.2-1.6,0.1-2.1c-0.2-0.7-1.2-1.8-1.5-2.4c-0.1-0.1-0.1-0.3-0.2-0.5
            L100.8,495.2z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="seminole-county" ref="seminole-county" class="st10" d="M109.9,530.8c-0.2,0-0.6,0.2-0.8,0.3c-0.2,0.1-0.7,0.3-0.9,0.2c-0.2-0.1-0.2-0.6-0.2-0.7
            c0-0.3,0.1-1.1,0.4-1.3c0.1-0.2,0.6-0.3,0.8-0.3c0.2,0,0.7,0.3,0.9,0.3c0.3,0,0.7-0.6,1.1-0.5c0.3,0,0.7,0.5,0.9,0.7
            c0.1,0.2,0.3,0.6,0.2,0.8c-0.1,0.3-0.4,0.8-0.7,0.8c-0.2,0.1-0.7-0.2-1-0.2C110.3,530.8,110,530.8,109.9,530.8z"/>
    </g>

    <g id="Richmond">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="richmond-county" ref="richmond-county" class="st11" d="
        M368.1,217.2l26.3-22.8c0.1,0.1,0.1,0.1,0.2,0.2c0.4,0.5,1.2,1.5,1.6,2c0.4,0.6,1.4,1.7,1.8,2.3c0.3,0.5,0.5,1.8,0.9,2.2
        c0.3,0.3,1.5,0.7,1.6,0.7s1.1,1,1.5,1.2c0.5,0.3,1.5,0.4,2,0.7c0.6,0.4,1.5,1.6,1.8,2.3c0.2,0.3,0.6,1.2,0.4,1.5
        c-0.1,0.2-0.7,0.2-0.9,0.3c-0.3,0.2-0.8,0.7-0.8,1c-0.1,0.4,0.6,1,0.5,1.4c-0.1,0.3-0.7,0.8-1.1,0.9c-0.2,0.1-0.6-0.1-0.7,0
        c-0.2,0.2-0.1,0.7,0,0.9c0.1,0.3,0.9,0.6,1,0.9c0.1,0.3-0.4,0.7-0.3,1c0.1,0.5,1.2,1.4,1.2,1.5s-0.9,0.3-1.2,0.3
        c-0.3,0-1.1-0.4-1.2-0.1c-0.2,0.3,0.7,0.8,0.8,1.2c0.1,0.3-0.2,1,0,1.3c0.1,0.3,0.6,0.7,0.9,0.8c0.3,0,0.7-0.7,1.1-0.6
        c0.3,0,0.9,0.5,0.9,0.8c0.1,0.3-0.5,0.9-0.5,1.3c0,0.3,0.3,0.8,0.5,0.9c0.3,0.2,1.2-0.4,1.5-0.2c0.4,0.2,0.7,1.1,0.9,1.4
        c0.2,0.3,0.4,1,0.8,1.1c0.2,0,0.5-0.6,0.7-0.7c0.2-0.1,0.8-0.2,1.1-0.2c0.3,0.1,1,0.4,1.2,0.7c0.1,0.3-0.2,0.8-0.4,1
        c-0.2,0.1-0.7,0-0.8,0.1c-0.1,0.2-0.1,0.7,0,0.9c0.1,0.2,0.7,0.4,0.9,0.6c0.3,0.3,1,0.8,1.2,1.1c0.3,0.3,0.8,0.9,0.9,1.3
        c0,0.3-0.2,1-0.4,1.2c-0.3,0.2-1.1,0.2-1.3,0.4c-0.2,0.1-0.5,0.5-0.4,0.7c0,0,0,0,0,0c-0.2,0-0.5-0.1-0.6-0.1
        c-0.5-0.1-1.3-1-1.8-1.2c-0.3-0.2-0.9-0.4-1.3-0.5c-0.5,0-1.4,0.2-1.9,0.3c-0.3,0.1-1,0.2-1.3,0.3c-0.5,0.2-1.6,0.6-2.1,1
        c-0.3,0.2-0.4,1-0.7,1.2c-0.3,0.2-1.1,0.2-1.4,0.4c-0.3,0.1-0.6,0.7-0.8,0.8c-0.7,0.3-2.3,0.2-3.1,0c-0.2,0-0.6-0.3-0.8-0.4
        c-0.5-0.1-1.4,0-1.9,0c-0.4,0-1.2-0.2-1.6-0.3c-0.3-0.1-0.9-0.7-1.3-0.7c-0.4-0.1-1.2,0.2-1.6,0.4c-0.4,0.2-1.1,0.9-1.6,1
        c-0.3,0.1-0.9,0-1.2-0.2c-0.4-0.2-0.8-1.2-1.1-1.5c-0.3-0.2-1-0.4-1.2-0.7c-0.2-0.3-0.3-1.1-0.4-1.4c-0.1-0.5-0.3-1.6-0.7-2
        c-0.3-0.3-1.6-0.5-1.6-0.5l-2.1-1.7l-9.5,3.7c-0.2-0.1-0.3-0.3-0.5-0.3c-0.2-0.1-0.8,0-1.1-0.1c-0.3-0.1-0.7-0.4-1-0.6
        c-0.2-0.2-0.8-0.4-0.9-0.7c-0.2-0.2-0.3-0.8-0.5-1.1c-0.2-0.3-0.6-0.7-0.8-0.9c-0.2-0.1-0.5-0.4-0.7-0.5c-0.2-0.1-0.5,0-0.7-0.1
        c-0.3-0.2-0.6-0.8-0.8-1c-0.2-0.2-0.7-0.4-0.9-0.5c-0.2,0-0.7,0.1-1,0.1c0,0,0,0,0,0L368.1,217.2z"/>
    </g>
    <g id="Glascock">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="glascock-county" ref="glascock-county" class="st10" d="M344,222.7l8.9,4.4l-14.8,12.5c0,0-0.1,1.1,0.1,1.4c0.1,0.2,0.7,0.4,0.8,0.6
        c0.2,0.3,0.3,1.1,0.1,1.4c-0.1,0.1-0.4,0.1-0.4,0.2c-0.2,0.3-0.1,1-0.1,1.4c0,0.3,0.4,1.1,0.1,1.3c-0.2,0.2-0.8,0.1-1.1,0
        c-0.2,0-0.6-0.3-0.8-0.4c-0.4-0.1-1.1,0.2-1.4,0.2c-0.2,0-0.7-0.1-0.9-0.1c-0.3-0.1-0.9-0.4-1.3-0.3c-0.3,0.1-0.6,0.8-0.9,0.8
        c-0.3,0-0.7-0.6-1-0.6c-0.1,0-0.2,0-0.4,0c0-0.2,0-0.3-0.1-0.4c-0.2-0.3-0.8-0.8-1.2-0.9c-0.4-0.1-1.1,0.4-1.4,0.2
        c-0.2-0.2-0.1-0.9-0.3-1.1c-0.2-0.3-1-0.6-1.4-0.8c-0.2-0.1-0.7-0.1-0.9-0.3c-0.2-0.2-0.2-0.6-0.2-0.8c-0.1-0.3-0.2-0.9-0.4-1.1
        c-0.3-0.3-1.2-0.2-1.5-0.3c-0.2-0.1-0.6-0.4-0.7-0.6c-0.1-0.3,0.3-0.8,0.2-1.1c-0.1-0.3-0.8-0.6-0.9-0.9c0-0.3,0.6-0.8,0.7-1.1
        c0.1-0.4-0.3-1.1-0.3-1.4c0-0.3,0.1-0.8,0-1c-0.1-0.2-0.3-0.5-0.4-0.7c-0.1-0.3-0.3-0.9-0.3-1.2c0-0.4,0.3-1.1,0.1-1.4
        c-0.1-0.2-0.2-0.3-0.5-0.5c0.2-0.3,0.4-0.6,0.6-0.7c0.4-0.2,1.4,0.2,1.8,0c0.5-0.2,1-1.2,1.4-1.4c0.4-0.2,1.2-0.4,1.6-0.5
        c0.3,0,1,0.1,1.4,0c0.4-0.1,1.3-0.6,1.6-0.9c0.3-0.3,0.7-1,0.9-1.4c0.3-0.3,0.8-1.1,1.2-1.3c0.2-0.1,0.6-0.1,0.8-0.2
        c0.3-0.1,0.7-0.6,1-0.7c0.3-0.1,1.1,0,1.4,0c0.3,0,0.9,0,1.2-0.2c0.4-0.2,0.7-1.3,1.1-1.5c0.4-0.2,1.3,0,1.7,0
        c0.5-0.1,1.4-0.4,1.8-0.4c0.5,0,1.5,0.2,2,0.5C343.3,221.6,344,222.7,344,222.7z"/>
    </g>

    <g id="Washington">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="washington-county" ref="washington-county" class="st11" d="
        M330.8,245.5c0,0.3,0,0.6,0,0.8c0,0.3-0.2,1,0,1.2c0.3,0.4,1.4,0.1,1.7,0.5c0.2,0.2,0.1,0.7,0.1,0.9c0,0.3-0.4,0.7-0.4,1
        c0,0.3,0.2,0.8,0.4,1.1c0.2,0.3,0.9,0.5,1,0.7c0.2,0.3,0.1,1,0.3,1.3c0.2,0.3,0.9,0.4,1.1,0.7c0.1,0.2-0.2,0.6-0.1,0.8
        c0.1,0.4,1.1,0.8,1.3,1.1c0.2,0.2,0.1,0.9,0.4,1c0.2,0.1,0.7-0.1,0.9,0c0.3,0,0.9,0.2,1.1,0.4c0.2,0.2,0.4,0.7,0.6,0.9
        c0.2,0.2,0.7,0.7,1,0.7c0.2,0,0.7-0.4,0.9-0.3c0.2,0.1,0.5,0.6,0.6,0.9c0.1,0.4,0,1.2,0,1.6c0.1,0.5,0.7,1.4,0.7,1.9
        c0,0.4-0.3,1.3-0.3,1.7c0,0.6,0,1.7,0.1,2.3c0.1,0.3,0.4,0.9,0.6,1.1c0.3,0.4,1.2,0.8,1.5,1.2c0.3,0.3,1,1.1,1.1,1.5
        c0.2,0.5,0.1,1.6,0,2.2c-0.1,0.3-0.5,0.9-0.5,1.2c-0.1,0.4,0.3,1.3,0.3,1.7c0,0.4-0.2,1.3-0.5,1.6c-0.2,0.2-0.7,0.4-0.8,0.6
        c-0.3,0.7,0.1,2.5,0.3,3.3c0.1,0.3,0.3,0.9,0.4,1.2H342c0,0-1.2,0.3-1.6,0.5c-0.7,0.3-2.1,0.9-2.8,1.2c-0.7,0.4-1.9,1.3-2.6,1.6
        c-0.7,0.3-2.3,0.4-3.1,0.6c-0.7,0.2-2.6,1-2.6,1l-1.3-1.7l-3.4,1.8l-2.6,0.1l-2.8,1.1c0,0-0.3-1.2-0.3-1.6c0-0.3,0.3-1,0.2-1.3
        c-0.2-0.3-1.1-0.4-1.5-0.6c-0.4-0.2-1.2-0.5-1.5-0.7c-0.2-0.1-0.9-0.7-0.9-0.7l-14.3,6.7c-0.2-0.1-0.4-0.2-0.5-0.4
        c-0.3-0.4-0.7-1.2-0.7-1.7c0-0.6,0.5-1.6,0.6-2.2c0.1-0.5,0.1-1.4,0-1.8c-0.2-0.4-1.1-0.9-1.4-1.3c-0.2-0.4-0.1-1.4-0.2-1.9
        c-0.1-0.4-0.5-1.1-0.7-1.4c-0.2-0.2-0.7-0.4-0.9-0.6c-0.2-0.2-0.5-0.8-0.8-0.9c-0.2-0.1-0.5,0.1-0.7,0.1c-0.3,0-0.9-0.1-1.2-0.2
        c-0.2,0-0.8,0.1-1,0c-0.3-0.2-0.8-0.8-1-1.2c-0.2-0.4,0-1.2-0.1-1.6c-0.2-0.4-0.9-1-1.1-1.4c-0.1-0.4,0-1.2-0.1-1.6
        c-0.1-0.4-0.2-1.3-0.5-1.7c-0.2-0.3-1-0.7-1.2-1c-0.3-0.4-0.6-1.4-0.9-2c0.2-0.6,0.4-1.4,0.4-1.5c0-0.1-0.3-1.2-0.3-1.6
        c0-0.2,0.1-0.8,0.3-0.9c0.3-0.2,1.1,0.1,1.3,0c0.3-0.2,0.6-1.1,0.7-1.5c0.1-0.5,0.3-1.5,0.1-2c-0.1-0.3-0.8-0.6-1-0.8
        c-0.2-0.2-0.4-0.8-0.5-1.1c-0.1-0.2-0.3-0.7-0.3-0.9c0.1-0.3,0.7-0.5,0.8-0.8c0.2-0.3,0.1-1.2,0.2-1.5c0.2-0.3,1-0.6,1.2-1
        c0.1-0.3,0.1-1,0-1.4c-0.1-0.3-0.4-1-0.5-1.3c0-0.1-0.1-0.4-0.1-0.6c0.2,0,0.4,0,0.6,0c0.5,0,1.4-0.1,1.8,0c0.4,0,1.1,0.2,1.4,0.1
        c0.5-0.1,1.5-0.5,1.9-0.8c0.3-0.3,0.5-1.1,0.8-1.4c0.4-0.4,1.4-0.8,2-1c0.5-0.2,1.7-0.3,2.2-0.4c0.3-0.1,0.9-0.1,1.1-0.2
        c0.3-0.2,0.3-1,0.6-1.3c0.3-0.3,1.1-0.5,1.5-0.6c0.3-0.1,1.1-0.1,1.4-0.2c0.3-0.1,0.7-0.6,0.8-0.8c0.2-0.3,0.2-1,0.3-1.3
        c0.1-0.3,0.4-1,0.6-1.2c0.3-0.4,1.3-0.8,1.6-1.2c0.3-0.3,0.7-1.1,0.8-1.5c0.1-0.4,0-1.4,0.1-1.9c0.1-0.2,0.3-0.7,0.5-0.8
        c0.4-0.3,1.4,0,1.8-0.2c0.2-0.2,0.3-0.8,0.5-1c0.2-0.3,0.9-0.8,1.2-1c0.4-0.2,1.3-0.3,1.6-0.5c0.4-0.2,1-0.9,1.4-1.2
        c0.3-0.2,0.9-0.5,1.2-0.6c0.4-0.1,1.4,0.3,1.8,0c0.3-0.2,0.4-0.9,0.5-1.2c0.1-0.1,0.2-0.3,0.3-0.5c0.2,0.2,0.4,0.3,0.5,0.5
        c0.1,0.3-0.2,1.1-0.1,1.4c0,0.3,0.2,0.9,0.3,1.2c0.1,0.2,0.4,0.5,0.4,0.7c0.1,0.2,0,0.8,0,1c0,0.4,0.4,1.1,0.3,1.4
        c0,0.3-0.7,0.8-0.7,1.1c0,0.3,0.8,0.6,0.9,0.9c0.1,0.3-0.3,0.8-0.2,1.1c0.1,0.2,0.5,0.5,0.7,0.6c0.4,0.2,1.2,0.1,1.5,0.3
        c0.2,0.2,0.3,0.8,0.4,1.1c0.1,0.2,0.1,0.7,0.2,0.8c0.2,0.2,0.7,0.2,0.9,0.3c0.4,0.2,1.2,0.5,1.4,0.8c0.2,0.2,0.1,0.9,0.3,1.1
        c0.3,0.2,1.1-0.3,1.4-0.2c0.4,0.1,1,0.6,1.2,0.9C330.8,245.2,330.8,245.4,330.8,245.5z"/>
    </g>

    <g id="Jefferson">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="jefferson-county" ref="jefferson-county" class="st11" d="
        M344.6,282.2c-0.1-0.3-0.3-0.9-0.4-1.2c-0.2-0.8-0.6-2.5-0.3-3.3c0.1-0.2,0.7-0.4,0.8-0.6c0.2-0.3,0.4-1.2,0.5-1.6
        c0-0.4-0.3-1.3-0.3-1.7c0-0.3,0.4-0.9,0.5-1.2c0.1-0.5,0.2-1.6,0-2.2c-0.1-0.4-0.8-1.2-1.1-1.5c-0.3-0.3-1.2-0.8-1.5-1.2
        c-0.2-0.2-0.5-0.8-0.6-1.1c-0.1-0.6-0.2-1.7-0.1-2.3c0-0.4,0.3-1.3,0.3-1.7c-0.1-0.5-0.6-1.4-0.7-1.9c-0.1-0.4,0.1-1.2,0-1.6
        c-0.1-0.3-0.3-0.8-0.6-0.9c-0.2-0.1-0.7,0.4-0.9,0.3c-0.3,0-0.8-0.5-1-0.7c-0.2-0.2-0.4-0.7-0.6-0.9c-0.2-0.2-0.8-0.4-1.1-0.4
        c-0.2,0-0.7,0.2-0.9,0c-0.2-0.2-0.2-0.8-0.4-1c-0.2-0.4-1.2-0.7-1.3-1.1c-0.1-0.2,0.2-0.6,0.1-0.8c-0.1-0.3-0.9-0.4-1.1-0.7
        c-0.2-0.3-0.1-1-0.3-1.3c-0.2-0.3-0.8-0.5-1-0.7c-0.2-0.2-0.4-0.8-0.4-1.1c0-0.3,0.3-0.7,0.4-1c0-0.2,0.1-0.8-0.1-0.9
        c-0.3-0.3-1.5-0.1-1.7-0.5c-0.2-0.2,0-0.9,0-1.2c0-0.2,0-0.5,0-0.8c0.2,0,0.3-0.1,0.4,0c0.3,0,0.7,0.6,1,0.6
        c0.3,0,0.6-0.8,0.9-0.8c0.3-0.1,0.9,0.3,1.3,0.3c0.2,0,0.7,0.1,0.9,0.1c0.4,0,1.1-0.2,1.4-0.2c0.2,0,0.6,0.3,0.8,0.4
        c0.3,0.1,0.9,0.2,1.1,0c0.2-0.2-0.1-1-0.1-1.3c0-0.3-0.1-1.1,0.1-1.4c0.1-0.1,0.4-0.1,0.4-0.2c0.2-0.3,0.1-1.1-0.1-1.4
        c-0.1-0.2-0.7-0.4-0.8-0.6c-0.2-0.3-0.1-1.4-0.1-1.4l14.8-12.5l5.2-4.4c0.1,0,0.1,0,0.2,0c0.4,0,1.1,0.1,1.5,0.1
        c0.3,0,0.9-0.2,1.2-0.2c0.2,0,0.6,0,0.8,0c0,0,0,0,0,0c0.2,0,0.7-0.1,1-0.1c0.3,0,0.7,0.3,0.9,0.5c0.2,0.2,0.5,0.8,0.8,1
        c0.2,0.1,0.5,0,0.7,0.1c0.2,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.6,0.7,0.8,0.9c0.2,0.3,0.3,0.9,0.5,1.1c0.2,0.2,0.7,0.5,0.9,0.7
        c0.2,0.2,0.7,0.5,1,0.6c0.3,0.1,0.8,0,1.1,0.1c0.1,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.3,0.8,0.5,1
        c0.3,0.3,1.1,0.7,1.4,1.1c0.2,0.3,0.2,1.1,0.4,1.4c0.1,0.1,0.5,0.2,0.6,0.4c0.2,0.4,0,1.4-0.2,1.8c-0.1,0.2-0.6,0.6-0.7,0.8
        c-0.3,0.6-0.4,1.9-0.5,2.6c-0.1,0.7-0.4,2-0.6,2.6c-0.2,0.4-0.8,1-0.9,1.4c-0.3,0.6-0.8,2-1,2.7c-0.4,1.4-1.1,4.1-1.3,5.5
        c-0.2,1.5-0.4,6.1-0.4,6.1l1.5,13.4l-4.1,10.9c0,0-0.1,0-0.3,0c-0.4,0-0.9,0-1.1,0.1c-0.1,0.1-0.2,0.4-0.3,0.5
        c-0.2,0.2-0.9,0.5-1.2,0.6c-0.4,0.2-1.1,0.6-1.4,0.9c-0.2,0.2-0.4,0.7-0.6,0.8c-0.2,0.1-0.7,0.1-0.9,0.1c-0.5,0-1.5-0.2-2-0.1
        c-0.2,0-0.4,0.3-0.6,0.3c-0.3,0-0.9-0.2-1.2-0.2c-0.2,0-0.6,0.1-0.8,0.2c-0.2,0.2-0.4,0.6-0.4,0.9c0,0.2,0.2,0.6,0.3,0.7
        c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.5,0.4,0.6c0,0.2-0.2,0.6-0.1,0.8c0.1,0.2,0.3,0.5,0.5,0.6c0.3,0.1,0.9-0.6,1.1-0.4
        c0.2,0.1-0.1,0.6-0.2,0.8c-0.1,0.2-0.6,0.6-0.8,0.7c-0.3,0.1-0.9,0.2-1.2,0.2c-0.3,0.1-0.8,0.3-1.1,0.5c-0.2,0.1-0.4,0.4-0.6,0.6
        c-0.3-0.2-0.7-0.5-0.9-0.5c-0.5-0.2-1.5-0.2-1.9-0.4c-0.4-0.3-1-1.1-1.4-1.5c-0.3-0.4-0.8-1.2-1.2-1.5c-0.3-0.3-1.2-0.6-1.5-0.9
        c-0.3-0.2-0.7-0.6-0.9-0.8C345.2,283.6,344.8,282.7,344.6,282.2z"/>
    </g>
    <g id="Johnson">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="johnson-county" ref="johnson-county" class="st10" d="M331.6,320l-2.6-1.4l0.6-10.6l-12.7-5.9v-2.9l-6.8-3.8l-9.8,0.9c0-0.6,0-1.2,0.1-1.6
        c0.1-0.4,0.8-0.9,1-1.3c0.2-0.6,0.3-2,0.1-2.5c-0.1-0.1-0.3-0.3-0.5-0.4l14.3-6.7c0,0,0.6,0.5,0.9,0.7c0.4,0.2,1.1,0.5,1.5,0.7
        c0.4,0.2,1.3,0.2,1.5,0.6c0.2,0.3-0.2,1-0.2,1.3c0,0.4,0.3,1.6,0.3,1.6l2.8-1.1l2.6-0.1l3.4-1.8l1.3,1.7c0,0,1.9-0.8,2.6-1
        c0.7-0.2,2.3-0.3,3.1-0.6c0.7-0.3,1.9-1.2,2.6-1.6c0.7-0.4,2.1-1,2.8-1.2c0.4-0.1,1.6-0.5,1.6-0.5h2.6c0.2,0.5,0.6,1.4,0.9,1.8
        c0.2,0.3,0.7,0.7,0.9,0.8c0.4,0.3,1.2,0.6,1.5,0.9c0.4,0.3,0.9,1.2,1.2,1.5c0.3,0.4,0.9,1.3,1.4,1.5c0.4,0.2,1.5,0.2,1.9,0.4
        c0.2,0.1,0.6,0.3,0.9,0.5c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.4-0.6,1.3-0.8,1.8c-0.1,0.3-0.4,0.8-0.5,1.1c-0.1,0.3-0.2,1.1-0.3,1.4
        c-0.1,0.2-0.3,0.7-0.5,0.9c-0.2,0.2-0.9,0.2-1.2,0.4c-0.3,0.2-0.8,0.7-1.1,0.9c-0.3,0.3-0.8,1-1.1,1.3c-0.2,0.2-0.7,0.5-0.8,0.7
        c-0.1,0.2-0.1,0.8,0,1.1c0.1,0.2,0.5,0.6,0.6,0.8c0.1,0.3-0.2,1-0.1,1.3c0.1,0.2,0.8,0.5,0.8,0.5l2,1.4l-2.8,1.5l-1.1-1.1
        l-1.6,1.6l0.8,0.8l-8.4,5.7c0,0,0,0,0,0c0.1,0.2,0.2,0.7,0.1,1c0,0.2-0.4,0.7-0.4,0.9c0,0.3,0.5,0.8,0.5,0.9
        c0.1,0.1,0.8,0.5,1,0.7c0.4,0.3,1.1,1,1.4,1.4c0.2,0.2,0.4,0.7,0.5,1c0.2,0.3,0.5,0.9,0.6,1.1c0.3,0.4,0.8,1.2,1.2,1.5
        c0.4,0.3,1.4,0.4,1.9,0.5c0.4,0.1,1.1,0.1,1.5,0.2c0.1,0,0.3,0.1,0.4,0.1h-12.2L331.6,320z"/>
    </g>
    <g id="Treutlen">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="treutlen-county" ref="treutlen-county" class="st10" d="M323.7,344.9c0,0-0.1,0-0.1,0c-0.4-0.2-0.7-1.1-0.9-1.4c-0.2-0.4-1-1-0.9-1.5
        c0.1-0.4,1.1-0.6,1.3-0.9c0.2-0.3,0.2-1.2,0.2-1.6c0-0.2-0.1-0.6,0-0.8c0.2-0.5,1.1-1,1.3-1.5c0.1-0.3-0.1-0.9,0-1.2
        c0.1-0.3,0.3-0.8,0.5-1.1c0.2-0.2,0.7-0.5,0.9-0.7c0.3-0.4,0.7-1.4,0.8-1.9c0.1-0.5-0.4-1.6-0.4-2.1c0-0.3,0-0.9,0.1-1.2
        c0.1-0.3,0.5-0.9,0.6-1.2c0.2-0.3,0.3-1,0.6-1.3c0.2-0.2,0.7-0.2,0.9-0.2c0.5-0.1,1.6,0,2.1-0.1c0.3-0.1,0.7-0.3,0.9-0.5
        c0.3-0.3,0.5-1.3,0.8-1.6c0.1-0.2,0.6-0.4,0.7-0.6c0.1-0.2,0-0.7-0.2-0.9c-0.1-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.3-0.6-0.5-0.8
        c-0.1-0.2-0.6-0.4-0.6-0.6c0-0.2,0.4-0.5,0.6-0.7l2.3,1.3h12.2c0.2,0.1,0.4,0.2,0.5,0.2c0.3,0.2,0.8,0.6,1.1,0.8
        c0.3,0.2,1.1,0.5,1.4,0.7c0.2,0.2,0.6,0.7,0.8,0.9c0.2,0.2,0.8,0.5,1.1,0.7c0.2,0.1,0.6,0.5,0.8,0.6c0.4,0.1,1.2,0.1,1.5,0.3
        c0.2,0.1,0.5,0.7,0.7,0.9c0.2,0.2,0.7,0.4,1,0.6c0.4,0.2,1.2,0.2,1.5,0.5c0.2,0.1,0.4,0.6,0.6,0.8c0.3,0.3,0.9,0.7,1.2,0.9
        c0.3,0.2,1,0.6,1.2,1c0.1,0.2,0.1,0.7,0.1,0.9c0,0.2-0.1,0.6-0.2,0.7c-0.2,0.2-0.7,0.6-0.9,0.8c-0.2,0.3-0.5,1-0.7,1.3
        c-0.2,0.3-0.6,1-0.8,1.3c-0.1,0.2-0.4,0.5-0.5,0.7c-0.1,0.3-0.2,0.9-0.4,1.1c-0.1,0.3-0.5,0.7-0.6,1c-0.1,0.3-0.1,1,0,1.3v0
        l-24.2,5.3l-0.8,1.3c-0.3-0.2-0.6-0.4-0.8-0.3c-0.4,0.1-0.7,1.5-0.7,1.5c0-0.1-1.1-0.6-1.5-0.7c-0.3-0.1-1,0.2-1.2,0
        c-0.3-0.2-0.7-0.9-1-1.1c-0.2-0.1-0.6-0.2-0.8-0.3C324.5,344.9,324,345,323.7,344.9z"/>
    </g>
    <g id="Candler">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="candler-county" ref="candler-county" class="st10" d="M394.9,348.6l-21.4-5.2c-0.1-0.3-0.3-0.6-0.3-0.8c-0.1-0.3-0.4-0.9-0.5-1.2
        c0-0.3,0.2-0.8,0.1-1c-0.2-0.3-1.2-0.6-1.2-0.6l6.8-10.6v-4.5l3.9-5.7l6.7-4.8l5.3,2.6l5.9,3c0,0,0.2,0.6,0.1,0.8
        c-0.1,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.4,0.5-0.4,0.7c0,0.2,0.5,0.5,0.5,0.8c0.1,0.3-0.2,0.9-0.2,1.2c0,0.4,0,1.1,0.2,1.4
        c0.1,0.1,0.5,0.2,0.6,0.3c0.1,0.2,0,0.8,0,1c0,0.2,0.1,0.7,0.3,0.8c0.1,0.2,0.6,0.2,0.8,0.4c0.3,0.2,0.6,0.8,0.8,1
        c0.2,0.1,0.7,0.1,0.9,0.3c0.2,0.2,0.1,0.7,0.1,1c0,0.2-0.2,0.6-0.1,0.8c0.1,0.2,0.5,0.5,0.7,0.6c0.3,0.1,1.1,0,1.3,0.3
        c0.2,0.3,0.1,1.1,0,1.5c0,0.1-0.2,0.3-0.3,0.4c-0.1,0.3-0.3,0.8-0.3,1.1c0,0.3,0.3,1,0.2,1.4c-0.1,0.2-0.5,0.4-0.6,0.6
        c-0.1,0.2-0.1,0.5-0.1,0.7c-0.1,0.4-0.2,1.1-0.3,1.4c-0.1,0.4-0.5,1.3-0.7,1.7c-0.1,0.5-0.3,1.4-0.4,1.9c-0.1,0.5-0.2,1.4-0.4,1.9
        c-0.1,0.3-0.4,1-0.5,1.3c-0.1,0.4,0,1.3-0.1,1.7c-0.1,0.2-0.4,0.5-0.5,0.6c-0.2,0.3-0.2,0.9-0.4,1.3c-0.1,0.1-0.1,0.3-0.2,0.3
        c-0.1,0.1-0.6,0.3-0.8,0.3c-0.4,0-1-0.3-1.4-0.3c-0.4-0.1-1.5-0.1-1.5-0.1L394.9,348.6z"/>
    </g>
    <g id="Evans">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="evans-county" ref="evans-county" class="st10" d="M419.6,364c0,0.2,0,0.4,0,0.5c0,0.1,0.1,0.4,0.1,0.6c0,0.3-0.1,0.9,0,1.2
        c0.1,0.2,0.7,0.2,0.9,0.4c0.2,0.2,0.3,0.7,0.4,0.8c0.1,0.2,0.5,0.3,0.6,0.5c0.1,0.2,0.1,0.7,0.2,0.9c0.1,0.2,0.3,0.8,0.6,0.9
        c0.2,0.1,0.8,0,1.1,0.1c0.3,0.1,0.8,0.4,1,0.6c0.2,0.2,0.4,0.7,0.6,0.9c0.2,0.1,0.4,0.1,0.7,0.2c-0.5,0.7-1.5,2.2-2,2.7
        c-0.5,0.6-1.5,1.7-2.1,2.2c-0.1,0.1-0.2,0.2-0.4,0.3l-13.1-0.6l-8.8-3.4l-4.4-24l2.3,0.6c0,0,1.1,0.1,1.5,0.1
        c0.3,0.1,1,0.3,1.4,0.3c0.2,0,0.6-0.1,0.8-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.2,0.1,0.2,0.1c0.2,0.2,0.6,0.7,0.6,0.7
        c0,0.1,0.9,0,1.2,0c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.8,0,1.1-0.1c0.2-0.1,0.4-0.5,0.6-0.6c0.2-0.1,0.7,0,0.9,0
        c0.3,0,1-0.1,1.4-0.1c0.3,0,0.9,0.1,1.2,0.2c0.2,0.1,0.5,0.4,0.7,0.5c0.2,0.1,0.6,0.3,0.8,0.4c0.2,0.1,0.5,0.5,0.7,0.6
        c0.2,0.1,0.6,0.3,0.8,0.4c0.3,0.2,0.7,0.9,1,1.1c0.2,0.2,0.5,0.6,0.7,0.7c0.2,0.1,0.8,0,1,0c0.2,0.1,0.5,0.4,0.7,0.5
        c0.2,0,0.5-0.1,0.7,0c0.2,0.1,0.5,0.4,0.6,0.5c0.1,0.2,0.1,0.7,0.3,1c0.1,0.1,0.4,0.3,0.4,0.4c0.2,0.3,0.3,0.9,0.3,1.2
        c0,0.3-0.2,0.9-0.1,1.2c0.1,0.3,0.6,0.9,0.8,1.1c0.1,0.1,0.4,0.3,0.6,0.4c0.2,0.2,0.5,0.7,0.6,1c0.1,0.2,0.1,0.6,0.2,0.8
        c0.1,0.3,0.5,0.7,0.5,1c0.1,0.3,0,0.9,0,1.2c0,0.1-0.2,0.4-0.2,0.6C419.6,363.7,419.6,363.8,419.6,364z"/>
    </g>
    <g id="Bulloch">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="bulloch-county" ref="bulloch-county" class="st10" d="M397.5,308.4l8.9-2.6l6.8-2.7c0.1,0,0.3,0,0.4,0c0.2,0,0.6-0.2,0.8-0.1
        c0.3,0.1,0.9,0.7,1.1,0.7c0.2,0,0.6-0.3,0.8-0.2c0.3,0.1,0.4,1,0.6,1.3c0.2,0.3,0.8,0.5,1.1,0.8c0.4,0.3,1.1,1.4,1.2,1.5
        c0.1,0.1,0,0.8,0.2,1.1c0.2,0.3,1.1,0.3,1.4,0.6c0.1,0.1,0.1,0.4,0.2,0.5c0.3,0.2,1.1-0.1,1.4,0.1c0.3,0.2,0.6,1,0.8,1.3
        c0.2,0.2,0.8,0.4,0.9,0.7c0.2,0.3,0,1,0.2,1.3c0.2,0.3,1,0.8,1.3,0.9c0.3,0.1,1,0.1,1.2,0.4c0.2,0.2,0.3,0.8,0.5,1
        c0.3,0.3,0.9,0.8,1.3,0.9c0.3,0.1,0.9-0.2,1.2-0.1c0.5,0.1,1.4,0.5,1.6,0.9c0.1,0.2-0.1,0.5,0,0.7c0.2,0.3,0.9,0.8,1.3,0.9
        c0.2,0,0.5-0.2,0.7-0.2c0.3,0,0.8-0.2,1-0.1c0.3,0.1,0.4,0.8,0.7,0.9c0.2,0.1,0.8-0.3,1-0.1c0.2,0.2-0.2,1,0,1.3
        c0.2,0.2,0.8,0.4,1.1,0.5c0.3,0.1,1,0.3,1.3,0.2c0.2-0.1,0.4-0.4,0.5-0.5c0.2-0.1,0.7-0.1,0.9-0.1c0.4,0.1,1,0.6,1.2,0.9
        c0.2,0.3,0.4,1,0.7,1.3c0.2,0.2,0.8,0.4,1.2,0.6c0.1,0,0.1,0,0.1,0.1c0.2,0.1,0.6,0.4,0.7,0.6c0.2,0.3,0.7,1,0.7,1.4
        c0,0.3-0.8,0.7-0.7,0.9c0.1,0.3,1.1,0.3,1.3,0.5c0.3,0.3,0.5,1.2,0.5,1.6c0,0.3-0.2,1-0.4,1.3c-0.2,0.3-1,0.5-1.1,0.8
        c-0.1,0.2,0.3,0.9,0.3,1c0,0.1-0.5,0.6-0.5,0.8c0,0.4,0.8,1,0.9,1.4c0.1,0.4-0.3,1.1-0.1,1.5c0.1,0.2,0.5,0.5,0.6,0.7
        c0.2,0.3,0.4,1,0.7,1.2c0.2,0.2,0.8,0.2,1,0.4c0.2,0.3,0,1.1,0.1,1.4c0.1,0.3,0.5,1,0.5,1.1c0,0.1,1,0.3,1.2,0.6
        c0.2,0.3,0.1,1.2,0.2,1.6c0.1,0.3,0.4,0.9,0.7,1.1c0.4,0.2,1.6-0.2,1.9,0.1c0.3,0.3,0,1.2,0.2,1.5c0.1,0.2,0.7,0.2,0.8,0.3
        c0.3,0.3,0.6,1,0.6,1.4c0,0.4-0.4,1.2-0.3,1.6c0,0.3,0.3,0.8,0.5,1c0.2,0.2,0.7,0.3,0.8,0.5c0.2,0.2,0.3,0.8,0.3,1.1
        c0,0.3-0.1,0.9-0.1,1.2c0,0.4,0,1.2,0.1,1.7l-35,11c0-0.1,0-0.3,0-0.4c0-0.1,0.2-0.4,0.2-0.6c0.1-0.3,0.1-0.9,0-1.2
        c-0.1-0.3-0.4-0.7-0.5-1c-0.1-0.2-0.1-0.6-0.2-0.8c-0.1-0.3-0.4-0.8-0.6-1c-0.1-0.1-0.4-0.3-0.6-0.4c-0.2-0.2-0.7-0.8-0.8-1.1
        c-0.1-0.3,0.2-0.9,0.1-1.2c0-0.3-0.1-0.9-0.3-1.2c-0.1-0.1-0.4-0.3-0.4-0.4c-0.1-0.2-0.1-0.7-0.3-1c-0.1-0.2-0.4-0.5-0.6-0.5
        c-0.2-0.1-0.5,0.1-0.7,0c-0.2-0.1-0.5-0.4-0.7-0.5c-0.2-0.1-0.8,0.1-1,0c-0.2-0.1-0.6-0.5-0.7-0.7c-0.3-0.3-0.7-0.9-1-1.1
        c-0.2-0.1-0.6-0.3-0.8-0.4c-0.2-0.1-0.5-0.5-0.7-0.6c-0.2-0.1-0.6-0.3-0.8-0.4c-0.2-0.1-0.5-0.4-0.7-0.5c-0.3-0.1-0.9-0.2-1.2-0.2
        c-0.3,0-1,0.1-1.4,0.1c-0.2,0-0.7-0.1-0.9,0c-0.2,0.1-0.4,0.5-0.6,0.6c-0.2,0.1-0.8,0-1.1,0.1c-0.1,0-0.3,0.1-0.4,0.1
        c-0.3,0.1-1.2,0.1-1.2,0c0-0.1-0.4-0.6-0.6-0.7c0,0-0.1-0.1-0.2-0.1c0.2-0.4,0.2-1,0.4-1.3c0.1-0.2,0.4-0.4,0.5-0.6
        c0.2-0.4,0.1-1.3,0.1-1.7c0.1-0.3,0.4-1,0.5-1.3c0.1-0.5,0.3-1.4,0.4-1.9c0.1-0.5,0.3-1.4,0.4-1.9c0.1-0.4,0.6-1.3,0.7-1.7
        c0.1-0.4,0.2-1.1,0.3-1.4c0-0.2,0.1-0.5,0.1-0.7c0.1-0.2,0.5-0.4,0.6-0.6c0.1-0.3-0.2-1-0.2-1.4c0-0.3,0.2-0.9,0.3-1.1
        c0.1-0.1,0.3-0.3,0.3-0.4c0.1-0.4,0.3-1.2,0-1.5c-0.2-0.3-1-0.1-1.3-0.3c-0.2-0.1-0.6-0.4-0.7-0.6c-0.1-0.2,0.1-0.6,0.1-0.8
        c0-0.2,0.1-0.8-0.1-1c-0.1-0.2-0.7-0.1-0.9-0.3c-0.3-0.2-0.6-0.8-0.8-1c-0.2-0.1-0.6-0.2-0.8-0.4c-0.1-0.2-0.2-0.6-0.3-0.8
        c0-0.3,0.1-0.8,0-1c-0.1-0.2-0.5-0.2-0.6-0.3c-0.2-0.3-0.2-1-0.2-1.4c0-0.3,0.2-0.9,0.2-1.2c-0.1-0.2-0.5-0.5-0.5-0.8
        c0-0.2,0.2-0.6,0.4-0.7c0.1-0.1,0.4-0.1,0.5-0.3c0.1-0.2-0.1-0.8-0.1-0.8l-5.9-3c-0.1-0.4-0.2-1.5-0.1-1.9c0.1-0.4,1-0.9,1.2-1.3
        c0.2-0.4,0.2-1.4,0.4-1.8c0.2-0.6,0.7-1.6,0.9-2.2c0.1-0.3,0.6-1.2,0.6-1.2L397.5,308.4z"/>
    </g>
    <g id="Screven">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="screven-county" ref="screven-county" class="st10" d="M413.2,303c-0.3-0.1-0.6-0.1-0.8-0.2c-0.3-0.2-1-0.6-1.2-0.9c-0.2-0.3-0.4-1.2-0.5-1.6
        c0-0.2,0.1-1,0.1-1l9.9-27.4l0.1-0.1l22.5-16.8c0.2-0.1,0.5-0.2,0.6-0.1c0.3,0.1,0.4,0.9,0.6,1.1c0.3,0.2,1,0.3,1.3,0.4
        c0.2,0.1,0.7,0.4,0.8,0.7c0.1,0.2-0.2,0.7-0.2,1c0,0.3-0.2,1,0,1.2c0.3,0.2,1.1-0.4,1.5-0.4c0.3,0.1,0.8,0.5,0.8,0.7
        c0.1,0.3-0.6,0.8-0.6,1.1c0,0.4,0.8,0.8,0.8,1.2c0,0.4-0.7,1-0.9,1.4c-0.2,0.3-0.3,1.2-0.5,1.4c-0.2,0.2-0.8,0.2-0.9,0.4
        c-0.2,0.2-0.1,0.9,0,1.2c0.1,0.3,0.8,0.8,1,1.1c0.2,0.3,0.3,0.9,0.5,1.1c0.2,0.2,1,0.4,1.1,0.7c0.3,0.5,0,1.7,0.1,2.2
        c0.1,0.3,0.3,1,0.6,1.2c0.3,0.2,1-0.1,1.3,0.1c0.2,0.1,0.4,0.6,0.3,0.8c-0.1,0.2-0.8,0.1-1,0.2c-0.2,0.1-0.5,0.5-0.5,0.8
        c0,0.3,0.4,0.8,0.6,0.9c0.2,0.1,0.6-0.1,0.8,0c0.4,0.1,1.2,0.7,1.3,1.1c0.1,0.3-0.6,0.8-0.7,1.1c0,0.4,0.2,1.3,0.6,1.4
        c0.2,0.1,0.4-0.5,0.6-0.5c0.2,0.1,0.2,0.7,0.4,0.9c0.3,0.3,1.5,0.2,1.8,0.6c0.1,0.2,0,0.8,0,1c-0.1,0.2-0.5,0.6-0.5,0.8
        c0,0.4,1,0.9,1,1.3c0.1,0.4-0.7,1.1-0.7,1.6c0,0.3,0.6,0.6,0.6,0.8c0,0.3-0.4,0.7-0.4,1c0,0.3,0.5,0.7,0.5,1
        c0,0.2-0.5,0.6-0.4,0.8c0.1,0.4,1.3,0.6,1.4,1c0.1,0.4-0.7,1-0.6,1.4c0,0.4,1,0.9,1,1.3c-0.1,0.4-1.1,0.5-1.2,0.9
        c0,0.2,0.5,0.6,0.5,0.8c0.1,0.3,0,1-0.1,1.2c-0.2,0.4-1.2,0.8-1.3,1.2c0,0.3,0.3,0.8,0.5,0.9c0.4,0.3,1.5,0,1.8,0.4
        c0.2,0.2-0.2,1.1-0.2,1.2c0,0.1,0.8,0.5,0.9,0.7c0.1,0.3-0.4,0.9-0.5,1.3c-0.1,0.3-0.2,0.9,0,1.2c0.1,0.3,1,0.4,1.1,0.7
        c0,0.3-0.8,0.7-1.1,0.8c-0.3,0.2-1,0.2-1.2,0.5c-0.2,0.2-0.5,0.8-0.5,1.1c0,0.5,0.7,1.2,1,1.6c0.3,0.5,1,1.5,1.4,2
        c0.2,0.2,0.6,0.4,1,0.7l-15.8,11l-0.9,2c-0.4-0.2-1-0.3-1.2-0.6c-0.3-0.3-0.4-1-0.7-1.3c-0.2-0.3-0.9-0.8-1.2-0.9
        c-0.2-0.1-0.7,0-0.9,0.1c-0.2,0.1-0.3,0.5-0.5,0.5c-0.3,0.1-1-0.1-1.3-0.2c-0.3-0.1-0.9-0.2-1.1-0.5c-0.2-0.3,0.2-1.1,0-1.3
        c-0.2-0.2-0.8,0.2-1,0.1c-0.3-0.1-0.4-0.8-0.7-0.9c-0.2-0.1-0.8,0.1-1,0.1c-0.2,0-0.5,0.2-0.7,0.2c-0.4-0.1-1.1-0.6-1.3-0.9
        c-0.1-0.2,0.1-0.6,0-0.7c-0.2-0.4-1.2-0.8-1.6-0.9c-0.3-0.1-0.9,0.2-1.2,0.1c-0.4-0.1-1-0.6-1.3-0.9c-0.2-0.2-0.3-0.8-0.5-1
        c-0.2-0.2-0.9-0.2-1.2-0.4c-0.4-0.2-1.1-0.6-1.3-0.9c-0.2-0.3,0-1-0.2-1.3c-0.2-0.3-0.7-0.5-0.9-0.7c-0.3-0.3-0.5-1.1-0.8-1.3
        c-0.3-0.2-1.1,0.1-1.4-0.1c-0.1-0.1-0.1-0.4-0.2-0.5c-0.3-0.3-1.1-0.3-1.4-0.6c-0.2-0.2-0.1-0.9-0.2-1.1c-0.1-0.1-0.8-1.2-1.2-1.5
        c-0.2-0.2-0.9-0.5-1.1-0.8c-0.2-0.3-0.3-1.2-0.6-1.3c-0.2-0.1-0.6,0.2-0.8,0.2c-0.2,0-0.8-0.6-1.1-0.7c-0.2,0-0.6,0.1-0.8,0.1
        C413.5,303.1,413.4,303,413.2,303z"/>
    </g>
    <g id="Jenkins">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="jenkins-county" ref="jenkins-county" class="st10" d="M420.9,271.9L420.9,271.9l-10,27.4c0,0-0.1,0.7-0.1,1c0,0.4,0.2,1.2,0.5,1.6
        c0.2,0.3,0.9,0.7,1.2,0.9c0.2,0.1,0.5,0.2,0.8,0.2l-6.8,2.7l-8.9,2.6l-8.8-6.8l-6.5-18.7l2.6-8.1l4.4-4l11.7-0.6l10.8-3.9l2.9,1.4
        c0,0,0.7-0.5,0.9-0.4c0.3,0.1,0.3,1.1,0.6,1.3c0.3,0.2,1,0,1.3,0c0.4,0.1,1.1,0.4,1.5,0.6c0.3,0.2,1,0.7,1.3,1
        C420.4,270.4,420.7,271.3,420.9,271.9z"/>
    </g>
    <g id="Burke">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="burke-county" ref="burke-county" class="st10" d="M382.2,282.8l-0.1,0.3c0,0-0.6,0.4-0.9,0.5c-0.4,0.1-1.3-0.1-1.7,0c-0.3,0-1,0.1-1.3,0.2
        c-0.4,0.1-0.9,0.8-1.3,0.8c-0.4,0.1-1.3-0.3-1.7-0.4c-0.4-0.1-1-0.7-1.4-0.8c-0.3-0.1-0.8,0.2-1,0.1c-0.3,0-0.8-0.3-1-0.5
        c-0.4-0.3-1-1-1.4-1.2c-0.3-0.1-0.8-0.1-1.1-0.1c-0.5,0-1.5,0.2-2,0.1c-0.4-0.1-1.2-0.3-1.4-0.5c-0.2-0.2-0.5-0.7-0.8-1.2
        c0.2,0,0.3,0,0.3,0l4.1-10.9l-1.5-13.4c0,0,0.2-4.6,0.4-6.1c0.2-1.4,0.9-4.2,1.3-5.5c0.2-0.7,0.7-2,1-2.7c0.2-0.4,0.8-1,0.9-1.4
        c0.3-0.6,0.5-2,0.6-2.6c0.1-0.6,0.2-2,0.5-2.6c0.1-0.2,0.6-0.6,0.7-0.8c0.2-0.4,0.4-1.4,0.2-1.8c-0.1-0.2-0.5-0.2-0.6-0.4
        c-0.2-0.3-0.2-1.1-0.4-1.4c-0.2-0.4-1.1-0.7-1.4-1.1c-0.2-0.2-0.3-0.8-0.5-1c-0.1-0.1-0.2-0.2-0.3-0.3l9.5-3.7l2.1,1.7
        c0,0,1.3,0.2,1.6,0.5c0.4,0.4,0.5,1.5,0.7,2c0.1,0.4,0.1,1.2,0.4,1.4c0.2,0.3,1,0.4,1.2,0.7c0.4,0.3,0.7,1.3,1.1,1.5
        c0.3,0.2,0.9,0.2,1.2,0.2c0.5-0.1,1.2-0.8,1.6-1c0.4-0.2,1.2-0.4,1.6-0.4c0.4,0,1,0.6,1.3,0.7c0.4,0.1,1.2,0.3,1.6,0.3
        c0.5,0.1,1.4-0.1,1.9,0c0.2,0.1,0.6,0.3,0.8,0.4c0.8,0.2,2.4,0.3,3.1,0c0.3-0.1,0.6-0.7,0.8-0.8c0.3-0.2,1.1-0.2,1.4-0.4
        c0.3-0.2,0.5-0.9,0.7-1.2c0.4-0.4,1.5-0.8,2.1-1c0.3-0.1,0.9-0.3,1.3-0.3c0.5-0.1,1.4-0.3,1.9-0.3c0.3,0,1,0.3,1.3,0.5
        c0.5,0.2,1.3,1.1,1.8,1.2c0.1,0,0.4,0.1,0.6,0.1c0.2,0.5,1.3,0.7,1.7,0.9c0.4,0.3,1.2,1,1.6,1.3c0.2,0.2,0.8,0.4,1,0.6
        c0.2,0.3,0.2,1.2,0.5,1.4c0.2,0.1,0.7,0,0.9,0c0.3,0.1,0.8,0.7,1.1,0.7c0.3,0,0.8-0.2,0.9-0.4c0.1-0.3-0.5-0.9-0.3-1.2
        c0.1-0.1,0.5-0.1,0.7,0c0.4,0.2,0.9,1,1.1,1.4c0.2,0.3,0.5,1.1,0.4,1.4c-0.1,0.3-0.8,0.5-0.9,0.8c-0.3,0.6-0.1,2,0,2.6
        c0.2,0.6,0.8,1.6,1.2,2c0.3,0.3,1,0.7,1.3,1c0.5,0.5,1.4,1.6,2,2.1c0.5,0.4,1.5,1.2,2.1,1.4c0.4,0.2,1.4,0.1,1.9,0.2
        c0.4,0.1,1,0.4,1.3,0.6c0.5,0.3,1.4,1,1.9,1.3c0.3,0.2,0.8,0.7,1.1,0.7c0.4,0.1,1.1-0.3,1.5-0.3c0.3,0,1,0.1,1.2,0.3
        c0.3,0.2,0.4,0.9,0.5,1.2c0.2,0.3,0.8,0.9,0.8,1c0,0.1,1.1,0,1.2,0c0.1,0,1.1,1,1.3,1.2c0.1,0.2,0.8,0.3,1,0.5
        c0.2,0.3,0,1.2,0.3,1.5c0.3,0.3,1.1,0.5,1.5,0.5c0.1,0,0.2,0,0.4-0.1l-22.5,16.8c-0.2-0.6-0.5-1.5-0.8-1.9c-0.2-0.3-0.9-0.8-1.3-1
        c-0.3-0.2-1.1-0.5-1.5-0.6c-0.3-0.1-1,0.1-1.3,0c-0.3-0.2-0.2-1.1-0.6-1.3c-0.2-0.1-0.9,0.4-0.9,0.4l-2.9-1.4l-10.8,3.9l-11.7,0.6
        l-4.4,4L382.2,282.8z"/>
    </g>
    <g id="Emanuel">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="emanuel-county" ref="emanuel-county" class="st10" d="M346.1,321.3c-0.2-0.1-0.3-0.1-0.4-0.1c-0.4-0.1-1.1-0.1-1.5-0.2c-0.5-0.1-1.5-0.2-1.9-0.5
        c-0.4-0.3-0.9-1.1-1.2-1.5c-0.2-0.3-0.5-0.8-0.6-1.1c-0.1-0.2-0.4-0.8-0.5-1c-0.3-0.4-1.1-1.1-1.4-1.4c-0.2-0.2-0.9-0.6-1-0.7
        c-0.1-0.1-0.5-0.6-0.5-0.9c0-0.2,0.3-0.7,0.4-0.9c0-0.2-0.1-0.7-0.1-1c0,0,0,0,0,0l8.4-5.7l-0.8-0.8l1.6-1.6l1.1,1.1l2.8-1.5
        l-2-1.4c0,0-0.7-0.2-0.8-0.5c-0.2-0.3,0.2-1,0.1-1.3c-0.1-0.2-0.5-0.6-0.6-0.8c-0.1-0.3-0.1-0.8,0-1.1c0.1-0.2,0.6-0.5,0.8-0.7
        c0.3-0.3,0.8-1,1.1-1.3c0.2-0.3,0.8-0.8,1.1-0.9c0.3-0.2,1-0.2,1.2-0.4c0.2-0.2,0.4-0.6,0.5-0.9c0.1-0.3,0.2-1.1,0.3-1.4
        c0.1-0.3,0.4-0.8,0.5-1.1c0.2-0.4,0.5-1.3,0.8-1.8c0-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.4-0.5,0.6-0.6c0.2-0.2,0.8-0.4,1.1-0.5
        c0.3-0.1,0.9-0.1,1.2-0.2c0.2-0.1,0.7-0.5,0.8-0.7c0.1-0.2,0.4-0.7,0.2-0.8c-0.2-0.2-0.8,0.5-1.1,0.4c-0.2,0-0.4-0.4-0.5-0.6
        c-0.1-0.2,0.1-0.6,0.1-0.8c0-0.2-0.3-0.5-0.4-0.6c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.2-0.3-0.5-0.3-0.7c0-0.2,0.2-0.7,0.4-0.9
        c0.2-0.1,0.6-0.2,0.8-0.2c0.3,0,0.9,0.3,1.2,0.2c0.2,0,0.4-0.3,0.6-0.3c0.5-0.1,1.5,0.1,2,0.1c0.2,0,0.7,0,0.9-0.1
        c0.2-0.1,0.4-0.6,0.6-0.8c0.3-0.3,1.1-0.7,1.4-0.9c0.3-0.2,0.9-0.4,1.2-0.6c0.1-0.1,0.2-0.4,0.3-0.5c0.2-0.1,0.7-0.1,1.1-0.1
        c0.2,0.5,0.5,1,0.8,1.2c0.3,0.2,1.1,0.5,1.4,0.5c0.5,0.1,1.5-0.2,2-0.1c0.3,0,0.9,0,1.1,0.1c0.4,0.2,1,1,1.4,1.2
        c0.2,0.1,0.7,0.4,1,0.5c0.3,0,0.8-0.2,1-0.1c0.4,0.1,1,0.6,1.4,0.8c0.4,0.2,1.3,0.5,1.7,0.4c0.4-0.1,0.9-0.7,1.3-0.8
        c0.3-0.1,1-0.2,1.3-0.2c0.4,0,1.3,0.1,1.7,0c0.2-0.1,0.9-0.5,0.9-0.5l0.1-0.3l6.5,18.7l8.8,6.8l-0.2,0.1c0,0-0.4,0.9-0.6,1.2
        c-0.2,0.5-0.8,1.6-0.9,2.2c-0.1,0.4-0.1,1.4-0.4,1.8c-0.2,0.4-1,0.9-1.2,1.3c-0.1,0.4,0,1.5,0.1,1.9l-5.3-2.6l-6.7,4.8l-3.9,5.7
        v4.5l-6.8,10.6c0,0,1,0.3,1.2,0.6c0.1,0.2-0.1,0.8-0.1,1c0,0.3,0.4,0.9,0.5,1.2c0.1,0.2,0.2,0.5,0.3,0.8c0,0.1,0.1,0.2,0.1,0.3
        c0,0,0,0,0,0l-4.8,3l-7.4,0l0.1-2.2c0,0-0.7-1.2-1-1.6c-0.2-0.2-0.9-0.5-0.9-0.6c-0.1-0.1-0.3-0.5-0.5-0.6
        c-0.1-0.1-0.4-0.3-0.6-0.3c-0.2,0-0.6,0.3-0.8,0.2c-0.1,0-0.4-0.2-0.4-0.3c-0.2-0.2-0.3-0.8-0.4-1c-0.1-0.2-0.2-0.6-0.4-0.7
        c-0.1-0.1-0.3-0.1-0.4-0.2l0.1-0.3l-0.3,0.1c-0.1-0.4-0.1-1,0-1.3c0.1-0.3,0.5-0.7,0.6-1c0.1-0.3,0.2-0.9,0.4-1.1
        c0.1-0.2,0.4-0.5,0.5-0.7c0.2-0.3,0.6-1,0.8-1.3c0.2-0.3,0.5-1,0.7-1.3c0.2-0.2,0.7-0.5,0.9-0.8c0.1-0.2,0.2-0.5,0.2-0.7
        c0-0.2,0-0.7-0.1-0.9c-0.2-0.3-0.9-0.7-1.2-1c-0.3-0.2-1-0.6-1.2-0.9c-0.2-0.2-0.4-0.6-0.6-0.8c-0.3-0.2-1.2-0.3-1.5-0.5
        c-0.3-0.1-0.8-0.4-1-0.6c-0.2-0.2-0.5-0.7-0.7-0.9c-0.3-0.2-1.2-0.1-1.5-0.3c-0.2-0.1-0.6-0.4-0.8-0.6c-0.3-0.2-0.8-0.5-1.1-0.7
        c-0.2-0.2-0.6-0.7-0.8-0.9c-0.3-0.2-1.1-0.5-1.4-0.7c-0.3-0.2-0.8-0.7-1.1-0.8C346.5,321.5,346.3,321.4,346.1,321.3z"/>
    </g>
    <g id="Montgomery">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="montgomery-county" ref="montgomery-county" class="st10" d="M356,339.4l-3.1,6.8l-0.4,10.5l-1,6.7l-3.3,22.5c-0.2,0-0.3,0.1-0.4,0.1
        c-0.2,0.1-0.8,0.3-0.8,0.6c0,0.4,1.3,0.4,1.4,0.7c0.1,0.2-0.1,0.7-0.3,0.9c-0.2,0.2-0.8,0.5-1.1,0.5c-0.4,0-1-0.4-1.3-0.6
        c-0.3-0.2-0.8-0.7-1.2-0.8c-0.3-0.1-0.9,0.1-1.1,0.1c-0.1,0-0.1,0-0.2,0.1c-0.3-0.4-0.6-0.7-0.7-1c-0.2-0.4-0.1-1.4-0.3-1.7
        c-0.1-0.2-0.6-0.2-0.7-0.4c-0.2-0.2-0.4-0.7-0.6-0.9c-0.2-0.1-0.7-0.1-0.9-0.2c-0.2-0.1-0.5-0.7-0.7-0.9c-0.1-0.1-0.5-0.3-0.6-0.4
        c-0.3-0.2-1.1-0.5-1.4-0.8c-0.2-0.2-0.2-0.7-0.4-0.9c-0.1-0.2-0.4-0.4-0.4-0.6c0-0.2,0.4-0.6,0.3-0.8c-0.1-0.2-0.7-0.4-0.7-0.6
        c-0.1-0.4,0.5-1.1,0.4-1.4c-0.1-0.3-0.8-0.6-0.9-0.8c-0.1-0.2-0.2-0.7-0.2-1c0-0.4,0.3-1.3,0.2-1.4c0-0.1-1-0.6-1.1-1
        c0-0.2,0.5-0.5,0.6-0.7c0.2-0.3,0.4-0.9,0.4-1.2c0-0.4-0.7-0.9-0.7-1.3c-0.1-0.3,0.1-0.9,0-1.1c-0.1-0.3-0.8-1-0.9-1.1
        c0-0.1-0.6-0.7-0.5-1c0.1-0.3,0.7-0.6,0.9-0.8c0.1-0.2,0.2-0.8,0.4-1c0.1-0.2,0.6-0.6,0.6-0.9c0.1-0.4-0.2-1.1-0.4-1.4
        c-0.2-0.3-0.8-0.5-1-0.7c-0.3-0.4-0.7-1.5-0.7-2c0-0.4,0.4-1,0.5-1.4c0.1-0.2,0.1-0.6,0.1-0.9c0-0.3-0.2-0.7-0.3-1
        c-0.1-0.2-0.5-0.5-0.6-0.7c-0.1-0.3,0.4-0.9,0.3-1.2c-0.1-0.4-0.6-1-0.9-1.3c-0.2-0.2-0.8-0.2-0.8-0.5c-0.1-0.4,0.5-1.3,0.7-1.7
        c0.1-0.3,0.6-1,0.6-1.1c0-0.1-0.2-0.8-0.3-1c-0.1-0.2-0.7-0.4-0.7-0.6c-0.1-0.3,0.2-1,0.1-1.3c-0.1-0.2-0.3-0.6-0.5-0.7
        c-0.1-0.1-0.4-0.3-0.6-0.5l0.8-1.3l24.2-5.3c0,0.1,0,0.1,0.1,0.2C355.9,339.4,355.9,339.4,356,339.4z"/>
    </g>
    <g id="Toombs">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="toombs-county" ref="toombs-county" class="st10" d="M353.7,386.1c-0.3,0-0.6,0.1-0.8,0.2c-0.4,0.1-1.1,0.8-1.5,0.7c-0.2,0-0.7-0.4-0.8-0.4
        c-0.1,0-1.2-0.7-1.6-0.8c-0.2,0-0.4,0-0.7,0.1l3.3-22.5l1-6.7l0.4-10.5l3.1-6.8c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.5,0.4,0.7
        c0.1,0.2,0.2,0.8,0.4,1c0.1,0.1,0.3,0.3,0.4,0.3c0.2,0,0.6-0.2,0.8-0.2c0.2,0,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.5,0.5,0.6
        c0.1,0.1,0.7,0.4,0.9,0.6c0.3,0.3,1,1.6,1,1.6l-0.1,2.2l7.4,0l4.8-3c0,0.4-0.2,1.1,0,1.4c0.1,0.2,0.5,0.4,0.5,0.6
        c0.1,0.4-0.2,1.4-0.1,1.8c0,0.3,0.3,0.8,0.4,1.1c0.1,0.2,0.3,0.6,0.4,0.8c0.1,0.4,0,1.3,0.1,1.7c0.1,0.3,0.8,0.6,0.9,0.9
        c0.2,0.4,0,1.3,0,1.8c0,0.5,0,1.4,0.1,1.8c0.1,0.3,0.5,0.9,0.7,1.2c0.3,0.3,1,0.8,1.2,1.1c0.2,0.3,0.5,0.9,0.5,1.3
        c0.1,0.5-0.2,1.4-0.2,1.9c0,0.5,0.5,1.3,0.5,1.8c-0.1,0.4-0.7,1.1-1,1.4c-0.2,0.2-0.8,0.6-0.8,0.9c0,0.2,0.5,0.5,0.6,0.7
        c0.1,0.1,0.2,0.6,0.2,0.6l-3.8,25.9c-0.1,0-0.3-0.1-0.4-0.1c-0.3,0-1.3,0.5-1.3,0.4c0-0.1,0-0.9-0.1-1.2c-0.1-0.2-0.3-0.5-0.5-0.6
        c-0.2-0.1-0.6,0.2-0.8,0.1c-0.2-0.1-0.4-0.8-0.6-0.9c-0.2-0.1-0.6,0-0.8-0.1c-0.3-0.1-0.7-0.8-1-0.8c-0.1,0-0.3,0.1-0.4,0.2
        c-0.2,0.2-0.2,0.7-0.4,0.9c-0.1,0.1-0.4,0.1-0.5,0.1c-0.2,0-0.8,0.1-1,0.1c-0.3,0-0.8,0.3-1.1,0.3c-0.3,0-0.7-0.4-1-0.5
        c-0.4-0.2-1.1-0.4-1.5-0.5c-0.3,0-1,0-1.1,0c-0.1,0-0.5-0.7-0.8-0.8c-0.3-0.1-0.7,0.5-1,0.5c-0.2,0-0.7-0.3-0.8-0.5
        c-0.1-0.2,0.2-0.6,0.1-0.8c-0.1-0.3-0.7-0.7-1-0.8c-0.2-0.1-0.8,0-1,0.1c-0.2,0.1-0.2,0.6-0.4,0.6c-0.4,0.1-1.3-0.4-1.6-0.7
        c-0.3-0.3-0.6-1.2-0.9-1.4C353.9,386.1,353.8,386.1,353.7,386.1z"/>
    </g>
    <g id="Tattnall">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="tattnall-county" ref="tattnall-county" class="st10" d="M373.6,343.7C373.6,343.7,373.6,343.7,373.6,343.7c0-0.1-0.1-0.2-0.1-0.3l21.4,5.2l4.4,24
        l8.8,3.4l13.1,0.6c-0.7,0.6-1.9,1.4-2.5,1.9c-0.6,0.4-1.7,1.3-2.3,1.7c-0.3,0.2-1,0.2-1.1,0.5c-0.4,0.3-0.8,0.3-1,0.4
        c-0.3,0.1-0.2,0.4-0.4,0.6c-0.2,0.2-0.8,0.5-0.9,0.7c-0.1,0.2,0.3,0.8,0.1,1c-0.2,0.2-0.8,0-1,0.1c-0.3,0.2-0.5,1.1-0.7,1.4
        c-0.1,0.2-0.1,0.7-0.3,0.8c-0.2,0.2-0.9,0.1-1.1,0.3c-0.2,0.2-0.1,0.8-0.2,1.1c-0.2,0.3-0.8,0.7-1,1c-0.1,0.2-0.1,0.7,0,1
        c0.1,0.2,0.5,0.3,0.6,0.5c0.1,0.3-0.2,0.9-0.2,1.2c0,0.3,0.3,0.9,0.3,1.1c0,0.4-0.3,1-0.5,1.3c-0.1,0.2-0.6,0.5-0.8,0.7
        c-0.1,0.2-0.2,0.7-0.2,0.9c0,0.2,0.3,0.5,0.2,0.7c-0.1,0.3-0.7,0.6-0.9,0.7c-0.2,0.1-0.9-0.1-1.1,0.1c-0.2,0.2,0.2,0.8,0,1
        c-0.1,0.2-0.6,0.1-0.7,0.2c-0.2,0.3,0.1,1.1,0,1.4c0,0.4-0.2,1.1-0.4,1.4c-0.2,0.3-0.7,0.9-1.1,1.1c-0.2,0.1-0.7,0.2-0.8,0.4
        c-0.2,0.2-0.4,0.7-0.5,1c-0.2,0.3-0.6,0.7-0.7,1c-0.1,0.4,0.3,1.3,0,1.6c-0.1,0.2-0.7,0.2-0.9,0.4c-0.2,0.1-0.6,0.3-0.8,0.4
        c-0.3,0.1-0.9,0-1.1,0.2c-0.2,0.3-0.1,1.1,0,1.5c0,0.2,0.1,0.7,0.1,1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.4-0.8-0.7-0.9
        c-0.2-0.1-0.8,0-1-0.2c-0.2-0.1-0.3-0.6-0.4-0.7c-0.2-0.1-0.6-0.1-0.7-0.3c-0.2-0.2-0.1-0.8-0.1-1c-0.1-0.3-0.3-0.8-0.5-1
        c-0.2-0.2-0.7-0.5-0.9-0.5c-0.2,0-0.3,0.5-0.5,0.5c-0.2,0-0.5-0.4-0.8-0.5c-0.2-0.1-0.7,0-0.9-0.1c-0.3-0.1-0.6-0.7-0.8-1
        c0,0,0,0,0,0c-0.2-0.2-1-0.8-1.1-0.8c-0.1,0-1.1-0.3-1.4-0.5c-0.2-0.2-0.7-0.9-0.8-0.9c-0.1,0-0.8-0.5-1-0.6
        c-0.2-0.1-0.7-0.8-0.7-0.8c-0.1,0-0.9-0.1-1-0.3c-0.2-0.2-0.1-0.8-0.1-1c0.1-0.4,0.7-1,0.6-1.4c-0.1-0.3-0.5-0.6-0.8-0.7
        c-0.4-0.1-1.2,0.3-1.6,0.1c-0.3-0.1-0.6-1-0.9-1.2c-0.2-0.1-0.8-0.2-1-0.3c-0.2-0.1-0.5-0.5-0.7-0.6c-0.3-0.1-0.9-0.2-1.3-0.2
        c-0.3,0-0.8,0.3-1.1,0.4c-0.1,0.1-0.3,0.3-0.5,0.3c-0.2,0-0.6-0.2-0.8-0.3c-0.3-0.2-0.7-0.8-1.1-0.9c-0.3-0.1-0.9,0.1-1.2,0
        c-0.3-0.1-0.7-0.4-1-0.6c-0.2-0.1-0.6-0.3-0.9-0.4l3.8-25.9c0,0-0.1-0.5-0.2-0.6c-0.1-0.2-0.6-0.5-0.6-0.7c0-0.3,0.6-0.7,0.8-0.9
        c0.3-0.3,0.9-1,1-1.4c0.1-0.4-0.4-1.3-0.5-1.8c0-0.5,0.2-1.4,0.2-1.9c-0.1-0.3-0.3-1-0.5-1.3c-0.2-0.3-1-0.8-1.2-1.1
        c-0.2-0.3-0.6-0.8-0.7-1.2c-0.1-0.4-0.1-1.4-0.1-1.8c0-0.4,0.1-1.3,0-1.8c-0.1-0.3-0.7-0.6-0.9-0.9c-0.2-0.4,0-1.3-0.1-1.7
        c-0.1-0.2-0.3-0.6-0.4-0.8c-0.1-0.3-0.4-0.8-0.4-1.1c-0.1-0.5,0.2-1.4,0.1-1.8c-0.1-0.2-0.5-0.4-0.5-0.6
        C373.5,344.8,373.7,344.1,373.6,343.7z"/>
    </g>
    <g id="Appling">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="appling-county" ref="appling-county" class="st10" d="M373.8,392.5c0.3,0.1,0.7,0.3,0.9,0.4c0.3,0.1,0.7,0.5,1,0.6c0.3,0.1,0.9-0.1,1.2,0
        c0.3,0.1,0.8,0.7,1.1,0.9c0.2,0.1,0.6,0.3,0.8,0.3c0.1,0,0.3-0.2,0.5-0.3c0.3-0.1,0.8-0.4,1.1-0.4c0.3,0,1,0.1,1.3,0.2
        c0.2,0.1,0.5,0.5,0.7,0.6c0.2,0.1,0.8,0.2,1,0.3c0.3,0.2,0.6,1,0.9,1.2c0.4,0.2,1.2-0.3,1.6-0.1c0.3,0.1,0.7,0.5,0.8,0.7
        c0.1,0.4-0.5,1-0.6,1.4c0,0.3-0.1,0.8,0.1,1c0.2,0.2,1,0.3,1,0.3c0.1,0,0.6,0.7,0.7,0.8c0.2,0.1,0.9,0.6,1,0.6
        c0.1,0,0.6,0.7,0.8,0.9c0.3,0.2,1.3,0.5,1.4,0.5c0.1,0,0.9,0.6,1.1,0.8c0,0,0,0,0,0h-3.8v3.7h-1.5v1.7h1.4v1.9h-4.3v24.4h-1.7v2.1
        h2v9.5c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.5,0.4-0.8,0.5c-0.4,0-1.3-0.4-1.7-0.6c-0.6-0.3-1.7-1.3-2.1-1.8
        c-0.3-0.3-0.7-1.2-1.1-1.4c-0.3-0.3-1.2-0.5-1.5-0.8c-0.2-0.2-0.5-0.8-0.7-1c-0.3-0.4-1.3-1-1.5-1.4c-0.1-0.2-0.2-0.4-0.2-0.7
        c-0.1-0.3-0.1-0.5-0.2-0.7c-0.2-0.6-0.7-1.8-1.1-2.3c-0.2-0.2-0.7-0.6-0.9-0.7c-0.3-0.1-0.8-0.2-1.1-0.4c-0.3-0.2-0.7-0.8-1.1-1
        c-0.3-0.2-1-0.4-1.3-0.6c-0.2-0.2-0.6-0.7-0.8-0.8c-0.5-0.3-1.9-0.6-2.4-0.9c-0.7-0.5-1.7-1.8-2.3-2.4c-0.5-0.5-1.7-1.4-2.3-1.8
        c-0.4-0.2-1.2-0.6-1.5-0.8c-0.4-0.2-1.2-0.8-1.7-0.9c-0.3-0.1-1,0.1-1.3,0c-0.4-0.2-1-0.9-1.4-1.2c-0.3-0.3-0.9-0.8-1.3-1
        c-0.3-0.1-1.2-0.2-1.3-0.2c-0.1,0-0.7-0.5-0.9-0.7c-0.3-0.3-0.6-1-0.8-1.3c-0.2-0.2-0.7-0.5-0.9-0.7c-0.2-0.2-0.4-0.6-0.6-0.9
        c-0.2-0.3-0.4-1-0.7-1.2c-0.2-0.2-0.7-0.5-0.9-0.7c-0.2-0.2-0.5-0.9-0.5-0.9h-2.7v-2.6h-2.5v-2.1h2.4V402h9.6v-15.9
        c0.1,0,0.2,0,0.3,0c0.4,0.2,0.6,1.1,0.9,1.4c0.3,0.3,1.2,0.8,1.6,0.7c0.2-0.1,0.3-0.5,0.4-0.6c0.2-0.1,0.8-0.2,1-0.1
        c0.3,0.1,0.9,0.5,1,0.8c0.1,0.2-0.2,0.7-0.1,0.8c0.1,0.2,0.6,0.5,0.8,0.5c0.3,0,0.7-0.5,1-0.5c0.3,0.1,0.7,0.8,0.8,0.8
        c0.1,0,0.8,0,1.1,0c0.4,0.1,1.1,0.4,1.5,0.5c0.2,0.1,0.7,0.5,1,0.5c0.3,0,0.8-0.2,1.1-0.3c0.2,0,0.8-0.1,1-0.1
        c0.1,0,0.4,0,0.5-0.1c0.2-0.1,0.3-0.7,0.4-0.9c0.1-0.1,0.3-0.2,0.4-0.2c0.3,0,0.7,0.7,1,0.8c0.2,0.1,0.6,0,0.8,0.1
        c0.2,0.1,0.3,0.8,0.6,0.9c0.2,0.1,0.6-0.2,0.8-0.1c0.2,0.1,0.4,0.4,0.5,0.6c0.1,0.3,0.1,1.1,0.1,1.2c0,0.1,1-0.4,1.3-0.4
        C373.5,392.4,373.7,392.4,373.8,392.5z"/>
    </g>
    <g id="Bacon">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="bacon-county" ref="bacon-county" class="st10" d="M354.7,453.3l-6.1,0V450H347v-2.8l-10.2-0.2v-10.6h-3.4v-14h10.7v-4.9h2.7c0,0,0.3,0.7,0.5,0.9
        c0.2,0.2,0.7,0.5,0.9,0.7c0.2,0.3,0.5,0.9,0.7,1.2c0.1,0.2,0.4,0.7,0.6,0.9c0.2,0.2,0.7,0.5,0.9,0.7c0.3,0.3,0.6,1,0.8,1.3
        c0.2,0.2,0.8,0.7,0.9,0.7c0.1,0,1,0,1.3,0.2c0.4,0.2,1,0.7,1.3,1c0.4,0.3,0.9,1,1.4,1.2c0.3,0.1,1-0.1,1.3,0
        c0.5,0.1,1.2,0.7,1.7,0.9c0.4,0.2,1.2,0.6,1.5,0.8c0.6,0.4,1.8,1.3,2.3,1.8c0.6,0.6,1.6,1.9,2.3,2.4c0.5,0.4,1.8,0.6,2.4,0.9
        c0.2,0.2,0.6,0.7,0.8,0.8c0.3,0.2,1,0.4,1.3,0.6c0.3,0.2,0.7,0.8,1.1,1c0.2,0.2,0.8,0.3,1.1,0.4c0.3,0.1,0.7,0.5,0.9,0.7
        c0.4,0.5,0.9,1.7,1.1,2.3c0.1,0.2,0.1,0.4,0.2,0.7H373v1.5h-1.6v1.6h-1.7v1.5h-2.8v2.9h-1.6v1.4h-1.6v1.9h-1.3v1.3h-5.8v1.6
        L354.7,453.3z"/>
    </g>
    <g id="Pierce">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="pierce-county" ref="pierce-county" class="st10" d="M368.6,476.7c-0.4-0.1-0.8-0.2-1.1-0.2c-0.3,0-1,0.2-1.4,0.2c-0.2,0-0.5-0.2-0.6-0.3
        c-0.2-0.3-0.3-1.1-0.5-1.4c-0.2-0.3-1-0.7-1.3-0.8c-0.4-0.2-1.3-0.2-1.6-0.5c-0.2-0.3-0.1-1.1-0.3-1.4c-0.2-0.4-0.8-0.9-1.1-1.1
        c-0.2-0.2-0.5-0.5-0.6-0.7c-0.1-0.2,0.3-0.7,0.3-1c-0.1-0.2-0.4-0.5-0.7-0.6c-0.3-0.2-1.2,0.1-1.4-0.1c-0.2-0.2-0.2-1-0.2-1V460
        h-1.7v-2h-1.6v-1.5h1.9v-1.4h-2v-1.9l1.9,0v-1.6h5.8v-1.3h1.3v-1.9h1.6v-1.4h1.6v-2.9h2.8v-1.5h1.7V441h1.6v-1.5h1.1
        c0.1,0.3,0.1,0.5,0.2,0.7c0.3,0.4,1.2,1,1.5,1.4c0.2,0.2,0.5,0.8,0.7,1c0.3,0.3,1.2,0.5,1.5,0.8c0.3,0.3,0.8,1.1,1.1,1.4
        c0.5,0.5,1.5,1.4,2.1,1.8c0.4,0.2,1.2,0.6,1.7,0.6c0.2,0,0.6-0.4,0.8-0.5c0.1-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.1,0.3-0.2
        c0.2-0.1,0.7-0.3,1-0.4c0.3-0.2,0.9-0.6,1.3-0.7c0.3-0.1,0.9-0.1,1.2,0c0.3,0.1,0.8,0.6,1.1,0.8c0.3,0.2,0.7,0.8,1,1.1
        c0.3,0.2,1,0.4,1.3,0.7c0.4,0.4,0.7,1.6,0.7,2.2c0,0.5-0.4,1.3-0.4,1.8c0,0.3,0.4,1,0.4,1.3c0,0.3-0.4,1-0.4,1.3
        c0,0.3,0.4,0.9,0.5,1.2c0.2,0.3,0.8,0.7,1,1c0.1,0.3,0.1,0.9,0.1,1.2c0,0.4-0.1,1.2,0,1.6c0,0.1,0,0.1,0.1,0.2
        c0.1,0.3,0.4,0.8,0.5,1.1c0.1,0.2-0.1,0.7,0,1c0.1,0.3,0.4,0.8,0.5,1.1c0.2,0.4,0.8,1.2,1,1.6c0.2,0.3,0.4,1,0.6,1.3
        c0.2,0.2,0.8,0.4,1,0.6c0.3,0.3,0.7,0.9,0.7,1.2c0.1,0.3,0.1,1,0,1.3c-0.2,0.4-0.8,0.9-1.2,1.1c-0.4,0.3-1.4,0.6-1.9,0.7
        c-0.1,0-0.4,0-0.5,0c-0.3,0-0.9,0.1-1.2,0.1c-0.5,0.1-1.4,0.5-1.8,0.5c-0.5,0-1.3-0.4-1.8-0.5c-0.4-0.1-1.2-0.4-1.6-0.4
        c-0.4,0-1.3,0.4-1.6,0.7c-0.3,0.2-0.4,1.1-0.7,1.3c-0.5,0.4-1.9,0.5-2.3,1c-0.2,0.2-0.1,0.8-0.2,1.1c0,0.3,0.1,0.8,0,1.1
        c-0.1,0.3-0.7,0.8-1,1c-0.4,0.2-1.4,0.2-1.8,0.5c-0.3,0.3-0.4,1.2-0.7,1.5c-0.3,0.3-1,0.9-1.4,0.8c-0.5,0-1.6-1.1-1.7-1.1
        c0-0.1-1,0.1-1.3,0c-0.4-0.1-0.9-0.7-1.3-0.7c-0.2,0-0.6,0.4-0.9,0.4c-0.4,0-1-0.5-1.3-0.5c-0.4,0-1.1,0.4-1.4,0.4
        c-0.4,0-1-0.6-1.4-0.7C368.8,476.8,368.7,476.8,368.6,476.7z"/>
    </g>
    <g id="Camden">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="camden-county" ref="camden-county" class="st10" d="M445.7,523c0,0.2,0,0.6-0.2,0.7c-0.1,0.1-0.5,0.1-0.6,0c-0.2-0.1-0.4-0.7-0.4-1c0-0.1,0.1-0.4,0.2-0.4
            c0.1-0.1,0.4,0.1,0.5,0.2C445.4,522.6,445.7,522.9,445.7,523z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="camden-county" ref="camden-county" class="st10" d="M448.8,527c0.1,0.3,0.1,1-0.1,1.3c-0.1,0.2-0.6,0.5-0.8,0.5c-0.4-0.1-1-0.9-1.1-1.3c-0.1-0.3-0.1-0.8,0-1.1
            c0.1-0.2,0.4-0.5,0.5-0.6c0.2-0.1,0.5-0.1,0.7-0.1c0.1,0,0.4,0.3,0.4,0.4C448.5,526.4,448.7,526.8,448.8,527z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="camden-county" ref="camden-county" class="st10" d="M458,511.3c0.3,0.5-0.5,1.7-0.6,2.3c-0.1,0.7-0.2,2.2-0.3,2.9c-0.1,0.7-0.3,2.1-0.4,2.8
            c-0.2,0.9-0.8,2.7-1.1,3.6c-0.3,0.8-1.1,2.3-1.5,3.1c-0.2,0.5-0.7,1.5-0.8,2c-0.1,0.4-0.1,1.1-0.2,1.5c-0.1,0.5-0.8,1.4-0.9,1.9
            c0,0.3,0.3,0.8,0.3,1.1c0,0.5-0.2,1.6-0.2,2.1c0,0.4,0.1,1.1,0.3,1.4c0.1,0.3,0.7,0.8,0.8,1.1c0.1,0.5,0.1,1.6-0.2,1.8
            c-0.2,0.1-0.7,0-0.9-0.1c-0.3-0.1-0.9-0.5-1.1-0.8c-0.1-0.2,0-0.6-0.1-0.8c-0.1-0.3-0.7-0.7-0.8-1c-0.1-0.3,0-0.9,0.1-1.2
            c0.1-0.3,0.5-0.8,0.6-1.1c0.1-0.4,0-1.2,0.1-1.6c0.1-0.3,0.4-0.7,0.4-1c0-0.3-0.7-0.8-0.8-1.1c-0.1-0.4,0-1.1,0-1.5
            c0-0.4,0.3-1,0.3-1.4c0-0.2-0.1-0.7-0.1-0.9c0-0.5,0.2-1.4,0.1-1.9c-0.1-0.2-0.4-0.6-0.4-0.8c-0.1-0.3-0.1-0.8-0.1-1.1
            c0-0.4,0-1.2-0.2-1.5c-0.3-0.4-1.3-0.8-1.7-1.2c-0.3-0.3-0.8-1-0.8-1.4c0-0.2,0.6-0.4,0.7-0.6c0.2-0.3,0.3-1.1,0.6-1.4
            c0.2-0.2,0.7-0.4,1-0.4c0.2,0,0.6,0.5,0.8,0.4c0.2-0.1,0.3-0.7,0.5-0.7c0.3-0.1,0.8,0.2,1,0.4c0.2,0.1,0.5,0.4,0.6,0.7
            c0,0.3-0.6,0.6-0.6,0.8c0.1,0.3,0.7,0.5,1,0.4c0.1,0,0.3-0.2,0.4-0.3c0.1-0.3,0.1-1,0-1.3c-0.1-0.2-0.4-0.6-0.6-0.8
            c-0.2-0.2-0.9-0.2-1.1-0.4c-0.1-0.2-0.1-0.7-0.1-0.9c0-0.3,0.3-0.8,0.4-1c0.3-0.3,1.1-0.7,1.5-0.9c0.2-0.1,0.6-0.3,0.8-0.4
            c0.2-0.2,0.4-0.9,0.5-1.2c0.2-0.5,0.4-1.5,0.5-2c0.1-0.4,0.1-1.1,0.3-1.4c0.1-0.2,0.5-0.5,0.7-0.4c0.3,0.1,0.8,0.9,0.8,1
            c0,0.1,0,1.1,0,1.4c0,0.4-0.1,1.6-0.1,1.7C457.4,511.2,457.9,511.2,458,511.3z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="camden-county" ref="camden-county" class="st10" d="M442.7,506.2c0,0.2-0.4,0.3-0.5,0.4c-0.1,0.2-0.1,0.6-0.2,0.7c-0.2,0.2-0.6,0.4-0.9,0.4
            c-0.3,0-0.9-0.4-1-0.7c-0.1-0.2,0.1-0.5,0.2-0.6c0.1-0.1,0.3,0,0.4-0.1c0.2-0.1,0.6-0.4,0.8-0.5c0.1,0,0.4-0.1,0.5,0
            C442.2,506,442.7,506,442.7,506.2z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="camden-county" ref="camden-county" class="st10" d="M449.7,508c0,0.2-0.4,0.6-0.6,0.7c-0.1,0-0.4,0-0.6-0.1c-0.2-0.1-0.5-0.2-0.5-0.4c0-0.2,0.1-0.5,0.2-0.6
            c0.1-0.1,0.4-0.2,0.6-0.2c0.1,0,0.4,0.1,0.5,0.1C449.5,507.6,449.7,507.8,449.7,508z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="camden-county" ref="camden-county" class="st10" d="M452.8,500.6c0.1,0.2,0.1,0.8-0.1,0.9c-0.1,0.1-0.5-0.1-0.6,0c-0.2,0.1-0.3,0.5-0.5,0.6
            c-0.2,0.1-0.6,0.1-0.8,0c-0.1-0.1-0.4-0.3-0.4-0.4c-0.1-0.3,0.1-0.8,0.3-1c0.1-0.1,0.3-0.3,0.4-0.4c0.3-0.1,0.8,0.1,1.1,0.1
            C452.3,500.5,452.7,500.5,452.8,500.6z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="camden-county" ref="camden-county" class="st10" d="M452.8,509.2c0.1,0.3,0.2,0.9,0.1,1.3c0,0.3-0.2,0.8-0.4,1c-0.2,0.4-0.8,1-1.2,1.3
            c-0.2,0.1-0.7,0.2-0.9,0.4c-0.3,0.3-0.4,1.2-0.7,1.5c-0.2,0.2-0.8,0.3-1.1,0.5c-0.1,0.1-0.4,0.2-0.5,0.3
            c-0.2,0.2-0.3,0.7-0.5,0.9c-0.2,0.2-0.7,0.4-0.9,0.6c-0.2,0.3-0.6,1.1-0.6,1.5c0,0.4,0.4,1,0.5,1.4c0.2,0.4,0.9,1.3,0.9,1.4
            c0,0.1,0.9,0.6,1.1,0.9c0.2,0.2,0.5,0.8,0.4,1.1c0,0.1-0.3,0.4-0.4,0.4c-0.2,0-0.4-0.6-0.6-0.7c-0.2-0.1-0.8,0-1.1-0.1
            c-0.3,0-0.8-0.1-1-0.3c-0.2-0.1-0.1-0.7-0.2-0.8c-0.4-0.3-1.5-0.4-1.9-0.2c-0.2,0.1-0.4,0.7-0.6,0.8c-0.2,0-0.6-0.4-0.8-0.6
            c-0.1-0.2-0.3-0.7-0.3-0.9c0-0.2,0.3-0.5,0.3-0.7c0-0.3-0.3-0.8-0.5-0.9c-0.3-0.2-1.1-0.1-1.4,0c-0.3,0.1-1,0.4-1.2,0.7
            c-0.2,0.2-0.3,0.7-0.4,1c0,0.3,0.3,0.9,0.3,1c0,0.1,0.4,0.7,0.6,0.6c0.3-0.1,0.4-0.7,0.4-1c0-0.2-0.1-0.7,0-0.9
            c0.1-0.1,0.4-0.3,0.6-0.3c0.1,0,0.3,0.1,0.4,0.2c0.1,0.3-0.1,1-0.1,1.3c0,0.3,0,0.8,0.2,1.1c0.1,0.2,0.5,0.4,0.7,0.5
            c0.2,0.1,0.7,0,0.9,0.1c0.2,0.1,0.6,0.2,0.7,0.4c0.2,0.2,0.1,0.9,0.3,1.1c0.2,0.1,0.6,0.2,0.9,0.1c0.3-0.1,0.6-0.5,0.9-0.6
            c0.1-0.1,0.5-0.1,0.6,0c0.2,0.1,0.5,0.5,0.5,0.7c0,0.2-0.2,0.6-0.3,0.7c-0.2,0.2-0.9,0-1.1,0.2c-0.2,0.2-0.1,0.9-0.1,1.2
            c0.1,0.4,0.6,1,0.9,1.3c0.2,0.3,0.8,0.8,1.1,1c0.2,0.2,0.8,0.4,0.9,0.7c0.2,0.2,0.4,0.6,0.4,0.8c0.1,0.4-0.1,1.2,0,1.6
            c0,0.3,0.3,0.7,0.4,1c0,0.2-0.1,0.8-0.3,0.9c-0.1,0.1-0.5-0.1-0.6,0c-0.2,0.2-0.1,0.7-0.1,0.9c0,0.4,0.4,1.1,0.3,1.5
            c-0.1,0.2-0.6,0.6-0.8,0.6c-0.4,0.1-1.2-0.2-1.6-0.2c-0.3,0-0.9-0.1-1.1,0.1c-0.2,0.2,0,0.7-0.1,0.9c-0.1,0.3-0.6,0.8-0.9,0.9
            c-0.2,0-0.5-0.5-0.7-0.6c-0.4-0.2-1.3,0-1.6-0.3c-0.3-0.2-0.3-1-0.5-1.2c-0.1-0.1-0.5-0.3-0.6-0.2c-0.2,0.1,0,0.7-0.2,0.8
            c-0.4,0.3-1.4,0.1-1.7-0.1c-0.2-0.1,0-0.7-0.2-0.8c-0.2-0.2-1-0.2-1.2,0c-0.2,0.2-0.2,0.7,0,0.9c0.1,0.1,0.4,0.2,0.5,0.3
            c0.1,0.2,0.2,0.6,0.1,0.8c-0.1,0.2-0.7,0.5-0.9,0.5c-0.2,0-0.4-0.5-0.5-0.6c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.2,0-0.6-0.1-0.7
            c-0.1-0.2-0.5-0.4-0.7-0.5c-0.2,0-0.6,0.3-0.8,0.4c-0.3,0.1-1,0.4-1.3,0.3c-0.2-0.1-0.4-0.7-0.5-1c-0.1-0.2-0.2-0.7-0.4-0.8
            c-0.2-0.2-0.8-0.3-1-0.2c-0.3,0.1-0.6,0.8-0.9,0.9c-0.5,0.1-1.3-0.7-1.7-0.8c-0.3-0.1-0.9-0.2-1.3-0.2c-0.4,0-1.3,0.1-1.6,0
            c-0.3-0.1-0.7-0.5-0.8-0.7c-0.4-0.4-0.9-1.5-1.3-1.8c-0.3-0.2-1.3-0.1-1.6-0.3c-0.2-0.1-0.3-0.7-0.5-0.8c-0.2-0.1-0.7,0-0.8,0.2
            c-0.2,0.2,0.1,1.1-0.1,1.3c-0.2,0.2-0.8,0.3-1,0.2c-0.2-0.1-0.4-0.7-0.5-1c-0.1-0.3,0-0.9-0.1-1.1c-0.2-0.4-1-0.8-1.4-1
            c-0.5-0.2-1.5-0.5-2.1-0.5c-0.3,0-0.9,0.5-1.2,0.5c-0.3,0-0.8-0.1-1-0.2c-0.3-0.1-0.7-0.7-1-0.8c-0.4-0.1-1.2,0.1-1.5,0
            c-0.3-0.1-0.8-0.2-1-0.4c-0.2-0.3-0.3-1-0.4-1.3c-0.2-0.4-0.6-1.2-0.9-1.5c-0.2-0.2-0.7-0.4-1.1-0.4l0.8-9.6c0,0-0.8-0.5-1.1-0.6
            c-0.3-0.1-1-0.2-1.1-0.5c-0.1-0.3,0.5-1,0.6-1.3c0.1-0.4,0.2-1.2,0.4-1.6c0.2-0.4,1-1.1,1.2-1.6c0.1-0.3,0.1-1,0-1.3
            c-0.2-0.4-1.1-0.8-1.4-1.1c-0.3-0.2-0.8-0.7-0.9-1.1c-0.1-0.3,0-1,0.2-1.3c0.1-0.3,0.7-0.6,0.8-0.9c0.1-0.3-0.3-1-0.4-1.3
            c0-0.3,0-0.8,0.1-1.1c0.1-0.3,0.6-0.6,0.6-0.9c0.1-0.4,0-1.3-0.4-1.6c-0.3-0.3-1.2-0.1-1.6-0.2c-0.2-0.1-0.6-0.3-1-0.4
            c0.1-0.3,0.3-0.6,0.3-0.9c0-0.3-0.5-0.7-0.5-1c0-0.3,0.6-0.6,0.7-0.9c0.1-0.3,0-0.8,0-1.1c0.1-0.3,0.3-1,0.5-1.3
            c0.1-0.2,0.6-0.4,0.8-0.6c0.1-0.1,0.2-0.6,0.3-0.7c1.5-0.9,5.8,1,7.2,0c0.5-0.4,0.6-1.8,0.8-2.4c0.2-0.4,0.4-1.4,0.7-1.7
            c0.1-0.2,0.5-0.4,0.6-0.5c0.2-0.3,0.4-1.2,0.7-1.4c0.1-0.2,0.5-0.4,0.7-0.4c0.4-0.1,1.1,0.3,1.5,0.1c0.2-0.1,0.4-0.4,0.5-0.5
            c0.3-0.3,1.2-1,1.1-1.4c0-0.1-0.1-0.1-0.1-0.2c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.4-0.2,0.5-0.1c0.3,0.1,0.6,0.6,0.7,0.8
            c0.1,0.3-0.3,0.8-0.1,1.1c0.2,0.3,1.1,0.6,1.2,0.6c0.1,0,1.1-0.2,1.4-0.1c0.5,0,1.4,0.3,1.7,0.5c0.3,0.2,0.5,0.8,0.8,1
            c0.3,0.2,1,0.2,1.3,0.3c0.3,0.2,0.8,0.9,1.2,1c0.4,0.1,1.2-0.2,1.6-0.1c0.4,0.1,1.3,0.4,1.7,0.6c0.5,0.3,1.8,1.4,1.9,1.4
            c0.1,0,1.1-0.5,1.5-0.6c0.2,0,0.6,0.2,0.9,0.2c0.2,0,0.7-0.3,0.9-0.3c0.2,0,0.7,0.1,0.9,0.1c0.3,0,1-0.2,1.2-0.4
            c0.2-0.2,0.3-0.8,0.5-1c0.2-0.2,0.7-0.5,0.9-0.4c0.3,0.1,0.7,0.6,0.8,0.8c0.1,0.2,0.1,0.8,0.1,1c0,0.3,0.1,0.8,0,1
            c-0.2,0.3-1.1,0.4-1.3,0.7c-0.1,0.2,0,0.7,0.1,0.9c0.1,0.1,0.4,0.2,0.5,0.3c0.2,0.2,0.5,0.7,0.7,0.8c0.2,0.1,0.7,0.3,1,0.3
            c0.2,0,0.4-0.5,0.6-0.5c0.3,0,0.7,0.5,0.8,0.7c0.1,0.1,0.2,0.4,0.2,0.7c-0.1,0-0.2,0-0.2,0c-0.3,0.1-0.4,0.8-0.4,1.1
            c0,0.2,0.4,0.5,0.5,0.7c0.1,0.2,0.4,0.5,0.6,0.6c0.2,0.1,0.8,0.1,1.1,0.2c0.2,0.1,0.7,0.4,0.8,0.6c0.2,0.3,0.5,0.9,0.7,1.2
            c0.3,0.3,1.1,0.6,1.3,0.9c0.2,0.3,0.2,1,0.2,1.4c0,0.2-0.3,0.7-0.2,1c0.1,0.3,0.5,0.8,0.8,0.9c0.3,0.1,0.9-0.1,1.2-0.3
            c0.1-0.1,0.3-0.5,0.4-0.5c0.1-0.1,0.4-0.2,0.5-0.1c0.2,0.2-0.1,0.9-0.2,1.2c-0.1,0.2-0.2,0.7-0.4,0.8c-0.2,0.1-0.7-0.2-0.9-0.1
            c-0.3,0.1-0.6,0.8-0.8,1c-0.1,0.1-0.4,0.6-0.6,0.5c-0.3-0.1-0.2-1.1-0.5-1.1c-0.3,0-0.5,0.7-0.5,0.9c0,0.2,0.3,0.7,0.2,0.9
            c-0.1,0.2-0.8,0.4-1,0.3c-0.2,0-0.7-0.4-0.8-0.6c-0.2-0.2-0.3-0.7-0.5-0.9c-0.2-0.3-0.7-0.8-1-0.9c-0.4-0.1-1.1,0.2-1.4,0.3
            c-0.2,0-0.5,0.2-0.7,0.2c-0.3,0-0.7-0.4-1-0.3c-0.2,0-0.5,0.5-0.6,0.6c-0.3,0.1-0.9,0-1.1,0.1c-0.3,0.1-0.6,0.5-0.9,0.6
            c-0.3,0.1-0.9,0.1-1.2,0c-0.2-0.1-0.4-0.3-0.5-0.4c-0.2-0.2-0.5-0.6-0.7-0.7c-0.2-0.1-0.5,0-0.7,0c-0.2,0-0.6-0.2-0.8-0.3
            c-0.2,0-0.7,0-1,0.1c-0.2,0.1-0.7,0.4-0.7,0.6c0,0.2,0.4,0.2,0.6,0.2c0.2,0,0.7-0.1,1,0c0.2,0.1,0.6,0.3,0.8,0.4
            c0.2,0.2,0.4,0.7,0.6,0.9c0.2,0.2,0.5,0.5,0.7,0.5c0.3,0.1,1-0.1,1.4,0c0.3,0.1,0.6,0.6,0.9,0.8c0.2,0.1,0.8,0.3,1,0.3
            c0.3,0,0.9-0.3,1.2-0.3c0.2,0,0.6,0.2,0.8,0.2c0.3-0.1,0.5-0.7,0.7-0.9c0.2-0.2,0.6-0.6,0.8-0.6c0.2-0.1,0.8,0,1,0.1
            c0.2,0.1,0.5,0.3,0.6,0.4c0.1,0.2-0.1,0.9,0.1,1.1c0.1,0.1,0.6,0.1,0.8,0.1c0.3,0.1,1,0.6,1.1,0.6s0.9-0.1,1.2-0.1
            c0.2,0,0.7,0.1,0.8,0c0.2-0.1,0.5-0.6,0.7-0.9c0.1-0.2,0.2-0.8,0.5-0.9c0.2-0.1,0.6,0.1,0.8,0.3
            C452.1,508.1,452.7,508.8,452.8,509.2z"/>
    </g>
    <g id="Glynn">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="glynn-county" ref="glynn-county" class="st10" d="M451,498.5c0.1,0.2,0.1,0.6,0,0.7c-0.1,0.1-0.5,0.3-0.7,0.2c-0.4-0.1-1.1-0.6-1.3-1c-0.1-0.3,0-0.9,0-1.2
            c0-0.2,0.1-0.7,0.3-0.7c0.2-0.1,0.6,0.3,0.8,0.4c0.1,0,0.2,0.2,0.3,0.2c0.1,0.2,0,0.6,0.1,0.8C450.5,498.2,450.9,498.3,451,498.5
            z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="glynn-county" ref="glynn-county" class="st10" d="M458.1,493c0,0.6,0.2,1.8,0.1,2.4c-0.1,0.7-0.8,2-1.1,2.7c-0.1,0.3-0.5,1-0.6,1.3c-0.1,0.4,0.1,1.2,0,1.6
            c-0.1,0.3-0.3,0.9-0.5,1.1c-0.1,0.1-0.4,0.4-0.6,0.4c-0.3,0-0.8-0.3-0.9-0.5c-0.1-0.2-0.1-0.8,0-1.1c0.1-0.2,0.6-0.5,0.7-0.7
            c0.1-0.2,0-0.6,0-0.8c0.1-0.2,0.4-0.4,0.5-0.5c0.1-0.3,0.2-0.9,0.2-1.1c0-0.4-0.2-1.1-0.3-1.4c-0.1-0.1-0.3-0.3-0.3-0.4
            c-0.2-0.4-0.3-1.2-0.5-1.6c-0.1-0.2-0.3-0.6-0.4-0.7c-0.1-0.2-0.3-0.5-0.3-0.7c0-0.3,0.3-0.8,0.5-0.9c0.2-0.1,0.7-0.1,0.9-0.3
            c0.3-0.3,0.3-1.2,0.5-1.5c0.2-0.3,0.5-0.9,0.8-0.9c0.4,0,0.9,1,1,1.4C458.1,491.3,458.1,492.5,458.1,493z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="glynn-county" ref="glynn-county" class="st10" d="M454.9,498c0.1,0.2,0.6,0.6,0.6,0.8c0,0.3-0.7,0.7-1,0.8c-0.3,0.1-0.9-0.1-1.1-0.3c-0.3-0.1-0.8-0.6-1-0.8
            c-0.2-0.2-0.7-0.6-0.8-0.9c-0.1-0.2-0.1-0.8-0.2-1.1c-0.1-0.3-0.4-0.9-0.6-1.1c-0.2-0.1-0.6-0.1-0.7-0.2c-0.3-0.2-0.9-0.9-1-1
            c0-0.1-0.7,0.1-0.9,0c-0.1-0.1,0-0.5-0.1-0.6c-0.1-0.2-0.6-0.3-0.6-0.5c0-0.2,0.3-0.6,0.5-0.7c0.1,0,0.3,0,0.4,0
            c0.1-0.1,0.1-0.6,0.1-0.8c0-0.3-0.3-0.8-0.3-1.1c0-0.2,0.2-0.6,0.4-0.7c0.2-0.1,0.6,0.3,0.8,0.5c0.1,0.2,0.3,0.6,0.4,0.7
            c0.2,0.2,0.6,0.6,0.8,0.8c0.1,0.2,0.2,0.8,0.4,0.9c0.2,0.1,0.7-0.1,0.9,0c0.3,0.1,0.8,0.3,0.9,0.5c0.1,0.2,0.1,0.7,0.2,0.9
            c0.1,0.2,0.5,0.5,0.7,0.5c0.2,0,0.5-0.4,0.7-0.4c0.3,0.1,0.6,1,0.7,1.3c0.1,0.3-0.1,0.9-0.1,1.1C454.8,497,454.8,497.7,454.9,498
            z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="glynn-county" ref="glynn-county" class="st10" d="M447.7,487.6c0.1,0.2,0.1,0.8-0.1,1c-0.2,0.1-0.7-0.1-0.9-0.3c-0.2-0.1-0.4-0.6-0.6-0.7
            c-0.1-0.1-0.5-0.4-0.6-0.5c-0.1-0.1-0.2-0.5-0.1-0.7c0.1-0.2,0.4-0.5,0.5-0.5c0.1,0,0.3,0.2,0.5,0.3c0.2,0.1,0.8,0.3,0.9,0.5
            c0.1,0.1,0,0.4,0.1,0.6C447.5,487.4,447.7,487.5,447.7,487.6z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="glynn-county" ref="glynn-county" class="st10" d="M454.7,480.4c0.2,0.3,0.7,0.8,0.6,1.2c0,0.2-0.4,0.5-0.5,0.7c-0.1,0.2,0.1,0.6,0,0.8
            c-0.1,0.1-0.4,0.3-0.5,0.4c-0.1,0.1,0.1,0.5,0.1,0.6c0,0.2-0.2,0.7-0.4,0.7c-0.2,0-0.3-0.4-0.4-0.6c-0.1-0.2,0.1-0.7,0-0.9
            c-0.1-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.3-0.4-0.3-0.6c-0.1-0.2,0-0.8,0.1-1c0.1-0.1,0.4-0.3,0.5-0.4c0.1-0.1,0.1-0.4,0.2-0.6
            c0.1-0.2,0.3-0.5,0.5-0.5C454.3,479.9,454.6,480.3,454.7,480.4z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="glynn-county" ref="glynn-county" class="st10" d="M425,464.5c0.3-0.2,0.5-0.3,0.7-0.5c0.4-0.5,0.8-1.8,1.1-2.3c0.2-0.3,0.7-0.8,0.8-1.1
            c0.1-0.3,0.4-0.8,0.4-1.1c0-0.3-0.4-0.7-0.4-0.9c0-0.4,0.4-1.2,0.5-1.6c0.1-0.2,0-0.7,0.2-0.9c0.1-0.2,0.5-0.4,0.7-0.4
            c0.2-0.1,0.8,0.1,1,0c0.2,0,0.7-0.1,1-0.1c0.2,0,0.6,0.1,0.8-0.1c0.3-0.3,0.1-1.3,0.3-1.6c0.2-0.3,0.9-0.8,1.1-1.1
            c0.3-0.4,0.6-1.3,0.9-1.8c0.2-0.3,0.7-1,1-1.3c0.1-0.1,0.3-0.2,0.5-0.4c0,0.1,0,0.1,0,0.2c0.2,0.3,1,0.3,1.1,0.5
            c0.2,0.3-0.1,1,0,1.3c0.1,0.2,0.4,0.6,0.6,0.8c0.2,0.2,0.8,0.4,0.9,0.6c0.2,0.2,0.3,0.9,0.6,1.1c0.1,0.1,0.5,0.3,0.6,0.4
            c0.2,0.2,0.4,0.9,0.6,1.1c0.2,0.2,0.9,0.4,1.2,0.6c0.3,0.2,0.8,0.8,1,1.1c0.2,0.2,0.4,0.8,0.6,1.1c0.2,0.2,0.8,0.5,1.1,0.6
            c0.3,0.1,0.8,0.1,1.1,0.2c0.3,0.2,0.5,0.9,0.7,1.2c0.2,0.2,0.5,0.7,0.8,0.8c0.4,0.2,1.2,0,1.5,0.1c0.2,0.1,0.6,0.5,0.8,0.7
            c0.1,0.2,0.3,0.6,0.5,0.8c0.2,0.1,0.6,0.2,0.8,0.3c0.3,0.1,0.8,0.2,1.1,0.4c0.2,0.1,0.5,0.7,0.7,0.8c0.4,0.1,1.2-0.1,1.5-0.1
            c0.2,0,0.8,0.2,0.9,0.4c0.2,0.3-0.2,1,0,1.3c0.1,0.2,0.7,0.3,0.9,0.4c0.3,0.2,0.7,0.6,0.9,0.8c0.3,0.2,1,0.2,1.4,0.4
            c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2,0,0.7,0.1,1c0.1,0.1,0.3,0.3,0.5,0.3c0.1,0.1,0.4,0.1,0.5,0.2c0.2,0.2,0.4,0.7,0.4,0.9
            c0.1,0.2,0.1,0.7,0.1,1c0,0.2-0.4,0.5-0.3,0.7c0,0.2,0.4,0.7,0.7,0.7c0.2,0,0.4-0.3,0.5-0.4c0.1-0.2,0.4-0.7,0.4-0.9
            c0-0.2-0.2-0.5-0.3-0.7c0-0.2,0-0.6-0.1-0.8c-0.1-0.2-0.5-0.6-0.6-0.8c-0.1-0.2-0.3-0.7-0.3-1c0-0.3,0.3-0.8,0.4-1
            c0.1-0.2,0.3-0.6,0.5-0.7c0.4-0.2,1.4-0.3,1.8-0.2c0.2,0,0.6,0.3,0.8,0.4c0.2,0,0.5-0.2,0.6-0.2c0.3,0,1,0.1,1.2,0.3
            c0.2,0.1,0.4,0.6,0.6,0.7c0.3,0.1,0.9-0.1,1.2,0c0.3,0.1,0.8,0.5,1.1,0.8c0.2,0.2,0.5,0.7,0.8,0.9c0.4,0.2,1.2,0.4,1.6,0.2
            c0.2-0.1,0.3-0.5,0.5-0.6c0.2-0.1,0.7,0,0.8,0.1c0.2,0.2,0.1,0.9,0,1.1c-0.2,0.2-0.9,0.2-1.1,0.4c-0.1,0.1-0.2,0.3-0.2,0.5
            c0,0.3,0.3,0.8,0.4,1c0.1,0.2,0.7,0.5,1,0.4c0.2-0.1,0.2-0.8,0.4-0.9c0.1,0,0.3,0,0.4,0.1c0.2,0.2,0.5,0.7,0.5,1
            c-0.1,0.3-0.6,0.5-0.8,0.7c-0.3,0.2-1,0.3-1.2,0.6c-0.2,0.4,0.2,1.6,0.2,1.7c0,0.1-0.7,0.2-0.8,0.3c-0.2,0.3,0,1.1-0.2,1.5
            c-0.1,0.3-0.5,0.8-0.8,0.9c-0.1,0.1-0.4,0.1-0.5,0c-0.4-0.2-1-0.8-1.2-1.2c-0.2-0.4-0.3-1.3-0.5-1.6c-0.2-0.2-0.8-0.4-0.9-0.7
            c-0.1-0.3,0.1-1,0.2-1.3c0.1-0.2,0.2-0.6,0.4-0.7c0.3-0.2,1.2-0.1,1.4-0.4c0.1-0.2-0.2-0.6-0.4-0.6c-0.2-0.1-0.7,0-1,0.1
            c-0.3,0.1-0.9,0.3-1.1,0.5c-0.2,0.2-0.4,0.8-0.4,1.1c0,0.3,0,1.1,0.1,1.4c0.1,0.2,0.5,0.6,0.6,0.8c0.2,0.3,0.5,1.1,0.6,1.5
            c0.2,0.3,0.6,0.7,0.7,1c0.1,0.2,0.2,0.8,0.1,1.1c-0.1,0.3-0.5,0.8-0.7,1.1c-0.4,0.5-1.8,1.7-1.8,1.7c0,0.1-0.9,1.1-1.1,1.5
            c-0.1,0.2-0.2,0.6-0.3,0.8c-0.1,0.2-0.4,0.5-0.6,0.5c-0.2-0.1-0.1-0.5-0.2-0.7c-0.1-0.2-0.5-0.4-0.7-0.4c-0.1,0-0.4-0.1-0.4,0
            c-0.2,0.1-0.3,0.6-0.2,0.8c0.1,0.2,0.5,0.4,0.6,0.5c0.1,0.2,0.4,0.6,0.4,0.8c0,0.3-0.2,0.9-0.4,1.2c-0.2,0.3-0.8,0.8-1.1,0.9
            c-0.3,0.2-0.8,0.4-1.2,0.5c-0.3,0-0.8,0.1-1.1,0c-0.4-0.2-1-0.8-1.2-1.1c-0.2-0.3-0.7-1.1-0.7-1.5c0-0.3,0.4-0.9,0.5-1.2
            c0.1-0.2,0.4-0.7,0.4-0.9c0.1-0.3,0.1-1,0-1.4c-0.1-0.3-0.6-0.7-0.7-1.1c-0.1-0.3,0.2-0.8,0.2-1.1c0-0.4-0.2-1.4-0.5-1.7
            c-0.2-0.2-0.9-0.6-1.2-0.5c-0.3,0.1-0.4,0.9-0.6,1.1c-0.3,0.4-1.1,1-1.4,1.4c-0.1,0.2-0.3,0.5-0.4,0.7c-0.1,0.2-0.4,0.8-0.4,0.8
            c0,0.1-0.1,1-0.1,1.3c0,0.3,0.1,0.9,0.2,1.1c0.1,0.2,0.7,0.4,0.8,0.7c0.1,0.2-0.1,0.8,0,1c0.1,0.2,0.3,0.6,0.4,0.7
            c0.2,0.2,0.7,0.2,0.8,0.4c0.2,0.2,0.3,0.8,0.3,1c0,0.4-0.3,1.3-0.6,1.6c-0.2,0.2-0.7,0.5-0.9,0.7c-0.2,0.1-0.5,0.5-0.7,0.6
            c-0.4,0.1-1.2-0.4-1.6-0.6c-0.3-0.2-1-0.5-1.3-0.8c-0.3-0.2-0.7-0.8-0.8-1.2c-0.1-0.3,0-0.9-0.1-1.2c-0.1-0.2-0.2-0.7-0.4-0.8
            c-0.2-0.1-0.5,0.1-0.7,0c-0.4-0.1-1.1-0.5-1.3-0.8c-0.1-0.2-0.1-0.6-0.1-0.8c0-0.2-0.2-0.7-0.2-0.9c0-0.4,0.2-1.1,0.2-1.5
            c0-0.3-0.3-0.8-0.5-1c-0.1-0.1-0.5-0.2-0.6-0.3c-0.3-0.2-0.7-0.8-1-1c-0.2-0.2-0.5-0.4-0.6-0.6c-0.1-0.2-0.4-0.5-0.5-0.7
            c-0.1-0.3,0.4-0.9,0.2-1.2c-0.1-0.3-0.8-0.5-1.1-0.6c-0.2,0-0.7,0.3-0.9,0.4c-0.1,0-0.4-0.1-0.5-0.1c-0.3,0-0.8,0.3-1.1,0.4
            c-0.2,0.1-0.8,0-1,0.1c-0.1,0.1-0.2,0.4-0.3,0.6c-0.1,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.7-0.2-0.9-0.1c-0.2,0.1-0.7,0.5-0.6,0.8
            c0,0.2,0.6,0.5,0.8,0.5c0.3,0.1,0.8-0.2,1-0.1c0.2,0,0.6,0.3,0.9,0.3c0.3-0.1,0.8-0.6,1.1-0.8c0.2-0.1,0.6-0.5,0.8-0.6
            c0.2-0.1,0.8-0.3,1-0.2c0.2,0.1,0.5,0.4,0.5,0.5c0.1,0.3-0.2,0.9-0.1,1.2c0.1,0.3,0.4,0.7,0.6,0.9c0.2,0.2,0.8,0.5,1,0.7
            c0.2,0.1,0.6,0.3,0.8,0.4c0.2,0.2,0.3,0.8,0.4,1.1c0,0.3-0.4,0.8-0.3,1.1c0,0.2,0.4,0.6,0.5,0.8c0.1,0.3,0.1,1.1-0.1,1.4
            c-0.1,0.2-0.5,0.4-0.7,0.4c-0.2,0-0.3-0.5-0.4-0.6c-0.2-0.1-0.6-0.2-0.9-0.2c-0.1,0-0.5,0.1-0.5,0.2c-0.1,0.3,0.4,0.7,0.6,1
            c0.2,0.2,0.4,0.7,0.7,0.8c0.2,0.1,0.8,0.2,1,0.3c0.4,0.2,1,0.7,1.3,0.9c0.2,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,1.1,0.3,1.5
            c0,0.2-0.3,0.5-0.4,0.7c-0.2,0.2-0.7,0.4-0.8,0.7c-0.1,0.2,0,0.7,0.1,0.9c0,0.2,0.1,0.5,0.2,0.6c0.1,0.2,0.6,0.3,0.8,0.4
            c0.2,0.2,0.7,0.6,0.9,0.8c0.2,0.2,0.9,0.3,0.9,0.6c0,0.2-0.5,0.4-0.8,0.4c-0.3,0-0.8-0.1-1.1-0.2c-0.3,0-1,0.1-1.3,0
            c-0.3-0.2-0.4-1-0.7-1.2c-0.1,0-0.2-0.1-0.3-0.1c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.5-0.7-0.8-0.7c-0.2,0-0.4,0.4-0.6,0.5
            c-0.2,0.1-0.8-0.1-1-0.3c-0.2-0.1-0.5-0.7-0.7-0.8c-0.1-0.1-0.4-0.2-0.5-0.3c-0.1-0.2-0.2-0.7-0.1-0.9c0.2-0.3,1.1-0.4,1.3-0.7
            c0.1-0.2,0-0.8,0-1c0-0.3,0-0.8-0.1-1c-0.1-0.3-0.5-0.8-0.8-0.8c-0.3,0-0.7,0.2-0.9,0.4c-0.2,0.2-0.3,0.8-0.5,1
            c-0.2,0.2-0.9,0.4-1.2,0.4c-0.2,0-0.7-0.1-0.9-0.1c-0.2,0-0.7,0.3-0.9,0.3c-0.2,0-0.6-0.2-0.9-0.2c-0.4,0-1.4,0.6-1.5,0.6
            c-0.1,0-1.4-1.2-1.9-1.4c-0.4-0.2-1.2-0.5-1.7-0.6c-0.4-0.1-1.2,0.2-1.6,0.1c-0.4-0.1-0.8-0.8-1.2-1c-0.3-0.1-1-0.1-1.3-0.3
            c-0.3-0.2-0.6-0.8-0.8-1c-0.4-0.3-1.3-0.5-1.7-0.5c-0.4,0-1.4,0.1-1.4,0.1c-0.1,0-1-0.3-1.2-0.6c-0.1-0.2,0.2-0.8,0.1-1.1
            c-0.1-0.3-0.4-0.7-0.7-0.8c-0.1,0-0.4,0.1-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2-0.1-0.4-0.3-0.4-0.4c-0.1-0.3,0.3-0.9,0.5-1.2
            c0.2-0.5,0.8-1.6,0.9-2.2c0.1-0.5-0.1-1.5,0-2c0.1-0.4,0.6-1.2,0.7-1.7c0.2-0.6,0.2-2,0.3-2.6c0.2-0.9,0.4-2.8,0.8-3.6
            c0.3-0.5,1.3-1.1,1.5-1.6c0.2-0.4,0.3-1.3,0.3-1.7c0-0.5-0.3-1.6-0.1-2C424.6,464.7,424.8,464.6,425,464.5z"/>
    </g>
    <g id="McIntosh">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="mcintosh-county" ref="mcintosh-county" class="st10" d="M468.9,466.8c0.1,0.2,0.5,0.4,0.4,0.6c0,0.2-0.4,0.5-0.6,0.5c-0.2,0-0.5-0.2-0.7-0.3
            c-0.2-0.1-0.5-0.4-0.5-0.6c-0.1-0.2-0.1-0.8,0.1-1c0.2-0.2,0.8-0.1,1.1,0C468.8,466.2,468.8,466.6,468.9,466.8z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="mcintosh-county" ref="mcintosh-county" class="st10" d="M466.4,464.5c0.1,0.2,0,0.5-0.1,0.6c0,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.4,0.3-0.6,0.2
            c-0.2-0.1-0.3-0.5-0.3-0.7c0-0.2,0.2-0.5,0.4-0.5c0.1-0.1,0.3-0.1,0.5-0.1C466,464.2,466.3,464.4,466.4,464.5z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="mcintosh-county" ref="mcintosh-county" class="st10" d="M462.9,464.3c0,0.1-0.2,0.4-0.4,0.5c-0.1,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.7,0-0.8-0.2c-0.1-0.1-0.1-0.4,0-0.6
            c0.1-0.2,0.3-0.5,0.5-0.5c0.2-0.1,0.5,0.1,0.7,0.1C462.4,463.8,462.9,464,462.9,464.3z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="mcintosh-county" ref="mcintosh-county" class="st10" d="M470.5,436.5c0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.5,0.5-0.7,0.6c-0.1,0.1-0.3,0.5-0.4,0.6
            c-0.2,0.1-0.5,0.4-0.7,0.4c-0.1,0-0.2-0.4-0.3-0.4c-0.1-0.1-0.5-0.1-0.6-0.1c-0.1-0.1-0.3-0.2-0.4-0.4c0-0.1,0.1-0.4,0.2-0.5
            c0.2-0.2,0.8,0,1.1-0.2c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.1,0.6-0.4,0.8-0.4C469.9,436.3,470.4,436.3,470.5,436.5z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="mcintosh-county" ref="mcintosh-county" class="st10" d="M435.5,449.3c0-0.2,0-0.4,0.1-0.5c0.1-0.2,0.4-0.4,0.5-0.5c0.1-0.2,0.3-0.5,0.3-0.7c0-0.3,0-1-0.1-1.3
            c-0.1-0.3-0.4-0.8-0.6-0.9c-0.2-0.1-0.8,0.1-1.1-0.1c-0.2-0.1-0.3-0.6-0.5-0.8c-0.2-0.2-0.7-0.2-0.8-0.4c-0.1-0.2,0.1-0.6,0-0.7
            c-0.1-0.3-0.6-0.7-0.8-0.9c-0.1-0.1-0.5-0.3-0.5-0.4c0-0.3,0.6-0.8,0.7-1.1c0.1-0.3,0.2-0.9,0-1.1c-0.2-0.2-0.8,0.1-1.1,0
            c-0.2-0.1-0.5-0.4-0.5-0.6c-0.1-0.2,0.3-0.7,0.2-1c0,0,0,0,0,0l10.1-4.8l7.7-15.2c0.1,0,0.2,0.1,0.2,0.1c0.3,0.1,0.8,0.3,1.1,0.4
            c0.3,0.2,1,0.6,1.2,0.9c0.2,0.2,0.4,0.8,0.3,1.1c-0.1,0.3-0.7,0.5-0.7,0.8c0,0.2,0.7,0.3,0.8,0.5c0.2,0.2,0.3,0.6,0.4,0.8
            c0.2,0.3,0.3,1.1,0.6,1.3c0.2,0.2,0.8,0.2,1.1,0.4c0.3,0.2,0.4,1,0.7,1.1c0.2,0.1,0.8-0.1,1.1-0.2c0.2-0.1,0.3-0.7,0.5-0.8
            c0.3-0.2,0.9-0.2,1.3-0.2c0.4,0.1,1.2,0.7,1.6,0.7c0.4,0,1.1-0.4,1.4-0.5c0.4-0.1,1.3-0.4,1.7-0.5c0.3,0,0.8-0.2,1.1-0.1
            c0.3,0.1,0.7,0.8,1,0.9c0.3,0.1,0.9-0.2,1.1-0.3c0.3-0.2,0.4-0.9,0.7-1.1c0.2-0.1,0.6-0.2,0.8-0.1c0.4,0.1,0.7,1,1.1,1.2
            c0.3,0.2,1.1,0.1,1.5,0.1c0.3,0,0.7-0.2,1-0.3c0.3,0,0.9,0,1.2,0.1c0.3,0.1,0.7,0.8,1,0.9c0.2,0.1,0.7,0.1,0.9,0.2
            c0.1,0.1,0.4,0.2,0.5,0.3c-0.1,0.2-0.3,0.5-0.2,0.7c0.1,0.3,0.6,0.5,0.8,0.6c0.2,0.1,0.6-0.1,0.7,0c0.2,0.1,0.4,0.7,0.5,0.9
            c0.1,0.2,0.2,0.7,0.4,0.9c0.1,0.2,0.6,0.4,0.7,0.6c0.3,0.2,0.9,0.4,1.1,0.6c0.2,0.2,0.6,0.9,0.7,1.2c0.1,0.3-0.1,1-0.2,1.3
            c-0.1,0.2-0.4,0.7-0.5,0.9c-0.1,0.3-0.4,0.9-0.6,1.2c-0.2,0.3-0.6,1-1,1.1c-0.3,0-0.7-0.4-1-0.5c-0.2-0.1-0.6-0.2-0.8-0.2
            c-0.2,0-0.4,0.3-0.4,0.5c-0.1,0.2,0.1,0.6,0,0.7c-0.1,0.1-0.4,0.1-0.6,0c-0.2-0.1-0.5-0.4-0.7-0.6c-0.1-0.2-0.1-0.7-0.2-0.9
            c-0.1-0.2-0.4-0.5-0.5-0.6c-0.1-0.1-0.4-0.1-0.5-0.1c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.2-0.1,0.7,0,0.9c0.1,0.1,0.5,0.2,0.6,0.4
            c0.1,0.2,0.1,0.7,0,0.9c-0.1,0.1-0.3,0.3-0.4,0.3c-0.3,0-0.5-0.7-0.7-0.9c-0.1-0.2-0.2-0.6-0.4-0.7c-0.3-0.2-0.9-0.2-1.3-0.1
            c-0.2,0-0.5,0.1-0.7,0.3c-0.1,0.1-0.1,0.4-0.2,0.5c-0.2,0.2-0.7,0.6-1,0.5c-0.2,0-0.3-0.4-0.4-0.5c-0.1-0.2-0.2-0.6-0.2-0.8
            c0-0.2,0.3-0.6,0.3-0.7c0-0.2-0.3-0.5-0.4-0.5c-0.1,0-0.4,0.1-0.5,0.2c-0.2,0.2-0.3,0.6-0.4,0.9c0,0.2-0.1,0.5-0.1,0.7
            c0,0.2,0.3,0.5,0.3,0.7c0,0.1,0.1,0.5,0,0.6c-0.2,0.1-0.5-0.2-0.7-0.3c-0.2,0-0.7,0-0.9,0c-0.2,0-0.5,0.3-0.7,0.3
            c-0.2,0.1-0.6,0-0.7,0.2c-0.1,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.5,0.2-0.6,0.2c-0.1-0.1-0.2-0.4-0.3-0.5c-0.1-0.1-0.5-0.1-0.6,0
            c-0.1,0.1-0.3,0.4-0.3,0.6c0,0.2,0.2,0.6,0.4,0.7c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.5,0.5,0.6c0.2,0.1,0.5,0.3,0.7,0.2
            c0.2-0.1,0.5-0.5,0.6-0.7c0.1-0.2,0.1-0.7,0.2-0.9c0.1-0.1,0.5-0.3,0.7-0.4c0.2,0,0.6,0,0.8,0.1c0.1,0.1,0.1,0.6,0.3,0.7
            c0.2,0.1,0.7-0.2,0.9-0.1c0.2,0.1,0.5,0.4,0.7,0.5c0.2,0.2,0.6,0.4,0.8,0.6c0.2,0.2,0.2,0.8,0.4,0.9c0.2,0.1,0.6,0.2,0.8,0.2
            c0.2,0,0.4-0.3,0.6-0.4c0.2-0.1,0.6-0.2,0.7-0.4c0.2-0.2,0.2-0.7,0.3-0.8c0.2-0.1,0.6-0.2,0.8-0.2c0.2,0,0.6,0.1,0.7,0.3
            c0.2,0.2,0.2,0.9,0.1,1.1c-0.2,0.3-1,0.5-1.3,0.8c-0.2,0.2-0.5,0.6-0.7,0.8c-0.2,0.2-0.8,0.3-1,0.5c-0.3,0.2-0.9,0.6-1.2,0.8
            c-0.2,0.2-0.5,0.8-0.5,1.1c0,0.2,0.3,0.6,0.5,0.7c0.3,0.1,0.8-0.2,1.1-0.4c0.2-0.1,0.4-0.6,0.5-0.7c0.3-0.2,1-0.1,1.4-0.3
            c0.4-0.2,1.1-0.6,1.4-0.9c0.3-0.3,0.6-1.1,0.8-1.4c0.1-0.3,0.1-1.1,0.4-1.4c0.2-0.2,0.8-0.5,1.1-0.4c0.2,0,0.6,0.4,0.7,0.6
            c0.1,0.2,0.1,0.8,0.3,1c0.1,0.1,0.5,0.2,0.7,0.2c0.2,0,0.5-0.2,0.5-0.4c0.1-0.3-0.4-0.8-0.3-1.1c0-0.2,0.5-0.5,0.6-0.7
            c0.2-0.2,0.5-0.7,0.8-0.8c0.2-0.1,0.7,0,0.9,0.1c0.4,0.2,0.9,0.8,1.2,1.1c0.2,0.2,0.7,0.6,0.7,0.8c0,0.2-0.2,0.5-0.3,0.7
            c-0.2,0.2-0.9,0.5-1.1,0.7c-0.2,0.3-0.5,1-0.6,1.3c-0.2,0.5-0.2,1.5-0.4,2c-0.2,0.4-0.8,0.9-1,1.2c-0.3,0.4-0.7,1.4-1.1,1.7
            c-0.1,0.2-0.5,0.4-0.7,0.5c-0.2,0.1-0.5,0.2-0.7,0.3c-0.1,0.1-0.2,0.4-0.2,0.5c0,0.2,0.2,0.4,0.2,0.6c0,0.3-0.2,0.8-0.4,1
            c-0.2,0.3-0.7,0.8-0.9,1c-0.3,0.4-0.9,1.4-1.1,1.9c-0.3,0.6-0.8,1.7-1.1,2.3c-0.2,0.4-0.4,1.3-0.8,1.4c-0.3,0.1-0.8-0.4-1-0.6
            c-0.3-0.3-0.5-1.1-0.6-1.4c-0.2-0.3-0.5-1-0.7-1.4c-0.2-0.4-0.9-1.1-1-1.6c-0.1-0.3,0-0.9,0-1.2c0-0.3,0.1-0.9-0.1-1.1
            c-0.1-0.1-0.6-0.2-0.7-0.1c-0.2,0.1-0.3,0.8-0.5,0.9c-0.1,0.1-0.5,0.2-0.6,0.2c-0.3-0.1-0.6-0.7-0.7-1c-0.1-0.2-0.3-0.7-0.4-0.9
            c-0.1-0.2-0.3-0.5-0.5-0.6c-0.2-0.1-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.2-0.6,0.4c-0.1,0.2,0.1,0.7,0.2,0.9c0.1,0.2,0.5,0.4,0.6,0.6
            c0.2,0.2,0.4,0.7,0.6,0.9c0.2,0.1,0.7,0.2,0.8,0.4c0.2,0.2,0.4,0.7,0.5,1c0.1,0.3,0.1,1.1,0.3,1.4c0.2,0.4,1.3,0.8,1.5,1.3
            c0.1,0.3,0.1,1,0,1.3c0,0.2-0.3,0.4-0.3,0.6c0,0.2,0.4,0.5,0.5,0.6c0.2,0.1,0.6,0.2,0.8,0.4c0.2,0.2,0.2,0.8,0.4,1.1
            c0.2,0.2,0.7,0.4,0.8,0.6c0.2,0.4,0.3,1.3,0.3,1.8c0.1,0.4,0.3,1.2,0.2,1.5c-0.1,0.3-0.5,0.9-0.9,1.1c-0.3,0.1-1,0-1.3,0
            c-0.3,0-0.8-0.2-1-0.4c-0.2-0.1-0.4-0.6-0.5-0.8c-0.2-0.2-0.7-0.6-1-0.7c-0.1,0-0.5,0-0.6,0c-0.2,0.1-0.3,0.6-0.5,0.7
            c-0.3,0.1-0.9-0.2-1.2-0.3c-0.3-0.1-0.9-0.3-1.2-0.4c-0.3-0.1-0.9-0.3-1.2-0.4c-0.4,0-1.2,0.3-1.6,0.2c-0.3,0-0.7-0.4-0.9-0.5
            c-0.3-0.1-0.9-0.2-1.2-0.2c-0.3,0-0.8,0.3-1.1,0.4c-0.3,0-1.1,0-1.3-0.2c-0.1-0.1,0-0.5-0.1-0.7c-0.1-0.2-0.7-0.2-0.9-0.4
            c-0.1-0.1,0-0.5-0.1-0.6c-0.1-0.1-0.5-0.3-0.6-0.3c-0.2,0-0.7-0.1-0.8,0.1c-0.2,0.2,0,0.8,0,1c0,0.3,0,0.8,0.1,1
            c0,0.1,0.1,0.4,0.2,0.5c0,0.1,0.2,0.1,0.3,0.1c0.1,0,0.7,0,0.9,0c0.3,0,0.9,0.2,1.2,0.3c0.2,0.1,0.6,0.4,0.8,0.5
            c0.2,0,0.7-0.3,0.9-0.3c0.2,0,0.8,0,1,0c0.2,0.1,0.4,0.3,0.5,0.4c0.3,0.1,0.9-0.3,1.2-0.3c0.2,0.1,0.6,0.3,0.7,0.6
            c0.1,0.2-0.1,0.7-0.3,0.9c-0.1,0.2-0.5,0.4-0.6,0.5c-0.2,0.1-0.8,0.1-1,0.3c-0.2,0.1-0.3,0.6-0.4,0.8c-0.1,0.1-0.2,0.3-0.4,0.4
            c-0.4-0.2-1.2-0.2-1.4-0.4c-0.3-0.2-0.7-0.6-0.9-0.8c-0.2-0.1-0.8-0.2-0.9-0.4c-0.2-0.3,0.2-1,0-1.3c-0.1-0.2-0.7-0.4-0.9-0.4
            c-0.4-0.1-1.2,0.2-1.5,0.1c-0.2-0.1-0.5-0.6-0.7-0.8c-0.2-0.2-0.8-0.3-1.1-0.4c-0.2-0.1-0.6-0.2-0.8-0.3
            c-0.2-0.1-0.3-0.6-0.5-0.8c-0.2-0.2-0.5-0.6-0.8-0.7c-0.4-0.2-1.2,0-1.5-0.1c-0.3-0.1-0.6-0.6-0.8-0.8c-0.2-0.3-0.4-1-0.7-1.2
            c-0.2-0.2-0.8-0.1-1.1-0.2c-0.3-0.1-0.8-0.4-1.1-0.6c-0.2-0.2-0.4-0.8-0.6-1.1c-0.2-0.3-0.7-0.9-1-1.1c-0.3-0.2-1-0.4-1.2-0.6
            c-0.2-0.2-0.4-0.9-0.6-1.1c-0.1-0.1-0.5-0.2-0.6-0.4c-0.2-0.2-0.4-0.9-0.6-1.1c-0.2-0.2-0.7-0.4-0.9-0.6
            c-0.2-0.2-0.5-0.6-0.6-0.8c-0.1-0.3,0.2-1.1,0-1.3c-0.2-0.3-1-0.3-1.1-0.5C435.5,449.5,435.5,449.4,435.5,449.3z"/>
    </g>
    <g id="Chatham">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="chatham-county" ref="chatham-county" class="st10" d="M493.4,399.6c0.1,0.2,0.3,0.6,0.2,0.8c0,0.2-0.4,0.3-0.5,0.5c-0.1,0.1-0.1,0.5-0.2,0.6
            c-0.1,0.1-0.5,0.2-0.7,0.2c-0.2-0.1-0.5-0.5-0.7-0.7c-0.2-0.2-0.7-0.3-0.9-0.5c-0.1-0.1-0.4-0.4-0.4-0.6c0-0.2,0.3-0.7,0.5-0.8
            c0.2-0.1,0.7,0.1,0.9,0.1c0.2,0,0.4,0.2,0.6,0.2c0.2,0,0.5-0.1,0.7-0.1C493,399.4,493.3,399.4,493.4,399.6z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="chatham-county" ref="chatham-county" class="st10" d="M494.8,404.2c0.2,0.2,0.4,0.7,0.4,1c0,0.3-0.5,0.6-0.7,0.8c-0.2,0.3-0.6,0.9-0.7,1.2
            c-0.2,0.4-0.4,1.1-0.6,1.5c-0.1,0.4-0.3,1.2-0.5,1.5c-0.3,0.4-1,0.9-1.4,1.1c-0.4,0.2-1.3,0.4-1.7,0.6c-0.5,0.3-1.2,1-1.6,1.4
            c-0.5,0.5-1.5,1.4-2,1.9c-0.2,0.2-0.5,0.9-0.8,1c-0.2,0.1-0.6-0.1-0.7-0.2c-0.3-0.3-0.2-1.1-0.3-1.5c-0.1-0.3-0.4-0.9-0.6-1.1
            c-0.1-0.2-0.3-0.7-0.5-0.9c-0.2-0.2-0.7-0.2-0.9-0.4c-0.2-0.2-0.4-0.8-0.5-1.1c0-0.3,0-1,0.2-1.2c0.1-0.2,0.5-0.4,0.6-0.6
            c0.1-0.2-0.2-0.6-0.2-0.8c-0.1-0.3-0.3-0.8-0.5-1c-0.1-0.2-0.5-0.6-0.7-0.7c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.3,0.2-1,0.4-1.3
            c0.2-0.2,0.7-0.3,0.8-0.5c0.2-0.2,0.5-0.7,0.5-1c0-0.2-0.1-0.7,0-0.9c0.1-0.3,0.6-0.9,0.9-1.1c0.1-0.1,0.5-0.2,0.7-0.3
            c0.1-0.1,0.3-0.2,0.5-0.4c0.2,0.1,0.3,0.2,0.4,0.2c0.4,0.1,1.2-0.2,1.7-0.2c0.4,0,1.2-0.2,1.6,0c0.2,0.1,0.6,0.4,0.8,0.6
            c0.2,0.2,0.4,0.9,0.6,1.1c0.2,0.3,0.8,0.6,1.1,0.7c0.2,0.1,0.6,0.1,0.7,0.2c0.2,0.1,0.3,0.7,0.5,0.8c0.2,0.2,0.7,0.4,0.9,0.4
            c0.2,0,0.4-0.4,0.5-0.4c0.2-0.1,0.5-0.2,0.7-0.2C494.4,403.8,494.7,404,494.8,404.2z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="chatham-county" ref="chatham-county" class="st10" d="M515.1,379.8c0.1,0.3-0.2,1-0.2,1.4c0,0.3,0.2,0.7,0.2,1c0,0.3-0.2,0.8-0.3,1.1c-0.1,0.2-0.3,0.5-0.5,0.5
            c-0.1,0-0.3-0.3-0.4-0.4c-0.1-0.1,0-0.4-0.1-0.6c0-0.1-0.2-0.4-0.3-0.4c-0.2,0-0.5,0.3-0.6,0.4c-0.1,0.2,0,0.7,0.1,0.9
            c0.1,0.2,0.5,0.5,0.6,0.8c0.1,0.2,0.4,0.8,0.3,1.1c-0.2,0.6-1.6,1.2-2.2,1.6c-0.3,0.2-0.8,0.7-1.1,0.9c-0.3,0.2-1,0.5-1.3,0.4
            c-0.2,0-0.5-0.3-0.6-0.4c-0.2,0-0.8,0-1,0.1c-0.3,0.2-0.2,1-0.5,1.2c-0.2,0.1-0.8,0.2-1,0.1c-0.2-0.2,0-0.7,0.1-0.9
            c0-0.1,0.3-0.3,0.3-0.5c0-0.2-0.4-0.5-0.5-0.7c-0.1-0.2-0.1-0.5-0.1-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.2,0.2-0.7,0.3-0.9
            c0-0.2,0-0.8,0-1c0-0.2-0.2-0.7-0.2-1c0-0.2-0.2-0.7-0.1-1c0-0.3,0.4-0.8,0.4-1.1c0-0.3,0.1-0.9-0.1-1.1c-0.1-0.1-0.5,0-0.7-0.1
            c-0.3-0.1-0.8-0.5-1-0.7c-0.1-0.2-0.1-0.7-0.3-0.9c-0.2-0.2-0.8-0.3-1.1-0.4c-0.2-0.1-0.7-0.2-0.9-0.4c-0.2-0.1-0.6-0.6-0.8-0.7
            c-0.2-0.1-0.6-0.3-0.7-0.1c-0.1,0.2,0.2,0.6,0.4,0.8c0.1,0.2,0.5,0.7,0.7,0.9c0.2,0.2,0.5,0.4,0.7,0.5c0.2,0.1,0.8,0.3,1,0.5
            c0.2,0.2,0,1,0.2,1.2c0.2,0.2,0.7,0.2,0.9,0.3c0.2,0.1,0.4,0.4,0.5,0.6c0.1,0.3-0.2,1-0.2,1.3c0,0.3-0.1,1-0.1,1.3
            c0,0.2,0.3,0.5,0.4,0.6c0,0.2-0.1,0.7-0.2,0.9c-0.1,0.2-0.4,0.5-0.6,0.6c-0.3,0.1-0.9,0-1.2-0.2c-0.2-0.1-0.3-0.5-0.5-0.6
            c-0.2-0.1-0.7-0.2-0.9-0.1c-0.2,0.1-0.3,0.5-0.3,0.6c0,0.2,0.1,0.7,0.3,0.8c0.3,0.2,1.3-0.3,1.6,0c0.1,0.1,0.1,0.4,0,0.5
            c-0.1,0.2-0.5,0.3-0.6,0.4c-0.2,0.2-0.5,0.8-0.7,1c-0.2,0.2-0.8,0.7-1.1,0.5c-0.2-0.1-0.2-0.8-0.3-1c-0.2-0.3-0.7-0.7-1-0.8
            c-0.3-0.1-1.2,0.1-1.5-0.2c-0.2-0.2-0.4-0.9-0.3-1.2c0-0.3,0.5-0.7,0.6-1c0.1-0.3-0.3-0.9-0.3-1.2c0-0.4,0.5-1,0.5-1.4
            c0-0.3-0.2-0.9-0.4-1.1c-0.1-0.1-0.5-0.2-0.6-0.3c-0.3-0.3-0.7-1-0.9-1.3c-0.1-0.2-0.4-0.6-0.6-0.7c-0.2-0.1-0.6-0.2-0.8-0.1
            c-0.2,0-0.6,0.2-0.7,0.4c0,0.1,0.2,0.3,0.3,0.4c0.2,0.2,0.7,0.3,0.8,0.5c0.1,0.2,0,0.7,0.1,0.9c0.1,0.2,0.3,0.7,0.5,0.8
            c0.1,0.1,0.4,0.1,0.5,0.1s0.6,0.6,0.7,0.9c0,0.3-0.2,0.8-0.4,1c-0.1,0.2-0.6,0.4-0.7,0.6c-0.2,0.3-0.2,1-0.2,1.4
            c0,0.3,0.3,1.2,0.3,1.3c0,0.1,0.7,0.8,1,0.9c0.2,0.1,0.7,0.3,0.9,0.4c0.3,0.2,0.8,0.5,0.9,0.8c0.2,0.3-0.1,1.1,0.1,1.4
            c0.1,0.2,0.7,0.3,0.9,0.4c0.2,0.2,0.5,0.8,0.7,1c0.3,0.2,0.9,0.6,1.2,0.5c0.2,0,0.5-0.4,0.6-0.4c0.4-0.1,1.2-0.1,1.6,0.1
            c0.3,0.1,0.8,0.5,0.8,0.8c0,0.2-0.1,0.5-0.2,0.7c-0.2,0.3-0.8,0.6-1,0.8c-0.4,0.3-1,0.9-1.4,1.2c-0.5,0.3-1.7,0.7-2.1,1.2
            c-0.3,0.3-0.5,1.1-0.7,1.4c-0.2,0.3-0.6,1-0.9,1.1c-0.3,0-0.7-0.3-0.9-0.5c-0.1-0.2,0-0.8-0.2-0.9c-0.2-0.1-0.6-0.1-0.8,0
            c-0.2,0.1-0.5,0.6-0.7,0.6c-0.3,0-0.7-0.6-0.9-0.7c-0.3-0.2-0.7-0.7-1.1-0.7c-0.4-0.1-1.4,0.2-1.8,0.2c-0.4,0-1.4,0-1.8-0.3
            c-0.3-0.2-0.5-1-0.7-1.3c-0.3-0.4-1-0.9-1.3-1.2c-0.3-0.3-0.8-0.9-0.9-1.3c-0.1-0.3-0.1-0.9,0-1.1c0-0.2,0.2-0.5,0.1-0.6
            c0-0.2-0.2-0.5-0.4-0.6c-0.2-0.1-0.6,0-0.7-0.2c-0.2-0.2-0.2-0.8-0.2-1.1c0-0.1,0-0.4,0-0.5c-0.2-0.2-0.7,0-0.9,0
            c-0.2,0-0.7,0-0.9-0.1c-0.1-0.1-0.2-0.5-0.2-0.7c0-0.2,0.2-0.5,0.4-0.6c0.2-0.1,0.6,0.1,0.8,0c0.2-0.2,0.1-0.8,0-1
            c-0.1-0.2-0.3-0.5-0.5-0.5c-0.3,0-0.7,0.5-1,0.7c-0.2,0.1-0.5,0.3-0.6,0.5c-0.1,0.2,0,0.7,0,0.9c0,0.2-0.1,0.5-0.1,0.6
            c0,0.2,0.3,0.5,0.4,0.6c0.1,0.2,0.4,0.6,0.6,0.7c0.2,0.1,0.9,0.1,1.1,0.2c0.2,0.1,0.4,0.4,0.4,0.6c0.1,0.2-0.1,0.7,0,0.9
            c0.1,0.2,0.7,0.4,0.9,0.6c0.2,0.3,0.4,1.1,0.5,1.4c0,0.2,0.1,0.7,0,0.9c-0.1,0.2-0.4,0.4-0.6,0.4c-0.2,0-0.7-0.2-0.9-0.3
            c-0.2-0.1-0.7-0.4-0.9-0.6c-0.2-0.2-0.3-0.8-0.4-1.1c-0.1-0.2-0.4-0.7-0.6-0.9c-0.1-0.2-0.5-0.6-0.6-0.8c-0.1-0.2,0-0.7-0.1-1
            c-0.1-0.3-0.4-0.7-0.6-0.9c-0.2-0.2-0.7-0.2-1-0.4c-0.2-0.1-0.6-0.4-0.7-0.6c-0.2-0.2-0.2-0.8-0.4-0.9c-0.2-0.2-0.9-0.2-1.1-0.1
            c-0.2,0-0.7,0.1-0.8,0.3c0,0.1,0.2,0.4,0.3,0.5c0.1,0.1,0.4,0.1,0.5,0.2c0.2,0.1,0.4,0.7,0.5,0.7c0,0,0.6,0.5,0.8,0.6
            c0.2,0.1,0.8,0.3,1,0.5c0.1,0.1,0.2,0.4,0.1,0.5c-0.1,0.2-0.6,0.2-0.8,0.1c-0.3,0-0.8-0.2-1.1-0.3c-0.2-0.1-0.6-0.5-0.8-0.5
            c-0.2,0-0.6,0.1-0.7,0.3c-0.1,0.2,0.1,0.5,0.3,0.6c0.2,0.2,0.7,0.3,0.9,0.4c0.2,0.1,0.6,0.3,0.8,0.3c0.3,0,0.8,0,1.1-0.1
            c0.2,0,0.5-0.2,0.6-0.2c0.2,0,0.6,0.3,0.7,0.5c0.1,0.1,0.3,0.5,0.3,0.7c0.1,0.3-0.1,1-0.1,1.3c0,0.3,0.1,0.8,0.3,1
            c0.2,0.3,1,0.5,1.4,0.6c0.3,0.1,0.8,0.6,1.2,0.6c0.5,0.1,1.3-0.5,1.8-0.5c0.2,0,0.5,0.3,0.7,0.4c0.2,0.3,0.6,0.9,0.7,1.3
            c0,0.2-0.2,0.7-0.4,0.8c-0.2,0.2-0.8,0.5-1.1,0.4c-0.3,0-0.5-0.6-0.8-0.7c-0.3,0-0.7,0.2-1,0.4c-0.3,0.1-0.8,0.7-1.1,0.7
            c-0.5,0-1.3-0.9-1.6-1.2c-0.4-0.4-0.9-1.2-1.1-1.7c-0.1-0.2,0-0.8-0.2-1.1c-0.2-0.2-0.7-0.3-0.9-0.4c-0.3-0.3-0.5-1.2-0.9-1.4
            c-0.3-0.2-1-0.2-1.3-0.1c-0.3,0.1-0.9,0.6-1.2,0.6c-0.2,0-0.6,0.1-0.7,0c-0.1-0.2,0.2-0.7,0.3-1c0.1-0.2,0.6-0.5,0.6-0.7
            c0-0.3-0.3-0.8-0.5-0.9c-0.2-0.1-0.6-0.3-0.8-0.3c-0.2,0-0.6,0.4-0.8,0.5c-0.2,0.2-0.5,0.8-0.7,1.1c-0.1,0.2-0.4,0.7-0.6,0.9
            c-0.2,0.1-0.6,0.4-0.8,0.3c-0.2-0.1-0.1-0.6-0.1-0.8c-0.1-0.2-0.2-0.5-0.3-0.6c-0.1-0.1-0.4-0.2-0.6-0.3c-0.3-0.1-0.8,0.1-1.1,0
            c-0.2-0.1-0.2-0.6-0.2-0.8c0-0.2,0.3-0.5,0.3-0.7c0-0.2-0.2-0.6-0.3-0.7c-0.2-0.2-0.8-0.1-0.9-0.3c-0.1-0.1-0.1-0.5-0.1-0.7
            c0-0.2,0.1-0.5,0-0.6c-0.1-0.2-0.6-0.3-0.7-0.5c-0.2-0.2-0.3-1-0.5-1.1c-0.1,0-0.2,0-0.4,0c0-0.2,0.1-0.5,0.1-0.7
            c0-0.4-0.1-1.2-0.3-1.5c-0.2-0.3-1-0.5-1.3-0.7c-0.2-0.2-0.5-0.8-0.6-1.1c-0.1-0.3,0-1-0.1-1.3c-0.2-0.4-1-0.8-1.2-1.2
            c-0.2-0.4-0.3-1.4-0.6-1.7c-0.3-0.4-1.2-0.8-1.6-1c-0.2-0.1-0.8-0.2-1-0.4c-0.3-0.2-1-1-1-1c0-0.1-0.5-1.2-0.6-1.6
            c-0.1-0.3,0.1-1,0-1.3c-0.2-0.3-1-0.4-1.2-0.7c-0.3-0.2-0.8-0.8-0.9-1.1c0-0.2,0.2-0.7,0.2-0.9c0-0.2,0-0.6,0-0.8l19.4-16.6
            l5.2,1.3c-0.1,0.2-0.5,0.5-0.4,0.7c0.1,0.4,0.9,0.7,1.2,0.9c0.3,0.2,1,0.7,1.2,0.9c0.3,0.3,1,1,1.1,1.4c0,0.3-0.3,0.7-0.3,1
            c0,0.3,0.6,0.7,0.7,0.9c0,0.2-0.1,0.7-0.3,0.8c-0.3,0.2-1.1-0.2-1.3,0c-0.2,0.1-0.3,0.7-0.2,0.9c0.1,0.4,0.9,0.8,1,1.2
            c0.1,0.2,0.1,0.7,0.1,1c0,0.1-0.3,0.3-0.4,0.4c-0.1,0.3,0,1.1,0.1,1.4c0.1,0.4,0.6,1.4,0.7,1.5c0.1,0.1,1.2,0.6,1.4,0.6
            s0.9,0.2,1.1,0.4c0.3,0.2,0.8,0.8,1.1,1c0.4,0.4,1.2,1.2,1.8,1.4c0.2,0.1,0.7,0,0.8,0c0.1,0,1.3,0.3,1.8,0.2
            c0.3-0.1,0.8-0.4,1.1-0.6c0.2-0.2,0.5-0.7,0.8-0.8c0.2-0.1,0.7-0.1,0.9-0.1c0.3,0.1,0.9,0.4,1.2,0.6c0.2,0.2,0.6,0.6,0.7,0.7
            c0.3,0.3,0.8,1,1.1,1.3c0.2,0.2,0.7,0.5,0.9,0.6c0.3,0.1,0.8,0.1,1.1,0.3c0.3,0.2,0.7,0.8,0.9,1.1c0.2,0.2,0.3,0.8,0.5,1
            c0.2,0.2,0.7,0.5,0.9,0.6c0.2,0.1,0.7,0,0.9,0.1c0.4,0.1,1,0.6,1.3,0.7c0.2,0.1,0.8,0,1-0.1c0.2,0,0.6-0.1,0.8-0.1
            c0.2,0.1,0.7,0.4,0.9,0.6c0.2,0.3,0.3,0.9,0.4,1.2c0.1,0.3,0,1,0.2,1.3c0.2,0.2,0.7,0.5,1,0.5c0.2,0,0.6-0.4,0.8-0.6
            c0.2-0.1,0.4-0.4,0.6-0.5c0.2-0.1,0.8-0.3,1-0.3C514.5,379.2,515,379.5,515.1,379.8z"/>
    </g>
    <g id="Effingham">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="effingham-county" ref="effingham-county" class="st10" d="M454.6,353c-0.1-0.5-0.1-1.3-0.1-1.7c0-0.3,0.1-0.9,0.1-1.2c0-0.3-0.2-0.9-0.3-1.1
        c-0.2-0.2-0.7-0.4-0.8-0.5c-0.2-0.2-0.4-0.7-0.5-1c-0.1-0.4,0.3-1.2,0.3-1.6c0-0.4-0.3-1.1-0.6-1.4c-0.2-0.2-0.7-0.2-0.8-0.3
        c-0.2-0.3,0.1-1.3-0.2-1.5c-0.4-0.3-1.5,0.1-1.9-0.1c-0.3-0.2-0.6-0.8-0.7-1.1c-0.1-0.4,0-1.3-0.2-1.6c-0.2-0.3-1.2-0.5-1.2-0.6
        c0-0.1-0.4-0.8-0.5-1.1c-0.1-0.3,0.1-1.1-0.1-1.4c-0.2-0.2-0.8-0.3-1-0.4c-0.3-0.2-0.5-0.9-0.7-1.2c-0.1-0.2-0.5-0.4-0.6-0.7
        c-0.1-0.4,0.2-1.1,0.1-1.5c-0.1-0.4-0.9-0.9-0.9-1.4c0-0.2,0.5-0.7,0.5-0.8c0-0.1-0.4-0.8-0.3-1c0.1-0.3,0.9-0.5,1.1-0.8
        c0.2-0.3,0.4-0.9,0.4-1.3c0-0.4-0.2-1.3-0.5-1.6c-0.2-0.3-1.2-0.2-1.3-0.5c-0.1-0.3,0.7-0.6,0.7-0.9c0-0.4-0.4-1.1-0.7-1.4
        c-0.1-0.2-0.5-0.5-0.7-0.6c0,0-0.1,0-0.1-0.1l0.9-2l15.8-11c0.3,0.2,0.5,0.3,0.7,0.5c0.2,0.2,0.4,0.7,0.6,0.8
        c0.3,0.2,1.1,0.3,1.4,0.6c0.3,0.2,0.6,0.7,0.8,1c0.2,0.3,0.5,0.9,0.8,1c0.6,0.4,2.1,0.4,2.8,0.5c0.4,0,1.2-0.2,1.6-0.2
        c0.3,0,0.9,0,1.2,0.1c0.5,0.2,1.2,1,1.4,1.5c0.2,0.4-0.4,1.5-0.2,1.9c0.3,0.6,1.7,1.2,2.3,1.5c0.3,0.1,1,0.3,1.3,0.4
        c0.2,0.1,0.7,0.2,0.8,0.4c0.2,0.3-0.4,1.1-0.3,1.4c0.1,0.6,1,1.4,1.4,1.8c0.5,0.4,1.6,0.8,2.1,1.2c0.3,0.3,0.7,1,0.8,1.4
        c0.1,0.3,0.2,0.9,0.1,1.2c-0.1,0.2-0.9,0.3-1,0.5c-0.1,0.3,0.2,0.9,0.2,1.2c0,0.2-0.3,0.7-0.4,0.9c-0.1,0.3-0.1,1.1,0,1.4
        c0.1,0.4,0.4,1.1,0.6,1.5c0.3,0.4,1.3,1,1.6,1.4c0.2,0.3,0.6,0.9,0.6,1.2c0,0.4-0.5,1.1-0.3,1.5c0.1,0.3,0.8,0.4,0.9,0.7
        c0.1,0.3-0.1,1,0,1.2c0.1,0.2,0.8,0.4,0.9,0.6c0.2,0.3,0,1.1,0,1.4c0,0.2,0.1,0.7,0.3,0.7c0.3,0,0.4-0.7,0.6-0.9
        c0.2-0.2,0.7-0.4,0.9-0.4c0.4,0.1,1,0.6,1.2,0.9c0.1,0.2,0.2,0.7,0.1,0.9c0,0.3-0.5,0.7-0.5,0.9c0,0.2,0.4,0.6,0.4,0.9
        c0.1,0.4-0.1,1.1,0,1.4c0.1,0.4,0.7,0.9,0.8,1.2c0.1,0.4,0.1,1.4,0,1.8c-0.1,0.4-0.3,1.1-0.6,1.4c-0.4,0.4-1.6,0.8-1.9,1.2
        c-0.2,0.2-0.1,0.9-0.2,1.1c-0.1,0.1-0.4,0.3-0.5,0.4c-0.1,0.3-0.2,1-0.1,1.3c0.1,0.4,0.9,1.1,0.9,1.5c0,0.1,0,0.1-0.1,0.2
        l-5.2-1.3l-19.4,16.6c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.2-0.9-0.1-1.2-0.2c-0.4-0.3-1-1.2-1-1.6c0-0.5,0.5-1.3,0.6-1.8
        c0.1-0.4,0.5-1.1,0.5-1.5c0-0.3-0.2-0.9-0.4-1.1c-0.1-0.2-0.6-0.3-0.7-0.5c-0.2-0.2-0.2-0.8-0.3-1.1c-0.1-0.4-0.3-1.1-0.3-1.5
        c0-0.3,0.1-0.8,0.1-1.1c0-0.3-0.3-0.8-0.3-1.1c0-0.4,0.6-1.1,0.5-1.5c-0.1-0.4-1.1-0.5-1.4-0.8c-0.1-0.2-0.2-0.6-0.2-0.8
        c0-0.3,0.5-0.9,0.4-1.3c-0.1-0.3-0.8-0.6-1-0.8C454.7,353.1,454.6,353.1,454.6,353z"/>
    </g>
    <g id="Bryan">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="bryan-county" ref="bryan-county" class="st10" d="M425.7,371.4c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.7-0.6-0.9c-0.2-0.2-0.7-0.5-1-0.6
        c-0.3-0.1-0.8,0.1-1.1-0.1c-0.2-0.1-0.5-0.7-0.6-0.9c-0.1-0.2-0.1-0.7-0.2-0.9c-0.1-0.2-0.5-0.3-0.6-0.5c-0.1-0.2-0.2-0.7-0.4-0.8
        c-0.2-0.2-0.8-0.2-0.9-0.4c-0.2-0.2,0-0.9,0-1.2c0-0.1-0.1-0.4-0.1-0.6c0-0.1,0-0.3,0-0.5l35-11c0,0.1,0.1,0.2,0.1,0.3
        c0.2,0.3,0.9,0.5,1,0.8c0.1,0.3-0.4,0.9-0.4,1.3c0,0.2,0.1,0.6,0.2,0.8c0.2,0.3,1.2,0.5,1.4,0.8c0.1,0.4-0.5,1.1-0.5,1.5
        c0,0.3,0.3,0.8,0.3,1.1c0,0.3-0.1,0.8-0.1,1.1c0,0.4,0.2,1.1,0.3,1.5c0.1,0.3,0.1,0.9,0.3,1.1c0.1,0.2,0.6,0.3,0.7,0.5
        c0.2,0.2,0.4,0.8,0.4,1.1c0,0.4-0.4,1.1-0.5,1.5c-0.2,0.4-0.7,1.3-0.6,1.8c0,0.5,0.6,1.4,1,1.6c0.2,0.2,1,0,1.2,0.2
        c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0,0.6,0,0.8c0,0.2-0.3,0.7-0.2,0.9c0.1,0.3,0.6,0.9,0.9,1.1c0.3,0.2,1.1,0.4,1.2,0.7
        c0.2,0.3,0,1,0,1.3c0.1,0.4,0.6,1.5,0.6,1.6c0,0.1,0.7,0.8,1,1c0.2,0.1,0.7,0.3,1,0.4c0.4,0.2,1.3,0.7,1.6,1
        c0.3,0.4,0.3,1.3,0.6,1.7c0.2,0.4,1.1,0.8,1.2,1.2c0.1,0.3,0,1,0.1,1.3c0.1,0.3,0.4,0.9,0.6,1.1c0.3,0.3,1.1,0.4,1.3,0.7
        c0.2,0.3,0.3,1.1,0.3,1.5c0,0.2-0.1,0.4-0.1,0.7c-0.2,0-0.5,0.2-0.5,0.3c-0.2,0.3,0.2,1.1,0.4,1.4c0.1,0.2,0.5,0.4,0.6,0.6
        c0.1,0.2,0.1,0.7,0.3,0.9c0.1,0.2,0.6,0.5,0.7,0.7c0.1,0.2-0.1,0.7-0.1,0.9c0,0.4,0.1,1.2,0.4,1.4c0.3,0.2,1.2-0.1,1.5-0.1
        c0.2,0,0.6-0.2,0.7-0.1c0.2,0.2,0,0.8,0,1.1c0,0.3,0.2,1,0.2,1.1c0,0.1,0.8,0.3,1.1,0.2c0.3-0.1,0.5-0.7,0.7-0.9
        c0.1-0.2,0.4-0.7,0.6-0.7c0.3,0,0.5,0.6,0.5,0.9c0,0.2-0.2,0.5-0.2,0.7c0.1,0.2,0.6,0.3,0.9,0.3c0.2,0,0.5-0.4,0.7-0.6
        c0.2-0.1,0.5-0.3,0.7-0.3c0.3-0.1,0.9-0.2,1.1-0.1c0.3,0,0.9,0.2,1.1,0.4c0.3,0.3,0.5,1.2,0.6,1.6c0.1,0.4,0.2,1.3,0.4,1.7
        c0.3,0.5,1.1,1.1,1.6,1.4c0.2,0.2,0.6,0.5,0.9,0.7c-0.1,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.5,0.2-0.7,0.3c-0.3,0.2-0.8,0.8-0.9,1.1
        c-0.1,0.2,0.1,0.7,0,0.9c-0.1,0.3-0.3,0.8-0.5,1c-0.2,0.2-0.7,0.3-0.8,0.5c-0.2,0.3-0.4,0.9-0.4,1.3c0,0.1,0,0.3,0.1,0.5
        c-0.1,0-0.1,0-0.2,0c-0.2,0-0.2,0.5-0.2,0.6c0,0.2-0.1,0.6,0,0.8c0.1,0.2,0.7,0.3,0.8,0.5c0.1,0.2-0.2,0.7-0.3,0.9
        c-0.1,0.1-0.3,0.3-0.4,0.5c-0.1,0.3,0.2,0.9,0.1,1.2c0,0.3-0.4,0.9-0.2,1.1c0.2,0.3,1,0.3,1.2,0.5c0.2,0.2,0.4,0.7,0.4,0.9
        c0,0.3,0,1-0.2,1.3c-0.1,0.2-0.6,0.5-0.9,0.4c-0.2,0-0.4-0.4-0.5-0.4c-0.4-0.2-1.3-0.2-1.7-0.3c-0.4-0.2-1.2-0.7-1.6-0.9
        c-0.3-0.2-0.9-0.8-1.2-1.1c-0.3-0.2-1.1-0.6-1.5-0.7c-0.3-0.1-1,0-1.4-0.1c-0.2-0.1-0.6-0.5-0.8-0.7c-0.2-0.3-0.4-1-0.4-1.3
        c0-0.3,0.2-0.8,0.2-1.1c-0.1-0.3-0.4-0.8-0.6-1.1c-0.2-0.2-0.5-0.6-0.8-0.7c-0.4-0.1-1.1-0.1-1.5-0.1c-0.3,0-0.8,0.2-1.1,0.2
        c-0.2,0-0.7,0-0.8-0.1c-0.3-0.2-0.3-1-0.3-1.3c0-0.1,0.1-0.4,0-0.6c0-0.1-0.1-0.4-0.2-0.5c0,0-0.1,0-0.1,0
        c-0.1-0.2-0.2-0.4-0.3-0.5c-0.2-0.1-0.6,0-0.8-0.2c-0.2-0.2,0-0.8,0-1.1c-0.1-0.3-0.3-0.9-0.6-1c-0.2-0.1-0.7-0.2-1-0.1
        c-0.1,0-0.4,0.2-0.5,0.4c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.5,0,0.7,0.1c0.1,0.1,0.2,0.4,0.2,0.5c-0.1,0.1-0.4,0-0.4,0.1
        c-0.1,0.2,0.2,0.6,0.2,0.7c0,0.2,0,0.8-0.2,0.9c-0.1,0.1-0.3,0-0.4,0c-0.2,0.1-0.4,0.5-0.6,0.6c-0.2,0-0.5-0.2-0.6-0.4
        c-0.1-0.1-0.1-0.5,0-0.6c0.1-0.1,0.5,0.1,0.6,0c0.1-0.1,0.2-0.5,0.2-0.6c0-0.2-0.2-0.5-0.4-0.7c-0.2-0.1-0.7-0.2-0.8-0.3
        c-0.1-0.1-0.3-0.5-0.3-0.6c0-0.3,0.7-0.6,0.7-0.8c0-0.2-0.2-0.5-0.3-0.5c-0.2-0.1-0.7-0.1-1,0c-0.1,0-0.3,0.3-0.4,0.3
        c-0.3,0-0.6-0.5-0.7-0.8c-0.1-0.2-0.3-0.6-0.4-0.8c0-0.2,0.1-0.6,0.1-0.8c0-0.3,0.1-1.1,0-1.4c-0.1-0.2-0.7-0.4-0.8-0.7
        c-0.2-0.2-0.3-0.8-0.3-1.1c0-0.3,0.3-0.9,0.2-1.2c-0.1-0.4-1-1-1.1-1.5c-0.1-0.4,0.3-1.7,0.3-1.8s-0.4-1.1-0.7-1.3
        c-0.3-0.2-1-0.3-1.3-0.3c-0.2,0-0.4,0.4-0.6,0.4c-0.3,0-0.7-0.5-1-0.6c-0.2-0.1-0.7,0-1,0c-0.3-0.1-1-0.3-1.3-0.4
        c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.7-0.2-0.9c0-0.2,0.1-0.7,0.2-1.1c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.7-0.3-0.9-0.2
        c-0.2,0.1-0.4,0.7-0.6,0.8c-0.2,0.1-0.7-0.3-1-0.3c-0.2,0-0.6,0.4-0.8,0.4c-0.3,0-0.7-0.4-1-0.4c-0.3,0-0.7,0.4-0.9,0.6
        c-0.1,0.1-0.1,0.5-0.2,0.5c-0.2,0.1-0.7-0.3-0.9-0.4c-0.1,0-0.4-0.2-0.6-0.2c-0.2,0.1-0.3,0.7-0.5,0.8c-0.3,0.1-1.2-0.3-1.2-0.3
        s-0.8,0.1-1-0.1c-0.2-0.1-0.4-0.8-0.6-0.9c-0.2-0.2-0.6-0.4-0.9-0.4c-0.2,0-0.4,0.3-0.6,0.3c-0.3,0-1-0.3-1.2-0.5
        c-0.2-0.3,0-1,0-1.4c0-0.3,0-0.9-0.1-1.1c-0.1-0.2-0.3-0.5-0.5-0.6c-0.1-0.2-0.5-0.5-0.6-0.7c-0.1-0.2,0.1-0.6,0-0.8
        c-0.1-0.3-0.3-0.8-0.4-0.9c-0.1-0.1-0.5-0.2-0.6-0.4c-0.2-0.2-0.3-1-0.5-1.2c-0.2-0.2-0.7-0.3-0.9-0.5c-0.2-0.1-0.5-0.5-0.6-0.6
        c-0.2-0.3-0.6-0.9-0.9-1.1c-0.2-0.2-0.8-0.6-1.1-0.7c-0.2-0.1-0.7-0.2-0.9-0.3c-0.2-0.1-0.6-0.4-0.7-0.5c-0.2-0.2-0.3-0.8-0.4-1.1
        c-0.1-0.1-0.4-0.3-0.5-0.4c-0.2-0.1-0.6-0.3-0.7-0.4c-0.2-0.2-0.2-0.8-0.4-1c-0.1-0.1-0.3-0.4-0.5-0.4c-0.3-0.1-0.8,0.4-1.1,0.4
        c-0.1,0-0.3-0.2-0.5-0.3C426.4,371.4,426,371.4,425.7,371.4z"/>
    </g>

    <g id="Liberty">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="liberty-county" ref="liberty-county" class="st11" d="M477.9,422.1c0.1,0.2-0.2,0.5-0.3,0.6c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.8-0.3-1.1-0.3
            c-0.2,0-0.7,0.1-0.9,0.2c-0.2,0.1-0.7,0.3-0.9,0.4c-0.3,0-0.9,0.1-1.2-0.1c-0.1-0.1-0.4-0.3-0.4-0.5c0.1-0.3,0.9-0.2,1.1-0.4
            c0.1-0.1,0.1-0.4,0.2-0.5c0.2-0.2,0.8-0.6,1.1-0.5c0.1,0,0.3,0.3,0.4,0.4c0.2,0.1,0.7,0,0.9,0.1c0.1,0,0.4,0.1,0.6,0.2
            C477.4,421.9,477.8,422,477.9,422.1z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="liberty-county" ref="liberty-county" class="st11" d="
            M421.3,376.6c0.1-0.1,0.3-0.2,0.4-0.3c0.6-0.5,1.6-1.7,2.1-2.2c0.4-0.5,1.4-2,2-2.7c0.3,0,0.7,0,0.9,0.1c0.1,0,0.3,0.2,0.5,0.3
            c0.3,0,0.8-0.4,1.1-0.4c0.2,0,0.4,0.3,0.5,0.4c0.2,0.2,0.2,0.8,0.4,1c0.1,0.2,0.6,0.3,0.7,0.4c0.1,0.1,0.4,0.3,0.5,0.4
            c0.2,0.2,0.2,0.8,0.4,1.1c0.1,0.2,0.5,0.4,0.7,0.5c0.2,0.1,0.7,0.2,0.9,0.3c0.3,0.1,0.8,0.5,1.1,0.7c0.3,0.2,0.7,0.8,0.9,1.1
            c0.1,0.2,0.4,0.5,0.6,0.6c0.2,0.2,0.7,0.3,0.9,0.5c0.2,0.2,0.3,0.9,0.5,1.2c0.1,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.4,0.7,0.4,0.9
            c0,0.2-0.1,0.6,0,0.8c0.1,0.2,0.4,0.6,0.6,0.7c0.1,0.2,0.4,0.5,0.5,0.6c0.1,0.3,0.1,0.8,0.1,1.1c0,0.3-0.2,1.1,0,1.4
            c0.2,0.3,0.9,0.6,1.2,0.5c0.2,0,0.4-0.3,0.6-0.3c0.2,0,0.7,0.3,0.9,0.4c0.2,0.2,0.3,0.8,0.6,0.9c0.2,0.1,1,0.1,1,0.1
            s0.9,0.4,1.2,0.3c0.2-0.1,0.3-0.7,0.5-0.8c0.1-0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.7,0.5,0.9,0.4c0.1-0.1,0.1-0.4,0.2-0.5
            c0.2-0.2,0.6-0.6,0.9-0.6c0.3,0,0.7,0.4,1,0.4c0.2,0,0.6-0.4,0.8-0.4c0.3,0,0.8,0.3,1,0.3c0.2-0.1,0.4-0.7,0.6-0.8
            c0.2-0.1,0.7,0.1,0.9,0.2c0.1,0.1,0.4,0.2,0.6,0.4c-0.1,0.4-0.2,0.9-0.2,1.1c0,0.2,0.1,0.7,0.2,0.9c0.1,0.2,0.4,0.4,0.5,0.5
            c0.3,0.2,1,0.4,1.3,0.4c0.2,0,0.7-0.1,1,0c0.3,0.1,0.7,0.6,1,0.6c0.2,0,0.4-0.3,0.6-0.4c0.3-0.1,1,0.1,1.3,0.3
            c0.3,0.2,0.7,1.3,0.7,1.3s-0.4,1.3-0.3,1.8c0.1,0.4,0.9,1,1.1,1.5c0.1,0.3-0.2,0.9-0.2,1.2c0,0.3,0.1,0.8,0.3,1.1
            c0.1,0.2,0.7,0.4,0.8,0.7c0.2,0.3,0.1,1,0,1.4c0,0.2-0.2,0.6-0.1,0.8c0,0.2,0.2,0.6,0.4,0.8c0.2,0.2,0.5,0.7,0.7,0.8
            c0.1,0,0.3-0.2,0.4-0.3c0.2-0.1,0.8-0.1,1,0c0.1,0.1,0.3,0.4,0.3,0.5c0,0.3-0.7,0.6-0.7,0.8c0,0.2,0.2,0.5,0.3,0.6
            c0.2,0.1,0.7,0.2,0.8,0.3c0.1,0.1,0.3,0.5,0.4,0.7c0,0.2,0,0.5-0.2,0.6c-0.1,0.1-0.5-0.1-0.6,0c-0.1,0.1-0.1,0.5,0,0.6
            c0.1,0.2,0.4,0.4,0.6,0.4c0.2,0,0.4-0.5,0.6-0.6c0.1,0,0.3,0.1,0.4,0c0.2-0.1,0.2-0.7,0.2-0.9c0-0.2-0.3-0.6-0.2-0.7
            c0.1-0.1,0.4,0,0.4-0.1c0.1-0.1-0.1-0.4-0.2-0.5c-0.1-0.1-0.5,0-0.7-0.1c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1,0.3-0.3,0.5-0.4
            c0.2-0.1,0.8,0,1,0.1c0.2,0.2,0.5,0.7,0.6,1c0.1,0.3-0.1,0.9,0,1.1c0.1,0.2,0.6,0.1,0.8,0.2c0.1,0.1,0.2,0.3,0.3,0.5
            c-0.2,0-0.7,0.1-0.8,0.2c-0.1,0.1-0.1,0.6-0.1,0.8c0,0.2,0,0.6,0.1,0.8c0.1,0.3,0.4,1,0.7,1.2c0.2,0.1,0.5,0.3,0.7,0.4
            c0.3,0.1,1,0.2,1.4,0.2c0.2,0,0.5-0.2,0.7-0.3c0.3,0,0.8,0.1,1.1,0.3c0.2,0.1,0.5,0.6,0.6,0.8c0,0.3-0.4,0.9-0.5,1.2
            c-0.1,0.3-0.1,0.9,0,1.2c0,0.2,0.2,0.6,0.3,0.8c0.3,0.3,1,0.7,1.4,0.8c0.3,0.1,1-0.1,1.3,0c0.4,0.1,1.1,0.3,1.4,0.5
            c0.2,0.1,0.7,0.4,0.9,0.6c0.2,0.3,0.2,1,0.4,1.3c0.2,0.3,0.8,0.5,1.1,0.7c0.4,0.3,1.3,0.6,1.7,1c0.3,0.2,0.8,0.8,0.8,1.2
            c0,0.3-0.3,0.7-0.4,0.9c-0.1,0.2-0.5,0.3-0.7,0.5c-0.1,0.2-0.1,0.7-0.2,0.9c-0.2,0.2-0.7,0.5-1,0.6c-0.2,0-0.6,0-0.7,0
            c-0.2-0.1-0.4-0.4-0.6-0.5c-0.3-0.2-1.1-0.4-1.5-0.3c-0.3,0-0.7,0.2-0.9,0.4c-0.1,0.1-0.2,0.5-0.4,0.6c-0.3,0.1-0.9-0.1-1.2,0
            c-0.2,0.1-0.5,0.3-0.6,0.4c-0.2,0.2-0.5,0.7-0.5,1c0,0.3,0.4,0.6,0.6,0.8c0.2,0.2,0.6,0.5,0.9,0.5c0.3,0.1,1,0,1.3,0
            c0.4,0,1.1-0.2,1.4-0.2c0.2,0,0.6,0.3,0.8,0.4c0.4,0.1,1.2,0.1,1.5,0c0.2-0.1,0.6-0.5,0.8-0.6c0.2-0.1,0.5-0.3,0.7-0.3
            c0.2,0,0.6,0.5,0.8,0.5c0.3,0,1-0.4,1.2-0.7c0.1-0.1,0.1-0.4,0.1-0.5c0-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.5,0-0.6,0
            c-0.1,0-0.4,0.1-0.5,0.1c-0.2,0-0.7,0.1-0.9-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2,0.2-0.5,0.4-0.7c0.2-0.1,0.6-0.2,0.8-0.3
            c0.2-0.1,0.4-0.6,0.6-0.7c0.2-0.1,0.6-0.2,0.8-0.3c0.2,0,0.7,0,0.9-0.1c0.2-0.1,0.2-0.6,0.4-0.7c0.3-0.2,1-0.2,1.3-0.3
            c0.2,0,0.5-0.1,0.7,0c0.2,0.1,0.4,0.5,0.4,0.7c0,0.3-0.3,0.8-0.3,1.1c0,0.4,0,1.1,0,1.4c0.1,0.2,0.3,0.6,0.4,0.8
            c0.1,0.4,0.2,1.4,0.2,1.8c0,0.2-0.1,0.7-0.3,0.9c-0.2,0.1-0.6,0.1-0.8,0.2c-0.1,0.1-0.4,0.2-0.5,0.3c-0.1,0.2,0.2,0.7,0.3,0.8
            c0.1,0.1,0.4,0,0.5,0.1c0.2,0.1,0.7,0.4,0.7,0.7c0.1,0.2,0,0.6-0.1,0.8c-0.1,0.2-0.4,0.5-0.5,0.7c-0.2,0.4-0.5,1.2-0.8,1.6
            c-0.2,0.4-0.9,1-1.1,1.4c-0.1,0.4-0.1,1.2-0.2,1.5c-0.1,0.4-0.6,1-0.8,1.3c-0.2,0.3-0.3,1-0.6,1.2c-0.2,0.1-0.7,0.2-0.9,0.2
            c-0.3-0.1-0.6-0.8-0.7-1.1c-0.1-0.3-0.2-0.9-0.2-1.3c0-0.3,0-0.9,0.1-1.2c0.1-0.3,0.4-0.9,0.4-1.2c0-0.4-0.1-1.1-0.3-1.4
            c-0.2-0.4-0.6-1.3-1-1.6c-0.2-0.2-0.7-0.5-0.9-0.4c-0.2,0.1-0.2,0.6-0.4,0.6c-0.3,0-0.7-0.4-0.8-0.7c-0.1-0.2,0-0.6-0.1-0.7
            c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2-0.1-0.5-0.5-0.7-0.7c-0.2-0.2-0.5-0.5-0.7-0.6c-0.2-0.1-0.7-0.2-0.8-0.1c0,0-0.1,0.1-0.2,0.1
            c-0.2-0.1-0.4-0.3-0.5-0.3c-0.2-0.1-0.7-0.1-0.9-0.2c-0.3-0.2-0.7-0.8-1-0.9c-0.3-0.1-0.9-0.1-1.2-0.1c-0.3,0-0.7,0.2-1,0.3
            c-0.4,0-1.2,0.1-1.5-0.1c-0.4-0.2-0.7-1.1-1.1-1.2c-0.2-0.1-0.6,0-0.8,0.1c-0.3,0.2-0.4,0.9-0.7,1.1c-0.2,0.2-0.8,0.4-1.1,0.3
            c-0.3-0.1-0.7-0.8-1-0.9c-0.2-0.1-0.8,0-1.1,0.1c-0.4,0.1-1.3,0.4-1.7,0.5c-0.4,0.1-1,0.5-1.4,0.5c-0.4,0-1.2-0.6-1.6-0.7
            c-0.3,0-1,0-1.3,0.2c-0.2,0.1-0.3,0.7-0.5,0.8c-0.2,0.2-0.8,0.3-1.1,0.2c-0.3-0.1-0.5-0.9-0.7-1.1c-0.2-0.2-0.8-0.2-1.1-0.4
            c-0.3-0.2-0.4-1-0.6-1.3c-0.1-0.2-0.3-0.6-0.4-0.8c-0.2-0.2-0.8-0.2-0.8-0.5c0-0.3,0.6-0.5,0.7-0.8c0.1-0.3-0.2-0.9-0.3-1.1
            c-0.2-0.3-0.9-0.7-1.2-0.9c-0.3-0.1-0.8-0.3-1.1-0.4c-0.1,0-0.2-0.1-0.2-0.1c-1.6-0.7-4.4-2-5.8-2.7c-1.4-0.6-4.2-1.9-5.6-2.6
            c-0.7-0.3-2.1-0.9-2.8-1.4c-0.3-0.2-0.9-0.8-1.1-1.2c-0.1-0.3,0.1-1.1,0-1.5c-0.1-0.4-0.6-1-0.9-1.3c-0.4-0.4-1.3-1.2-1.8-1.4
            c-0.5-0.2-1.6-0.3-2-0.6c-0.4-0.3-0.8-1.2-1.1-1.6c-0.4-0.5-1.1-1.5-1.6-1.8c-0.3-0.2-1-0.2-1.3-0.5c-0.2-0.2-0.2-1-0.3-1.3
            c-0.2-0.3-0.8-0.8-1.2-1c-0.3-0.2-1-0.2-1.2-0.4c-0.2-0.2-0.2-0.7-0.3-0.9c-0.1-0.2-0.5-0.3-0.6-0.5c-0.3-0.4-0.5-1.4-0.4-1.9
            c0-0.2,0.4-0.5,0.5-0.7c0.2-0.4,0.4-1.2,0.6-1.6c0.2-0.3,0.7-0.9,0.8-1.3c0.1-0.3,0-0.8,0-1.1c0.1-0.3,0.4-0.9,0.5-1.3
            c0.1-0.3,0.1-1,0.1-1.4c0-0.6,0-1.9-0.2-2.4c-0.1-0.3-0.6-0.7-0.9-0.8c-0.3-0.1-1,0.2-1.4,0.1c-0.3-0.1-0.6-0.6-0.9-0.6
            c-0.2,0-0.5,0.3-0.7,0.3c-0.4,0-1.2-0.2-1.4-0.5c-0.3-0.2-0.5-1-0.7-1.3c-0.1-0.2-0.3-0.7-0.5-0.9c-0.2-0.2-0.8-0.4-0.9-0.7
            c-0.1-0.2-0.4-0.7-0.3-0.9c0.1-0.3,0.9-0.3,1.1-0.5c0.6-0.3,1.7-1.3,2.3-1.7C419.4,378.1,420.5,377.2,421.3,376.6z"/>
    </g>
    <g id="Long">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="long-county" ref="long-county" class="st10" d="M449,418.3l-7.7,15.2l-10.1,4.8c0-0.2-0.3-0.6-0.4-0.6c-0.3-0.1-0.7,0.5-1,0.4
        c-0.2-0.1-0.3-0.5-0.5-0.7c-0.2-0.2-0.6-0.4-0.8-0.5c-0.2-0.1-0.6-0.3-0.7-0.5c-0.1-0.2,0.1-0.7,0.1-1c0-0.4,0.3-1.2,0.2-1.5
        c-0.1-0.3-0.8-0.8-1.1-1c-0.2-0.1-0.6-0.4-0.9-0.5c-0.2-0.1-0.8,0-1-0.2c-0.2-0.1-0.2-0.6-0.3-0.8c-0.2-0.2-0.6-0.7-0.8-0.8
        c-0.2-0.1-0.7,0-0.8-0.1c-0.2-0.2-0.3-0.9-0.5-1.1c-0.2-0.2-0.8-0.5-1.2-0.6c-0.2,0-0.5,0.1-0.6,0c-0.3-0.2-0.3-0.9-0.5-1.2
        c-0.2-0.2-0.9-0.2-1.1-0.3c-0.1-0.1-0.4-0.3-0.5-0.4c-0.1-0.2,0.2-0.8,0.1-1.1c-0.1-0.2-0.5-0.3-0.7-0.4c-0.2-0.1-0.5-0.5-0.7-0.6
        c-0.2-0.1-0.6-0.2-0.9-0.2c-0.3,0-1,0.3-1.3,0.2c-0.2-0.1-0.6-0.5-0.7-0.7c-0.1-0.2-0.3-0.6-0.4-0.8c-0.2-0.2-0.7-0.2-0.9-0.3
        c-0.2-0.1-0.6-0.2-0.7-0.3c-0.1-0.2,0.1-0.8,0-1c-0.1-0.3-0.3-0.8-0.5-1c-0.2-0.2-0.7-0.3-0.8-0.4c-0.2-0.2-0.6-0.5-0.8-0.8
        c-0.2-0.4,0-1.4-0.3-1.8c-0.2-0.3-0.8-0.5-1.1-0.6c-0.2-0.1-0.7-0.3-1-0.4c-0.3-0.1-1.1-0.3-1.3-0.6c-0.3-0.3-0.4-1.2-0.5-1.5
        c-0.1-0.2-0.3-0.5-0.3-0.7c0-0.2,0.4-0.5,0.4-0.6c0-0.1,0-0.8-0.2-0.9c-0.1-0.2-0.6-0.4-0.8-0.4c-0.2,0-0.5,0.3-0.7,0.3
        c-0.3,0-0.8-0.5-1.1-0.7c-0.2-0.1-0.8-0.2-1-0.4c-0.3-0.3-0.2-1.2-0.3-1.6c-0.1-0.5-0.3-1.5-0.7-1.8c-0.2-0.1-0.7,0-0.9,0.2
        c-0.2,0.1-0.2,0.7-0.4,0.9c-0.2,0.1-0.7,0.1-0.9,0c-0.1-0.3-0.1-0.8-0.1-1c0-0.4-0.2-1.2,0-1.5c0.2-0.2,0.8-0.1,1.1-0.2
        c0.2-0.1,0.6-0.3,0.8-0.4c0.2-0.1,0.8-0.2,0.9-0.4c0.2-0.3-0.2-1.2,0-1.6c0.1-0.3,0.5-0.7,0.7-1c0.1-0.2,0.3-0.8,0.5-1
        c0.2-0.1,0.6-0.2,0.8-0.4c0.3-0.2,0.9-0.8,1.1-1.1c0.2-0.3,0.4-1.1,0.4-1.4c0-0.4-0.3-1.2,0-1.4c0.1-0.2,0.6-0.1,0.7-0.2
        c0.1-0.2-0.2-0.8,0-1c0.2-0.2,0.8,0,1.1-0.1c0.3-0.1,0.8-0.4,0.9-0.7c0.1-0.2-0.2-0.5-0.2-0.7c0-0.2,0.1-0.7,0.2-0.9
        c0.1-0.2,0.6-0.5,0.8-0.7c0.2-0.3,0.5-1,0.5-1.3c0-0.3-0.3-0.9-0.3-1.1c0-0.3,0.3-0.9,0.2-1.2c-0.1-0.2-0.5-0.3-0.6-0.5
        c-0.1-0.2,0-0.7,0-1c0.1-0.3,0.8-0.7,1-1c0.1-0.2,0-0.9,0.2-1.1c0.2-0.2,0.9-0.1,1.1-0.3c0.2-0.1,0.2-0.6,0.3-0.8
        c0.2-0.4,0.3-1.2,0.7-1.4c0.2-0.1,0.8,0.1,1-0.1c0.2-0.2-0.2-0.8-0.1-1c0.1-0.3,0.7-0.5,0.9-0.7c0.2-0.2,0.2-0.4,0.4-0.6
        c0.2-0.1,0.6,0,1-0.4c-0.1,0.2,0.1,0.7,0.3,0.9c0.2,0.2,0.7,0.4,0.9,0.7c0.2,0.2,0.4,0.7,0.5,0.9c0.2,0.3,0.4,1.1,0.7,1.3
        c0.3,0.2,1.1,0.5,1.4,0.5c0.2,0,0.5-0.3,0.7-0.3c0.3,0,0.6,0.5,0.9,0.6c0.3,0.1,1-0.2,1.4-0.1c0.3,0.1,0.8,0.6,0.9,0.8
        c0.3,0.5,0.2,1.8,0.2,2.4c0,0.4-0.1,1.1-0.1,1.4c-0.1,0.3-0.4,0.9-0.5,1.3c0,0.3,0.1,0.9,0,1.1c-0.1,0.4-0.6,1-0.8,1.3
        c-0.2,0.4-0.4,1.2-0.6,1.6c-0.1,0.2-0.5,0.5-0.5,0.7c-0.1,0.5,0.2,1.5,0.4,1.9c0.1,0.2,0.5,0.3,0.6,0.5c0.1,0.2,0.1,0.8,0.3,0.9
        c0.2,0.2,0.9,0.2,1.2,0.4c0.3,0.2,1,0.7,1.2,1c0.2,0.3,0.1,1,0.3,1.3c0.2,0.2,1,0.3,1.3,0.5c0.5,0.3,1.3,1.3,1.6,1.8
        c0.3,0.4,0.7,1.3,1.1,1.6c0.4,0.3,1.5,0.4,2,0.6c0.5,0.3,1.4,1,1.8,1.4c0.3,0.3,0.8,0.9,0.9,1.3c0.1,0.4-0.1,1.1,0,1.5
        c0.1,0.4,0.8,1,1.1,1.2c0.6,0.5,2.1,1,2.8,1.4c1.4,0.7,4.2,2,5.6,2.6C444.6,416.4,447.4,417.7,449,418.3z"/>
    </g>
    <g id="Wayne">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="wayne-county" ref="wayne-county" class="st10" d="M399.5,408.8c0.2,0,0.7,0.1,0.9,0c0.2-0.1,0.2-0.7,0.4-0.9c0.2-0.1,0.7-0.3,0.9-0.2
        c0.4,0.2,0.5,1.3,0.7,1.8c0.1,0.4,0,1.3,0.3,1.6c0.2,0.2,0.7,0.3,1,0.4c0.3,0.1,0.8,0.6,1.1,0.7c0.2,0,0.5-0.3,0.7-0.3
        c0.2,0,0.6,0.2,0.8,0.4c0.1,0.2,0.2,0.9,0.2,0.9c0,0.1-0.4,0.4-0.4,0.6c0,0.2,0.2,0.6,0.3,0.7c0.1,0.4,0.3,1.2,0.5,1.5
        c0.2,0.3,1,0.4,1.3,0.6c0.2,0.1,0.7,0.2,1,0.4c0.3,0.1,0.9,0.4,1.1,0.6c0.2,0.4,0.1,1.4,0.3,1.8c0.1,0.2,0.5,0.6,0.8,0.8
        c0.2,0.1,0.7,0.3,0.8,0.4c0.2,0.2,0.5,0.7,0.5,1c0.1,0.2-0.2,0.8,0,1c0.1,0.2,0.5,0.3,0.7,0.3c0.2,0.1,0.8,0.2,0.9,0.3
        c0.2,0.1,0.3,0.6,0.4,0.8c0.2,0.2,0.5,0.7,0.7,0.7c0.3,0.1,1-0.2,1.3-0.2c0.2,0,0.7,0.1,0.9,0.2c0.2,0.1,0.5,0.5,0.7,0.6
        c0.2,0.1,0.6,0.3,0.7,0.4c0.1,0.2-0.2,0.8-0.1,1.1c0.1,0.1,0.3,0.3,0.5,0.4c0.3,0.1,0.9,0.1,1.1,0.3c0.2,0.2,0.3,1,0.5,1.2
        c0.1,0.1,0.5-0.1,0.6,0c0.3,0.1,0.9,0.4,1.2,0.6c0.2,0.2,0.3,0.9,0.5,1.1c0.2,0.1,0.7,0,0.8,0.1c0.3,0.1,0.7,0.6,0.8,0.8
        c0.1,0.2,0.2,0.7,0.3,0.8c0.2,0.2,0.8,0.1,1,0.2c0.2,0.1,0.7,0.4,0.9,0.5c0.3,0.2,1,0.6,1.1,1c0.1,0.4-0.2,1.1-0.2,1.5
        c0,0.2-0.2,0.8-0.1,1c0.1,0.2,0.5,0.4,0.7,0.5c0.2,0.1,0.6,0.4,0.8,0.5c0.1,0.1,0.3,0.6,0.5,0.7c0.3,0.1,0.7-0.5,1-0.4
        c0.2,0.1,0.4,0.4,0.4,0.6c0,0,0,0,0,0c0,0.2-0.3,0.7-0.2,1c0,0.2,0.3,0.5,0.5,0.6c0.2,0.1,0.9-0.2,1.1,0c0.2,0.2,0.1,0.9,0,1.1
        c-0.1,0.3-0.8,0.8-0.7,1.1c0,0.2,0.4,0.3,0.5,0.4c0.2,0.2,0.7,0.6,0.8,0.9c0.1,0.2-0.1,0.6,0,0.7c0.1,0.2,0.7,0.3,0.8,0.4
        c0.2,0.2,0.3,0.6,0.5,0.8c0.2,0.1,0.8-0.1,1.1,0.1c0.2,0.1,0.6,0.6,0.6,0.9c0.1,0.3,0.1,1,0.1,1.3c0,0.2-0.2,0.6-0.3,0.7
        c-0.1,0.2-0.4,0.4-0.5,0.5c0,0.1-0.1,0.3-0.1,0.5c-0.2,0.1-0.4,0.3-0.5,0.4c-0.3,0.3-0.8,1-1,1.3c-0.3,0.4-0.6,1.4-0.9,1.8
        c-0.2,0.3-1,0.7-1.1,1.1c-0.2,0.4,0,1.3-0.3,1.6c-0.1,0.1-0.6,0-0.8,0.1c-0.2,0-0.7,0.1-1,0.1c-0.3,0-0.8-0.1-1,0
        c-0.2,0.1-0.6,0.3-0.7,0.4c-0.1,0.2-0.1,0.7-0.2,0.9c-0.1,0.4-0.5,1.2-0.5,1.6c0,0.3,0.4,0.7,0.4,0.9c0,0.3-0.2,0.9-0.4,1.1
        c-0.2,0.3-0.7,0.8-0.8,1.1c-0.3,0.5-0.7,1.8-1.1,2.3c-0.1,0.2-0.4,0.3-0.7,0.5c0-0.2-0.1-0.6-0.2-0.7c-0.4-0.4-1.6-0.3-2-0.6
        c-0.4-0.2-0.9-1-1.2-1.3c-0.4-0.4-1.1-1.1-1.5-1.4c-0.5-0.3-1.8-0.8-2.4-0.8c-0.7-0.1-2.1-0.1-2.8,0.2c-0.3,0.1-0.6,0.6-0.9,0.7
        c-0.5,0.1-1.5-0.1-2.1-0.1c-0.5,0-1.6,0.4-2.2,0.5c-0.4,0.1-1.3,0-1.8,0.1c-0.4,0.1-1.3,0.4-1.7,0.5c-0.7,0.1-2,0-2.7-0.1
        c-0.4-0.1-1.1-0.5-1.5-0.6c-0.5-0.1-1.4,0.1-1.9,0c-0.9-0.1-2.8-0.5-3.7-0.7c-0.4-0.1-1.3-0.3-1.7-0.4c-0.3-0.1-0.8-0.1-1.3-0.2
        c0-0.1,0-0.1-0.1-0.2c-0.1-0.4,0-1.2,0-1.6c0-0.3,0-1-0.1-1.2c-0.2-0.3-0.8-0.7-1-1c-0.2-0.3-0.5-0.9-0.5-1.2c0-0.3,0.4-1,0.4-1.3
        c0-0.3-0.4-1-0.4-1.3c0-0.5,0.4-1.3,0.4-1.8c0-0.6-0.4-1.7-0.7-2.2c-0.2-0.3-1-0.5-1.3-0.7c-0.3-0.2-0.8-0.8-1-1.1
        c-0.2-0.2-0.7-0.7-1.1-0.8c-0.3-0.1-0.9-0.1-1.2,0c-0.4,0.1-1,0.6-1.3,0.7c-0.2,0.1-0.7,0.3-1,0.4c-0.1,0-0.2,0.1-0.3,0.2v-9.5h-2
        v-2.1h1.7v-24.4h4.3v-1.9h-1.4v-1.7h1.5v-3.7h3.8c0.2,0.2,0.5,0.8,0.8,1c0.2,0.1,0.7,0,0.9,0.1c0.2,0.1,0.5,0.5,0.8,0.5
        c0.2,0,0.3-0.5,0.5-0.5c0.3,0,0.7,0.3,0.9,0.5c0.2,0.2,0.4,0.8,0.5,1c0.1,0.2,0,0.8,0.1,1c0.1,0.2,0.6,0.2,0.7,0.3
        c0.2,0.1,0.3,0.6,0.4,0.7c0.2,0.1,0.8,0,1,0.2c0.2,0.2,0.6,0.9,0.7,0.9C399.4,408.8,399.5,408.8,399.5,408.8z"/>
    </g>
    <g id="Brantley">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="brantley-county" ref="brantley-county" class="st10" d="M420,483.8c0.1,0.1,0.1,0.1,0.1,0.2c0,0.5-0.8,1.1-1.1,1.4c-0.1,0.1-0.4,0.4-0.5,0.5
        c-0.4,0.1-1.1-0.2-1.5-0.1c-0.2,0-0.6,0.3-0.7,0.4c-0.3,0.3-0.5,1.1-0.7,1.5c-0.1,0.2-0.5,0.4-0.6,0.5c-0.3,0.4-0.5,1.3-0.7,1.7
        c-0.2,0.6-0.3,2-0.8,2.4c-1.5,1-5.7-0.9-7.2,0c-0.2,0.1-0.2,0.5-0.3,0.7c-0.2,0.2-0.6,0.4-0.8,0.6c-0.2,0.3-0.4,0.9-0.5,1.3
        c-0.1,0.3,0.1,0.8,0,1.1c-0.1,0.3-0.7,0.6-0.7,0.9c0,0.3,0.5,0.7,0.5,1c0,0.2-0.2,0.6-0.3,0.9c-0.3-0.2-0.6-0.3-0.6-0.3l-12.4-3.2
        l-2.2,7.4l-4.8,0l-7.8-9l0-10.3l-2.5,0.6l-5.2-2.7l0-4.3c0.1,0,0.3,0.1,0.4,0.1c0.4,0.1,1,0.6,1.4,0.7c0.4,0,1.1-0.4,1.4-0.4
        c0.4,0,1,0.5,1.3,0.5c0.2,0,0.6-0.4,0.9-0.4c0.4,0,0.9,0.7,1.3,0.7c0.3,0.1,1.3-0.1,1.3,0c0,0.1,1.2,1.1,1.7,1.1
        c0.4,0,1.2-0.5,1.4-0.8c0.3-0.3,0.4-1.3,0.7-1.5c0.3-0.3,1.4-0.3,1.8-0.5c0.3-0.2,0.8-0.7,1-1c0.1-0.2-0.1-0.8,0-1.1
        c0-0.3,0-0.9,0.2-1.1c0.4-0.5,1.9-0.5,2.3-1c0.3-0.2,0.4-1.1,0.7-1.3c0.3-0.3,1.2-0.6,1.6-0.7c0.4,0,1.2,0.3,1.6,0.4
        c0.4,0.1,1.3,0.5,1.8,0.5c0.5,0,1.4-0.4,1.8-0.5c0.3-0.1,0.9-0.1,1.2-0.1c0.1,0,0.4,0,0.5,0c0.5-0.1,1.5-0.4,1.9-0.7
        c0.3-0.2,1-0.8,1.2-1.1c0.1-0.3,0.1-1,0-1.3c-0.1-0.3-0.5-1-0.7-1.2c-0.2-0.2-0.8-0.4-1-0.6c-0.2-0.3-0.4-1-0.6-1.3
        c-0.2-0.4-0.8-1.2-1-1.6c-0.1-0.3-0.5-0.8-0.5-1.1c-0.1-0.2,0.1-0.7,0-1c-0.1-0.3-0.4-0.8-0.5-1.1c0.4,0.1,1,0.1,1.3,0.2
        c0.4,0.1,1.3,0.3,1.7,0.4c0.9,0.2,2.8,0.6,3.7,0.7c0.5,0,1.4-0.1,1.9,0c0.4,0.1,1.1,0.5,1.5,0.6c0.7,0.1,2,0.2,2.7,0.1
        c0.4,0,1.3-0.4,1.7-0.5c0.4-0.1,1.3,0,1.8-0.1c0.5-0.1,1.6-0.4,2.2-0.5c0.5,0,1.6,0.2,2.1,0.1c0.3-0.1,0.6-0.6,0.9-0.7
        c0.6-0.2,2.1-0.3,2.8-0.2c0.6,0.1,1.8,0.5,2.4,0.8c0.4,0.3,1.2,1,1.5,1.4c0.3,0.3,0.8,1,1.2,1.3c0.4,0.3,1.6,0.2,2,0.6
        c0.1,0.1,0.2,0.5,0.2,0.7c-0.2,0.1-0.3,0.2-0.4,0.4c-0.2,0.5,0.1,1.5,0.1,2c0,0.4-0.1,1.3-0.3,1.7c-0.2,0.5-1.2,1.1-1.5,1.6
        c-0.4,0.8-0.7,2.7-0.8,3.6c-0.1,0.6-0.1,2-0.3,2.6c-0.1,0.4-0.7,1.2-0.7,1.7c-0.1,0.5,0.1,1.5,0,2c-0.1,0.6-0.7,1.6-0.9,2.2
        c-0.1,0.3-0.5,0.9-0.5,1.2C419.6,483.5,419.9,483.6,420,483.8z"/>
    </g>
    <g id="Charlton">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="charlton-county" ref="charlton-county" class="st10" d="M384,502.4h4.8l2.2-7.4l12.4,3.2c0,0,0.3,0.2,0.6,0.3c0.3,0.2,0.7,0.4,1,0.4
        c0.4,0.1,1.3-0.1,1.6,0.2c0.3,0.3,0.4,1.2,0.4,1.6c-0.1,0.3-0.5,0.7-0.6,0.9c-0.1,0.3-0.1,0.8-0.1,1.1c0,0.3,0.4,1,0.4,1.3
        c-0.1,0.3-0.7,0.7-0.8,0.9c-0.1,0.3-0.3,1-0.2,1.3c0.1,0.3,0.7,0.8,0.9,1.1c0.3,0.3,1.2,0.7,1.4,1.1c0.1,0.3,0.1,1,0,1.3
        c-0.2,0.5-0.9,1.2-1.2,1.6c-0.2,0.4-0.3,1.2-0.4,1.6c-0.1,0.3-0.8,1-0.6,1.3c0.1,0.3,0.8,0.3,1.1,0.5c0.3,0.1,1.1,0.6,1.1,0.6
        l-0.8,9.6c-0.1,0-0.2,0-0.3,0c-0.4,0.2-0.3,1.3-0.7,1.4c-0.4,0.1-1.1-0.5-1.5-0.7c-0.4-0.2-1.2-0.9-1.6-1c-0.4,0-1.1,0.2-1.4,0.5
        c-0.3,0.2-0.6,0.8-0.7,1.1c-0.1,0.5,0.3,1.5,0.2,2c-0.1,0.3-0.4,0.7-0.5,0.9c-0.2,0.4-0.3,1.5-0.7,1.8c-0.3,0.2-1.1,0.2-1.5,0
        c-0.3-0.2-0.6-1-0.8-1.2c-0.3-0.3-1.1-0.8-1.4-0.7c-0.4,0.1-1.1,0.8-1.2,1.3c-0.1,0.4,0.6,1.2,0.7,1.6c0.1,0.3,0.4,0.9,0.3,1.1
        c-0.1,0.4-0.9,0.8-1.2,0.9c-0.3,0.1-1.2-0.3-1.4-0.1c-0.3,0.2,0,1.2-0.1,1.4c0,0.1-0.7,1.2-0.7,1.6c0,0.6,0.8,1.6,0.8,2.2
        c0.1,0.5-0.1,1.4-0.2,1.9c-0.2,0.6-0.9,1.7-1,2.3c0,0.3,0.3,0.9,0.3,1.2c0,0.3-0.5,0.7-0.4,1c0.1,0.3,0.7,0.7,0.9,1
        c0.2,0.4,0.5,1.3,0.6,1.7c0.3,0.7,0.7,2.2,1,2.9c0.1,0.3,0.5,0.7,0.7,0.9c0.4,0.8,1.3,2.3,1.4,3.2c0.1,1-0.3,2.9-0.6,3.9
        c-0.2,0.6-1,1.7-1.1,2.3c-0.1,0.5,0.4,1.6,0.5,2.2c0.1,0.8,0.4,2.4,0.2,3.2c-0.1,0.6-0.9,1.6-1.1,2.1c-0.2,0.6-0.2,1.8-0.4,2.4
        c-0.1,0.3-0.9,0.8-0.9,1.2c-0.1,0.4,0.7,1.2,0.6,1.6c-0.1,0.4-1,0.7-1.1,1.1c-0.1,0.4,0.1,1.2,0.2,1.6c0.1,0.5,0.8,1.3,0.9,1.8
        c0.1,0.5-0.1,1.6-0.4,2c-0.3,0.4-1.2,1.1-1.7,1.2c-0.4,0.1-1.2-0.1-1.6-0.1c-0.5,0-1.5,0-1.9,0c-0.4-0.1-1.2-0.3-1.6-0.4
        c-0.4-0.1-1.3-0.5-1.8-0.6c-0.5-0.1-1.4-0.1-1.9,0c-0.5,0.1-1.5,1-2,1c-0.4,0-1.1-0.4-1.4-0.6c-0.7-0.6-1.3-2.4-1.7-3.2
        c-0.3-0.7-1-2-1.2-2.7c-0.2-0.5-0.4-1.7-0.4-2.3c0-0.5,0.3-1.6,0.4-2.1c0.1-0.6,0.2-1.7,0.2-2.2c0-0.5,0.1-1.4,0-1.9
        c-0.1-0.5-0.3-1.5-0.6-1.9c-0.3-0.5-1.3-1.1-1.6-1.6c-0.3-0.5-0.7-1.5-0.9-2c-0.2-0.3-0.7-1-0.7-1.4c0-0.3,0.5-0.6,0.6-0.9
        c0.2-0.4,0.2-1.3,0.4-1.6c0.2-0.3,0.9-1.1,0.9-1.1l7.4-27.1l-27.5-0.4v-26.7H384z"/>
    </g>
    <g id="Columbia">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="columbia-county" ref="columbia-county" class="st10" d="M394.4,194.4l-26.3,22.8c-0.5-0.2-1.1-0.3-1.4-0.5c-0.3-0.2-0.6-0.8-0.8-1.1
        c-0.3-0.4-0.8-1.3-1.1-1.7c-0.2-0.2-0.7-0.5-0.9-0.7c-0.1-0.1-0.2-0.5-0.3-0.6c-0.1-0.2-0.4-0.4-0.5-0.6c-0.1-0.2-0.2-0.6-0.2-0.8
        c0-0.4,0.7-1.2,0.6-1.6c-0.2-0.5-1.4-0.6-1.8-0.9c-0.2-0.3-0.4-1-0.6-1.3c-0.2-0.3-0.5-0.9-0.7-1.1c-0.3-0.2-1.1-0.3-1.3-0.6
        c-0.2-0.3-0.5-1-0.5-1.3c-0.1-0.4,0.2-1.2,0.1-1.5c-0.1-0.3-0.8-0.7-1-1c-0.2-0.3-0.6-1-0.6-1.4c0-0.3,0.3-1,0.3-1.4
        c-0.1-0.4-0.7-1.2-0.7-1.2l1.6-1.6l-5.5-3.1l2.8-4.7c0,0-0.4-0.3-0.5-0.4c-0.3-0.3-0.6-1-0.9-1.3c-0.3-0.2-1-0.2-1.2-0.5
        c-0.1-0.2,0.2-0.8,0.1-1.1c0-0.3-0.3-0.8-0.4-1.1c-0.1-0.3-0.2-0.9-0.1-1.2c0.1-0.3,0.5-0.7,0.8-1.1c0.2,0.2,0,0.8,0,1
        c0,0.1,0.6,0.2,0.6,0.2c0.1,0,0.6-0.3,0.7-0.5c0.1-0.2,0.1-0.6,0.1-0.7c0-0.1,0.2-0.2,0.2-0.2c0.2,0,0.8,0.4,0.9,0.4
        c0.1,0.1,0.2-0.5,0.2-0.7c0-0.2-0.4-0.7-0.3-0.9c0.1-0.1,0.4-0.3,0.6-0.3c0.2,0,0.6,0.4,0.8,0.4c0.2,0,0.4-0.4,0.6-0.4
        c0.2-0.1,0.8-0.3,1-0.2c0.1,0,0.4,0.2,0.4,0.3c0.1,0.2,0.1,1.1,0.1,1.1c0,0.1,0.6,0.5,0.8,0.4c0.2-0.1,0.3-0.6,0.3-0.8
        c0-0.4-0.2-1.4-0.2-1.5c0-0.1,0.2-0.3,0.4-0.3c0.3-0.1,0.8,0.3,1.1,0.1c0.1-0.1,0.2-0.5,0.2-0.6c0-0.1-0.2-0.5-0.1-0.7
        c0.1-0.2,0.7,0,0.9-0.1c0.2-0.1,0.5-0.5,0.7-0.6c0.2-0.1,0.7-0.1,0.9-0.2c0.2-0.1,0.6-0.3,0.8-0.3c0.1,0,0.4,0.1,0.5,0.2
        c0.1,0.1,0,0.6,0.2,0.7c0.1,0.1,0.5-0.1,0.6-0.2c0.1,0,0.7,0.1,0.9,0.2c0.2,0.1,0.7,0.5,0.8,0.5c0.1,0.1,0.6,0,0.8,0
        c0.3,0,0.8,0.2,1,0.2c0.2-0.1,0.5-0.6,0.8-0.6c0.2,0,0.7,0,0.8,0.2c0.1,0.1,0,0.5-0.1,0.6c-0.1,0.1-0.5,0.2-0.6,0.3
        c-0.1,0.1-0.2,0.4-0.3,0.5c-0.2,0.2-0.9,0.4-1.1,0.7c-0.1,0.2-0.3,0.6-0.2,0.7c0.1,0.2,0.7,0.4,0.8,0.5c0.1,0.1,0.5-0.3,0.6-0.5
        c0.2-0.3,0.8-1.2,0.9-1.2c0.1,0,0.8,0.5,1,0.4c0.2-0.1,0.3-0.6,0.4-0.8c0.2-0.2,0.5-0.8,0.8-0.8c0.2,0,0.3,0.4,0.4,0.4
        c0.2,0,0.7-0.5,0.9-0.4c0.2,0.1,0.2,0.6,0.2,0.8c0.1,0.2,0.2,0.8,0.3,1c0.2,0.2,1,0.3,1.2,0.5c0,0,0,0.2,0,0.2
        c0,0.5-0.1,1.4,0,1.8c0.2,0.5,0.9,1.5,1.3,1.9c0.3,0.3,1.1,0.9,1.5,1.1c0.3,0.2,1.2,0.2,1.5,0.4c0.3,0.2,0.7,1,1,1.3
        c0.3,0.3,0.9,1,1.3,1.1c0.4,0.1,1.2-0.1,1.5-0.2c0.3-0.1,0.9-0.7,1.3-0.6c0.5,0.1,1.1,1.2,1.4,1.5c0.3,0.3,0.9,0.8,1.2,1
        c0.5,0.3,1.7,0.6,2.2,0.9c0.3,0.2,0.9,0.7,1.2,0.9C392.8,192.6,393.8,193.7,394.4,194.4z"/>
    </g>
    <g id="Ware">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="ware-county" ref="ware-county" class="st10" d="M329.4,481.9l-1.8-4.5l-0.9-0.9v-6.1l3.1-1.3l3.6,1.6V460v-13l3.4,0.1l10.2,0.2v2.8h1.5v3.3l6.1,0
        v1.9h2v1.4h-1.9v1.5h1.6v2h1.7v7.7c0,0,0,0.8,0.2,1c0.3,0.2,1.1,0,1.4,0.1c0.2,0.1,0.6,0.4,0.7,0.6c0.1,0.2-0.3,0.7-0.3,1
        c0,0.2,0.4,0.5,0.6,0.7c0.3,0.3,0.9,0.8,1.1,1.1c0.2,0.3,0,1.1,0.3,1.4c0.3,0.3,1.2,0.3,1.6,0.5c0.4,0.2,1.1,0.5,1.3,0.8
        c0.2,0.3,0.2,1.1,0.5,1.4c0.1,0.1,0.5,0.3,0.6,0.3c0.3,0.1,1-0.2,1.4-0.2c0.3,0,0.7,0.1,1.1,0.2l0,4.3l5.2,2.7l2.5-0.6v10.3l7.8,9
        h-29.3v26.7l27.5,0.4l-7.4,27.1l-20.2-1.4v-3.8h-1.3v-10.6l-0.2-2h1.5v-1.5H353v-11h-5.8v-17.8h-2.9l-3.2-4.6l-3.6-1.4
        L329.4,481.9z"/>
    </g>
    <g id="McDuffie">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="mcduffie-county" ref="mcduffie-county" class="st10" d="M358.1,222.7c-0.4,0-0.9,0-1.1-0.1c-0.3-0.1-0.7-0.4-0.9-0.6c-0.2-0.2-0.6-0.8-0.8-1.1
        c-0.1-0.1-0.3-0.5-0.4-0.5c-0.2-0.1-0.7,0.1-0.9,0.1c-0.3,0-0.7-0.3-0.9-0.5c-0.2-0.1-0.5-0.5-0.6-0.6c-0.2-0.1-0.7-0.4-0.8-0.4
        c-0.1,0-0.7,0.2-1,0.2c-0.2,0-0.5-0.3-0.7-0.3c-0.3,0-0.9,0.1-1.2,0.2c-0.2,0.1-0.5,0.3-0.6,0.3c-0.2,0-0.7,0-0.9-0.1
        c-0.3-0.1-0.7-0.6-0.9-0.6c-0.2,0-0.6,0.2-0.7,0.1c-0.3-0.1-0.8-0.5-1.1-0.7c-0.2-0.2-0.4-0.6-0.5-0.7c-0.2-0.2-0.6-0.5-0.8-0.5
        c-0.1,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.2,0.5-0.4,0.5c-0.2,0-0.3-0.3-0.3-0.5c-0.2-0.8,0.4-1.8,0.3-3.3c-0.1-0.8-0.4-2.5-0.7-3.3
        c-0.3-0.7-1.1-1.9-1.5-2.5c-0.3-0.4-1.2-1.1-1.5-1.6c-0.3-0.5-0.8-1.8-1-2.4c-0.2-0.7-0.6-2.1-0.8-2.7c-0.4-1.3-1.4-3.9-1.8-5.2
        c-0.6-1.8-1.7-5.3-2.3-7.1c-0.2-0.7-0.8-1.8-1.1-2.6c0.2,0.2,0.4,0.5,0.6,0.4c0.3-0.1,0-0.9,0.2-1.1c0.3-0.2,1,0.4,1.3,0.5
        c0.3,0.1,0.9,0.2,1.2,0c0,0,0.1-0.1,0.1-0.2c0,0,0,0,0.1,0c0.2,0.1,0.5,0,0.7-0.1c0.2-0.1,0.5-0.4,0.7-0.5c0.2,0,0.5,0.1,0.7,0.1
        c0.3,0,0.8-0.2,1-0.4c0.1-0.1,0.3-0.5,0.4-0.6c0.1-0.1,0.4-0.5,0.6-0.5c0.2,0,0.5,0.1,0.7,0.1c0.1,0,0.4-0.1,0.5-0.1
        c0.2,0,0.7,0.2,0.9,0.1c0.1,0,0.2-0.3,0.4-0.4c0.2-0.1,0.7,0.3,0.9,0.3c0.1,0,0.4-0.2,0.5-0.3c0.1-0.2-0.1-0.7,0-0.9
        c0.1-0.2,0.4-0.5,0.5-0.5c0.2,0,0.4,0.4,0.5,0.5c0.2,0.1,0.8,0.2,1,0.1c0.1-0.1,0-0.5,0.1-0.6c0.1-0.2,0.2-0.6,0.4-0.7
        c0.2-0.1,0.6,0.1,0.8,0.2c0.2,0.1,0.5,0.4,0.7,0.5c0.2,0.1,0.6,0.3,0.8,0.4c0.1,0.1,0.4,0.4,0.4,0.6c0,0.2-0.5,0.5-0.7,0.6
        c-0.2,0.1-0.8,0.2-1,0.3c-0.1,0.1-0.3,0.5-0.4,0.5c-0.2,0.1-0.5-0.3-0.7-0.2c-0.2,0-0.7,0.3-0.8,0.6c0,0.1,0.3,0.4,0.3,0.5
        c-0.1,0.3-0.9,0.6-0.9,0.7c0,0.1,0.3,0.3,0.4,0.3c0.3,0,0.8-0.5,1.1-0.5c0.1,0,0.3,0.3,0.3,0.4c0,0.2-0.4,0.2-0.5,0.3
        c-0.1,0.1-0.2,0.5-0.3,0.7c-0.2,0.3-0.7,0.6-0.9,0.8c-0.2,0.3-0.2,1.3-0.3,1.4c-0.1,0.1,0.4,0.6,0.6,0.6c0.2,0,0.4-0.4,0.5-0.6
        c0.2-0.3,0.5-0.9,0.7-1.2c0.2-0.3,0.5-0.8,0.7-1.1c0.2-0.2,0.6-0.3,0.8-0.5c0.1-0.2,0.3-0.6,0.4-0.8c0.1-0.2,0-0.8,0.2-1
        c0.2-0.2,0.7-0.5,0.9-0.6c0.2-0.2,0.6-0.6,0.9-0.8c0.2-0.1,0.7-0.2,0.9-0.1c0.2,0.1,0.3,0.5,0.5,0.5c0.2,0,0.6-0.4,0.7-0.6
        c0.2-0.2,0.3-0.8,0.4-1c0.1-0.2,0.5-0.6,0.7-0.7c0.2-0.1,0.8-0.3,1-0.1c0,0,0,0,0,0c-0.3,0.4-0.7,0.8-0.8,1.1
        c-0.1,0.3,0,0.9,0.1,1.2c0.1,0.3,0.3,0.8,0.4,1.1c0,0.3-0.3,0.9-0.1,1.1c0.2,0.3,1,0.3,1.2,0.5c0.3,0.2,0.6,1,0.9,1.3
        c0.1,0.1,0.5,0.4,0.5,0.4l-2.8,4.7l5.5,3.1l-1.6,1.6c0,0,0.7,0.9,0.7,1.2c0.1,0.3-0.3,1-0.3,1.4c0,0.4,0.4,1.1,0.6,1.4
        c0.2,0.3,0.9,0.7,1,1c0.1,0.4-0.2,1.2-0.1,1.5c0.1,0.3,0.3,1.1,0.5,1.3c0.2,0.3,1,0.3,1.3,0.6c0.3,0.2,0.6,0.8,0.7,1.1
        c0.2,0.3,0.3,1,0.6,1.3c0.3,0.4,1.6,0.5,1.8,0.9c0.2,0.4-0.5,1.2-0.6,1.6c0,0.2,0.1,0.6,0.2,0.8c0.1,0.2,0.4,0.4,0.5,0.6
        c0.1,0.1,0.2,0.5,0.3,0.6c0.2,0.2,0.7,0.5,0.9,0.7c0.3,0.4,0.8,1.3,1.1,1.7c0.2,0.3,0.6,0.9,0.8,1.1c0.3,0.2,0.9,0.3,1.4,0.5
        l-6.4,5.5c-0.2,0-0.6,0-0.8,0c-0.3,0-0.9,0.2-1.2,0.2c-0.4,0-1.1-0.1-1.5-0.1C358.3,222.7,358.2,222.7,358.1,222.7z"/>
    </g>
    
    <g id="Warren">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="warren-county" ref="warren-county" class="st11" d="
        M358.1,222.7l-5.2,4.4l-8.9-4.4c0,0-0.8-1.1-1.1-1.4c-0.4-0.3-1.5-0.5-2-0.5c-0.5,0-1.4,0.3-1.8,0.4c-0.4,0-1.3-0.2-1.7,0
        c-0.4,0.2-0.7,1.3-1.1,1.5c-0.3,0.2-0.9,0.2-1.2,0.2c-0.4,0-1.1-0.1-1.4,0c-0.3,0.1-0.7,0.6-1,0.7c-0.2,0.1-0.7,0.1-0.8,0.2
        c-0.4,0.2-0.9,1-1.2,1.3c-0.3,0.3-0.6,1.1-0.9,1.4c-0.3,0.3-1.1,0.8-1.6,0.9c-0.3,0.1-1-0.1-1.4,0c-0.4,0.1-1.3,0.3-1.6,0.5
        c-0.4,0.3-1,1.3-1.4,1.4c-0.4,0.2-1.4-0.2-1.8,0c-0.2,0.1-0.4,0.4-0.6,0.7c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.8,0-1-0.1
        c-0.3-0.1-0.8-0.5-0.9-0.7c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.6-0.5-0.6-0.7c-0.1-0.3,0.4-1,0.4-1.4c0-0.6-0.3-1.7-0.4-2.2
        c0-0.3,0.1-1-0.1-1.2c-0.2-0.1-0.7,0.2-0.8,0c-0.4-0.3-0.6-1.3-0.6-1.8c0-0.4,0.3-1.3,0.2-1.7c-0.1-0.3-0.7-0.7-0.7-1
        c-0.1-0.4,0.3-1.3,0-1.6c-0.2-0.3-1-0.1-1.2-0.3c-0.2-0.2-0.5-0.7-0.6-1c-0.1-0.3,0.3-1,0.2-1.3c-0.1-0.4-1.2-0.9-1.3-1.3
        c0-0.4,0.7-0.9,0.7-1.3c0.1-0.6-0.4-1.7-0.6-2.2c-0.1-0.3-0.2-1-0.5-1.2c-0.4-0.3-1.5-0.2-1.9-0.6c-0.3-0.3-0.3-1.1-0.4-1.4
        c-0.1-0.4-0.3-1.3-0.4-1.8c0.1-0.4,0.2-0.8,0.3-1c0.2-0.3,0.9-0.8,1.2-1.1c0.2-0.3,0.7-0.8,0.9-1.1c0.2-0.3,0.8-1,0.9-1.4
        c0.1-0.4-0.2-1.2,0-1.5c0.2-0.3,1-0.7,1.4-0.7c0.4,0,1.3,0.2,1.6,0.4c0.3,0.1,0.8,0.8,1.2,0.8c0.4,0.1,1.2-0.6,1.6-0.6
        c0.4,0,1.1,0.2,1.5,0.2c0.4-0.1,1.2-0.5,1.5-0.8c0.3-0.3,0.6-1.1,0.9-1.4c0.2-0.3,1-0.9,1.1-1.3c0.1-0.3-0.3-1.1-0.2-1.4
        c0.1-0.3,0.6-0.8,0.8-1.1c0.2-0.3,0.6-0.9,0.7-1.3c0.1-0.4-0.1-1.2,0.1-1.6c0.1-0.2,0.4-0.6,0.7-0.7c0.3-0.1,0.9,0.2,1.2,0.1
        c0.3-0.1,0.6-0.6,0.9-1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3-0.3,0.4-0.4c0.3-0.1,0.8-0.1,1.1,0c0.1,0,0.4,0.3,0.5,0.2
        c0.2-0.1,0.3-0.5,0.4-0.7c0-0.1,0-0.4,0.1-0.5c0.1,0,0.2,0,0.3,0.2c0.3,0.9,0.9,2,1.1,2.6c0.6,1.8,1.7,5.3,2.3,7.1
        c0.4,1.3,1.4,3.9,1.8,5.2c0.2,0.7,0.5,2.1,0.8,2.7c0.2,0.6,0.6,1.8,1,2.4c0.3,0.5,1.2,1.2,1.5,1.6c0.4,0.6,1.3,1.8,1.5,2.5
        c0.3,0.8,0.6,2.5,0.7,3.3c0.1,1.5-0.4,2.5-0.3,3.3c0,0.2,0.1,0.5,0.3,0.5c0.2,0,0.2-0.4,0.4-0.5c0.1-0.1,0.4-0.2,0.5-0.1
        c0.2,0,0.6,0.4,0.8,0.5c0.2,0.2,0.4,0.6,0.5,0.7c0.2,0.2,0.7,0.6,1.1,0.7c0.2,0,0.6-0.2,0.7-0.1c0.3,0.1,0.7,0.5,0.9,0.6
        c0.2,0.1,0.7,0.1,0.9,0.1c0.2,0,0.5-0.3,0.6-0.3c0.3-0.1,0.9-0.2,1.2-0.2c0.2,0,0.5,0.3,0.7,0.3c0.3,0,0.9-0.2,1-0.2
        c0.1,0,0.7,0.3,0.8,0.4c0.2,0.1,0.4,0.5,0.6,0.6c0.2,0.2,0.7,0.4,0.9,0.5c0.2,0,0.7-0.2,0.9-0.1c0.2,0.1,0.3,0.4,0.4,0.5
        c0.2,0.3,0.6,0.9,0.8,1.1c0.2,0.2,0.7,0.5,0.9,0.6C357.3,222.7,357.8,222.7,358.1,222.7z"/>
    </g>
    <g id="Clinch">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="clinch-county" ref="clinch-county" class="st10" d="M354.6,551.4v3.8l-16.5-1.1c0-0.7,0-1.5,0-1.9c0-0.6,0.2-1.7,0.2-2.2c0.1-1,0.3-2.9,0.2-3.8
        c-0.1-0.6-0.6-1.8-0.6-2.4c0-0.5,0.6-1.4,0.6-1.8c0-0.4-0.2-1.1-0.5-1.4c-0.5-0.6-1.9-1.4-2.7-1.6c-0.9-0.3-2.8-0.2-3.7-0.4
        c-0.7-0.2-2-0.7-2.6-0.9c-0.5-0.2-1.5-0.9-2.1-0.9c-0.4,0-1.2,0.4-1.6,0.4c-0.7,0.1-2,0-2.6,0c-0.6,0-1.7,0.1-2.2,0
        c-0.8-0.1-2.4-0.5-3.1-0.9c-0.5-0.3-1.3-1.1-1.6-1.6c-0.1-0.2-0.1-0.8-0.2-1c-0.1-0.5-0.4-1.5-0.7-1.9c-0.4-0.4-1.8-0.4-2.1-0.9
        c-0.3-0.4-0.3-1.6-0.2-2.1c0-0.4,0.5-1,0.5-1.4c0-0.3-0.6-0.8-0.7-1c-0.3-0.5-0.9-1.5-1.4-1.8c-0.5-0.4-1.6-1-2.2-1.1
        c-0.7-0.2-2.1-0.2-2.8-0.4c-1-0.3-2.9-1.3-4-1.7c-0.7-0.3-2.2-0.6-3-0.9l-0.1-38.3l30.6,0.1l8.1,20.5l3.6,1.4l3.2,4.6h2.9v17.8
        h5.8v11h1.5v1.5h-1.5l0.2,2v10.6H354.6z"/>
    </g>
    <g id="Jeff_Davis">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="jeffdavis-county" ref="jeffdavis-county" class="st10" d="M333.5,422.4h-20.5v-17.5c0.2-0.1,0.5-0.2,0.7-0.3c0.2,0,0.8,0.1,1,0
        c0.2-0.1,0.5-0.5,0.7-0.7c0.1-0.2,0.2-0.7,0.3-0.9c0.1-0.1,0.6-0.2,0.8-0.3c0.2-0.2,0.6-0.7,0.7-0.9c0-0.2-0.1-0.5-0.1-0.7
        c0-0.2,0-0.5,0.1-0.6c0.1-0.1,0.6-0.1,0.7-0.2c0.3-0.2,0.5-0.8,0.7-0.9c0.4-0.1,1.2,0.2,1.6,0.2c0.1,0,0.5,0.2,0.6,0.1
        c0.3-0.1,0.5-0.8,0.6-1.1c0.1-0.3-0.2-0.9,0-1.1c0.2-0.3,1-0.4,1.3-0.5c0.4-0.1,1.2-0.4,1.6-0.5c0.2-0.1,0.7-0.2,0.9-0.4
        c0.2-0.2,0.3-1,0.6-1.2c0.2-0.2,0.9-0.4,1.2-0.6c0.2-0.2,0.5-0.7,0.7-0.9c0.2-0.2,0.8-0.3,0.9-0.5c0.1-0.2-0.1-0.7,0-0.9
        c0.1-0.1,0.6-0.1,0.7-0.1c0.3,0,1,0,1.2,0c0.1,0,0.4,0,0.7,0c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.5-0.4,0.7-0.4
        c0.5-0.2,1.8,0.1,2.2-0.2c0.2-0.1,0.3-0.6,0.5-0.7c0.3-0.2,0.9-0.5,1.2-0.5c0.3,0,0.7,0.8,1,0.7c0.2-0.1,0.2-0.7,0.4-0.9
        c0.2-0.3,0.9-0.8,1.1-1.1c0.2-0.3,0.3-0.9,0.6-1.1c0.2-0.2,0.9-0.3,1.2-0.3c0.3,0,0.9,0.6,1.2,0.6c0.3,0,0.9-0.3,1.3-0.4
        c0.1,0,0.2-0.1,0.2-0.1c0.3-0.1,0.9-0.2,1.1-0.1c0.3,0.1,0.9,0.6,1.2,0.8c0.3,0.2,1,0.6,1.3,0.6c0.3,0,0.9-0.3,1.1-0.5
        c0.2-0.2,0.4-0.7,0.3-0.9c-0.2-0.4-1.4-0.3-1.4-0.7c0-0.2,0.6-0.5,0.8-0.6c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.5-0.1,0.7-0.1
        c0.4,0.1,1.5,0.8,1.6,0.8c0.1,0,0.6,0.4,0.8,0.4c0.4,0,1.1-0.6,1.5-0.7c0.2-0.1,0.6-0.2,0.8-0.2V402h-9.6v10.7h-2.4v2.1h2.5v2.6
        v4.9H333.5z"/>
    </g>
    <g id="Wilkes">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="wilkes-county" ref="wilkes-county" class="st10" d="M301.2,171.4c-0.2-0.4-0.6-1.1-0.8-1.4c-0.2-0.2-0.7-0.6-0.9-0.8c-0.3-0.4-0.6-1.4-0.9-1.8
        c-0.2-0.3-1-0.5-1.2-0.9c-0.2-0.3-0.1-1.1,0-1.4c0.1-0.3,0.5-0.8,0.7-1.1c0.1-0.2,0-0.7,0.2-0.8c0.2-0.2,0.9-0.2,1.1-0.4
        c0.3-0.2,0.8-0.8,0.9-1.2c0.1-0.3,0-1.1,0.1-1.5c0.1-0.4,0.3-1.2,0.5-1.5c0.1-0.3,0.4-1,0.7-1.1c0.3-0.2,1,0,1.3-0.1
        c0.3-0.1,0.8-0.6,1-0.9c0.2-0.4,0.4-1.2,0.5-1.6c0.1-0.3,0.3-0.9,0.5-1.1c0.2-0.1,0.7-0.1,0.9-0.2c0.2-0.1,0.6-0.6,0.7-0.8
        c0.2-0.3,0.5-1,0.6-1.4c0.1-0.2,0.1-0.8,0.2-1c0.2-0.3,0.6-0.8,0.8-1.1c0.1-0.2,0.3-0.6,0.5-0.8c0.2-0.2,0.9-0.3,1.1-0.4
        c0.2-0.1,0.7-0.3,0.9-0.5c0.2-0.2,0.3-1,0.6-1.2c0.3-0.2,0.9,0,1.2,0c0.3,0,1,0,1.4-0.1c0.3-0.1,0.7-0.5,1-0.6
        c0.2-0.1,0.6-0.3,0.8-0.4c0.2,0,0.7,0.2,0.9,0.1c0.3-0.1,0.6-0.6,0.8-0.8c0.2-0.2,0.5-0.6,0.6-0.8c0.1-0.2,0.2-0.7,0.3-1
        c0.1,0,0.1,0,0.1,0c0.3,0,0.8-0.5,1.2-0.5c0.3,0,0.7,0.5,1,0.4c0.4,0,0.9-0.7,1.2-0.9c0.3-0.2,1-0.3,1.3-0.4
        c0.3-0.1,0.9-0.7,1.3-0.6c0.3,0,0.7,0.5,0.9,0.6c0.3,0.1,0.9,0.3,1.2,0.3c0.3,0,0.7-0.4,1-0.4c0.4-0.1,1.3,0.2,1.7,0
        c0.2-0.1,0.5-0.5,0.7-0.6c0.3-0.2,1-0.5,1.3-0.4c0.3,0.1,0.8,0.5,0.9,0.8c0.2,0.3-0.1,1.1,0.2,1.3c0,0,0,0,0.1,0l1,4.8l2.6,9.2
        l2.4-1.6c0,0.1,0.1,0.1,0.1,0.2c0.1,0.3,0.1,1,0,1.3c-0.1,0.3-0.6,0.8-0.8,1c-0.2,0.2-0.7,0.6-0.8,0.8c-0.2,0.4-0.2,1.2-0.1,1.6
        c0,0.1,0.1,0.4,0.3,0.5c0.1,0,0.2,0,0.4,0c0.6,1.3,2.7,6.5,3.6,8.3c1,2.1,2.9,6.5,4.1,8.5c0.7,1.2,3,4.1,3.7,5
        c-0.2-0.1-0.7-0.1-0.9-0.2c-0.4-0.2-1-1.2-1.4-1.4c-0.2-0.1-0.7-0.1-0.9,0c-0.2,0.1-0.4,0.5-0.6,0.5c-0.2,0.1-0.7-0.1-0.9,0
        c-0.2,0-0.6,0.3-0.7,0.4c-0.1,0.2-0.2,0.6-0.4,0.8c-0.1,0.1-0.5,0.1-0.6,0.1c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.1,0-0.5-0.2-0.6
        c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1,0.1,0.5,0,0.6c-0.1,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.3-0.4-0.5-0.5c-0.2-0.1-0.6,0.1-0.7,0
        c-0.1-0.1-0.1-0.4-0.1-0.5c-0.2-0.2-0.6-0.3-0.9-0.3c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.6-0.5,0.8c-0.1,0.2,0.1,0.6,0,0.8
        c-0.2,0.2-0.8,0.3-1.1,0.5c-0.2,0.1-0.8,0.3-0.9,0.5c-0.1,0.2,0,0.5-0.1,0.7c-0.1,0.3-0.7,1.2-0.7,1.3c0,0.1,0.3,0.6,0.5,0.7
        c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.1-0.9,0-1.2,0c-0.3-0.1-1-0.6-1.3-0.5c-0.2,0.1,0,1-0.2,1.1c-0.2,0.1-0.4-0.2-0.6-0.4
        c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1,0.4-0.1,0.5c-0.1,0.2-0.2,0.6-0.4,0.7c-0.1,0-0.4-0.2-0.5-0.2c-0.3-0.1-0.9-0.1-1.1,0
        c-0.1,0.1-0.3,0.4-0.4,0.4c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.2-0.2-0.3-0.8-0.4-1c-0.1-0.2-0.1-0.7-0.3-0.8
        c-0.3-0.1-1,0.2-1.3,0.4c-0.2,0.2-0.4,0.9-0.7,1c-0.3,0.1-0.9-0.3-1.2-0.5c-0.1-0.1-0.3-0.3-0.5-0.3c-0.4-0.1-1.3,0.1-1.8,0
        c-0.4-0.1-1-0.4-1.3-0.5c-0.3-0.2-1-0.6-1.3-0.9c-0.2-0.2-0.4-0.7-0.6-0.9c-0.2-0.2-0.8-0.3-1.1-0.5c-0.4-0.2-1-0.9-1.3-1.2
        c-0.2-0.2-0.4-0.7-0.6-0.8c-0.2-0.1-0.8-0.2-1.1-0.1c-0.3,0.1-0.7,0.5-0.9,0.5c-0.3,0.1-0.8-0.2-1.1-0.1c-0.3,0.1-0.8,0.7-1.1,0.9
        c-0.3,0.2-1,0.6-1.4,0.7c-0.3,0.1-0.9-0.1-1.2,0c-0.2,0.1-0.3,0.6-0.4,0.8c-0.1,0.2,0.1,0.8-0.1,0.9c-0.1,0.1-0.6,0.1-0.7,0
        c-0.3-0.2-0.5-0.9-0.7-1.2c-0.3-0.6-0.7-1.7-0.9-2.3c-0.5-1.1-1.5-3.1-2-4.1c-0.4-0.9-1.2-2.6-1.6-3.4c-0.3-0.6-0.9-1.9-1.2-2.5
        C301.2,171.4,301.2,171.4,301.2,171.4z"/>
    </g>
    <g id="Lincoln">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="lincoln-county" ref="lincoln-county" class="st10" d="M372.1,171.9c0.1,0.1,0.3,0.4,0.3,0.6c0,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.6-0.2-0.8-0.2
            c-0.2,0-0.5,0.1-0.6,0.2c-0.1,0.1-0.1,0.6-0.1,0.7c0,0.1,0.4,0.4,0.6,0.5c0.2,0.1,0.7-0.3,0.9-0.1c0.3,0.2,0.1,1.1-0.1,1.4
            c-0.1,0.2-0.7,0.4-0.9,0.5c-0.3,0.1-1.1,0.5-1.4,0.4c-0.1,0-0.3-0.3-0.3-0.4c-0.1-0.3,0.1-0.9-0.1-1.1c-0.1-0.1-0.4-0.3-0.6-0.2
            c-0.1,0.1-0.1,0.5-0.2,0.6c-0.1,0.1-0.4,0.3-0.5,0.3c-0.2,0-0.6-0.5-0.7-0.7c-0.1-0.2,0-0.7-0.1-0.9c-0.1-0.2-0.6-0.6-0.9-0.7
            c-0.3,0-0.8,0.4-1.1,0.3c-0.3,0-1-0.7-1.1-0.7c-0.1,0-0.4-0.7-0.4-0.8c0-0.1-0.4-0.6-0.6-0.6c-0.2,0-0.4,0.4-0.5,0.5
            c-0.2,0.2-0.6,0.5-0.6,0.8c0,0.2,0.4,0.6,0.6,0.7c0.3,0.2,1,0.3,1.2,0.6c0.1,0.2,0.3,0.8,0.2,0.9c-0.2,0.2-0.9-0.1-1.1,0
            c-0.2,0.1-0.3,0.5-0.4,0.6c-0.3,0.1-1-0.3-1.4-0.2c-0.2,0.1-0.5,0.5-0.6,0.5c-0.1,0-0.5-0.3-0.7-0.3c-0.3-0.1-0.8,0-1.1-0.1
            c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.5-0.5-0.7-0.6c-0.1,0-0.5,0-0.6,0.1c-0.2,0.1-0.4,0.5-0.5,0.8c0,0.1,0.1,0.3,0.2,0.4
            c0.1,0.1,0.5,0.3,0.7,0.4c0.2,0.1,0.7,0,0.9,0.1c0.3,0.1,0.7,0.5,0.8,0.8c0.1,0.2,0.5,0.6,0.4,0.7c-0.2,0.3-1-0.1-1.4,0
            c-0.2,0.1-0.5,0.5-0.7,0.5c-0.3,0.1-1-0.1-1.4,0c-0.3,0.1-0.6,0.5-0.8,0.7c-0.2,0.1-0.5,0.5-0.7,0.5c-0.3,0-0.7-0.4-1-0.5
            c-0.2,0-0.5-0.1-0.6,0c-0.1,0.1-0.2,0.6-0.1,0.7c0.1,0.1,0.5,0.2,0.5,0.4c0.1,0.2,0,0.7-0.1,0.9c-0.2,0.2-0.8,0.3-1.1,0.3
            c-0.2,0-0.6,0-0.8,0.1c-0.2,0.1-0.3,0.6-0.4,0.8c-0.2,0.2-0.7,0.5-0.9,0.5c-0.2,0-0.6-0.2-0.8-0.3c-0.2-0.1-0.6-0.2-0.8-0.3
            c0,0,0,0,0,0c-0.7-0.8-3-3.8-3.7-5c-1.2-2-3.2-6.3-4.1-8.5c-0.9-1.9-3-7-3.6-8.3c0.2,0,0.4-0.1,0.5-0.2c0.2-0.1,0.6-0.5,0.8-0.7
            c0.2-0.2,0.6-0.6,0.7-0.8c0.1-0.2,0.3-0.7,0.4-1c0.1-0.3,0.5-1,0.5-1.4c0-0.4-0.6-1.1-0.8-1.4c-0.1-0.3-0.3-0.8-0.3-1.1
            c0-0.3,0.1-0.8,0.2-1c0.2-0.2,0.7-0.5,1-0.6c0.2-0.1,0.8,0,1-0.1c0.2-0.2,0.2-1,0.2-0.9c0,0.1,0.5-0.2,0.7-0.2
            c0.2,0.1,0.2,0.8,0.2,0.8c0,0,0.6,0.4,0.8,0.4c0.2,0,0.5-0.4,0.6-0.6c0.1-0.2,0.3-0.6,0.3-0.8c0-0.2-0.4-0.6-0.4-0.9
            c0-0.2,0.4-0.6,0.6-0.7c0.1-0.2,0.4-0.5,0.6-0.6c0.1-0.1,0.5-0.1,0.6,0c0.2,0.2,0,0.9,0.1,1.1c0.2,0.2,0.8,0.2,1,0.4
            c0.2,0.2,0.4,0.8,0.6,1c0.2,0.2,0.7,0.5,0.9,0.6c0.2,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.6,0.7,0.7,1.1c0,0.2,0,0.5-0.1,0.6
            c-0.3,0.2-0.9-0.4-1.2-0.3c-0.3,0.1-0.7,0.5-0.9,0.7c-0.2,0.2-0.6,0.6-0.6,0.9c0,0.3,0.1,1,0.4,1.2c0.2,0.1,0.7,0.2,0.9,0.1
            c0.3-0.1,0.6-0.7,0.7-1c0.1-0.2,0.3-0.5,0.4-0.6c0.2-0.2,0.8-0.3,1-0.3c0.3,0,0.7,0.5,1,0.5c0.2,0,0.6,0,0.8,0
            c0.2,0,0.6,0.2,0.7,0.4c0.2,0.3,0.1,1.1,0.4,1.4c0.1,0.2,0.6,0.3,0.8,0.4c0.3,0.1,0.9,0,1.2,0c0.2,0,0.7-0.2,0.9-0.1
            c0.3,0,0.8,0.3,1,0.5c0.2,0.2,0.3,0.9,0.6,1c0.2,0.1,0.8-0.1,1,0c0.3,0.1,0.8,0.4,0.9,0.7c0.1,0.3-0.3,0.8-0.5,0.9
            c-0.3,0.2-1.1-0.1-1.5-0.2c-0.3,0-0.9-0.3-1.2-0.2c-0.3,0.1-0.6,0.9-0.9,0.8c-0.3-0.1-0.5-0.9-0.7-1.2c-0.2-0.2-0.5-0.7-0.7-0.8
            c-0.3-0.1-0.9-0.1-1.2,0c-0.4,0-1.1,0.3-1.5,0.4c-0.4,0.1-1.4,0.2-1.8,0.4c-0.3,0.2-1,1-0.8,1.3c0.1,0.3,1,0.3,1.3,0.2
            c0.2-0.1,0.4-0.4,0.6-0.5c0.4-0.1,1.2,0,1.5,0.1c0.3,0.1,0.7,0.5,1,0.5c0.2,0,0.5-0.2,0.6-0.2c0.3,0,1.1,0.2,1.2,0.5
            c0.1,0.2-0.1,0.7-0.3,0.9c-0.2,0.1-0.7,0-0.9,0.1c-0.2,0.1-0.6,0.5-0.6,0.7c0,0.2,0.5,0.6,0.8,0.6c0.2,0,0.3-0.6,0.5-0.7
            c0.3-0.2,0.9-0.2,1.2-0.3c0.2-0.1,0.8-0.3,0.9-0.4c0.1-0.1,0.4-0.6,0.6-0.7c0.1,0,0.3,0.1,0.4,0.2c0.2,0.1,0.6,0.2,0.7,0.2
            c0.2,0,0.4-0.5,0.5-0.5c0.2,0,0.6,0.3,0.9,0.3c0.3,0,0.7-0.5,0.9-0.7c0.1-0.2,0.1-0.7,0.3-0.8c0.2-0.1,0.9,0.2,1,0.4
            c0.2,0.2-0.1,0.9,0.1,1.1c0.1,0.1,0.7,0,0.8,0.1c0.1,0.1,0.4,0.5,0.4,0.6c-0.1,0.2-0.8,0.2-1,0.2c-0.3,0-1-0.4-1.3-0.2
            c-0.2,0.1-0.2,0.6-0.4,0.7c-0.2,0.1-0.8,0-1,0.2c-0.1,0.2-0.1,0.7-0.1,0.9c0.1,0.2,0.4,0.6,0.6,0.6c0.2,0,0.3-0.4,0.4-0.5
            c0.1-0.1,0.4-0.3,0.6-0.2c0.2,0.1,0.1,0.6,0.3,0.6c0.2,0,0.4-0.3,0.5-0.4c0.2-0.2,0.4-0.6,0.6-0.7c0.2-0.1,0.5,0.1,0.7,0.1
            c0.2,0,0.5-0.4,0.7-0.4c0.2,0,0.4,0.3,0.5,0.5c0.1,0.2-0.2,0.6-0.1,0.7c0.1,0.2,0.6,0.3,0.9,0.2c0.1,0,0.3-0.3,0.5-0.3
            c0.2,0.1,0.2,0.6,0.3,0.7c0.1,0.1,0.5,0.1,0.6,0.2c0.2,0.2,0.5,0.8,0.3,1c-0.2,0.2-0.9-0.1-1.2,0.1c-0.1,0.1-0.1,0.4-0.2,0.5
            c-0.3,0.1-0.9-0.5-1.2-0.4c-0.2,0.1-0.6,0.5-0.6,0.7c0,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.7-0.1,0.9-0.1c0.1,0,0.3,0.3,0.5,0.4
            c0.2,0.1,0.7,0.1,0.8,0c0.1-0.1,0-0.5,0.1-0.6c0.1-0.1,0.4-0.3,0.6-0.3c0.3,0,0.8,0.5,1.1,0.6c0.2,0,0.5-0.1,0.7,0
            c0.3,0.1,0.7,0.5,0.9,0.7c0.2,0.2,0.6,0.8,0.5,1.1c0,0.2-0.5,0.5-0.5,0.8c0,0.2,0.4,0.4,0.5,0.6c0.1,0.2,0.1,0.6,0,0.7
            c-0.1,0.2-0.6,0.2-0.6,0.3c0,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.5,0.1,0.6,0.1c0.2,0,0.6-0.4,0.9-0.4
            C371.4,171.3,371.9,171.7,372.1,171.9z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="lincoln-county" ref="lincoln-county" class="st10" d="M343.6,146.6c0.1,0.1,0.3,0.4,0.3,0.6c-0.1,0.2-0.6,0.4-0.8,0.5c-0.2,0.2-0.4,0.8-0.6,0.9
            c-0.1,0.1-0.5,0-0.6,0.1c-0.2,0.1-0.2,0.6-0.3,0.7c-0.1,0.1-0.4,0.1-0.6,0c-0.2-0.1-0.5-0.4-0.7-0.5c-0.1,0-0.4,0-0.5,0.1
            c-0.2,0.2,0,0.8-0.1,1c-0.2,0.3-0.9,0.5-1.2,0.7c-0.3,0.2-0.8,0.8-0.9,1.1c-0.1,0.5-0.1,1.4,0.1,1.9c0.1,0.2,0.3,0.5,0.5,0.7
            l-2.4,1.6l-2.6-9.2l-1-4.8c0.2,0.1,0.7,0,0.9-0.1c0.2,0,0.6,0.1,0.9,0c0.1,0.1,0.3,0.2,0.4,0.3c0.3,0.2,1,0.3,1.3,0.2
            c0.2,0,0.5-0.3,0.7-0.2c0.3,0.1,0.5,0.8,0.7,0.9c0.2,0.2,0.9,0.2,1.1,0.4c0.1,0.2,0.1,0.6,0,0.8c-0.1,0.2-0.4,0.5-0.6,0.5
            c-0.3,0.1-0.9-0.2-1.2-0.2c-0.4,0-1.1,0.2-1.4,0.4c-0.1,0.1-0.4,0.5-0.3,0.7c0.1,0.2,0.7,0.1,0.9,0c0.2,0,0.5-0.3,0.7-0.4
            c0.2,0,0.7,0,0.9,0.1c0.1,0.1,0.4,0.2,0.4,0.3c0,0.3-0.7,0.5-0.9,0.6c-0.2,0.1-0.9-0.1-1.1,0.1c-0.1,0.1,0.1,0.5,0.2,0.7
            c0.2,0.2,1.1,0.3,1.3,0.3c0.1,0,0.5-0.6,0.8-0.6c0.3,0,0.7,0.6,0.8,0.6c0.1,0,0-0.6,0-0.8c0-0.3,0.2-0.9,0.4-1.2
            c0.2-0.2,0.7-0.4,0.9-0.3c0.3,0.1,0.7,0.8,0.9,0.9c0.1,0.1,0.6,0.7,0.9,0.7c0.2,0,0.5-0.4,0.7-0.4
            C342.9,146.7,343.4,146.5,343.6,146.6z"/>
    </g>
    <g id="Wheeler">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="wheeler-county" ref="wheeler-county" class="st10" d="M301.9,367c-0.2-0.1-0.5-0.2-0.6-0.3c-0.3-0.2-0.6-0.7-0.8-1c0,0,0-0.1,0-0.1l2.1-1.9l0.9,0.8
        l-0.8,0.7l0.6,0.5l5.5-5.8l-1.2-1.3l0.8-0.9l0.6,0.6l0.6-0.6l0.9,0.4l13.3-13.5c0.3,0.1,0.8,0,1,0.1c0.2,0,0.6,0.2,0.8,0.3
        c0.3,0.2,0.6,1,1,1.1c0.3,0.1,0.9-0.1,1.2,0c0.4,0.1,1.5,0.6,1.5,0.7c0,0.1,0.3-1.3,0.7-1.5c0.2-0.1,0.5,0.1,0.8,0.3
        c0.2,0.2,0.5,0.4,0.6,0.5c0.2,0.2,0.5,0.5,0.5,0.7c0.1,0.3-0.3,1-0.1,1.3c0.1,0.2,0.6,0.4,0.7,0.6c0.1,0.2,0.3,0.8,0.3,1
        c0,0.1-0.5,0.8-0.6,1.1c-0.2,0.4-0.9,1.2-0.7,1.7c0.1,0.2,0.7,0.3,0.8,0.5c0.3,0.3,0.8,0.9,0.9,1.3c0.1,0.3-0.4,0.9-0.3,1.2
        c0,0.2,0.5,0.5,0.6,0.7c0.1,0.2,0.3,0.7,0.3,1c0,0.2-0.1,0.7-0.1,0.9c-0.1,0.3-0.5,1-0.5,1.4c0,0.5,0.4,1.6,0.7,2
        c0.2,0.2,0.8,0.5,1,0.7c0.2,0.3,0.4,1,0.4,1.4c0,0.3-0.5,0.6-0.6,0.9c-0.1,0.2-0.2,0.8-0.4,1c-0.2,0.3-0.9,0.5-0.9,0.8
        c0,0.3,0.5,0.9,0.5,1c0,0.1,0.8,0.7,0.9,1.1c0.1,0.3-0.1,0.9,0,1.1c0.1,0.4,0.7,0.9,0.7,1.3c0,0.3-0.2,1-0.4,1.2
        c-0.1,0.2-0.6,0.4-0.6,0.7c0,0.4,1,0.9,1.1,1c0,0.1-0.2,1-0.2,1.4c0,0.2,0.1,0.7,0.2,1c0.2,0.3,0.8,0.5,0.9,0.8
        c0.1,0.4-0.5,1.1-0.4,1.4c0.1,0.2,0.7,0.4,0.7,0.6c0.1,0.2-0.3,0.6-0.3,0.8c0,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.7,0.4,0.9
        c0.3,0.3,1,0.6,1.4,0.8c0.2,0.1,0.5,0.3,0.6,0.4c0.2,0.2,0.4,0.7,0.7,0.9c0.2,0.1,0.7,0.1,0.9,0.2c0.2,0.2,0.4,0.7,0.6,0.9
        c0.2,0.1,0.6,0.2,0.7,0.4c0.3,0.3,0.2,1.3,0.3,1.7c0.1,0.3,0.5,0.7,0.7,1c-0.4,0.2-1,0.5-1.3,0.4c-0.3,0-0.9-0.6-1.2-0.6
        c-0.3,0-1,0.1-1.2,0.3c-0.2,0.2-0.4,0.9-0.6,1.1c-0.2,0.3-0.9,0.8-1.1,1.1c-0.1,0.2-0.1,0.8-0.4,0.9c-0.3,0.1-0.7-0.7-1-0.7
        c-0.3,0-1,0.3-1.2,0.5c-0.2,0.1-0.3,0.6-0.5,0.7c-0.5,0.3-1.7,0-2.2,0.2c-0.2,0.1-0.5,0.4-0.7,0.4c-0.2,0.1-0.5,0.1-0.8,0.1
        c0-0.4,0.1-0.9-0.1-1.2c-0.2-0.3-0.9-0.3-1.2-0.5c-0.4-0.2-0.9-0.9-1.2-1.2c-0.4-0.3-1.2-0.7-1.5-1c-0.2-0.2-0.3-0.8-0.4-1.1
        c-0.1-0.3-0.3-0.8-0.5-1c-0.1-0.1-0.6-0.2-0.7-0.4c-0.2-0.2-0.3-0.8-0.4-1c-0.2-0.3-0.6-0.8-0.7-1.2c-0.1-0.3,0.1-0.8,0-1.1
        c-0.1-0.3-0.7-0.6-0.9-0.8c-0.3-0.2-0.8-0.4-1.1-0.5c-0.3-0.1-1,0.1-1.3,0.1c-0.4,0-1.4,0-1.8-0.3c-0.1-0.1-0.1-0.6-0.2-0.7
        c-0.2-0.2-0.9-0.2-1.1-0.4c-0.2-0.2-0.4-0.7-0.5-0.9c-0.1-0.2-0.3-0.5-0.4-0.7c-0.3-0.4-1.1-1.1-1.5-1.5c-0.3-0.3-0.7-1.1-1-1.3
        c-0.3-0.2-1.1-0.4-1.5-0.5c-0.4,0-1.1,0.2-1.5,0.1c-0.3-0.1-0.7-0.5-0.9-0.7c-0.3-0.3-0.8-1.1-0.9-1.1s-0.9-0.1-1.1-0.1
        c-0.4-0.1-1.2-0.4-1.4-0.7c-0.2-0.2-0.4-0.9-0.6-1.2c-0.1-0.2-0.5-0.5-0.6-0.8c-0.2-0.4-0.1-1.4-0.2-1.8c-0.1-0.2-0.3-0.6-0.4-0.8
        c-0.2-0.3-1-0.9-1.2-0.9s-1.1,0.1-1.5,0.1C302.4,367.1,302.1,367.1,301.9,367z"/>
    </g>
    <g id="Elbert">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="elbert-county" ref="elbert-county" class="st10" d="M288.3,111.9c0.2-0.2,0.5-0.3,0.7-0.4c0.3-0.1,1,0,1.3-0.1c0.3-0.1,0.9-0.4,1.2-0.6
        c0.2-0.1,0.6-0.2,0.8-0.2c0.4-0.1,1.3-0.2,1.7,0c0.2,0.1,0.4,0.4,0.6,0.5c0.3,0.1,0.9-0.1,1.2,0c0.1,0,0.4,0.1,0.5,0.3
        c0.1,0.2-0.4,0.5-0.4,0.7c0,0.2,0.2,0.5,0.3,0.6c0.2,0.2,0.6,0.4,0.8,0.4c0.2,0,0.7-0.3,0.9-0.3c0.4,0,1.1,0.1,1.4,0.1
        c0.3,0,1,0.1,1.3-0.1c0.3-0.2,0.6-1,0.8-1.3c0.1-0.2,0.4-0.6,0.6-0.7c0.2-0.2,0.8-0.4,1-0.6c0.2-0.2,0.6-0.7,0.8-0.9
        c0.2-0.1,0.5-0.3,0.7-0.4c0.4-0.1,1.2-0.1,1.6-0.3c0.3-0.1,0.8-0.4,1.1-0.5c0.3-0.1,1-0.4,1.4-0.5c0.5-0.1,1.5-0.1,1.9-0.2
        c0.3-0.1,0.8-0.6,1.1-0.8c0.3-0.1,0.9-0.3,1.3-0.4c0.3-0.1,0.9-0.2,1.2-0.3c0.2-0.1,0.4-0.5,0.6-0.6c0.4-0.2,1.3,0.1,1.7-0.1
        c0.2-0.1,0.4-0.6,0.6-0.7c0.2-0.1,0.8-0.2,1-0.3c0.2-0.1,0.5-0.2,0.8-0.4c0.5,0.4,1.4,0.7,1.8,1.1c0.3,0.4,0.8,1.2,0.9,1.7
        c0.2,0.6,0.4,1.9,0.4,2.5c0,0.5-0.2,1.5-0.4,1.9c-0.1,0.2-0.5,0.5-0.6,0.7c-0.1,0.3-0.1,0.9,0,1.2c0.1,0.2,0.5,0.5,0.6,0.7
        c0.2,0.2,0.6,0.8,0.5,1.1c-0.1,0.2-0.6,0.4-0.8,0.4c-0.3,0-0.8-0.4-1-0.6c-0.2-0.1-0.5-0.5-0.7-0.6c-0.3-0.1-0.9-0.2-1.2-0.1
        c-0.2,0-0.3,0.3-0.3,0.5c0,0.3,0.3,0.9,0.5,1.1c0.2,0.1,0.7,0.1,0.9,0.3c0.2,0.1,0.3,0.6,0.4,0.7c0.2,0.1,0.6-0.1,0.7-0.1
        c0.4,0.1,0.8,0.8,1.1,1.1c0.2,0.2,0.8,0.4,1,0.6c0.2,0.3,0.5,1,0.6,1.4c0,0.2,0,0.5-0.1,0.6c-0.1,0.1-0.2,0.1-0.3,0
        c-0.3-0.1-0.5-0.9-0.8-1.1c-0.1-0.1-0.5-0.3-0.7-0.3c-0.2,0-0.6,0-0.7,0.2c-0.1,0.2,0.2,0.6,0.3,0.8c0.1,0.1,0.5,0.2,0.6,0.3
        c0.1,0.1,0.1,0.5,0.1,0.6c0,0.1-0.8-0.1-0.9,0.1c-0.1,0.2,0.5,0.5,0.7,0.6c0.3,0.1,0.8,0,1.1,0c0.2,0.1,0.6,0.3,0.7,0.6
        c0.1,0.2-0.3,0.7-0.4,0.9c-0.1,0.1-0.6,0.1-0.6,0.2c-0.1,0.2,0.3,0.6,0.5,0.7c0.2,0.1,0.6,0.1,0.8,0.1c0.2,0.1,0.5,0.5,0.6,0.4
        c0.2-0.1,0-0.5,0-0.7c0.1-0.2,0.5-0.6,0.7-0.7c0.3-0.1,0.9,0.1,1.2,0.2c0.4,0.1,1.1,0.3,1.4,0.5c0.3,0.2,0.9,0.8,1.1,1.2
        c0.2,0.3,0,1.1,0.2,1.4c0.1,0.2,0.6,0.5,0.8,0.6c0.1,0.1,0.5,0.2,0.6,0.3c0.2,0.1,0.7,0.5,0.7,0.7c0,0.2,0,0.6-0.2,0.7
        c-0.2,0.2-0.7,0.2-0.9,0.2c-0.2-0.1-0.6-0.4-0.7-0.6c-0.2-0.2-0.5-0.7-0.7-0.9c-0.2-0.2-0.9-0.5-1.1-0.4c-0.2,0.1-0.4,0.4-0.4,0.6
        c0.1,0.3,0.7,0.4,0.9,0.7c0.1,0.2,0.2,0.6,0.1,0.7c-0.3,0.3-1.3-0.1-1.6-0.4c-0.2-0.2-0.1-0.9-0.4-1.1c-0.1-0.1-0.6,0-0.7,0.1
        c-0.1,0.1-0.1,0.5-0.2,0.6c-0.1,0.1-0.4,0-0.5,0c-0.2-0.2-0.1-0.8-0.2-1c0-0.1-0.1-0.5-0.2-0.6c-0.2-0.1-0.9,0.3-1,0.3
        c-0.1,0-0.9-0.3-1.2-0.5c-0.2-0.1-0.4-0.5-0.5-0.6c-0.1-0.1-0.4-0.4-0.6-0.5c-0.2-0.1-0.7-0.2-0.8-0.4c-0.1-0.2-0.1-0.7-0.2-0.8
        c-0.3-0.3-1.2-0.2-1.6-0.2c-0.3,0-0.9,0-1.2,0.1c-0.4,0.1-1.3,0.4-1.4,0.8c0,0.2,0.3,0.5,0.4,0.5c0.4,0.2,1.2,0.2,1.6,0.1
        c0.2,0,0.5-0.3,0.6-0.3c0.3,0,1,0.2,1.3,0.4c0.4,0.2,0.9,1.1,1.2,1.3c0.2,0.1,0.8,0.1,1,0.3c0.2,0.1,0.4,0.4,0.5,0.6
        c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.2,0.6,0.3,0.9,0.4c0.4,0.2,1.3,0.3,1.5,0.6c0.1,0.1,0.1,0.5,0,0.6c-0.2,0.2-0.9-0.2-1,0
        c-0.1,0.1,0,0.5,0.1,0.6c0.2,0.2,0.7,0.3,0.9,0.2c0.2,0,0.5-0.4,0.7-0.5c0.2-0.1,0.7,0.1,0.9,0c0.1-0.1,0.2-0.3,0.4-0.4
        c0.4-0.1,1.2,0.1,1.6,0.1c0.3,0.1,0.9,0.3,1.2,0.4c0.3,0.1,1,0.1,1.3,0.2c0.2,0.1,0.5,0.6,0.8,0.8c0.2,0.2,0.8,0.3,1,0.4
        c0.3,0.3,0.5,1.3,0.6,1.3c0,0.1,0.9,0.7,1,0.8c0.1,0,0.6,0.3,0.7,0.4c0.2,0.2,0.6,0.8,0.7,1.1c0.1,0.3,0.4,0.9,0.4,1.2
        c0,0.2-0.3,0.6-0.3,0.8c0.1,0.3,0.8,0.5,0.9,0.7c0.2,0.3,0.3,0.9,0.3,1.2c0,0.4-0.3,1.1-0.1,1.4c0.1,0.2,0.7,0.4,0.9,0.5
        c0.2,0.1,0.8,0.3,0.9,0.5c0.1,0.2,0.2,0.7,0,0.8c-0.1,0.1-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.3-0.6-0.4c-0.1-0.1-0.3-0.3-0.4-0.4
        c-0.2-0.1-0.5,0-0.7,0c-0.2,0-0.5-0.3-0.7-0.3c-0.3,0-0.9,0-1.2,0.1c-0.2,0.1-0.7,0.2-0.7,0.4c0,0.1,0.1,0.2,0.1,0.2
        c-0.3,0-0.7-0.1-0.9,0c-0.2,0-0.7,0.1-0.9,0.1c0,0,0,0-0.1,0c-0.2-0.2,0-1-0.2-1.3c-0.1-0.3-0.6-0.8-0.9-0.8
        c-0.3-0.1-1,0.2-1.3,0.4c-0.2,0.1-0.5,0.5-0.7,0.6c-0.4,0.1-1.3-0.1-1.7,0c-0.3,0.1-0.7,0.4-1,0.4c-0.3,0-0.9-0.2-1.2-0.3
        c-0.3-0.1-0.6-0.6-0.9-0.6c-0.4,0-0.9,0.5-1.3,0.6c-0.3,0.1-1,0.3-1.3,0.4c-0.3,0.2-0.8,0.8-1.2,0.9c-0.3,0-0.7-0.4-1-0.4
        c-0.3,0-0.8,0.5-1.2,0.5c0,0-0.1,0-0.1,0c-0.3-0.1-0.7-0.3-0.9-0.4c-0.2-0.1-0.6-0.5-0.8-0.6c-0.4-0.1-1.1,0.2-1.5,0
        c-0.2-0.1-0.4-0.7-0.6-0.9c-0.1-0.1-0.5-0.3-0.7-0.4c-0.2-0.1-0.7,0.1-0.9,0c-0.2-0.1-0.3-0.5-0.5-0.5c-0.2,0-0.5,0.4-0.6,0.6
        c-0.1,0.2,0.1,0.7-0.1,0.9c-0.1,0.1-0.5,0-0.6,0c-0.4,0-1.3,0-1.4-0.1c-0.1-0.1-0.3-1-0.6-1.2c-0.1-0.1-0.5-0.2-0.7-0.3
        c-0.2-0.2-0.5-0.7-0.7-0.8c-0.3-0.1-0.9,0-1.2,0c-0.2,0-0.7-0.2-0.9-0.4c-0.2-0.2-0.1-0.8-0.3-0.9c-0.2-0.2-0.9-0.1-1.1,0
        c-0.2,0.1-0.4,0.6-0.6,0.7c-0.1,0-0.3-0.1-0.5-0.2c-0.3-0.2-0.8-0.5-1-0.8c-0.1-0.1-0.5-0.4-0.5-0.6c0-0.2,0.6-0.4,0.6-0.7
        c0-0.3-0.5-0.8-0.7-1c-0.2-0.2-0.9-0.2-1.1-0.3c-0.2-0.1-0.6-0.5-0.8-0.6c-0.3-0.2-0.9-0.4-1.2-0.6c0,0-0.1,0-0.1-0.1
        c-0.2-0.2-0.7-0.5-0.8-0.7c-0.2-0.3-0.4-1.2-0.6-1.5c-0.1-0.2-0.5-0.3-0.7-0.5c-0.3-0.3-0.5-1-0.8-1.3c-0.2-0.1-0.7-0.1-0.9-0.2
        c-0.3-0.2-0.7-0.7-0.9-1c-0.1-0.2-0.3-0.5-0.4-0.7c-0.2-0.2-0.7-0.4-0.7-0.7c0-0.3,0.5-0.7,0.5-1c0-0.3-0.2-0.9-0.4-1.1
        c-0.2-0.2-0.9-0.1-1.1-0.2c-0.2-0.1-0.5-0.5-0.6-0.7c-0.1-0.2,0-0.6,0-0.7c-0.1-0.2-0.3-0.7-0.5-0.8c-0.1-0.1-0.5-0.3-0.6-0.4
        c-0.1-0.2-0.2-0.6-0.3-0.8c-0.1-0.2-0.2-0.6-0.4-0.7c-0.1-0.1-0.5,0.1-0.6,0.1c-0.3,0-0.8-0.2-1-0.4c-0.2-0.2-0.3-0.9-0.5-1
        c-0.2-0.1-0.6,0.1-0.8,0c-0.2-0.1-0.6-0.6-0.6-0.9c0-0.2,0.1-0.5,0.2-0.6c0.1-0.2,0.5-0.5,0.6-0.7c0.1-0.3-0.1-0.8-0.1-1.1
        c0-0.3-0.1-0.9,0-1.2c0.1-0.2,0.5-0.5,0.6-0.6c0.2-0.2,0.6-0.6,0.8-0.8C287.9,112.7,288,112.1,288.3,111.9
        C288.2,111.9,288.2,111.9,288.3,111.9z"/>
    </g>
    <g id="Echols">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="echols-county" ref="echols-county" class="st10" d="M293.3,522.2h1.3c0,0,0.4-0.9,0.7-1.1c0.2-0.2,0.8-0.1,1.1-0.3c0.3-0.2,0.5-1.1,0.9-1.2
        c0.4-0.2,1.3,0.2,1.7,0.4c0,0,0.1,0,0.1,0c0.8,0.3,2.3,0.7,3,0.9c1,0.4,2.9,1.4,4,1.7c0.7,0.2,2.1,0.2,2.8,0.4
        c0.6,0.2,1.7,0.7,2.2,1.1c0.4,0.4,1,1.4,1.4,1.8c0.2,0.3,0.7,0.7,0.7,1c0.1,0.4-0.4,1-0.5,1.4c-0.1,0.5-0.1,1.7,0.2,2.1
        c0.3,0.5,1.7,0.5,2.1,0.9c0.4,0.4,0.6,1.4,0.7,1.9c0.1,0.3,0,0.8,0.2,1c0.3,0.5,1.1,1.3,1.6,1.6c0.7,0.4,2.3,0.8,3.1,0.9
        c0.6,0.1,1.7,0,2.2,0c0.7,0,2,0.1,2.6,0c0.4-0.1,1.2-0.5,1.6-0.4c0.6,0,1.6,0.7,2.1,0.9c0.6,0.3,1.9,0.8,2.6,0.9
        c0.9,0.2,2.8,0.1,3.7,0.4c0.7,0.3,2.2,1,2.7,1.6c0.2,0.3,0.5,1.1,0.5,1.4c0,0.5-0.6,1.4-0.6,1.8c0,0.6,0.5,1.8,0.6,2.4
        c0.1,1-0.1,2.9-0.2,3.8c0,0.6-0.2,1.7-0.2,2.2c0,0.4,0,1.2,0,1.9l-55.8-3.9l-0.1-2.8h1.6v-1.6h-1.5v-14.7l1-3h2.3v-3.4h1.5v-2.5
        H293.3z"/>
    </g>
    <g id="Coffee">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="coffee-county" ref="coffee-county" class="st10" d="M336.8,436.4V447l-3.4-0.1v13l-19.2-0.3c0,0-0.1-0.6-0.3-0.7c-0.2-0.2-0.8-0.5-0.9-0.7
        c-0.3-0.4-0.3-1.6-0.6-2.1c-0.1-0.2-0.7-0.7-0.7-0.7h-11.7v-2.1l-18.5-0.4c0,0,0-0.1,0-0.1c0-0.4,0.3-1.2,0.3-1.6
        c0-0.5,0-1.4-0.2-1.8c-0.2-0.4-1.2-0.8-1.2-1.2c0-0.4,0.7-0.9,0.8-1.3c0-0.1,0.1-0.4,0.1-0.6l2.5,0c0,0,0.8-1,1-1.3
        c0.3-0.5,0.7-1.6,1-2.2c0.2-0.4,0.5-1.2,0.7-1.5c0.2-0.4,1-1,1.2-1.4c0.3-0.6,0.7-1.9,0.9-2.6c0.2-0.6,0.9-1.6,1-2.2
        c0.2-0.8,0.2-2.4,0.2-3.3c0-0.5-0.6-1.7-0.2-2.1c0.2-0.2,0.7,0.1,0.9,0c0.3-0.1,0.8-0.3,0.9-0.5c0.1-0.2-0.2-0.9,0-1.2
        c0.2-0.2,0.7,0,0.9-0.2c0.3-0.2,0.3-1.1,0.6-1.3c0.2-0.1,0.7,0.1,0.8,0c0.2-0.1,0.5-0.7,0.5-0.9c0.1-0.3-0.1-0.9-0.1-1.2
        c0-0.2,0.1-0.7,0.2-0.8c0.2-0.1,0.6,0.1,0.7,0.1c0.2-0.1,0.5-0.3,0.6-0.5c0.1-0.2,0.2-1.1,0.2-1.1v-13c0.1-0.1,0.2-0.2,0.2-0.2
        c0.2-0.1,0.6,0,0.8-0.1c0.2-0.1,0.4-0.7,0.6-0.9c0.2-0.1,0.7-0.2,0.8-0.1c0.2,0.1,0.3,0.8,0.6,0.9c0.3,0.2,1,0.4,1.3,0.3
        c0.2-0.1,0.4-0.6,0.7-0.7c0.3-0.2,0.9-0.4,1.2-0.4c0.3,0,0.6,0.5,0.9,0.7c0.2,0.1,0.8,0.4,1,0.4c0.2,0,0.6-0.4,0.8-0.5
        c0.2-0.1,0.7-0.3,0.9-0.3c0.3,0.1,0.6,0.9,0.7,0.9c0.1,0,0.8,0,0.9,0c0.1,0,0.4-0.6,0.7-0.7c0.2-0.1,0.7,0.3,0.9,0.3
        c0.3,0,0.8-0.2,1-0.4c0.3-0.3,0.2-1.1,0.5-1.4c0.2-0.2,0.8-0.1,0.9-0.3c0.2-0.2,0-0.8,0.2-1c0.2-0.3,0.8-0.6,1.1-0.7
        c0,0,0.1,0,0.1-0.1v17.5h20.5v14H336.8z"/>
    </g>
    <g id="Atkinson">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="atkinson-county" ref="atkinson-county" class="st10" d="M298.8,481.8l-7.7,0c0,0,0.1-0.2,0-0.5c-0.1-0.3,0.1-1.1,0-1.5c-0.1-0.2-0.5-0.4-0.5-0.6
        c0-0.3,0.4-0.8,0.5-1.2c0.1-0.3,0-1-0.1-1.3c-0.1-0.2-0.4-0.4-0.4-0.6c-0.1-0.3,0-1,0.2-1.3c0.1-0.3,0.8-0.5,0.9-0.8
        c0.1-0.2,0.1-0.7,0.1-0.9c0-0.3-0.3-0.8-0.2-1c0-0.2,0.5-0.5,0.5-0.7c0-0.3-0.4-1-0.6-1.3c-0.1-0.3-0.5-0.8-0.7-1.1
        c-0.2-0.5-0.2-1.5-0.4-2c-0.1-0.3-0.6-0.6-0.7-0.9c-0.2-0.5,0.1-1.6,0-2.1c-0.1-0.4-0.4-1.2-0.7-1.5c-0.3-0.3-1.7-0.7-1.8-0.8
        c-0.1-0.1-0.9-0.4-1.2-0.7c-0.3-0.3-0.7-1.1-1-1.5c-0.1-0.2-0.5-0.4-0.6-0.6c-0.2-0.3-0.2-1.1-0.3-1.5c-0.1-0.3-0.1-1-0.4-1.3
        c-0.2-0.2-0.9-0.1-1.1-0.3c-0.3-0.3-0.6-1.1-0.7-1.4c-0.1-0.4-0.2-1.1-0.2-1.5l18.5,0.4v2.1h11.7c0,0,0.5,0.5,0.7,0.7
        c0.3,0.5,0.3,1.6,0.6,2.1c0.2,0.3,0.8,0.5,0.9,0.7c0.1,0.2,0.3,0.7,0.3,0.7l19.2,0.3v10.8l-3.6-1.6l-3.1,1.3v6.1l0.9,0.9l1.8,4.5
        L298.8,481.8z"/>
    </g>
    <g id="Laurens">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="laurens-county" ref="laurens-county" class="st10" d="M281.6,330.4l-8.5-19.2l2.9-1.7l24-13.3l0.3,0l9.8-0.9l6.8,3.8v2.9l12.7,5.9l-0.6,10.6l2.6,1.4
        c-0.3,0.2-0.6,0.5-0.6,0.7c0,0.2,0.5,0.4,0.6,0.6c0.1,0.2,0.3,0.6,0.5,0.8c0.1,0.1,0.6,0.2,0.7,0.3c0.1,0.2,0.2,0.7,0.2,0.9
        c-0.1,0.2-0.5,0.5-0.7,0.6c-0.3,0.4-0.5,1.3-0.8,1.6c-0.2,0.2-0.7,0.5-0.9,0.5c-0.5,0.2-1.6,0-2.1,0.1c-0.2,0-0.7,0.1-0.9,0.2
        c-0.3,0.2-0.4,1-0.6,1.3c-0.2,0.3-0.5,0.9-0.6,1.2c-0.1,0.3-0.1,0.9-0.1,1.2c0,0.5,0.4,1.6,0.4,2.1c-0.1,0.5-0.5,1.5-0.8,1.9
        c-0.2,0.2-0.7,0.5-0.9,0.7c-0.2,0.2-0.4,0.8-0.5,1.1c-0.1,0.3,0.1,0.9,0,1.2c-0.2,0.5-1.1,1-1.3,1.5c-0.1,0.2,0,0.6,0,0.8
        c0,0.4,0,1.2-0.2,1.6c-0.2,0.3-1.2,0.5-1.3,0.9c-0.1,0.4,0.7,1.1,0.9,1.5c0.2,0.4,0.6,1.2,0.9,1.4c0,0,0.1,0,0.1,0l-13.3,13.5
        l-0.9-0.4l-0.6,0.6l-0.6-0.6l-0.8,0.9l-0.2-0.2l-10.2,5.8l-15.5-33.8L281.6,330.4z"/>
    </g>
    <g id="Telfair">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="telfair-county" ref="telfair-county" class="st10" d="M277.3,400c-0.1-0.2-0.1-0.3-0.1-0.5c0.1-0.2,0.6-0.4,0.7-0.6c0.1-0.3,0.2-0.9,0.1-1.2
        c-0.1-0.2-0.5-0.6-0.7-0.6c-0.2,0-0.3,0.4-0.5,0.4c-0.3,0-0.9-0.3-1.2-0.5c-0.3-0.2-0.7-0.7-0.8-1.1c0-0.2,0.2-0.6,0.2-0.8
        c0-0.2-0.1-0.6-0.1-0.9l27-27.3c0.3,0.1,0.6,0.1,0.7,0.2c0.4,0,1.4-0.1,1.5-0.1s0.9,0.6,1.2,0.9c0.1,0.2,0.4,0.6,0.4,0.8
        c0.1,0.4,0,1.4,0.2,1.8c0.1,0.2,0.5,0.6,0.6,0.8c0.2,0.3,0.4,0.9,0.6,1.2c0.3,0.3,1.1,0.6,1.4,0.7c0.3,0.1,1,0.1,1.1,0.1
        s0.6,0.9,0.9,1.1c0.2,0.2,0.7,0.6,0.9,0.7c0.4,0.1,1.1-0.2,1.5-0.1c0.4,0,1.2,0.2,1.5,0.5c0.3,0.2,0.7,1,1,1.3
        c0.3,0.4,1.2,1.1,1.5,1.5c0.1,0.2,0.3,0.5,0.4,0.7c0.1,0.2,0.3,0.8,0.5,0.9c0.2,0.2,0.9,0.2,1.1,0.4c0.1,0.1,0.1,0.6,0.2,0.7
        c0.3,0.3,1.3,0.3,1.8,0.3c0.3,0,1-0.2,1.3-0.1c0.3,0,0.9,0.3,1.1,0.5c0.3,0.2,0.8,0.5,0.9,0.8c0.1,0.2-0.1,0.8,0,1.1
        c0.1,0.3,0.6,0.9,0.7,1.2c0.1,0.2,0.2,0.8,0.4,1c0.1,0.2,0.6,0.2,0.7,0.4c0.2,0.2,0.4,0.7,0.5,1c0.1,0.3,0.2,0.9,0.4,1.1
        c0.3,0.4,1.2,0.7,1.5,1c0.3,0.3,0.8,1,1.2,1.2c0.3,0.2,1,0.2,1.2,0.5c0.2,0.2,0.1,0.8,0.1,1.2c-0.3,0-0.6,0-0.7,0
        c-0.2,0-0.9,0-1.2,0c-0.2,0-0.6,0-0.7,0.1c-0.1,0.2,0.1,0.7,0,0.9c-0.1,0.2-0.7,0.4-0.9,0.5c-0.2,0.2-0.5,0.7-0.7,0.9
        c-0.3,0.2-1,0.3-1.2,0.6c-0.2,0.2-0.3,1-0.6,1.2c-0.2,0.2-0.7,0.3-0.9,0.4c-0.4,0.1-1.2,0.4-1.6,0.5c-0.3,0.1-1.1,0.2-1.3,0.5
        c-0.2,0.2,0.1,0.9,0,1.1c-0.1,0.3-0.3,0.9-0.6,1.1c-0.1,0.1-0.4-0.1-0.6-0.1c-0.4-0.1-1.2-0.4-1.6-0.2c-0.3,0.1-0.5,0.8-0.7,0.9
        c-0.2,0.1-0.6,0-0.7,0.2c-0.1,0.1-0.1,0.5-0.1,0.6c0,0.2,0.1,0.5,0.1,0.7c-0.1,0.3-0.4,0.8-0.7,0.9c-0.2,0.1-0.6,0.1-0.8,0.3
        c-0.2,0.2-0.2,0.7-0.3,0.9c-0.1,0.2-0.4,0.7-0.7,0.7c-0.2,0.1-0.7-0.1-1,0c-0.2,0-0.5,0.2-0.7,0.3c0,0-0.1,0-0.1,0.1
        c-0.3,0.2-0.9,0.5-1.1,0.7c-0.1,0.2,0,0.8-0.2,1c-0.2,0.2-0.8,0.2-0.9,0.3c-0.3,0.3-0.2,1.1-0.5,1.4c-0.2,0.2-0.7,0.4-1,0.4
        c-0.2,0-0.7-0.4-0.9-0.3c-0.2,0.1-0.6,0.7-0.7,0.7c-0.1,0-0.8,0-0.9,0c-0.1,0-0.4-0.8-0.7-0.9c-0.2-0.1-0.7,0.2-0.9,0.3
        c-0.2,0.1-0.6,0.5-0.8,0.5c-0.3,0-0.8-0.3-1-0.4c-0.2-0.1-0.6-0.6-0.9-0.7c-0.3-0.1-0.9,0.2-1.2,0.4c-0.2,0.1-0.4,0.7-0.7,0.7
        c-0.3,0.1-1-0.1-1.3-0.3c-0.2-0.2-0.3-0.8-0.6-0.9c-0.2-0.1-0.7,0-0.8,0.1c-0.2,0.1-0.4,0.7-0.6,0.9c-0.2,0.1-0.6,0-0.8,0.1
        c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.2-0.3,0.4-0.4,0.5c-0.2,0.1-0.7,0.3-0.9,0.3c-0.4,0-1-0.4-1.3-0.5c-0.3-0.1-0.8,0.1-1.1,0
        c-0.3-0.2-0.4-0.8-0.6-1.1c-0.1-0.2-0.3-0.5-0.5-0.6c-0.3-0.1-1.1,0.2-1.5,0.1c-0.2-0.1-0.4-0.4-0.6-0.6c-0.2-0.2-0.9-0.8-1-0.8
        c-0.1,0-1,0.1-1.2-0.1c-0.1-0.1,0.1-0.6-0.1-0.7c-0.2-0.1-0.6,0.4-0.7,0.3c-0.3-0.1-0.4-0.7-0.6-0.9c-0.2-0.2-0.6-0.6-0.9-0.6
        c-0.3,0-0.6,0.5-0.8,0.7c-0.1,0.1-0.2,0.6-0.4,0.6c-0.3,0.1-0.8-0.3-1-0.4c-0.2-0.1-0.6-0.5-0.7-0.7c-0.1-0.2,0.2-0.6,0.1-0.8
        c-0.1-0.2-0.6-0.3-0.8-0.3c-0.2,0-0.6,0.1-0.7,0c-0.3-0.1-0.4-0.8-0.7-1c-0.2-0.2-0.9-0.4-1.1-0.7c-0.2-0.2-0.3-0.7-0.5-0.9
        c-0.1-0.2-0.5-0.4-0.7-0.6C277.5,400.4,277.4,400.2,277.3,400z"/>
    </g>
    
    <g id="Taliaferro">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="taliaferro-county" ref="taliaferro-county" class="st11" d="
        M310,204.1c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.4-0.2,1.2-0.5,1.5c-0.2,0.2-0.7,0.4-0.9,0.5c-0.2,0.1-0.6,0-0.8,0.1
        c-0.3,0.1-0.6,0.6-0.9,0.7c-0.6,0.2-1.8,0-2.3-0.1c-0.3,0-0.9-0.3-1.2-0.4c-0.3,0-0.9,0.1-1.1,0c-0.2-0.1-0.3-0.7-0.5-0.8
        c-0.3-0.2-1.1-0.4-1.5-0.4c-0.3,0-0.8,0.3-1.1,0.3c-0.2,0-0.7,0-0.9,0c-0.3,0-1,0.3-1.3,0.1c-0.4-0.2-0.6-1-0.9-1.3
        c-0.3-0.3-1.2-0.7-1.4-1.1c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.4,1.2-1.1,1.6-1.4c0.2-0.2,0.8-0.9,0.8-0.9s0-1.9,0-2.5
        c0-0.5,0.2-1.4,0.1-1.9c-0.1-0.6-0.8-1.7-1.1-2.2c-0.2-0.5-0.7-1.4-0.9-1.9c-0.1-0.3-0.5-1-0.5-1.4c0-0.4,0.1-1.3,0.4-1.7
        c0.1-0.2,0.5-0.5,0.7-0.7s0.6-0.5,0.7-0.7c0.1-0.2,0.2-0.7,0.3-1c0-0.2,0-0.7-0.1-0.9c-0.1-0.2-0.5-0.2-0.6-0.4
        c-0.1-0.1-0.3-0.5-0.2-0.6c0-0.2,0.3-0.5,0.5-0.7c0.2-0.1,0.6-0.4,0.8-0.3c0.2,0.1,0.3,0.7,0.5,0.9c0.1,0.2,0.4,0.6,0.6,0.6
        c0.1,0,0.3-0.2,0.4-0.3c0.1-0.2,0-0.7,0-0.9c0.1-0.3,0.4-0.8,0.4-1.1c0-0.2-0.1-0.7-0.1-1c0-0.2,0.3-0.7,0.5-0.8
        c0.2-0.2,0.9-0.3,1.1-0.4c0.2-0.1,0.8-0.2,0.9-0.4c0.1-0.2,0.1-0.8,0-1c-0.2-0.3-0.9-0.4-1.2-0.6c-0.2-0.2-0.4-0.7-0.7-0.9
        c-0.3-0.2-1-0.1-1.3-0.3c-0.2-0.1-0.4-0.4-0.6-0.6c-0.3-0.2-1-0.3-1.2-0.5c-0.1-0.2-0.2-0.5-0.2-0.8c0.1-0.2,0.1-0.7,0.2-0.9
        c0.1-0.2,0.5-0.7,0.7-0.8c0.3-0.2,0.9-0.4,1.1-0.6c0.3-0.2,1-0.6,1.3-0.8c0.3-0.2,0.8-0.7,1-1c0.1-0.2,0.4-0.4,0.6-0.6
        c0,0,0,0.1,0.1,0.1c0.3,0.6,0.9,1.9,1.2,2.5c0.4,0.9,1.2,2.6,1.6,3.4c0.5,1,1.5,3.1,2,4.1c0.2,0.6,0.7,1.7,0.9,2.3
        c0.1,0.3,0.4,1,0.7,1.2c0.2,0.1,0.6,0.1,0.7,0c0.2-0.2,0-0.7,0.1-0.9c0.1-0.2,0.2-0.7,0.4-0.8c0.2-0.2,0.9,0,1.2,0
        c0.4-0.1,1-0.5,1.4-0.7c0.3-0.2,0.8-0.8,1.1-0.9c0.3-0.1,0.8,0.1,1.1,0.1c0.3-0.1,0.7-0.5,0.9-0.5c0.3-0.1,0.9,0,1.1,0.1
        c0.2,0.1,0.4,0.6,0.6,0.8c0.3,0.3,0.9,0.9,1.3,1.2c0.2,0.2,0.9,0.3,1.1,0.5c0.2,0.2,0.4,0.7,0.6,0.9c0.3,0.3,1,0.7,1.3,0.9
        c0.3,0.2,1,0.5,1.3,0.5c0.4,0.1,1.4-0.1,1.8,0c0.1,0,0.3,0.3,0.5,0.3c0.3,0.1,0.9,0.5,1.2,0.5c0.3-0.1,0.4-0.8,0.7-1
        c0.3-0.2,1-0.6,1.3-0.4c0.2,0.1,0.2,0.6,0.3,0.8c0.1,0.3,0.2,0.8,0.4,1c0.1,0.1,0.3,0.3,0.5,0.4c-0.3,0.4-0.6,0.9-0.9,1
        c-0.3,0.1-0.9-0.2-1.2-0.1c-0.2,0.1-0.6,0.5-0.7,0.7c-0.2,0.4,0,1.2-0.1,1.6c-0.1,0.3-0.5,1-0.7,1.3c-0.2,0.3-0.7,0.8-0.8,1.1
        c-0.1,0.3,0.3,1.1,0.2,1.4c-0.1,0.4-0.8,1-1.1,1.3c-0.2,0.3-0.6,1.1-0.9,1.4c-0.3,0.3-1.1,0.7-1.5,0.8c-0.4,0.1-1.1-0.2-1.5-0.2
        c-0.4,0.1-1.2,0.7-1.6,0.6c-0.3,0-0.8-0.7-1.2-0.8c-0.4-0.2-1.2-0.5-1.6-0.4c-0.4,0-1.2,0.4-1.4,0.7c-0.2,0.3,0.1,1.1,0,1.5
        c-0.1,0.4-0.7,1-0.9,1.4c-0.2,0.3-0.7,0.9-0.9,1.1c-0.3,0.3-1,0.7-1.2,1.1C310.1,203.3,310,203.7,310,204.1z"/>
    </g>
    
    <g id="Hancock">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="hancock-county" ref="hancock-county" class="st11" d="
        M290.9,250.7c-0.2,0-0.5,0-0.6,0.1c-0.2,0.2-0.4,0.8-0.6,1c-0.3,0.3-0.9,0.9-1.2,1c-0.2,0.1-0.8,0-1,0.1c-0.2,0.1-0.5,0.6-0.7,0.8
        c-0.3,0.2-0.8,1-1.2,0.8c-0.4-0.1-0.3-1.3-0.4-1.7c-0.1-0.2-0.4-0.7-0.5-0.9c-0.1-0.2-0.1-0.8-0.2-1c-0.1-0.2-0.5-0.6-0.6-0.8
        c0-0.2,0.1-0.5,0.2-0.6c0.2-0.2,0.7-0.3,0.9-0.5c0.2-0.2,0.5-0.9,0.5-1.2c0-0.3-0.4-1-0.5-1.3c-0.1-0.3-0.2-1.1,0-1.4
        c0.2-0.3,0.9-0.3,1.1-0.6c0.3-0.4,0.2-1.9,0.2-1.9l-9.1-2.9l-2.2-0.7c0,0,0.6-0.4,0.6-0.7c0.1-0.3-0.5-1-0.5-1.3
        c0-0.3,0.1-0.9,0.1-1.2c0-0.3,0-0.8-0.1-1.1c-0.1-0.2-0.3-0.7-0.5-0.8c-0.2-0.1-0.7-0.1-0.8,0.1c-0.2,0.3-0.1,1,0,1.4
        c0.1,0.2,0.4,0.4,0.4,0.6c0,0.2-0.6,0.5-0.7,0.7c-0.1,0.2-0.2,0.7-0.1,0.9c0,0.2,0.3,0.5,0.3,0.7c0.1,0.2,0.2,0.6,0.2,0.8
        c0,0.3-0.3,0.8-0.6,0.9c-0.2,0.1-0.8,0.1-1,0c-0.2-0.1-0.3-0.5-0.4-0.7c-0.1-0.3,0.1-0.9-0.1-1.2c-0.1-0.2-0.4-0.4-0.5-0.5
        c-0.2-0.1-0.6,0.1-0.8,0c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.7,0.3-1,0.3c-0.2,0-0.6,0-0.7-0.1c-0.1-0.1-0.1-0.5,0-0.6
        c0.1-0.2,0.7-0.4,0.9-0.6c0.2-0.1,0.7-0.3,0.8-0.5c0.1-0.1-0.1-0.5,0-0.6c0.2-0.2,0.8,0.2,1,0.1c0.2-0.1,0.4-0.5,0.3-0.7
        c0-0.2-0.4-0.2-0.5-0.4c-0.1-0.2-0.1-0.6,0-0.7c0.1-0.2,0.6-0.3,0.7-0.5c0.2-0.4,0-1.3-0.1-1.6c-0.1-0.2-0.5-0.6-0.5-0.8
        c-0.1-0.4,0.1-1.1,0.3-1.4c0.1-0.2,0.5-0.5,0.7-0.6c0.2,0,0.5,0.1,0.6,0.2c0.1,0.1,0.1,0.6,0.3,0.7c0.3,0.1,0.8-0.2,1-0.5
        c0.1-0.2-0.1-0.7-0.1-0.9c0.1-0.2,0.5-0.5,0.5-0.8c0-0.2-0.5-0.6-0.4-0.8c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.4,0.3,0.6,0.2
        c0.1,0,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.6,0.4-0.7c0.3-0.1,0.9,0.2,1.2,0.1c0.2-0.1,0.4-0.5,0.6-0.6c0.3-0.1,1,0,1.3-0.2
        c0.1,0,0.2-0.3,0.4-0.3c0.1,0,0.4,0.1,0.6,0.2c0.1,0.1,0.4,0.2,0.5,0.2c0.3,0.1,0.8,0.2,1.1,0.1c0.2-0.1,0.3-0.6,0.2-0.7
        c-0.1-0.2-0.5-0.3-0.6-0.4c-0.2-0.1-0.6-0.4-0.6-0.6c0-0.3,0.6-0.5,0.9-0.6c0.2-0.1,0.6-0.1,0.7-0.2c0.2-0.2,0.3-0.8,0.2-1.1
        c0-0.2-0.5-0.6-0.6-0.8c-0.1-0.3,0-0.9-0.1-1.2c0-0.3,0-1-0.2-1.2c-0.2-0.3-0.8-0.6-1-0.8c-0.2-0.2-0.4-0.5-0.6-0.8l14.1-12.8
        c0,0,0.4-0.4,0.6-0.6c0.3,0.4,1.1,0.8,1.4,1.1c0.3,0.3,0.5,1.2,0.9,1.3c0.3,0.1,0.9-0.1,1.3-0.1c0.2,0,0.7,0,0.9,0
        c0.3,0,0.8-0.3,1.1-0.3c0.4,0,1.2,0.2,1.5,0.4c0.2,0.1,0.3,0.7,0.5,0.8c0.2,0.1,0.8,0,1.1,0c0.3,0,0.9,0.3,1.2,0.4
        c0.6,0.1,1.8,0.2,2.3,0.1c0.3-0.1,0.6-0.6,0.9-0.7c0.2-0.1,0.6,0,0.8-0.1c0.2-0.1,0.7-0.3,0.9-0.5c0.2-0.3,0.4-1.1,0.5-1.5
        c0-0.1,0.1-0.3,0.1-0.5c0.2,0.5,0.3,1.3,0.4,1.8c0.1,0.4,0.1,1.2,0.4,1.4c0.3,0.4,1.5,0.3,1.9,0.6c0.2,0.2,0.4,0.9,0.5,1.2
        c0.2,0.6,0.6,1.7,0.6,2.2c-0.1,0.4-0.8,0.9-0.7,1.3c0.1,0.5,1.2,0.9,1.3,1.3c0.1,0.3-0.2,1-0.2,1.3c0.1,0.3,0.3,0.8,0.6,1
        c0.2,0.2,1,0.1,1.2,0.3c0.2,0.3-0.2,1.2,0,1.6c0.1,0.3,0.7,0.7,0.7,1c0.1,0.4-0.2,1.3-0.2,1.7c0.1,0.5,0.2,1.6,0.6,1.8
        c0.2,0.1,0.7-0.2,0.8,0c0.2,0.2,0.1,0.9,0.1,1.2c0.1,0.6,0.5,1.7,0.4,2.2c0,0.4-0.5,1-0.4,1.4c0,0.2,0.5,0.5,0.6,0.7
        c0.1,0.2,0.1,0.6,0.2,0.7c0.2,0.2,0.7,0.6,0.9,0.7c0.2,0.1,0.8,0,1,0.1c0.2,0.1,0.4,0.2,0.6,0.4c-0.1,0.2-0.2,0.4-0.3,0.5
        c-0.2,0.3-0.2,1-0.5,1.2c-0.4,0.2-1.3-0.1-1.8,0c-0.3,0.1-0.9,0.4-1.2,0.6c-0.4,0.2-1,1-1.4,1.2c-0.4,0.2-1.3,0.3-1.6,0.5
        c-0.4,0.2-1,0.7-1.2,1c-0.2,0.2-0.3,0.8-0.5,1c-0.4,0.3-1.4,0-1.8,0.2c-0.2,0.1-0.4,0.6-0.5,0.8c-0.1,0.5,0,1.4-0.1,1.9
        c-0.1,0.4-0.6,1.2-0.8,1.5c-0.3,0.4-1.3,0.8-1.6,1.2c-0.2,0.3-0.5,0.9-0.6,1.2c-0.1,0.3-0.2,1-0.3,1.3c-0.1,0.3-0.6,0.7-0.8,0.8
        c-0.3,0.2-1.1,0.1-1.4,0.2c-0.4,0.1-1.2,0.3-1.5,0.6c-0.3,0.2-0.3,1-0.6,1.3c-0.2,0.2-0.8,0.2-1.1,0.2c-0.6,0.1-1.7,0.2-2.2,0.4
        c-0.5,0.2-1.6,0.6-2,1c-0.3,0.3-0.5,1.1-0.8,1.4c-0.4,0.3-1.4,0.8-1.9,0.8c-0.3,0.1-1-0.1-1.4-0.1c-0.5,0-1.4,0-1.8,0
        C291.3,250.7,291.1,250.7,290.9,250.7z"/>
    </g>
    <g id="Hart">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="hart-county" ref="hart-county" class="st10" d="M318.7,103.7c0,0,0.1,0.1,0.2,0.1c-0.3,0.1-0.6,0.3-0.8,0.4c-0.3,0.1-0.8,0.2-1,0.3
        c-0.2,0.1-0.4,0.6-0.6,0.7c-0.4,0.2-1.3-0.1-1.7,0.1c-0.2,0.1-0.4,0.5-0.6,0.6c-0.3,0.1-0.9,0.2-1.2,0.3c-0.3,0.1-1,0.2-1.3,0.4
        c-0.3,0.1-0.8,0.7-1.1,0.8c-0.5,0.2-1.5,0.1-1.9,0.2c-0.4,0.1-1.1,0.4-1.4,0.5c-0.3,0.1-0.8,0.4-1.1,0.5c-0.4,0.1-1.2,0.1-1.6,0.3
        c-0.2,0.1-0.6,0.3-0.7,0.4c-0.2,0.2-0.6,0.7-0.8,0.9c-0.2,0.2-0.8,0.4-1,0.6c-0.2,0.2-0.5,0.5-0.6,0.7c-0.2,0.3-0.5,1.1-0.8,1.3
        c-0.3,0.2-1,0.1-1.3,0.1c-0.4,0-1.1-0.2-1.4-0.1c-0.2,0-0.7,0.3-0.9,0.3c-0.2,0-0.7-0.2-0.8-0.4c-0.1-0.1-0.3-0.4-0.3-0.6
        c0-0.2,0.4-0.5,0.4-0.7c0-0.1-0.3-0.2-0.5-0.3c-0.3-0.1-0.9,0.1-1.2,0c-0.2-0.1-0.4-0.4-0.6-0.5c-0.4-0.1-1.3,0-1.7,0
        c-0.2,0-0.6,0.2-0.8,0.2c-0.3,0.1-0.9,0.5-1.2,0.6c-0.3,0.1-1,0-1.3,0.1c-0.2,0.1-0.6,0.2-0.7,0.4l-3.9-4.8l0-1.7l0.7-0.6
        c0,0,0.1-1.6,0.1-2.2c0-0.6,0.4-1.3,0.5-1.9c0.1-0.6,0-2,0-2.6c0-0.2-0.1-0.7-0.2-0.9c-0.1-0.2-0.6-0.5-0.7-0.7
        c-0.1-0.4,0.3-1,0.4-1.3c0.2-0.5,0.4-1.5,0.5-2c0-0.5-0.2-1-0.1-1.9c0.1-0.8,0.5-1.7,0.7-2.3c0.2-0.8,0.7-2.3,1-3.1
        c0.2-0.5,0.5-1.5,0.8-2c0.2-0.4,0.9-1.1,1.1-1.5c0.3-0.5,0.9-1.6,1.2-2.1c0.1-0.2,0.5-0.6,0.7-0.9c0,0,0,0,0,0
        c0.2,0,0.6,0.1,0.8,0.2c0.3,0.1,0.7,0.5,0.9,0.7c0.1,0.2,0.3,0.8,0.3,0.9c0,0.1-0.5,0.3-0.6,0.5c-0.1,0.2,0,0.5,0,0.7
        c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.4,0,0.5,0.1c0.2,0.1,0.2,0.7,0.4,0.8c0.2,0.1,0.6-0.3,0.7-0.4c0.2-0.1,0.3-0.5,0.5-0.6
        c0.2,0,0.5,0.3,0.6,0.3c0.2,0,0.6-0.2,0.8-0.4c0.2-0.1,0.4-0.5,0.6-0.6c0.1-0.1,0.5-0.2,0.6-0.1c0.1,0.1,0.2,0.5,0.1,0.7
        c-0.1,0.2-0.5,0.3-0.6,0.5c0,0.2,0.4,0.7,0.5,0.8c0.1,0.1,0.7,0.2,0.8,0.1c0.1-0.1,0.1-0.5,0.2-0.7c0-0.1,0.2-0.3,0.2-0.4
        c0.1-0.3,0-1,0.2-1.2c0.1-0.1,0.5-0.1,0.6-0.1c0.2,0.1,0.5,0.6,0.8,0.7c0.1,0.1,0.5,0.1,0.6,0.1c0.2-0.1,0.3-0.5,0.5-0.6
        c0.1-0.1,0.4-0.3,0.6-0.3c0.1,0,0.3,0.2,0.4,0.3c0.1,0.2-0.3,0.9-0.3,1c0,0.1,0.4,0.3,0.5,0.2c0.2-0.1,0.4-0.6,0.6-0.8
        c0.1-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.2-0.6,0.4-0.6c0.2-0.1,0.6-0.1,0.8,0c0.4,0,1.2,0.1,1.4,0.3c0.1,0.1,0.2,0.5,0.2,0.7
        c-0.1,0.2-0.6,0.4-0.7,0.6c-0.1,0.1-0.1,0.4,0,0.5c0.1,0.1,0.5,0.2,0.6,0.2c0.2,0,0.3-0.4,0.4-0.5c0.2-0.1,0.5-0.2,0.7-0.3
        c0.2,0,0.5-0.1,0.6-0.1c0.2,0.1,0.5,0.5,0.6,0.7c0.1,0.2,0.2,0.6,0.3,0.8c0.1,0.2,0.5,0.5,0.5,0.7c0,0.3-0.5,0.7-0.6,0.9
        c-0.1,0.2-0.5,0.5-0.5,0.7c0,0.2,0.4,0.3,0.6,0.4c0.3,0.1,0.9-0.1,1.1,0c0.2,0.1,0.4,0.5,0.4,0.7c0.1,0.2,0.1,0.6,0,0.8
        c-0.1,0.2-0.3,0.4-0.5,0.5c-0.2,0-0.5-0.1-0.6-0.2c-0.3-0.1-0.8-0.4-1-0.5c-0.2,0-0.5,0.1-0.7,0c-0.2-0.1-0.2-0.5-0.3-0.7
        c-0.1-0.1-0.4-0.4-0.6-0.5c-0.2,0-0.7,0-0.8,0.2c-0.1,0.1-0.2,0.6-0.1,0.8c0.1,0.2,0.6,0.1,0.7,0.3c0.1,0.1,0.1,0.4,0.2,0.5
        c0.2,0.2,0.7,0.2,0.9,0.4c0.1,0.1,0.1,0.5,0.1,0.6c-0.1,0.1-0.4,0.2-0.5,0.2c-0.2,0-0.6-0.5-0.6-0.5c0-0.1-0.6-0.1-0.7-0.1
        c-0.2,0-0.6,0.3-0.8,0.3c-0.3,0-0.7-0.5-1-0.5c-0.2,0-0.6,0.1-0.7,0.3c-0.1,0.1-0.1,0.4,0,0.6c0.1,0.2,0.5,0.3,0.7,0.3
        c0.2,0,0.8-0.1,1,0c0.2,0.1,0.5,0.4,0.4,0.5c-0.1,0.2-0.7,0.2-0.9,0.3c-0.2,0-0.6,0.1-0.8,0c-0.3-0.1-0.8-0.5-1.1-0.5
        c-0.2,0-0.7,0-0.9,0.1c-0.2,0.1-0.5,0.4-0.6,0.7c-0.1,0.2,0.1,0.6,0.1,0.8c-0.1,0.2-0.4,0.5-0.5,0.6c-0.2,0.1-0.6,0.1-0.7,0.2
        c-0.2,0.1-0.3,0.6-0.3,0.8c0,0.3,0.3,0.8,0.6,0.9c0.2,0.1,0.6,0.1,0.8,0c0.2-0.1,0.4-0.7,0.6-0.9c0.2-0.2,0.8-0.6,1-0.8
        c0.2-0.2,0.4-0.8,0.7-0.9c0.2-0.1,0.6-0.1,0.8,0c0.2,0,0.6,0.4,0.8,0.4c0.2,0,0.5-0.4,0.7-0.5c0.1-0.1,0.3-0.5,0.5-0.6
        c0.2-0.1,0.6-0.3,0.8-0.3c0.1,0,0.4,0.2,0.4,0.4c0,0.2-0.4,0.4-0.3,0.6c0.1,0.2,0.6,0,0.8,0c0.2-0.1,0.6-0.5,0.8-0.6
        c0.2,0,0.5,0.2,0.6,0.1c0.3-0.1,0.3-0.9,0.6-1c0.2-0.1,0.5,0.1,0.7,0.2c0.2,0.1,0.2,0.5,0.4,0.7c0.1,0.1,0.5,0.1,0.6,0.2
        c0.1,0.1,0,0.5-0.1,0.6c-0.1,0.2-0.5,0.4-0.6,0.6c-0.1,0.1-0.2,0.6-0.4,0.6c-0.2,0-0.3-0.4-0.4-0.5c-0.2-0.2-0.7-0.5-1-0.5
        c-0.2,0-0.7,0.3-0.9,0.4c-0.1,0.1-0.4,0.3-0.4,0.4c-0.1,0.2-0.1,0.7-0.1,0.8c0,0.1-0.5,0.4-0.5,0.6c-0.1,0.2,0.1,0.8,0.2,1
        c0.1,0.1,0.5,0.3,0.6,0.2c0.1,0,0.3-0.3,0.4-0.4c0.1-0.1,0.4-0.3,0.4-0.5c0.1-0.2-0.3-0.8-0.1-1c0.1-0.1,0.4-0.1,0.6,0
        c0.2,0.1,0.4,0.5,0.6,0.6c0.2,0,0.5-0.3,0.7-0.3c0.1,0,0.3,0.3,0.3,0.5c0,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.3,0.5-0.3,0.6
        c0,0.2,0.3,0.6,0.4,0.7c0,0.1,0.5,0.3,0.7,0.3c0.2-0.1,0.2-0.5,0.3-0.7c0.1-0.1,0.2-0.3,0.3-0.3c0.1,0,0.3,0,0.4,0
        c0.1,0.1,0.3,0.4,0.3,0.6c0,0.2-0.5,0.4-0.5,0.6c0,0.1,0.2,0.3,0.4,0.3c0.2,0.1,0.7-0.1,0.9-0.1c0.1,0,0.4,0.2,0.5,0.1
        c0.2-0.1,0.2-0.5,0.2-0.7c0-0.2,0-0.6,0.2-0.6c0.1,0,0.3,0.3,0.4,0.4c0.2,0.2,0.6,0.7,0.8,0.9c0.2,0.2,0.8,0.5,0.9,0.8
        c0.3,0.5,0.3,1.7,0.4,2.2c0.1,0.5,0.5,1.5,0.7,2C317.8,102.2,318.4,103.3,318.7,103.7z"/>
    </g>
    <g id="Oglethorpe">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="oglethorpe-county" ref="oglethorpe-county" class="st10" d="M267.9,157.4c0.5-0.8,1.2-1.9,1.6-2.6c0.5-1,1.7-2.9,1.9-4c0-0.3,0-0.9-0.2-1.2
        c-0.2-0.5-1.1-1.3-1.5-1.8c-0.4-0.4-1.1-1.2-1.4-1.6c-0.2-0.2-0.7-0.4-0.7-0.7c0-0.3,0.5-0.8,0.7-1c0.2-0.2,0.8-0.4,1-0.7
        c0.1-0.3,0-1-0.1-1.3c0-0.2-0.2-0.6-0.3-0.8c0-0.2-0.1-0.6-0.1-0.9c0-0.3,0-1,0.1-1.3c0.1-0.2,0.6-0.5,0.9-0.7
        c0.1-0.1,0.2-0.1,0.2-0.1c0.3-0.3,1-0.9,1.4-1c0.4-0.1,1.1,0,1.4,0.2c0.2,0.1,0.5,0.7,0.7,0.8c0.2,0.1,0.6-0.1,0.8-0.1
        c0.6-0.2,1.9-0.7,2.3-1.2c0.3-0.2,0.5-0.9,0.7-1.2c0.2-0.3,0.9-0.6,1.2-0.9c0.2-0.2,0.5-0.6,0.8-0.7c0.3-0.1,0.9,0,1.2-0.1
        c0.4-0.1,1-0.7,1.4-0.8c0.3-0.1,0.9-0.2,1.2-0.1c0.3,0.1,0.7,0.3,0.9,0.5c0.2,0.2,0.4,1,0.7,1.2c0.2,0.2,0.9,0.2,1.1,0.2
        c0.4,0,1-0.6,1.4-0.6c0.3,0,0.9,0.4,1.1,0.6c0.2,0.2,0.4,0.9,0.7,1c0.2,0.1,0.8,0,1.1,0c0.2,0.1,0.6,0.5,0.8,0.5
        c0.3,0,0.6-0.5,0.8-0.7c0.1-0.2,0.3-0.7,0.4-0.8c0.2-0.2,0.8-0.1,1.1-0.2c0.2-0.1,0.5-0.5,0.7-0.5c0.3-0.1,0.9,0.3,1.1,0.2
        c0.3-0.1,0.6-0.7,0.9-0.8c0.2-0.1,0.6,0.2,0.9,0.2c0.2,0,0.6-0.3,0.8-0.4c0.2-0.1,0.4-0.5,0.6-0.7c0.3,0.2,0.9,0.4,1.2,0.6
        c0.2,0.1,0.6,0.5,0.8,0.6c0.3,0.1,0.9,0.1,1.1,0.3c0.2,0.2,0.7,0.7,0.7,1c0,0.2-0.6,0.4-0.6,0.7c0,0.2,0.3,0.5,0.5,0.6
        c0.2,0.2,0.7,0.6,1,0.8c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.6,0.6-0.7c0.3-0.1,0.9-0.1,1.1,0c0.2,0.1,0.1,0.7,0.3,0.9
        c0.2,0.2,0.6,0.3,0.9,0.4c0.3,0.1,0.9-0.1,1.2,0c0.2,0.1,0.5,0.6,0.7,0.8c0.2,0.1,0.6,0.2,0.7,0.3c0.2,0.2,0.5,1.1,0.6,1.2
        c0.1,0.1,1.1,0.1,1.4,0.1c0.2,0,0.5,0.1,0.6,0c0.2-0.1,0-0.7,0.1-0.9c0.1-0.2,0.4-0.6,0.6-0.6c0.2,0,0.4,0.4,0.5,0.5
        c0.2,0.1,0.7-0.1,0.9,0c0.2,0,0.6,0.2,0.7,0.4c0.2,0.2,0.3,0.8,0.6,0.9c0.3,0.2,1.1-0.1,1.5,0c0.2,0.1,0.6,0.5,0.8,0.6
        c0.2,0.1,0.6,0.3,0.9,0.4c-0.1,0.4-0.2,0.8-0.3,1c-0.1,0.2-0.4,0.6-0.6,0.8c-0.2,0.2-0.5,0.7-0.8,0.8c-0.2,0.1-0.7-0.1-0.9-0.1
        c-0.2,0-0.6,0.3-0.8,0.4c-0.3,0.1-0.7,0.5-1,0.6c-0.3,0.1-1,0.1-1.4,0.1c-0.3,0-1-0.2-1.2,0c-0.3,0.2-0.3,0.9-0.6,1.2
        c-0.2,0.2-0.6,0.4-0.9,0.5c-0.3,0.1-0.9,0.2-1.1,0.4c-0.2,0.1-0.4,0.6-0.5,0.8c-0.2,0.3-0.7,0.8-0.8,1.1c-0.1,0.2-0.2,0.7-0.2,1
        c-0.1,0.4-0.4,1.1-0.6,1.4c-0.2,0.2-0.5,0.7-0.7,0.8c-0.2,0.1-0.7,0.1-0.9,0.2c-0.2,0.2-0.4,0.8-0.5,1.1c-0.2,0.4-0.3,1.3-0.5,1.6
        c-0.2,0.3-0.7,0.7-1,0.9c-0.3,0.1-1-0.1-1.3,0.1c-0.3,0.2-0.6,0.8-0.7,1.1c-0.2,0.4-0.4,1.1-0.5,1.5c-0.1,0.4,0.1,1.1-0.1,1.5
        c-0.1,0.3-0.6,0.9-0.9,1.2c-0.2,0.2-0.9,0.2-1.1,0.4c-0.1,0.2-0.1,0.6-0.2,0.8c-0.1,0.3-0.6,0.8-0.7,1.1c-0.1,0.3-0.1,1.1,0,1.4
        c0.2,0.3,1,0.6,1.2,0.9c0.3,0.4,0.6,1.4,0.9,1.8c0.2,0.2,0.7,0.6,0.9,0.8c0.3,0.3,0.6,1,0.8,1.4c-0.2,0.2-0.5,0.5-0.6,0.6
        c-0.2,0.3-0.7,0.8-1,1c-0.3,0.2-1,0.6-1.3,0.8c-0.3,0.2-0.9,0.4-1.1,0.6c-0.2,0.2-0.6,0.6-0.7,0.8c-0.1,0.2,0,0.7-0.2,0.9
        c0,0,0,0,0,0c-0.2,0.2-0.7,0.5-1,0.5c-0.3,0-1-0.2-1.3-0.4c-0.2-0.2-0.4-0.7-0.6-0.8c-0.2-0.3-0.9-0.6-1.1-0.8
        c-0.2-0.2-0.4-0.7-0.7-0.8c-0.4-0.2-1.3,0-1.7,0c-0.6,0.1-1.6,0.3-2.2,0.6c-0.4,0.2-1,0.7-1.3,0.8c-0.4,0.1-1.3,0.1-1.7,0.2
        c-0.3,0.1-0.7,0.4-1,0.3c-0.3-0.1-0.3-0.8-0.5-1c-0.3-0.2-0.9-0.5-1.3-0.6c-0.5-0.1-1.5,0-2-0.1c-0.7-0.2-2.1-0.9-2.8-1.2
        c-0.9-0.4-2.5-1.5-3.4-2c-0.7-0.4-2-1.4-2.8-1.7c-0.5-0.2-1.7-0.5-2.3-0.7c-0.3-0.1-0.9-0.3-1.1-0.4c0,0-0.1-0.1-0.2-0.1
        c-0.3-0.3-0.6-0.8-0.7-1.1c0-0.2,0.2-0.7,0.2-0.9c0.1-0.3,0.3-0.9,0.2-1.2c-0.1-0.2-0.7-0.5-0.9-0.6c-0.1-0.1-0.5-0.3-0.5-0.5
        c-0.1-0.2,0.2-0.6,0.2-0.9c-0.1-0.4-0.7-0.9-0.7-1.3c0-0.4,0.7-1.1,0.9-1.4c0.3-0.5,1-1.6,1.3-2.1
        C267.7,157.7,267.8,157.6,267.9,157.4z"/>
    </g>
    <g id="Dodge">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="dodge-county" ref="dodge-county" class="st10" d="M260.9,349.4l0.6-0.6l-0.7-1.1l6.2-4.6l-1.9-2.8l13.2-12.9l3.3,3l0,0.1l15.5,33.8l10.2-5.8
        l0.2,0.2l1.2,1.3l-5.5,5.8l-0.6-0.5l0.8-0.7l-0.9-0.8l-2.1,1.9c0,0,0,0.1,0,0.1c0.2,0.3,0.5,0.8,0.8,1c0.1,0.1,0.4,0.2,0.6,0.3
        l-27,27.3c0-0.1,0-0.2-0.1-0.2c0-0.2-0.1-0.6-0.2-0.7c-0.2-0.3-1-0.3-1.2-0.6c-0.2-0.3,0.2-1.2,0.1-1.5c-0.1-0.2-0.4-0.4-0.5-0.6
        c-0.2-0.3-0.5-0.9-0.7-1.1c-0.2-0.1-0.6-0.2-0.7-0.3c-0.1-0.1-0.3-0.5-0.5-0.6c-0.2-0.2-0.8-0.3-1.1-0.4c-0.2,0-0.7,0.1-0.9,0
        c-0.2-0.1-0.6-0.7-0.7-0.7c-0.1,0-0.6-1.1-0.8-1.5c-0.1-0.2-0.6-0.5-0.7-0.7c-0.1-0.2-0.2-0.6-0.2-0.7c0-0.3,0.3-1,0.3-1.1
        c0-0.1-0.2-0.9-0.4-1.1c-0.2-0.2-0.8-0.4-1-0.6c-0.1-0.2-0.2-0.8-0.1-1.1c0.1-0.1,0.5-0.2,0.5-0.4c0.1-0.2,0.2-0.8,0.1-1.1
        c-0.1-0.2-0.6-0.4-0.6-0.7c-0.1-0.2,0.1-0.6,0-0.8c-0.1-0.2-0.4-0.7-0.6-0.8c-0.1-0.1-0.6-0.2-0.6-0.4c0-0.3,0.6-0.6,0.7-0.9
        c0.1-0.4-0.2-1.1-0.2-1.5c0-0.3,0.3-0.7,0.4-1c0-0.3-0.1-0.9-0.2-1.2c-0.1-0.2-0.3-0.7-0.5-0.7c-0.2,0-0.3,0.6-0.4,0.7
        c-0.2,0.1-0.6,0.3-0.8,0.2c-0.3-0.1-0.6-0.7-0.7-0.9c-0.1-0.2,0-0.8-0.1-1c-0.1-0.2-0.3-0.4-0.6-0.5l3.3-2.8l-2.1-2.8l3.3-3.3
        l-8.2-8.7L260.9,349.4z"/>
    </g>
    <g id="Wilkinson">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="wilkinson-county" ref="wilkinson-county" class="st10" d="M288.4,267.7c0.3,0.6,0.6,1.6,0.9,2c0.2,0.3,1,0.7,1.2,1c0.2,0.4,0.4,1.3,0.5,1.7
        c0.1,0.4,0,1.2,0.1,1.6c0.2,0.4,0.9,1,1.1,1.4c0.1,0.4,0,1.2,0.1,1.6c0.1,0.4,0.6,1,1,1.2c0.2,0.1,0.7,0,1,0
        c0.3,0,0.9,0.2,1.2,0.2c0.2,0,0.6-0.2,0.7-0.1c0.3,0.1,0.6,0.7,0.8,0.9c0.2,0.2,0.7,0.4,0.9,0.6c0.3,0.3,0.6,1,0.7,1.4
        c0.1,0.5,0,1.5,0.2,1.9c0.2,0.4,1.2,0.9,1.4,1.3c0.2,0.4,0.1,1.4,0,1.8c-0.1,0.6-0.6,1.6-0.6,2.2c0,0.5,0.4,1.3,0.7,1.7
        c0.1,0.1,0.3,0.2,0.5,0.4c0.2,0.1,0.4,0.3,0.5,0.4c0.3,0.6,0.1,1.9-0.1,2.5c-0.1,0.4-0.8,0.9-1,1.3c-0.1,0.4-0.1,1-0.1,1.6l-0.3,0
        l-24,13.3l-2.9,1.7l0,0l-18.5-38.3l5.2-3.2l5.9-2.8c0,0,0.6-0.1,0.8-0.1c0.3,0.1,0.8,0.6,1.2,0.7c0.2,0,0.6,0,0.7-0.1
        c0.3-0.3,0.1-1.4,0.4-1.8c0.2-0.2,0.7-0.4,0.9-0.5c0.3-0.1,1-0.2,1.3-0.4c0.6-0.3,1.6-1,2.1-1.3c0.3-0.2,0.8-0.5,1.1-0.7
        c0.4-0.2,1.2-0.5,1.7-0.7c0.4-0.1,1.4-0.1,1.8-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.4-0.3,1.5-0.5,2-0.7c0.2-0.1,0.7-0.2,0.9-0.3
        c0.2-0.1,0.3-0.7,0.5-0.7c0.3-0.1,0.9,0.2,1.2,0.4c0.3,0.2,0.7,0.8,0.8,1.1c0.1,0.4-0.2,1.2-0.1,1.6c0.1,0.3,0.8,0.9,0.8,1
        c0,0.1,1.1,0.4,1.4,0.6c0.3,0.2,0.4,1,0.6,1.3c0.2,0.3,1,0.5,1.2,0.8c0.3,0.2,0.7,0.8,0.8,1.1
        C288.3,267.5,288.4,267.6,288.4,267.7z"/>
    </g>
    <g id="Greene">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="greene-county" ref="greene-county" class="st10" d="M294.6,203.6c-0.3,0.3-0.6,0.6-0.6,0.6L279.9,217c-0.1-0.2-0.2-0.3-0.2-0.4c0-0.1,0.1-0.4,0-0.5
        c-0.1-0.2-0.6-0.5-0.6-0.7c0-0.1,0.5-0.1,0.6-0.2c0.2-0.1,0.7-0.4,0.8-0.6c0.1-0.2-0.1-0.6,0-0.7c0.1-0.1,0.4-0.2,0.6-0.3
        c0.1-0.1,0.4-0.3,0.5-0.5c0.2-0.2,0.6-0.5,0.7-0.7c0-0.1,0-0.3,0-0.3c-0.1-0.1-0.4,0-0.5,0c-0.1,0-0.3,0.3-0.4,0.3
        c-0.1,0-0.3-0.2-0.4-0.3c-0.1-0.1,0.1-0.5,0.1-0.6c0-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.6,0.2-0.6,0.4c-0.1,0.2,0.2,0.7,0.1,0.9
        c0,0.2-0.2,0.7-0.4,0.8c-0.2,0.1-0.8,0.2-1,0.1c-0.2-0.1-0.5-0.4-0.5-0.6c0-0.2,0.4-0.6,0.5-0.8c0-0.2-0.1-0.5-0.2-0.7
        c-0.1-0.1-0.2-0.4-0.3-0.5c0-0.2,0-0.7,0.1-0.8c0.1-0.2,0.7-0.1,0.8-0.3c0.1-0.1,0.1-0.5,0-0.6c-0.1-0.1-0.5-0.2-0.7-0.2
        c-0.2,0-0.5,0.3-0.7,0.4c-0.2,0.2-0.3,0.9-0.4,1.1c0,0.1,0.1,0.5-0.1,0.5c-0.2,0-0.4-0.4-0.5-0.6c-0.1-0.3,0-1,0.1-1.3
        c0.1-0.2,0.4-0.9,0.6-1.1c0.1-0.1,0.6-0.2,0.7-0.3c0.2-0.2,0.4-0.6,0.6-0.8c0.1-0.2,0.4-0.5,0.3-0.6c-0.1-0.1-0.6,0-0.8,0
        c-0.3,0.1-0.8,0.5-1.1,0.5c-0.1,0-0.3-0.2-0.4-0.4c-0.1-0.2-0.4-0.6-0.4-0.8c-0.1-0.3,0.1-1,0.1-1.1c0-0.1,0-0.6,0.1-0.7
        c0.2-0.2,0.7-0.6,1-0.7c0.2-0.1,1,0,1.1-0.2c0.2-0.3,0.3-1,0.2-1.3c-0.1-0.2-0.5-0.3-0.7-0.3c-0.2,0.1-0.6,0.6-0.7,0.7
        c-0.1,0-0.6,0.6-0.8,0.8c-0.2,0.1-0.6,0.4-0.8,0.4c-0.2,0-0.4-0.4-0.6-0.5c-0.1-0.1-0.4-0.4-0.5-0.3c-0.1,0.1-0.1,0.4,0,0.6
        c0,0.2,0.2,0.6,0.2,0.8c0,0.2,0.2,0.9-0.1,1c-0.2,0.1-0.5-0.5-0.7-0.6c-0.2-0.1-0.5-0.1-0.7,0c-0.1,0.1-0.3,0.4-0.3,0.6
        c0,0.3,0.4,0.2,0.6,0.4c0.1,0.1,0.2,0.5,0.3,0.6c0.2,0,0.6,0.1,0.8,0.2c0.2,0.1,0.6,0.2,0.7,0.3c0.2,0.2,0.1,0.7,0.2,0.9
        c0.1,0.2,0.3,0.6,0.2,0.9c0,0.1-0.1,0.3-0.2,0.4c-0.2,0-0.4-0.4-0.6-0.4c-0.2,0-0.4,0.3-0.4,0.5c0,0.2,0.4,0.5,0.5,0.6
        c0.1,0.1,0.6,0.2,0.6,0.4c0,0.2-0.4,0.4-0.4,0.6c0,0.3,0.3,0.7,0.4,0.9c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.2,0.5,0.2,0.6
        c0,0.1-0.2,0.3-0.3,0.4c-0.1,0-0.2-0.3-0.4-0.3c-0.2,0-0.4,0.3-0.5,0.5c-0.1,0.2-0.3,0.6-0.4,0.7c-0.2,0.1-0.7,0.2-0.9,0.2
        c-0.2,0.1-0.6,0.5-0.8,0.3c-0.1-0.1,0.2-0.5,0.3-0.7c0.1-0.2,0.6-0.4,0.7-0.6c0.1-0.2,0.3-0.7,0.2-0.9c-0.1-0.2-0.4-0.4-0.6-0.6
        c-0.1-0.1-0.2-0.6-0.4-0.6c-0.2,0-0.5,0.6-0.7,0.6c-0.2,0-0.5-0.2-0.7-0.2c-0.2,0-0.5,0.2-0.7,0.1c-0.2-0.1-0.4-0.4-0.4-0.6
        c0-0.2,0.4-0.5,0.4-0.7c0-0.2-0.2-0.6-0.2-0.8c0-0.2,0-0.7-0.2-0.8c-0.1-0.2-0.7-0.4-0.8-0.5c-0.1-0.1-0.4-0.4-0.4-0.6
        c0-0.2,0.1-0.7,0.2-0.9c0.1-0.2,0.6-0.7,0.5-0.9c-0.1-0.3-0.8-0.3-1.1-0.4c-0.2-0.1-0.7-0.4-0.8-0.6c-0.1-0.2-0.2-0.8-0.2-1
        c0-0.3,0.4-0.8,0.4-1.1c-0.1-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.2-0.8-0.4-1c-0.1-0.1-0.3-0.1-0.4-0.2c0-0.2,0-0.5,0-0.6
        c0-0.4,0-1.1,0-1.4c-0.1-0.5-0.4-1.3-0.5-1.8c0-0.2,0-0.6,0-0.8c0-0.2-0.1-0.7-0.2-0.8c-0.2-0.3-0.7-0.6-1-0.8
        c-0.2-0.1-0.5-0.2-0.6-0.4c-0.2-0.2-0.6-0.7-0.7-1c-0.2-0.3-0.5-1.1-0.7-1.4c-0.4-0.5-1.5-1.3-1.8-1.8c-0.2-0.3-0.3-0.9-0.4-1.2
        c-0.1-0.2-0.4-0.7-0.6-0.9c-0.1-0.2-0.4-0.7-0.5-0.9c-0.1-0.3,0-1-0.2-1.3c-0.1-0.2-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.7-0.3-0.9
        c-0.1-0.3-0.6-0.9-0.6-1.2c-0.1-0.3,0.2-1,0-1.3c-0.1-0.2-0.5-0.4-0.7-0.5c-0.2-0.1-0.6-0.2-0.7-0.3c-0.2-0.1-0.4-0.5-0.4-0.7
        c-0.1-0.3,0.2-0.9,0-1.1c-0.1-0.3-0.7-0.5-0.8-0.7c-0.1-0.2,0-0.7-0.1-0.9c-0.1-0.3-0.5-0.7-0.8-1c-0.1-0.1-0.3-0.2-0.5-0.3
        l10-3.9c0,0,0.9-0.3,1.1-0.5c0.1-0.2,0.1-0.6,0.2-0.8c0.1-0.3,0.1-1,0.3-1.3c0.1-0.2,0.7-0.4,0.9-0.6c0.1-0.1,0.3-0.4,0.4-0.6
        c0.1,0.1,0.1,0.1,0.2,0.1c0.2,0.2,0.8,0.3,1.1,0.4c0.6,0.2,1.7,0.5,2.3,0.7c0.7,0.3,2.1,1.3,2.8,1.7c0.8,0.5,2.5,1.5,3.4,2
        c0.7,0.3,2,1,2.8,1.2c0.5,0.1,1.5,0,2,0.1c0.3,0.1,1,0.3,1.3,0.6c0.2,0.2,0.3,0.9,0.5,1c0.2,0.1,0.7-0.3,1-0.3
        c0.4-0.1,1.3-0.1,1.7-0.2c0.4-0.1,1-0.7,1.3-0.8c0.5-0.2,1.6-0.5,2.2-0.6c0.4-0.1,1.3-0.2,1.7,0c0.2,0.1,0.5,0.6,0.7,0.8
        c0.3,0.2,0.9,0.6,1.1,0.8c0.2,0.2,0.4,0.7,0.6,0.8c0.3,0.2,1,0.4,1.3,0.4c0.3,0,0.8-0.3,1-0.5c0,0,0,0,0,0c0,0.3,0.1,0.6,0.2,0.8
        c0.2,0.3,0.9,0.3,1.2,0.5c0.2,0.1,0.4,0.5,0.6,0.6c0.3,0.2,1,0.1,1.3,0.3c0.2,0.1,0.4,0.7,0.7,0.9c0.3,0.2,1,0.3,1.2,0.6
        c0.1,0.2,0.1,0.8,0,1c-0.1,0.2-0.7,0.3-0.9,0.4c-0.3,0.1-0.9,0.2-1.1,0.4c-0.2,0.2-0.4,0.6-0.5,0.8c0,0.2,0.1,0.7,0.1,1
        c0,0.3-0.3,0.8-0.4,1.1c0,0.2,0.1,0.7,0,0.9c-0.1,0.1-0.2,0.3-0.4,0.3c-0.2,0-0.5-0.4-0.6-0.6c-0.2-0.2-0.2-0.8-0.5-0.9
        c-0.2-0.1-0.6,0.2-0.8,0.3c-0.2,0.1-0.5,0.4-0.5,0.7c0,0.2,0.1,0.5,0.2,0.6c0.1,0.1,0.5,0.2,0.6,0.4c0.1,0.2,0.1,0.7,0.1,0.9
        c0,0.2-0.1,0.7-0.3,1c-0.1,0.2-0.5,0.5-0.7,0.7s-0.6,0.5-0.7,0.7c-0.2,0.4-0.4,1.3-0.4,1.7c0,0.4,0.4,1.1,0.5,1.4
        c0.2,0.5,0.7,1.5,0.9,1.9c0.3,0.6,0.9,1.6,1.1,2.2c0.1,0.5-0.1,1.4-0.1,1.9c0,0.6,0,2.5,0,2.5s-0.6,0.7-0.8,0.9
        c-0.4,0.4-1.2,1.1-1.6,1.4C294.8,203.4,294.7,203.5,294.6,203.6z"/>
    </g>
    <g id="Lanier">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="lanier-county" ref="lanier-county" class="st10" d="M298.8,481.8l0.1,38.3c0,0-0.1,0-0.1,0c-0.4-0.1-1.3-0.5-1.7-0.4c-0.3,0.1-0.6,1-0.9,1.2
        c-0.2,0.2-0.8,0.1-1.1,0.3c-0.3,0.2-0.7,1.1-0.7,1.1h-1.3c-0.1-0.5-0.1-1.1-0.1-1.4c0.1-0.2,0.4-0.4,0.5-0.6
        c0.1-0.3-0.1-1-0.1-1.4c0-0.4-0.1-1.2,0-1.6c0.1-0.3,0.3-0.7,0.2-1c-0.1-0.4-0.6-0.8-1-1.1c-0.2-0.2-0.6-0.4-0.7-0.6
        c-0.2-0.5,0.1-1.5,0-2c-0.1-0.3-0.7-0.7-0.7-1c0-0.3,0.3-1.1,0.3-1.1h-14V501l-1.8,0v-4.4h3v-10.5h12.5l-0.4-4.3L298.8,481.8z"/>
    </g>
    <g id="Madison">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="madison-county" ref="madison-county" class="st10" d="M259.3,134.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-1.1-0.5-1.4-0.8c-0.2-0.2-0.6-0.7-0.6-1.1
        c0-0.4,0.8-1,0.8-1.4c0-0.3-0.4-0.9-0.3-1.2c0-0.2,0.4-0.3,0.5-0.5c0.1-0.2,0-0.6,0.1-0.7c0.1-0.5,0.7-1.2,0.9-1.6
        c0.2-0.3,0.8-0.9,0.9-1.3c0.2-0.5-0.1-1.6-0.1-2.2c-0.1-0.5-0.3-1.5-0.4-2c0-0.5,0.4-1.5,0.1-1.9c-0.2-0.3-1.3-0.2-1.6-0.5
        c-0.2-0.2-0.3-0.8-0.5-1.1c-0.3-0.5-1.3-1.1-1.6-1.5c-0.2-0.3-0.4-0.9-0.6-1.2c0.2-0.2,0.5-0.3,0.6-0.4c0.4-0.2,1.1-0.3,1.5-0.3
        c0.6,0,1.2-0.4,1.5-0.7c0.3-0.3,0.6-1.2,0.9-1.6c0.1-0.3,0.6-0.5,0.6-0.5l4.5-3.9c0,0,0.7-0.3,1-0.3c0.2,0,0.7,0.2,0.9,0.4
        c0.2,0.1,0.2,0.6,0.4,0.7c0.2,0.1,0.7-0.2,0.8,0c0.1,0.1,0.1,0.3,0.2,0.4c0.2,0.1,0.7,0.1,1,0.2c0.3,0.1,0.8,0.2,1.1,0.3
        c0.3,0.1,0.8,0.4,1,0.4c0.2,0,0.7,0.1,0.8,0c0.2-0.1,0.2-0.6,0.4-0.7c0.1,0,0.3,0.3,0.5,0.4c0.1,0.1,0.4,0.1,0.6,0.1
        c0.1,0,0.3-0.3,0.5-0.3c0.2,0,0.7,0.2,0.9,0.2c0.2,0,0.6-0.4,0.8-0.4c0.2,0,0.5,0.3,0.7,0.3c0.2,0.1,0.6,0,0.8,0.1
        c0.2,0.1,0.5,0.5,0.7,0.6c0.2,0.1,0.8,0,0.8,0.2l2.2-1.6l3.6-2.9l0,1.7l3.9,4.8c0,0,0,0,0,0c-0.2,0.2-0.4,0.8-0.5,1
        c-0.2,0.2-0.6,0.6-0.8,0.8c-0.2,0.2-0.5,0.4-0.6,0.6c-0.1,0.3,0,0.9,0,1.2c0,0.3,0.2,0.9,0.1,1.1c-0.1,0.2-0.5,0.5-0.6,0.7
        c-0.1,0.1-0.2,0.5-0.2,0.6c0,0.3,0.4,0.8,0.6,0.9c0.2,0.1,0.6-0.1,0.8,0c0.2,0.2,0.3,0.8,0.5,1c0.2,0.2,0.7,0.4,1,0.4
        c0.2,0,0.5-0.2,0.6-0.1c0.2,0.1,0.3,0.5,0.4,0.7c0.1,0.2,0.1,0.6,0.3,0.8c0.1,0.1,0.5,0.3,0.6,0.4c0.2,0.2,0.4,0.6,0.5,0.8
        c0.1,0.2,0,0.6,0,0.7c0.1,0.2,0.4,0.6,0.6,0.7c0.2,0.1,0.9,0,1.1,0.2c0.2,0.2,0.5,0.8,0.4,1.1c0,0.3-0.6,0.7-0.5,1
        c0,0.2,0.5,0.5,0.7,0.7c0.1,0.2,0.3,0.5,0.4,0.7c0.2,0.3,0.6,0.8,0.9,1c0.2,0.1,0.7,0.1,0.9,0.2c0.3,0.2,0.5,1,0.8,1.3
        c0.1,0.1,0.5,0.3,0.7,0.5c0.3,0.3,0.4,1.1,0.6,1.5c0.2,0.2,0.6,0.6,0.8,0.7c0,0,0.1,0,0.1,0.1c-0.2,0.3-0.5,0.6-0.6,0.7
        c-0.2,0.1-0.6,0.4-0.8,0.4c-0.2,0-0.7-0.2-0.9-0.2c-0.3,0.1-0.6,0.7-0.9,0.8c-0.3,0.1-0.9-0.3-1.1-0.2c-0.2,0-0.5,0.4-0.7,0.5
        c-0.2,0.1-0.8,0-1.1,0.2c-0.2,0.1-0.3,0.6-0.4,0.8c-0.2,0.2-0.6,0.7-0.8,0.7c-0.2,0-0.6-0.4-0.8-0.5c-0.3-0.1-0.8,0.1-1.1,0
        c-0.3-0.2-0.5-0.8-0.7-1c-0.2-0.2-0.8-0.6-1.1-0.6c-0.4,0-1,0.5-1.4,0.6c-0.3,0-0.9,0-1.1-0.2c-0.3-0.2-0.4-1-0.7-1.2
        c-0.2-0.2-0.7-0.5-0.9-0.5c-0.3-0.1-0.9,0-1.2,0.1c-0.4,0.1-1,0.7-1.4,0.8c-0.3,0.1-0.9,0-1.2,0.1c-0.2,0.1-0.6,0.6-0.8,0.7
        c-0.3,0.2-0.9,0.6-1.2,0.9c-0.2,0.3-0.5,0.9-0.7,1.2c-0.5,0.4-1.7,1-2.3,1.2c-0.2,0.1-0.6,0.2-0.8,0.1c-0.3-0.1-0.5-0.7-0.7-0.8
        c-0.3-0.2-1.1-0.3-1.4-0.2c-0.4,0.1-1,0.7-1.4,1c-0.1,0-0.1,0.1-0.2,0.1c-0.2-0.2-0.6-0.4-0.7-0.5c-0.3-0.1-0.8-0.1-1.1-0.1
        c-0.3,0-0.9,0.2-1.2,0.2c-0.4-0.1-1.4-1-1.4-1.1c0-0.1-1-1-1.4-1.2c-0.2-0.1-0.8-0.1-1-0.2c-0.4-0.2-1.1-0.8-1.5-1
        c-0.4-0.2-1.4-0.5-1.8-0.7C259.5,134.2,259.4,134.1,259.3,134.1z"/>
    </g>
    <g id="Ben_Hill">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="ben-hill-county" ref="ben-hill-county" class="st10" d="M296.3,409.1v13h-18.2v2.9h-9.2v-3.7H263V412h-1.7v-1.5h-2.9v1.4h-8.7
        c0.1-0.3,0.1-0.6,0.1-0.8c0-0.3,0.2-0.8,0.2-1.1c-0.1-0.3-0.7-0.8-0.8-1.1c-0.1-0.3,0.2-0.9,0.1-1.2c-0.1-0.1-0.4-0.2-0.4-0.4
        c-0.1-0.1,0.1-0.5,0-0.7c-0.1-0.2-0.4-0.3-0.5-0.5c-0.2-0.3-0.7-1-0.7-1.3c0-0.3,0.3-0.9,0.3-1.3c-0.1-0.4-1-1-1.1-1.4
        c0-0.2,0.5-0.5,0.5-0.7c0-0.1,0-0.2,0-0.3h27.1l2.8-1.2c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.5,0.4,0.7,0.6c0.2,0.2,0.3,0.7,0.5,0.9
        c0.2,0.2,0.9,0.4,1.1,0.7c0.2,0.2,0.4,0.8,0.7,1c0.2,0.1,0.5,0,0.7,0c0.2,0,0.7,0.1,0.8,0.3c0.1,0.2-0.1,0.6-0.1,0.8
        c0.1,0.2,0.5,0.5,0.7,0.7c0.2,0.2,0.7,0.5,1,0.4c0.2-0.1,0.3-0.5,0.4-0.6c0.2-0.2,0.5-0.7,0.8-0.7c0.3,0,0.7,0.4,0.9,0.6
        c0.2,0.2,0.4,0.8,0.6,0.9c0.2,0.1,0.6-0.4,0.7-0.3c0.1,0.1,0,0.6,0.1,0.7c0.2,0.2,1.1,0.1,1.2,0.1c0.1,0,0.8,0.6,1,0.8
        c0.2,0.1,0.4,0.5,0.6,0.6c0.4,0.1,1.2-0.2,1.5-0.1c0.2,0.1,0.4,0.4,0.5,0.6c0.2,0.2,0.3,0.9,0.6,1.1c0.2,0.1,0.8,0,1.1,0
        c0.3,0.1,1,0.5,1.3,0.5c0.2,0,0.7-0.2,0.9-0.3C296,409.5,296.1,409.3,296.3,409.1z"/>
    </g>
    <g id="Irwin">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="irwin-county" ref="irwin-county" class="st10" d="M245.3,431.8l0.6-12.4h3.2c0,0,0-0.7,0.1-0.9c0.1-0.3,0.5-0.7,0.6-1c0.1-0.3,0.1-1,0-1.3
        c-0.1-0.3-0.8-0.7-1-1c-0.2-0.3-0.4-1.1-0.3-1.5c0.1-0.3,0.9-0.6,1.1-1c0.1-0.2,0.2-0.5,0.2-0.8h8.7v-1.4h2.9v1.5h1.7v9.2h5.9v3.7
        h9.2v-2.9h18.2c0,0,0,0.9-0.2,1.1c-0.1,0.2-0.4,0.5-0.6,0.5c-0.2,0.1-0.6-0.2-0.7-0.1c-0.2,0.1-0.2,0.6-0.2,0.8
        c0,0.3,0.1,0.9,0.1,1.2c-0.1,0.3-0.3,0.8-0.5,0.9c-0.2,0.1-0.7-0.1-0.8,0c-0.3,0.2-0.3,1.1-0.6,1.3c-0.2,0.1-0.8,0-0.9,0.2
        c-0.2,0.2,0.1,0.9,0,1.2c-0.1,0.2-0.7,0.5-0.9,0.5c-0.2,0.1-0.8-0.2-0.9,0c-0.4,0.4,0.2,1.6,0.2,2.1c0,0.8,0,2.5-0.2,3.3
        c-0.1,0.6-0.8,1.7-1,2.2c-0.3,0.6-0.6,2-0.9,2.6c-0.2,0.4-0.9,1-1.2,1.4c-0.2,0.4-0.6,1.2-0.7,1.5c-0.3,0.5-0.7,1.7-1,2.2
        c-0.2,0.4-1,1.3-1,1.3l-2.5,0l-20.2-0.3l-0.4,0c0,0-0.7-0.5-0.9-0.7c-0.3-0.2-0.7-0.6-1-0.7c-0.3-0.1-1,0-1.3-0.1
        c-0.3-0.2-0.6-1-0.8-1.3c-0.2-0.5-0.5-1.7-0.7-2.2c-0.1-0.3-0.4-0.8-0.6-1.1c-0.3-0.5-0.8-1.4-1.1-1.9c-0.3-0.5-1.3-1.8-1.3-1.8
        h-5v-1.8h-3L245.3,431.8z"/>
    </g>
    <g id="Lowndes">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="lowndes-county" ref="lowndes-county" class="st10" d="M265.8,500.7L276,501l1.8,0v9.5h14c0,0-0.4,0.8-0.3,1.1c0.1,0.3,0.6,0.7,0.7,1
        c0.2,0.5-0.2,1.5,0,2c0.1,0.2,0.5,0.5,0.7,0.6c0.3,0.3,0.9,0.7,1,1.1c0,0.3-0.2,0.7-0.2,1c-0.1,0.4,0,1.2,0,1.6
        c0,0.3,0.2,1.1,0.1,1.4c-0.1,0.2-0.4,0.4-0.5,0.6c-0.1,0.3,0,0.9,0.1,1.4H287v2.5h-1.5v3.4h-2.3l-1,3v14.7h1.5v1.6h-1.6l0.1,2.8
        l-23.2-1.6c0-0.3,0-0.7,0-1c0-0.2,0.2-0.5,0.2-0.7c0-0.3-0.2-0.9-0.4-1.1c-0.2-0.1-0.6,0-0.8-0.1c-0.2-0.1-0.3-0.6-0.5-0.7
        c-0.3-0.2-1-0.3-1.3-0.6c-0.1-0.2-0.1-0.7-0.3-0.8c-0.1-0.1-0.6-0.2-0.8-0.2c-0.2,0-0.4,0.2-0.6,0.3c-0.2,0-0.8,0.1-1-0.1
        c-0.3-0.2-0.2-1.1-0.5-1.4c-0.1-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.8,0.1-1.1,0c-0.3-0.2-0.6-1-0.9-1.2c-0.1-0.1-0.4,0-0.6-0.1
        c-0.2-0.1-0.4-0.4-0.4-0.6c0-0.2,0.2-0.6,0.1-0.8c-0.1-0.2-0.4-0.6-0.6-0.7c-0.2-0.1-0.7,0-0.9-0.2c-0.2-0.2-0.2-0.7-0.2-0.9
        c0-0.2,0.2-0.7,0.1-1c0-0.3-0.3-0.7-0.5-0.9c-0.2-0.3-0.9-0.7-1.1-1c-0.1-0.2-0.3-0.9-0.1-1.1c0.1-0.2,0.7-0.1,0.9-0.2
        c0.3-0.2,0.5-0.8,0.7-0.9c0.2-0.1,0.8,0.2,1,0.1c0.2-0.1,0.6-0.4,0.6-0.6c0-0.2-0.4-0.5-0.5-0.7c-0.1-0.2-0.1-0.6,0-0.8
        c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.2,0.1-0.6,0.3-0.7c0.2-0.2,0.7-0.2,0.8-0.4c0.1-0.2,0.2-0.6,0.2-0.8c-0.1-0.2-0.5-0.3-0.6-0.5
        c-0.1-0.2-0.3-0.6-0.3-0.9c0-0.2,0.2-0.5,0.4-0.6c0.2-0.1,0.7,0,0.8,0.1c0.2,0.1,0.3,0.6,0.5,0.6c0.2,0,0.5-0.2,0.6-0.3
        c0.2-0.1,0.6-0.5,0.7-0.7c0.2-0.3,0.4-1,0.7-1.2c0.2-0.1,0.8,0.1,1,0.2c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.7,0,0.9,0
        c0.2-0.1,0.5-0.4,0.7-0.5c0.1-0.1,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.5,0.3-0.6c0.3-0.2,1.2,0.3,1.5,0.2c0.2-0.1,0.4-0.5,0.6-0.7
        c0.1-0.2,0.3-0.6,0.4-0.8c0.1-0.4,0-1.1-0.1-1.4c-0.1-0.2-0.6-0.4-0.7-0.6c-0.1-0.2-0.1-0.7-0.2-0.9c-0.1-0.2-0.6-0.3-0.7-0.5
        c-0.2-0.2-0.1-1-0.4-1.1c-0.2-0.1-0.4,0.5-0.6,0.5c-0.2,0-0.6-0.5-0.8-0.5c-0.2,0-0.6,0.2-0.8,0.2c-0.4,0-1.1-0.1-1.4-0.2
        c-0.3-0.1-0.7-0.7-1.1-0.9c-0.3-0.1-0.9-0.1-1.2-0.2c-0.2-0.2-0.3-0.8-0.5-1c-0.1-0.2-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.8-0.3-1
        c0-0.3,0.5-0.8,0.6-1.1c0.1-0.4,0.1-1.3,0.2-1.8c0.1-0.3,0.3-0.8,0.3-1.1c0-0.2,0.1-0.6,0.1-0.8c-0.1-0.2-0.5-0.6-0.6-0.8
        c-0.1-0.2-0.1-0.7-0.1-0.9c-0.1-0.2-0.4-0.4-0.4-0.6c-0.1-0.2,0.1-0.7,0-1c0-0.2-0.1-0.6-0.2-0.7c-0.2-0.2-0.8-0.3-1-0.5
        c-0.2-0.2-0.2-0.7-0.4-0.9c-0.1-0.2-0.5-0.5-0.6-0.8c-0.1-0.2-0.1-0.7-0.2-0.9c0-0.3,0-0.9-0.1-1.2c0-0.3-0.1-0.9-0.3-1.1
        c-0.1-0.1-0.2-0.1-0.3-0.2L265.8,500.7z"/>
    </g>
    <g id="Berrien">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="berrien-county" ref="berrien-county" class="st10" d="M281.7,453c0,0.4,0.1,1.2,0.2,1.5c0.1,0.4,0.4,1.2,0.7,1.4c0.2,0.2,0.9,0.1,1.1,0.3
        c0.2,0.2,0.3,0.9,0.4,1.3c0.1,0.4,0.1,1.1,0.3,1.5c0.1,0.2,0.5,0.4,0.6,0.6c0.3,0.3,0.7,1.2,1,1.5c0.3,0.2,1.1,0.6,1.2,0.7
        c0.1,0.1,1.4,0.5,1.8,0.8c0.3,0.3,0.6,1.1,0.7,1.5c0.1,0.5-0.2,1.6,0,2.1c0.1,0.3,0.6,0.6,0.7,0.9c0.2,0.5,0.2,1.5,0.4,2
        c0.1,0.3,0.5,0.8,0.7,1.1c0.2,0.3,0.6,0.9,0.6,1.3c0,0.2-0.4,0.5-0.5,0.7c-0.1,0.3,0.2,0.8,0.2,1c0,0.2,0,0.7-0.1,0.9
        c-0.1,0.3-0.8,0.5-0.9,0.8c-0.2,0.3-0.3,1-0.2,1.3c0,0.2,0.4,0.5,0.4,0.6c0.1,0.3,0.1,1,0.1,1.3c-0.1,0.3-0.5,0.8-0.5,1.2
        c0,0.2,0.5,0.4,0.5,0.6c0.1,0.3-0.1,1.1,0,1.5c0.1,0.2,0,0.5,0,0.5l0.4,4.3H279v10.5h-3v4.4l-10.1-0.3c0-0.2,0-0.3,0.1-0.4
        c0.1-0.2,0.3-0.7,0.5-0.9c0.1-0.2,0.7-0.4,0.7-0.6c0.1-0.3-0.3-0.9-0.3-1.2c0-0.3,0.4-0.8,0.4-1.1c0-0.2-0.2-0.6-0.3-0.7
        c-0.2-0.1-0.8,0.1-1-0.1c-0.2-0.2,0.2-0.9,0-1.2c-0.1-0.2-0.5-0.3-0.6-0.4c-0.1-0.1,0-0.5,0-0.6c-0.1-0.1-0.2-0.4-0.4-0.5
        c-0.2-0.1-0.7,0.4-0.9,0.4c-0.3-0.1-0.7-1-0.7-1.1c0-0.1,0.1-0.7,0.1-0.9c0-0.3-0.3-1-0.3-1.3c0-0.3,0.4-0.9,0.5-1.1
        c0.1-0.2,0.3-0.5,0.4-0.6c0.2-0.4,0.2-1.2,0.4-1.6c0.1-0.4,0.5-1.1,0.5-1.5c0-0.5-0.3-1.3-0.5-1.6c-0.2-0.4-0.8-1-1.2-1.3
        c-0.2-0.2-0.8-0.5-0.9-0.7c-0.2-0.2-0.5-0.8-0.5-1.1c0-0.4,0.3-1.1,0.4-1.4c0-0.3,0.1-0.9,0-1.2c-0.1-0.4-0.8-1-0.8-1.4
        c0-0.3,0.5-0.9,0.5-1.2c0-0.3-0.1-1-0.4-1.2c-0.3-0.3-1.2-0.6-1.6-0.6c-0.2,0-0.6,0.3-0.8,0.4c-0.4,0.1-1.3,0.2-1.6,0
        c-0.3-0.2-0.4-1.2-0.6-1.5c-0.3-0.4-1.2-0.8-1.5-1.1c-0.3-0.2-0.8-0.8-0.9-1.1c-0.1-0.2,0-0.7-0.1-0.9c-0.2-0.3-0.8-0.7-1.2-0.9
        c-0.3-0.1-1,0.1-1.2-0.1c-0.2-0.2-0.2-0.9-0.2-1.2c0-0.2,0.2-0.7,0.2-0.9c0-0.2-0.5-0.6-0.5-0.8c-0.1-0.3,0.2-1,0.2-1.3
        c0-0.2,0-0.7,0-1c0.3-0.1,0.5-0.2,0.6-0.3c0.2-0.3-0.2-1.2-0.1-1.5c0.1-0.4,0.3-1.2,0.6-1.5c0.2-0.3,0.9-0.6,1.2-0.8
        c0.4-0.4,1.3-1.8,1.3-1.8h1.7v-1.6h1.3v-7.6h3.2v-1.6l20.2,0.3c0,0.2-0.1,0.5-0.1,0.6c-0.1,0.4-0.9,0.9-0.8,1.3
        c0,0.4,1,0.8,1.2,1.2c0.2,0.4,0.3,1.3,0.2,1.8C282,451.7,281.7,452.5,281.7,453C281.7,452.9,281.7,452.9,281.7,453z"/>
    </g>
    <g id="Franklin">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="franklin-county" ref="franklin-county" class="st10" d="M284.4,105.4l-3.6,2.9l-2.2,1.6c0-0.2-0.6-0.1-0.8-0.2c-0.2-0.1-0.5-0.5-0.7-0.6
        c-0.2-0.1-0.6,0-0.8-0.1c-0.2,0-0.5-0.3-0.7-0.3c-0.2,0-0.6,0.4-0.8,0.4c-0.2,0-0.7-0.2-0.9-0.2c-0.1,0-0.3,0.3-0.5,0.3
        c-0.1,0-0.4,0-0.6-0.1c-0.1-0.1-0.3-0.4-0.5-0.4c-0.2,0-0.2,0.6-0.4,0.7c-0.2,0.1-0.6,0-0.8,0c-0.3-0.1-0.8-0.3-1-0.4
        c-0.3-0.1-0.8-0.3-1.1-0.3c-0.2-0.1-0.8,0-1-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2-0.1-0.7,0.2-0.8,0c-0.2-0.1-0.2-0.6-0.4-0.7
        c-0.2-0.2-0.6-0.3-0.9-0.4c-0.3,0-1,0.3-1,0.3l-4.5,3.9l1.1-2.4l0.2-2.2l-3.8-4.6l-1.5-2.1l1-13.2c0,0-0.9-2.7-1.5-3.4
        c0-0.1-0.1-0.1-0.2-0.2l2.5-1.3l19.2-0.7l5.4-5.2c0.3,0,0.6-0.1,0.7,0c0.2,0,0.5,0.3,0.7,0.4c0.2,0.1,0.8,0.1,0.8,0.1
        c0.1,0,0.5-0.7,0.6-0.9c0.1-0.2,0.3-0.6,0.5-0.6c0.2-0.1,0.6-0.1,0.7,0.1c0.3,0.2,0.2,1,0.3,1.3c0.1,0.2,0.4,0.8,0.4,0.8
        c0.1,0,0.7,0.6,1,0.8c0.2,0.1,0.6,0.2,0.8,0.3c0.2,0.1,0.5,0.4,0.5,0.6c0.1,0.2,0.1,0.6,0,0.7c-0.1,0.1-0.5,0-0.7-0.1
        c-0.1-0.1-0.2-0.5-0.4-0.5c-0.2-0.1-0.8-0.2-1.1-0.1c-0.2,0-0.4,0.3-0.6,0.3c-0.2,0.1-0.6,0.2-0.7,0.2c-0.2,0-0.5-0.4-0.7-0.5
        c-0.1-0.1-0.5,0-0.6-0.1c-0.2-0.1-0.4-0.5-0.6-0.5c-0.2,0-0.8,0.1-0.9,0.3c-0.1,0.2-0.1,0.6,0,0.8c0.1,0.1,0.5,0.3,0.6,0.4
        c0.1,0,0.3-0.1,0.4,0c0.2,0.1,0.2,0.7,0.2,0.8c0,0.1,0.6,0.4,0.8,0.4c0.3,0,0.9-0.3,1.1-0.5c0.1-0.1,0.1-0.4,0.2-0.4
        c0.2-0.2,0.9-0.1,1.2-0.1c0.2,0,0.6,0.2,0.8,0.4c0.1,0.1,0.3,0.3,0.4,0.3c0.2,0.1,0.7,0,0.9-0.1c0.2-0.1,0.4-0.3,0.5-0.4
        c0.1-0.1,0.4-0.3,0.6-0.3c-0.2,0.3-0.6,0.7-0.7,0.9c-0.3,0.5-0.8,1.6-1.2,2.1c-0.3,0.4-0.9,1.1-1.1,1.5c-0.3,0.5-0.6,1.5-0.8,2
        c-0.3,0.8-0.8,2.3-1,3.1c-0.2,0.6-0.6,1.5-0.7,2.3c-0.1,0.9,0.2,1.5,0.1,1.9c0,0.5-0.3,1.5-0.5,2c-0.1,0.4-0.5,1-0.4,1.3
        c0.1,0.2,0.6,0.5,0.7,0.7c0.1,0.2,0.2,0.7,0.2,0.9c0,0.6,0.1,2.1,0,2.6c-0.1,0.6-0.4,1.3-0.5,1.9c0,0.6-0.1,2.2-0.1,2.2
        L284.4,105.4z"/>
    </g>
    <g id="Rabun">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="rabun-county" ref="rabun-county" class="st10" d="M229.6,38.6c0,0-0.1-0.1-0.1-0.1c-0.2-0.3-0.5-1.1-0.5-1.5c0-0.2,0-0.7,0-0.9
        c0-0.2,0-0.7,0-1c0.1-0.3,0.5-0.6,0.6-0.9c0.1-0.3,0-1,0-1.3c0-0.2-0.2-0.7-0.1-0.9c0.1-0.2,0.6-0.2,0.8-0.3
        c0.2-0.2,0.1-1,0.3-1.2c0.2-0.2,0.8,0.1,1.1-0.1c0.1-0.1,0.3-0.4,0.4-0.5c0.1-0.2,0-0.6,0.1-0.7c0.1-0.2,0.4-0.6,0.5-0.7
        c0.2-0.2,0.6-0.4,0.8-0.4c0.3-0.1,0.9,0.2,1.2,0.1c0.3-0.1,1-0.6,1.1-0.9c0.1-0.2-0.2-0.6-0.3-0.8c-0.1-0.2-0.4-0.7-0.4-0.9
        c0-0.2-0.2-0.6-0.1-0.8c0.2-0.3,1.1,0,1.4-0.1c0.3-0.1,0.7-0.4,1-0.4c0.3-0.1,0.8,0,1.1,0c0.4,0,1.4,0.2,1.8-0.1
        c0.2-0.2,0.4-0.8,0.5-1.1c0.1-0.4-0.1-1.3-0.1-1.7c-0.1-0.4-0.4-1.2-0.4-1.6c0-0.3,0.1-0.8,0.1-1.1c0-0.2-0.1-0.6-0.3-0.9
        l45.2-0.4c0,0,0.8,0.8,0.8,1.2c0,0.4-0.9,1-1.1,1.4c-0.2,0.3-0.5,0.9-0.5,1.3c0,0.3,0.4,0.9,0.4,1.2c-0.1,0.5-1.3,0.9-1.3,1.4
        c0,0.2,0.6,0.5,0.6,0.8c0,0.2-0.6,0.3-0.8,0.5c-0.3,0.3-0.7,1-1.1,1.2c-0.3,0.1-1-0.1-1.3-0.1c-0.2,0-0.7-0.1-0.9,0
        c-0.3,0.2-0.3,1-0.6,1.2c-0.2,0.1-0.8-0.2-1-0.1c-0.4,0.3-0.1,1.5-0.4,1.8c-0.2,0.3-1.1,0.4-1.4,0.7c-0.3,0.3-0.8,1-1.1,1.3
        c-0.3,0.3-0.9,0.8-1.3,1c-0.4,0.1-1.3-0.1-1.7,0.1c-0.4,0.2-1,0.9-1.2,1.3c-0.2,0.4-0.6,1.2-0.7,1.6c0,0.3,0.5,1,0.3,1.2
        c-0.3,0.4-1.6-0.4-2-0.2c-0.3,0.1-0.6,0.8-0.5,1.1c0,0.4,1,1,0.9,1.4c-0.1,0.5-0.9,1.1-1.4,1.3c-0.4,0.1-1.4-0.3-1.9-0.2
        c-0.3,0-0.8,0.2-1,0.4c-0.2,0.3,0.1,1.2-0.1,1.5c-0.2,0.3-1.3,0.7-1.3,0.8s-0.2,1.5-0.2,2c0,0.5,0.2,1.4,0.1,1.8
        c-0.1,0.5-0.3,1.4-0.6,1.8c-0.3,0.5-1.3,1.1-1.8,1.5c-0.3,0.2-1,0.6-1.2,0.9c-0.2,0.3-0.2,1.2-0.1,1.6c0,0.1,0.1,0.2,0.2,0.4
        c-0.3,0.1-0.7,0-0.9-0.1c-0.4-0.2-0.6-1.2-0.9-1.5c-0.2-0.2-0.8-0.3-1.1-0.5c-0.2-0.1-0.5-0.7-0.8-0.7c-0.3,0-0.6,0.7-0.8,0.8
        c-0.3,0.1-0.9,0.1-1.2,0c-0.3-0.1-0.8-0.3-1-0.6c-0.2-0.2-0.1-1-0.4-1.2c-0.1-0.1-0.5-0.1-0.7-0.1c-0.2,0-0.3,0.3-0.5,0.4
        c-0.2,0.1-0.8-0.1-0.9,0.1c-0.1,0.1-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.6-0.4,0.6c-0.3,0.1-0.8-0.3-1.1-0.3c-0.2,0-0.5,0.2-0.7,0.3
        c-0.2,0.1-0.4,0.6-0.6,0.7c-0.2,0.1-0.6,0.2-0.8,0.2c-0.4,0-1.1-0.3-1.4-0.4c-0.3-0.1-0.6-0.7-0.9-0.7c-0.2,0-0.5,0.3-0.7,0.3
        c-0.2,0-0.6-0.2-0.7-0.3c-0.1-0.1-0.4-0.2-0.6-0.3c-0.1-0.1-0.4-0.1-0.6-0.2c-0.2-0.2-0.4-0.7-0.6-0.9c-0.2-0.2-0.6-0.4-0.9-0.5
        c-0.3-0.1-0.9-0.2-1.1-0.4c-0.3-0.3-0.5-1.2-0.5-1.7c0-0.3,0.2-0.7,0.2-1c0-0.3-0.1-0.8-0.2-1.1c0-0.2,0-0.7-0.1-0.8
        c-0.2-0.3-1.3-0.7-1.3-0.8c0-0.1-0.4-1.4-0.7-1.9c-0.2-0.3-0.6-1-0.9-1.1c-0.3-0.1-0.9,0-1.2-0.1c-0.2-0.1-0.6-0.3-0.8-0.4
        c-0.1,0-0.4,0-0.5,0c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.4,0.2-0.6,0.1c-0.2,0-0.5-0.3-0.6-0.4c-0.2,0-0.6,0.1-0.7,0.1
        c-0.3,0.1-1,0.6-1.3,0.5C229.9,39,229.7,38.8,229.6,38.6z"/>
    </g>
    <g id="Stephens">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="stephens-county" ref="stephens-county" class="st10" d="M284.4,73.1c0.1,0.2,0,0.8-0.1,1c-0.1,0.2-0.6,0.5-0.8,0.6c-0.3,0.1-0.9-0.1-1.2,0
        c-0.2,0.1-0.6,0.3-0.6,0.5c0,0.2,0.4,0.4,0.6,0.5c0.1,0,0.2,0,0.4,0l-5.4,5.2L258,81.7l-2.5,1.3c-0.5-0.4-1.5-0.9-2-1.2
        c-0.7-0.4-2.2-1.1-3-1.3c-0.3-0.1-0.8-0.2-1.3-0.3l9.7-23.6l2.2-0.9c0,0.1,0,0.2,0.1,0.2c0.1,0.2,0.6,0.2,0.8,0.3
        c0.3,0.1,0.9,0.2,1.1,0.3c0.3,0.1,0.8,0.6,1.2,0.7c0.2,0.1,0.7,0.1,0.9,0.2c0.3,0.2,0.7,0.9,1,1.2c0.3,0.3,1.1,0.8,1.4,1.2
        c0.4,0.4,1,1.2,1.4,1.6c0.4,0.4,1.5,1.1,1.9,1.6c0.2,0.3,0.7,1,0.8,1.2c0.1,0.1,1.6,0.1,2.1,0.3c0.2,0.1,0.6,0.3,0.8,0.5
        c0.3,0.2,0.7,0.8,1,0.9c0.3,0,0.9-0.3,1.1-0.5c0.1-0.1,0.3-0.4,0.5-0.5c0.3-0.1,1.1,0.1,1.3,0.3c0.2,0.2-0.2,0.9-0.1,1.1
        c0,0.2,0.4,0.7,0.4,0.8c0,0.1,0.2,0.7,0,0.8c-0.1,0.1-0.5,0.2-0.6,0.2c-0.4,0-1.1-0.5-1.4-0.4c-0.2,0.1-0.3,0.5-0.2,0.7
        c0.1,0.4,1,0.6,1.3,0.8c0.2,0.1,0.6,0.5,0.8,0.5c0.3,0,0.7-0.5,1-0.5c0.3,0,0.9,0.1,1.1,0.3c0.1,0.2,0.1,0.7,0.2,0.8
        c0.1,0.2,0.7,0.5,0.8,0.5c0.1,0,0.8,0.3,1.1,0.5c0.2,0.2,0.3,0.8,0.5,0.9c0.1,0.1,0.4,0.1,0.5,0.2
        C283.9,72.6,284.3,72.9,284.4,73.1z"/>
    </g>
    <g id="Bleckley">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="bleckley-county" ref="bleckley-county" class="st10" d="M281.6,330.4l-3.3-3l-13.2,12.9l1.9,2.8l-6.2,4.6l0.7,1.1l-0.6,0.6l-15.4-15.6
        c0-0.2,0.1-0.4,0.2-0.5c0.2-0.2,0.8,0,1-0.2c0.2-0.2,0.5-0.9,0.5-1.2c0-0.4-0.5-1-0.7-1.3c-0.1-0.3-0.3-1-0.4-1.3
        c-0.1-0.3,0-0.9-0.1-1.2c-0.1-0.2-0.4-0.4-0.4-0.6l27.5-16.2L281.6,330.4z"/>
    </g>
    <g id="Putnam">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="putnam-county" ref="putnam-county" class="st10" d="M280.2,218.9c0.3,0.3,1,0.9,0.8,1.3c-0.1,0.2-0.7,0-0.8,0.2c-0.1,0.1-0.2,0.6-0.1,0.7
        c0.1,0.2,0.6,0.4,0.6,0.6c0.1,0.2-0.1,0.8-0.4,1c-0.2,0.2-0.8,0.1-1.1,0c-0.1,0-0.3-0.3-0.4-0.3c-0.3,0.1-0.2,0.8-0.3,1.1
        c-0.1,0.2-0.3,0.6-0.5,0.7c-0.2,0.1-0.5,0.3-0.7,0.2c-0.2,0-0.4-0.4-0.6-0.4c-0.2,0-0.5,0.1-0.7,0.2c-0.1,0.1-0.2,0.5-0.4,0.6
        c-0.1,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.3-0.1-0.5-0.1c-0.2,0-0.6,0.4-0.8,0.5c-0.2,0.1-0.8,0.2-1,0.4c-0.2,0.2-0.3,0.7-0.3,0.9
        c0,0.2,0.2,0.5,0.2,0.7c0,0.1-0.1,0.3-0.1,0.4c-0.2,0.1-0.8,0.1-1,0c-0.2-0.1-0.4-0.6-0.4-0.8c0-0.2,0.1-0.7,0.1-0.9
        c0-0.3-0.2-0.9-0.5-1c-0.1-0.1-0.5-0.1-0.6,0c-0.2,0.1-0.4,0.4-0.4,0.6c0,0.3,0.5,0.7,0.6,1c0,0.2,0,0.6-0.2,0.8
        c-0.1,0.1-0.6-0.1-0.7,0c-0.1,0.1,0,0.6,0,0.8c0,0.1,0.3,0.3,0.3,0.4c0.1,0.2,0.1,0.5,0.1,0.7c-0.1,0.3-0.6,0.6-0.6,0.9
        c0,0.2,0.4,0.6,0.5,0.8c0.1,0.2,0.2,0.8,0,1c-0.1,0.2-0.6,0.2-0.6,0.4c0,0.3,0.8,0.6,0.8,0.9c0,0.2-0.7,0.3-0.8,0.5
        c-0.1,0.2-0.1,0.6-0.1,0.8c0,0.1-0.1,0.4-0.2,0.5c-0.1,0.1-0.2,0.4-0.4,0.5c-0.2,0.1-0.6,0.1-0.7,0c-0.1-0.1-0.1-0.5-0.1-0.6
        c0-0.3,0.3-0.9,0.1-1.2c-0.1-0.2-0.5-0.2-0.6-0.4c-0.1-0.2,0.1-0.7,0-0.9c-0.1-0.1-0.5-0.3-0.7-0.2c-0.2,0.1,0,0.7-0.1,0.8
        c-0.1,0.1-0.4,0.2-0.5,0.3c-0.1,0.2,0,0.7,0.1,0.9c0.1,0.2,0.5,0.3,0.6,0.5c0.1,0.2,0,0.7,0.1,0.9c0.1,0.2,0.3,0.5,0.3,0.6
        c0,0.2,0.1,0.8-0.1,0.9c-0.2,0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.5-0.7-0.8-0.7c-0.2,0-0.5,0.4-0.6,0.4c-0.1,0-0.4-0.1-0.5,0
        c-0.1,0.1-0.1,0.5-0.1,0.7c0.1,0.1,0.5,0.2,0.5,0.4c0.1,0.1,0,0.5-0.1,0.6c-0.1,0.1-0.6,0-0.8,0c-0.2,0-0.6,0.1-0.8,0.1
        c-0.2,0-0.5-0.1-0.7-0.1c-0.2,0.1-0.3,0.5-0.4,0.7c-0.1,0.2-0.1,0.6-0.3,0.7c-0.1,0-0.4-0.3-0.5-0.4c-0.2-0.1-0.6-0.3-0.7-0.5
        c-0.1-0.2,0-0.8-0.1-1.1c-0.2-0.2-0.9-0.2-1.1-0.4c-0.1-0.1-0.1-0.4-0.2-0.6c-0.1-0.2-0.5-0.5-0.7-0.5c-0.1,0-0.4,0.1-0.5,0
        c-0.1,0-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.5-0.2-0.6c-0.1-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.8,0-0.9-0.2c-0.1-0.2,0-0.7-0.1-0.9
        c-0.1-0.2-0.6-0.3-0.7-0.4c-0.1-0.2-0.3-0.7-0.3-0.9c-0.1-0.2,0-0.7-0.2-0.9c-0.1-0.1-0.4-0.3-0.5-0.3c-0.2,0.1-0.2,0.6-0.3,0.9
        c0,0.2,0.1,0.6,0.1,0.8c0,0.2-0.3,0.7-0.2,0.9c0,0.3,0.3,0.9,0.6,1.1c0.2,0.2,0.9,0.1,1.1,0.3c0.1,0.1-0.1,0.6,0,0.7
        c0.1,0.2,0.7,0.2,0.9,0.3c0.2,0.1,0.3,0.6,0.5,0.7c0.2,0.1,0.7,0.2,0.9,0.3c0.3,0.1,1,0.3,1.2,0.5c0.2,0.2,0.5,0.6,0.4,0.8
        c-0.1,0.2-0.7,0.1-1,0c-0.2-0.1-0.5-0.5-0.7-0.6c-0.1,0-0.4,0.1-0.6,0.1c-0.2,0-0.7,0-1-0.1c-0.2-0.1-0.5-0.4-0.7-0.5
        c-0.2-0.1-0.6-0.1-0.8-0.2c-0.4,0-1.3-0.3-1.5,0c-0.1,0.1,0.1,0.4,0.2,0.6l-1.5-0.5l-11.7,1.9l1.4-31.9l-0.2-0.2
        c0.5-0.1,1.5-0.3,1.9-0.4c0.8-0.2,2.5-0.5,3.4-0.7c0.5-0.1,1.5-0.4,1.9-0.6c0.5-0.1,1.4-0.4,1.8-0.4c0.8-0.2,2.5-0.4,3.3-0.5
        c1.1-0.2,3.1-1,4.2-1.2c0.4-0.1,1.2-0.2,1.6-0.3c0.6-0.1,1.6-0.6,2.2-0.7c0.2,0,0.5-0.1,0.8-0.1c-0.1,0.2-0.1,0.4,0,0.5
        c0.1,0.1,0.4,0,0.5,0c0.2,0,0.7-0.1,0.9,0.1c0.1,0.1,0.3,0.4,0.2,0.5c0,0.1-0.4,0.1-0.5,0.3c-0.1,0.2,0,0.6,0.1,0.7
        c0.1,0.1,0.5,0,0.6,0c0.1-0.1,0.1-0.4,0.3-0.5c0.1-0.1,0.5-0.2,0.6-0.2c0.2,0,0.7,0.2,0.9,0.4c0.1,0.1,0.2,0.5,0.2,0.6
        c0,0.2-0.1,0.6,0,0.7c0.1,0.1,0.4,0.1,0.5,0c0.2-0.1,0.1-0.6,0.3-0.7c0.1-0.1,0.5-0.3,0.7-0.3c0.2,0,0.6,0.3,0.7,0.5
        c0.1,0.2-0.1,0.6,0,0.8c0.1,0.2,0.6,0.4,0.6,0.6c0.1,0.3-0.2,0.9-0.3,1.2c0,0.1-0.2,0.4-0.2,0.5c0.1,0.3,0.8,0.4,1,0.6
        c0.2,0.1,0.5,0.4,0.6,0.7c0,0.2-0.2,0.5-0.2,0.7c0,0.2,0.5,0.7,0.3,0.9c-0.1,0.2-0.6,0.1-0.7,0.1c-0.1,0-0.4,0-0.4-0.1
        c-0.1,0-0.5,0-0.7-0.1c-0.2-0.1-0.2-0.6-0.3-0.8c-0.1-0.1-0.5-0.2-0.6-0.4c-0.1-0.2,0.1-0.6,0-0.7c-0.1-0.1-0.3-0.3-0.5-0.3
        c-0.1,0-0.3,0.3-0.4,0.4c-0.1,0.2,0,0.6,0.1,0.8c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.2,0.5,0.2,0.7c-0.1,0.2,0.1,0.6,0,0.7
        c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.2-0.4,0.6-0.3,0.7c0.2,0.2,0.5,0.5,0.7,0.4c0.3-0.1,0-0.6,0.1-0.8c0.1-0.1,0.4-0.2,0.5-0.3
        c0.1-0.1,0.1-0.6,0.3-0.7c0.2-0.1,0.6,0,0.8,0c0.1,0.1,0.5,0.2,0.5,0.4c0,0.1-0.3,0.3-0.3,0.5c0,0.2,0.2,0.6,0.4,0.6
        c0.1,0,0.2-0.2,0.3-0.3c0.2-0.2,0.3-0.8,0.6-0.9c0.1-0.1,0.4,0,0.5,0.1c0.1,0.1,0.4,0.3,0.3,0.5c0,0.1-0.3,0.1-0.4,0.2
        c-0.1,0.1-0.4,0.5-0.3,0.7c0.1,0.2,0.6,0.2,0.7,0.1c0.1-0.1,0.1-0.4,0.2-0.5c0.1-0.1,0.5-0.2,0.6-0.1c0.2,0.1,0.1,0.6,0,0.7
        c-0.1,0.1-0.3,0.5-0.4,0.6c-0.1,0.1-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.6-0.1,0.6c0.1,0.1,0.8-0.3,0.9-0.2c0.2,0.1,0.2,0.6,0.3,0.7
        c0.1,0.2,0.9,0.7,1.1,0.5c0.2-0.1,0-0.7,0.1-1c0-0.1,0.2-0.4,0.3-0.5c0.1-0.1,0.4-0.4,0.5-0.4c0.2,0,0.7,0.4,0.9,0.3
        c0.2-0.1-0.2-0.6-0.2-0.8c0.1-0.2,0.5-0.4,0.7-0.6c0.1-0.1,0.3-0.5,0.5-0.6c0.1-0.1,0.5,0,0.6,0c0.2,0.1,0.5,0.5,0.5,0.7
        c0,0.1-0.1,0.3-0.1,0.4c0.1,0.3,0.7,0.5,0.8,0.7c0.1,0.2,0.2,0.6,0.1,0.7c0,0.2-0.3,0.5-0.4,0.6c-0.3,0.2-1.1,0.2-1.2,0.5
        c-0.1,0.2,0.1,0.6,0.2,0.7c0.2,0.1,0.7-0.1,0.9-0.2c0.2-0.1,0.4-0.5,0.6-0.5c0.2,0,0.4,0.3,0.5,0.5c0.1,0.2,0.1,0.6,0.3,0.8
        c0.1,0.1,0.4,0.3,0.6,0.4C279.8,218.6,280.1,218.8,280.2,218.9z"/>
    </g>
    <g id="Wilcox">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="wilcox-county" ref="wilcox-county" class="st10" d="M234,378.9l0.1-11l17.7,0.2V367h1.5c0,0,0.2-0.6,0.4-0.7c0.3-0.2,0.9-0.1,1.3-0.1
        c0.4,0,1.2,0,1.6,0c0.3,0,0.9-0.4,1.2-0.3c0.3,0.1,0.6,0.5,0.8,0.7c0.2,0.2,0.8,0.5,1,0.8c0.2,0.3,0.3,1.1,0.4,1.5
        c0,0.1,0,0.5,0.2,0.6c0.2,0.1,0.7-0.3,0.9-0.3c0.2,0,0.5,0.2,0.8,0.4c0.2,0.2,0.5,0.4,0.6,0.5c0.1,0.2,0,0.7,0.1,1
        c0.1,0.3,0.4,0.8,0.7,0.9c0.2,0.1,0.6-0.1,0.8-0.2c0.2-0.1,0.2-0.7,0.4-0.7c0.2,0,0.4,0.5,0.5,0.7c0.1,0.3,0.2,0.9,0.2,1.2
        c0,0.3-0.3,0.7-0.4,1c0,0.4,0.3,1.1,0.2,1.5c-0.1,0.3-0.7,0.6-0.7,0.9c0,0.2,0.5,0.3,0.6,0.4c0.2,0.2,0.5,0.6,0.6,0.8
        c0.1,0.2-0.1,0.6,0,0.8c0.1,0.2,0.6,0.4,0.6,0.7c0.1,0.3,0,0.8-0.1,1.1c-0.1,0.1-0.5,0.2-0.5,0.4c-0.1,0.2,0,0.8,0.1,1.1
        c0.2,0.3,0.8,0.4,1,0.6c0.2,0.2,0.4,1.1,0.4,1.1c0,0.1-0.2,0.8-0.3,1.1c0,0.2,0.1,0.6,0.2,0.7c0.1,0.2,0.5,0.5,0.7,0.7
        c0.2,0.3,0.7,1.4,0.8,1.5c0.1,0,0.5,0.6,0.7,0.7c0.2,0.1,0.7,0,0.9,0c0.3,0.1,0.8,0.2,1.1,0.4c0.2,0.1,0.3,0.5,0.5,0.6
        c0.2,0.1,0.6,0.2,0.7,0.3c0.3,0.2,0.5,0.9,0.7,1.1c0.1,0.2,0.4,0.4,0.5,0.6c0.1,0.4-0.3,1.2-0.1,1.5c0.2,0.3,1,0.3,1.2,0.6
        c0.1,0.2,0.1,0.6,0.2,0.7c0,0.1,0,0.2,0.1,0.2c0.1,0.3,0.1,0.7,0.1,0.9c0,0.2-0.2,0.6-0.2,0.8c0.1,0.3,0.5,0.9,0.8,1.1
        c0.3,0.2,0.9,0.5,1.2,0.5c0.2,0,0.3-0.4,0.5-0.4c0.2,0,0.7,0.4,0.7,0.6c0.1,0.3,0.1,0.9-0.1,1.2c-0.1,0.2-0.6,0.4-0.7,0.6
        c0,0.1,0,0.3,0.1,0.5l-2.8,1.2h-27.1h-6.5v-1.1h-7.1L234,378.9z"/>
    </g>
    <g id="Baldwin">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="baldwin-county" ref="baldwin-county" class="st10" d="M291.4,252.7c0.1,0.3,0.1,1.1,0,1.4c-0.2,0.3-1,0.6-1.2,1c-0.2,0.3-0.1,1.2-0.2,1.5
            c-0.1,0.2-0.7,0.5-0.8,0.8c-0.1,0.2,0.2,0.7,0.3,0.9c0.1,0.3,0.3,0.9,0.5,1.1c0.2,0.3,0.9,0.5,1,0.8c0.2,0.4,0,1.5-0.1,2
            c-0.1,0.4-0.3,1.2-0.7,1.5c-0.3,0.2-1.1-0.2-1.3,0c-0.2,0.1-0.3,0.7-0.3,0.9c0,0.4,0.3,1.5,0.3,1.6c0,0.1-0.3,0.9-0.4,1.5
            c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.3-0.6-0.9-0.8-1.1c-0.3-0.2-1-0.5-1.2-0.8c-0.2-0.3-0.4-1-0.6-1.3c-0.3-0.3-1.4-0.6-1.4-0.6
            c0-0.1-0.7-0.7-0.8-1c-0.1-0.4,0.2-1.2,0.1-1.6c-0.1-0.3-0.5-0.9-0.8-1.1c-0.2-0.2-0.9-0.4-1.2-0.4c-0.2,0.1-0.3,0.6-0.5,0.7
            c-0.2,0.1-0.7,0.2-0.9,0.3c-0.5,0.2-1.5,0.4-2,0.7c-0.1,0.1-0.3,0.4-0.4,0.4c-0.4,0.2-1.3,0.2-1.8,0.3c-0.4,0.1-1.3,0.5-1.7,0.7
            c-0.3,0.1-0.8,0.5-1.1,0.7c-0.5,0.3-1.6,1.1-2.1,1.3c-0.3,0.1-1,0.3-1.3,0.4c-0.2,0.1-0.8,0.2-0.9,0.5c-0.3,0.4-0.1,1.5-0.4,1.8
            c-0.1,0.1-0.6,0.2-0.7,0.1c-0.3-0.1-0.8-0.6-1.2-0.7c-0.2,0-0.8,0.1-0.8,0.1l-5.9,2.8l-7.8-31.8l1.5,0.5c0.1,0.1,0.1,0.2,0.2,0.2
            c0.1,0,0.9,0.4,1.2,0.4c0.2,0,0.6,0,0.7,0.1c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.5,0.1,0.7,0.2c0.3,0,1.1-0.3,1.4,0
            c0.1,0.2-0.1,0.7-0.3,0.9c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.1,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.6-0.4,0.8c0,0.1,0.1,0.4,0.2,0.4
            c0.3,0.1,0.8-0.2,1-0.4c0.2-0.2,0.1-0.8,0.3-1.1c0.1-0.1,0.4-0.2,0.6-0.3c0.2-0.2,0.5-0.8,0.7-0.9c0.2-0.1,0.8-0.1,1,0
            c0.3,0.1,0.4,0.8,0.6,1c0.2,0.1,0.6,0.3,0.8,0.4c0.2,0,0.6-0.3,0.7-0.1c0.1,0.1-0.3,0.4-0.3,0.6c0,0.1,0,0.5,0.1,0.6
            c0.1,0.1,0.6,0.2,0.8,0.1c0.2-0.1,0.6-0.5,0.7-0.7c0.1-0.2-0.1-0.5,0-0.7c0.1-0.2,0.5-0.4,0.6-0.6c0.1-0.2-0.1-0.6,0-0.7
            c0.1-0.1,0.4-0.1,0.5,0c0.2,0.1,0.6,0.5,0.8,0.5c0.2,0,0.5-0.4,0.7-0.6c0.2-0.2,0.7-0.8,1-0.8c0.3,0,0.8,0.8,1.1,0.7
            c0.2,0,0.4-0.6,0.6-0.7c0.2-0.1,0.6-0.2,0.8,0c0.2,0.1,0.3,0.6,0.3,0.8c-0.1,0.2-0.5,0.1-0.6,0.3c-0.1,0.1,0,0.5,0.1,0.6
            c0.2,0.2,0.8,0.2,1.1,0.2c0.3,0.1,0.8,0,1,0.2c0.2,0.2,0.6,0.7,0.6,0.9c0,0.2-0.5,0.2-0.6,0.4c-0.1,0.2,0,0.5,0.1,0.7
            c0.2,0.2,0.7,0.3,0.9,0.4c0.3,0.1,1,0,1.3,0.1c0.2,0.1,0.5,0.5,0.7,0.6c0.3,0.1,1.2,0.4,1.5,0.1c0.2-0.1,0-0.6,0-0.8
            c0.1-0.2,0.5-0.5,0.6-0.7c0.1-0.1,0.1-0.5,0.3-0.5c0.2-0.1,0.7,0.2,0.9,0.1c0.1-0.1,0.2-0.4,0.1-0.5c-0.1-0.1-0.5-0.1-0.6-0.2
            c-0.1-0.2,0.2-0.8,0.1-1c-0.1-0.2-0.4-0.5-0.6-0.6l0-0.2l9.1,2.9c0,0,0,1.5-0.2,1.9c-0.2,0.2-0.9,0.3-1.1,0.6
            c-0.2,0.3,0,1.1,0,1.4c0.1,0.3,0.5,1,0.5,1.3c0,0.3-0.3,1-0.5,1.2c-0.2,0.2-0.7,0.3-0.9,0.5c-0.1,0.1-0.2,0.5-0.2,0.6
            c0,0.3,0.5,0.6,0.6,0.8c0.1,0.2,0.1,0.7,0.2,1c0.1,0.3,0.4,0.7,0.5,0.9c0.2,0.4,0,1.5,0.4,1.7c0.3,0.1,0.9-0.6,1.2-0.8
            c0.2-0.2,0.5-0.7,0.7-0.8c0.2-0.1,0.8,0,1-0.1c0.4-0.2,1-0.7,1.2-1c0.2-0.2,0.3-0.9,0.6-1c0.1-0.1,0.4-0.1,0.6-0.1
            c0,0.2,0,0.5,0.1,0.6C291.1,251.7,291.4,252.3,291.4,252.7z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="baldwin-county" ref="baldwin-county" class="st10" d="M277.2,239.8l0,0.2c0,0,0,0,0,0c-0.2,0-0.6,0-0.7,0.2c-0.1,0.2,0.3,0.4,0.3,0.6c0,0.2-0.2,0.7-0.4,0.9
            c-0.1,0.1-0.4,0.4-0.6,0.4c-0.2,0-0.3-0.5-0.4-0.6c-0.2-0.1-0.6,0.1-0.7,0c-0.1-0.1-0.3-0.4-0.3-0.5c0-0.2,0.3-0.4,0.4-0.5
            c0.1-0.2,0.4-0.5,0.5-0.7c0-0.2-0.1-0.5-0.1-0.7L277.2,239.8z"/>
    </g>
    <g id="Twiggs">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="twiggs-county" ref="twiggs-county" class="st10" d="M273.1,311.2L273.1,311.2l-27.5,16.2c0,0,0,0,0,0c-0.1-0.3,0.3-1,0.4-1.3
        c0.1-0.5,0.4-1.7,0.2-2.2c-0.2-0.5-1.3-1-1.7-1.3c-0.3-0.3-1.2-1-1.4-1.1c-0.1-0.1-0.5-1-0.7-1.3c-0.1-0.1-0.4-0.4-0.5-0.5
        c-0.3-0.4-0.8-1.3-0.9-1.8c-0.1-0.5,0.5-1.6,0.4-2.1c-0.1-0.4-1.1-1.2-1.1-1.3c0-0.1-0.3-1.3-0.1-1.6c0.2-0.2,0.9-0.1,1.1-0.3
        c0.2-0.2,0.2-1.2,0.2-1.3c0-0.1-0.5-0.7-0.6-1c-0.1-0.4,0.3-1.1,0.2-1.4c-0.1-0.3-0.9-0.7-0.8-1c0-0.2,0.5-0.3,0.6-0.5
        c0.1-0.4-0.1-1.2-0.2-1.5c-0.1-0.2-0.5-0.8-0.6-0.9c-0.1,0-0.6-1.3-0.9-1.6c-0.2-0.2-0.6-0.8-0.8-0.9c-0.2-0.1-0.8,0.2-1,0.2
        c-0.4-0.1-0.9-0.8-1.2-1c-0.2-0.1-0.5-0.3-0.7-0.4c-0.1-0.2-0.2-0.4-0.3-0.5c-0.2-0.2-0.9-0.4-1.1-0.7c-0.1-0.3,0.3-0.9,0.2-1.2
        c0-0.2-0.6-0.6-0.5-0.8c0.1-0.3,0.8-0.5,1.1-0.7c0.2-0.2,0.8-0.8,0.7-1.2c0-0.2-0.5-0.4-0.5-0.6c0-0.3,0.6-0.8,0.7-1.1
        c0-0.4-0.5-1-0.6-1.3c-0.1-0.2-0.2-0.6-0.1-0.8c0.2-0.3,1.4-0.5,1.4-0.5l0.7,0.6l4.3-3.8l4.9-6.4l-2.6-2.7l11-6.7L273.1,311.2z"/>
    </g>
    <g id="Clarke">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="clarke-county" ref="clarke-county" class="st10" d="M271.1,149.6c0.1,0.3,0.2,0.9,0.2,1.2c-0.2,1.1-1.4,3-1.9,4c-0.3,0.6-1.1,1.8-1.6,2.6
        c-0.2,0-0.4,0-0.6,0c-0.2-0.1-0.6-0.4-0.9-0.4c-0.3-0.1-1,0.2-1.4,0.1c-0.2-0.1-0.5-0.5-0.7-0.5c-0.3-0.1-0.8,0.2-1.1,0.2
        c-0.3,0-0.9,0.2-1.1,0c-0.3-0.3,0.1-1.2-0.1-1.5c-0.2-0.4-1.1-0.8-1.5-1c-0.3-0.1-1-0.2-1.2-0.5c-0.2-0.2-0.3-0.9-0.6-1.1
        c-0.2-0.2-0.8-0.2-1-0.3c-0.2-0.2-0.5-0.7-0.7-0.9c-0.1-0.1-0.4-0.2-0.5-0.2c-0.5-0.3-1.1-1.3-1.5-1.6c-0.3-0.3-1-0.6-1.2-0.9
        c-0.2-0.2-0.5-0.6-0.7-0.7c-0.2-0.1-0.7-0.2-0.9-0.2c-0.4,0-1.1,0.2-1.4,0.2c-0.4,0-1.5-0.3-1.6-0.3c-0.1,0-0.9,0.1-1.2,0
        c-0.4,0-1.3-0.4-1.7-0.5c-0.5-0.1-2.2-0.2-2.2-0.2l-2.8-4l0-0.1l2.7-1.8c0,0,0.9-0.2,1-0.5c0.1-0.2-0.3-0.6-0.4-0.8
        c-0.1-0.3-0.2-0.8-0.1-1.1c0.1-0.2,0.3-0.7,0.5-0.8c0.1,0,0.3,0.2,0.4,0.3c0.1,0.2-0.1,0.5,0,0.7c0.2,0.3,1,0.2,1.3,0.2
        c0.2,0,0.6,0,0.8-0.1c0.4-0.1,1.1-0.7,1.5-0.9c0.4-0.2,1.2-0.6,1.6-0.7c0.4-0.1,1.2-0.3,1.6-0.4c0.5-0.2,1.4-0.7,1.9-0.9
        c0.5-0.2,1.7-0.3,2-0.8c0.1-0.1-0.2-0.4-0.2-0.6c0.1-0.2,0.4-0.6,0.7-0.7c0.2,0,0.6,0.4,0.8,0.5c0.3,0.1,0.9,0,1.2-0.1
        c0.1-0.1,0.4-0.3,0.6-0.5c0.1,0,0.2,0.1,0.2,0.1c0.4,0.2,1.4,0.5,1.8,0.7c0.4,0.2,1.1,0.8,1.5,1c0.2,0.1,0.8,0.1,1,0.2
        c0.4,0.2,1.3,1.1,1.4,1.2c0,0.1,1,1,1.4,1.1c0.3,0.1,0.9-0.2,1.2-0.2c0.3,0,0.9,0,1.1,0.1c0.2,0.1,0.5,0.3,0.7,0.5
        c-0.3,0.2-0.7,0.5-0.9,0.7c-0.2,0.3-0.1,1-0.1,1.3c0,0.2,0,0.7,0.1,0.9c0,0.2,0.2,0.6,0.3,0.8c0.1,0.3,0.2,1,0.1,1.3
        c-0.1,0.3-0.8,0.5-1,0.7c-0.2,0.2-0.7,0.7-0.7,1c0,0.2,0.5,0.5,0.7,0.7c0.4,0.4,1.1,1.2,1.4,1.6C270,148.3,270.9,149,271.1,149.6z
        "/>
    </g>
    <g id="Oconee">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="oconee-county" ref="oconee-county" class="st10" d="M244.8,161.1c-0.1,0-0.2-0.1-0.2-0.1c-0.2,0-0.5,0-0.7,0.1c-0.2,0-0.7,0.3-1,0.2
        c-0.3-0.1-0.5-0.6-0.7-0.7c-0.3-0.3-1.1-0.8-1.5-1.1c-0.3-0.2-0.8-0.7-1-0.9c-0.2-0.2-0.6-0.7-0.9-0.8c-0.2-0.1-0.7,0-0.9-0.2
        c-0.1-0.1-0.2-0.4-0.3-0.5c-0.1-0.2-0.1-0.6-0.3-0.8c-0.1-0.2-0.7-0.4-0.8-0.5c-0.1-0.1-0.3-0.5-0.3-0.6c0-0.3,0.2-0.8,0.2-1
        c0-0.2,0.3-0.6,0.2-0.8c-0.2-0.2-0.9-0.1-1.2-0.1c-0.3,0-1,0.3-1.4,0.3c-0.2,0-0.6-0.4-0.8-0.4c-0.2-0.1-0.6,0-0.8,0
        c-0.2-0.1-0.7-0.4-0.8-0.6c-0.2-0.3,0-1-0.2-1.2c-0.1-0.2-0.5-0.6-0.8-0.8l10.6-7.2l0,0.1l2.8,4c0,0,1.7,0.1,2.2,0.2
        c0.4,0.1,1.3,0.4,1.7,0.5c0.3,0,1.2,0,1.2,0c0.1,0,1.2,0.2,1.6,0.3c0.4,0,1.1-0.2,1.4-0.2c0.2,0,0.7,0.1,0.9,0.2
        c0.2,0.1,0.5,0.5,0.7,0.7c0.3,0.2,1,0.6,1.2,0.9c0.4,0.4,1.1,1.3,1.5,1.6c0.1,0.1,0.4,0.2,0.5,0.2c0.2,0.2,0.5,0.7,0.7,0.9
        c0.2,0.1,0.8,0.1,1,0.3c0.2,0.2,0.3,0.9,0.6,1.1c0.2,0.2,0.9,0.3,1.2,0.5c0.4,0.2,1.3,0.6,1.5,1c0.2,0.3-0.2,1.2,0.1,1.5
        c0.2,0.2,0.8,0,1.1,0c0.3,0,0.8-0.3,1.1-0.2c0.2,0,0.5,0.5,0.7,0.5c0.3,0.1,1-0.2,1.4-0.1c0.2,0,0.6,0.4,0.9,0.4
        c0.1,0,0.3,0,0.6,0c-0.1,0.1-0.2,0.3-0.2,0.4c-0.3,0.5-1,1.6-1.3,2.1c-0.2,0.4-0.9,1-0.9,1.4c0,0.4,0.6,0.9,0.7,1.3
        c0,0.2-0.2,0.7-0.2,0.9c0.1,0.2,0.4,0.4,0.5,0.5c0.2,0.2,0.8,0.4,0.9,0.6c0.1,0.3-0.1,0.9-0.2,1.2c0,0.2-0.3,0.7-0.2,0.9
        c0.1,0.3,0.4,0.8,0.7,1.1c-0.1,0.2-0.3,0.5-0.4,0.6c-0.2,0.2-0.7,0.4-0.9,0.6c-0.2,0.3-0.2,1-0.3,1.3c0,0.2,0,0.7-0.2,0.8
        c-0.2,0.2-1.1,0.5-1.1,0.5l-10,3.9c-0.3-0.2-0.7-0.4-0.8-0.6c-0.2-0.2-0.5-0.7-0.7-0.9c-0.2-0.2-0.4-0.6-0.6-0.7
        c-0.2-0.1-0.7-0.2-0.9-0.2c-0.3-0.1-0.9-0.3-1.1-0.5c-0.1-0.2,0.2-0.8,0.1-1.1c-0.1-0.3-0.6-0.8-0.7-1.1c-0.1-0.2-0.2-0.7-0.2-0.9
        c0-0.3,0-0.8,0-1c-0.1-0.4-0.5-1.3-0.7-1.7c-0.1-0.2-0.3-0.6-0.4-0.8c-0.2-0.3-0.8-0.6-1-0.9c-0.1-0.3,0.1-1.1,0-1.5
        c-0.1-0.3-0.4-0.8-0.6-1c-0.2-0.1-0.6,0.1-0.8,0c-0.2-0.1-0.3-0.7-0.5-0.8C245.6,161.6,245.2,161.3,244.8,161.1z"/>
    </g>
    <g id="Morgan">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="morgan-county" ref="morgan-county" class="st10" d="M254.7,175.6c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.2,0.7,0.7,0.8,1c0.1,0.2,0,0.7,0.1,0.9
        c0.1,0.3,0.7,0.5,0.8,0.7c0.1,0.3-0.1,0.9,0,1.1c0.1,0.2,0.3,0.6,0.4,0.7c0.2,0.1,0.6,0.2,0.7,0.3c0.2,0.1,0.6,0.3,0.7,0.5
        c0.1,0.3-0.1,1,0,1.3c0.1,0.3,0.5,0.9,0.6,1.2c0.1,0.2,0.2,0.7,0.3,0.9c0.1,0.1,0.4,0.3,0.5,0.5c0.2,0.3,0.1,1,0.2,1.3
        c0.1,0.2,0.3,0.7,0.5,0.9c0.1,0.2,0.4,0.7,0.6,0.9c0.1,0.3,0.3,0.9,0.4,1.2c0.3,0.5,1.4,1.3,1.8,1.8c0.2,0.3,0.5,1.1,0.7,1.4
        c0.2,0.3,0.5,0.8,0.7,1c0.1,0.1,0.5,0.3,0.6,0.4c0.3,0.2,0.8,0.5,1,0.8c0.1,0.2,0.1,0.6,0.2,0.8c0,0.2,0,0.6,0,0.8
        c0.1,0.5,0.4,1.3,0.5,1.8c0,0.3,0,1.1,0,1.4c0,0.2,0,0.4,0,0.6c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3
        c0-0.1,0-0.3,0-0.4c-0.1-0.1-0.4-0.3-0.4-0.4c0-0.1,0-0.2,0-0.2c-0.1-0.2-0.7-0.7-1-0.6c-0.2,0.1-0.2,0.6-0.2,0.7
        c0,0.1,0,0.2,0,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.1,0.3,0.2
        c0.1,0.1,0.1,0.3,0.1,0.3c0,0.1,0.2,0.3,0.3,0.4c-0.1,0.1-0.3,0.1-0.3,0.2c-0.2,0.1-0.5,0.5-0.7,0.6c-0.3,0.1-0.8,0.3-1.1,0.3
        c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.2,0.1-0.7,0.1-1c0-0.2-0.3-0.5-0.3-0.7c0-0.3,0.3-0.8,0.4-1
        c0-0.2,0-0.6-0.1-0.8c-0.1-0.1-0.5-0.2-0.6-0.1c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.7,0,0.9c0,0.1-0.1,0.3-0.2,0.4
        c-0.1,0.2,0,0.6-0.2,0.7c-0.1,0.1-0.4-0.3-0.6-0.3c-0.2,0-0.7,0.1-0.9,0.1c-0.1,0-0.3-0.3-0.4-0.3c-0.2-0.1-0.7-0.2-0.9-0.2
        c-0.2,0-0.6,0.3-0.7,0.5c-0.1,0.2,0,0.6,0.1,0.7c0.1,0.1,0.4,0,0.6,0c0.1,0,0.2-0.3,0.3-0.3c0.2-0.1,0.6,0,0.7,0.1
        c0.1,0.1-0.1,0.4-0.1,0.5c0,0.2-0.1,0.6,0,0.7c0.1,0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.2-0.4,0.3-0.5c0.1-0.1,0.4-0.1,0.5-0.1
        c0.2,0,0.5,0.1,0.7,0.3c0.1,0.1,0.2,0.3,0.1,0.4c-0.1,0.1-0.4,0.1-0.5,0.3c0,0-0.1,0.1-0.1,0.2c-0.3,0-0.6,0.1-0.8,0.1
        c-0.6,0.1-1.6,0.6-2.2,0.7c-0.4,0.1-1.2,0.2-1.6,0.3c-1.1,0.3-3.1,1-4.2,1.2c-0.8,0.2-2.5,0.4-3.3,0.5c-0.5,0.1-1.4,0.3-1.8,0.4
        c-0.5,0.1-1.5,0.4-1.9,0.6c-0.8,0.2-2.5,0.6-3.4,0.7c-0.4,0.1-1.5,0.3-1.9,0.4l-14.6-10.9c0-0.4,0-0.8,0-1c0-0.3,0.2-1,0.2-1.4
        c0-0.4-0.3-1.2-0.2-1.6c0-0.5,0.7-1.4,0.6-1.8c-0.1-0.4-1.2-0.8-1.3-1.2c-0.1-0.4,0.2-1.3,0.3-1.7c0.1-0.4,0.7-1.1,0.6-1.5
        c0,0,0,0,0,0l17.8-25.4c0.4,0.2,0.8,0.6,1,0.8c0.2,0.2,0.3,0.7,0.5,0.8c0.2,0.1,0.6-0.1,0.8,0c0.3,0.1,0.5,0.7,0.6,1
        c0.1,0.4-0.1,1.1,0,1.5c0.1,0.3,0.8,0.6,1,0.9c0.1,0.2,0.3,0.6,0.4,0.8c0.2,0.4,0.6,1.2,0.7,1.7c0.1,0.3,0,0.8,0,1
        c0,0.2,0.1,0.7,0.2,0.9c0.1,0.3,0.6,0.7,0.7,1.1c0.1,0.3-0.3,0.9-0.1,1.1c0.1,0.3,0.8,0.4,1.1,0.5c0.2,0.1,0.7,0.1,0.9,0.2
        c0.2,0.1,0.5,0.6,0.6,0.7c0.2,0.2,0.5,0.7,0.7,0.9C254.1,175.2,254.4,175.4,254.7,175.6z"/>
    </g>
    <g id="Cook">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="cook-county" ref="cook-county" class="st10" d="M237.6,495c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.2-0.4-0.1-0.6c0.1-0.3,0.9-0.5,1-0.8
        c0.1-0.2-0.2-0.7-0.2-1c0-0.2,0.3-0.5,0.5-0.7c0.1-0.1,0.4-0.2,0.5-0.4c0.1-0.1,0.3-0.5,0.3-0.6c0.1-0.3-0.2-1.3-0.2-1.3
        c0,0,0.2-0.7,0.2-0.9c0-0.3,0-1.3,0-1.4c0,0,0.5-0.6,0.7-0.8c0.2-0.1,0.7-0.2,0.7-0.3s-0.2-1-0.1-1.4c0-0.3,0.3-0.9,0.3-1.2
        c0-0.3-0.2-0.8-0.1-1c0.1-0.2,0.5-0.4,0.7-0.5c0.2-0.1,0.7-0.2,0.9-0.4c0.2-0.2,0.3-0.7,0.3-0.9c0.1-0.2,0.1-0.8,0.3-1
        c0.1-0.1,0.5-0.1,0.6-0.3c0.2-0.2,0.3-0.6,0.4-0.8c0-0.2-0.2-0.5-0.2-0.7c0-0.2,0.5-0.6,0.5-0.8c0-0.1-0.1-0.4-0.2-0.5
        c-0.1-0.2-0.5-0.4-0.6-0.6c-0.1-0.4,0.2-1.4,0.4-1.8c0.1-0.3,0.7-0.7,0.7-1c0-0.2-0.4-0.9-0.4-0.9c0-0.1,0.3-0.9,0.3-1.2
        c0-0.3-0.3-0.9-0.4-1.2c-0.1-0.3-0.2-0.8-0.2-1c0-0.2,0-0.7-0.1-1c-0.1-0.2-0.4-0.6-0.4-0.8c0-0.3,0.2-0.8,0.2-1.1
        c0.1-0.4,0.2-1.3,0.2-1.8l5.2,0.2c0,0,0-0.9,0.2-1.1c0.2-0.2,1-0.2,1.3-0.3c0.2-0.1,0.6-0.1,1-0.2c0,0.3,0,0.8,0,1
        c0,0.3-0.3,1-0.2,1.3c0,0.2,0.5,0.6,0.5,0.8c0,0.2-0.2,0.7-0.2,0.9c0,0.3,0,1,0.2,1.2c0.2,0.2,0.9,0,1.2,0.1
        c0.3,0.1,1,0.6,1.2,0.9c0.1,0.2,0,0.7,0.1,0.9c0.2,0.3,0.7,0.9,0.9,1.1c0.3,0.3,1.2,0.7,1.5,1.1c0.3,0.3,0.3,1.3,0.6,1.5
        c0.3,0.2,1.2,0.1,1.6,0c0.2,0,0.6-0.3,0.8-0.4c0.4,0,1.3,0.3,1.6,0.6c0.2,0.2,0.4,0.9,0.4,1.2c0,0.3-0.5,0.9-0.5,1.2
        c0,0.4,0.7,1,0.8,1.4c0.1,0.3,0,0.9,0,1.2c0,0.4-0.4,1.1-0.4,1.4c0,0.3,0.3,0.9,0.5,1.1c0.2,0.2,0.7,0.5,0.9,0.7
        c0.3,0.3,0.9,0.9,1.2,1.3c0.3,0.4,0.5,1.2,0.5,1.6c0,0.4-0.3,1.1-0.5,1.5c-0.1,0.4-0.2,1.3-0.4,1.6c-0.1,0.2-0.3,0.5-0.4,0.6
        c-0.2,0.3-0.5,0.8-0.5,1.1c0,0.3,0.3,1,0.3,1.3c0,0.2-0.1,0.8-0.1,0.9c0,0.1,0.4,1,0.7,1.1c0.2,0.1,0.7-0.4,0.9-0.4
        c0.2,0,0.3,0.3,0.4,0.5c0.1,0.1,0,0.5,0,0.6c0.1,0.2,0.5,0.3,0.6,0.4c0.1,0.3-0.2,0.9,0,1.2c0.2,0.2,0.8-0.1,1,0.1
        c0.2,0.1,0.3,0.5,0.3,0.7c0,0.3-0.4,0.8-0.4,1.1c0,0.3,0.4,0.9,0.3,1.2c-0.1,0.2-0.6,0.4-0.7,0.6c-0.2,0.2-0.4,0.7-0.5,0.9
        c0,0.1,0,0.3-0.1,0.4l-17.8-0.5c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.8,0.1-1,0c-0.2-0.1-0.6-0.4-0.7-0.6c-0.1-0.2,0.1-0.8,0-1
        c-0.1-0.2-0.5-0.4-0.7-0.5c-0.2,0-0.5,0.2-0.6,0.2c-0.4,0-0.9-0.7-1.3-0.6c-0.2,0-0.5,0.5-0.7,0.6c-0.3,0-0.7-0.4-1-0.4
        c-0.1,0-0.3,0.1-0.4,0.1c-0.4,0-1.2-0.4-1.5-0.6c-0.2-0.2-0.4-0.9-0.6-1.1c-0.1-0.1-0.4-0.3-0.6-0.4c-0.1-0.1-0.4-0.2-0.5-0.3
        C237.8,495.2,237.7,495.1,237.6,495z"/>
    </g>
    <g id="Pulaski">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="pulaski-county" ref="pulaski-county" class="st10" d="M258.2,352l8.2,8.7l-3.3,3.3l2.1,2.8l-3.3,2.8c-0.3-0.2-0.6-0.3-0.8-0.4
        c-0.2,0-0.7,0.4-0.9,0.3c-0.1-0.1-0.1-0.4-0.2-0.6c-0.1-0.4-0.2-1.2-0.4-1.5c-0.2-0.3-0.7-0.6-1-0.8c-0.2-0.2-0.5-0.6-0.8-0.7
        c-0.3-0.1-0.9,0.3-1.2,0.3c-0.4,0.1-1.2,0-1.6,0c-0.3,0-1-0.1-1.3,0.1c-0.2,0.1-0.4,0.7-0.4,0.7h-1.5v1.1l-17.7-0.2l0.3-21.2
        c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.2,0.7-0.4,0.9-0.6c0.2-0.2,0.4-0.7,0.4-1c0.1-0.4-0.1-1.2,0-1.6c0.1-0.3,0.5-0.9,0.7-1.3
        c0.2-0.3,0.5-1,0.7-1.3c0.4-0.6,1.2-1.7,1.6-2.2c0.3-0.4,0.9-1.1,1.1-1.5c0.5-0.6,1.3-2,1.8-2.6c0.1-0.2,0.4-0.6,0.6-0.7
        c0.2-0.1,0.6,0.2,0.8,0.3c0.3,0.1,0.9,0.7,1.3,0.7c0.2,0,0.6-0.3,0.7-0.5c0.1-0.1,0.1-0.3,0.1-0.5l15.4,15.6L258.2,352z"/>
    </g>
    <g id="Banks">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="banks-county" ref="banks-county" class="st10" d="M233.5,86.4C233.5,86.4,233.5,86.4,233.5,86.4c0.2-0.4,0.5-1.2,0.8-1.5c0.2-0.2,0.6-0.5,0.8-0.6
        c0.4-0.2,1.2-0.2,1.5-0.4c0.3-0.1,0.7-0.6,0.8-0.8c0.2-0.3,0.3-1,0.5-1.2c0.1-0.1,0.5-0.3,0.6-0.4c0.1-0.2,0.2-0.7,0.4-0.9
        c0.1-0.1,0.5-0.2,0.7-0.3c0.3-0.1,1-0.1,1.3-0.2c0.3-0.2,0.6-1.1,1-1.1c0.3,0,0.8,0.6,1.1,0.6c0.4,0.1,1.2-0.1,1.6-0.1
        c0.8,0,2.3,0.3,3.1,0.4c0.4,0.1,1,0.2,1.6,0.3c0.5,0.1,1,0.2,1.3,0.3c0.8,0.3,2.3,0.9,3,1.3c0.5,0.3,1.5,0.7,2,1.2
        c0.1,0.1,0.1,0.1,0.2,0.2c0.6,0.7,1.5,3.4,1.5,3.4l-1,13.2l1.5,2.1l3.8,4.6l-0.2,2.2l-1.1,2.4c0,0-0.5,0.3-0.6,0.5
        c-0.2,0.4-0.5,1.3-0.9,1.6c-0.3,0.3-0.9,0.7-1.5,0.7c-0.4,0-1.1,0.1-1.5,0.3c-0.2,0.1-0.4,0.2-0.6,0.4c0,0-0.1-0.1-0.1-0.1
        c-0.2-0.3-0.8-0.7-1-0.9c-0.6-0.5-1.8-1.5-2.4-2.1c-0.3-0.4-0.9-1.2-1.2-1.6c-0.3-0.3-0.8-1-1.1-1.3c-0.2-0.2-0.8-0.5-1-0.8
        c-0.2-0.2-0.4-0.8-0.7-0.9c-0.3-0.2-1.1,0-1.4,0.1c-0.3,0.1-0.7,0.5-1,0.5c-0.3,0.1-0.9,0-1.3,0c-0.5,0-1.6-0.1-2.2,0
        c-0.4,0-1.2,0.4-1.6,0.4c-0.5,0-1.6-0.2-2-0.5c-1.5-0.8-4.2-3.1-5.1-4.5c0,0,0,0,0,0c-0.2-0.3-0.1-1.1-0.4-1.3
        c-0.2-0.2-0.7-0.1-0.9-0.2c-0.3-0.2-0.9-0.7-1.1-0.9c-0.2-0.2-0.6-0.7-0.6-1c0-0.3,0.3-0.9,0.3-1.2c0-0.3,0.1-1,0-1.3
        c-0.1-0.4-0.6-1.1-0.9-1.4c-0.2-0.2-0.8-0.2-0.9-0.4c-0.2-0.2-0.6-0.7-0.5-1c0.1-0.3,0.8-0.5,0.9-0.7c0.2-0.2,0.4-0.7,0.5-1
        c0-0.3-0.4-0.9-0.4-1.3c0-0.4,0.3-1,0.5-1.3c0.2-0.4,0.8-1,1.1-1.2c0.3-0.2,1-0.3,1.3-0.5c0.2-0.1,0.7-0.4,0.8-0.6
        C232.9,87.4,233.3,86.8,233.5,86.4z"/>
    </g>
    <g id="Habersham">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="habersham-county" ref="habersham-county" class="st10" d="M261.3,54.3c0.2,0.3-0.2,1-0.2,1.5l-2.2,0.9l-9.7,23.6c-0.6-0.1-1.2-0.3-1.6-0.3
        c-0.8-0.1-2.3-0.4-3.1-0.4c-0.4,0-1.2,0.2-1.6,0.1c-0.3-0.1-0.7-0.7-1.1-0.6c-0.4,0.1-0.7,0.9-1,1.1c-0.3,0.2-1,0.1-1.3,0.2
        c-0.2,0.1-0.6,0.1-0.7,0.3c-0.2,0.2-0.2,0.7-0.4,0.9c-0.1,0.1-0.5,0.3-0.6,0.4c-0.2,0.3-0.3,1-0.5,1.2c-0.2,0.2-0.6,0.7-0.8,0.8
        c-0.3,0.2-1.2,0.2-1.5,0.4c-0.2,0.1-0.7,0.4-0.8,0.6c-0.3,0.3-0.5,1.1-0.7,1.4c0,0,0,0,0,0.1l-5.2-8.7c0-0.4,0.3-1.3,0.6-1.6
        c0.3-0.3,1.3-0.2,1.6-0.5c0.2-0.2,0.3-0.8,0.5-1c0.3-0.3,1.2-0.6,1.5-0.9c0.3-0.3,0.7-1,0.8-1.4c0.1-0.4,0.3-1.3,0.1-1.7
        c-0.3-0.5-1.6-0.7-1.9-1.2c-0.2-0.3,0.1-1.2,0-1.5c-0.1-0.4-0.6-1-0.7-1.4c-0.1-0.3-0.2-1-0.2-1.4c0-0.2,0.1-0.5,0.1-0.7
        c0.1-0.4,0.4-1.3,0.4-1.7c0-0.3-0.2-1.1-0.5-1.3c-0.2-0.3-1.1-0.3-1.3-0.5c-0.3-0.3-0.5-1.2-0.5-1.6c0-0.4,0.5-1.3,0.7-1.6
        c0.2-0.3,0.5-0.9,0.8-1.1c0.1-0.1,0.5-0.1,0.7-0.3c0.3-0.3,0.3-1.3,0.3-1.7c0-0.4-0.3-1.4-0.1-1.8c0.1-0.4,0.8-1.1,1.1-1.4
        c0.2-0.2,0.9-0.2,1-0.5c0.1-0.4-0.7-1-0.8-1.4c-0.1-0.4,0.3-1.2,0.2-1.5c-0.1-0.2-0.7-0.4-0.8-0.7c0-0.2,0.4-0.5,0.4-0.7
        c0-0.4-0.2-1.3-0.6-1.5c-0.3-0.2-1.2,0.2-1.5,0c-0.2-0.1-0.3-0.6-0.5-0.8c-0.3-0.3-1-0.9-1.4-1c-0.2-0.1-0.8,0-1-0.1
        c-0.2-0.2-0.1-1-0.4-1.2c0,0,0,0-0.1-0.1c0.1-0.2,0.3-0.4,0.3-0.5c0.1-0.2-0.1-0.5,0-0.7c0.1-0.1,0.6,0,0.8,0
        c0.2-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.2-0.7,0.4-1c0.1-0.1,0.2-0.4,0.4-0.5c0.1,0.2,0.3,0.4,0.5,0.4c0.3,0.1,1-0.4,1.3-0.5
        c0.2,0,0.6-0.1,0.7-0.1c0.2,0,0.5,0.3,0.6,0.4c0.2,0,0.5-0.1,0.6-0.1c0.1-0.1,0.3-0.4,0.4-0.4c0.1,0,0.3,0,0.5,0
        c0.2,0,0.6,0.3,0.8,0.4c0.3,0.1,0.9-0.1,1.2,0.1c0.3,0.2,0.7,0.8,0.9,1.1c0.2,0.4,0.7,1.8,0.7,1.9c0,0.1,1.1,0.5,1.3,0.8
        c0.1,0.2,0.1,0.6,0.1,0.8c0,0.3,0.2,0.8,0.2,1.1c0,0.3-0.3,0.7-0.2,1c0,0.4,0.2,1.4,0.5,1.7c0.2,0.2,0.8,0.3,1.1,0.4
        c0.2,0.1,0.7,0.4,0.9,0.5c0.2,0.2,0.4,0.7,0.6,0.9c0.1,0.1,0.4,0.2,0.6,0.2c0.1,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.5,0.3,0.7,0.3
        c0.2,0,0.5-0.3,0.7-0.3c0.3,0,0.7,0.6,0.9,0.7c0.3,0.2,1,0.4,1.4,0.4c0.2,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.6,0.6-0.7
        c0.1-0.1,0.5-0.3,0.7-0.3c0.3,0,0.9,0.4,1.1,0.3c0.2-0.1,0.3-0.5,0.4-0.6c0.1-0.1,0.1-0.5,0.2-0.6c0.2-0.1,0.7,0,0.9-0.1
        c0.1-0.1,0.3-0.3,0.5-0.4c0.2,0,0.5,0,0.7,0.1c0.2,0.2,0.2,0.9,0.4,1.2c0.2,0.2,0.7,0.5,1,0.6c0.3,0.1,0.9,0.1,1.2,0
        c0.3-0.1,0.6-0.8,0.8-0.8c0.3,0,0.6,0.5,0.8,0.7c0.3,0.2,0.9,0.3,1.1,0.5c0.3,0.3,0.5,1.3,0.9,1.5c0.2,0.1,0.6,0.1,0.9,0.1
        c0.2,0.3,0.4,0.5,0.5,0.7c0,0.3-0.3,0.8-0.2,1.1C260.4,54,261.2,54,261.3,54.3z"/>
    </g>
    <g id="Tift">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="tift-county" ref="tift-county" class="st10" d="M230,434.7l0-3.3l15.3,0.4l-0.1,2.7h3v1.8h5c0,0,1,1.3,1.3,1.8c0.3,0.5,0.8,1.4,1.1,1.9
        c0.2,0.3,0.5,0.8,0.6,1.1c0.2,0.5,0.5,1.7,0.7,2.2c0.2,0.3,0.5,1.1,0.8,1.3c0.3,0.2,1,0,1.3,0.1c0.3,0.1,0.8,0.5,1,0.7
        c0.2,0.2,0.9,0.7,0.9,0.7l0.4,0v1.6h-3.2v7.6h-1.3v1.6H255c0,0-0.9,1.4-1.3,1.8c-0.3,0.3-1,0.6-1.2,0.8c-0.2,0.3-0.5,1.1-0.6,1.5
        c-0.1,0.4,0.3,1.2,0.1,1.5c-0.1,0.1-0.3,0.2-0.6,0.3c-0.3,0.1-0.8,0.2-1,0.2c-0.3,0.1-1,0-1.3,0.3c-0.2,0.2-0.2,1.1-0.2,1.1
        l-5.2-0.2l-14.4-0.5l0.1-12.4h-1.3V445h1.5L230,434.7z"/>
    </g>
    <g id="Brooks">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="brooks-county" ref="brooks-county" class="st10" d="M248,500.2c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.2,0.8,0.3,1.1c0,0.3,0,0.9,0.1,1.2
        c0,0.2,0.1,0.7,0.2,0.9c0.1,0.2,0.5,0.6,0.6,0.8c0.1,0.2,0.2,0.7,0.4,0.9c0.2,0.2,0.8,0.3,1,0.5c0.1,0.1,0.2,0.5,0.2,0.7
        c0,0.2-0.1,0.7,0,1c0.1,0.2,0.4,0.5,0.4,0.6c0.1,0.2,0.1,0.7,0.1,0.9c0.1,0.2,0.5,0.6,0.6,0.8c0.1,0.2,0,0.6-0.1,0.8
        c-0.1,0.3-0.3,0.8-0.3,1.1c-0.1,0.4-0.1,1.4-0.2,1.8c-0.1,0.3-0.5,0.8-0.6,1.1c0,0.3,0.2,0.8,0.3,1c0.1,0.2,0.5,0.3,0.7,0.5
        c0.2,0.2,0.3,0.8,0.5,1c0.2,0.2,0.9,0.1,1.2,0.2c0.3,0.1,0.7,0.7,1.1,0.9c0.3,0.1,1.1,0.2,1.4,0.2c0.2,0,0.6-0.3,0.8-0.2
        c0.2,0,0.6,0.5,0.8,0.5c0.2,0,0.4-0.6,0.6-0.5c0.3,0.1,0.2,0.8,0.4,1.1c0.1,0.2,0.6,0.3,0.7,0.5c0.1,0.2,0.1,0.7,0.2,0.9
        c0.1,0.2,0.6,0.4,0.7,0.6c0.2,0.3,0.2,1.1,0.1,1.4c0,0.2-0.3,0.6-0.4,0.8c-0.1,0.2-0.4,0.6-0.6,0.7c-0.4,0.1-1.2-0.4-1.5-0.2
        c-0.2,0.1-0.2,0.5-0.3,0.6c-0.1,0.1-0.5,0.1-0.6,0.2c-0.2,0.1-0.5,0.5-0.7,0.5c-0.2,0.1-0.7,0.1-0.9,0c-0.2,0-0.4-0.3-0.6-0.4
        c-0.2-0.1-0.8-0.3-1-0.2c-0.3,0.1-0.5,0.9-0.7,1.2c-0.2,0.2-0.5,0.6-0.7,0.7c-0.1,0.1-0.5,0.3-0.6,0.3c-0.2,0-0.3-0.5-0.5-0.6
        c-0.2-0.1-0.7-0.2-0.8-0.1c-0.1,0.1-0.3,0.4-0.4,0.6c0,0.2,0.1,0.7,0.3,0.9c0.1,0.2,0.6,0.3,0.6,0.5c0.1,0.2,0,0.7-0.2,0.8
        c-0.1,0.2-0.7,0.3-0.8,0.4c-0.1,0.1-0.2,0.6-0.3,0.7c-0.1,0.1-0.4,0.3-0.4,0.4c-0.1,0.2,0,0.6,0,0.8c0.1,0.2,0.5,0.5,0.5,0.7
        c0,0.2-0.4,0.5-0.6,0.6c-0.2,0.1-0.8-0.2-1-0.1c-0.3,0.1-0.5,0.8-0.7,0.9c-0.2,0.1-0.8,0-0.9,0.2c-0.2,0.2,0,0.8,0.1,1.1
        c0.2,0.3,0.9,0.7,1.1,1c0.2,0.2,0.4,0.7,0.5,0.9c0,0.2-0.1,0.7-0.1,1c0,0.2,0.1,0.7,0.2,0.9c0.2,0.2,0.7,0.1,0.9,0.2
        c0.2,0.1,0.5,0.5,0.6,0.7c0.1,0.2-0.2,0.6-0.1,0.8c0,0.2,0.3,0.5,0.4,0.6c0.1,0.1,0.4,0,0.6,0.1c0.3,0.2,0.6,1,0.9,1.2
        c0.2,0.1,0.8,0,1.1,0c0.1,0,0.5,0,0.6,0.1c0.3,0.2,0.2,1.1,0.5,1.4c0.2,0.2,0.7,0.1,1,0.1c0.2,0,0.4-0.3,0.6-0.3
        c0.2,0,0.6,0.1,0.8,0.2c0.2,0.2,0.1,0.7,0.3,0.8c0.2,0.3,1,0.4,1.3,0.6c0.2,0.1,0.3,0.6,0.5,0.7c0.2,0.1,0.6,0,0.8,0.1
        c0.2,0.2,0.4,0.8,0.4,1.1c0,0.2-0.2,0.5-0.2,0.7c0,0.2,0,0.7,0,1l-38.7-2.7l0.9-46.2l16.2,0.3l0.1-4.9c0.1,0.1,0.1,0.1,0.2,0.2
        c0.1,0.1,0.4,0.3,0.5,0.3c0.1,0.1,0.5,0.3,0.6,0.4c0.2,0.2,0.3,0.9,0.6,1.1c0.3,0.3,1.1,0.6,1.5,0.6c0.1,0,0.3-0.1,0.4-0.1
        c0.3,0,0.7,0.5,1,0.4c0.2,0,0.5-0.5,0.7-0.6c0.4,0,0.9,0.6,1.3,0.6c0.2,0,0.5-0.2,0.6-0.2c0.2,0,0.6,0.3,0.7,0.5
        c0.1,0.2-0.1,0.8,0,1c0.1,0.2,0.5,0.5,0.7,0.6c0.2,0.1,0.8,0,1,0C247.5,500,247.8,500.1,248,500.2z"/>
    </g>
    <g id="Jones">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="jones-county" ref="jones-county" class="st10" d="M223.7,266.5c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.3,0.7-0.8,0.7-1.2c0-0.2-0.2-0.5-0.3-0.7
        c-0.2-0.3-1-0.7-1.2-1.1c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.4-0.1-1.2,0-1.5c0.1-0.2,0.7-0.2,0.7-0.5c0-0.3-0.9-0.4-1-0.7
        c-0.2-0.2-0.3-0.8-0.2-1.1c0.1-0.2,0.5-0.4,0.6-0.6c0.2-0.3,0.4-1,0.2-1.3c-0.1-0.2-0.8-0.1-1-0.3c-0.3-0.3-0.3-1.1-0.4-1.5
        c-0.3-0.7-1-2.1-1.6-2.6c-0.3-0.3-1.3-0.5-1.7-0.8c-0.4-0.3-1-1.3-1.4-1.7c-0.3-0.4-1.2-1.2-1.4-1.7c-0.1-0.4,0.1-1.3,0-1.6
        c-0.1-0.3-0.7-0.6-1-0.7c-0.2-0.1-0.7,0-0.8-0.1c-0.2-0.2-0.3-0.8-0.3-1c0-0.1,0.1-0.3,0.2-0.6l5.1,0l9.5-2.4l12.4-2l11.7-1.9
        l7.8,31.8l-5.2,3.2l-11,6.7l-0.7,0.5l-4.2-4.2l-1.4,1.1l-2.2-2.4l-5.7,0.1c0,0-0.3-0.9-0.4-1.2c-0.1-0.2-0.4-0.6-0.6-0.7
        c-0.4-0.3-1.4-0.4-1.8-0.7c-0.3-0.2-0.7-0.9-1-1.2c-0.2-0.3-0.9-0.7-1-1.1c-0.1-0.4,0.2-1.3,0.3-1.7c0.1-0.2,0.4-0.7,0.3-1
        C225,266.8,224,266.8,223.7,266.5z"/>
    </g>
    <g id="Jackson">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="jackson-county" ref="jackson-county" class="st10" d="M255.1,114.7c0.2,0.3,0.4,0.9,0.6,1.2c0.3,0.5,1.3,1.1,1.6,1.5c0.2,0.2,0.2,0.9,0.5,1.1
        c0.3,0.3,1.3,0.2,1.6,0.5c0.3,0.4-0.1,1.4-0.1,1.9c0,0.5,0.3,1.5,0.4,2c0.1,0.5,0.3,1.7,0.1,2.2c-0.1,0.4-0.7,1-0.9,1.3
        c-0.3,0.4-0.8,1.2-0.9,1.6c-0.1,0.2,0,0.6-0.1,0.7c-0.1,0.1-0.4,0.3-0.5,0.5c-0.1,0.3,0.3,0.9,0.3,1.2c-0.1,0.4-0.8,1-0.8,1.4
        c0,0.3,0.4,0.8,0.6,1.1c0.3,0.3,1,0.6,1.4,0.8c0.1,0.1,0.3,0.2,0.5,0.3c-0.2,0.2-0.5,0.4-0.6,0.5c-0.3,0.1-0.9,0.2-1.2,0.1
        c-0.2,0-0.6-0.5-0.8-0.5c-0.2,0-0.6,0.4-0.7,0.7c0,0.2,0.3,0.5,0.2,0.6c-0.2,0.5-1.5,0.6-2,0.8c-0.5,0.2-1.4,0.8-1.9,0.9
        c-0.4,0.1-1.2,0.3-1.6,0.4c-0.4,0.1-1.2,0.5-1.6,0.7c-0.4,0.2-1.1,0.7-1.5,0.9c-0.2,0.1-0.6,0.1-0.8,0.1c-0.3,0-1.1,0.1-1.3-0.2
        c-0.1-0.1,0.1-0.5,0-0.7c-0.1-0.1-0.3-0.3-0.4-0.3c-0.2,0-0.5,0.5-0.5,0.8c-0.1,0.3,0.1,0.8,0.1,1.1c0.1,0.2,0.4,0.6,0.4,0.8
        c-0.1,0.3-1,0.5-1,0.5l-2.7,1.8l-1.8-6.1c0,0-0.3-1.3-0.6-1.6c-0.1-0.1-0.6-0.2-0.7-0.3c-0.1-0.1-0.2-0.5-0.4-0.7
        c-0.3-0.2-1-0.4-1.3-0.4c-0.3,0-0.8,0.4-1.1,0.4c-0.3,0-0.9-0.3-1.2-0.4c-0.2-0.1-0.5-0.5-0.7-0.6c-0.2,0-0.5,0-0.6,0
        c-0.3,0-1,0-1.3-0.1c-0.3-0.1-0.7-0.4-1-0.4c-0.3,0-0.9,0.3-1.1,0.2c-0.2,0-0.4-0.3-0.6-0.4c-0.3-0.1-0.8-0.1-1.1-0.1
        c-0.1,0-0.4-0.2-0.5-0.2c-0.2,0-0.3,0.4-0.4,0.5c-0.2,0.1-0.5,0.2-0.7,0.1c-0.2-0.1-0.1-0.7-0.3-0.8c-0.2-0.1-0.8-0.1-1,0
        c-0.2,0.1-0.2,0.7-0.4,0.8c-0.1,0.1-0.6,0.3-0.7,0.2c-0.3-0.2,0-0.9-0.2-1.2c-0.1-0.2-0.6-0.7-0.9-0.7c-0.3,0-0.6,0.7-0.8,0.6
        c-0.2-0.1-0.2-0.6-0.3-0.7c-0.1-0.2-0.3-0.6-0.5-0.6c-0.3-0.1-1,0.4-1.3,0.4c-0.4,0-1.2-0.1-1.5-0.3c-0.3-0.2-0.3-1.1-0.6-1.3
        c-0.3-0.3-1.5-0.4-1.7-0.8c-0.1-0.2,0-0.7,0-0.9c0-0.4,0.1-1.1-0.1-1.4c-0.2-0.2-0.7-0.4-1-0.5c-0.3-0.1-0.9-0.2-1-0.4
        c-0.3-0.3-0.1-1.2-0.3-1.6c-0.1-0.1-0.2-0.3-0.4-0.4l0,0l0.1-0.1L233,103c0,0,0,0,0,0c0.9,1.4,3.6,3.7,5.1,4.5
        c0.5,0.2,1.5,0.5,2,0.5c0.4,0,1.2-0.3,1.6-0.4c0.5-0.1,1.6,0,2.2,0c0.3,0,1,0,1.3,0c0.3-0.1,0.7-0.5,1-0.5
        c0.3-0.1,1.1-0.2,1.4-0.1c0.3,0.1,0.5,0.7,0.7,0.9c0.2,0.2,0.8,0.6,1,0.8c0.3,0.3,0.8,1,1.1,1.3c0.3,0.4,0.9,1.2,1.2,1.6
        c0.5,0.6,1.8,1.6,2.4,2.1c0.3,0.2,0.8,0.6,1,0.9C255.1,114.6,255.1,114.7,255.1,114.7z"/>
    </g>
    <g id="Turner">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="turner-county" ref="turner-county" class="st10" d="M249.8,412c-0.1,0.3-0.1,0.6-0.2,0.8c-0.2,0.3-1,0.6-1.1,1c-0.1,0.4,0.1,1.2,0.3,1.5
        c0.2,0.3,0.8,0.7,1,1c0.1,0.3,0.1,1,0,1.3c-0.1,0.3-0.5,0.7-0.6,1c-0.1,0.2-0.1,0.9-0.1,0.9h-3.2l-0.6,12.4l-15.3-0.4l0,3.3
        c-0.2-0.1-0.5-0.1-0.6-0.1c-0.2,0.1-0.3,0.7-0.6,0.7c-0.2,0-0.6-0.5-0.7-0.6c-0.1-0.1-0.5-0.2-0.6-0.4c-0.2-0.3-0.2-0.9-0.1-1.2
        c0.1-0.2,0.6-0.4,0.6-0.7c0-0.4-0.3-1-0.6-1.2c-0.2-0.2-0.7-0.3-1-0.2c-0.1,0-0.5,0.4-0.6,0.4c-0.2,0-0.7-0.4-0.8-0.6
        c-0.2-0.2-0.2-0.8-0.3-1.1c-0.2-0.4-1-1.2-1-1.2l-8.6-0.5l-0.1-21.4c0.5-0.2,1.2-0.4,1.6-0.5c0.5-0.1,1.6-0.1,2.1-0.1
        c0.8,0,3,0.3,3,0.3h11.9l0.1-6.3h7.1v1.1h6.5c0,0.1,0,0.2,0,0.3c0,0.2-0.5,0.5-0.5,0.7c0,0.4,1,1,1.1,1.4c0.1,0.3-0.3,0.9-0.3,1.3
        c0.1,0.4,0.5,1,0.7,1.3c0.1,0.1,0.4,0.3,0.5,0.5c0.1,0.2-0.1,0.5,0,0.7c0.1,0.1,0.4,0.2,0.4,0.4c0.1,0.3-0.2,0.9-0.1,1.2
        c0.1,0.3,0.8,0.8,0.8,1.1c0.1,0.3-0.1,0.8-0.2,1.1C249.9,411.3,249.8,411.6,249.8,412z"/>
    </g>
    <g id="Houston">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="houston-county" ref="houston-county" class="st10" d="M235.4,301.2c0.3,0.1,0.6,0.3,0.7,0.4c0.3,0.2,0.8,0.9,1.2,1c0.2,0.1,0.8-0.3,1-0.2
        c0.3,0.1,0.7,0.6,0.8,0.9c0.3,0.4,0.8,1.6,0.9,1.6c0.1,0,0.5,0.6,0.6,0.9c0.1,0.4,0.4,1.2,0.2,1.5c-0.1,0.2-0.6,0.3-0.6,0.5
        c0,0.3,0.7,0.7,0.8,1c0.1,0.4-0.2,1.1-0.2,1.4c0.1,0.3,0.6,0.9,0.6,1c0,0.1,0,1-0.2,1.3c-0.2,0.2-0.9,0.1-1.1,0.3
        c-0.2,0.3,0,1.5,0.1,1.6c0,0.1,1,0.9,1.1,1.3c0.1,0.5-0.5,1.6-0.4,2.1c0.1,0.5,0.6,1.4,0.9,1.8c0.1,0.1,0.4,0.4,0.5,0.5
        c0.2,0.3,0.6,1.2,0.7,1.3c0.1,0.1,1,0.8,1.4,1.1c0.4,0.3,1.5,0.8,1.7,1.3c0.2,0.5-0.1,1.6-0.2,2.2c-0.1,0.3-0.4,1-0.4,1.3
        c0,0,0,0,0,0c0,0.2,0.4,0.5,0.4,0.6c0.1,0.3,0.1,0.9,0.1,1.2c0.1,0.3,0.3,1,0.4,1.3c0.1,0.3,0.6,1,0.7,1.3c0,0.3-0.2,1-0.5,1.2
        c-0.2,0.2-0.8,0-1,0.2c-0.1,0.1-0.1,0.3-0.2,0.5c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.2-0.5,0.5-0.7,0.5c-0.4,0-0.9-0.6-1.3-0.7
        c-0.2-0.1-0.6-0.3-0.8-0.3c-0.2,0.1-0.4,0.5-0.6,0.7c-0.5,0.6-1.3,2-1.8,2.6c-0.3,0.4-0.8,1.1-1.1,1.5c-0.4,0.5-1.2,1.6-1.6,2.2
        c-0.2,0.3-0.5,1-0.7,1.3c-0.2,0.3-0.6,0.9-0.7,1.3c-0.1,0.4,0.1,1.2,0,1.6c-0.1,0.3-0.3,0.8-0.4,1c-0.2,0.2-0.7,0.5-0.9,0.6
        c-0.1,0-0.1,0.1-0.2,0.2c-0.2,0.2-0.6,0.4-0.6,0.4h-9c0,0-0.2,0.5-0.4,0.6c-0.1,0.1-0.3,0.3-0.5,0.3c-0.3,0-0.8-0.3-1-0.5
        c-0.1-0.1-0.1-0.6-0.1-0.6h-12.9v-8h-1.1v-3h1.9v-6.5h-0.9v-4.3c0.2,0.3,0.6,1,0.9,1.3c0.2,0.1,0.6,0.1,0.7,0.3
        c0.2,0.2,0.3,0.7,0.4,1c0.1,0.3,0,1.1,0.2,1.3c0.1,0.1,0.6,0.2,0.8,0.1c0.2-0.1,0-0.6,0.2-0.7c0.3-0.2,1.2,0.2,1.6,0
        c0.3-0.1,0.7-0.6,0.7-0.9c0.1-0.4-0.5-1.2-0.5-1.6c0-0.3,0.3-0.9,0.3-1.2c-0.1-0.3-0.8-0.7-0.8-1.1c-0.1-0.4,0-1.2,0.3-1.5
        c0.2-0.3,1.1-0.1,1.3-0.4c0.1-0.2-0.2-0.8-0.1-1c0.1-0.2,0.3-0.6,0.5-0.6c0.3,0,0.8,0.7,1.1,0.8c0.3,0.1,0.9,0.2,1.2,0.1
        c0.3,0,0.9-0.3,1-0.5c0.2-0.3,0-1.2,0-1.6c0-0.3,0.1-0.9,0.3-1.1c0.2-0.2,0.7-0.2,0.9-0.2c0.3,0.1,0.6,0.7,0.9,0.8
        c0.2,0.1,0.7,0,0.9-0.1c0.5-0.5,0.5-2.1,0.5-2.9c0-0.5-0.5-1.6-0.6-2.1c-0.1-0.6-0.1-1.7,0-2.2c0.1-0.4,0.8-1.5,0.8-1.6
        c0-0.1,0-0.9-0.2-1.1c-0.1-0.2-0.7-0.5-0.8-0.8c-0.1-0.3,0.2-0.9,0.1-1.2c-0.1-0.4-0.6-1.1-0.7-1.5c-0.1-0.4-0.2-1.2,0-1.5
        c0.2-0.3,1-0.5,1.3-0.5c0.2,0,0.6,0.2,0.7,0.1c0.3-0.2,0.5-1,0.5-1.3c0-0.4-0.2-1.2-0.2-1.5c0-0.3,0.2-0.8,0.2-1.1
        c0-0.2,0.1-0.6,0.2-0.9h0.8c0,0,0.5,0.4,0.6,0.6c0.3,0.3,0.9,0.9,1.3,1.1c0.4,0.2,1.1,0.5,1.5,0.5c0.2,0,0.6-0.3,0.9-0.3
        c0.5,0,1.4,0.6,1.8,0.8c0.4,0.2,1,0.7,1.4,0.8c0.4,0.1,1.2,0,1.5-0.1c0.2,0,0.4-0.3,0.6-0.3C235.2,301.1,235.3,301.1,235.4,301.2z
        "/>
    </g>
    
    <g id="Bibb">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="bibb-county" ref="bibb-county" class="st11" d="
        M224.8,298.1h-2.9c-0.4-0.3-1-0.9-1.3-1.1c-0.4-0.3-1-1.1-1.4-1.3c-0.2-0.1-0.8-0.1-1.1-0.2c-0.3-0.2-0.8-0.7-1.1-0.9
        c-0.2-0.1-0.7-0.2-0.9-0.3c-0.4-0.1-1.1-0.5-1.4-0.7c-0.5-0.3-1.5-0.8-1.9-1.2c-0.3-0.3-0.7-1.2-0.9-1.6c-0.2-0.4-0.4-1.2-0.6-1.6
        c-0.3-0.4-1.2-0.9-1.4-1.3c-0.4-0.6-0.7-2.2-1.1-2.9c-0.3-0.6-1.5-2.2-1.5-2.2h-1.7V279l0.1,0l9.1-6.7l7-4.5v-1.3h1.9
        c0.4,0.3,1.3,0.3,1.5,0.7c0.1,0.2-0.2,0.7-0.3,1c-0.1,0.4-0.4,1.3-0.3,1.7c0.1,0.3,0.7,0.8,1,1.1c0.2,0.3,0.7,1,1,1.2
        c0.4,0.3,1.4,0.4,1.8,0.7c0.2,0.1,0.5,0.5,0.6,0.7c0.2,0.3,0.4,1.2,0.4,1.2l5.7-0.1l2.2,2.4l1.4-1.1l4.2,4.2l0.7-0.5l2.6,2.7
        l-4.9,6.4l-4.3,3.8l-0.7-0.6c0,0-1.3,0.2-1.4,0.5c-0.1,0.2,0.1,0.6,0.1,0.8c0.1,0.3,0.6,1,0.6,1.3c0,0.3-0.7,0.8-0.7,1.1
        c0,0.2,0.5,0.4,0.5,0.6c0,0.3-0.5,0.9-0.7,1.2c-0.2,0.2-1,0.4-1.1,0.7c0,0.2,0.5,0.6,0.5,0.8c0.1,0.3-0.3,0.9-0.2,1.2
        c0.1,0.3,0.8,0.5,1.1,0.7c0.1,0.1,0.2,0.3,0.3,0.5c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.3-0.6,0.3c-0.4,0.1-1.2,0.2-1.5,0.1
        c-0.4-0.1-1-0.6-1.4-0.8c-0.4-0.2-1.3-0.7-1.8-0.8c-0.2,0-0.6,0.3-0.9,0.3c-0.4,0-1.2-0.3-1.5-0.5c-0.4-0.2-1-0.8-1.3-1.1
        c-0.2-0.1-0.6-0.6-0.6-0.6H224.8z"/>
    </g>
    <g id="Walton">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="walton-county" ref="walton-county" class="st10" d="M203.3,170.2l-7-5.3l0.1-0.1l18-17.2c0.3,0.4,1,0.8,1.3,1c0.3,0.2,0.9,0.5,1.2,0.7
        c0.4,0.2,1.5,0.5,1.7,0.9c0.1,0.2-0.3,0.6-0.3,0.9c0,0.1,0.1,0.5,0.2,0.6c0.2,0.1,0.8,0,1-0.1c0.3-0.1,0.6-0.5,0.9-0.6
        c0.2,0,0.7,0.3,0.9,0.3c0.4,0,1.1,0,1.5-0.1c0.3-0.1,0.7-0.5,0.9-0.6c0.2-0.1,0.6,0,0.8-0.1c0.2-0.1,0.5-0.6,0.7-0.7
        c0.2-0.1,0.7,0.1,0.9,0c0.2-0.1,0.6-0.3,0.7-0.4c0.2-0.1,0.5-0.4,0.7-0.4c0.2,0,0.6,0.3,0.7,0.4c0.2,0.2,0.6,0.6,0.8,0.7
        c0.3,0.1,1.1-0.1,1.5,0.1c0,0,0.1,0,0.1,0.1c0.3,0.2,0.6,0.5,0.8,0.8c0.1,0.3,0,1,0.2,1.2c0.1,0.2,0.6,0.5,0.8,0.6
        c0.2,0.1,0.6,0,0.8,0c0.2,0.1,0.6,0.4,0.8,0.4c0.3,0,1-0.3,1.4-0.3c0.3,0,1-0.1,1.2,0.1c0.1,0.2-0.1,0.6-0.2,0.8
        c-0.1,0.3-0.3,0.8-0.2,1c0,0.2,0.2,0.5,0.3,0.6c0.2,0.2,0.7,0.3,0.8,0.5c0.1,0.2,0.2,0.6,0.3,0.8c0.1,0.1,0.2,0.4,0.3,0.5
        c0.2,0.1,0.7,0.1,0.9,0.2c0.3,0.1,0.7,0.6,0.9,0.8c0.3,0.2,0.8,0.7,1,0.9c0.4,0.3,1.1,0.8,1.5,1.1c0.2,0.2,0.5,0.7,0.7,0.7
        c0.2,0.1,0.7-0.2,1-0.2c0.2,0,0.5-0.1,0.7-0.1c0.1,0,0.1,0.1,0.2,0.1L227,186.5c-0.2-0.7-1.5-1.4-2.1-1.8
        c-0.3-0.3-1.1-0.7-1.4-0.9c-0.5-0.4-1.2-1.4-1.8-1.6c-0.7-0.3-3-0.3-3-0.3L203.3,170.2z"/>
    </g>
    <g id="Colquitt">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="colquitt-county" ref="colquitt-county" class="st10" d="M194.2,494.7l0.2-31.4h24.4v2h4.5v-1.8l6.2,0.2l14.4,0.5c0,0.5-0.1,1.3-0.2,1.8
        c0,0.3-0.3,0.8-0.2,1.1c0,0.2,0.4,0.6,0.4,0.8c0.1,0.2,0.1,0.7,0.1,1c0,0.3,0.2,0.8,0.2,1c0.1,0.3,0.4,0.9,0.4,1.2
        c0,0.3-0.3,1.1-0.3,1.2c0,0.1,0.4,0.7,0.4,0.9c0,0.3-0.6,0.7-0.7,1c-0.2,0.4-0.5,1.3-0.4,1.8c0.1,0.2,0.5,0.4,0.6,0.6
        c0.1,0.1,0.2,0.4,0.2,0.5c0,0.2-0.5,0.6-0.5,0.8c0,0.2,0.2,0.5,0.2,0.7c0,0.2-0.2,0.7-0.4,0.8c-0.1,0.1-0.5,0.1-0.6,0.3
        c-0.2,0.2-0.2,0.7-0.3,1c-0.1,0.2-0.2,0.8-0.3,0.9c-0.2,0.2-0.7,0.3-0.9,0.4c-0.2,0.1-0.6,0.3-0.7,0.5c-0.1,0.2,0.1,0.8,0.1,1
        c0,0.3-0.3,0.9-0.3,1.2c0,0.3,0.1,1.3,0.1,1.4s-0.6,0.2-0.7,0.3c-0.2,0.1-0.7,0.8-0.7,0.8c0,0,0,1,0,1.4c0,0.2-0.2,0.9-0.2,0.9
        c0,0,0.2,1,0.2,1.3c0,0.2-0.2,0.5-0.3,0.6c-0.1,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.4,0.5-0.5,0.7c-0.1,0.2,0.3,0.7,0.2,1
        c-0.1,0.3-0.9,0.5-1,0.8c0,0.1,0.1,0.4,0.1,0.6c0,0.1,0.1,0.2,0.2,0.3l-0.1,4.9l-16.2-0.3l-4.8-0.1v1.4h-1.5v-1.8h-20.9
        L194.2,494.7z"/>
    </g>
    <g id="Jasper">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="jasper-county" ref="jasper-county" class="st10" d="M209.8,216.2c0.1-0.2,0.1-0.3,0.2-0.4c0.3-0.3,1.1-0.3,1.4-0.6c0.2-0.2,0.2-0.8,0.3-1
        c0.1-0.3,0.3-0.9,0.5-1.2c0.2-0.3,1.1-0.4,1.3-0.7c0.2-0.3,0.2-1.1,0-1.3c-0.2-0.2-0.9,0.4-1.1,0.3c-0.2-0.1-0.2-0.5-0.2-0.7
        c0-0.2,0.2-0.7,0.2-0.9c0-0.2-0.1-0.6-0.1-0.8c0-0.2,0.2-0.6,0.3-0.7c0.1-0.2,0.5-0.4,0.7-0.5c0.3-0.2,0.9-0.6,1.2-0.8
        c0.3-0.2,0.8-0.9,1.2-1c0.3-0.1,1,0.3,1.3,0.2c0.2-0.1,0.4-0.5,0.6-0.6c0.2-0.1,0.7-0.1,0.9-0.3c0.3-0.2,0.9-0.6,1.1-0.9
        c0.2-0.3,0.2-1.1,0.4-1.3c0.2-0.2,0.6-0.5,0.9-0.6c0.5-0.2,1.7-0.2,2.2-0.4c0.3-0.1,0.9-0.3,1-0.6c0.1-0.3-0.4-0.8-0.3-1
        c0.1-0.2,0.4-0.5,0.6-0.5c0.2,0,0.7,0.2,0.8,0.4c0.2,0.2,0.4,1,0.5,1.1c0.1,0.1,0.8,0.7,1.2,0.6c0.2,0,0.4-0.4,0.5-0.6
        c0.1-0.4-0.1-1.4-0.2-1.8c-0.1-0.4-0.7-1-0.7-1.4c0-0.2,0.3-0.6,0.4-0.8c0-0.1,0-0.3,0.1-0.5l14.6,10.9l0.2,0.2l-1.4,31.9l-12.4,2
        l-9.5,2.4l-5.1,0c0.1-0.3,0.3-0.6,0.3-0.8c0-0.4-0.6-0.9-0.7-1.3c-0.2-0.5-0.6-1.4-0.7-1.9c0-0.4,0-1.1,0.2-1.6
        c0-0.1,0-0.1,0.1-0.2c0.2-0.4,1.2-0.7,1.2-1.1c0.1-0.4-0.6-1.1-0.8-1.4c-0.2-0.4-1-1-1-1.4c0-0.3,0.4-0.7,0.5-1
        c0.2-0.5,0.5-1.5,0.7-2c0.1-0.3,0.6-1,0.5-1.4c-0.1-0.4-0.7-0.8-0.9-1.1c-0.3-0.4-0.9-1.1-1-1.5c-0.1-0.3-0.2-1-0.1-1.4
        c0.1-0.3,0.7-0.8,0.7-1.1c0-0.4-0.6-1.2-0.7-1.6c-0.2-0.5-0.7-1.5-0.5-1.9c0.1-0.2,0.6-0.3,0.7-0.5c0.1-0.2,0.1-0.6,0-0.7
        c-0.1-0.2-0.5-0.3-0.6-0.4c-0.2-0.3,0.1-1.2-0.1-1.5c-0.2-0.3-1-0.2-1.2-0.5c-0.1-0.2-0.1-0.7-0.1-1
        C209.6,216.7,209.7,216.4,209.8,216.2z"/>
    </g>
    <g id="Barrow">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="barrow-county" ref="barrow-county" class="st10" d="M214.4,147.6c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.3,0.2-1.1,0.1-1.4c-0.1-0.2-0.6-0.4-0.7-0.5
        c-0.2-0.3-0.2-0.9-0.4-1.2c-0.2-0.3-1.1-0.7-1.4-1c-0.2-0.2-0.5-0.7-0.6-0.9c-0.1-0.3,0.2-1,0.1-1.2c-0.3-0.4-1.4-0.5-1.8-0.7
        c-0.3-0.2-0.7-0.6-0.8-0.9c-0.1-0.2,0.1-0.5,0-0.7c-0.2-0.2-1-0.4-1-0.4l5.2-15c0.2,0.2,0.3,0.3,0.4,0.4c0.2,0.4,0,1.3,0.3,1.6
        c0.2,0.2,0.8,0.3,1,0.4c0.3,0.1,0.8,0.2,1,0.5c0.2,0.3,0.1,1.1,0.1,1.4c0,0.2-0.1,0.7,0,0.9c0.3,0.4,1.4,0.5,1.7,0.8
        c0.2,0.3,0.3,1.1,0.6,1.3c0.3,0.3,1.1,0.3,1.5,0.3c0.3,0,1-0.5,1.3-0.4c0.2,0.1,0.4,0.5,0.5,0.6c0.1,0.2,0.1,0.7,0.3,0.7
        c0.2,0.1,0.6-0.6,0.8-0.6c0.3,0,0.8,0.4,0.9,0.7c0.2,0.3-0.1,1,0.2,1.2c0.2,0.1,0.6-0.1,0.7-0.2c0.2-0.1,0.2-0.7,0.4-0.8
        c0.2-0.1,0.8-0.2,1,0c0.2,0.1,0.1,0.7,0.3,0.8c0.2,0.1,0.5,0,0.7-0.1c0.1-0.1,0.3-0.4,0.4-0.5c0.1,0,0.4,0.1,0.5,0.2
        c0.3,0.1,0.8,0,1.1,0.1c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0,0.9-0.2,1.1-0.2c0.3,0,0.7,0.4,1,0.4c0.3,0.1,1,0.1,1.3,0.1
        c0.2,0,0.5-0.1,0.6,0c0.2,0.1,0.5,0.5,0.7,0.6c0.3,0.2,0.9,0.4,1.2,0.4c0.3,0,0.8-0.4,1.1-0.4c0.3,0,1,0.2,1.3,0.4
        c0.1,0.1,0.2,0.5,0.4,0.7c0.1,0.1,0.6,0.2,0.7,0.3c0.3,0.3,0.6,1.6,0.6,1.6l1.8,6.1l-10.6,7.2c0,0-0.1-0.1-0.1-0.1
        c-0.3-0.1-1.1,0.1-1.5-0.1c-0.3-0.1-0.6-0.5-0.8-0.7c-0.2-0.1-0.5-0.4-0.7-0.4c-0.2,0-0.6,0.3-0.7,0.4c-0.2,0.1-0.5,0.4-0.7,0.4
        c-0.2,0.1-0.7-0.1-0.9,0c-0.2,0.1-0.5,0.6-0.7,0.7c-0.2,0.1-0.6,0-0.8,0.1c-0.3,0.1-0.7,0.5-0.9,0.6c-0.4,0.1-1.1,0.2-1.5,0.1
        c-0.2,0-0.7-0.3-0.9-0.3c-0.3,0-0.6,0.5-0.9,0.6c-0.2,0.1-0.8,0.2-1,0.1c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.4-0.7,0.3-0.9
        c-0.2-0.4-1.2-0.7-1.7-0.9c-0.3-0.2-0.9-0.5-1.2-0.7C215.4,148.4,214.7,148,214.4,147.6z"/>
    </g>
    <g id="Towns">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="towns-county" ref="towns-county" class="st10" d="M240.5,21.2c0.1,0.4,0.2,1.3,0.1,1.7c-0.1,0.3-0.2,0.9-0.5,1.1c-0.4,0.2-1.3,0.1-1.8,0.1
        c-0.3,0-0.8-0.1-1.1,0c-0.3,0.1-0.7,0.4-1,0.4c-0.3,0.1-1.2-0.2-1.4,0.1c-0.1,0.1,0,0.6,0.1,0.8c0.1,0.3,0.3,0.7,0.4,0.9
        c0.1,0.2,0.4,0.6,0.3,0.8c-0.1,0.4-0.8,0.8-1.1,0.9c-0.3,0.1-0.9-0.1-1.2-0.1c-0.2,0-0.7,0.3-0.8,0.4c-0.2,0.1-0.4,0.5-0.5,0.7
        c-0.1,0.2,0,0.6-0.1,0.7c-0.1,0.1-0.2,0.4-0.4,0.5c-0.2,0.1-0.8-0.1-1.1,0.1c-0.2,0.2-0.1,1-0.3,1.2c-0.1,0.2-0.7,0.1-0.8,0.3
        c-0.1,0.2,0.1,0.7,0.1,0.9c0,0.3,0.1,1,0,1.3c-0.1,0.3-0.5,0.6-0.6,0.9c-0.1,0.2,0,0.7,0,1c0,0.2,0,0.7,0,0.9
        c0.1,0.4,0.3,1.2,0.5,1.5c0,0,0.1,0.1,0.1,0.1c-0.1,0.2-0.3,0.4-0.4,0.5c-0.1,0.2-0.2,0.8-0.4,1c-0.2,0.2-0.6,0.5-0.9,0.6
        c-0.2,0.1-0.7-0.1-0.8,0c-0.1,0.1,0,0.5,0,0.7c0,0.1-0.2,0.3-0.3,0.5c-0.3-0.2-0.8-0.3-1.1-0.2c-0.2,0.1-0.2,0.5-0.4,0.6
        c-0.2,0.2-0.9,0.3-1.2,0.3c-0.4,0-1.1-0.5-1.5-0.7c-0.6-0.2-1.9-0.3-2.5-0.3c-0.6,0-1.9,0.3-2.5,0.4c-0.2,0-0.5,0.1-0.8,0.2
        c-0.1-0.3-0.2-0.7-0.3-1c0-0.5,0.3-1.5,0-1.9c-0.1-0.2-0.8-0.3-1-0.5c-0.3-0.3-0.7-0.9-0.7-1.3c0-0.3,0.7-0.8,0.7-1.1
        c0-0.4-0.5-1-0.7-1.3c-0.2-0.2-0.7-0.4-0.8-0.6c-0.3-0.3-0.8-1.2-0.8-1.6c0-0.4,0.1-1.3,0.4-1.6c0.2-0.2,0.9-0.4,0.9-0.4
        c0-0.1,0.1-1.3-0.1-1.7c-0.1-0.2-0.7-0.4-0.9-0.6c-0.2-0.2-0.4-0.7-0.7-0.8c-0.3-0.1-1,0-1.3,0.2c-0.2,0.1-0.5,0.3-0.6,0.5
        c-0.1,0.2,0.3,0.6,0.2,0.8c-0.1,0.3-0.6,0.7-0.9,0.7c-0.3,0-0.8-0.2-1-0.4c-0.2-0.3,0.1-1.3-0.1-1.7c-0.1-0.2-0.5-0.4-0.7-0.5
        c-0.4-0.2-1.4-0.1-1.8-0.3c-0.4-0.1-1-0.6-1.4-0.7c-0.2,0-0.7,0.2-0.9,0.2c-0.3-0.1-0.9-0.4-1.1-0.6c-0.2-0.2-0.3-0.8-0.4-1
        c-0.1-0.3-0.1-0.9-0.2-1.2c-0.1-0.4-0.5-1.1-0.8-1.4c-0.2-0.2-0.8-0.4-1-0.7c-0.2-0.2-0.7-0.7-0.7-1.1c0-0.2,0.6-0.4,0.7-0.6
        c0.1-0.2,0.2-0.7,0.2-1c0-0.2-0.1-0.7-0.2-1l39.3-0.3c0.1,0.3,0.2,0.7,0.3,0.9c0,0.3-0.1,0.8-0.1,1.1
        C240.2,19.9,240.5,20.7,240.5,21.2z"/>
    </g>
    
    <g id="Dooly">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="dooly-county" ref="dooly-county" class="st11" d="
        M210.1,346.9h12.9c0,0,0,0.5,0.1,0.6c0.2,0.2,0.7,0.5,1,0.5c0.1,0,0.4-0.2,0.5-0.3c0.1-0.1,0.4-0.6,0.4-0.6h9c0,0,0.3-0.2,0.6-0.4
        l-0.3,21.2l-0.1,11l-36-0.3c0-0.3-0.1-0.8-0.2-1c-0.2-0.3-1.2-0.5-1.4-0.9c-0.2-0.4,0.5-1.3,0.4-1.7c-0.1-0.4-0.9-1-0.9-1.5
        c0-0.3,0.4-1,0.5-1.3c0.1-0.3,0.3-1,0.1-1.3c-0.2-0.3-1-0.3-1.3-0.5c-0.2-0.2-0.4-0.9-0.6-1.2c-0.2-0.3-0.6-0.8-0.8-1
        c-0.1-0.1-0.6-0.4-0.6-0.5c0-0.1,0.3-0.9,0.2-1.2c-0.1-0.4-1-0.8-1.2-1.1c-0.2-0.2-0.4-0.8-0.5-1c-0.1-0.2-0.4-0.5-0.4-0.8
        c0.1-0.2,0.5-1.3,0.3-1.8c-0.2-0.4-1-0.6-1.3-0.9c-0.2-0.2-0.4-0.6-0.5-1c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0.1,0.8,0.4,1.1,0.3
        c0.4-0.1,0.9-0.7,1.1-1c0.2-0.3,0-1,0.2-1.3c0.1-0.2,0.7-0.5,0.9-0.7c0.3-0.3,0.8-1.1,1.1-1.4c0.3-0.3,1.2-0.5,1.5-0.8
        c0.3-0.3,0.6-1.1,0.8-1.4c0.2-0.4,0.4-1.2,0.7-1.5c0.2-0.3,0.9-0.5,1.3-0.7c0.4-0.3,1.4-0.7,1.8-1c0.4-0.3,1.3-0.8,1.7-1.1
        c0.5-0.3,2.1-1.1,2.1-1.1v-1.4H210.1z"/>
    </g>
    <g id="Crisp">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="crisp-county" ref="crisp-county" class="st10" d="M201,393c0-0.2,0-0.4,0-0.5c0-0.3,0.2-0.8,0.2-1c0-0.4-0.4-1.1-0.4-1.5c0-0.4,0.4-1.3,0.4-1.8
        c0-0.4-0.4-1.3-0.6-1.6c-0.1-0.3-0.5-0.9-0.7-1.2c-0.2-0.3-0.7-0.6-0.8-0.9c-0.1-0.3,0.3-1,0.2-1.3c-0.1-0.2-0.5-0.3-0.6-0.5
        c-0.2-0.3-0.1-0.9-0.2-1.2c-0.1-0.3-0.5-0.8-0.6-1.2c-0.1-0.4,0.1-1.1,0-1.5c0-0.1,0-0.1,0-0.2l36,0.3l-0.3,21.2l-0.1,6.3h-11.9
        c0,0-2.3-0.3-3-0.3c-0.5,0-1.6,0-2.1,0.1c-0.3,0.1-1.1,0.3-1.6,0.5c-0.3,0.1-0.6,0.2-0.6,0.2c-0.1-0.1-0.9-1.3-1.3-1.5
        c-0.3-0.1-0.9,0.1-1.2,0c-0.2-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.2-0.8-0.5-0.9c-0.1-0.1-0.4,0.1-0.6,0.1c-0.2,0-0.7,0.2-0.8,0.1
        c-0.1-0.1-0.5-0.5-0.5-0.6s0-0.9-0.2-1.1c-0.3-0.2-1.2,0.2-1.6,0.1c-0.3-0.1-0.8-0.5-1.2-0.5c-0.4-0.1-1.2,0-1.5,0
        c-0.2,0-0.7,0.2-1,0.1c-0.3-0.1-0.8-0.5-1.1-0.6c-0.3-0.1-1.1,0.1-1.4,0.1c-0.3-0.1-0.9-0.2-1.2-0.4c-0.1,0-0.1-0.1-0.1-0.1
        c-0.2-0.3-0.4-1.2-0.2-1.6c0.1-0.3,0.8-0.7,0.9-1c0.1-0.3-0.2-1-0.2-1.4c0-0.4,0.1-1.1,0.2-1.4c0.1-0.3,0.6-0.6,0.7-0.9
        C201.2,394.3,201.1,393.6,201,393z"/>
    </g>
    <g id="Hall">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="hall-county" ref="hall-county" class="st10" d="M213,123.5l-5.2,3.4l-18.2-7.6c0,0,0.3-0.6,0.5-0.6c0.2-0.1,0.5,0.2,0.7,0.2
        c0.2,0,0.6-0.2,0.7-0.3c0.1-0.1,0-0.5,0-0.7c0-0.2,0-0.6,0-0.8c0-0.1-0.2-0.4-0.3-0.4c-0.2,0-0.4,0.4-0.5,0.4
        c-0.2,0-0.7,0-0.9-0.2c-0.1-0.1-0.1-0.4,0-0.6c0-0.2,0.2-0.4,0.3-0.5c0.2-0.1,0.7-0.3,0.9-0.3c0.3,0,0.9,0.4,1.2,0.6
        c0.2,0.1,0.7,0.4,0.9,0.6c0.2,0.2,0.2,0.8,0.4,1c0.1,0.1,0.5,0.3,0.6,0.3c0.2,0,0.6-0.4,0.9-0.4c0.2,0,0.4,0.5,0.6,0.6
        c0.2,0.1,0.6,0.1,0.8,0c0.1-0.1,0.3-0.3,0.3-0.5c0-0.2-0.3-0.6-0.5-0.8c-0.1-0.1-0.6-0.2-0.7-0.4c-0.1-0.1-0.2-0.5-0.1-0.7
        c0.1-0.2,0.4-0.4,0.5-0.4c0.2,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.4,0.5,0.5c0.1,0.1,0.4,0.3,0.6,0.3c0.1,0,0.3-0.2,0.5-0.2
        c0.3-0.1,0.9-0.2,1.2-0.1c0.2,0.1,0.3,0.5,0.5,0.6c0.2,0.2,0.6,0.4,0.9,0.4c0.3-0.1,0.8-0.6,0.7-0.9c0-0.4-0.7-0.8-1.1-1
        c-0.2-0.1-0.7-0.2-0.9-0.3c-0.2-0.1-0.4-0.5-0.6-0.6c-0.3-0.1-1,0.2-1.3,0.1c-0.2,0-0.6-0.2-0.7-0.4c-0.1-0.2,0-0.6,0.1-0.8
        c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.4-0.2,0.6-0.2c0.2,0,0.7,0.3,0.9,0.4c0.3,0,0.9,0.2,1.1-0.1c0.1-0.1-0.1-0.5-0.1-0.7
        c-0.1-0.2-0.5-0.5-0.5-0.7c0-0.2,0.3-0.5,0.4-0.5c0.1-0.1,0.5-0.1,0.6-0.1c0.2,0,0.5,0.3,0.7,0.4c0.2,0,0.5,0,0.6-0.1
        c0.1-0.1,0.3-0.4,0.2-0.6c-0.1-0.2-0.6-0.5-0.7-0.6c-0.2-0.2-0.5-0.5-0.5-0.8c0-0.2,0.4-0.5,0.6-0.5c0.2-0.1,0.8-0.1,1.1-0.1
        c0.2,0,0.5,0.3,0.8,0.4c0.2,0,0.6-0.1,0.8-0.3c0.1-0.1,0.3-0.5,0.4-0.7c0-0.1,0.1-0.4,0-0.4c-0.1-0.1-0.6,0-0.8,0
        c-0.2,0-0.6-0.2-0.7-0.4c0-0.1,0.1-0.4,0.2-0.5c0.3-0.2,1-0.3,1.3-0.2c0.2,0,0.5,0.4,0.7,0.4c0.2-0.1,0.5-0.5,0.5-0.7
        c0-0.2-0.2-0.6-0.4-0.7c-0.1-0.1-0.5-0.2-0.7-0.3c-0.2,0-0.6-0.1-0.7,0c-0.2,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.5-0.6,0.6
        c-0.2,0.1-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.9-0.5,1c-0.3,0.2-1,0.1-1.2-0.1c-0.2-0.1-0.5-0.6-0.5-0.9c0-0.2,0.2-0.6,0.4-0.8
        c0.1-0.1,0.5,0,0.7,0c0.3,0,0.8-0.2,1.1-0.4c0.1-0.1,0.1-0.4,0.2-0.5c0.2-0.1,0.6-0.2,0.8-0.3c0.3-0.1,0.8-0.2,1-0.4
        c0.1-0.1,0.3-0.5,0.4-0.5c0.2-0.1,0.9-0.2,1.1-0.2c0.2,0,0.6,0.3,0.8,0.2c0.2,0,0.5-0.4,0.7-0.4c0.2,0,0.6,0.3,0.8,0.2
        c0.2,0,0.4-0.4,0.5-0.5c0.1-0.2,0.3-0.8,0.2-1c0-0.4-0.5-1.1-0.5-1.4c0-0.2,0.2-0.6,0.4-0.6c0.3-0.1,0.9,0.5,1.1,0.7
        c0.2,0.1,0.4,0.5,0.6,0.5c0.1,0,0.3-0.3,0.3-0.4c0.1-0.3-0.1-1-0.1-1.4c0-0.3-0.3-0.8-0.2-1.1c0.1-0.1,0.3-0.4,0.5-0.4
        c0.1,0,0.4,0.2,0.6,0.1c0.2,0,0.6-0.3,0.8-0.4c0.2-0.1,0.6-0.1,0.8-0.2c0.2,0,0.8,0,0.9-0.2c0.2-0.1,0.2-0.8,0.5-0.9
        c0.1-0.1,0.4,0,0.5,0.1c0.1,0.1,0,0.5-0.1,0.6c-0.1,0.2-0.4,0.5-0.4,0.6c-0.1,0.2,0,0.5,0,0.7c-0.1,0.2-0.4,0.4-0.4,0.5
        c0,0.2,0.3,0.4,0.4,0.5c0.1,0,0.3,0,0.4-0.1c0.2-0.1,0.3-0.5,0.4-0.7c0.1-0.2,0.3-0.7,0.4-1c0.1-0.2,0.2-0.5,0.3-0.7
        c0.1-0.2,0.3-0.6,0.5-0.7c0.2-0.1,0.6,0,0.8-0.1c0.2-0.1,0.5-0.3,0.5-0.4c0.1-0.2,0.1-0.7,0.2-0.9c0.1-0.2,0.6-0.5,0.9-0.6
        c0.2-0.1,0.5-0.1,0.7-0.1c0.2-0.1,0.3-0.4,0.5-0.5c0.2-0.2,0.5-0.5,0.8-0.6c0.3-0.2,0.9-0.3,1.2-0.5c0.1-0.1,0.2-0.5,0.4-0.6
        c0.2-0.1,0.7,0,0.9,0c0.2,0,0.7,0,0.8-0.2c0.1-0.1,0-0.6-0.1-0.7c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.1,0.4-0.2,0.5
        c-0.1,0.1-0.4,0.1-0.5,0c-0.1-0.1,0-0.6,0-0.7c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.1-0.5,0.2-0.6c0.1-0.1,0.4-0.3,0.6-0.4
        c0.1,0,0.4,0,0.5,0c0.2-0.1,0.3-0.5,0.4-0.5c0.2-0.1,0.7,0,0.9,0.2c0.2,0.2,0,0.8,0,1c0.1,0.2,0.5,0.6,0.5,0.7
        c0,0.1,0.7,0.2,0.9,0c0.1-0.1-0.2-0.5-0.1-0.7c0-0.2,0.3-0.5,0.4-0.7c0-0.2-0.4-0.7-0.4-0.9c0-0.2,0.4-0.7,0.6-0.8
        c0.2-0.1,0.6,0,0.8-0.1c0.1,0,0.4,0,0.5-0.1c0.1-0.1,0.3-0.6,0.2-0.8c-0.1-0.2-0.7-0.1-0.9-0.1c-0.2,0-0.5,0-0.6,0
        c-0.2,0-0.4,0.3-0.6,0.4c-0.1,0-0.4-0.1-0.6-0.1c-0.2,0-0.7,0.2-0.9,0.4c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.1-0.8-0.1-1,0
        c-0.2,0.1-0.3,0.5-0.5,0.6c-0.2,0.1-0.7,0-0.8-0.2c-0.1-0.1,0.1-0.5,0-0.6c-0.1-0.1-0.4,0-0.5-0.1c-0.2-0.1-0.6-0.5-0.8-0.5
        c-0.2-0.1-0.8-0.1-0.9,0c-0.1,0.2,0,0.7,0.1,0.9c0.1,0.2,0.3,0.5,0.4,0.6c0.2,0.1,0.6,0,0.8,0.2c0.1,0.2,0.1,0.7,0,0.9
        c0,0.1-0.3,0.3-0.4,0.5c-0.1,0.2-0.2,0.6-0.3,0.8c-0.1,0.2-0.5,0.4-0.6,0.5c-0.1,0.1-0.4,0.4-0.5,0.6c-0.1,0.2,0.1,0.8-0.1,1
        c-0.1,0.1-0.4,0.3-0.5,0.4c-0.1,0-0.4,0-0.4-0.1c-0.1-0.1,0.2-0.6,0.1-0.7c-0.1-0.1-0.4,0-0.6,0c-0.1,0-0.5,0.1-0.5,0.3
        c-0.1,0.2,0.1,0.6,0.1,0.8c0,0.2,0,0.7-0.2,0.8c-0.1,0.1-0.4,0-0.5-0.1c-0.2-0.1-0.4-0.4-0.5-0.5c-0.1,0-0.4,0-0.5,0.1
        c-0.1,0.1-0.1,0.6-0.1,0.8c0,0.2,0.1,0.5,0.1,0.6c0,0.2-0.2,0.7-0.4,0.8c-0.2,0.1-0.7,0.1-0.9,0c-0.1,0-0.3-0.4-0.5-0.4
        c-0.2,0-0.5,0.3-0.6,0.5c-0.2,0.2-0.4,0.7-0.6,0.8c-0.2,0.1-0.8,0.1-1,0.2c-0.2,0-0.5,0.2-0.7,0.2c-0.2,0-0.6-0.2-0.7-0.4
        c-0.1-0.1-0.1-0.4,0-0.6c0.1-0.1,0.4-0.2,0.5-0.2c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.5-0.6,0.8-0.8c0.2-0.1,0.7-0.2,0.8-0.3
        c0.2-0.1,0.3-0.6,0.4-0.7c0.3-0.2,1-0.1,1.2-0.3c0.2-0.2,0.4-0.7,0.3-0.9c-0.1-0.1-0.4-0.3-0.5-0.3c-0.1,0-0.3,0.3-0.4,0.4
        c-0.2,0.1-0.5,0.4-0.7,0.4c-0.2,0-0.4-0.3-0.6-0.3c-0.2,0-0.5-0.1-0.6,0c-0.2,0.1-0.1,0.9-0.3,1c-0.3,0.2-1.1-0.2-1.4-0.4
        c-0.2-0.1-0.4-0.5-0.5-0.7c-0.1-0.2-0.3-0.7-0.5-0.8c-0.2-0.1-0.8,0-1.1,0c-0.3-0.1-0.7-0.5-0.9-0.7c-0.1-0.2-0.4-0.6-0.4-0.8
        c0-0.3,0.4-0.7,0.4-1c0-0.1-0.2-0.3-0.2-0.4c-0.1-0.1-0.5-0.3-0.6-0.4c-0.1-0.2,0.1-0.6,0.1-0.8c0-0.2,0-0.7,0-1
        c0-0.2-0.2-0.5-0.2-0.7c0-0.2,0.3-0.7,0.2-1c-0.1-0.3-0.5-0.7-0.7-0.8c-0.3-0.1-0.9,0.2-1.1,0.4c-0.1,0.1-0.3,0.3-0.3,0.4
        c-0.1,0.2-0.1,0.6,0,0.8c0.1,0.1,0.6,0,0.7,0.2c0.1,0.2,0.2,0.6,0.1,0.8c0,0.2-0.2,0.6-0.3,0.7c-0.1,0.1-0.6,0.2-0.7,0.4
        c-0.1,0.1,0.1,0.5,0.2,0.6c0.2,0.2,0.8,0.3,1,0.5c0.2,0.2,0.2,0.8,0.4,1.1c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.3,0.5,1.1,0.7,1.4
        c0.3,0.3,1.2,0.6,1.5,1c0.1,0.2,0.1,0.6,0.3,0.8c0.2,0.2,0.9,0.4,1,0.7c0.1,0.3-0.4,1-0.6,1.2c-0.1,0.1-0.6,0.2-0.7,0.4
        c-0.2,0.2-0.2,1-0.5,1c-0.5,0-0.4-1.3-0.5-1.7c-0.2-0.4-0.6-1.3-0.8-1.7c-0.3-0.4-0.9-1.2-1.2-1.7c-0.1-0.3-0.4-0.8-0.5-1.1
        c-0.1-0.2-0.1-0.6-0.3-0.7c-0.1-0.1-0.5-0.3-0.6-0.2c-0.2,0.1-0.5,0.5-0.5,0.8c0,0.2,0.2,0.7,0.4,0.9c0.2,0.3,0.8,0.7,1,1
        c0.2,0.2,0.5,0.7,0.7,0.9c0.2,0.2,0.5,0.7,0.6,1c0.1,0.2,0.5,0.6,0.5,0.8c0,0.2-0.1,0.6-0.1,0.8c0,0.2,0,0.7-0.1,0.8
        c-0.1,0.1-0.5,0.3-0.7,0.2c-0.1,0-0.3-0.3-0.4-0.4c-0.2-0.1-0.6,0-0.8,0.1c-0.2,0.1-0.9,0.1-0.9,0.4c0,0.2,0.3,0.4,0.5,0.5
        c0.1,0.1,0.3,0,0.4,0.1c0.2,0.1,0.4,0.5,0.6,0.7c0.2,0.1,0.8,0.2,0.9,0.4c0.2,0.3,0,0.9,0,1.2c0,0.2-0.1,0.7-0.3,0.9
        c-0.1,0.1-0.6,0.2-0.8,0.2c-0.2,0.1-0.5,0.2-0.6,0.3c-0.1,0.1-0.2,0.5-0.3,0.6c-0.1,0.1-0.4,0.3-0.6,0.4c-0.1,0-0.4-0.1-0.5-0.1
        c-0.2,0-0.7,0-0.9,0c-0.2-0.1-0.4-0.3-0.6-0.4c-0.1-0.1-0.5,0-0.6-0.1c-0.1-0.2,0-0.7-0.1-0.9c-0.1-0.1-0.3-0.3-0.4-0.4
        c-0.2-0.2-0.7-0.5-0.7-0.7c0-0.3,0.4-0.8,0.6-1c0.1-0.1,0.4-0.3,0.6-0.3c0.2,0,0.6,0.2,0.8,0.1c0.1-0.1,0.1-0.4,0.2-0.5
        c0.2-0.2,0.8-0.2,0.9-0.4c0.1-0.2,0.1-0.7,0-0.9c-0.1-0.2-0.6-0.5-0.8-0.6c-0.2-0.1-0.6-0.3-0.8-0.3c-0.2,0-0.4,0.5-0.6,0.4
        c-0.2,0-0.4-0.4-0.4-0.6c0-0.2,0.6-0.3,0.7-0.5c0-0.2-0.2-0.5-0.4-0.6c-0.1-0.1-0.4-0.1-0.6,0c-0.2,0.2-0.3,0.8-0.4,1
        c-0.1,0.1-0.4,0.2-0.5,0.4c-0.1,0.2,0,0.7-0.1,0.9c-0.1,0.2-0.4,0.5-0.5,0.5c-0.2-0.1,0-0.5-0.1-0.7c-0.2-0.2-0.8,0-0.9-0.2
        c-0.2-0.2-0.1-0.8-0.1-1.1c0-0.2-0.1-0.5-0.1-0.7c0.1-0.2,0.6-0.3,0.7-0.5c0.1-0.2,0-0.6-0.2-0.7c-0.2-0.1-0.8,0.2-0.9,0
        c-0.1-0.2,0.5-0.4,0.5-0.6c0-0.2-0.4-0.3-0.4-0.5c0-0.2,0.3-0.6,0.5-0.7c0.1,0,0.4,0.1,0.5,0.2c0.2,0.1,0.7,0.2,0.9,0.2
        c0.2,0,0.7,0.1,0.9-0.1c0.2-0.2,0-0.9-0.1-1.1c-0.2-0.2-0.7-0.2-0.9-0.3c-0.2-0.1-0.6-0.1-0.8-0.2c-0.1-0.1-0.2-0.6-0.1-0.8
        c0.1-0.2,0.4-0.5,0.6-0.7c0.2-0.2,0.6-0.4,0.8-0.5c0.2-0.1,0.6-0.3,0.9-0.4c0.2,0,0.6,0,0.8-0.1c0.2,0,0.8,0.1,1,0
        c0.2-0.2,0.1-0.7,0-1c-0.1-0.3-0.7-0.8-0.7-1.1c0-0.2,0.5-0.3,0.6-0.5c0-0.2-0.2-0.6-0.3-0.7c-0.1-0.1-0.3-0.1-0.5-0.1l0.1-0.4
        h1.9l-0.3-1l-0.9-0.2v-0.7l3.3-0.1v0.8h1.1v-1l3.4-3.1l0.1-1.2l18,0.3l5.2,8.7c-0.2,0.4-0.5,1-0.8,1.3c-0.2,0.2-0.6,0.4-0.8,0.6
        c-0.3,0.2-1,0.3-1.3,0.5c-0.3,0.2-0.9,0.9-1.1,1.2c-0.2,0.3-0.5,1-0.5,1.3c0,0.3,0.4,0.9,0.4,1.3c0,0.3-0.3,0.8-0.5,1
        c-0.2,0.2-0.9,0.4-0.9,0.7c-0.1,0.3,0.3,0.8,0.5,1c0.2,0.2,0.8,0.2,0.9,0.4c0.3,0.3,0.8,1,0.9,1.4c0.1,0.3,0.1,0.9,0,1.3
        c0,0.3-0.4,0.9-0.3,1.2c0,0.3,0.4,0.8,0.6,1c0.2,0.3,0.8,0.8,1.1,0.9c0.2,0.1,0.7,0,0.9,0.2c0.3,0.2,0.2,1,0.4,1.3l-19.8,20.4
        L213,123.5L213,123.5z"/>
    </g>
    <g id="White">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="white-county" ref="white-county" class="st10" d="M209.3,51.1c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.3,1.4-0.1,1.7-0.4c0.2-0.2,0-0.7,0.1-0.9
        c0.1-0.3,0.8-0.6,0.9-0.9c0.1-0.4-0.2-1.1-0.3-1.4c0-0.4-0.1-1.1,0-1.5c0.2-0.6,0.8-1.8,1.3-2.2c0.4-0.4,1.5-0.6,2.1-0.7
        c0.4-0.1,1-0.3,1.6-0.5c0.3-0.1,0.6-0.2,0.8-0.2c0.6-0.1,1.9-0.4,2.5-0.4c0.6,0,1.9,0.1,2.5,0.3c0.4,0.1,1.1,0.6,1.5,0.7
        c0.3,0,0.9-0.1,1.2-0.3c0.1-0.1,0.2-0.5,0.4-0.6c0.2-0.1,0.8,0,1.1,0.2c0,0,0,0,0.1,0.1c0.2,0.2,0.1,1,0.4,1.2
        c0.2,0.2,0.8,0,1,0.1c0.4,0.2,1.1,0.7,1.4,1c0.2,0.2,0.3,0.7,0.5,0.8c0.3,0.2,1.2-0.3,1.5,0c0.3,0.2,0.6,1.1,0.6,1.5
        c0,0.2-0.4,0.5-0.4,0.7c0.1,0.3,0.7,0.5,0.8,0.7c0.1,0.4-0.3,1.2-0.2,1.5c0.1,0.4,0.9,1,0.8,1.4c-0.1,0.3-0.8,0.3-1,0.5
        c-0.3,0.3-1,0.9-1.1,1.4c-0.1,0.4,0.2,1.3,0.1,1.8c0,0.4,0,1.4-0.3,1.7c-0.1,0.1-0.5,0.2-0.7,0.3c-0.3,0.2-0.6,0.8-0.8,1.1
        c-0.2,0.4-0.7,1.2-0.7,1.6c0,0.4,0.2,1.3,0.5,1.6c0.2,0.3,1.1,0.3,1.3,0.5c0.2,0.2,0.4,1,0.5,1.3c0,0.4-0.3,1.3-0.4,1.7
        c0,0.2-0.1,0.5-0.1,0.7c0,0.4,0.1,1.1,0.2,1.4c0.1,0.4,0.7,1,0.7,1.4c0.1,0.4-0.2,1.2,0,1.5c0.3,0.5,1.7,0.7,1.9,1.2
        c0.2,0.4,0,1.3-0.1,1.7c-0.1,0.4-0.5,1.1-0.8,1.4c-0.3,0.3-1.2,0.6-1.5,0.9c-0.2,0.2-0.3,0.8-0.5,1c-0.3,0.3-1.3,0.2-1.6,0.5
        c-0.3,0.3-0.6,1.2-0.6,1.6l-18-0.3l0.1-1.8c0,0,0.4-0.1,0.5-0.2c0.2-0.2,0.5-0.6,0.5-0.8c0-0.5-0.6-1.3-0.9-1.6
        c-0.4-0.3-1.4-0.4-1.8-0.7c-0.2-0.2-0.3-0.8-0.4-1.1c-0.1-0.7,0.3-2.2,0.2-2.9c0-0.5-0.1-1.6-0.4-2.1c-0.2-0.3-0.9-0.6-1.1-0.9
        c-0.1-0.2-0.2-0.7-0.2-1c0-0.3,0.2-0.9,0.2-1.3c0-0.4,0-1.1,0-1.5c0-0.5,0.4-1.6,0.3-2.1c-0.1-0.5-0.7-1.3-0.7-1.8
        c0-0.5,0.8-1.4,1.1-1.9c0.2-0.3,0.8-0.8,0.9-1.2c0.3-0.6,0.2-1.9,0.3-2.6C209.1,51.7,209.2,51.3,209.3,51.1z"/>
    </g>
    <g id="Worth">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="worth-county" ref="worth-county" class="st10" d="M194.5,450l0-2.2h1.9v-1.6h-1.3v-21h-3.5c0,0,1-0.1,1.2-0.8c0.2-0.7,0.6-1,0.6-1.3
        c0-0.4-0.7-1-0.8-1.4c-0.1-0.3,0.3-0.9,0.2-1.2c-0.1-0.3-0.8-0.5-0.9-0.7c-0.2-0.4,0.4-1.4,0.2-1.8c-0.2-0.4-1.3-0.5-1.3-0.9
        c0-0.3,0.7-0.4,0.8-0.6c0.2-0.2,0.3-0.8,0.5-1c0.3-0.3,1-0.4,1.3-0.6c0.3-0.2,0.6-0.9,0.7-1.3c0.1-0.3-0.2-1.1,0-1.4
        c0.2-0.3,1-0.3,1.3-0.6c0.2-0.2,0.6-0.9,0.6-1.2c-0.1-0.3-0.9-0.4-1-0.7c-0.1-0.2,0.3-0.8,0.2-1c-0.1-0.2-0.4-0.4-0.6-0.5
        c-0.3-0.2-1.1-0.1-1.3-0.4c-0.2-0.3,0-1.2,0.2-1.5c0.2-0.2,0.9,0.2,1.1,0.1c0.2-0.1,0.5-0.5,0.7-0.6c0.2,0,0.7,0.3,0.9,0.3
        c0.2,0,0.6,0,0.7-0.2c0.2-0.3-0.4-1.1-0.4-1.5c0-0.3,0.1-0.9,0.2-1.1c0.2-0.3,0.7-0.9,1.1-1c0.2-0.1,0.6,0.1,0.7,0
        c0.2-0.1,0.4-0.4,0.5-0.5c0.2-0.2,0.5-0.5,0.7-0.8c0.3,0.2,0.9,0.4,1.2,0.4c0.3,0.1,1.1-0.2,1.4-0.1c0.3,0.1,0.8,0.5,1.1,0.6
        c0.2,0,0.7-0.1,1-0.1c0.4,0,1.2-0.1,1.5,0c0.3,0.1,0.8,0.5,1.2,0.5c0.4,0.1,1.3-0.4,1.6-0.1c0.2,0.2,0.2,1,0.2,1.1
        s0.4,0.5,0.5,0.6c0.1,0.1,0.6,0,0.8-0.1c0.1,0,0.5-0.2,0.6-0.1c0.2,0.1,0.3,0.7,0.5,0.9c0.1,0.2,0.5,0.4,0.7,0.5
        c0.3,0.1,1-0.1,1.2,0c0.4,0.2,1.2,1.4,1.3,1.5c0,0,0.3,0,0.6-0.2l0.1,21.4l8.6,0.5c0,0,0.8,0.9,1,1.2c0.1,0.3,0.1,0.9,0.3,1.1
        c0.1,0.2,0.6,0.6,0.8,0.6c0.2,0,0.5-0.3,0.6-0.4c0.3-0.1,0.8,0.1,1,0.2c0.3,0.2,0.6,0.8,0.6,1.2c0,0.2-0.6,0.4-0.6,0.7
        c-0.1,0.3-0.1,1,0.1,1.2c0.1,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.6,0.7,0.6c0.2,0,0.3-0.6,0.6-0.7c0.1,0,0.4,0,0.6,0.1l-0.1,10.3
        h-1.5v6.4h1.3l-0.1,12.4l-6.2-0.2v1.8h-4.5v-2h-24.4L194.5,450z"/>
    </g>
    <g id="Newton">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="newton-county" ref="newton-county" class="st10" d="M189.8,196.3l11.4-14.8c0,0,0.9-1.9,1-2.6c0.1-0.7,0.2-2.2,0-2.9c-0.1-0.4-0.8-1.1-0.7-1.4
        c0.1-0.2,0.5-0.3,0.7-0.3c0.2,0,0.6,0.2,0.7,0c0.3-0.2,0.3-1.1,0.3-1.5c0-0.4-0.4-1.1-0.4-1.4c0-0.3,0.3-0.8,0.4-1.2l15.4,11.7
        c0,0,2.3,0,3,0.3c0.6,0.2,1.3,1.3,1.8,1.6c0.3,0.3,1.1,0.7,1.4,0.9c0.5,0.4,1.9,1.2,2.1,1.8c0,0,0,0,0,0c0.1,0.4-0.5,1.1-0.6,1.5
        c-0.1,0.4-0.4,1.3-0.3,1.7c0.1,0.4,1.2,0.8,1.3,1.2c0.1,0.5-0.5,1.3-0.6,1.8c0,0.4,0.2,1.2,0.2,1.6c0,0.3-0.2,1-0.2,1.4
        c0,0.2,0,0.7,0,1c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.4,0.6-0.4,0.8c0,0.4,0.6,1,0.7,1.4c0.1,0.4,0.3,1.4,0.2,1.8
        c-0.1,0.2-0.3,0.6-0.5,0.6c-0.3,0.1-1.1-0.5-1.2-0.6c-0.1-0.1-0.3-0.9-0.5-1.1c-0.2-0.2-0.6-0.4-0.8-0.4c-0.2,0-0.6,0.3-0.6,0.5
        c-0.1,0.3,0.4,0.8,0.3,1c-0.1,0.3-0.7,0.5-1,0.6c-0.5,0.2-1.7,0.2-2.2,0.4c-0.2,0.1-0.7,0.4-0.9,0.6c-0.2,0.3-0.2,1-0.4,1.3
        c-0.2,0.3-0.8,0.8-1.1,0.9c-0.2,0.1-0.7,0.1-0.9,0.3c-0.2,0.1-0.4,0.5-0.6,0.6c-0.3,0.1-1-0.3-1.3-0.2c-0.4,0.1-0.9,0.8-1.2,1
        c-0.3,0.2-0.9,0.6-1.2,0.8c-0.2,0.1-0.6,0.3-0.7,0.5c-0.1,0.2-0.3,0.5-0.3,0.7c0,0.2,0.1,0.6,0.1,0.8c0,0.2-0.2,0.7-0.2,0.9
        c0,0.2,0.1,0.6,0.2,0.7c0.2,0.1,0.9-0.4,1.1-0.3c0.3,0.2,0.2,1,0,1.3c-0.2,0.3-1,0.5-1.3,0.7c-0.2,0.2-0.4,0.9-0.5,1.2
        c-0.1,0.2-0.1,0.8-0.3,1c-0.2,0.3-1.1,0.3-1.4,0.6c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1-0.5-0.1-1-0.8-1.3c-0.2-0.1-0.7-0.6-0.7-0.8
        c-0.1-0.3,0.5-0.9,0.5-1.2c0-0.4-0.2-1.3-0.5-1.5c-0.1-0.1-0.5-0.1-0.6,0c-0.2,0.2-0.1,0.9-0.4,1.1c-0.2,0.1-0.7-0.1-0.8-0.2
        c-0.2-0.1-0.6-0.5-0.8-0.7c-0.2-0.2-0.8-0.5-1.1-0.7c-0.3-0.2-0.8-0.7-1-1.1c-0.2-0.3-0.3-0.9-0.5-1.2c-0.2-0.3-0.6-0.7-0.7-1.1
        c-0.1-0.2,0-0.7,0-1c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.8-0.2-0.9-0.5c-0.2-0.2-0.1-0.7-0.2-0.9c-0.1-0.2-0.4-0.6-0.5-0.9
        c-0.1-0.3-0.1-1.1-0.4-1.4c-0.2-0.2-0.9-0.4-1.2-0.5c-0.4-0.1-1.3-0.1-1.7-0.2c-0.5-0.2-1.2-0.9-1.6-1c-0.3-0.1-1.1,0-1.4,0
        c-0.3,0-1,0.2-1.4,0.1c-0.4-0.1-0.8-0.8-1.2-1c-0.3-0.2-1.1-0.5-1.4-0.7c-0.3-0.2-1-0.7-1.2-1.1c-0.1-0.3,0.2-1.1,0.2-1.1
        L189.8,196.3z"/>
    </g>
    
    <g id="Peach">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="peach-county" ref="peach-county" class="st11" d="
        M209.9,325.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.9-0.3-1.2-0.4c-0.4-0.1-1.1-0.2-1.4-0.4c-0.5-0.3-1-1.4-1.5-1.7
        c-0.4-0.2-1.2-0.2-1.6-0.3c-0.4-0.1-1.3,0-1.7-0.2c-0.3-0.2-0.6-0.8-0.9-1c-0.4-0.2-1.2,0-1.6,0c-0.4,0-1.4,0.1-1.8,0
        c-0.3-0.1-0.7-0.6-1-0.7c-0.3-0.1-1-0.1-1.2,0c-0.2,0.1-0.3,0.8-0.6,0.9c-0.4,0.2-1.3-0.2-1.7,0c-0.2,0.1-0.8,0.5-0.8,0.5
        c0,0.1-0.1,0.2-0.2,0.3c-0.1-0.3-0.4-0.8-0.3-1c0.1-0.2,0.6-0.4,0.7-0.5c0.2-0.2,0.3-0.8,0.4-1c0.1-0.2,0.6-0.5,0.7-0.8
        c0.1-0.2,0.1-0.6,0-0.8l24-19.5h3.9h2.9c-0.1,0.3-0.1,0.7-0.2,0.9c-0.1,0.3-0.2,0.8-0.2,1.1c0,0.4,0.3,1.2,0.2,1.5
        c0,0.4-0.2,1.2-0.5,1.3c-0.2,0.1-0.6-0.1-0.7-0.1c-0.4,0-1.1,0.2-1.3,0.5c-0.2,0.3-0.1,1.1,0,1.5c0.1,0.4,0.7,1.1,0.7,1.5
        c0.1,0.3-0.2,0.9-0.1,1.2c0.1,0.3,0.6,0.6,0.8,0.8c0.1,0.3,0.2,1,0.2,1.1c0,0.1-0.7,1.2-0.8,1.6c-0.1,0.5-0.1,1.7,0,2.2
        c0.1,0.5,0.6,1.6,0.6,2.1c0,0.7,0,2.3-0.5,2.9c-0.2,0.2-0.7,0.2-0.9,0.1c-0.3-0.1-0.6-0.7-0.9-0.8c-0.2-0.1-0.7,0-0.9,0.2
        c-0.2,0.2-0.2,0.8-0.3,1.1c0,0.4,0.2,1.2,0,1.6c-0.1,0.2-0.7,0.4-1,0.5c-0.3,0.1-0.9,0-1.2-0.1c-0.3-0.1-0.8-0.9-1.1-0.8
        c-0.2,0-0.5,0.4-0.5,0.6c-0.1,0.2,0.3,0.8,0.1,1c-0.2,0.3-1.1,0.1-1.3,0.4c-0.3,0.3-0.4,1.1-0.3,1.5c0.1,0.3,0.8,0.7,0.8,1.1
        c0.1,0.3-0.3,0.9-0.3,1.2c0,0.4,0.5,1.2,0.5,1.6c-0.1,0.3-0.5,0.8-0.7,0.9c-0.4,0.2-1.3-0.3-1.6,0c-0.1,0.1,0,0.6-0.2,0.7
        c-0.2,0.1-0.7,0-0.8-0.1c-0.2-0.2-0.1-1-0.2-1.3c-0.1-0.3-0.2-0.8-0.4-1c-0.1-0.1-0.6-0.2-0.7-0.3
        C210.5,326.1,210.1,325.4,209.9,325.1z"/>
    </g>
    <g id="Monroe">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="monroe-county" ref="monroe-county" class="st10" d="M205.6,279l-7.8,0.1v-1h-1.2v0.8h-14.4v-9.9h6.3l1.9-2.8V236H208l4.3,2.5
        c-0.1,0.4-0.2,1.2-0.2,1.6c0.1,0.5,0.5,1.5,0.7,1.9c0.2,0.3,0.7,0.9,0.7,1.3c0,0.2-0.1,0.5-0.3,0.8c-0.1,0.2-0.2,0.4-0.2,0.6
        c0,0.3,0.1,0.9,0.3,1c0.2,0.1,0.6,0.1,0.8,0.1c0.3,0.1,0.8,0.5,1,0.7c0.2,0.4-0.1,1.3,0,1.6c0.2,0.5,1.1,1.3,1.4,1.7
        c0.3,0.4,0.9,1.4,1.4,1.7c0.4,0.3,1.3,0.5,1.7,0.8c0.6,0.5,1.3,1.9,1.6,2.6c0.2,0.4,0.2,1.2,0.4,1.5c0.2,0.2,0.8,0.1,1,0.3
        c0.2,0.3,0,1-0.2,1.3c-0.1,0.2-0.5,0.4-0.6,0.6c-0.1,0.3,0.1,0.8,0.2,1.1c0.2,0.3,1,0.4,1,0.7c0,0.2-0.6,0.3-0.7,0.5
        c-0.2,0.3-0.1,1.2,0,1.5c0,0.2,0.2,0.5,0.3,0.7c0.2,0.3,1,0.8,1.2,1.1c0.1,0.2,0.3,0.5,0.3,0.7c0,0.3-0.8,0.8-0.7,1.2
        c0,0.1,0.1,0.1,0.1,0.2h-1.9v1.3l-7,4.5L205.6,279L205.6,279z"/>
    </g>
    <g id="Crawford">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="crawford-county" ref="crawford-county" class="st10" d="M182.2,279h14.4v-0.8h1.2v1l7.8-0.1v3.9h1.7c0,0,1.2,1.6,1.5,2.2c0.4,0.7,0.6,2.3,1.1,2.9
        c0.3,0.4,1.2,0.9,1.4,1.3c0.2,0.3,0.4,1.2,0.6,1.6c0.2,0.4,0.6,1.2,0.9,1.6c0.4,0.4,1.4,0.9,1.9,1.2c0.3,0.2,1.1,0.5,1.4,0.7
        c0.2,0.1,0.7,0.2,0.9,0.3c0.3,0.2,0.8,0.8,1.1,0.9c0.3,0.1,0.8,0.1,1.1,0.2c0.4,0.2,1,1,1.4,1.3c0.3,0.2,0.9,0.8,1.3,1.1H218
        l-24,19.5c0,0,0-0.1,0-0.1c-0.1-0.2-0.6-0.3-0.7-0.4c-0.3-0.2-0.6-0.9-0.8-1.2c-0.1-0.2-0.4-0.7-0.6-0.8c-0.2-0.1-0.7-0.1-0.9-0.2
        c-0.3-0.1-0.8-0.2-1-0.4c-0.2-0.2-0.2-0.9-0.4-1.2c-0.1-0.2-0.3-0.8-0.5-0.9c-0.2-0.1-0.6,0-0.8-0.1c-0.2-0.1-0.5-0.6-0.5-0.8
        c-0.1-0.2,0-0.7-0.2-0.9c-0.1-0.2-0.5-0.4-0.6-0.6c0-0.2,0.2-0.6,0.3-0.8c0.1-0.2,0.3-0.7,0.2-1c0-0.1-0.2-0.4-0.2-0.5
        c-0.1-0.3-0.4-0.9-0.2-1.2c0.1-0.3,0.9-0.3,1-0.5c0.1-0.2,0.1-0.6,0-0.8c-0.1-0.2-0.8-0.4-0.9-0.6c-0.1-0.1-0.1-0.4-0.2-0.6
        c-0.1-0.2-0.5-0.6-0.7-0.8c-0.3-0.3-1.1-0.8-1.5-1.1c-0.2-0.2-0.5-0.6-0.6-0.8c-0.1-0.3,0-0.9-0.1-1.1c-0.1-0.3-0.3-0.8-0.5-0.9
        c-0.2-0.2-0.9-0.2-1.2-0.1c-0.3,0.1-0.6,0.9-1,1.1c-0.2,0.1-0.7,0.1-0.9,0c-0.3-0.2-0.3-1-0.6-1.1c-0.2-0.1-0.7-0.1-0.9,0
        c-0.3,0.1-0.5,0.7-0.8,0.8c-0.4,0.2-1.3,0.2-1.8,0.1c-0.5-0.1-1.4-0.4-1.8-0.7c-0.3-0.2-0.6-0.7-0.8-1.1l5.8-13.9h1.4V279H182.2z"
        />
    </g>
    <g id="Thomas">
    <polygon @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="thomas-county" ref="thomas-county" class="st10" points="221.4,499.7 220.4,545.9 194.9,544.1 186.1,543.6 186.6,514.8 182.4,507.9 182.6,494.7 
        194.2,494.7 194.2,499.2 215.1,499.2 215.1,501 216.6,501 216.6,499.6 		"/>
    </g>
    <g id="Union">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="union-county" ref="union-county" class="st10" d="M216.6,41.3c0,0.2,0.2,0.6,0.3,1c-0.6,0.2-1.2,0.3-1.6,0.5c-0.5,0.2-1.6,0.4-2.1,0.7
        c-0.5,0.4-1.1,1.5-1.3,2.2c-0.1,0.4,0,1.1,0,1.5c0,0.4,0.4,1.1,0.3,1.4c-0.1,0.3-0.7,0.6-0.9,0.9c-0.1,0.2,0,0.7-0.1,0.9
        c-0.3,0.3-1.4,0.1-1.7,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2-0.1-0.5-0.3-0.6-0.4c-0.3-0.1-1.1-0.1-1.4-0.1c-0.2,0-0.7,0-0.9,0
        c-0.3,0-1-0.1-1.3-0.2c-0.3-0.1-0.8-0.7-1.1-0.8c-0.3-0.2-1.1-0.3-1.5-0.3c-0.2,0-0.7,0.1-0.9,0c-0.2-0.1-0.7-0.4-0.9-0.5
        c-0.3,0-1,0.3-1.2,0.5c-0.1,0.1-0.2,0.6-0.4,0.7c-0.3,0.2-1,0.1-1.3,0.3c-0.3,0.2-0.5,1-0.8,1.3c-0.2,0.2-1.1,0.4-1.2,0.7
        c-0.1,0.2,0.2,0.5,0.1,0.7c0,0.3-0.4,0.7-0.5,0.9c-0.2,0.4-0.3,1.4-0.6,1.8c-0.2,0.3-0.9,0.8-1.2,0.9c-0.4,0.2-1.2,0.4-1.6,0.7
        c-0.2,0.1-0.5,0.5-0.6,0.8c-0.1,0.3,0.1,0.9-0.1,1.1c-0.2,0.2-0.9,0.1-1.1,0.3c-0.1,0.2,0.4,0.8,0.3,1c-0.2,0.3-1.1,0.1-1.5,0
        c-0.2-0.1-0.7-0.3-0.8-0.5c-0.3-0.2-0.7-0.8-1-1c-0.2-0.1-0.7-0.3-0.9-0.2c-0.2,0-0.3,0.4-0.5,0.4c-0.4,0.1-1.3,0-1.8-0.1
        c-0.3-0.1-0.9-0.3-1.2-0.5c-0.3-0.1-1-0.5-1.4-0.5c-0.3,0-0.8,0.1-1,0.2c-0.2,0.2-0.2,0.8-0.4,1c-0.1,0.2-0.6,0.4-0.8,0.5
        c-0.2,0-0.5-0.2-0.7-0.2l6.2-10.4v-7.9c0,0-1.4,0-1.8,0.2c-0.3,0.1-0.7,0.5-1,0.5c-0.4,0-1.2-0.4-1.4-0.7
        c-0.2-0.2-0.2-0.7-0.3-0.9c-0.1-0.3-0.1-0.9-0.2-1.2c-0.1-0.2-0.5-0.6-0.6-0.8c-0.1-0.3,0.2-0.8,0.1-1.1c0-0.3-0.4-0.9-0.4-1.3
        c0-0.4,0.2-1.1,0.4-1.4c0.3-0.4,1.1-0.9,1.5-1.1c0.2-0.1,0.6,0,0.7-0.1c0.3-0.2,0.8-0.7,1-1c0.1-0.3,0.2-0.9,0.1-1.2
        c-0.1-0.3-0.6-0.8-0.8-1.1c-0.2-0.3-0.4-1.1-0.6-1.4c-0.2-0.4-0.6-1.3-1-1.5c-0.4-0.3-1.3-0.2-1.7-0.4c-0.4-0.2-1-0.9-1.3-1.3
        c-0.3-0.3-0.9-0.8-1.1-1.1c-0.2-0.3-0.6-0.9-0.7-1.2c0-0.4,0.2-1.3,0.5-1.6c0.3-0.3,1.2-0.2,1.5-0.4c0.3-0.2,0.7-0.8,0.9-1.1
        c0.3-0.3,1.1-0.6,1.3-1c0.1-0.2,0.2-0.9,0.3-1.4l20.1-0.2c0.1,0.3,0.2,0.8,0.2,1c0,0.2-0.1,0.7-0.2,1c-0.1,0.2-0.7,0.4-0.7,0.6
        c0,0.3,0.5,0.8,0.7,1.1c0.2,0.2,0.8,0.4,1,0.7c0.3,0.3,0.7,1.1,0.8,1.4c0.1,0.3,0.1,0.9,0.2,1.2c0.1,0.3,0.2,0.8,0.4,1
        c0.2,0.2,0.8,0.6,1.1,0.6c0.2,0,0.7-0.2,0.9-0.2c0.4,0.1,1,0.6,1.4,0.7c0.4,0.1,1.4,0.1,1.8,0.3c0.2,0.1,0.6,0.3,0.7,0.5
        c0.2,0.4-0.1,1.4,0.1,1.7c0.2,0.2,0.7,0.5,1,0.4c0.3,0,0.8-0.4,0.9-0.7c0.1-0.2-0.2-0.6-0.2-0.8c0.1-0.2,0.4-0.4,0.6-0.5
        c0.3-0.1,1-0.3,1.3-0.2c0.2,0.1,0.5,0.7,0.7,0.8c0.2,0.2,0.8,0.4,0.9,0.6c0.2,0.4,0.1,1.6,0.1,1.7c0,0.1-0.7,0.2-0.9,0.4
        c-0.3,0.3-0.4,1.2-0.4,1.6c0.1,0.5,0.5,1.3,0.8,1.6c0.2,0.2,0.7,0.4,0.8,0.6c0.3,0.3,0.7,0.9,0.7,1.3c0,0.3-0.6,0.8-0.7,1.1
        c0,0.4,0.4,1,0.7,1.3c0.2,0.2,0.8,0.3,1,0.5C216.9,39.8,216.6,40.8,216.6,41.3z"/>
    </g>
    <g id="Gwinnett">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="gwinnett-county" ref="gwinnett-county" class="st10" d="M167.2,144.5c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.3,0.2-0.8,0.4-1c0.2-0.3,0.7-0.8,0.8-1.1
        c0.1-0.2-0.1-0.8,0-1.1c0.2-0.4,0.9-1,1.3-1.3c0.3-0.3,0.9-1,1.3-1.1c0.5-0.2,1.6-0.3,2,0c0.3,0.2,0.5,1,0.8,1.2
        c0.4,0.2,1.4,0.6,1.7,0.4c0.3-0.2,0.2-0.9,0.3-1.2c0.2-0.5,0.4-1.6,0.7-2.1c0.2-0.3,0.6-0.9,0.8-1.1c0.3-0.2,1.2-0.4,1.6-0.5
        c0.5,0,1.5,0.3,2,0.3c0.4-0.1,1.2-0.4,1.5-0.7c0.3-0.3,0.7-1,0.9-1.4c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.7-0.5,0.7-0.7
        c0-0.4-0.7-0.8-0.9-1.1c-0.2-0.2-0.8-0.5-0.8-0.8c-0.1-0.5,0.3-1.5,0.5-1.9c0.2-0.4,0.7-1.1,0.8-1.5c0.1-0.4,0.1-1.4,0.3-1.9
        c0.1-0.4,0.7-1.1,0.8-1.5c0.2-0.4,0.2-1.4,0.4-1.9c0.2-0.5,1-1.1,1.4-1.5c0.2-0.2,0.6-0.7,0.9-0.7c0.3,0,0.7,0.4,1,0.5
        c0.2,0,0.5,0,0.6-0.1c0.2-0.2,0.6-1,0.6-1l18.2,7.6l5.2-3.4c0,0,0,0,0,0l-5.2,15c0,0,0.9,0.2,1,0.4c0.1,0.1-0.1,0.5,0,0.7
        c0.1,0.3,0.6,0.7,0.8,0.9c0.4,0.3,1.5,0.3,1.8,0.7c0.2,0.3-0.1,0.9-0.1,1.2c0.1,0.3,0.4,0.7,0.6,0.9c0.3,0.3,1.1,0.7,1.4,1
        c0.2,0.2,0.2,0.9,0.4,1.2c0.1,0.2,0.6,0.3,0.7,0.5c0.1,0.3-0.2,1.1-0.1,1.4c0,0.1,0.1,0.1,0.1,0.2l-18,17.2l-0.1,0.1l-5.2,5
        c-0.2-0.5-0.6-1-0.8-1.3c-0.3-0.4-0.8-1.2-1.1-1.5c-0.5-0.4-1.7-0.7-2.2-1.1c-0.2-0.1-0.5-0.5-0.7-0.6c-0.4-0.4-1.1-1.2-1.5-1.6
        c-0.5-0.5-1.7-1.1-2.2-1.6c-0.3-0.3-0.6-1.1-0.8-1.5c-0.2-0.4-0.8-1.2-1.1-1.5c-0.3-0.3-0.9-0.6-1.2-0.9c-0.2-0.2-0.5-0.9-0.8-1.1
        c-0.4-0.3-1.3-0.4-1.7-0.7c-0.2-0.2-0.6-0.6-0.8-0.8c-0.2-0.2-0.9-0.6-1-0.7c-0.1,0-1.1-0.3-1.4-0.6c-0.4-0.3-0.9-1.1-1.2-1.5
        c-0.3-0.4-1-1.3-1.4-1.7c-0.4-0.4-1.5-0.8-1.9-1.2c-0.4-0.3-1-1.2-1.3-1.6c-0.3-0.4-0.7-1.2-0.9-1.6c-0.1-0.3-0.2-1-0.2-1.4
        c0-0.2,0.3-0.5,0.2-0.7C167.4,144.8,167.3,144.6,167.2,144.5z"/>
    </g>
    <g id="Butts">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="butts-county" ref="butts-county" class="st10" d="M190.4,236H182v-6.9c0,0,0.7-0.2,0.9-0.4c0.4-0.3,0.8-1.1,1.1-1.4c0.3-0.3,1.1-0.5,1.3-0.9
        c0.1-0.3-0.1-1-0.2-1.3c-0.2-0.4-1-1.1-1.4-1.3c0,0,0,0,0,0c0.3-0.1,0.9-0.1,1.2-0.1c0.3,0,1,0.1,1.3,0c0.2,0,0.8-0.2,0.9-0.4
        c0.2-0.3-0.1-1,0-1.3c0.1-0.1,0.4-0.3,0.6-0.3c0.3-0.1,1,0.4,1.3,0.1c0.2-0.2-0.2-1,0-1.2c0.2-0.2,0.9-0.1,1.1-0.3
        c0.1-0.2,0-0.7,0.1-0.9c0.1-0.1,0.5-0.1,0.6-0.3c0.2-0.3,0.2-1,0.3-1.3c0.1-0.3,0.1-1.2,0.4-1.4c0.3-0.2,1,0.4,1.4,0.3
        c0.4-0.1,0.8-0.8,1-1.1c0.5-0.5,1.5-1.3,1.8-1.8c0.2-0.3,0.1-1.2,0.4-1.4c0.2-0.2,1.1,0,1.3-0.3c0.2-0.3-0.2-1.2,0-1.5
        c0.2-0.4,1.1-0.8,1.4-1.1c0.5-0.5,1.2-1.6,1.7-2.1c0.3-0.3,0.8-0.8,1.2-1.1c0.1-0.1,0.4-0.2,0.6-0.4c0,0.3-0.1,0.8,0,1
        c0.1,0.3,0.6,0.8,0.7,1.1c0.1,0.3,0.3,0.9,0.5,1.2c0.2,0.3,0.7,0.8,1,1.1c0.2,0.2,0.8,0.5,1.1,0.7c0.2,0.2,0.5,0.6,0.8,0.7
        c0.2,0.1,0.7,0.3,0.8,0.2c0.2-0.1,0.2-0.9,0.4-1.1c0.1-0.1,0.5-0.1,0.6,0c0.3,0.2,0.5,1.1,0.5,1.5c0,0.3-0.5,0.9-0.5,1.2
        c0,0.3,0.5,0.7,0.7,0.8c0.7,0.4,0.8,0.8,0.8,1.3c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.2-0.1,0.8,0.1,1c0.2,0.3,1,0.2,1.2,0.5
        c0.2,0.3-0.1,1.2,0.1,1.5c0.1,0.2,0.5,0.3,0.6,0.4c0.1,0.2,0.1,0.6,0,0.7c-0.1,0.2-0.6,0.3-0.7,0.5c-0.2,0.5,0.3,1.4,0.5,1.9
        c0.1,0.4,0.7,1.2,0.7,1.6c0,0.3-0.6,0.8-0.7,1.1c-0.1,0.3,0,1,0.1,1.4c0.2,0.4,0.8,1.1,1,1.5c0.2,0.3,0.9,0.8,0.9,1.1
        c0.1,0.4-0.4,1-0.5,1.4c-0.2,0.5-0.4,1.5-0.7,2c-0.1,0.3-0.5,0.7-0.5,1c0,0.4,0.7,1.1,1,1.4c0.2,0.4,0.9,1,0.8,1.4
        c-0.1,0.4-1,0.8-1.2,1.1c0,0,0,0.1-0.1,0.2L208,236H190.4z"/>
    </g>
    <g id="Lumpkin">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="lumpkin-county" ref="lumpkin-county" class="st10" d="M174.8,62.4c0.2,0,0.5,0.2,0.6,0.1c0.2-0.1,0.4-0.8,0.6-0.9c0.2-0.1,0.8,0.1,1.1,0.1
        c0.3,0,1.1,0,1.3-0.3c0.1-0.2-0.2-0.7-0.1-0.9c0-0.2,0.3-0.5,0.4-0.6c0,0,0.1,0,0.1,0c0.2,0,0.5,0.2,0.7,0.2
        c0.2,0,0.6-0.3,0.8-0.5c0.2-0.2,0.2-0.8,0.4-1c0.2-0.2,0.8-0.3,1-0.2c0.4,0,1,0.4,1.4,0.5c0.3,0.1,0.9,0.4,1.2,0.5
        c0.4,0.1,1.3,0.2,1.8,0.1c0.2-0.1,0.3-0.4,0.5-0.4c0.2-0.1,0.7,0.1,0.9,0.2c0.3,0.2,0.7,0.8,1,1c0.2,0.1,0.6,0.4,0.8,0.5
        c0.4,0.1,1.3,0.3,1.5,0c0.2-0.2-0.4-0.8-0.3-1c0.1-0.2,0.9-0.1,1.1-0.3c0.2-0.2,0-0.9,0.1-1.1c0.1-0.2,0.4-0.6,0.6-0.8
        c0.3-0.3,1.2-0.5,1.6-0.7c0.3-0.2,1-0.6,1.2-0.9c0.3-0.4,0.4-1.4,0.6-1.8c0.1-0.2,0.5-0.7,0.5-0.9c0-0.2-0.2-0.5-0.1-0.7
        c0.1-0.3,0.9-0.5,1.2-0.7c0.3-0.3,0.5-1,0.8-1.3c0.3-0.2,1-0.1,1.3-0.3c0.1-0.1,0.2-0.5,0.4-0.7c0.2-0.2,0.9-0.5,1.2-0.5
        c0.3,0,0.7,0.4,0.9,0.5c0.2,0,0.7-0.1,0.9,0c0.4,0,1.1,0.1,1.5,0.3c0.3,0.1,0.8,0.7,1.1,0.8c0.3,0.1,1,0.2,1.3,0.2
        c0.2,0,0.7,0,0.9,0c0.4,0,1.1,0,1.4,0.1c0.1,0,0.4,0.2,0.6,0.4c-0.1,0.3-0.2,0.6-0.3,0.8c-0.2,0.6-0.1,2-0.3,2.6
        c-0.1,0.3-0.7,0.9-0.9,1.2c-0.3,0.4-1,1.3-1.1,1.9c0,0.5,0.6,1.3,0.7,1.8c0.1,0.5-0.3,1.6-0.3,2.1c0,0.4,0,1.1,0,1.5
        c0,0.3-0.2,0.9-0.2,1.3c0,0.2,0.1,0.8,0.2,1c0.2,0.3,0.9,0.6,1.1,0.9c0.3,0.5,0.3,1.6,0.4,2.1c0,0.7-0.3,2.2-0.2,2.9
        c0,0.3,0.2,0.9,0.4,1.1c0.3,0.3,1.4,0.4,1.8,0.7c0.4,0.3,0.9,1.2,0.9,1.6c0,0.2-0.3,0.7-0.5,0.8c-0.1,0.1-0.5,0.2-0.5,0.2
        l-0.1,1.8l-0.1,1.2l-3.4,3.1v1h-1.1V82l-3.3,0.1v0.7l0.9,0.2l0.3,1h-1.9l-0.1,0.4c-0.2,0-0.4,0-0.6,0.1c-0.2,0.2-0.3,0.8-0.4,1.1
        c-0.1,0.3,0,0.8-0.2,1c-0.2,0.3-1,0.7-1.3,0.6c-0.4-0.1-0.9-0.8-1.1-1.2c-0.1-0.3-0.2-1-0.2-1.4c0-0.3,0.5-0.9,0.4-1.1
        c-0.1-0.2-0.6-0.4-0.8-0.4c-0.2,0-0.5,0.3-0.6,0.4c-0.2,0.2-0.4,0.6-0.4,0.8c0,0.2,0.3,0.6,0.3,0.9c-0.1,0.2-0.4,0.3-0.5,0.4
        c-0.2,0.2-0.5,0.8-0.4,1c0.1,0.2,0.6,0.3,0.7,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.1-0.1,0.2-0.2,0.3l-11.8-5.1l0-1l-1.4-0.3l-3-2.8
        l-0.7-1.2h-0.6l-3.6-3.5v-9.2l-0.6-0.7l-0.2,0.2c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.3-0.1-1.2,0.2-1.4
        C174.6,62.4,174.7,62.4,174.8,62.4z"/>
    </g>
    
    <g id="Macon">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="macon-county" ref="macon-county" class="st11" d="
        M176,354.5l-3.7-0.1v-4.5v-3.6h-3.6V337v-3.4h5.9c0,0,1.1-2.2,1.5-2.9c0.5-0.8,1.4-2.3,1.9-3.1c0.7-1.2,2-3.5,2.7-4.6
        c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.5-0.8,0.7-1.1c0.1-0.2,0.4-0.5,0.6-0.6c0.1-0.1,0.5,0,0.6-0.1c0.1-0.1,0.2-0.5,0.4-0.5
        c0.8-0.4,3,0.7,3.7,0c0.2-0.2-0.2-0.8,0-0.9c0.3-0.3,1.5-0.3,1.9,0c0.2,0.2-0.2,0.8,0,1c0.3,0.3,1.5-0.3,1.8,0
        c0.3,0.3-0.2,1.3,0,1.7c0.1,0.2,0.5,0.6,0.8,0.6c0.2,0,0.6-0.1,0.8-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0.6-0.5,0.8-0.5
        c0.4-0.1,1.3,0.2,1.7,0c0.2-0.1,0.3-0.8,0.6-0.9c0.3-0.2,0.9-0.1,1.2,0c0.3,0.1,0.7,0.6,1,0.7c0.4,0.1,1.4,0,1.8,0
        c0.4,0,1.2-0.2,1.6,0c0.3,0.1,0.6,0.8,0.9,1c0.4,0.2,1.3,0.2,1.7,0.2c0.4,0.1,1.2,0.1,1.6,0.3c0.5,0.3,1,1.4,1.5,1.7
        c0.3,0.2,1.1,0.3,1.4,0.4c0.3,0.1,1.2,0.3,1.2,0.4c0,0,0,0.1,0.1,0.2v4.3h0.9v6.5h-1.9v3h1.1v8h-5.4v1.4c0,0-1.6,0.8-2.1,1.1
        c-0.4,0.3-1.3,0.8-1.7,1.1c-0.4,0.3-1.4,0.7-1.8,1c-0.3,0.2-1,0.5-1.3,0.7c-0.3,0.3-0.5,1.1-0.7,1.5c-0.2,0.4-0.5,1.2-0.8,1.4
        c-0.3,0.3-1.2,0.5-1.5,0.8c-0.3,0.3-0.8,1.1-1.1,1.4c-0.2,0.2-0.7,0.5-0.9,0.7c-0.2,0.3,0,1-0.2,1.3c-0.2,0.3-0.8,0.9-1.1,1
        c-0.3,0.1-0.8-0.2-1.1-0.3c-0.2-0.1-0.4-0.1-0.6-0.2c-0.3-0.1-0.6-0.2-0.8-0.3c-0.5-0.1-1.6,0.2-2.1,0c-0.2-0.1-0.6-0.5-0.8-0.6
        c-0.3-0.1-1.1-0.3-1.2-0.3c-0.1,0-1,0.3-1,0.3l-2.8,0v-4.5L176,354.5z"/>
    </g>
    
    <g id="Rockdale">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="rockdale-county" ref="rockdale-county" class="st11" d="
        M191.2,169.9l5.2-5l7,5.3c-0.1,0.4-0.4,0.9-0.4,1.2c0,0.4,0.4,1.1,0.4,1.4c0,0.4,0,1.3-0.3,1.5c-0.1,0.1-0.6-0.1-0.7,0
        c-0.2,0-0.6,0.1-0.7,0.3c-0.1,0.4,0.6,1.1,0.7,1.4c0.2,0.7,0.1,2.2,0,2.9c-0.1,0.7-1,2.6-1,2.6l-11.4,14.8c-0.3,0-0.6,0-0.8-0.1
        c-0.2-0.1-0.4-0.6-0.4-0.8c-0.1-0.3,0.1-0.8-0.1-1.1c-0.2-0.3-1.2-0.5-1.5-0.7c-0.3-0.2-0.9-0.7-1.2-0.8c-0.4-0.2-1.2-0.2-1.5-0.4
        c-0.2-0.1-0.4-0.6-0.6-0.7c-0.2-0.1-0.6-0.1-0.8,0c-0.2,0.1-0.5,0.5-0.7,0.5c-0.4,0-1.1-0.3-1.3-0.5c-0.2-0.3-0.2-1.1-0.4-1.4
        c-0.2-0.4-0.7-1.1-1-1.4c-0.2-0.3-0.9-0.6-1.1-0.9c-0.2-0.3-0.2-1.1-0.3-1.4c-0.1-0.3-0.5-0.8-0.6-1c-0.2-0.3-0.5-0.8-0.7-1
        c-0.3-0.4-0.8-1.1-1-1.5c-0.1-0.2-0.1-0.6-0.1-0.9l1.1,0c0,0,0,0.5,0.1,0.7c0.2,0.2,0.7,0.3,0.9,0.4c0.1,0,0.3,0.1,0.4,0
        c0.1-0.1,0.1-0.4,0.2-0.6c0.1-0.1,0.4-0.4,0.5-0.4c0.3,0,0.5,0.6,0.7,0.8c0.1,0.1,0.5,0.3,0.6,0.4c0.2,0.1,0.7,0.4,0.9,0.6
        c0.1,0.1,0.4,0.3,0.5,0.4c0.4,0.3,1.6,1.1,1.6,1.1l5.8-13.4L191.2,169.9z"/>
    </g>
    
    <g id="Henry">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="henry-county" ref="henry-county" class="st11" d="
        M202.2,206c0.1,0.1,0.1,0.2,0.1,0.3c-0.2,0.1-0.5,0.3-0.6,0.4c-0.3,0.2-0.9,0.8-1.2,1.1c-0.5,0.5-1.2,1.6-1.7,2.1
        c-0.3,0.3-1.2,0.7-1.4,1.1c-0.2,0.3,0.2,1.2,0,1.5c-0.2,0.3-1.1,0.1-1.3,0.3c-0.3,0.3-0.2,1.1-0.4,1.4c-0.3,0.6-1.4,1.4-1.8,1.8
        c-0.3,0.3-0.7,0.9-1,1.1c-0.3,0.1-1.1-0.5-1.4-0.3c-0.3,0.2-0.3,1-0.4,1.4c-0.1,0.3-0.1,1-0.3,1.3c-0.1,0.1-0.5,0.1-0.6,0.3
        c-0.1,0.2,0,0.7-0.1,0.9c-0.2,0.2-0.9,0.1-1.1,0.3c-0.2,0.2,0.2,1,0,1.2c-0.2,0.2-1-0.2-1.3-0.1c-0.2,0-0.5,0.2-0.6,0.3
        c-0.2,0.3,0.1,1,0,1.3c-0.1,0.2-0.7,0.3-0.9,0.4c-0.3,0.1-1-0.1-1.3,0c-0.3,0-0.9-0.1-1.2,0.1c-0.2-0.2-0.7-0.4-1-0.4
        c-0.3,0-0.8,0.5-1.1,0.5c-0.3,0-0.8-0.5-1.1-0.7c-0.2-0.2-0.5-0.6-0.7-0.8c-0.2-0.2-0.6-0.3-0.7-0.5c-0.2-0.5,0-2,0-2h-9.5v-2.3
        l-10.9,0.1v-10.1c0,0,1.1,0.1,1.4,0c0.2-0.1,0.7-0.4,1-0.4c0.5,0,0.9,0.7,1.2,1.1c1.3,1.3,1-0.2,1.3-0.5c0.2-0.2,0.7,0,0.9-0.1
        c0.4-0.4,0.2-1.6,0.2-2.2c0-0.9,0-3.6,0-3.6h1V194c0,0,0.9-0.1,1.2-0.3c0.3-0.3,0.7-1.1,0.8-1.5c0.1-0.5-0.1-1.5,0-2
        c0.1-0.6,1.2-1.7,1.1-2.4c-0.1-0.4-1-0.6-1.2-1c-0.3-0.6,0.2-1.9,0-2.5c-0.1-0.3-0.8-0.8-1-1.1c-0.2-0.3-0.6-0.9-0.9-1.4l3.6,0
        c0,0,0.1,1,0.2,1.3c0.1,0.2,0.2,0.6,0.4,0.7c0.3,0.2,1,0.3,1.3,0.1c0.2-0.1,0.3-0.5,0.3-0.7c0-0.2,0-0.6,0-0.7
        c0-0.1,0.1-0.5,0.1-0.5l4,0c0,0.3,0,0.7,0.1,0.9c0.2,0.4,0.7,1.1,1,1.5c0.2,0.3,0.5,0.7,0.7,1c0.2,0.3,0.5,0.8,0.6,1
        c0.1,0.3,0.1,1.1,0.3,1.4c0.2,0.3,0.8,0.6,1.1,0.9c0.3,0.3,0.8,1,1,1.4c0.2,0.3,0.1,1.2,0.4,1.4c0.2,0.3,1,0.6,1.3,0.5
        c0.2,0,0.5-0.4,0.7-0.5c0.2-0.1,0.6-0.1,0.8,0c0.2,0.1,0.4,0.6,0.6,0.7c0.3,0.2,1.2,0.2,1.5,0.4c0.3,0.1,0.9,0.6,1.2,0.8
        c0.4,0.2,1.3,0.3,1.5,0.7c0.2,0.2,0,0.8,0.1,1.1c0.1,0.2,0.3,0.7,0.4,0.8c0.1,0.1,0.5,0.1,0.8,0.1l-0.5,0.6c0,0-0.3,0.8-0.2,1.1
        c0.1,0.4,0.8,0.8,1.2,1.1c0.3,0.2,1.1,0.5,1.4,0.7c0.3,0.2,0.8,0.9,1.2,1c0.3,0.1,1-0.1,1.4-0.1c0.4,0,1.1-0.1,1.4,0
        c0.5,0.1,1.2,0.9,1.6,1c0.4,0.1,1.3,0.1,1.7,0.2c0.3,0.1,1,0.2,1.2,0.5c0.2,0.3,0.2,1,0.4,1.4c0.1,0.2,0.4,0.6,0.5,0.9
        c0.1,0.2,0.1,0.8,0.2,0.9C201.4,205.8,202.1,205.8,202.2,206z"/>
    </g>
    
    <g id="Sumter">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="sumter-county" ref="sumter-county" class="st11" d="
        M198,378.7c0,0.1,0,0.2,0,0.2c0,0.4-0.1,1.1,0,1.5c0.1,0.3,0.5,0.9,0.6,1.2c0.1,0.3,0,1,0.2,1.2c0.1,0.2,0.6,0.3,0.6,0.5
        c0.1,0.3-0.3,1-0.2,1.3c0.1,0.3,0.7,0.7,0.8,0.9c0.2,0.3,0.6,0.9,0.7,1.2c0.2,0.4,0.5,1.2,0.6,1.6c0,0.4-0.4,1.3-0.4,1.8
        c0,0.4,0.4,1.1,0.4,1.5c0,0.3-0.2,0.8-0.2,1c0,0.1,0,0.3,0,0.5l-40.8-0.3v5.2c0,0,0,0,0,0c-0.2-0.1-0.6-0.4-0.8-0.5
        c-0.3-0.2-0.9-0.7-1.2-1.1c-0.2-0.2-0.4-0.8-0.7-1.1c-0.3-0.3-1.1-0.7-1.3-1.1c-0.2-0.3-0.4-1.1-0.6-1.4c-0.3-0.4-1.2-0.9-1.5-1.3
        c-0.1-0.1-0.1-0.5-0.1-0.6c-0.2-0.3-0.8-0.7-1.1-0.8c-0.3-0.1-0.9-0.2-1.2-0.4c-0.3-0.3-0.7-1.1-0.9-1.4c-0.2-0.3-0.6-0.8-0.8-1.1
        c-0.1-0.1-0.5-0.3-0.7-0.5v-3.6h0.4v-5.7h0.9v-11.4h0.4v-3.7l15.3,0.2v0.7h9.7v-8.6l5.1,0.2v4.5l2.8,0c0,0,0.9-0.3,1-0.3
        c0.1,0,0.9,0.2,1.2,0.3c0.2,0.1,0.6,0.5,0.8,0.6c0.5,0.2,1.6-0.1,2.1,0c0.2,0,0.5,0.1,0.8,0.3c0.2,0.4,0.4,0.8,0.5,1
        c0.2,0.3,1.1,0.6,1.3,0.9c0.2,0.4-0.2,1.5-0.3,1.8c-0.1,0.2,0.2,0.6,0.4,0.8c0.2,0.2,0.4,0.8,0.5,1c0.3,0.3,1.1,0.7,1.2,1.1
        c0.1,0.3-0.2,1.1-0.2,1.2c0,0.1,0.4,0.4,0.6,0.5c0.2,0.2,0.7,0.7,0.8,1c0.2,0.3,0.4,0.9,0.6,1.2c0.3,0.2,1.1,0.2,1.3,0.5
        c0.2,0.3,0,1-0.1,1.3c-0.1,0.3-0.5,1-0.5,1.3c0,0.4,0.8,1,0.9,1.5c0.1,0.4-0.6,1.3-0.4,1.7c0.2,0.4,1.1,0.5,1.4,0.9
        C197.9,377.9,197.9,378.3,198,378.7z"/>
    </g>
    <g id="Lee">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="lee-county" ref="lee-county" class="st10" d="M201,393c0.1,0.6,0.2,1.3,0.1,1.7c-0.1,0.3-0.6,0.6-0.7,0.9c-0.1,0.3-0.2,1-0.2,1.4
        c0,0.4,0.3,1.1,0.2,1.4c-0.1,0.3-0.8,0.7-0.9,1c-0.1,0.4,0,1.3,0.2,1.6c0,0,0.1,0.1,0.1,0.1c-0.2,0.3-0.5,0.6-0.7,0.8
        c-0.1,0.1-0.3,0.4-0.5,0.5c-0.2,0.1-0.6-0.1-0.7,0c-0.3,0.1-0.9,0.7-1.1,1c-0.1,0.3-0.2,0.8-0.2,1.1c0,0.4,0.6,1.2,0.4,1.5
        c-0.1,0.2-0.5,0.2-0.7,0.2c-0.2,0-0.7-0.3-0.9-0.3c-0.2,0-0.5,0.5-0.7,0.6c-0.3,0.1-0.9-0.2-1.1-0.1c-0.3,0.2-0.4,1.2-0.2,1.5
        c0.2,0.3,1,0.2,1.3,0.4c0.2,0.1,0.6,0.3,0.6,0.5c0.1,0.2-0.3,0.8-0.2,1c0.1,0.3,0.9,0.4,1,0.7c0.1,0.3-0.3,1-0.6,1.2
        c-0.3,0.3-1.1,0.3-1.3,0.6c-0.2,0.3,0.1,1.1,0,1.4c-0.1,0.3-0.4,1-0.7,1.3c-0.3,0.2-1,0.4-1.3,0.6c-0.2,0.2-0.3,0.8-0.5,1
        c-0.2,0.2-0.8,0.3-0.8,0.6c0,0.4,1.2,0.6,1.3,0.9c0.2,0.4-0.4,1.4-0.2,1.8c0.1,0.3,0.8,0.5,0.9,0.7c0.1,0.3-0.3,0.9-0.2,1.2
        c0.1,0.4,0.8,1,0.8,1.4c0,0.3-0.4,0.6-0.6,1.3c-0.2,0.6-1.2,0.8-1.2,0.8s-0.8,0.7-1,1c-0.2,0.4-0.3,1.4-0.6,1.7
        c-0.2,0.2-0.7,0.3-0.9,0.4c-0.3,0.1-1,0-1.2,0c-0.1,0-0.8-0.1-1,0c-0.2,0.1-0.4,0.5-0.7,0.6c-0.3,0.1-0.9,0.1-1.1,0
        c-0.2-0.1-0.6-0.4-0.8-0.5c-0.3-0.1-1.3,0-1.4,0c-0.1,0-0.7,0.7-1,0.8c-0.4,0.1-1.3-0.3-1.7-0.3c-0.4-0.1-1.3,0-1.8-0.1
        c-0.3-0.1-1.3-0.6-1.3-0.6l-12.9,0.1v-7.1h-1.6v-1.4h1.8v-6.9h0.8v-5.1h2.9c0,0,0.1-0.8,0-1.1c0-0.3-0.2-0.9-0.3-1.3
        c-0.1-0.3-0.4-0.8-0.3-1.1c0-0.3,0.6-0.6,0.7-0.8c0-0.2-0.2-0.7-0.4-0.9c-0.3-0.5-1.3-1.1-1.7-1.5c-0.1-0.2-0.4-0.6-0.5-0.8
        c-0.2-0.3-0.5-1.1-0.8-1.3c-0.3-0.3-1.1-0.5-1.4-0.6c-0.2-0.1-0.8,0-1,0c-0.3-0.1-0.7-0.5-1-0.6c-0.3-0.1-0.9,0.1-1.2,0.1v-5.2
        L201,393z"/>
    </g>
    <g id="Forsyth">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="forsyth-county" ref="forsyth-county" class="st10" d="M168.3,116.3l0-18.1h26.7c0.1,0.3,0.6,0.7,0.9,0.9c0.3,0.2,0.9,0.2,1.2,0.2
        c0.3,0,0.8-0.3,1.1-0.2c0.1,0,0.4,0.2,0.5,0.4c0.2,0.2,0.4,0.6,0.4,0.8c0,0.3-0.3,0.8-0.3,1.1c0,0.2-0.2,0.7-0.1,0.9
        c0.2,0.3,1.1,0.5,1.4,0.8c0.2,0.2,0.3,0.8,0.2,1.1c0,0.2-0.3,0.5-0.5,0.6c-0.2,0.2-0.7,0.4-0.9,0.4c-0.2,0-0.5-0.3-0.6-0.5
        c-0.1-0.2,0.1-0.8,0-1c-0.1-0.2-0.5-0.5-0.7-0.5c-0.3,0-0.6,0.6-0.7,0.8c-0.1,0.3-0.1,0.9,0.1,1.2c0.1,0.3,0.8,0.6,0.9,0.9
        c0.1,0.3,0,1.1,0,1.4c0,0.3,0.4,0.8,0.4,1.1c0,0.2-0.4,0.4-0.5,0.6c-0.1,0.2-0.1,0.7-0.3,0.8c-0.2,0.1-0.5-0.1-0.6-0.2
        c-0.2-0.2-0.4-0.8-0.4-1.1c0-0.3,0.1-0.9,0.1-1.2c0-0.2,0.1-0.8,0-1c-0.1-0.2-0.4-0.7-0.6-0.6c-0.3,0.1-0.4,0.9-0.5,1.2
        c-0.1,0.2-0.3,0.6-0.4,0.8c0,0.3,0.1,0.9,0.2,1.2c0.1,0.4,0.5,1.3,0.2,1.6c-0.1,0.2-0.6,0.1-0.8,0.1c-0.3,0-1,0.3-1.3,0.2
        c-0.3-0.1-0.5-0.9-0.7-1.1c-0.2-0.2-0.7-0.2-0.9-0.4c-0.2-0.2-0.2-0.6-0.4-0.8c-0.2-0.2-0.6-0.3-0.8-0.4c-0.2-0.1-0.6-0.2-0.7-0.4
        c-0.1-0.1-0.1-0.5-0.2-0.6c-0.2-0.2-0.6-0.9-0.9-0.8c-0.3,0.1-0.1,0.8-0.1,1.1c0,0.3,0.2,0.9,0.4,1.1c0.2,0.3,1,0.6,1.2,0.9
        c0.3,0.3,0.8,0.9,0.9,1.2c0.1,0.3,0,0.8-0.1,1.1c-0.1,0.3-0.5,0.7-0.7,0.8c-0.3,0.2-0.9,0.3-1.2,0.3c-0.3,0-0.8-0.1-1-0.2
        c-0.2-0.2-0.5-1-0.5-1c0-0.1,0.4-1,0.4-1.3c0-0.3-0.4-0.8-0.6-1c-0.1-0.1-0.4-0.4-0.6-0.4c-0.2,0-0.5,0.5-0.5,0.8
        c-0.1,0.3,0.1,0.9,0.2,1.2c0,0.3-0.1,1,0,1.4c0.1,0.2,0.5,0.5,0.6,0.7c0.1,0.2,0.1,0.6,0.1,0.9c0,0.2-0.2,0.7-0.4,0.8
        c-0.3,0-0.6-0.5-0.8-0.7c-0.1-0.2,0-0.7-0.1-0.8c-0.1-0.1-0.5,0.2-0.6,0.2c-0.2,0-0.7-0.4-0.7-0.6c-0.1-0.3,0.3-0.8,0.4-1
        c0.1-0.2,0.4-0.5,0.5-0.6c0.1-0.2,0.1-0.6-0.1-0.7c-0.2-0.2-0.8-0.1-1,0.1c-0.2,0.1-0.3,0.6-0.5,0.7c-0.1,0.1-0.5-0.1-0.6-0.1
        c-0.3-0.1-0.8-0.8-1.1-0.7c-0.2,0-0.3,0.5-0.3,0.6c0,0.2,0.2,0.6,0.4,0.7c0.1,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.5,0.6,0.5,0.8
        c0,0.3-0.8,0.6-0.8,0.9c0,0.3,0.3,0.8,0.5,1c0.2,0.1,0.5-0.1,0.7,0c0.4,0.1,1.2,0.3,1.5,0.6c0.1,0.1,0.2,0.6,0.3,0.7
        c0.2,0.1,0.8-0.2,0.9,0c0.2,0.2-0.3,0.8-0.4,1.1c-0.1,0.2-0.2,0.5-0.2,0.6c0,0.2,0.1,0.6,0.2,0.8c0.1,0.2,0.4,0.5,0.5,0.7
        c0.2,0.2,0.6,0.4,0.6,0.6c-0.3,0-0.7,0.5-0.9,0.7c-0.4,0.4-1.1,1-1.4,1.5c-0.2,0.4-0.2,1.4-0.4,1.9c-0.1,0.4-0.7,1.1-0.8,1.5
        c-0.1,0.4-0.1,1.4-0.3,1.9c-0.1,0.4-0.6,1.1-0.8,1.5c-0.2,0.5-0.6,1.4-0.5,1.9c0.1,0.3,0.6,0.6,0.8,0.8c0.2,0.3,0.9,0.7,0.9,1.1
        c0,0.2-0.5,0.5-0.7,0.7L181,132l-1.1,0.4l-3.6-2.2l-0.7-1.7l-4-2.3h-3.3L168.3,116.3z"/>
    </g>
    <g id="Dawson">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="dawson-county" ref="dawson-county" class="st10" d="M159.3,70.9v-0.2l1.2-0.8l0-0.7l0.5-0.6l1.1-0.2l0.5,0.4l0.8-0.2l0.8,0.6L165,70l1.5-0.4
        l1.3,0.2l0.9,0.4v-3.5h3.1l0.8-1.2l0.6-0.6l1.1-0.3l0.3-0.3l0.2-0.2l0.6,0.7V74l3.6,3.5h0.6l0.7,1.2l3,2.8l1.4,0.3l0,1l11.8,5.1
        c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2-0.5,0.4-0.5,0.6c0,0.1,0.1,0.4,0.2,0.5c0.2,0.2,0.9,0.3,1,0.5c0.1,0.2-0.3,0.7-0.4,0.9
        c-0.1,0.1-0.3,0.1-0.3,0.2c-0.1,0.2,0,0.8-0.1,1.1c-0.1,0.2-0.5,0.4-0.6,0.6c-0.1,0.3,0.4,1,0.4,1.3c0,0.2,0,0.8-0.2,1
        c-0.1,0.1-0.6,0.1-0.8,0.1c-0.2,0.1-0.7,0.2-0.9,0.4c-0.1,0.2-0.2,0.6-0.1,0.8c0.2,0.2,0.8,0.1,1.1,0.2c0.2,0,0.6,0.1,0.8,0.2
        c0.2,0.1,0.6,0.3,0.8,0.4c0.3,0.2,0.8,0.4,1,0.7c0.1,0.1,0.1,0.5,0,0.6c-0.1,0.1-0.5,0.2-0.7,0.1c-0.3-0.1-0.5-0.7-0.7-0.8
        c-0.2-0.1-0.8-0.1-1,0.1c-0.1,0.1-0.3,0.4-0.2,0.6c0,0,0,0.1,0,0.1h-26.7l0-5.4l0-3.2V82h-6.4l-0.6-3.9l-1.3-3.3l-0.6-2.5V70.9z"
        />
    </g>
    
    <g id="Dougherty">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="dougherty-county" ref="dougherty-county" class="st11" d="
        M164.3,428.3l12.9-0.1c0,0,0.9,0.5,1.3,0.6c0.4,0.1,1.3,0.1,1.8,0.1c0.4,0.1,1.3,0.5,1.7,0.3c0.3-0.1,0.8-0.8,1-0.8
        c0.1,0,1.1-0.1,1.4,0c0.2,0.1,0.6,0.4,0.8,0.5c0.3,0.1,0.9,0.1,1.1,0c0.2-0.1,0.4-0.5,0.7-0.6c0.2-0.1,0.9,0,1,0
        c0.1,0,0.9,0.1,1.2,0c0.2,0,0.7-0.2,0.9-0.4c0.3-0.3,0.4-1.3,0.6-1.7c0.2-0.3,1-1,1-1h3.5v21h1.3v1.6h-1.9l0,2.2l-14.5,0.2
        l-7.1,0.1v0.7l-21.6-0.1c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.3,0.5-0.6,0.7-0.8c0.2-0.3,0.5-0.9,0.6-1.3c0-0.4-0.3-1-0.4-1.4
        c-0.1-0.3-0.6-0.6-0.7-0.9c-0.1-0.3,0.3-0.8,0.3-1.1c0-0.2,0.2-0.7,0.1-0.9c-0.1-0.3-0.4-0.8-0.6-1c-0.2-0.2-0.8-0.4-0.9-0.6
        c-0.2-0.3,0.1-1.2,0-1.5c-0.1-0.4-0.5-1-0.6-1.3c-0.1-0.4,0.1-1.2-0.1-1.5c-0.2-0.2-0.9-0.2-1.1-0.5c-0.1-0.2-0.1-0.6,0-0.8
        c0-0.3,0.3-0.9,0.4-1.2c0-0.3,0-1,0-1.3c-0.1-0.3-0.6-0.9-0.6-1.2c0-0.2,0.1-0.7,0.3-0.9c0.2-0.2,0.7-0.4,0.8-0.6
        c0.2-0.3,0.1-1.1,0.1-1.5c0-0.3-0.2-1-0.2-1.4L164.3,428.3z"/>
    </g>
    
    <g id="Mitchell">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="mitchell-county" ref="mitchell-county" class="st11" d="
        M156.4,494.5l-13.5-0.1c0.2-0.3,0.7-0.7,0.8-1c0-0.2-0.1-0.7-0.2-0.9c-0.1-0.3-0.5-0.8-0.5-1.2c0.1-0.4,0.9-0.8,0.9-1.2
        c0-0.2-0.2-0.5-0.3-0.7c-0.1-0.2-0.6-0.4-0.6-0.6c0-0.4,0.8-1,1.1-1.3c0.3-0.2,0.9-0.3,1.2-0.5c0.2-0.2,0.6-0.8,0.6-1.1
        c0-0.2-0.4-0.6-0.4-0.8c0-0.2,0.4-0.5,0.5-0.6c0.2-0.2,0.3-0.8,0.6-0.9c0.3-0.1,0.9,0.1,1.2,0c0.2-0.1,0.7-0.3,0.9-0.6
        c0.2-0.2,0.1-1,0.3-1.2c0.1-0.2,0.6-0.1,0.8-0.2c0.3-0.1,0.6-0.7,0.9-0.7c0.3-0.1,0.9,0.3,1.2,0.1c0.1-0.1,0-0.6,0.1-0.7
        c0.2-0.2,1,0.2,1.3,0c0.2-0.1,0.2-0.5,0.2-0.7c-0.1-0.3-0.9-0.8-0.8-1.1c0-0.2,0.6-0.4,0.7-0.6c0.1-0.2-0.1-0.7,0-0.8
        c0.1-0.1,0.4-0.3,0.5-0.4c0.4-0.2,1.1-0.7,1.4-1c0.2-0.2,0.5-0.6,0.7-0.7c0.3-0.1,0.9,0,1.2-0.1c0.4-0.2,1-0.8,1.2-1.1
        c0.1-0.3-0.1-1.1,0-1.4c0.2-0.4,0.9-0.8,1.1-1.2c0.2-0.3,0.5-1,0.7-1.3c0.2-0.4,0.7-1.1,0.7-1.5c0-0.3-0.5-0.9-0.5-1.3
        c0-0.3,0-0.9,0.2-1.1c0.3-0.3,1.2,0,1.5-0.2c0.3-0.2,0.4-1,0.7-1.2c0.3-0.2,1,0,1.4-0.1c0.4-0.1,1.1-0.8,1.5-0.9
        c0.2-0.1,0.8,0.1,1,0c0.3-0.2,0.2-1.1,0.5-1.2c0.5-0.2,1.3,0.8,1.8,0.7c0.3,0,0.8-0.3,1-0.5c0.2-0.2,0.6-0.7,0.8-0.9
        c0.2-0.1,0.6-0.4,0.7-0.6c0.1-0.2,0-0.9,0.1-0.9c0.1,0,0.8-0.1,1.1-0.1c0.3,0,1,0.4,1.3,0.3c0.3-0.1,0.6-0.7,0.6-1.1
        c0-0.4-0.5-1-0.6-1.3c0-0.3,0-1,0.2-1.3c0.1-0.2,0.5-0.7,0.7-0.8c0.3-0.1,1.1,0.3,1.4,0.1c0.2-0.2,0.5-0.8,0.4-1.1
        c0-0.2-0.7-0.5-0.7-0.7c0-0.3,0.6-0.8,0.9-0.9c0.4-0.2,1.5,0.3,1.9,0c0.2-0.2,0.1-0.9,0.3-1.1c0.1-0.2,0.6-0.5,0.7-0.8
        c0.1-0.3,0.1-1,0-1.5l14.5-0.2l-0.1,13.3l-0.2,31.4l-11.6-0.1L156.4,494.5z"/>
    </g>
    <g id="Taylor">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="taylor-county" ref="taylor-county" class="st10" d="M173.9,298.2c0.2,0.4,0.6,0.9,0.8,1.1c0.4,0.3,1.3,0.6,1.8,0.7c0.4,0.1,1.4,0.1,1.8-0.1
        c0.3-0.1,0.5-0.7,0.8-0.8c0.2-0.1,0.7-0.1,0.9,0c0.3,0.2,0.3,1,0.6,1.1c0.2,0.1,0.7,0.1,0.9,0c0.3-0.2,0.6-1,1-1.1
        c0.3-0.1,0.9-0.1,1.2,0.1c0.2,0.1,0.4,0.7,0.5,0.9c0.1,0.3,0,0.9,0.1,1.1c0.1,0.2,0.5,0.7,0.6,0.8c0.3,0.3,1.1,0.8,1.5,1.1
        c0.2,0.2,0.6,0.6,0.7,0.8c0.1,0.1,0.1,0.4,0.2,0.6c0.2,0.2,0.8,0.4,0.9,0.6c0.1,0.2,0.1,0.6,0,0.8c-0.1,0.2-0.9,0.2-1,0.5
        c-0.1,0.3,0.1,0.9,0.2,1.2c0,0.1,0.2,0.4,0.2,0.5c0,0.2-0.2,0.7-0.2,1c-0.1,0.2-0.3,0.6-0.3,0.8c0,0.2,0.5,0.4,0.6,0.6
        c0.1,0.2,0.1,0.7,0.2,0.9c0.1,0.2,0.3,0.7,0.5,0.8c0.2,0.1,0.6,0,0.8,0.1c0.2,0.2,0.4,0.7,0.5,0.9c0.1,0.3,0.1,0.9,0.4,1.2
        c0.2,0.2,0.7,0.3,1,0.4c0.2,0.1,0.7,0.1,0.9,0.2c0.2,0.1,0.4,0.6,0.6,0.8c0.2,0.3,0.6,1,0.8,1.2c0.2,0.1,0.6,0.2,0.7,0.4
        c0,0,0,0,0,0.1c0.1,0.2,0.1,0.6,0,0.8c-0.1,0.2-0.5,0.6-0.7,0.8c-0.1,0.2-0.3,0.8-0.4,1c-0.1,0.2-0.6,0.3-0.7,0.5
        c-0.1,0.3,0.2,0.8,0.3,1c-0.2,0.2-0.6,0.3-0.8,0.3c-0.2,0-0.7-0.4-0.8-0.6c-0.2-0.4,0.3-1.4,0-1.7c-0.3-0.3-1.5,0.3-1.8,0
        c-0.2-0.2,0.2-0.8,0-1c-0.3-0.3-1.5-0.3-1.9,0c-0.2,0.2,0.2,0.8,0,0.9c-0.7,0.7-2.9-0.4-3.7,0c-0.1,0.1-0.2,0.4-0.4,0.5
        c-0.1,0.1-0.5,0.1-0.6,0.1c-0.2,0.1-0.4,0.4-0.6,0.6c-0.2,0.3-0.5,0.8-0.7,1.1c-0.1,0.2-0.4,0.5-0.5,0.7c-0.7,1.1-2,3.5-2.7,4.6
        c-0.5,0.8-1.5,2.3-1.9,3.1c-0.4,0.7-1.5,2.9-1.5,2.9h-5.9v3.4h-7v-3.2h-2.2v1.6h-1v-1.7h-0.8v-3.3h-3.1v-1.9h-1.7v-5.3h-1.3v-0.8
        h-1.1v-2.7h-1v-2.2h-1v-1.1h0.7V314h3.8v-6c0,0,1-0.5,1.2-0.7c0.5-0.4,1.4-1.2,1.7-1.8c0.2-0.3,0.6-1,0.7-1.4c0.1-0.5,0-1.5,0.1-2
        c0.1-0.3,0.4-0.9,0.5-1.2c0-0.2-0.2-0.8,0-1c0.1-0.2,0.6-0.3,0.8-0.3c0.4,0,1.3,0.4,1.7,0.2c0.2-0.1,0.2-0.7,0.3-1
        c0.1-0.5,0.2-1.4,0.2-1.9c0-0.4-0.2-1.1-0.1-1.5c0-0.2,0.1-0.6,0.2-0.8c0.2-0.2,0.9,0,1.1-0.1c0.3-0.1,0.8-0.5,1-0.7
        c0.1-0.1,0.3-0.5,0.5-0.6c0.3-0.2,0.9-0.2,1.2-0.3c0.3-0.1,0.8-0.3,1-0.5c0.2-0.2,0.3-0.7,0.4-1c0.1,0.1,0.2,0.3,0.3,0.3
        c0.3,0.2,1.1,0.2,1.4,0.4c0.2,0.1,0.7,0.3,0.9,0.5c0.1,0.2,0.1,0.6,0.2,0.8c0.1,0.1,0.3,0.4,0.5,0.4c0.3,0,0.7-0.4,1-0.5
        c0.2-0.1,0.7-0.7,0.9-0.6c0.3,0.1,0.2,0.9,0.3,1.2c0.1,0.2,0.3,0.6,0.5,0.8c0.1,0.1,0.6,0.2,0.7,0.4c0.4,0.3,0.9,1.2,1.1,1.7
        c0.2,0.3,0.3,1,0.5,1.3C173.8,298.1,173.9,298.2,173.9,298.2z"/>
    </g>
    
    <g id="DeKalb">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="deKalb-county" ref="deKalb-county" class="st11" d="
        M165.7,181.8l-6.7,0l0.2-39.2l8.1,1.9c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.2,0.5-0.2,0.7c0,0.3,0.1,1,0.2,1.4
        c0.1,0.4,0.6,1.2,0.9,1.6c0.3,0.4,0.9,1.3,1.3,1.6c0.4,0.4,1.5,0.8,1.9,1.2c0.4,0.4,1.1,1.2,1.4,1.7c0.3,0.4,0.8,1.2,1.2,1.5
        c0.3,0.2,1.4,0.6,1.4,0.6c0.1,0,0.8,0.5,1,0.7c0.2,0.2,0.5,0.6,0.8,0.8c0.4,0.3,1.3,0.4,1.7,0.7c0.3,0.2,0.5,0.8,0.8,1.1
        c0.3,0.3,0.9,0.6,1.2,0.9c0.3,0.3,0.9,1.1,1.1,1.5c0.2,0.4,0.5,1.2,0.8,1.5c0.4,0.5,1.7,1.2,2.2,1.6c0.4,0.4,1.1,1.2,1.5,1.6
        c0.2,0.2,0.5,0.5,0.7,0.6c0.5,0.3,1.7,0.7,2.2,1.1c0.4,0.3,0.9,1.1,1.1,1.5c0.2,0.3,0.6,0.8,0.8,1.3l-2.3,2.2l-5.8,13.4
        c0,0-1.2-0.8-1.6-1.1c-0.1-0.1-0.4-0.3-0.5-0.4c-0.2-0.2-0.7-0.4-0.9-0.6c-0.2-0.1-0.5-0.3-0.6-0.4c-0.2-0.2-0.4-0.8-0.7-0.8
        c-0.2,0-0.4,0.2-0.5,0.4c-0.1,0.1,0,0.5-0.2,0.6c-0.1,0.1-0.3,0-0.4,0c-0.2,0-0.8-0.2-0.9-0.4c-0.1-0.1-0.1-0.7-0.1-0.7l-1.1,0
        l-4,0c0,0-0.1,0.4-0.1,0.5c0,0.2,0.1,0.6,0,0.7c0,0.2-0.1,0.6-0.3,0.7c-0.3,0.2-1.1,0-1.3-0.1c-0.2-0.1-0.4-0.5-0.4-0.7
        c-0.1-0.3-0.2-1.3-0.2-1.3L165.7,181.8z"/>
    </g>
    <g id="Lamar">
    <polygon @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="lamar-county" ref="lamar-county" class="st10" points="190.4,236 190.4,266.3 188.5,269.1 182.2,269.1 182.2,261.9 167,261.9 167,256.8 
        169.1,256.8 169.1,237.5 171.1,237.5 171.7,238 171.7,238.8 182,238.8 182,236 		"/>
    </g>
    <g id="Grady">
    <polygon @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="grady-county" ref="grady-county" class="st10" points="182.4,507.9 186.6,514.8 186.1,543.6 155.5,541.9 156.4,494.5 182.6,494.7 		"/>
    </g>
    <g id="Spalding">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="spalding-county" ref="spalding-county" class="st10" d="M144.9,237.9c-0.3-0.1-0.7-0.3-0.8-0.5c-0.2-0.3,0-1.1,0-1.5c0-0.4,0.2-1.1,0-1.5
        c-0.1-0.2-0.6-0.5-0.8-0.7c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.2,0.2-0.5,0.2-0.7c0-0.2,0.2-0.6,0.1-0.7c-0.1-0.2-0.6-0.4-0.7-0.7
        c-0.1-0.3,0-0.9,0.1-1.1c0.1-0.1,0.5,0,0.7-0.1c0.2-0.1,0.4-0.6,0.4-0.8c0,0,0,0,0,0c0.5-0.1,1.4-0.1,1.8-0.2
        c0.6,0,1.9,0.1,2.4,0.1c0.4,0,0.9,0.2,1.4,0.2c0.3,0,0.9,0.2,1.1,0c0.3-0.3-0.2-1.2-0.1-1.5c0.1-0.5,0.4-1.4,0.7-1.8
        c0.2-0.3,0.9-0.8,1.1-1.1c0.3-0.3,0.6-1,0.9-1.2c0.2-0.1,0.8-0.1,0.9-0.3c0.2-0.3,0.1-1.1,0.3-1.4c0.1-0.1,0.4-0.3,0.5-0.4
        c0.1-0.2,0.2-0.7,0.2-0.9c0-0.3-0.7-0.9-0.6-1.2c0.1-0.2,0.5-0.2,0.7-0.4c0.1-0.1,0.2-0.5,0.2-0.7c0-0.3-0.3-1-0.3-1l3.4,0
        l10.9-0.1v2.3h9.5c0,0-0.2,1.6,0,2c0.1,0.2,0.6,0.4,0.7,0.5c0.2,0.2,0.5,0.6,0.7,0.8c0.2,0.2,0.8,0.7,1.1,0.7
        c0.3,0,0.8-0.5,1.1-0.5c0.3,0,0.7,0.2,1,0.4c0,0,0,0,0,0c0.4,0.3,1.2,0.9,1.4,1.3c0.2,0.3,0.4,1,0.2,1.3c-0.1,0.4-1,0.6-1.3,0.9
        c-0.3,0.3-0.8,1.1-1.1,1.4c-0.2,0.1-0.9,0.4-0.9,0.4v6.9v2.8h-10.2V238l-0.6-0.5h-2h-12.5v-1.3h-2.1v-0.9l-5.2,0
        c0,0-0.5,0.4-0.7,0.5c-0.3,0.2-0.8,0.4-1.1,0.5c-0.3,0.1-1-0.3-1.3-0.1c-0.3,0.2-0.2,0.9-0.4,1.2c-0.1,0.2-0.6,0.5-0.8,0.6
        C145,237.9,145,237.9,144.9,237.9z"/>
    </g>
    <g id="Fannin">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="fannin-county" ref="fannin-county" class="st10" d="M185,41.6v7.9l-6.2,10.4c0,0-0.1,0-0.1,0c-0.2,0.1-0.4,0.4-0.4,0.6c-0.1,0.2,0.3,0.7,0.1,0.9
        c-0.2,0.3-0.9,0.3-1.3,0.3c-0.3,0-0.8-0.2-1.1-0.1c-0.2,0.1-0.4,0.8-0.6,0.9c-0.1,0.1-0.4-0.1-0.6-0.1c-0.3-0.5-0.7-1.2-1-1.5
        c-0.4-0.4-1.6-1-2.1-1.3c-0.4-0.3-1-1-1.3-1.3c-0.4-0.5-1.5-1.9-1.5-1.9l-0.1-4.2H168v-1.3l-5.7-9h-1.6v-1.5l-4.3-1.4l-4.8-2.7
        l-10.8,0.1l-9.7-1.3l-0.3-0.3V18.5l49.7-0.4c0,0.5-0.1,1.1-0.3,1.4c-0.2,0.3-1,0.7-1.3,1c-0.3,0.2-0.6,0.9-0.9,1.1
        c-0.3,0.2-1.2,0.1-1.5,0.4c-0.3,0.3-0.6,1.2-0.5,1.6c0,0.3,0.4,0.9,0.7,1.2c0.2,0.3,0.8,0.8,1.1,1.1c0.3,0.3,0.9,1,1.3,1.3
        c0.4,0.2,1.3,0.2,1.7,0.4c0.4,0.3,0.8,1.1,1,1.5c0.2,0.3,0.4,1.1,0.6,1.4c0.2,0.3,0.7,0.8,0.8,1.1c0.1,0.3,0,0.9-0.1,1.2
        c-0.1,0.3-0.6,0.9-1,1c-0.2,0.1-0.6,0-0.7,0.1c-0.4,0.2-1.2,0.7-1.5,1.1c-0.2,0.3-0.4,1-0.4,1.4c0,0.3,0.3,0.9,0.4,1.3
        c0,0.3-0.2,0.8-0.1,1.1c0.1,0.2,0.5,0.6,0.6,0.8c0.1,0.3,0.2,0.9,0.2,1.2c0.1,0.2,0.1,0.7,0.3,0.9c0.3,0.3,1,0.7,1.4,0.7
        c0.3,0,0.7-0.4,1-0.5C183.6,41.6,185,41.6,185,41.6z"/>
    </g>
    
    <g id="Fulton">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="fulton-county" ref="fulton-county" class="st11" d="
        M112.3,190.9c0.1-0.4,0.1-0.8,0.2-1.1c0.2-0.5,0.9-1.2,1.3-1.6c0.4-0.4,1.4-1,1.8-1.4c0.2-0.2,0.5-0.8,0.7-1
        c0.2-0.2,0.8-0.3,1-0.4c0.2-0.2,0.3-1,0.6-1.2c0.2-0.1,0.6-0.3,0.8-0.2c0.3,0.1,0.6,1,1,1c0.3,0,0.8-0.7,1-1c0.1-0.2,0-0.8,0.2-1
        c0.3-0.4,1.1-0.9,1.6-1.1c0.3-0.1,1.1-0.1,1.5-0.2c0.4-0.1,1.2-0.3,1.6-0.4c0.3-0.1,1.1-0.2,1.4-0.4c0.3-0.2,0.9-0.8,1.1-1.2
        c0.1-0.3-0.1-0.8,0-1.1c0-0.3,0.2-0.8,0.4-1c0.2-0.2,0.7,0,1-0.1c0.3-0.1,0.9-0.3,1.1-0.6c0.2-0.3-0.2-1.2,0-1.5
        c0.2-0.3,1-0.2,1.3-0.4c0.3-0.1,1.1-0.4,1.3-0.7c0.3-0.3,0.3-1.4,0.7-1.7c0.3-0.4,1.4-0.5,1.7-0.8c0.2-0.2,0.3-0.8,0.4-1
        c0.1-0.1,0.2-0.3,0.3-0.5c0.3-0.4,0.7-0.9,0.9-1.2c0.4-0.3,1.2-0.8,1.6-1.1c0.5-0.4,1.6-1,2-1.5c0.3-0.3,0.5-1,0.8-1.3
        c0.4-0.4,1.4-0.8,1.7-1.2c0.4-0.4,1-1.5,1.4-1.9c0.3-0.3,1.1-0.7,1.4-1c0.3-0.3,0.4-1.2,0.7-1.4c0.2-0.1,0.7,0.2,0.9,0.2
        c0.2,0,0.6,0.1,0.7-0.1c0.2-0.3,0.1-1.1,0-1.4c-0.1-0.4-0.8-0.9-0.8-1.3c0-0.3,0.6-0.7,0.7-1c0.1-0.3,0.1-1.1,0.3-1.5
        c0.1-0.3,0.7-0.8,0.8-1.1c0.2-0.4,0.5-1.1,0.5-1.5c0-0.3-0.3-0.8-0.4-1.1c0-0.2,0-0.7,0.1-1c0.1-0.3,0.4-0.7,0.5-1
        c0.1-0.3,0.3-0.9,0.5-1.1c0.2-0.2,0.9-0.3,1.1-0.5c0.3-0.3,0.6-1.1,0.9-1.4c0.3-0.3,1-0.6,1.3-0.8c0.4-0.2,1.2-0.6,1.4-1
        c0.2-0.3,0.2-1.1,0.3-1.4c0.1-0.2,0.5-0.5,0.6-0.7c0.1-0.3,0.4-0.9,0.4-1.2c0-0.4-0.5-1-0.7-1.3c-0.2-0.2-0.6-0.4-0.8-0.5
        c-0.2-0.1-0.5-0.4-0.6-0.6c-0.2-0.5-0.2-1.6-0.3-2.2c0-0.5,0.1-1.4-0.1-1.8c-0.1-0.2-0.6-0.3-0.8-0.4c-0.3-0.3-0.7-1.1-0.7-1.5
        c-0.1-0.3,0.2-1,0.1-1.3c-0.1-0.3-0.7-1-0.7-1v-0.4v-3.3l0.9-0.9h1.3v-1.3h3.9v-6.7h1.6l1.4-1.4h7.4l0,10h3.3l4,2.3l0.7,1.7
        l3.6,2.2l1.1-0.4l2.4,1.4c0,0-0.1,0.1-0.1,0.1c-0.3,0.3-0.6,1.1-0.9,1.4c-0.3,0.3-1.1,0.6-1.5,0.7c-0.5,0.1-1.5-0.3-2-0.3
        c-0.4,0-1.2,0.2-1.6,0.5c-0.3,0.2-0.7,0.8-0.8,1.1c-0.3,0.5-0.5,1.6-0.7,2.1c-0.1,0.3-0.1,1.1-0.3,1.2c-0.4,0.2-1.4-0.1-1.7-0.4
        c-0.3-0.2-0.5-1-0.8-1.2c-0.4-0.2-1.5-0.1-2,0c-0.4,0.2-1,0.8-1.3,1.1c-0.3,0.3-1.1,0.9-1.3,1.3c-0.1,0.2,0.1,0.8,0,1.1
        c-0.1,0.3-0.6,0.8-0.8,1.1c-0.1,0.2-0.4,0.7-0.4,1c0,0.1,0.1,0.2,0.1,0.3l-8.1-1.9l-0.2,39.2l-10.8,0l-0.3,11.8
        c-0.2,0-0.3,0-0.5,0.1c-0.2,0.1-0.6,0.5-0.8,0.6c-0.4,0.1-1.2,0-1.5,0c-0.8,0.1-2.3,0.5-3.1,0.7c-0.4,0.1-1,0.6-1.4,0.7
        c-0.5,0.2-1.6,0.2-2.1,0.4c-0.4,0.1-1.1,0.3-1.4,0.4c-0.7,0.2-2.3,0.3-2.9,0.7c-0.4,0.2-0.8,0.9-1,1.4l-22.5-0.2
        c0.1-0.2,0.1-0.3,0.2-0.3c0.3-0.2,0.7-0.6,0.8-0.9c0.1-0.3-0.1-1-0.3-1.3c-0.2-0.3-0.7-0.6-0.9-0.9c-0.2-0.2-0.5-0.6-0.5-0.8
        c0-0.3,0.8-0.9,0.8-1c0-0.1,0.1-0.9,0.2-1.1c0.2-0.3,0.9-0.4,1.1-0.7C112.2,191.2,112.2,191,112.3,190.9z"/>
    </g>
    <g id="Upson">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="upson-county" ref="upson-county" class="st10" d="M167,261.9h15.2v7.2v9.9h-1.1v5.4h-1.4l-5.8,13.9c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.3-0.3-1-0.5-1.3
        c-0.2-0.4-0.8-1.3-1.1-1.7c-0.1-0.1-0.6-0.2-0.7-0.4c-0.2-0.2-0.4-0.6-0.5-0.8c-0.1-0.3,0-1.1-0.3-1.2c-0.3-0.1-0.7,0.5-0.9,0.6
        c-0.2,0.1-0.7,0.5-1,0.5c-0.2,0-0.4-0.2-0.5-0.4c-0.1-0.2-0.1-0.6-0.2-0.8c-0.2-0.2-0.6-0.4-0.9-0.5c-0.3-0.1-1.1-0.2-1.4-0.4
        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.2-0.8-0.5-1.1-0.6c-0.2-0.1-0.4-0.4-0.6-0.4c-0.3,0-0.7,0.4-0.9,0.4
        c-0.3,0-0.7-0.6-1-0.7c-0.3-0.1-1,0.4-1.3,0.2c-0.3-0.2-0.1-1.3-0.3-1.6c-0.2-0.3-0.8-0.7-1.1-0.7c-0.3,0-0.7,0.4-1,0.5
        c-0.4,0.1-1.1,0.1-1.4,0c-0.3-0.1-0.8-0.6-1-0.8c-0.2-0.2-0.7-0.7-0.8-1.1c-0.1-0.4,0.1-1.1,0-1.5c-0.1-0.4-0.7-1-0.9-1.3
        c-0.2-0.2-0.6-0.5-0.7-0.7c-0.2-0.4,0-0.9-0.2-1.4c-0.1-0.4-0.6-0.8-1-0.9c-0.4-0.1-1.1,0.1-1.3,0.5c-0.2,0.3,0,0.8-0.2,1.1
        c-0.2,0.3-0.8,0.6-1.1,0.6c-0.3,0-0.8-0.4-0.9-0.7c-0.1-0.3,0-1.1-0.1-1.4c-0.1-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.1-0.8-0.3-1.1
        c-0.2-0.2-0.7-0.4-1-0.4c-0.3,0-0.6,0.5-0.8,0.5c-0.2,0-0.6-0.4-0.6-0.6c-0.1-0.3,0.3-0.8,0.4-1c0.1-0.2,0.6-0.5,0.7-0.7
        c0.1-0.2,0-0.8-0.2-0.9c-0.1-0.1-0.4-0.1-0.5-0.1c-0.3-0.1-0.9,0.1-1.6-0.3c0,0-0.7-0.8-0.7-0.9c0-0.1,0.3-1.3,0.2-1.7
        c0-0.3-0.1-1.1-0.3-1.3c-0.2-0.2-1,0.1-1.3,0c-0.3-0.2-0.5-0.9-0.6-1.2c0-0.2,0.1-0.6,0.1-0.8c0.1-0.2,0.4-0.6,0.5-0.8
        c0.1-0.4,0.4-1.1,0.3-1.5c0-0.3-0.2-0.8-0.4-1.1c-0.2-0.2-0.8-0.4-0.9-0.7c-0.1-0.1-0.1-0.4-0.1-0.6c0.6,0,1.9-0.1,2.4-0.4
        c0.3-0.2,0.7-0.6,0.7-1.4c0-0.4,1.1-0.8,1.1-0.8H167z"/>
    </g>
    <g id="Baker">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="baker-county" ref="baker-county" class="st10" d="M151.4,451l21.6,0.1v-0.7l7.1-0.1c0,0.5,0.1,1.2,0,1.5c-0.1,0.2-0.6,0.5-0.7,0.8
        c-0.1,0.3-0.1,0.9-0.3,1.1c-0.4,0.3-1.5-0.2-1.9,0c-0.3,0.1-0.9,0.6-0.9,0.9c0,0.3,0.7,0.5,0.7,0.7c0.1,0.3-0.2,0.9-0.4,1.1
        c-0.3,0.2-1.1-0.2-1.4-0.1c-0.2,0.1-0.6,0.6-0.7,0.8c-0.1,0.3-0.2,0.9-0.2,1.3c0,0.4,0.6,1,0.6,1.3c0,0.3-0.4,0.9-0.6,1.1
        c-0.3,0.1-1-0.3-1.3-0.3c-0.3,0-0.9,0.1-1.1,0.1c-0.1,0,0,0.7-0.1,0.9c-0.1,0.2-0.5,0.4-0.7,0.6c-0.2,0.2-0.6,0.7-0.8,0.9
        c-0.2,0.2-0.7,0.5-1,0.5c-0.5,0-1.4-0.9-1.8-0.7c-0.3,0.1-0.3,1-0.5,1.2c-0.2,0.1-0.8-0.1-1,0c-0.4,0.1-1.1,0.8-1.5,0.9
        c-0.3,0.1-1.1-0.1-1.4,0.1c-0.3,0.2-0.4,1-0.7,1.2c-0.3,0.2-1.3-0.1-1.5,0.2c-0.2,0.2-0.3,0.8-0.2,1.1c0,0.3,0.5,0.9,0.5,1.3
        c0,0.4-0.5,1.1-0.7,1.5c-0.2,0.3-0.5,1-0.7,1.3c-0.2,0.3-1,0.8-1.1,1.2c-0.1,0.3,0.1,1.1,0,1.4c-0.2,0.4-0.8,1-1.2,1.1
        c-0.3,0.1-0.9,0-1.2,0.1c-0.2,0.1-0.5,0.6-0.7,0.7c-0.3,0.3-1.1,0.7-1.4,1c-0.1,0.1-0.4,0.2-0.5,0.4c-0.1,0.2,0.1,0.7,0,0.8
        c-0.1,0.2-0.6,0.4-0.7,0.6c0,0.3,0.8,0.8,0.8,1.1c0,0.2-0.1,0.6-0.2,0.7c-0.3,0.2-1.1-0.2-1.3,0c-0.1,0.1,0,0.6-0.1,0.7
        c-0.2,0.2-0.9-0.2-1.2-0.1c-0.3,0.1-0.7,0.6-0.9,0.7c-0.2,0.1-0.7,0.1-0.8,0.2c-0.2,0.2-0.1,0.9-0.3,1.2c-0.2,0.2-0.6,0.5-0.9,0.6
        c-0.3,0.1-0.9-0.1-1.2,0c-0.2,0.1-0.4,0.7-0.6,0.9c-0.1,0.2-0.5,0.4-0.5,0.6c0,0.2,0.4,0.6,0.4,0.8c0,0.3-0.4,0.9-0.6,1.1
        c-0.2,0.2-0.9,0.3-1.2,0.5c-0.3,0.2-1.1,0.8-1.1,1.3c0,0.2,0.5,0.4,0.6,0.6c0.1,0.2,0.3,0.5,0.3,0.7c-0.1,0.4-0.9,0.8-0.9,1.2
        c0,0.3,0.4,0.9,0.5,1.2c0.1,0.2,0.2,0.7,0.2,0.9c-0.1,0.3-0.6,0.7-0.8,1l-3.4,0l0.4-21.6l-10.5,0l0.6-21.6l6.5,0.1
        c0,0,0.2,0.4,0.4,0.4c0.3,0.1,0.9,0,1.1-0.1c0.2-0.1,0.6-0.3,0.7-0.4c0.1-0.2,0.1-0.7,0.3-0.8c0.2-0.1,0.7-0.1,0.9,0
        c0.2,0.1,0.4,0.6,0.6,0.8c0.1,0.1,0.5,0.3,0.6,0.3c0.2,0,0.4-0.4,0.5-0.5c0.1-0.2,0.1-0.7,0.3-0.8c0.1-0.1,0.5,0.2,0.5,0.3
        c0.1,0.2,0,0.7,0.1,0.9c0.2,0.3,0.9,0.5,1.2,0.5c0.2,0,0.8,0,1-0.2c0.1-0.1,0.1-0.6,0.2-0.7c0.1-0.1,0.6-0.2,0.7-0.4
        c0.1-0.2,0-1.1,0-1.1h3.5v1.4L151.4,451z"/>
    </g>
    <g id="Schley">
    <polygon @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="schley-county" ref="schley-county" class="st10" points="176,354.5 176,363.1 166.3,363.1 166.3,362.5 150.9,362.2 150.9,361 151.7,361 
        151.7,358.8 151,358.8 151,354 152.5,354 152.5,345.8 154.6,345.8 154.6,330.4 157.7,330.4 157.7,333.7 158.6,333.7 158.6,335.5 
        159.6,335.5 159.6,333.8 161.8,333.8 161.8,337 168.7,337 168.7,346.3 172.3,346.3 172.3,349.9 172.3,354.4 		"/>
    </g>
    <g id="Gilmer">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="gilmer-county" ref="gilmer-county" class="st10" d="M128,67.6l-0.1-17.7l7.3-11.5l-4-3.5l9.7,1.3l10.8-0.1l4.8,2.7l4.3,1.4v1.5h1.6l5.7,9v1.3h0.8
        l0.1,4.2c0,0,1.1,1.4,1.5,1.9c0.3,0.3,0.9,1.1,1.3,1.3c0.5,0.3,1.7,1,2.1,1.3c0.3,0.3,0.7,1,1,1.5c-0.1,0-0.2,0-0.2,0
        c-0.3,0.2-0.2,1-0.2,1.4c0,0.1,0.1,0.3,0.1,0.4l-0.3,0.3l-1.1,0.3l-0.6,0.6l-0.8,1.2h-3.1v3.5l-0.9-0.4l-1.3-0.2L165,70l-0.8-0.9
        l-0.8-0.6l-0.8,0.2l-0.5-0.4l-1.1,0.2l-0.5,0.6l0,0.7l-1.2,0.8v0.2h-2.6v1.5H128L128,67.6z"/>
    </g>
    <g id="Pike">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="pike-county" ref="pike-county" class="st10" d="M169.1,237.5v19.3H167v5.1h-22.4c0,0-1,0.3-1.1,0.8c-0.1,0.8-0.4,1.2-0.7,1.4
        c-0.5,0.3-1.7,0.4-2.4,0.4c0-0.1,0-0.1,0.1-0.2c0.2-0.3,1.1-0.3,1.2-0.7c0-0.3-0.8-0.6-1-0.9c-0.2-0.3-0.3-1.1-0.2-1.4
        c0.1-0.3,0.8-0.6,0.7-0.9c-0.1-0.4-1.3-0.6-1.4-1c-0.1-0.3,0.7-0.7,0.7-1.1c0-0.4-0.8-1.1-0.8-1.5c0-0.3,0.3-0.9,0.5-1.2
        c0.2-0.2,0.9-0.3,1-0.5c0.2-0.3,0.2-1.1,0.2-1.4c0-0.4-0.3-1-0.3-1.4c0-0.3-0.1-0.9,0-1c0.2-0.2,0.8,0.2,0.9,0
        c0.3-0.2,0.4-1.1,0.2-1.4c-0.2-0.3-1-0.3-1.2-0.6c-0.1-0.2-0.1-0.7,0-0.8c0.2-0.3,1-0.4,1.1-0.7c0.1-0.3-0.4-1.1-0.3-1.4
        c0.1-0.3,0.6-0.6,0.7-0.8c0.1-0.2,0.1-0.7,0-0.9c-0.1-0.3-0.6-0.8-0.6-1.1c0-0.4,0.7-0.9,0.7-1.3c0.1-0.3-0.1-1.1,0-1.4
        c0.1-0.5,0.2-1.7,0.6-2c0.2-0.2,1.1-0.1,1.3-0.3c0.1-0.1,0.2-0.3,0.3-0.5c0.1,0,0.1,0,0.2,0c0.2,0,0.7-0.4,0.8-0.6
        c0.2-0.3,0.1-1,0.4-1.2c0.3-0.2,1,0.2,1.3,0.1c0.3-0.1,0.8-0.3,1.1-0.5c0.2-0.1,0.7-0.5,0.7-0.5l5.2,0v0.9h2.1v1.3H169.1z"/>
    </g>
    
    <g id="Clayton">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="clayton-county" ref="clayton-county" class="st11" d="
        M158.9,181.8l6.7,0c0.3,0.5,0.7,1.1,0.9,1.4c0.2,0.3,0.9,0.7,1,1.1c0.2,0.6-0.3,2,0,2.5c0.2,0.3,1.1,0.6,1.2,1
        c0.1,0.6-0.9,1.7-1.1,2.4c-0.1,0.5,0.1,1.5,0,2c-0.1,0.4-0.5,1.3-0.8,1.5c-0.2,0.2-1.2,0.3-1.2,0.3v7.9h-1c0,0,0,2.7,0,3.6
        c0,0.5,0.2,1.8-0.2,2.2c-0.1,0.1-0.7-0.1-0.9,0.1c-0.3,0.3,0,1.9-1.3,0.5c-0.3-0.4-0.7-1.1-1.2-1.1c-0.2,0-0.8,0.3-1,0.4
        c-0.3,0.1-1.4,0-1.4,0v10.1l-3.4,0c0,0-0.9-0.8-0.9-0.9c0-0.2-0.1-1.1,0.1-1.4c0.1-0.2,0.6-0.2,0.7-0.4c0.1-0.2-0.1-0.7-0.2-0.9
        c-0.1-0.3-0.7-0.7-0.7-0.9c0-0.2,0.6-0.4,0.7-0.5c0.2-0.2,0.5-0.7,0.5-1c0-0.2-0.3-0.5-0.3-0.7c0-0.2,0.3-0.7,0.2-0.9
        c-0.1-0.2-0.5-0.3-0.7-0.4c-0.2,0-0.5,0.2-0.6,0.1c-0.3-0.2-0.2-1.2-0.1-1.5c0.1-0.3,0.8-0.6,0.8-0.7c0-0.1,0-0.9,0-1.2
        c0-0.4,0.2-1.2,0.1-1.6c-0.1-0.4-0.5-1.2-0.6-1.6c-0.2-0.5-0.3-1.6-0.7-2c-0.2-0.3-1-0.3-1.3-0.5c-0.3-0.2-0.8-0.7-0.9-1.1
        c-0.2-0.4,0.2-1.4,0-1.8c-0.1-0.3-1-0.6-1.1-0.9c-0.1-0.4,0.3-1.2,0.2-1.6c0-0.3-0.1-0.9-0.3-1.1c-0.2-0.2-1-0.3-1.3-0.4
        c-0.3,0-0.8-0.1-1.2-0.1l0.3-11.8L158.9,181.8z"/>
    </g>
    <g id="Cherokee">
    <polygon @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="cherokee-county" ref="cherokee-county" class="st10" points="168.3,98.2 168.3,116.3 160.9,116.3 159.5,117.6 157.9,117.6 157.9,124.4 
        154.1,124.4 154.1,125.7 152.7,125.7 151.8,126.6 151.8,129.9 128.6,129.4 127.6,129.4 128.1,88.8 135.5,88.8 135.5,92.6 
        149.6,92.6 149.6,91.7 151.3,91.7 151.3,92.8 168.2,92.8 		"/>
    </g>
    <g id="Pickens">
    <polygon @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="pickens-county" ref="pickens-county" class="st10" points="168.2,89.5 168.2,92.8 151.3,92.8 151.3,91.7 149.6,91.7 149.6,92.6 135.5,92.6 
        135.5,88.8 128.1,88.8 128.1,88.7 128,72.4 156.8,72.4 156.8,70.9 159.3,70.9 159.3,72.3 160,74.8 161.2,78.1 161.8,82 168.2,82    "/>
    </g>
    
    <g id="Terrell">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="terrell-county" ref="terrell-county" class="st11" d="
        M149,428.5l-10,0.1c0,0,0-0.1,0-0.1c0.1-0.3,0.5-0.8,0.6-1.1c0.2-0.4,0.5-1.3,0.5-1.7c0-0.4-0.5-0.9-0.6-1.3
        c-0.1-0.4,0-1.3,0.2-1.7c0.1-0.3,0.7-0.6,0.7-0.9c0-0.3-0.5-0.9-0.5-1c0-0.1-0.2-0.9-0.3-1.2c-0.1-0.3-0.5-0.8-0.6-1.1
        c-0.1-0.4,0.1-1.2-0.1-1.5c-0.1-0.3-0.6-0.8-0.8-1c-0.2-0.3-0.6-0.7-0.7-1c-0.1-0.3,0.1-0.9,0.1-1.1c-0.1-0.2-0.4-0.6-0.5-0.8
        c-0.1-0.3-0.4-1-0.3-1.3c0-0.2,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.9,0.2-0.9h-4l0.3-18.1l5.6,0.1c0,0,1.9,0,2.5,0
        c0.4,0,1.2,0.2,1.6,0.1c0.3,0,0.8-0.2,1.1-0.2c0.3,0,1,0.1,1.4,0.1c0.4,0,1.6,0.1,1.7,0.1c0.1,0,0.6-0.5,0.8-0.6
        c0.2-0.1,0.9-0.2,0.9-0.2v-4.8c0.3,0.2,0.6,0.3,0.7,0.5c0.2,0.2,0.6,0.8,0.8,1.1c0.2,0.3,0.6,1.2,0.9,1.4c0.2,0.2,0.9,0.3,1.2,0.4
        c0.3,0.2,0.9,0.5,1.1,0.8c0.1,0.1,0.1,0.5,0.1,0.6c0.3,0.4,1.2,0.9,1.5,1.3c0.2,0.3,0.4,1.1,0.6,1.4c0.3,0.3,1,0.8,1.3,1.1
        c0.2,0.2,0.5,0.8,0.7,1.1c0.3,0.3,0.9,0.8,1.2,1.1c0.2,0.1,0.6,0.4,0.8,0.5c0,0,0,0,0,0c0.3,0.1,0.9-0.1,1.2-0.1
        c0.3,0.1,0.7,0.5,1,0.6c0.2,0.1,0.8,0,1,0c0.4,0.1,1.2,0.4,1.4,0.6c0.3,0.2,0.6,1,0.8,1.3c0.1,0.2,0.3,0.6,0.5,0.8
        c0.4,0.4,1.4,1.1,1.7,1.5c0.1,0.2,0.4,0.6,0.4,0.9c0,0.3-0.6,0.6-0.7,0.8c-0.1,0.3,0.3,0.8,0.3,1.1c0.1,0.3,0.3,0.9,0.3,1.3
        c0,0.3,0,1.1,0,1.1h-2.9v5.1h-0.8v6.9h-1.8v1.4h1.6v7.1L149,428.5z"/>
    </g>
    
    <g id="Talbot">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="talbot-county" ref="talbot-county" class="st11" d="
        M123.8,311.4v-8h0.4v-4.1h-0.9c0,0-0.1-1.1-0.1-1.4c0.1-0.4,0.3-1,0.5-1.3c0.1-0.2,0.5-0.4,0.6-0.6c0.2-0.4-0.2-1.2,0-1.6
        c0.1-0.2,0.6-0.4,0.7-0.6c0.1-0.4-0.5-1-0.6-1.4c-0.1-0.5-0.2-1.7-0.3-2.2c-0.1-0.6-0.3-1.7-0.1-2.2c0.1-0.3,0.6-0.6,0.6-0.9
        c0.1-0.6-0.4-1.8-0.5-2.5c0-0.5,0.2-1.4,0.1-1.9c0-0.5-0.2-1.5-0.3-2c-0.1-0.3-0.3-0.6-0.4-1l0.2,0.1l12.5-0.1l7.2-4.5
        c0.6,0.5,1.3,0.3,1.6,0.3c0.1,0,0.4,0,0.5,0.1c0.2,0.2,0.2,0.7,0.2,0.9c-0.1,0.2-0.5,0.5-0.7,0.7c-0.1,0.2-0.5,0.8-0.4,1
        c0,0.2,0.4,0.6,0.6,0.6c0.3,0,0.6-0.5,0.8-0.5c0.2,0,0.8,0.2,1,0.4c0.2,0.2,0.1,0.8,0.3,1.1c0.2,0.2,0.5,0.4,0.6,0.6
        c0.2,0.3,0,1.1,0.1,1.4c0.1,0.3,0.6,0.7,0.9,0.7c0.3,0,0.9-0.4,1.1-0.6c0.2-0.3,0.1-0.8,0.2-1.1c0.2-0.4,0.9-0.5,1.3-0.5
        c0.4,0.1,0.8,0.5,1,0.9c0.2,0.5,0,1.1,0.2,1.4c0.1,0.2,0.5,0.5,0.7,0.7c0.3,0.3,0.8,0.9,0.9,1.3c0.1,0.4-0.1,1.1,0,1.5
        c0.1,0.3,0.6,0.8,0.8,1.1c0.2,0.2,0.7,0.7,1,0.8c0.3,0.1,1.1,0.1,1.4,0c0.3-0.1,0.7-0.5,1-0.5c0.3,0,0.9,0.5,1.1,0.7
        c0.2,0.4,0,1.4,0.3,1.6c0.3,0.2,1-0.3,1.3-0.2c0.3,0.1,0.7,0.7,1,0.7c0.3,0,0.7-0.4,0.9-0.4c0.2,0,0.5,0.3,0.6,0.4
        c0.3,0.2,0.8,0.5,1.1,0.6c0.1,0.1,0.3,0.3,0.4,0.5c-0.1,0.3-0.2,0.8-0.4,1c-0.2,0.2-0.7,0.4-1,0.5c-0.3,0.1-0.9,0.1-1.2,0.3
        c-0.2,0.1-0.4,0.5-0.5,0.6c-0.2,0.2-0.7,0.6-1,0.7c-0.3,0.1-0.9-0.1-1.1,0.1c-0.2,0.1-0.2,0.6-0.2,0.8c0,0.4,0.1,1.1,0.1,1.5
        c0,0.5-0.1,1.4-0.2,1.9c0,0.2-0.1,0.8-0.3,1c-0.4,0.2-1.3-0.3-1.7-0.2c-0.2,0-0.7,0.1-0.8,0.3c-0.1,0.2,0.1,0.7,0,1
        c-0.1,0.3-0.4,0.9-0.5,1.2c-0.1,0.5,0,1.5-0.1,2c-0.1,0.4-0.4,1.1-0.7,1.4c-0.3,0.5-1.2,1.4-1.7,1.8c-0.3,0.2-1.2,0.7-1.2,0.7v6
        h-3.8h-4.6c0,0,0.2,0.8,0.1,1c-0.1,0.2-0.4,0.5-0.6,0.6c-0.2,0.1-0.5-0.1-0.7,0c-0.1,0.1-0.1,0.6-0.2,0.6c-0.2,0.1-0.7,0-0.9,0
        c-0.3,0-1-0.1-1.3-0.2c-0.2-0.1-0.3-0.5-0.5-0.6c-0.2-0.1-0.6-0.2-0.8,0c-0.2,0.1,0,0.6-0.2,0.8c-0.1,0.1-0.3,0.1-0.5,0.1
        c-0.2-0.1-0.4-0.6-0.6-0.6c-0.2,0-0.7,0.2-0.9,0.4c-0.1,0.1-0.1,0.5-0.2,0.6c-0.1,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.4,0,0.5
        c0.1,0.2,0.6,0.2,0.7,0.4c0,0.1,0,0.3,0,0.4c-0.1,0.2-0.6,0.2-0.8,0.2c-0.2,0-0.7-0.1-0.9,0c-0.2,0.1-0.4,0.5-0.6,0.5
        c-0.3,0.1-1-0.2-1.2-0.5c-0.2-0.2-0.2-0.7-0.4-0.9c-0.2-0.2-0.8-0.4-1.1-0.3c-0.2,0.1-0.4,0.7-0.7,0.7c-0.3,0.1-1-0.3-1.3-0.5
        c-0.1,0-0.2-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.3,0.4-0.5,0.5c-0.1,0.1-0.4,0-0.6,0
        c-0.2,0-0.5,0-0.7,0.1c-0.3,0.2-0.5,0.8-0.8,1c-0.2,0.1-0.6,0.3-0.8,0.2c-0.1,0-0.4-0.1-0.6-0.2c0.1-0.2,0.2-0.6,0.3-0.7
        c0.2-0.2,0.7-0.6,0.8-0.9c0.1-0.3-0.4-1-0.4-1.3c0-0.2,0.5-0.6,0.6-0.8c0.1-0.2,0.3-0.6,0.2-0.8c0-0.3-0.4-0.8-0.7-0.9
        c-0.3-0.1-0.7,0.3-1,0.4c-0.2,0-0.9-0.3-0.9-0.3V311.4z"/>
    </g>
    <g id="Cobb">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="cobb-county" ref="cobb-county" class="st10" d="M127.6,129.4l1,0l23.2,0.5v0.4c0,0,0.7,0.7,0.7,1c0.1,0.3-0.2,1-0.1,1.3c0.1,0.4,0.5,1.2,0.7,1.5
        c0.2,0.2,0.7,0.3,0.8,0.4c0.2,0.4,0.1,1.4,0.1,1.8c0.1,0.5,0,1.7,0.3,2.2c0.1,0.2,0.4,0.4,0.6,0.6c0.2,0.1,0.6,0.3,0.8,0.5
        c0.3,0.3,0.7,0.9,0.7,1.3c0,0.3-0.2,0.9-0.4,1.2c-0.1,0.2-0.5,0.5-0.6,0.7c-0.1,0.3-0.1,1.1-0.3,1.4c-0.2,0.4-1.1,0.8-1.4,1
        c-0.3,0.2-1,0.5-1.3,0.8c-0.3,0.3-0.6,1.1-0.9,1.4c-0.2,0.2-0.9,0.3-1.1,0.5c-0.2,0.2-0.4,0.8-0.5,1.1c-0.1,0.2-0.5,0.7-0.5,1
        c-0.1,0.2-0.1,0.7-0.1,1c0,0.3,0.4,0.8,0.4,1.1c0,0.4-0.3,1.2-0.5,1.5c-0.2,0.3-0.7,0.8-0.8,1.1c-0.1,0.3-0.1,1.1-0.3,1.5
        c-0.1,0.3-0.7,0.7-0.7,1c0,0.4,0.7,0.9,0.8,1.3c0.1,0.3,0.3,1.2,0,1.4c-0.1,0.1-0.6,0.1-0.7,0.1c-0.2,0-0.7-0.3-0.9-0.2
        c-0.3,0.2-0.5,1.1-0.7,1.4c-0.3,0.3-1.1,0.7-1.4,1c-0.4,0.4-1,1.5-1.4,1.9c-0.4,0.4-1.4,0.8-1.7,1.2c-0.3,0.3-0.5,1-0.8,1.3
        c-0.4,0.5-1.5,1.1-2,1.5c-0.4,0.3-1.3,0.8-1.6,1.1c-0.3,0.2-0.6,0.7-0.9,1.2l-5.7-7.8l-9.2,0.1l-0.1-17.3h-1.5l0.3-15.9
        L127.6,129.4z"/>
    </g>
    <g id="Fayette">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="fayette-county" ref="fayette-county" class="st10" d="M133.2,198.6c0.3-0.5,0.7-1.2,1-1.4c0.6-0.4,2.2-0.5,2.9-0.7c0.4-0.1,1.1-0.3,1.4-0.4
        c0.5-0.1,1.6-0.2,2.1-0.4c0.4-0.1,1-0.6,1.4-0.7c0.7-0.3,2.3-0.6,3.1-0.7c0.4,0,1.2,0.1,1.5,0c0.2-0.1,0.6-0.6,0.8-0.6
        c0.1,0,0.3-0.1,0.5-0.1c0.4,0,0.9,0.1,1.2,0.1c0.3,0.1,1.1,0.1,1.3,0.4c0.2,0.2,0.3,0.8,0.3,1.1c0,0.4-0.4,1.2-0.2,1.6
        c0.1,0.3,0.9,0.6,1.1,0.9c0.2,0.4-0.2,1.4,0,1.8c0.1,0.3,0.7,0.9,0.9,1.1c0.3,0.2,1.1,0.3,1.3,0.5c0.4,0.4,0.5,1.5,0.7,2
        c0.1,0.4,0.6,1.2,0.6,1.6c0.1,0.4-0.1,1.2-0.1,1.6c0,0.3,0,1.1,0,1.2c0,0.1-0.7,0.5-0.8,0.7c-0.2,0.3-0.2,1.3,0.1,1.5
        c0.1,0.1,0.5-0.1,0.6-0.1c0.2,0,0.6,0.2,0.7,0.4c0.1,0.2-0.2,0.7-0.2,0.9c0,0.2,0.3,0.5,0.3,0.7c0,0.3-0.3,0.8-0.5,1
        c-0.1,0.2-0.7,0.3-0.7,0.5c0,0.3,0.6,0.7,0.7,0.9c0.1,0.2,0.3,0.7,0.2,0.9c-0.1,0.2-0.6,0.2-0.7,0.4c-0.2,0.3-0.1,1.2-0.1,1.4
        c0,0.2,0.9,0.9,0.9,0.9s0.3,0.7,0.3,1c0,0.2-0.1,0.6-0.2,0.7c-0.1,0.2-0.6,0.2-0.7,0.4c-0.1,0.3,0.5,0.9,0.6,1.2
        c0,0.2-0.1,0.7-0.2,0.9c-0.1,0.1-0.4,0.3-0.5,0.4c-0.2,0.3,0,1.1-0.3,1.4c-0.2,0.2-0.7,0.1-0.9,0.3c-0.3,0.2-0.7,0.9-0.9,1.2
        c-0.3,0.3-0.9,0.8-1.1,1.1c-0.3,0.4-0.6,1.3-0.7,1.8c-0.1,0.4,0.4,1.3,0.1,1.5c-0.2,0.2-0.8,0-1.1,0c-0.4,0-0.9-0.1-1.4-0.2
        c-0.5,0-1.7-0.1-2.4-0.1c-0.4,0-1.3,0.1-1.8,0.2c0-0.4-0.5-1.2-0.7-1.6c-0.2-0.4-0.6-1.3-0.8-1.7c-0.1-0.2-0.4-0.7-0.4-0.9
        c-0.1-0.3,0.1-0.9,0-1.2c-0.1-0.3-0.6-0.7-0.7-0.9c-0.2-0.4,0-1.4-0.2-1.8c-0.2-0.4-0.9-1-1.3-1.2c-0.1-0.1-0.3-0.2-0.5-0.2
        c-0.2-0.1-0.5-0.5-0.8-0.6c-0.2,0-0.6,0.1-0.7,0.1c-0.3-0.1-0.9-0.4-1.1-0.6c-0.2-0.2-0.4-0.7-0.6-0.9c-0.1-0.2-0.2-0.6-0.4-0.6
        c-0.2,0-0.4,0.4-0.5,0.4c-0.2,0-0.6-0.3-0.7-0.5c-0.1-0.2,0.2-0.6,0.2-0.8c0-0.3-0.5-0.9-0.6-1.2c-0.2-0.4-0.3-1.3-0.6-1.6
        c-0.2-0.4-1-0.8-1.2-1.2c-0.5-0.8-0.8-2.6-1.1-3.4c-0.2-0.8-0.5-2.4-0.5-3.2c0-0.5,0.4-1.5,0.4-2.1c0-0.2-0.2-0.5-0.1-0.7
        c0-0.2,0.2-0.6,0.3-0.8c0.2-0.5,1-1.2,1.3-1.7c0.2-0.3,0.2-1,0.4-1.3C133.1,198.8,133.1,198.7,133.2,198.6z"/>
    </g>
    <g id="Marion">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="marion-county" ref="marion-county" class="st10" d="M128.6,354h-0.9v-31.6h2.2v-5.1c0.1,0.1,0.3,0.1,0.4,0.2c0.3,0.1,1,0.6,1.3,0.5
        c0.2-0.1,0.4-0.7,0.7-0.7c0.3-0.1,0.9,0.1,1.1,0.3c0.2,0.2,0.2,0.7,0.4,0.9c0.2,0.2,0.9,0.5,1.2,0.5c0.2,0,0.4-0.5,0.6-0.5
        c0.2-0.1,0.6,0,0.9,0c0.2,0,0.7,0,0.8-0.2c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.2-0.6-0.2-0.7-0.4c-0.1-0.1,0-0.4,0-0.5
        c0.1-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.1-0.5,0.2-0.6c0.2-0.2,0.6-0.4,0.9-0.4c0.2,0,0.4,0.5,0.6,0.6c0.1,0,0.4,0,0.5-0.1
        c0.1-0.1,0-0.7,0.2-0.8c0.2-0.1,0.6-0.1,0.8,0c0.2,0.1,0.3,0.5,0.5,0.6c0.3,0.2,1,0.2,1.3,0.2c0.2,0,0.7,0.2,0.9,0
        c0.1-0.1,0.1-0.5,0.2-0.6c0.1-0.1,0.5,0,0.7,0c0.2-0.1,0.6-0.4,0.6-0.6c0.1-0.2-0.1-1-0.1-1h4.6v2.3h-0.7v1.1h1v2.2h1v2.7h1.1v0.8
        h1.3v5.3h1.7v1.9v15.4h-2.1v8.2H151v4.8h0.7v2.2h-0.7v1.2v3.7h-0.4h-9.5v-3.6h-1.8V361h-2.1v-1.7h-3.5c0,0,0.1-1,0-1.4
        c-0.1-0.6-1-1.4-1.2-2c-0.1-0.2,0.1-0.8,0-1c-0.2-0.2-0.8-0.1-1-0.3c-0.1-0.1-0.2-0.7-0.2-0.7H128.6z"/>
    </g>
    
    <g id="Calhoun">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="calhoun-county" ref="calhoun-county" class="st11" d="
        M111.2,443c-0.1-0.2-0.4-0.5-0.4-0.7c0-0.3,0.3-1,0.6-1.2c0.2-0.1,0.7-0.1,0.9-0.2c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2,0-0.7-0.1-0.8
        c-0.2-0.2-1.1,0-1.2-0.3c-0.2-0.3,0-1,0.2-1.3c0.1-0.2,0.5-0.4,0.6-0.6c0.1-0.2,0.1-0.6,0.2-0.7c0.1-0.2,0.7-0.3,0.7-0.6
        c0.1-0.2,0-0.8-0.2-0.9c-0.2-0.2-0.9,0-1.1-0.1c-0.1,0-0.3-0.2-0.4-0.3c-0.1-0.3,0.4-1,0.4-1.4c0-0.3-0.2-0.9-0.4-1.2
        c-0.2-0.2-0.8-0.3-0.9-0.6c-0.1-0.2,0.2-0.7,0.3-0.8c0.1-0.1,0.6,0,0.7-0.1c0.2-0.2,0.3-0.7,0.4-0.9c0-0.2,0-0.7-0.1-1l16.2-0.2
        v-2.5c0,0,1.4-0.2,1.8,0c0.3,0.1,0.7,0.6,0.9,0.7c0.1,0,0.4-0.1,0.6,0c0.1,0.1,0.1,0.4,0.2,0.5c0.2,0.2,0.9,0.6,1.2,0.5
        c0.3-0.1,0.4-1,0.7-1c0.1,0,0.1,0.3,0.1,0.5c0,0.3-0.2,1,0,1.2c0.2,0.3,1,0.5,1.3,0.3c0.3-0.2-0.1-1,0.1-1.3
        c0.1-0.1,0.4-0.1,0.5,0c0.3,0.1,0.4,1,0.7,1c0.2,0,0.4-0.6,0.6-0.7c0.2-0.1,0.8-0.1,1.1,0c0.2,0.1,0.4,0.8,0.4,0.8l0.9,0l10-0.1
        c0,0.4,0.2,1.1,0.2,1.4c0,0.4,0.1,1.2-0.1,1.5c-0.1,0.2-0.7,0.4-0.8,0.6c-0.1,0.2-0.3,0.7-0.3,0.9c0,0.3,0.5,0.9,0.6,1.2
        c0.1,0.3,0.1,1,0,1.3c0,0.3-0.3,0.9-0.4,1.2c0,0.2-0.1,0.6,0,0.8c0.2,0.2,0.9,0.2,1.1,0.5c0.2,0.3,0,1.1,0.1,1.5
        c0.1,0.4,0.5,1,0.6,1.3c0.1,0.4-0.2,1.2,0,1.5c0.1,0.2,0.7,0.4,0.9,0.6c0.2,0.2,0.6,0.7,0.6,1c0,0.2-0.1,0.7-0.1,0.9
        c-0.1,0.3-0.4,0.8-0.3,1.1c0.1,0.3,0.6,0.7,0.7,0.9c0.2,0.3,0.4,1,0.4,1.4c0,0.3-0.4,1-0.6,1.3c-0.1,0.2-0.7,0.5-0.7,0.8
        c0,0.3,0.1,0.5,0.2,0.8l-2.4,0v-1.4h-3.5c0,0,0.2,0.9,0,1.1c-0.1,0.2-0.5,0.2-0.7,0.4c-0.1,0.1-0.1,0.6-0.2,0.7
        c-0.2,0.2-0.7,0.2-1,0.2c-0.3,0-1-0.2-1.2-0.5c-0.1-0.2,0-0.7-0.1-0.9c-0.1-0.1-0.4-0.4-0.5-0.3c-0.2,0.1-0.2,0.6-0.3,0.8
        c-0.1,0.2-0.4,0.5-0.5,0.5c-0.2,0-0.5-0.2-0.6-0.3c-0.2-0.1-0.4-0.7-0.6-0.8c-0.2-0.1-0.7-0.2-0.9,0c-0.2,0.1-0.1,0.6-0.3,0.8
        c-0.1,0.2-0.5,0.4-0.7,0.4c-0.3,0.1-0.9,0.2-1.1,0.1c-0.1-0.1-0.4-0.4-0.4-0.4l-6.5-0.1l-15.2-0.3c0,0-0.3-0.3-0.4-0.4
        c-0.1-0.2-0.4-0.6-0.4-0.8c0-0.2,0.4-0.4,0.4-0.6c0-0.2,0-0.6-0.2-0.8c-0.2-0.3-0.9-0.4-1.1-0.7c-0.1-0.2-0.2-0.7-0.1-0.9
        c0.1-0.1,0.5-0.2,0.5-0.4c0.2-0.2,0.1-0.8,0.2-1.1c0-0.3,0.1-1,0.1-1.4c0-0.2,0-0.7-0.2-0.8c-0.2-0.1-0.8,0-0.9,0.2
        c-0.1,0.1,0.1,0.6,0,0.8c-0.2,0.2-0.8,0.1-1-0.1C111.5,443.8,111.4,443.3,111.2,443C111.3,443.1,111.2,443.1,111.2,443z"/>
    </g>
    <g id="Webster">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="webster-county" ref="webster-county" class="st10" d="M133.6,392.1l-5.5-0.1v-4.8h0.9v-0.9h-0.9c0,0-0.2-1-0.2-1.3c0-0.4-0.1-1.3,0-1.8
        c0-0.5,0.3-1.4,0.3-1.9c0-0.5-0.3-1.5-0.1-2c0-0.1,0.3-0.4,0.4-0.4c0.2-0.1,0.9,0.1,0.9,0.1V364l-0.7-0.2c0,0-0.3-0.9-0.1-1.2
        c0.1-0.2,0.8-0.3,0.9-0.5c0.2-0.2,0.1-0.9,0.3-1.1c0.1-0.1,0.6,0.1,0.7,0c0.2-0.2,0.1-0.8,0-1.1c-0.2-0.3-1-0.5-1.2-0.8
        c-0.2-0.3-0.1-1,0-1.3c0.1-0.3,0.5-0.8,0.6-1.2c0.1-0.4,0-1.2-0.2-1.5c-0.2-0.2-0.9-0.1-0.9-0.1v-1h2.8c0,0,0.1,0.6,0.2,0.7
        c0.2,0.2,0.8,0.1,1,0.3c0.1,0.2-0.1,0.7,0,1c0.2,0.6,1,1.4,1.2,2c0.1,0.3,0,1.4,0,1.4h3.5v1.7h2.1v1.4h1.8v3.6h9.5v11.4h-0.9v5.7
        h-0.4v3.6v4.8c0,0-0.7,0.1-0.9,0.2c-0.2,0.1-0.7,0.6-0.8,0.6c-0.1,0-1.3,0-1.7-0.1c-0.3,0-1-0.2-1.4-0.1c-0.3,0-0.8,0.2-1.1,0.2
        c-0.4,0-1.2-0.1-1.6-0.1c-0.6,0-2.5,0-2.5,0L133.6,392.1z"/>
    </g>
    <g id="Meriwether">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="meriwether-county" ref="meriwether-county" class="st10" d="M123.6,279.7l-6.2-2.9l-10.3-0.1l-0.3-43.4l14.7,0v-0.8h2.7v1.2c0,0,1.8-1,2.5-1.1
        c0.3-0.1,0.8-0.2,1.1-0.1c0.2,0,0.5,0.2,0.6,0.3c0.1,0.2,0.1,0.9,0.1,0.9h14.9c0.2,0.2,0.7,0.5,0.8,0.7c0.1,0.3,0,1.1,0,1.5
        c0,0.4-0.2,1.2,0,1.5c0.1,0.2,0.5,0.4,0.8,0.5c-0.1,0.2-0.2,0.5-0.3,0.5c-0.3,0.2-1.1,0.1-1.3,0.3c-0.4,0.4-0.5,1.5-0.6,2
        c-0.1,0.4,0.1,1.1,0,1.4c-0.1,0.4-0.7,0.9-0.7,1.3c0,0.3,0.5,0.8,0.6,1.1c0.1,0.2,0.1,0.7,0,0.9c-0.1,0.2-0.6,0.5-0.7,0.8
        c-0.1,0.4,0.5,1.1,0.3,1.4c-0.1,0.3-1,0.4-1.1,0.7c-0.1,0.2-0.1,0.7,0,0.8c0.2,0.3,1.1,0.3,1.2,0.6c0.2,0.3,0,1.2-0.2,1.4
        c-0.2,0.2-0.8-0.2-0.9,0c-0.2,0.2-0.1,0.8,0,1c0,0.4,0.3,1,0.3,1.4c0,0.4,0,1.2-0.2,1.4c-0.2,0.2-0.8,0.3-1,0.5
        c-0.2,0.2-0.5,0.9-0.5,1.2c0,0.4,0.9,1.1,0.8,1.5c0,0.3-0.8,0.8-0.7,1.1c0.1,0.4,1.4,0.6,1.4,1c0,0.3-0.7,0.6-0.7,0.9
        c-0.1,0.3,0.1,1.1,0.2,1.4c0.2,0.3,1,0.6,1,0.9c0,0.3-1,0.4-1.2,0.7c0,0,0,0.1-0.1,0.2c0,0.2,0,0.5,0.1,0.6
        c0.1,0.3,0.8,0.5,0.9,0.7c0.2,0.2,0.4,0.8,0.4,1.1c0,0.4-0.2,1.1-0.3,1.5c-0.1,0.2-0.4,0.6-0.5,0.8c-0.1,0.2-0.2,0.6-0.1,0.8
        c0,0.3,0.3,1,0.6,1.2c0.3,0.2,1-0.2,1.3,0c0.3,0.2,0.3,1,0.3,1.3c0,0.4-0.2,1.5-0.2,1.7c0,0.1,0.7,0.9,0.7,0.9l-7.2,4.5l-12.5,0.1
        L123.6,279.7z"/>
    </g>
    <g id="Coweta">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="coweta-county" ref="coweta-county" class="st10" d="M106.8,233.3l-7.9,0l-7.4-24.5c0,0,0.8-0.7,1.1-0.9c0.3-0.2,1.1-0.3,1.4-0.5
        c0.2-0.2,0.4-0.8,0.4-1.1c0-0.3-0.7-0.8-0.6-1.1c0.2-0.4,1.3-0.3,1.8-0.2c0.4,0.1,1.1,0.8,1.4,1.1c0.3,0.3,0.5,1.2,0.9,1.4
        c0.3,0.2,1.2,0.1,1.5,0c0.2-0.1,0.6-0.5,0.8-0.7c0.2-0.1,0.8-0.3,0.9-0.5c0.2-0.3-0.1-1.1,0-1.4c0.1-0.2,0.7-0.4,0.8-0.6
        c0.2-0.3,0.2-1.3,0.4-1.6c0.3-0.4,1.3-0.6,1.7-0.8c0.3-0.2,0.8-0.9,1.2-1.1c0.4-0.2,1.5,0.2,1.9,0c0.3-0.1,0.7-0.6,0.8-0.9
        c0.1-0.3-0.1-1.2,0-1.5c0.1-0.2,0.6-0.5,0.8-0.5c0.5-0.1,1.8,0.4,1.8,0.4l0.1,0l22.5,0.2c-0.1,0.1-0.1,0.3-0.2,0.4
        c-0.1,0.3-0.2,1-0.4,1.3c-0.2,0.5-1,1.2-1.3,1.7c-0.1,0.2-0.3,0.6-0.3,0.8c0,0.2,0.1,0.5,0.1,0.7c0,0.5-0.3,1.5-0.4,2.1
        c0,0.8,0.3,2.4,0.5,3.2c0.2,0.9,0.6,2.7,1.1,3.4c0.2,0.4,1,0.8,1.2,1.2c0.2,0.4,0.4,1.2,0.6,1.6c0.1,0.3,0.6,0.9,0.6,1.2
        c0,0.2-0.2,0.6-0.2,0.8c0.1,0.2,0.5,0.5,0.7,0.5c0.2,0,0.4-0.4,0.5-0.4c0.2,0,0.3,0.5,0.4,0.6c0.1,0.2,0.4,0.7,0.6,0.9
        c0.2,0.2,0.8,0.5,1.1,0.6c0.2,0,0.6-0.1,0.7-0.1c0.2,0.1,0.6,0.4,0.8,0.6c0.1,0.1,0.4,0.2,0.5,0.2c0.4,0.3,1.1,0.8,1.3,1.2
        c0.2,0.4,0.1,1.4,0.2,1.8c0.1,0.3,0.6,0.7,0.7,0.9c0.1,0.3-0.1,0.9,0,1.2c0.1,0.3,0.3,0.7,0.4,0.9c0.2,0.4,0.6,1.3,0.8,1.7
        c0.2,0.4,0.7,1.1,0.7,1.6c0,0,0,0,0,0c0,0.2-0.2,0.6-0.4,0.8c-0.1,0.1-0.6,0-0.7,0.1c-0.2,0.2-0.2,0.8-0.1,1.1
        c0.1,0.2,0.6,0.4,0.7,0.7c0.1,0.2-0.1,0.6-0.1,0.7c0,0.2-0.3,0.5-0.2,0.7c0,0.1,0.1,0.1,0.1,0.2h-14.9c0,0,0-0.7-0.1-0.9
        c-0.1-0.1-0.4-0.3-0.6-0.3c-0.3-0.1-0.8,0-1.1,0.1c-0.7,0.2-2.5,1.1-2.5,1.1v-1.2h-2.7v0.8L106.8,233.3z"/>
    </g>
    
    <g id="Randolph">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="randolph-county" ref="randolph-county" class="st11" d="
        M111.6,428.8l-12.7,0.2v-4.8h1.3c0,0,0.2-1.3,0-1.7c-0.1-0.2-0.5-0.6-0.7-0.8c-0.3-0.2-1.2-0.2-1.2-0.2v-4.1h-0.9v-7.7l4.7,0.1
        l0.3-18l25.7,0.3l5.5,0.1l-0.3,18.1h4c0,0-0.1,0.7-0.2,0.9c-0.1,0.2-0.5,0.3-0.5,0.5c-0.1,0.3,0.2,1,0.3,1.3
        c0.1,0.2,0.4,0.6,0.5,0.8c0.1,0.3-0.1,0.9-0.1,1.1c0.1,0.3,0.5,0.8,0.7,1c0.2,0.3,0.7,0.7,0.8,1c0.1,0.4-0.1,1.2,0.1,1.5
        c0.1,0.3,0.5,0.8,0.6,1.1c0.1,0.3,0.3,1.2,0.3,1.2c0,0.1,0.5,0.7,0.5,1c0,0.3-0.6,0.7-0.7,0.9c-0.2,0.4-0.3,1.3-0.2,1.7
        c0.1,0.3,0.5,0.9,0.6,1.3c0,0.4-0.3,1.3-0.5,1.7c-0.1,0.3-0.5,0.8-0.6,1.1c0,0,0,0.1,0,0.1l-0.9,0c0,0-0.2-0.7-0.4-0.8
        c-0.2-0.1-0.8-0.1-1.1,0c-0.2,0.1-0.3,0.7-0.6,0.7c-0.3,0-0.4-0.9-0.7-1c-0.1-0.1-0.4-0.1-0.5,0c-0.2,0.2,0.2,1.1-0.1,1.3
        c-0.3,0.2-1.1-0.1-1.3-0.3c-0.2-0.2,0-0.9,0-1.2c0-0.1,0-0.4-0.1-0.5c-0.3-0.1-0.4,0.9-0.7,1c-0.3,0.1-0.9-0.3-1.2-0.5
        c-0.1-0.1-0.1-0.4-0.2-0.5c-0.1-0.1-0.4,0-0.6,0c-0.3-0.1-0.7-0.6-0.9-0.7c-0.4-0.1-1.8,0-1.8,0v2.5L111.6,428.8z"/>
    </g>
    <g id="Miller">
    <polygon @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="miller-county" ref="miller-county" class="st10" points="120.7,495.5 100.8,495.2 101.5,472.9 129.4,472.9 139.9,472.8 139.4,494.4 129,494.4 
        129,495.7 		"/>
    </g>
    
    <g id="Douglas">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="douglas-county" ref="douglas-county" class="st11" d="
        M130.3,162.4l5.7,7.8c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.8-0.4,1c-0.3,0.3-1.4,0.5-1.7,0.8c-0.3,0.3-0.4,1.4-0.7,1.7
        c-0.3,0.3-1,0.6-1.3,0.7c-0.3,0.1-1.1,0.1-1.3,0.4c-0.2,0.3,0.2,1.2,0,1.5c-0.2,0.3-0.8,0.5-1.1,0.6c-0.2,0.1-0.8,0-1,0.1
        c-0.2,0.2-0.4,0.7-0.4,1c0,0.3,0.1,0.8,0,1.1c-0.1,0.4-0.7,1-1.1,1.2c-0.3,0.2-1,0.3-1.4,0.4c-0.4,0.1-1.2,0.3-1.6,0.4
        c-0.4,0.1-1.1,0.1-1.5,0.2c-0.5,0.2-1.3,0.7-1.6,1.1c-0.1,0.2-0.1,0.8-0.2,1c-0.2,0.3-0.6,1-1,1c-0.3,0-0.6-0.9-1-1
        c-0.2-0.1-0.7,0.1-0.8,0.2c-0.3,0.2-0.4,1-0.6,1.2c-0.2,0.2-0.8,0.3-1,0.4c-0.2,0.2-0.5,0.7-0.7,1c-0.4,0.4-1.4,1-1.8,1.4
        c-0.4,0.4-1.1,1.2-1.3,1.6c-0.1,0.2-0.2,0.7-0.2,1.1l-9.5,0.1l0.1-25.1l2,0.6l16.4-0.9l0-3L130.3,162.4z"/>
    </g>
    <g id="Murray">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="murray-county" ref="murray-county" class="st10" d="M131.1,34.9l4,3.5l-7.3,11.5l0.1,17.7c-0.4-0.1-0.9,0-1.1-0.1c-0.2-0.1-0.5-0.7-0.8-0.8
        c-0.3-0.2-1.1-0.1-1.5-0.2c-0.4-0.1-1.2,0-1.6-0.2c-0.1-0.1-0.3-0.4-0.4-0.5c-0.4-0.2-1.3,0-1.7-0.1c-0.2-0.1-0.7-0.3-0.7-0.5
        c0-0.2,0.7-0.4,0.8-0.6c0.1-0.3-0.2-0.9-0.4-1.1c-0.2-0.1-0.6-0.1-0.8-0.1c-0.3,0-1-0.1-1.1,0.2c-0.1,0.2,0.3,0.5,0.3,0.7
        c-0.1,0.3-0.7,0.5-0.9,0.5c-0.3,0-0.9-0.5-1.2-0.6c-0.2,0-0.7-0.1-0.9,0c-0.3,0.1-0.9,0.8-0.9,0.8h-8.4v-1h-4.3v-1.5h-0.5
        c0-0.3,0.1-0.8,0.1-1c0-0.2,0-0.6-0.1-0.7c-0.2-0.2-0.9-0.2-1.1-0.5c-0.2-0.2-0.3-0.6-0.3-0.8c0-0.3,0.1-0.8,0.1-1
        c0-0.2-0.2-0.7-0.4-0.8c-0.1-0.1-0.5-0.1-0.7-0.1c-0.2-0.1-0.6-0.2-0.7-0.3c-0.1-0.2-0.3-0.8-0.1-0.9c0.2-0.2,0.7,0.1,0.9,0.2
        c0.3,0.1,0.8,0.2,1.1,0.2c0.2,0,0.6-0.5,0.8-0.4c0.3,0,0.7,0.6,0.9,0.8c0.2,0.2,0.3,0.9,0.5,1.1c0.1,0.1,0.4,0.1,0.5,0.1
        c0.3,0,0.9-0.1,1-0.3c0.1-0.1,0-0.6,0-0.7c0-0.3,0.1-1-0.2-1.1c-0.2-0.1-0.5,0.3-0.6,0.3c-0.4,0-0.9-0.6-1.2-0.8
        c-0.1-0.2-0.2-0.7-0.3-0.8c-0.1-0.2-0.5-0.4-0.7-0.5c-0.3-0.2-1.1-0.2-1.4-0.4c-0.2-0.2-0.4-0.8-0.3-1c0-0.2,0.4-0.7,0.6-0.7
        c0.3-0.1,0.9,0.2,1.2,0.3c0.3,0.1,0.9,0.5,1.2,0.6c0.3,0.2,1,0.7,1.4,0.6c0.3-0.1,0.5-1,0.9-1.1c0.1-0.1,0.4,0.1,0.6,0.1
        c0.2,0.1,0.7,0.3,0.9,0.2c0.2-0.1,0.5-0.4,0.7-0.6c0.1-0.2,0.4-0.8,0.3-1c0-0.1-0.2-0.2-0.3-0.2c-0.3-0.2-0.9-0.6-1.1-0.9
        c0-0.1,0-0.5,0.1-0.6c0.1-0.1,0.4,0.2,0.6,0.2c0.3-0.1,0.6-0.5,0.7-0.8c0.1-0.3,0.1-0.9,0-1.2c-0.1-0.2-0.6-0.2-0.7-0.3
        c-0.2-0.2-0.5-0.7-0.6-0.9c-0.1-0.3-0.2-0.9-0.2-1.3c0-0.3,0-0.8,0.1-1c0.2-0.2,0.8,0.1,0.9-0.1c0.1-0.2-0.3-0.6-0.3-0.8
        c0-0.3,0.1-1,0.4-1.2c0.2-0.1,0.8,0.2,1.1,0.2c0.2,0,0.7-0.1,0.8-0.3c0.1-0.2,0-0.7-0.2-0.9c-0.1-0.1-0.5-0.2-0.6-0.2
        c-0.2,0-0.7,0.1-0.8,0c-0.2-0.2-0.3-0.8-0.2-1c0.1-0.3,0.5-0.9,0.7-1.2c0.1-0.3,0.4-0.8,0.4-1.1c0-0.3-0.1-0.9,0-1.1
        c0.1-0.3,0.5-0.9,0.7-1.1c0.1-0.1,0.4-0.1,0.4-0.2c0.1-0.2-0.3-0.6-0.1-0.8c0.1-0.2,0.6,0,0.8,0c0.2,0,0.6,0.1,0.8,0
        c0.2-0.1,0.3-0.6,0.4-0.8c0.1-0.2,0.1-0.8,0-1c-0.1-0.2-0.5-0.5-0.7-0.7c-0.2-0.3-0.4-0.8-0.4-1.1c0-0.2,0-0.7,0.1-0.8
        c0.1-0.1,0.4,0,0.5-0.1c0.1-0.1,0.1-0.5,0-0.7c-0.1-0.3-0.8-0.4-0.9-0.6c-0.2-0.2-0.5-0.8-0.6-1.1c-0.1-0.3-0.1-1-0.1-1.3
        c0.1-0.4,0.4-1.3,0.6-1.6c0.2-0.4,1-1,1.2-1.4c0.3-0.5,0.6-1.8,0.7-2.4c0.1-0.4,0.1-1.2,0.1-1.7l19-0.2v16.2L131.1,34.9z"/>
    </g>
    
    <g id="Stewart">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="stewart-county" ref="stewart-county" class="st11" d="
        M102.4,391.7l-1,0c0,0,0.2-1,0-1.2c-0.2-0.3-1.1,0-1.4-0.2c-0.2-0.1-0.3-0.6-0.5-0.7c-0.1-0.1-0.4,0.1-0.6,0.1
        c-0.3,0-1-0.2-1.3-0.5c-0.1-0.1-0.1-0.6-0.3-0.8c-0.1-0.1-0.4,0-0.5-0.1c-0.2-0.2-0.1-0.9,0-1.2c0.1-0.2,0.2-0.7,0.4-0.8
        c0.2-0.1,0.7,0.3,0.8,0.2c0.1-0.1,0.1-0.6,0-0.7c-0.2-0.3-0.8-0.7-1.2-0.8c-0.4-0.1-1.2,0-1.6,0c-0.3,0.1-0.9,0.6-1.2,0.5
        c-0.9-0.1-0.9-0.8-1.2-1.1c-0.2-0.2-1.1-0.2-1.1-0.2l0,0.9h-3v-0.8c0,0-1.1-0.1-1.5-0.2c-0.4,0-1.2-0.1-1.7-0.1
        c-0.1-0.2-0.2-0.4-0.2-0.5c-0.1-0.3-0.1-1,0-1.3c0.1-0.3,0.7-0.8,0.9-1.1c0.2-0.3,0.4-0.9,0.5-1.3c0-0.3-0.1-1-0.1-1.4
        c0-0.5-0.1-1.6,0-2.1c0.1-0.3,0.5-0.9,0.6-1.2c0.1-0.4-0.2-1.1-0.2-1.5c-0.1-0.3-0.5-0.9-0.4-1.3c0.1-0.4,1.1-0.5,1.3-0.8
        c0.2-0.4-0.4-1.3-0.5-1.8c-0.2-0.5-0.6-1.6-0.7-2.2c-0.1-0.5-0.3-1.5-0.1-1.9c0.2-0.5,1.3-1,1.6-1.3c0.3-0.3,0.8-1,1.1-1.4
        c0.5-0.6,1.2-2,1.7-2.5c0.3-0.3,0.8-0.8,1.2-0.9c0.5-0.2,1.7-0.1,2.3-0.3c0.4-0.2,1.3-0.6,1.6-1c0.1-0.1,0.1-0.5,0.1-0.6
        c-0.2-0.4-1.4-0.4-1.5-0.8c-0.1-0.3,0.6-0.8,0.9-1c0.3-0.2,0.9-0.5,1.2-0.5c0.6-0.1,1.8,0.4,2.4,0.3c0.3,0,0.8-0.2,1-0.4
        c0.1-0.2,0.1-0.7,0.2-0.9c0-0.1,0.1-0.2,0.2-0.3h11.7v0.9h1.4V354h14.2h0.9v1c0,0,0.8,0,0.9,0.1c0.3,0.3,0.3,1.1,0.2,1.5
        c-0.1,0.3-0.5,0.8-0.6,1.2c-0.1,0.3-0.1,1,0,1.3c0.2,0.3,1.1,0.5,1.2,0.8c0.1,0.2,0.2,0.9,0,1.1c-0.1,0.1-0.6-0.1-0.7,0
        c-0.2,0.2-0.1,0.8-0.3,1.1c-0.2,0.2-0.8,0.3-0.9,0.5c-0.1,0.3,0.1,1.2,0.1,1.2l0.7,0.2v14.9c0,0-0.7-0.2-0.9-0.1
        c-0.1,0-0.3,0.3-0.4,0.4c-0.2,0.5,0.2,1.5,0.1,2c0,0.5-0.3,1.4-0.3,1.9c0,0.4,0,1.3,0,1.8c0,0.3,0.2,1.3,0.2,1.3h0.9v0.9h-0.9v4.8
        L102.4,391.7z"/>
    </g>
    
    <g id="Early">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="early-county" ref="early-county" class="st11" d="
        M129.9,451.3l-0.6,21.6l-27.9,0.1l-0.7,22.3l-10.7-0.2c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.6,0.3-1.9,0-2.4c-0.3-0.8-2.1-1.8-2.6-2.5
        c-0.3-0.4-0.6-1.3-0.8-1.7c-0.2-0.4-0.6-1.3-0.8-1.7c-0.2-0.4-0.8-1.1-1.2-1.3c-0.3-0.1-0.9,0.1-1.1-0.1c-0.2-0.1-0.3-0.7-0.3-0.9
        c-0.1-0.4,0-1.1-0.1-1.5c-0.2-0.6-1-1.6-1.1-2.2c0-0.3,0.3-0.9,0.4-1.2c0.1-0.5,0.5-1.4,0.6-1.9c0.1-0.4,0.1-1.2,0-1.6
        c-0.2-0.7-1.1-2-1.3-2.7c-0.2-0.5-0.5-1.5-0.4-1.9c0.1-0.3,0.5-0.9,0.8-1.1c0.2-0.2,0.9-0.3,1.1-0.6c0.3-0.3,0.7-1.1,0.8-1.5
        c0.2-0.6,0.3-1.9,0.3-2.5c0-0.4-0.5-1.2-0.4-1.6c0-0.2,0.3-0.7,0.3-0.9c0.1-0.4,0.1-1.2-0.1-1.6c-0.1-0.3-0.8-0.7-0.8-1
        c0-0.3,0.6-0.6,0.8-0.9c0.3-0.5,0.4-1.5,0.6-2c0.1-0.3,0.5-0.9,0.5-1.2c0-0.5-0.5-1.4-0.5-1.9c0-0.4,0.1-1.2,0.3-1.5
        c0.2-0.4,1.1-0.9,1.2-1.3c0.2-0.5-0.4-1.7-0.4-2.2c-0.1-0.6-0.1-1.8,0-2.5c0-0.4,0.2-1.1,0.3-1.4c0.2-0.4,0.8-1,1-1.4
        c0.2-0.4,0.6-1.4,0.8-1.8c0-0.1,0-0.2,0.1-0.4c0.3-0.1,0.6-0.1,0.8-0.1c0.3,0,1.1,0.1,1.3,0.4c0.2,0.2,0.3,1,0.3,1.2
        c0,0.1-0.3,0.8-0.2,1.1c0,0.3,0.3,0.9,0.6,1.1c0.3,0.2,1.2,0.2,1.6,0.2c0.2,0,0.7-0.3,0.9-0.3c0.5-0.1,1.4,0.2,1.8,0
        c0.3-0.1,0.5-0.8,0.8-0.9c0.3-0.1,0.9,0.3,1.2,0.3c0.3,0,0.8-0.3,1-0.4c0.3-0.1,1-0.7,1-0.7h12.4c0,0,0,0,0,0.1
        c0.1,0.2,0.2,0.7,0.4,0.8c0.2,0.1,0.8,0.2,1,0.1c0.1-0.1-0.1-0.6,0-0.8c0.2-0.2,0.7-0.3,0.9-0.2c0.2,0.1,0.1,0.6,0.2,0.8
        c0,0.3-0.1,1-0.1,1.4c0,0.3,0,0.9-0.2,1.1c-0.1,0.1-0.5,0.2-0.5,0.4c-0.1,0.2-0.1,0.7,0.1,0.9c0.2,0.3,0.9,0.4,1.1,0.7
        c0.1,0.2,0.2,0.6,0.2,0.8c0,0.2-0.4,0.4-0.4,0.6c0,0.2,0.3,0.6,0.4,0.8c0.1,0.1,0.4,0.4,0.4,0.4L129.9,451.3z"/>
    </g>
    <g id="Chattahoochee">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="chattahoochee-county" ref="chattahoochee-county" class="st10" d="M129.9,317.3v5.1h-2.2V354h-14.2v1.2h-1.4v-0.9h-11.7c0.2-0.3,0.5-0.6,0.5-0.8
        c0-0.3-0.6-0.9-0.5-1.2c0-0.2,0.4-0.6,0.6-0.7c0.3-0.2,1.1,0,1.5-0.2c0.4-0.2,1.1-0.9,1-1.4c-0.1-0.7-1.7-1.1-2.2-1.6
        c-0.4-0.4-0.9-1.3-1.2-1.6c-0.5-0.5-1.6-1.2-2.2-1.5c-0.5-0.3-1.7-0.7-2.3-1c-0.6-0.2-1.9-0.6-2.5-0.9c-0.4-0.3-1-1-1.2-1.5
        c-0.1-0.3-0.1-0.9,0-1.2c0.1-0.3,0.4-0.8,0.7-1.1c0.2-0.2,0.9-0.4,1.2-0.6c0.2-0.3,0.2-1,0.4-1.2c0.1-0.2,0.6-0.4,0.7-0.6
        c0.1-0.2,0.5-0.5,0.5-0.8c0.3,0,0.7,0,0.8,0.1c0.2,0,0.5,0.2,0.7,0.2c0.3,0.1,0.9,0.2,1.2,0.1c0.2,0,0.5-0.4,0.7-0.5
        c0.2-0.1,0.8-0.1,1-0.2c0.3-0.2,0.6-1,0.9-1.3c0.4-0.3,1.5-0.5,2-0.7c0.4-0.2,1.1-0.5,1.4-0.7c0.2-0.2,0.5-0.7,0.7-0.9
        c0.1-0.1,0.5,0,0.7,0c0.3-0.1,0.9-0.4,1.2-0.4c0.5-0.1,1.7-0.1,2.2-0.1c0.4,0,1.3,0.1,1.7,0.1c0.3,0,0.8-0.1,1.1-0.2
        c0.1-0.1,0.2-0.4,0.2-0.5c0.1-0.1,0.4-0.3,0.6-0.2c0.2,0,0.6,0.4,0.8,0.5c0.2,0.1,0.6,0.3,0.8,0.3c0.2,0,0.4-0.4,0.6-0.6
        c0.2-0.2,0.8-0.4,1.1-0.5c0.2-0.1,0.8,0,0.9-0.1c0.2-0.3-0.1-1.2,0-1.5c0.1-0.3,0.5-0.9,0.7-1.2c0.2-0.3,0.7-0.8,1-1
        c0.2-0.2,0.7-0.3,0.8-0.5c0.2-0.3-0.1-1.1,0-1.4c0.1-0.7,0.7-1.9,1-2.5c0.1-0.1,0.2-0.4,0.3-0.5c0.1-0.2,0.2-0.6,0.3-0.8
        c0.1-0.2,0.1-0.7,0.3-0.9c0.2-0.1,0.6,0,0.8-0.1c0.2-0.1,0.4-0.6,0.6-0.7c0.2-0.2,0.8-0.3,1.1-0.4c0.3,0,0.8,0.2,1,0.1
        c0.1,0,0.2-0.2,0.2-0.3c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.6-0.1,0.8-0.2c0.3-0.2,0.5-0.8,0.8-1c0.2-0.1,0.5-0.1,0.7-0.1
        c0.1,0,0.5,0.1,0.6,0c0.2-0.1,0.4-0.4,0.5-0.5c0.2-0.1,0.6-0.2,0.8-0.2C129.6,317.2,129.8,317.2,129.9,317.3z"/>
    </g>
    <g id="Gordon">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="gordon-county" ref="gordon-county" class="st10" d="M101.8,62.3h0.5v1.5h4.3v1h8.4c0,0,0.6-0.7,0.9-0.8c0.2-0.1,0.7,0,0.9,0
        c0.3,0.1,0.8,0.6,1.2,0.6c0.3,0,0.8-0.3,0.9-0.5c0.1-0.2-0.4-0.5-0.3-0.7c0.1-0.2,0.8-0.2,1.1-0.2c0.2,0,0.7,0,0.8,0.1
        c0.2,0.2,0.5,0.8,0.4,1.1c-0.1,0.2-0.8,0.4-0.8,0.6c0,0.2,0.5,0.4,0.7,0.5c0.4,0.1,1.3-0.1,1.7,0.1c0.1,0.1,0.3,0.4,0.4,0.5
        c0.3,0.2,1.2,0.2,1.6,0.2c0.4,0.1,1.2,0,1.5,0.2c0.3,0.1,0.5,0.7,0.8,0.8c0.2,0.1,0.7,0.1,1.1,0.1l0,4.8l0.1,16.3l0,0.1h-7.9v1.5
        H97.7v1.9h-1.9v-1.9h-1v1.6h-1v-2h-1.4v1.4h-2.3v-1.3h-7.8v-1.8l1.7-1.2l-1.9-4.3h2.2v-9h2.4v-3.8h-1.1v-2.5l1.5,0l0.1-3.7h13.1
        v-1.5H101.8z"/>
    </g>
    <g id="Bartow">
    <polygon @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="bartow-county" ref="bartow-county" class="st10" points="100.8,129.2 88.3,129.1 88.3,127.2 88.3,113.1 90.6,113.1 90.6,96.8 92.4,96.8 
        92.4,91.3 92.4,89.9 93.8,89.9 93.8,91.9 94.8,91.9 94.8,90.3 95.9,90.3 95.9,92.2 97.7,92.2 97.7,90.3 120.2,90.3 120.2,88.8 
        128.1,88.8 127.6,129.4 119.7,129.4 		"/>
    </g>
    
    <g id="Muscogee">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="muscogee-county" ref="muscogee-county" class="st11" d="
        M124.8,319c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.8-0.1-1-0.1c-0.3,0-0.8,0.2-1.1,0.4c-0.2,0.1-0.4,0.6-0.6,0.7
        c-0.2,0.1-0.6-0.1-0.8,0.1c-0.2,0.1-0.2,0.7-0.3,0.9c-0.1,0.2-0.2,0.6-0.3,0.8c-0.1,0.1-0.2,0.4-0.3,0.5c-0.3,0.6-0.9,1.9-1,2.5
        c-0.1,0.3,0.2,1.1,0,1.4c-0.1,0.2-0.6,0.4-0.8,0.5c-0.3,0.2-0.8,0.7-1,1c-0.2,0.3-0.6,0.9-0.7,1.2c-0.1,0.4,0.3,1.2,0,1.5
        c-0.1,0.2-0.7,0.1-0.9,0.1c-0.3,0.1-0.8,0.3-1.1,0.5c-0.2,0.1-0.4,0.5-0.6,0.6c-0.2,0-0.6-0.2-0.8-0.3c-0.2-0.1-0.5-0.5-0.8-0.5
        c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.1,0.4-0.2,0.5c-0.2,0.2-0.8,0.2-1.1,0.2c-0.4,0-1.3-0.1-1.7-0.1c-0.6,0-1.7,0-2.2,0.1
        c-0.3,0.1-0.9,0.4-1.2,0.4c-0.2,0-0.5,0-0.7,0c-0.3,0.1-0.5,0.7-0.7,0.9c-0.3,0.2-1,0.6-1.4,0.7c-0.5,0.2-1.6,0.4-2,0.7
        c-0.3,0.2-0.6,1-0.9,1.3c-0.2,0.1-0.8,0.1-1,0.2c-0.2,0.1-0.5,0.4-0.7,0.5c-0.3,0.1-0.9-0.1-1.2-0.1c-0.2,0-0.5-0.2-0.7-0.2
        c-0.2,0-0.5-0.1-0.8-0.1c0-0.1,0-0.1,0-0.1c-0.1-0.4-1.2-0.3-1.4-0.6c-0.1-0.2,0.1-0.6,0.2-0.8c0.2-0.3,1.3-0.3,1.4-0.7
        c0.1-0.4-0.7-0.9-0.8-1.2c0-0.3,0.2-0.7,0.3-1c0.2-0.3,0.9-0.8,1.1-1.2c0.1-0.2,0.3-0.8,0.2-1.1c-0.2-0.4-1.1-0.7-1.4-1
        c-0.4-0.4-1.1-1.3-1.4-1.8c-0.2-0.4-0.7-1.4-0.7-1.9c0-0.7,0.6-2,0.6-2.6c0-0.6-0.3-1.9-0.5-2.5c-0.3-0.6-1.3-1.5-1.7-2
        c-0.5-0.6-1.3-2.1-1.8-2.6c-0.3-0.3-1.1-0.8-1.4-1.1c-0.3-0.3-0.8-1-1-1.4c-0.4-0.5-1.2-1.4-1.5-2c-0.2-0.4-0.6-1.3-0.6-1.8
        c0-0.1,0-0.3,0-0.5l17.6,0.1l0.1,2.8l21.4,0.1v2.3c0,0,0.6,0.3,0.9,0.3c0.3,0,0.7-0.4,1-0.4c0.3,0.1,0.7,0.6,0.7,0.9
        c0,0.2-0.2,0.6-0.2,0.8c-0.1,0.2-0.5,0.5-0.6,0.8c-0.1,0.3,0.4,1,0.4,1.3c-0.1,0.3-0.6,0.6-0.8,0.9
        C125,318.4,124.9,318.7,124.8,319z"/>
    </g>
    <g id="Harris">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="harris-county" ref="harris-county" class="st10" d="M107.1,276.8l10.3,0.1l6.2,2.9c0.2,0.4,0.4,0.7,0.4,1c0.1,0.5,0.3,1.5,0.3,2
        c0,0.5-0.2,1.4-0.1,1.9c0,0.6,0.6,1.8,0.5,2.5c-0.1,0.3-0.6,0.6-0.6,0.9c-0.2,0.5,0,1.7,0.1,2.2c0.1,0.5,0.2,1.7,0.3,2.2
        c0.1,0.4,0.8,1,0.6,1.4c-0.1,0.2-0.6,0.4-0.7,0.6c-0.2,0.4,0.2,1.2,0,1.6c-0.1,0.2-0.5,0.4-0.6,0.6c-0.2,0.3-0.5,1-0.5,1.3
        c-0.1,0.3,0.1,1.4,0.1,1.4h0.9v4.1h-0.4v8l-21.4-0.1l-0.1-2.8l-17.6-0.1c0-0.3,0.1-0.7,0-0.9c-0.1-0.3-0.7-0.8-0.8-1.1
        c-0.1-0.3,0.2-1.1,0-1.4c-0.2-0.4-1.3-0.5-1.5-0.9c-0.1-0.3,0-0.9,0.2-1.1c0.1-0.2,0.7-0.5,0.8-0.7c0.1-0.3,0.1-1.1-0.1-1.4
        c-0.2-0.6-1.3-1.5-1.6-2.1c-0.3-0.5-0.7-1.5-0.8-2c-0.1-0.4-0.1-1.2-0.1-1.6c0.1-0.4,0.5-1.2,0.6-1.6c0-0.3,0-1.1-0.2-1.3
        c-0.2-0.2-0.9-0.2-1.2-0.3c-0.5-0.2-1.4-0.8-1.6-1.2c-0.2-0.4-0.2-1.2-0.1-1.6c0.1-0.3,0.9-0.6,1.1-0.8c0.2-0.2,0.5-0.6,0.5-0.8
        c0-0.2-0.5-0.4-0.6-0.5c-0.2-0.3-0.5-1-0.7-1.2c-0.3-0.2-0.9-0.3-1.2-0.5c-0.3-0.2-1-0.5-1.2-0.8c-0.3-0.3-0.8-1.2-0.8-1.7
        c-0.1-0.4,0.2-1.2,0.4-1.6c0.1-0.3,0.3-0.9,0.4-1.1c0.1-0.2,0.7-0.5,0.7-0.7c0-0.3-0.5-0.9-0.8-1.1c-0.5-0.2-2.1-0.1-2.1-0.1
        l-0.3-1.8L107.1,276.8z"/>
    </g>
    <g id="Paulding">
    <polygon @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="paulding-county" ref="paulding-county" class="st10" points="102.8,165.9 89.3,161.9 89.2,161.9 89.2,150.4 87.8,150.4 88,144.9 95,144.9 
        100.8,129.2 119.7,129.4 119.5,145.2 121,145.2 121.1,162.5 121.2,165.5 104.8,166.5 		"/>
    </g>

    <g id="Clay">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="clay-county" ref="clay-county" class="st11" d="
        M112.7,435.2c0.2,0.2,0.3,0.7,0.2,0.9c-0.1,0.2-0.6,0.4-0.7,0.6c-0.1,0.2-0.1,0.6-0.2,0.7c-0.1,0.2-0.5,0.4-0.6,0.6
        c-0.1,0.3-0.4,1-0.2,1.3c0.2,0.3,1,0.1,1.2,0.3c0.1,0.2,0.1,0.6,0.1,0.8c0,0.1,0,0.4-0.1,0.5c-0.2,0.2-0.7,0.1-0.9,0.2
        c-0.3,0.2-0.6,0.9-0.6,1.2c0,0.2,0.3,0.5,0.4,0.7H98.8c0,0-0.7,0.5-1,0.7c-0.2,0.1-0.8,0.4-1,0.4c-0.3,0-0.9-0.4-1.2-0.3
        c-0.3,0.1-0.5,0.8-0.8,0.9c-0.4,0.2-1.4-0.1-1.8,0c-0.2,0-0.7,0.3-0.9,0.3c-0.4,0-1.3,0-1.6-0.2c-0.2-0.2-0.5-0.8-0.6-1.1
        c0-0.3,0.2-1,0.2-1.1c0-0.1-0.1-1-0.3-1.2c-0.2-0.2-1-0.4-1.3-0.4c-0.2,0-0.5,0-0.8,0.1c0-0.5,0-1.1,0.1-1.5
        c0.1-0.3,0.5-0.9,0.5-1.2c0-0.5-0.4-1.4-0.6-1.9c-0.2-0.6-0.9-1.9-1-2.5c-0.1-0.6-0.1-1.8-0.1-2.4c0-0.6-0.2-1.8-0.3-2.4
        c0-0.3,0-0.9-0.1-1.1c-0.2-0.2-0.8-0.3-1-0.4c-0.2-0.1-0.4-0.6-0.6-0.7c-0.1-0.1-0.4,0-0.6-0.1c-0.2-0.1-0.5-0.6-0.4-0.8
        c0-0.2,0.4-0.3,0.5-0.5c0.1-0.2,0.3-0.7,0.3-1c-0.1-0.3-0.6-0.9-0.8-1.1c-0.5-0.5-1.7-1.2-2.1-1.8c-0.4-0.5-0.9-1.9-1.2-2.5
        c-0.2-0.4-0.5-1.2-0.5-1.6c0-0.4,0.3-1,0.4-1.4c0.1-0.3,0.4-0.8,0.4-1.1c0-0.4-0.1-1.1-0.3-1.4c-0.1-0.3-0.5-0.8-0.5-1.1
        c-0.1-0.4,0-1.3-0.1-1.8c-0.1-0.3-0.5-1-0.7-1.3c-0.1-0.1-0.2-0.3-0.4-0.5l6.9,0.1l0,1l3.5-0.1l0.6-0.8l7.6,0.2v7.7h0.9v4.1
        c0,0,1,0.1,1.2,0.2c0.2,0.1,0.6,0.5,0.7,0.8c0.2,0.4,0,1.7,0,1.7h-1.3v4.8l12.7-0.2c0,0.3,0.1,0.8,0.1,1c0,0.2-0.2,0.8-0.4,0.9
        c-0.1,0.1-0.6,0-0.7,0.1c-0.2,0.1-0.4,0.6-0.3,0.8c0.1,0.3,0.7,0.4,0.9,0.6c0.2,0.2,0.3,0.9,0.4,1.2c0,0.3-0.5,1-0.4,1.4
        c0,0.1,0.2,0.2,0.4,0.3C111.8,435.2,112.4,435,112.7,435.2z"/>
    </g>
    <g id="Carroll">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="carroll-county" ref="carroll-county" class="st10" d="M89.2,161.9L89.2,161.9l13.6,4l-0.1,25.1l9.5-0.1c0,0.2-0.1,0.3-0.2,0.4
        c-0.2,0.3-0.9,0.4-1.1,0.7c-0.2,0.2-0.2,1.1-0.2,1.1c0,0.1-0.7,0.7-0.8,1c0,0.2,0.4,0.6,0.5,0.8c0.2,0.2,0.8,0.6,0.9,0.9
        c0.2,0.3,0.4,0.9,0.3,1.3c-0.1,0.3-0.5,0.8-0.8,0.9c-0.1,0-0.1,0.2-0.2,0.3l-0.1,0c0,0-1.4-0.4-1.8-0.4c-0.2,0-0.7,0.3-0.8,0.5
        c-0.2,0.3,0.1,1.1,0,1.5c-0.1,0.3-0.5,0.8-0.8,0.9c-0.4,0.2-1.5-0.2-1.9,0c-0.4,0.1-0.8,0.8-1.2,1.1c-0.4,0.3-1.4,0.5-1.7,0.8
        c-0.3,0.3-0.2,1.3-0.4,1.6c-0.1,0.2-0.7,0.4-0.8,0.6c-0.2,0.3,0.1,1.1,0,1.4c-0.1,0.2-0.7,0.3-0.9,0.5c-0.2,0.1-0.6,0.6-0.8,0.7
        c-0.4,0.1-1.2,0.2-1.5,0c-0.4-0.2-0.6-1.1-0.9-1.4c-0.3-0.3-0.9-1-1.4-1.1c-0.4-0.1-1.6-0.2-1.8,0.2c-0.1,0.3,0.5,0.8,0.6,1.1
        c0,0.3-0.2,0.9-0.4,1.1c-0.3,0.3-1.1,0.4-1.4,0.5c-0.3,0.2-1.1,0.9-1.1,0.9l-28.9-0.3l-4.5-27.7l5.5,0.4l15.7-6.1l1.4,1.6l6.9-2.2
        v-12.8H89.2z"/>
    </g>
    <g id="Whitfield">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="whitfield-county" ref="whitfield-county" class="st10" d="M78.5,44.8l0.1-0.2l0.9-5.3h0.9v0.8h1.8v-0.8h4.5v-5.4h3.9v-4.5h1.2c0,0-0.1-1.5-0.1-1.9
        c0.1-0.4,0.3-1.1,0.5-1.4c0.2-0.4,0.8-0.9,1-1.3c0.2-0.4,0.2-1.3,0.2-1.7c0-0.3-0.5-0.9-0.5-1.3c0-0.3,0.4-1,0.4-1H95v-1.9
        l16.8-0.1c0,0.6-0.1,1.3-0.1,1.7c-0.1,0.6-0.4,1.8-0.7,2.4c-0.2,0.4-1,1-1.2,1.4c-0.2,0.4-0.5,1.2-0.6,1.6c0,0.3,0,1,0.1,1.3
        c0.1,0.3,0.4,0.9,0.6,1.1c0.2,0.2,0.8,0.4,0.9,0.6c0.1,0.2,0.1,0.6,0,0.7c-0.1,0.1-0.4,0.1-0.5,0.1c-0.1,0.2-0.2,0.6-0.1,0.8
        c0,0.3,0.3,0.9,0.4,1.1c0.1,0.2,0.6,0.5,0.7,0.7c0.1,0.2,0,0.8,0,1c-0.1,0.2-0.2,0.7-0.4,0.8c-0.2,0.1-0.6,0-0.8,0
        c-0.2,0-0.7-0.1-0.8,0c-0.1,0.2,0.2,0.6,0.1,0.8c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.2-0.6,0.8-0.7,1.1c-0.1,0.3,0.1,0.8,0,1.1
        c0,0.3-0.3,0.8-0.4,1.1c-0.2,0.3-0.7,0.9-0.7,1.2c0,0.3,0,0.9,0.2,1c0.2,0.1,0.6,0,0.8,0c0.2,0,0.5,0.1,0.6,0.2
        c0.2,0.2,0.3,0.7,0.2,0.9c-0.1,0.2-0.6,0.3-0.8,0.3c-0.3,0-0.9-0.3-1.1-0.2c-0.3,0.2-0.4,0.9-0.4,1.2c0,0.2,0.4,0.7,0.3,0.8
        c-0.1,0.2-0.7-0.1-0.9,0.1c-0.2,0.2-0.1,0.8-0.1,1c0,0.3,0.1,1,0.2,1.3c0.1,0.3,0.4,0.8,0.6,0.9c0.2,0.1,0.6,0.1,0.7,0.3
        c0.2,0.2,0.1,0.9,0,1.2c-0.1,0.2-0.5,0.7-0.7,0.8c-0.2,0-0.5-0.3-0.6-0.2c-0.1,0.1-0.2,0.5-0.1,0.6c0.1,0.3,0.8,0.7,1.1,0.9
        c0.1,0.1,0.3,0.2,0.3,0.2c0.1,0.3-0.2,0.8-0.3,1c-0.1,0.2-0.4,0.5-0.7,0.6c-0.2,0.1-0.7-0.2-0.9-0.2c-0.1,0-0.5-0.2-0.6-0.1
        c-0.3,0.1-0.5,1-0.9,1.1c-0.4,0.1-1-0.5-1.4-0.6c-0.3-0.2-0.9-0.5-1.2-0.6c-0.3-0.1-0.9-0.4-1.2-0.3c-0.2,0.1-0.6,0.5-0.6,0.7
        c-0.1,0.3,0.1,0.8,0.3,1c0.3,0.3,1.1,0.3,1.4,0.4c0.2,0.1,0.6,0.3,0.7,0.5c0.1,0.2,0.1,0.7,0.3,0.8c0.2,0.3,0.8,0.8,1.2,0.8
        c0.2,0,0.5-0.4,0.6-0.3c0.2,0.1,0.2,0.8,0.2,1.1c0,0.2,0.1,0.6,0,0.7c-0.2,0.2-0.8,0.3-1,0.3c-0.1,0-0.4,0-0.5-0.1
        c-0.2-0.2-0.3-0.8-0.5-1.1c-0.2-0.2-0.6-0.8-0.9-0.8c-0.2,0-0.6,0.4-0.8,0.4c-0.3,0-0.8-0.2-1.1-0.2c-0.2,0-0.8-0.3-0.9-0.2
        c-0.2,0.2,0,0.8,0.1,0.9c0.1,0.2,0.5,0.3,0.7,0.3c0.2,0,0.6,0,0.7,0.1c0.2,0.1,0.3,0.6,0.4,0.8c0,0.3-0.1,0.8-0.1,1
        c0,0.2,0.2,0.7,0.3,0.8c0.2,0.2,0.9,0.2,1.1,0.5c0.1,0.1,0.1,0.5,0.1,0.7c0,0.2,0,0.7-0.1,1h-1.6v1.5H87.1l0.4-12.3L76,51
        L78.5,44.8z"/>
    </g>
    
    <g id="Quitman">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="quitman-county" ref="quitman-county" class="st11" d="
        M97.4,409.5l-7.6-0.2l-0.6,0.8l-3.5,0.1l0-1l-6.9-0.1c-0.2-0.2-0.4-0.5-0.4-0.7c0-0.4,0.6-1,0.7-1.3c0.1-0.5,0.1-1.4,0.1-1.9
        c0-0.4,0.1-1.3,0-1.7c-0.1-0.4-0.6-1.1-0.7-1.5c-0.1-0.2-0.1-0.6-0.1-0.8c0-0.4,0.4-1,0.5-1.4c0-0.3-0.1-0.8-0.1-1.1
        c0.1-0.3,0.5-0.7,0.6-0.9c0.1-0.3,0.2-0.9,0.1-1.2c-0.1-0.3-0.7-0.7-0.6-1c0.1-0.3,0.9-0.5,1.2-0.6c0.3-0.1,1,0.3,1.3,0.1
        c0.2-0.2,0.2-0.9,0.1-1.2c0-0.2-0.5-0.7-0.4-0.9c0.1-0.4,1-0.6,1.2-0.9c0.2-0.2,0.4-0.8,0.6-1c0.1-0.2,0.6-0.4,0.7-0.6
        c0.3-0.3,0.7-1,0.8-1.4c0-0.2-0.1-0.7-0.1-0.9c-0.1-0.3-0.4-0.8-0.3-1c0.1-0.3,0.6-0.6,0.9-0.7c0.2-0.1,0.8-0.2,1-0.4
        c0.2-0.2,0.2-0.8,0.2-1.1c0-0.2-0.2-0.5-0.3-0.8c0.6,0,1.4,0,1.7,0.1c0.4,0,1.5,0.2,1.5,0.2v0.8h3l0-0.9c0,0,0.9,0,1.1,0.2
        c0.3,0.3,0.3,0.9,1.2,1.1c0.3,0,0.9-0.5,1.2-0.5c0.4-0.1,1.2-0.2,1.6,0c0.3,0.1,1,0.5,1.2,0.8c0.1,0.2,0.1,0.6,0,0.7
        c-0.2,0.1-0.6-0.3-0.8-0.2c-0.2,0.1-0.4,0.6-0.4,0.8c-0.1,0.3-0.2,1,0,1.2c0.1,0.1,0.4,0,0.5,0.1c0.2,0.1,0.1,0.6,0.3,0.8
        c0.2,0.2,0.9,0.5,1.3,0.5c0.2,0,0.5-0.2,0.6-0.1c0.2,0.1,0.3,0.6,0.5,0.7c0.3,0.2,1.2,0,1.4,0.2c0.2,0.2,0,1.2,0,1.2l1,0l-0.3,18
        L97.4,409.5z"/>
    </g>
    <g id="Polk">
    <polygon @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="polk-county" ref="polk-county" class="st10" points="100.8,129.2 95,144.9 88,144.9 87.8,150.4 71.5,150.4 71.5,151.4 53.3,151.4 49.7,129.4 
        66.7,129.4 66.7,126.8 77.2,126.8 77.2,129.2 80.6,129.2 80.6,127.2 88.3,127.2 88.3,129.1 		"/>
    </g>
    <g id="Heard">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="heard-county" ref="heard-county" class="st10" d="M91.5,208.9l7.4,24.5l-7.9,3.4c0,0,0-0.8,0-1.1c0-0.3,0.1-1,0.3-1.3c0.1-0.2,0.4-0.6,0.7-0.7
        c0.3-0.1,1,0.1,1.3-0.1c0.2-0.2,0.2-0.9,0-1.1c-0.2-0.3-1-0.3-1.4-0.2c-0.3,0-1,0.3-1.3,0.5c-0.3,0.2-0.6,0.8-0.8,1
        c-0.2,0.3-0.4,1.2-0.7,1.4c-0.2,0.2-0.9,0.5-1.2,0.4c-0.4-0.1-1.1-0.7-1.3-1c-0.3-0.4-0.3-1.4-0.6-1.8c-0.2-0.3-0.7-0.8-0.8-1.1
        c-0.2-0.3-0.5-1.1-0.7-1.4c-0.2-0.2-0.9-0.5-1.2-0.5c-0.3,0-1,0.1-1.2,0.3c-0.2,0.3,0.3,1,0.4,1.3c0.3,0.7,1,1.9,1.4,2.5
        c0.4,0.6,1.4,1.8,2,2.2c0.4,0.3,1.5,0.5,1.7,0.9c0.2,0.3-0.1,1.5-0.1,1.5l-1.7,1.1l-1.9,0.4c0,0,0-0.8,0-1.1c0-0.4,0.3-1.4,0-1.7
        c-0.2-0.3-1.2-0.5-1.6-0.4c-0.2,0.1-0.3,0.4-0.4,0.6c-0.1,0.3,0.3,1,0.3,1.4c-0.1,0.4-0.7,1.5-0.7,1.5L68.6,245l-6-36.4
        L91.5,208.9z"/>
    </g>
    <g id="Catoosa">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="catoosa-county" ref="catoosa-county" class="st10" d="M95,18.8v1.9h-1.7c0,0-0.4,0.7-0.4,1c0,0.3,0.5,0.9,0.5,1.3c0.1,0.4,0,1.3-0.2,1.7
        c-0.1,0.4-0.8,1-1,1.3c-0.2,0.3-0.4,1.1-0.5,1.4c-0.1,0.5,0.1,1.9,0.1,1.9h-1.2v4.5h-3.9v5.4h-4.5v0.8h-1.8v-0.8h-0.9l-0.9,5.3
        l-0.1,0.2h-5.8v-9.7h-6.6l-0.7-3.4l1.1-2.3v-7.6L64,19L95,18.8z"/>
    </g>
    <g id="Floyd">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="floyd-county" ref="floyd-county" class="st10" d="M92.4,91.3v5.5h-1.9v16.2h-2.2v14.1h-7.8v2h-3.4v-2.4H66.7v2.5H49.7l-4.1-25.3l8.1-0.1
        c0,0,0.9-0.6,0.9-1c0-0.3-0.6-0.7-0.6-1.1c-0.1-0.9,0.6-1.6,1.5-3.3c0.7-1.1,1.8-2.2,2.2-2.8c0.5-0.7,1.3-2.2,2-2.6
        c0.2-0.1,0.9-0.1,1.1-0.1c0.6-0.2,1.8-0.8,2.4-1.1c0.6-0.3,2-0.8,2.6-1.1c0.4-0.2,1.3-0.6,1.7-0.9c0.3-0.3,0.7-1.2,1.1-1.4
        c0.3-0.2,0.9-0.2,1.2-0.4c0.4-0.2,0.9-0.7,1.3-1c0.3-0.2,0.8-0.6,1.1-0.7c0.4-0.2,1.4-0.3,1.8-0.5c0.2,0,0.5-0.1,0.7-0.2
        c0.3-0.3,0.7-1.1,0.9-1.5c0.3-0.6,0.6-1.9,0.8-2.6c0.3-0.8,0.9-2.4,1.1-3.2c0.2-0.7,0.3-2.1,0.6-2.8c0.4-1,1.6-2.7,2.1-3.6
        c0.4-0.8,1.1-2.3,1.3-3.1c0.1-0.3,0.3-1.1,0.4-1.6l3.8,0v2.5h1.1v3.8h-2.4v9h-2.2l1.9,4.3l-1.7,1.2v1.8h7.8v1.3H92.4z"/>
    </g>
    <g id="Troup">
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="troup-county" ref="troup-county" class="st10" d="M106.8,233.3l0.3,43.4l-33.4-0.3l-0.7-4.3c0.3,0,0.7,0,0.9-0.1c0.5,0,1.6-0.1,2-0.5
            c0.4-0.3,0.6-1.2,0.8-1.6c0.2-0.3,0.7-0.9,0.8-1.3c0.2-0.4,0-1.4,0.2-1.8c0.2-0.3,0.9-0.7,1.2-1c0.2-0.2,0.7-0.8,0.7-1.1
            c0-0.2-0.4-0.6-0.7-0.6c-0.3,0-0.6,0.8-0.9,0.8c-0.2,0.1-0.6-0.2-0.7-0.1c-0.3,0.1-0.3,0.9-0.5,1.1c-0.2,0.2-0.6,0.7-0.9,0.7
            c-0.3,0-0.7-0.6-0.8-0.9c-0.1-0.4,0.5-1.2,0.6-1.7c0.1-0.3,0.1-0.9,0.2-1.2c0.1-0.4,0.2-1.2,0.4-1.5c0.3-0.4,1.2-0.7,1.6-0.9
            c0.3-0.1,0.8-0.1,1.1-0.3c0.3-0.2,0.8-0.7,1-1c0.1-0.2,0.3-0.6,0.3-0.9c0-0.2-0.2-0.6-0.4-0.7c-0.3-0.1-0.9,0.2-1.2,0.1
            c-0.2-0.1-0.6-0.3-0.7-0.5c-0.1-0.2,0.2-0.8,0.4-1c0.1-0.2,0.6-0.3,0.8-0.4c0.3-0.3,0.8-1,1.1-1.3c0.3-0.3,0.9-1.1,1.3-1.3
            c0.4-0.1,1.2,0.4,1.6,0.1c0.2-0.1,0.1-0.8,0.3-0.9c0.2-0.1,0.7-0.2,0.9-0.1c0.2,0.1,0.2,0.7,0.4,0.7c0.2,0.1,0.7-0.4,0.8-0.6
            c0.1-0.3-0.4-0.9-0.2-1.1c0.1-0.2,0.7-0.1,0.9-0.1c0.4,0.1,0.8,0.8,1.2,0.9c0.2,0.1,0.6,0.1,0.8,0c0.2-0.2,0.3-0.8,0.3-1.1
            c0-0.3-0.6-0.9-0.6-1.2c0-0.2,0-0.8,0.2-1c0.3-0.4,1.4-0.5,1.9-0.5c0.2,0,0.7,0.2,0.9,0.3c0.3,0.1,0.6,0.7,0.9,0.7
            c0.2,0,0.5-0.2,0.6-0.3c0.2-0.2,0.3-0.7,0.5-0.9c0.2-0.2,0.7-0.3,0.8-0.5c0.2-0.2,0.3-0.9,0.1-1.2c-0.2-0.2-0.7-0.1-0.9,0
            c-0.2,0.1-0.6,0.4-0.8,0.5c-0.3,0.1-0.9,0.2-1.2,0.1c-0.2-0.1-0.6-0.4-0.6-0.6c0-0.4,1-0.6,1.3-0.8c0.2-0.1,0.6-0.4,0.7-0.7
            c0.1-0.3,0.2-0.9,0-1.1c-0.2-0.2-1-0.1-1.3,0.1c-0.2,0.2-0.4,0.8-0.6,1c-0.2,0.2-0.8,0.4-1.1,0.6c-0.4,0.2-1.2,0.5-1.5,0.7
            c-0.4,0.3-1,1.1-1.3,1.5c-0.3,0.3-0.9,1-1.2,1.3c-0.2,0.2-0.6,0.7-0.9,0.7c-0.2,0-0.5-0.3-0.6-0.5c-0.2-0.4-0.4-1.3-0.3-1.7
            c0.1-0.4,0.7-0.9,0.9-1.2c0.2-0.3,0.5-0.9,0.7-1.2c0.3-0.4,0.7-1.2,1.1-1.4c0.3-0.2,1-0.2,1.3-0.4c0.3-0.2,0.5-1.1,0.9-1.2
            c0.3-0.1,0.9,0.1,1.2,0c0.2-0.1,0.5-0.5,0.4-0.7c-0.1-0.3-0.7-0.5-0.9-0.7c-0.1-0.2-0.2-0.8-0.1-1c0-0.3,0.3-0.9,0.5-1.1
            c0.2-0.2,0.9-0.3,1.1-0.5c0.2-0.2,0.6-0.8,0.6-1.5l7.9-3.4L106.8,233.3z"/>
        <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="troup-county" ref="troup-county" class="st10" d="M85.9,239.7l1.7-1.1c0,0-0.2,0.4-0.3,0.6c0,0.3,0.3,1,0.2,1.3c-0.1,0.3-0.5,0.9-0.8,1.1
            c-0.3,0.3-1.3,0.3-1.6,0.7c-0.2,0.2-0.2,1-0.4,1.1c-0.3,0.3-1.3,0-1.7,0.2c-0.4,0.3-0.9,1.5-0.9,2c0,0.3,0.3,1,0.3,1.3
            c0,0.5-0.5,1.4-0.6,1.9c0,0.4,0.2,1.2,0.1,1.5c-0.1,0.3-0.8,0.8-1.1,0.7c-0.2-0.1-0.4-0.6-0.5-0.9c-0.1-0.3-0.1-0.8,0-1.1
            c0-0.3,0.2-1,0.2-1.3c0-0.2-0.1-0.6-0.2-0.8c-0.1-0.2-0.2-0.6-0.4-0.7c-0.1-0.1-0.6-0.1-0.7,0c-0.2,0.2,0,0.9,0,1
            c0,0.1,0.5,0.9,0.5,1.2c0,0.3-0.3,0.8-0.5,0.9c-0.2,0.1-0.6,0-0.8,0.1c-0.3,0.2-0.6,0.9-0.6,1.2c0,0.2,0.2,0.6,0.3,0.7
            c0.1,0.3,0.4,1.1,0.2,1.4c-0.1,0.2-0.7,0.2-0.9,0.4c-0.3,0.2-0.7,0.7-0.9,1c-0.2,0.3-0.3,1.1-0.3,1.4c0,0.2,0.3,0.6,0.4,0.8
            c0,0.2,0.1,0.7,0,0.9c-0.1,0.2-0.7,0.4-0.9,0.6c-0.2,0.2-0.3,0.9-0.4,1.2c-0.1,0.2-0.5,0.6-0.8,0.6c-0.3-0.1-0.4-0.8-0.4-1.1
            c0-0.3,0.3-0.8,0.4-1.1c0-0.3,0-1-0.1-1.3c-0.1-0.4-0.4-1.1-0.5-1.4c-0.1-0.4,0.1-1.4,0-1.8c-0.1-0.4-0.2-1.3-0.5-1.6
            c-0.2-0.2-0.7,0-0.9-0.2c-0.2-0.1-0.4-0.6-0.5-0.7c-0.2-0.2-0.7-0.8-1-0.7c-0.3,0.1-0.5,0.8-0.5,1.1c0,0.3,0.3,0.9,0.6,1.1
            c0.1,0.1,0.6,0.2,0.7,0.4c0.2,0.3,0.2,1.2,0.3,1.7c0,0.4-0.3,1.3-0.1,1.6c0.1,0.3,0.8,0.5,0.9,0.8c0.3,0.5,0.5,1.6,0.3,2.1
            c-0.1,0.3-0.7,0.7-1,0.7c-0.3,0-0.8,0-1.2-0.1L68.6,245l12.9-4.6c0,0,0.7-1.1,0.7-1.5c0-0.3-0.3-1-0.3-1.4c0-0.2,0.2-0.5,0.4-0.6
            c0.4-0.2,1.3,0.1,1.6,0.4c0.3,0.3-0.1,1.3,0,1.7c0,0.3,0,1.1,0,1.1L85.9,239.7z"/>
    </g>
    <g id="Haralson">
    <polygon @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="haralson-county" ref="haralson-county" class="st10" points="87.8,150.4 89.2,150.4 89.2,161.9 87.6,161.9 87.6,174.7 80.7,176.8 79.3,175.2 
        63.6,181.3 58.1,180.9 53.3,151.4 71.5,151.4 71.5,150.4 		"/>
    </g>
    <g id="Walker">
    <polygon @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="walker-county" ref="walker-county" class="st10" points="81.7,67.5 39.5,67.2 38.8,62.8 43,57.8 43,53.8 45.9,50.2 45.9,47.5 47.3,46.6 
        47.3,37.4 49,32 56.3,19.1 64,19 66.6,21.8 66.6,29.4 65.4,31.6 66.1,35.1 72.7,35.1 72.7,44.8 78.5,44.8 76,51 87.5,51.6 
        87.1,63.8 87,67.5 85.5,67.5 		"/>
    </g>
    <g id="Chattooga">
    <path @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="chattooga-county" ref="chattooga-county" class="st10" d="M39.5,67.2l42.2,0.2c-0.1,0.5-0.3,1.3-0.4,1.6c-0.3,0.8-1,2.3-1.3,3.1
        c-0.5,0.9-1.7,2.7-2.1,3.6c-0.3,0.7-0.4,2.1-0.6,2.8c-0.2,0.8-0.8,2.4-1.1,3.2c-0.2,0.6-0.5,1.9-0.8,2.6c-0.2,0.4-0.5,1.2-0.9,1.5
        C74.5,86,74.1,86,73.9,86c-0.5,0.1-1.4,0.3-1.8,0.5c-0.3,0.1-0.8,0.5-1.1,0.7c-0.3,0.2-0.9,0.8-1.3,1c-0.3,0.1-1,0.2-1.2,0.4
        c-0.4,0.3-0.7,1.1-1.1,1.4c-0.4,0.3-1.3,0.7-1.7,0.9c-0.6,0.3-1.9,0.8-2.6,1.1c-0.6,0.3-1.8,0.9-2.4,1.1c-0.3,0.1-0.9,0-1.1,0.1
        c-0.7,0.4-1.6,1.9-2,2.6c-0.4,0.6-1.5,1.7-2.2,2.8c-1,1.6-1.6,2.4-1.5,3.3c0,0.3,0.6,0.7,0.6,1.1c0,0.3-0.9,1-0.9,1l-8.1,0.1
        L39.5,67.2z"/>
    </g>
    <g id="Dade">
    <polygon @mouseover="hover = true" @mousemove="hoverCounty" @mouseleave="hover = false" @click="clcCnt" data-cnt="dade-county" ref="dade-county" class="st10" points="56.3,19.1 49,32 47.3,37.4 47.3,46.6 45.9,47.5 45.9,50.2 43,53.8 43,57.8 38.8,62.8 
        31.7,19.3 		"/>
    </g>
</g>

<g id="City_Names">
	<rect x="383.1" y="181.7" class="st1-l" width="69.9" height="16.8"/>
	<rect x="45.7" y="34.6" class="st1-l" width="69.9" height="16.8"/>
	<rect x="119.2" y="160.6" class="st1-l" width="69.9" height="16.8"/>
	<rect x="195.9" y="279.3" class="st1-l" width="69.9" height="16.8"/>
	<rect x="148.5" y="438" class="st1-l" width="69.9" height="16.8"/>
	<rect x="249.4" y="525.7" class="st1-l" width="69.9" height="16.8"/>
	<rect x="15.4" y="320.2" class="st1-l" width="75.9" height="16.8"/>
	<rect x="452.2" y="344.6" class="st1-l" width="75.9" height="16.8"/>
	<rect x="447.7" y="468.9" class="st1-l" width="75.9" height="16.8"/>
	<text transform="matrix(1 0 0 1 59.7832 47.4443)" class="st41">Dalton</text>
	<text transform="matrix(1 0 0 1 132.5942 173.8794)" class="st41">Atlanta</text>
	<text transform="matrix(1 0 0 1 19.7373 333.8047)" class="st41">Columbus</text>
	<text transform="matrix(1 0 0 1 160.3281 450.5273)" class="st41">Albany</text>
	<text transform="matrix(1 0 0 1 210.7529 292.5337)" class="st41">Macon</text>
	<text transform="matrix(1 0 0 1 256.7554 539.0166)" class="st41">Valdosta</text>
	<text transform="matrix(1 0 0 1 451.876 482.3633)" class="st41">Brunswick</text>
	<text transform="matrix(1 0 0 1 458.4805 358.2852)" class="st41">Savannah</text>
	<text transform="matrix(1 0 0 1 391.7803 194.8037)" class="st41">Augusta</text>
</g>
</svg>
</template>

<script>
export default {
    name: 'EmcSvg',
    data() {
        return {
            hover: false
        }
    },
    props: {
        emcLHover: Boolean,
        emcLId: String,
        countyLHover: Boolean,
        countyLId: String,
        toggleCounty: Boolean,
		showList: Boolean,
		pdfHover: String,
		SummaryScores: Array
    },
    emits: ['data-emc', 'data-cnt', 'hover', 'open-emc'],
	mounted() {
		if(this.pdfHover) {
			this.$refs[this.pdfHover].classList.add('hovering')
		}
		this.SummaryScores.forEach(s => {
			let id = s.id
			let style = s.style
			this.$refs[id].classList.add(style)
		})

		let plantersEmc = this.SummaryScores.filter(s => { return s.id == 'planters-emc'})
		this.$refs['planters-emc-1'].classList.add(plantersEmc[0].style)

		let diverseEmc = this.SummaryScores.filter(s => { return s.id == 'diverse-power-inc'})
		this.$refs['diverse-power-inc-1'].classList.add(diverseEmc[0].style)
		this.$refs['diverse-power-inc-2'].classList.add(diverseEmc[0].style)
	
		let jacksonEmc = this.SummaryScores.filter(s => { return s.id == 'jackson-emc'})
		this.$refs['jackson-emc-1'].classList.add(jacksonEmc[0].style)
		
		let okefenokeEmc = this.SummaryScores.filter(s => { return s.id == 'okefenoke-remc'})
		this.$refs['okefenoke-remc-1'].classList.add(okefenokeEmc[0].style)
		this.$refs['okefenoke-remc-2'].classList.add(okefenokeEmc[0].style)
		this.$refs['okefenoke-remc-3'].classList.add(okefenokeEmc[0].style)
		this.$refs['okefenoke-remc-4'].classList.add(okefenokeEmc[0].style)
		this.$refs['okefenoke-remc-5'].classList.add(okefenokeEmc[0].style)
		this.$refs['okefenoke-remc-6'].classList.add(okefenokeEmc[0].style)

		let coastalEmc = this.SummaryScores.filter(s => { return s.id == 'coastal-emc'})
		this.$refs['coastal-emc-1'].classList.add(coastalEmc[0].style)
		this.$refs['coastal-emc-2'].classList.add(coastalEmc[0].style)

		let gradyEmc = this.SummaryScores.filter(s => { return s.id == 'grady-emc'})
		this.$refs['grady-emc-1'].classList.add(gradyEmc[0].style)
		this.$refs['grady-emc-2'].classList.add(gradyEmc[0].style)
		this.$refs['grady-emc-3'].classList.add(gradyEmc[0].style)
		
	},
    methods: {
        hoverEmc(e) {
            if(!this.toggleCounty) {
            let id = e.target.getAttribute('data-emc')
            this.$emit('data-emc', id)
            }
        },
        hoverCounty(e) {
            if(this.toggleCounty) {
            let id = e.target.getAttribute('data-cnt')
            this.$emit('data-cnt', id)
            } 
        },
        openEmc(e) {
            let id = e.target.getAttribute('data-emc')
            this.$emit('open-emc', id)
        },
        clcCnt(e) {
            e.target.classList.remove('countiesfocus')
            e.target.classList.add('displayNone')
            let x = e.clientX
            let y = e.clientY
            let el = document.elementFromPoint(x,y)
            let id = el.getAttribute('data-emc')
            this.$emit('open-emc', id)
            e.target.classList.remove('displayNone')
        },
        mouseOverEMC(e) {
            let id = e.target.getAttribute('data-emc')
            if(id == 'coastal-emc') {
                this.$refs['coastal-emc'].classList.add('hovering')
                this.$refs['coastal-emc-1'].classList.add('hovering')
                this.$refs['coastal-emc-2'].classList.add('hovering')
            } else if(id == 'planters-emc') {
                this.$refs['planters-emc'].classList.add('hovering')
                this.$refs['planters-emc-1'].classList.add('hovering')
            } else if(id == 'okefenoke-remc') {
                this.$refs['okefenoke-remc'].classList.add('hovering')
                this.$refs['okefenoke-remc-1'].classList.add('hovering')
                this.$refs['okefenoke-remc-2'].classList.add('hovering')
                this.$refs['okefenoke-remc-3'].classList.add('hovering')
                this.$refs['okefenoke-remc-4'].classList.add('hovering')
                this.$refs['okefenoke-remc-5'].classList.add('hovering')
                this.$refs['okefenoke-remc-6'].classList.add('hovering')
            } else if(id == 'diverse-power-inc') {
                this.$refs['diverse-power-inc'].classList.add('hovering')
                this.$refs['diverse-power-inc-1'].classList.add('hovering')
                this.$refs['diverse-power-inc-2'].classList.add('hovering')
            } else if(id == 'grady-emc') {
                this.$refs['grady-emc'].classList.add('hovering')
                this.$refs['grady-emc-1'].classList.add('hovering')
                this.$refs['grady-emc-2'].classList.add('hovering')
                this.$refs['grady-emc-3'].classList.add('hovering')
            } else if(id == 'jackson-emc') {
                this.$refs['jackson-emc'].classList.add('hovering')
                this.$refs['jackson-emc-1'].classList.add('hovering')
            } else {
                this.$refs[id].classList.add('hovering')
            }
            this.hover = true
        },
        mouseLeaveEmc(e) {
            let id = e.target.getAttribute('data-emc')
            if(id == 'coastal-emc') {
                this.$refs['coastal-emc'].classList.remove('hovering')
                this.$refs['coastal-emc-1'].classList.remove('hovering')
                this.$refs['coastal-emc-2'].classList.remove('hovering')
            } else if(id == 'planters-emc') {
                this.$refs['planters-emc'].classList.remove('hovering')
                this.$refs['planters-emc-1'].classList.remove('hovering')
            } else if(id == 'okefenoke-remc') {
                this.$refs['okefenoke-remc'].classList.remove('hovering')
                this.$refs['okefenoke-remc-1'].classList.remove('hovering')
                this.$refs['okefenoke-remc-2'].classList.remove('hovering')
                this.$refs['okefenoke-remc-3'].classList.remove('hovering')
                this.$refs['okefenoke-remc-4'].classList.remove('hovering')
                this.$refs['okefenoke-remc-5'].classList.remove('hovering')
                this.$refs['okefenoke-remc-6'].classList.remove('hovering')
            } else if(id == 'diverse-power-inc') {
                this.$refs['diverse-power-inc'].classList.remove('hovering')
                this.$refs['diverse-power-inc-1'].classList.remove('hovering')
                this.$refs['diverse-power-inc-2'].classList.remove('hovering')
            } else if(id == 'grady-emc') {
                this.$refs['grady-emc'].classList.remove('hovering')
                this.$refs['grady-emc-1'].classList.remove('hovering')
                this.$refs['grady-emc-2'].classList.remove('hovering')
                this.$refs['grady-emc-3'].classList.remove('hovering')
            } else if(id == 'jackson-emc') {
                this.$refs['jackson-emc'].classList.remove('hovering')
                this.$refs['jackson-emc-1'].classList.remove('hovering')
            } else {
                this.$refs[id].classList.remove('hovering')
            }
        }
    },
    watch: {
        hover(newV) {
            this.$emit('hover', newV)
        },
        emcLHover(newV) {
            if(newV == true) {
                if(this.emcLId == 'coastal-emc') {
                    this.$refs['coastal-emc'].classList.add('hovering')
                    this.$refs['coastal-emc-1'].classList.add('hovering')
                    this.$refs['coastal-emc-2'].classList.add('hovering')
                } else if(this.emcLId == 'planters-emc') {
                    this.$refs['planters-emc'].classList.add('hovering')
                    this.$refs['planters-emc-1'].classList.add('hovering')
                } else if(this.emcLId == 'okefenoke-remc') {
                    this.$refs['okefenoke-remc'].classList.add('hovering')
                    this.$refs['okefenoke-remc-1'].classList.add('hovering')
                    this.$refs['okefenoke-remc-2'].classList.add('hovering')
                    this.$refs['okefenoke-remc-3'].classList.add('hovering')
                    this.$refs['okefenoke-remc-4'].classList.add('hovering')
                    this.$refs['okefenoke-remc-5'].classList.add('hovering')
                    this.$refs['okefenoke-remc-6'].classList.add('hovering')
                } else if(this.emcLId == 'diverse-power-inc') {
                    this.$refs['diverse-power-inc'].classList.add('hovering')
                    this.$refs['diverse-power-inc-1'].classList.add('hovering')
                    this.$refs['diverse-power-inc-2'].classList.add('hovering')
                } else if(this.emcLId == 'grady-emc') {
                    this.$refs['grady-emc'].classList.add('hovering')
                    this.$refs['grady-emc-1'].classList.add('hovering')
                    this.$refs['grady-emc-2'].classList.add('hovering')
                    this.$refs['grady-emc-3'].classList.add('hovering')
                } else if(this.emcLId == 'jackson-emc') {
                    this.$refs['jackson-emc'].classList.add('hovering')
                    this.$refs['jackson-emc-1'].classList.add('hovering')
                } else {
                this.$refs[this.emcLId].classList.add('hovering')
                }
                this.hover = true
            } else {
                if(this.emcLId == 'coastal-emc') {
                    this.$refs['coastal-emc'].classList.remove('hovering')
                    this.$refs['coastal-emc-1'].classList.remove('hovering')
                    this.$refs['coastal-emc-2'].classList.remove('hovering')
                } else if(this.emcLId == 'planters-emc') {
                    this.$refs['planters-emc'].classList.remove('hovering')
                    this.$refs['planters-emc-1'].classList.remove('hovering')
                } else if(this.emcLId == 'okefenoke-remc') {
                    this.$refs['okefenoke-remc'].classList.remove('hovering')
                    this.$refs['okefenoke-remc-1'].classList.remove('hovering')
                    this.$refs['okefenoke-remc-2'].classList.remove('hovering')
                    this.$refs['okefenoke-remc-3'].classList.remove('hovering')
                    this.$refs['okefenoke-remc-4'].classList.remove('hovering')
                    this.$refs['okefenoke-remc-5'].classList.remove('hovering')
                    this.$refs['okefenoke-remc-6'].classList.remove('hovering')
                } else if(this.emcLId == 'diverse-power-inc') {
                    this.$refs['diverse-power-inc'].classList.remove('hovering')
                    this.$refs['diverse-power-inc-1'].classList.remove('hovering')
                    this.$refs['diverse-power-inc-2'].classList.remove('hovering')
                } else if(this.emcLId == 'grady-emc') {
                    this.$refs['grady-emc'].classList.remove('hovering')
                    this.$refs['grady-emc-1'].classList.remove('hovering')
                    this.$refs['grady-emc-2'].classList.remove('hovering')
                    this.$refs['grady-emc-3'].classList.remove('hovering')
                } else if(this.emcLId == 'jackson-emc') {
                    this.$refs['jackson-emc'].classList.remove('hovering')
                    this.$refs['jackson-emc-1'].classList.remove('hovering')
                } else {
                this.$refs[this.emcLId].classList.remove('hovering')
                }
            }
        },
        countyLHover(newV) {
            if(newV == true) {
                this.$refs[this.countyLId].classList.add('hovering')
                this.hover = true
            } else {
                this.$refs[this.countyLId].classList.remove('hovering')
                this.hover = false
            }
        }
    }
}
</script>

<style scoped>
.displayNone { display: none;}
.noemc{fill:#CCC;}
	.rank-a{fill:#03E538;stroke:#231F20;}
	.rank-b{fill:#A8FF00;stroke:#231F20;}
	.rank-c{fill:#fffb00;stroke:#231F20;}
	.rank-d{fill:#FF9100;stroke:#231F20;}
	.rank-f{fill:#FF3939;stroke:#231F20;}

    @media (hover: hover) {
        .rank-a:hover {fill:#007F00;transition-duration:100ms}
        .rank-b:hover {fill:#669900;transition-duration:100ms}
        .rank-c:hover {fill:#999900;transition-duration:100ms}
        .rank-d:hover {fill:#99651A;transition-duration:100ms}
        .rank-f:hover {fill:#993838;transition-duration:100ms}
    }
	.rank-a.hovering {fill:#007F00;transition-duration:100ms}
	.rank-b.hovering {fill:#669900;transition-duration:100ms}
	.rank-c.hovering {fill:#999900;transition-duration:100ms}
	.rank-d.hovering {fill:#99651A;transition-duration:100ms}
	.rank-f.hovering {fill:#993838;transition-duration:100ms}


    .patternbg{fill:none;}
    .patternbg-focus{fill: #FFFFFF;}
    .passtru {pointer-events: none;}
    
    .st1 {
        fill:#231F20; stroke:#231F20; stroke-width: 3px; opacity: 0.6;
    }
    .st1-f {
        fill:#231F20; stroke:#231F20; stroke-width: 3px; opacity: 1;
    }

    .st2 {fill:none;stroke:#231F20; stroke-width:4.8; stroke-linejoin:round; opacity: 0.6;}
    .st2-f {fill:none;stroke:#231F20; stroke-width:4.8; stroke-linejoin:round; opacity: 1;}

	.counties{
        pointer-events: none; 
    }

	.countiesfocus{
        pointer-events: all;}

    .counties .st10, .counties .st11 { fill:none;stroke:rgba(35, 31, 32, 0.3);stroke-width:0.5; }
	.countiesfocus .st10{fill:url(#Tilted_Lines);stroke:#231F20;stroke-width:0.5;}
	.countiesfocus .st11{fill:url(#Tilted_Dots);stroke:#231F20;stroke-width:0.5;}

    @media (hover: hover) {
    .st10:hover {fill:url(#Tilted_Lines_W);}
	.st11:hover {fill:url(#Tilted_Dots_W);}
    }

    .st10.hovering{fill:url(#Tilted_Lines_W);}
	.st11.hovering{fill:url(#Tilted_Dots_W);}
	.st41{fill:#FFFFFF; font-size:14px; pointer-events: none;}


</style>